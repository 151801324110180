/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  ntpServerIsRequired: {
    id: 'NTP server is required',
    defaultMessage: 'NTP Server URL is required (previously set URL was {serverUrl}).',
  },
  notDefined: {
    id: 'not defined',
    defaultMessage: 'not defined',
  },
})
