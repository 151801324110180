import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  pageName: {
    id: 'info-page.pageName',
    defaultMessage: 'Info',
  },
  version: {
    id: 'info-page.version',
    defaultMessage: 'Version',
  },
  unknown: {
    id: 'info-page.unknown',
    defaultMessage: 'Unknown',
  },
})
