import axios from 'axios'
import { isEmpty, range } from 'lodash'
import { batchActions } from 'redux-batched-actions'
import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { getTenantApi, getUserApi } from 'skybase-oauth/rest'
import { getStudioAPIHost } from '@/utils/url'
import { showErrorToast, showSuccessToast } from '@/common/services/show-toast'
import {
  getCalibrationItems,
  getCalibrationUserData,
} from '@/fleet-configuration/data-fleet/calibration/calibration-selectors'
import { equipmentApi } from '@/fleet-configuration/equipment'
import { MAX_CALIBRATIONS_TO_LOAD_PER_REQUEST } from '@/fleet-configuration/data-fleet/calibration/calibration-constants'
import { DEVICE_CALIBRATION_PRE_EXPIRATION_WARNING_DAYS } from '@/fleet-configuration/data-fleet/devices-calibrations/devices-calibrations-constants'
import { messages as t } from './calibration-actions-i18n'

export const SET_CALIBRATION_USER_DATA = getActionName('SET_CALIBRATION_USER_DATA')
export const setCalibrationUserData = userData => createAction(SET_CALIBRATION_USER_DATA, userData)

export const SET_CALIBRATION_FORM_DATA = getActionName('SET_CALIBRATION_FORM_DATA')
export const setCalibrationFormData = formData => createAction(SET_CALIBRATION_FORM_DATA, formData)

export const SET_CALIBRATION_FORM_DATA_ERRORS = getActionName('SET_CALIBRATION_FORM_DATA_ERRORS')
export const setCalibrationFormDataErrors = (field, alerts) =>
  createAction(SET_CALIBRATION_FORM_DATA_ERRORS, { [field]: alerts })

export const SET_CALIBRATION_ITEM = getActionName('SET_CALIBRATION_ITEM')
export const setCalibrationItem = (typeNumber, serialNumber, calibrationItem) =>
  createAction(SET_CALIBRATION_ITEM, {
    id: JSON.stringify({ typeNumber, serialNumber }),
    typeNumber,
    serialNumber,
    calibrationItem,
  })

export const tryLoadCalibrationUserData = (tenantId, userId) => async (dispatch, getState) => {
  let userData = getCalibrationUserData(getState())
  if (!userData || isEmpty(userData)) {
    const [{ name: tenantName }, { email: userEmail, name: userName }] = await Promise.all([
      getTenantApi(tenantId).catch(error => {
        // 403 is normal workflow for people that don't have rights to see tenant. Consider it empty response
        if (error?.status === 403) {
          return {}
        }
        showErrorToast(t.anErrorOccurredWhileFetchingCompanyInformation, t.error)
        return {}
      }),
      getUserApi(userId).catch(() => {
        showErrorToast(t.anErrorOccurredWhileFetchingTheUsers, t.error)
        return {}
      }),
    ])
    userData = {
      email: userEmail ?? '',
      name: userName ?? '',
      company: tenantName ?? '',
    }
    dispatch(setCalibrationUserData(userData))
  }
  return userData
}

export const sendOrderCalibrationMail = async (subject, email, name, company, typeNumber, serialNumber, note) => {
  try {
    await axios.post(
      `${getStudioAPIHost()}/api/calibrations/email`,
      {
        subject,
        userEmail: email.trim(),
        userName: name.trim(),
        userCompany: company.trim(),
        typeNumber,
        serialNumber,
        note,
      },
      { customErrorHandler: () => {} },
    )
    showSuccessToast(t.yourOrderCalibrationWasSuccessfullySent)
    return true
  } catch (e) {
    if (e.response?.status === 403) {
      showErrorToast(t.pleaseContactAdmin, t.yourCalibrationOrderIsForbidden)
    } else {
      showErrorToast(t.pleaseTryAgainLater, t.yourCalibrationOrderCouldNotBeSent)
    }
    return false
  }
}

// Extend the calibrations list with some helper fields
const addCalibrationHelperFields = calibration => {
  let notifications = 0
  if (!calibration.calibrationDate) {
    return calibration
  }
  const calibrationExpires = new Date(calibration.calibrationDate)
  calibrationExpires.setDate(calibrationExpires.getDate() + calibration.calibrationIntervalDays)
  const expirationWarningDate = new Date()
  expirationWarningDate.setDate(expirationWarningDate.getDate() + DEVICE_CALIBRATION_PRE_EXPIRATION_WARNING_DAYS)
  const isExpiring = expirationWarningDate.getTime() > calibrationExpires.getTime() && calibration.calibrationDate

  if (isExpiring) {
    notifications += 1
  }

  return { ...calibration, isExpiring, notifications }
}

export const loadMissingCalibrations =
  (typeAndSerialNumberArr = []) =>
  async (dispatch, getState) => {
    const loadedCalibrations = getCalibrationItems(getState())
    const itemsToLoad = typeAndSerialNumberArr.filter(({ typeNumber, serialNumber }) => {
      const calibrationKey = JSON.stringify({ typeNumber, serialNumber })
      return (
        typeNumber &&
        serialNumber &&
        (!loadedCalibrations[calibrationKey] || loadedCalibrations[calibrationKey].hasRequestError)
      )
    })
    if (itemsToLoad.length) {
      // first mark all items as being loaded
      dispatch(
        batchActions(
          itemsToLoad.map(item =>
            setCalibrationItem(item.typeNumber, item.serialNumber, {
              typeNumber: item.typeNumber,
              serialNumber: item.serialNumber,
              isLoading: true,
            }),
          ),
        ),
      )

      const numberOfCalibrationRequests = itemsToLoad.length / MAX_CALIBRATIONS_TO_LOAD_PER_REQUEST
      // load calibrations per batch size - each batch sequentially (wait for previous item to finish)
      //  The reason for this is, that /api/calibrations can't handle too much stress, so this code gives a bit of breathing room to it - see DHUB-5211
      range(0, numberOfCalibrationRequests).reduce(async (previousPromise, currentDataIndex) => {
        const itemsBatch = itemsToLoad
          .slice(
            currentDataIndex * MAX_CALIBRATIONS_TO_LOAD_PER_REQUEST,
            (currentDataIndex + 1) * MAX_CALIBRATIONS_TO_LOAD_PER_REQUEST,
          )
          .map(item => ({ typeNumber: item.typeNumber, serialNumber: item.serialNumber }))
        // by using "previousPromise.then" we are forcing waiting for last request to finish, before new one starts.
        //  a side effect from this one is, that "previousPromise" must resolve, otherwise one failed request would skip all following requests.
        //  To prevent this, we need to have ".catch" part (that can never throw).
        const { data } = await previousPromise.then(() =>
          axios
            .post(`${getStudioAPIHost()}${equipmentApi.CALIBRATIONS}`, itemsBatch)
            .catch(() => ({ data: itemsBatch.map(item => ({ ...item, hasRequestError: true })) })),
        )
        dispatch(
          batchActions(
            data.map(item =>
              setCalibrationItem(
                itemsBatch.find(
                  itemToLoad =>
                    itemToLoad.typeNumber.startsWith(item.baseTypeNumber) &&
                    itemToLoad.serialNumber === item.serialNumber,
                )?.typeNumber || item.baseTypeNumber,
                item.serialNumber,
                addCalibrationHelperFields(item),
              ),
            ),
          ),
        )
        // mark all remaining calibrations from this batch as already loaded
        //  This happens only if baseTypeNumber covers more then 1 equipment item
        if (data.length < itemsBatch.length) {
          const allCalibrations = getCalibrationItems(getState())
          const noLongerLoadingCalibrationItems = []
          itemsBatch.forEach(batchItem => {
            const calibrationKey = JSON.stringify({
              typeNumber: batchItem.typeNumber,
              serialNumber: batchItem.serialNumber,
            })
            if (allCalibrations[calibrationKey].calibrationItem.isLoading) {
              noLongerLoadingCalibrationItems.push(
                setCalibrationItem(batchItem.typeNumber, batchItem.serialNumber, {
                  ...allCalibrations[calibrationKey].calibrationItem,
                  isLoading: false,
                }),
              )
            }
          })
          dispatch(batchActions(noLongerLoadingCalibrationItems))
        }
      }, Promise.resolve())
    }
  }
