import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { SbModal } from 'skybase-ui/skybase-components/sb-modal'
import { SbButton } from 'skybase-ui/skybase-components/sb-button'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'
import { SbLightTabs } from 'skybase-ui/skybase-components/sb-light-tabs'
import { SbLightTabsTab } from 'skybase-ui/skybase-components/sb-light-tabs/sb-light-tabs-tab'
import { SbLightTabsTitle } from 'skybase-ui/skybase-components/sb-light-tabs/sb-light-tabs-title'
import { SbLightTabsContent } from 'skybase-ui/skybase-components/sb-light-tabs/sb-light-tabs-content'
import { showErrorToast, showInfoToast } from '@/common/services/show-toast'
import { storeEquipmentItem } from '@/fleet-configuration/equipment/actions'
import { loadChainIfEmpty, storeNewChain, updateChain } from '@/fleet-configuration/data-fleet/chain/chain-actions'
import { loadProjectDeviceIfEmpty } from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import {
  addComponentModalPossibleComponents,
  addComponentModalTabs,
} from '@/fleet-configuration/page-components/wizard/add-component-modal/add-component-modal-constants'
import { fetchComponentByType, useEquipment } from '@/fleet-configuration/equipment'
import { AddComponentModalEquipmentTable } from '@/fleet-configuration/page-components/wizard/add-component-modal/add-component-modal-equipment-table'
import { AddComponentModalCatalogTable } from '@/fleet-configuration/page-components/wizard/add-component-modal/add-component-modal-catalog-table'
import { getChannelsChainItemFromAllChainItems } from '@/fleet-configuration/data-fleet/chain/chain-selectors'
import { AddComponentModalMissingData } from '@/fleet-configuration/page-components/wizard/add-component-modal/add-component-modal-missing-data'
import { messages as t } from './add-component-modal-i18n'
import './add-component-modal.scss'

export const AddComponentModal = ({ deviceId, moduleIndex, channelIndex, componentToAdd }) => {
  const [isLoadingDependencies, setIsLoadingDependencies] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [dataToStore, setDataToStore] = useState(null)
  const [chainItem, setChainItem] = useState(null)
  const [showMissingDataForm, setShowMissingDataForm] = useState(false)
  const [activeTab, setActiveTab] = useState(addComponentModalTabs.EQUIPMENT_TAB)
  const [lastSelectedEquipmentRow, setLastSelectedEquipmentRow] = useState(null)

  const { formatMessage: _ } = useIntl()
  const dispatch = useDispatch()

  const { loading: isEquipmentLoading, refetch } = useEquipment()

  useEffect(() => {
    setIsLoadingDependencies(true)
    Promise.all([dispatch(loadProjectDeviceIfEmpty(deviceId)), dispatch(loadChainIfEmpty())]).then(
      async ([projectDevice, chainItems]) => {
        let currentChain = getChannelsChainItemFromAllChainItems(projectDevice, moduleIndex, channelIndex, chainItems)
        if (currentChain) {
          if (componentToAdd === addComponentModalPossibleComponents.SENSOR) {
            setDataToStore({
              typeNumber: currentChain.sensor?.typeNumber,
              serialNumber: currentChain.sensor?.serialNumber,
            })
          } else {
            setDataToStore({ typeNumber: currentChain.cable?.typeNumber })
          }
        } else {
          const currentModule = projectDevice.modules[moduleIndex]
          const typeNumber = currentModule?.getType()
          const serialNumber = currentModule?.parametersReadable?.snr || projectDevice.serialNumber
          currentChain = await dispatch(storeNewChain(typeNumber, serialNumber, channelIndex))
        }
        setChainItem(currentChain)
        setIsLoadingDependencies(false)
      },
    )
  }, [deviceId, moduleIndex, channelIndex, componentToAdd, dispatch])

  const isLoading = isLoadingDependencies || isEquipmentLoading

  const closeModalCallback = useCallback(() => dispatch(closeModal()), [dispatch])
  const submitChainFactory = useCallback(
    originalChainItem => async updateData => {
      let isChainUpdated = false
      try {
        const newChainData = { ...originalChainItem, userPreferences: {} }
        if (updateData) {
          newChainData[componentToAdd] = updateData
          if (componentToAdd === addComponentModalPossibleComponents.CABLE) {
            delete newChainData[componentToAdd].serialNumber
          }
        } else {
          delete newChainData[componentToAdd]
        }

        // make the request only if there was any actual change
        const bothDontHaveComponent = !updateData && !originalChainItem[componentToAdd]
        const haveSameTypeNumber = originalChainItem[componentToAdd]?.typeNumber === updateData?.typeNumber
        const haveSameSerialIfApplicable =
          componentToAdd !== addComponentModalPossibleComponents.CABLE &&
          originalChainItem[componentToAdd]?.serialNumber === updateData?.serialNumber
        const requestPromises = []
        if (!bothDontHaveComponent && !haveSameTypeNumber && !haveSameSerialIfApplicable) {
          isChainUpdated = true
          requestPromises.push(dispatch(updateChain(newChainData)))
          if (activeTab === addComponentModalTabs.CATALOG_TAB && newChainData[componentToAdd]) {
            const newEquipmentData = {
              id: v4(),
              family: componentToAdd,
              typeNumber: updateData.typeNumber,
              userNotes: '',
            }
            if (componentToAdd === addComponentModalPossibleComponents.SENSOR) {
              newEquipmentData.serialNumber = updateData.serialNumber
            }
            const component = await fetchComponentByType(updateData.typeNumber)
            newEquipmentData.typeName = component?.name
            requestPromises.push(
              storeEquipmentItem(newEquipmentData, false).then(newEquipmentItem => {
                refetch()
                return newEquipmentItem
              }),
            )
          }
        }
        // in case we are filling some missing data from equipment table, then update source row from that equipment table
        if (
          activeTab === addComponentModalTabs.EQUIPMENT_TAB && // equipment tab must be active
          newChainData[componentToAdd] && // we are not deleting row
          lastSelectedEquipmentRow && // last row is known === we are actually storing a change
          // and there actually was missing data that was filled in
          ((updateData.typeNumber && !lastSelectedEquipmentRow.typeNumber) ||
            (componentToAdd !== addComponentModalPossibleComponents.CABLE &&
              updateData.serialNumber &&
              !lastSelectedEquipmentRow.serialNumber))
        ) {
          const newEquipmentData = {
            ...lastSelectedEquipmentRow,
          }
          if (lastSelectedEquipmentRow.typeNumber !== updateData.typeNumber) {
            newEquipmentData.typeNumber = updateData.typeNumber
            const component = await fetchComponentByType(updateData.typeNumber)
            if (component?.family === componentToAdd) {
              newEquipmentData.typeName = component?.name || newEquipmentData.typeName || ''
            } else {
              newEquipmentData.typeName = newEquipmentData.typeName || ''
            }
          }
          if (componentToAdd !== addComponentModalPossibleComponents.CABLE) {
            newEquipmentData.serialNumber = updateData.serialNumber
          }
          requestPromises.push(
            storeEquipmentItem(newEquipmentData, true).then(newEquipmentItem => {
              refetch()
              return newEquipmentItem
            }),
          )
        }
        await Promise.all(requestPromises)
      } catch (e) {
        showErrorToast(_(t.systemWasUnableToUpdateComponents))
        return
      }
      if (isChainUpdated) {
        showInfoToast(_(t.componentsOfChannelChannelIndexChanged, { channelIndex: channelIndex + 1 }))
      }
      closeModalCallback()
    },
    [lastSelectedEquipmentRow, activeTab, componentToAdd, channelIndex, dispatch, closeModalCallback, _, refetch],
  )

  return (
    <SbModal
      width={900}
      height="auto"
      className="add-component-modal"
      onClickOverlay={closeModalCallback}
      Header={
        /* TODO: Rewrite this to just title (contents of h1 as fragment) when <SbModalHeader /> supports node instead of just string */
        <div className="sb-modal-header fl-align-items-center">
          <h1 data-testid="modal-heading">
            <span className="component-part">
              {componentToAdd === addComponentModalPossibleComponents.SENSOR ? _(t.addSensor) : _(t.addCable)}
            </span>
            <span className="channel-identifier-part m-l-20">
              {_(t.channelChannelIndex, { channelIndex: channelIndex + 1 })}
            </span>
          </h1>
          <div className="close-modal" onClick={closeModalCallback}>
            <i className="sbi-toast-close" />
          </div>
        </div>
      }
      Footer={
        <div className="add-component-modal-footer fl-container m-t-10">
          <SbButton
            className="primary add-component-submit margin-left-auto"
            disabled={isLoading}
            loading={isSubmitting}
            onClick={async () => {
              if (!isSubmitting) {
                setIsSubmitting(true)
                await submitChainFactory(chainItem)(dataToStore)
                setIsSubmitting(false)
              }
            }}
          >
            {_(t.save)}
          </SbButton>
          <SbButton className="add-component-cancel m-l-20" onClick={closeModalCallback}>
            {_(t.cancel)}
          </SbButton>
        </div>
      }
    >
      <SbLightTabs onTabChange={tabId => setActiveTab(tabId)}>
        <SbLightTabsTab active id={addComponentModalTabs.EQUIPMENT_TAB}>
          <SbLightTabsTitle>
            <p>{_(t.myEquipment)}</p>
          </SbLightTabsTitle>
          <SbLightTabsContent>
            <AddComponentModalEquipmentTable
              deviceId={deviceId}
              moduleIndex={moduleIndex}
              channelIndex={channelIndex}
              componentToAdd={componentToAdd}
              onSelectionChange={(row, setSelectedRow) => {
                setLastSelectedEquipmentRow(row)
                if (row) {
                  if (componentToAdd === addComponentModalPossibleComponents.SENSOR) {
                    setDataToStore({
                      typeNumber: row.typeNumber,
                      serialNumber: row.serialNumber,
                    })
                    if (!row.typeNumber || !row.serialNumber) {
                      setShowMissingDataForm(true)
                    } else {
                      setSelectedRow(row)
                    }
                  } else {
                    setDataToStore({ typeNumber: row.typeNumber })
                    if (!row.typeNumber) {
                      setShowMissingDataForm(true)
                    } else {
                      setSelectedRow(row)
                    }
                  }
                } else {
                  setDataToStore(null)
                }
              }}
            />
          </SbLightTabsContent>
        </SbLightTabsTab>
        <SbLightTabsTab id={addComponentModalTabs.CATALOG_TAB}>
          <SbLightTabsTitle>
            <p>{_(t.addFromCatalog)}</p>
          </SbLightTabsTitle>
          <SbLightTabsContent>
            <AddComponentModalCatalogTable
              componentToAdd={componentToAdd}
              onSelectionChange={(row, setSelectedRow) => {
                if (row) {
                  setDataToStore({
                    typeNumber: row.kistlerType,
                  })
                  if (componentToAdd === addComponentModalPossibleComponents.SENSOR) {
                    setShowMissingDataForm(true)
                  } else {
                    setSelectedRow(row)
                  }
                } else {
                  setDataToStore(null)
                }
              }}
            />
          </SbLightTabsContent>
        </SbLightTabsTab>
      </SbLightTabs>
      {showMissingDataForm && (
        <AddComponentModalMissingData
          existingData={dataToStore}
          componentToAdd={componentToAdd}
          onSubmit={submitChainFactory(chainItem)}
          onCancel={() => setShowMissingDataForm(false)}
        />
      )}
    </SbModal>
  )
}

AddComponentModal.propTypes = {
  deviceId: PropTypes.string.isRequired,
  moduleIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  channelIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  componentToAdd: PropTypes.oneOf(Object.values(addComponentModalPossibleComponents)).isRequired,
}
