import { getEntries, getEntryById } from '@/fleet-configuration/data-fleet/entries/entries-selectors'

export const getDevicesLastState = state => getEntries(state, 'entries.devicesLastState')

export const getDeviceLastStateById = (state, deviceId) => getEntryById(state, 'entries.devicesLastState', deviceId)

export const getProjectDevicePreviousNTPServer = (state, deviceId) => {
  const deviceLastState = getDeviceLastStateById(state, deviceId)
  return deviceLastState?.clockSettings?.ntp?.servers?.[0]
}
