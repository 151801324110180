// TODO: somehow axios does not register with eslint - triggering error (even it it is node_modules hard dependency). Find out why
// eslint-disable-next-line import/no-unresolved
import axios from 'axios'
import { omit } from 'lodash'
import { batchActions } from 'redux-batched-actions'
import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { getActiveProjectId } from '@/fleet-configuration/data-fleet/projects/projects-selectors'
import {
  getChannelConnections,
  getProjectConnections,
  getProjectConnectionsBySourceComponentId,
} from './connections-selectors'
import { getProjectDeviceModuleById } from '../project-devices/project-devices-selectors'

export const REMOVE_ALL_CONNECTIONS = getActionName('REMOVE_ALL_CONNECTIONS')

export const removeAllConnections = () => createAction(REMOVE_ALL_CONNECTIONS)

export const SET_CONNECTION = getActionName('SET_CONNECTION')

export const setConnection = connection =>
  createAction(SET_CONNECTION, { ...connection, id: connection.connectionId || connection.id })

export const REMOVE_CONNECTION = getActionName('REMOVE_CONNECTION')

export const removeConnection = connection => createAction(REMOVE_CONNECTION, connection)

export const loadActiveProjectConnections = () => async (/* dispatch, getState */) => {
  /*
  const projectId = getActiveProjectId(getState())
  const { data: connections } = await axios.get(`/api/chain/connections?projectId=${encodeURIComponent(projectId)}`)
  const actions = [removeAllConnections(), ...connections.map(connection => setConnection(connection))]
  dispatch(batchActions(actions))
  */
}

const addConnectionToActiveProject = async (newConnection, dispatch, getState) => {
  const projectId = getActiveProjectId(getState())
  const postData = {
    projectId,
    ...newConnection,
  }
  delete postData.id
  const {
    headers: { location },
  } = await axios.post('/api/chain/connections', postData)
  const { data: connection } = await axios.get(location)
  dispatch(setConnection(connection))
  return connection
}

export const createConnectionFromExistingComponents = (existingSource, existingTarget) => (dispatch, getState) => {
  if (existingSource.deviceId) {
    const existingConnection = getProjectConnections(getState()).find(
      ({ source, target }) =>
        !target &&
        source.deviceId === existingSource.deviceId &&
        source.deviceResourcePath === existingSource.deviceResourcePath,
    )
    if (existingConnection) {
      const newConnection = {
        ...existingConnection,
        source: existingSource,
        target: existingTarget,
      }
      dispatch(batchActions([removeConnection(existingConnection), setConnection(newConnection)]))
      return axios.put(`/api/chain/connections/${existingConnection.id}`, newConnection)
    }
  }
  return addConnectionToActiveProject({ source: existingSource, target: existingTarget }, dispatch, getState)
}

export const createDefaultEnvVars = channel => {
  const channelMeasurementOptions = channel.getOptions('measurementRange')
  const range = {
    type: 'measurement',
    low: null,
    high: null,
    unit: channelMeasurementOptions[0] && channelMeasurementOptions[0].value,
  }
  const rangeUI = {
    type: 'measurementUI',
    low: null,
    high: null,
    unit: channelMeasurementOptions[0] && channelMeasurementOptions[0].value,
  }
  const temperature = {
    type: 'temperature',
    low: null,
    high: null,
    unit: '°C',
  }
  const frequency = {
    type: 'frequency',
    low: null,
    high: null,
    unit: 'Hz',
  }
  return [range, rangeUI, temperature, frequency]
}
export const initChannelConnection = () => () => {}
/*
export const initChannelConnection = channel => async (dispatch, getState) => {
  const state = getState()
  if (!getProjectConnections(state).length) {
    await dispatch(loadActiveProjectConnections())
  }
  if (!getChannelConnections(state, channel).length) {
    return addConnectionToActiveProject(
      {
        source: {
          deviceId: channel.getDeviceId(),
          deviceResourcePath: channel.resourcePath,
        },
      },
      dispatch,
      getState,
    )
  }
  return Promise.resolve()
}
 */

export const updateTargetConnectionToActiveProject =
  (connectionId, targetComponentId, targetSlot) => async (dispatch, getState) => {
    const state = getState()
    const connections = getProjectConnections(state)
    const oldConnection = connections.find(connection => connection.id === connectionId)
    if (!oldConnection) {
      console.error('Cannot update connection that does not exist', connectionId)
      return
    }
    const newConnection = {
      ...oldConnection,
      target: {
        componentId: targetComponentId,
        slot: targetSlot,
      },
    }
    delete newConnection.id
    await axios.put(`/api/chain/connections/${connectionId}`, newConnection)
    // update connection in state
    dispatch(batchActions([removeConnection(oldConnection), setConnection(newConnection)]))
  }

export const addFromDeviceConnectionToActiveProject =
  (deviceId, moduleId, channelId, targetComponentId, targetSlot) => async (dispatch, getState) => {
    const state = getState()
    const module = getProjectDeviceModuleById(state, deviceId, moduleId)
    const channel = module.channels[channelId]

    const existingConnection = getChannelConnections(state, channel)[0]
    if (existingConnection && !existingConnection.target) {
      await dispatch(updateTargetConnectionToActiveProject(existingConnection.id, targetComponentId, targetSlot))
      return
    }

    await addConnectionToActiveProject(
      {
        source: {
          deviceId,
          deviceResourcePath: channel.resourcePath,
        },
        target: {
          componentId: targetComponentId,
          slot: targetSlot,
        },
      },
      dispatch,
      getState,
    )
    const nextConnection = getProjectConnectionsBySourceComponentId(state, targetComponentId, false, targetSlot)[0]
    if (nextConnection) {
      const updatedNextConnection = {
        ...omit(nextConnection, ['id']),
      }
      await axios.put(`/api/chain/connections/${nextConnection.id}`, updatedNextConnection)
      dispatch(batchActions([removeConnection(nextConnection), setConnection(updatedNextConnection)]))
    }
  }

export const addComponentConnectionToActiveProject =
  (sourceComponentId, sourceSlot, targetComponentId, targetSlot) => (dispatch, getState) => {
    const state = getState()
    const deviceConnections = getProjectConnections(state).filter(
      conn => conn.target && conn.target.componentId === sourceComponentId,
    )
    // 3 - 1 cables don't need to be fully added to all channels before someone decides to add sensor
    const deviceConnection = deviceConnections.find(conn => conn.slot === sourceSlot) || deviceConnections[0]

    if (!deviceConnection) {
      throw new Error('Cannot add component connection if device connection does not exist')
    }

    return addConnectionToActiveProject(
      {
        source: {
          componentId: sourceComponentId,
          slot: sourceSlot,
        },
        target: {
          componentId: targetComponentId,
          slot: targetSlot,
        },
      },
      dispatch,
      getState,
    )
  }

export const removeConnectionFromActiveProject = connection => dispatch => {
  if (connection.source.deviceId) {
    const newConnection = omit(connection, ['target', 'id'])
    dispatch(batchActions([removeConnection(connection), setConnection(newConnection)]))
    return axios.put(`/api/chain/connections/${connection.id}`, newConnection)
  }
  dispatch(removeConnection(connection))
  return axios.delete(`/api/chain/connections/${connection.connectionId}`)
}

export const storeChainRanges = channel => (dispatch, getState) => {
  const state = getState()
  const module = getProjectDeviceModuleById(state, channel.getDeviceId(), channel.getModuleIndex())
  const connectionChain = getProjectConnectionsBySourceComponentId(
    state,
    module.componentId,
    true,
    channel.getChannelIndex(),
  )

  return Promise.all(
    connectionChain.map(({ id, ...connection }) => axios.put(`/api/chain/connections/${id}`, connection)),
  )
}
