import PropTypes from 'prop-types'

import { notificationTypes } from './constants'

const { ERROR, WARNING, SUCCESS, INFO } = notificationTypes

export const notificationShape = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.oneOf([ERROR, WARNING, SUCCESS, INFO]),
  time: PropTypes.instanceOf(Date),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, // translate object
  ]),
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, // translate object
  ]),
  payload: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  redirectLocation: PropTypes.string,
  visited: PropTypes.bool,
})
