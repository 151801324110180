import React from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbDropdown } from 'skybase-ui/skybase-components/sb-dropdown'
import { SbCheckbox } from 'skybase-ui/skybase-components/sb-checkbox/sb-checkbox'
import { SbInlineMessage } from 'skybase-ui/skybase-components/sb-inline-message/sb-inline-message'
import { INLINE_MESSAGE_TYPE_WARNING } from 'skybase-ui/skybase-components/sb-inline-message/constants'
import { withRouter } from '@/common/router'
import { getDropdownLabelByValue } from '@/utils'
import { LabeledField } from '@/fleet-configuration/components/form/labeled-field'
import { messages as t } from '@/fleet-configuration/page-components/module/module-detail-i18n'
import { TextBox } from '@/fleet-configuration/components/text-box/text-box'
import { required } from '@/fleet-configuration/validation/validators'
import { Label } from '@/fleet-configuration/components/form/label'
import { SwitchingControl } from '@/fleet-configuration/components/switching-control/switching-control'
import { ValidationGroup } from '@/fleet-configuration/components/form/validation-group'
import {
  saveProjectDevice,
  setDeviceAlerts,
  setProjectDeviceModule,
  setProjectDeviceModuleDataSource,
} from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { selectMeasurementUnitParams } from '@/fleet-configuration/select-measurement-unit-params'
import { getProjectDeviceById } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import { isValid } from '@/fleet-configuration/validation/is-valid'
import {
  wizardNavigationSelection,
  urlPathController,
} from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation-constants'

const { SELECTED, UNSELECTED } = wizardNavigationSelection

class _ModuleDetailContent extends React.Component {
  static propTypes = {
    module: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    moduleIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    unitId: PropTypes.string.isRequired,
    snippetPathPrefix: PropTypes.string.isRequired,
    isValidDevice: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    device: PropTypes.object,
    intl: intlShape.isRequired,
    computeDirtyState: PropTypes.bool,
    paramsSelected: PropTypes.object,
    paramSelectionChange: PropTypes.func,
  }

  static defaultProps = {
    module: null,
    device: null,
    isValidDevice: false,
    computeDirtyState: true,
    paramsSelected: {},
    paramSelectionChange: null,
  }

  handleParamsSelectionChange = (checked, name) => {
    const { paramSelectionChange } = this.props
    const writeValue = checked ? SELECTED : UNSELECTED
    paramSelectionChange(writeValue, name)
  }

  handleOnChange = spec => {
    const { dispatch, module, unitId, moduleIndex } = this.props
    const updatedModule = update(module, spec)
    dispatch(setProjectDeviceModule(unitId, moduleIndex, updatedModule))
  }

  handleOnParameterChange = (field, value) => {
    const { dispatch, module, unitId, moduleIndex } = this.props
    const updatedModule = update(module, {
      parameters: { [field]: { $set: value } },
    })
    dispatch(setProjectDeviceModule(unitId, moduleIndex, updatedModule))
  }

  handleOnSamplingRateChange = value => {
    const { dispatch, unitId, moduleIndex } = this.props
    const updatedDevice = dispatch(setProjectDeviceModuleDataSource(unitId, moduleIndex, value))
    const validationResults = updatedDevice.validate()
    dispatch(setDeviceAlerts(updatedDevice.id, validationResults))
  }

  handleOnBlur = () => {
    this.saveDevice()
  }

  handleOnSamplingRateBlur = async () => {
    await this.saveDevice()
    dispatchEvent(new CustomEvent('ModuleSamplingRateChanged'))
  }

  saveDevice = () => {
    const { dispatch, device, isValidDevice } = this.props
    if (isValidDevice) {
      return dispatch(saveProjectDevice(device))
    }
    return Promise.resolve()
  }

  renderMainsRejection() {
    const {
      module,
      computeDirtyState,
      paramsSelected,
      paramSelectionChange,
      snippetPathPrefix,
      intl: { formatMessage: _ },
    } = this.props

    if (!module.getModuleConfigurationOptions('mainsRejection')) {
      return null
    }

    const options = module.getOptions('mainsRejection', true)
    return (
      <Label
        title={
          <>
            {paramSelectionChange && (
              <SbCheckbox
                onClick={evt =>
                  this.handleParamsSelectionChange(evt.target.checked, `${snippetPathPrefix}parameters.mainsRejection`)
                }
                checked={paramsSelected[`${snippetPathPrefix}parameters.mainsRejection`] === SELECTED}
              />
            )}
            {_(t.mainsRejection)}
          </>
        }
        className="test-mains-rejection"
        isDirty={computeDirtyState && module.isDirty('parameters', 'mainsRejection')}
      >
        <SbDropdown
          value={module.parameters.mainsRejection}
          title={getDropdownLabelByValue(options, module.parameters.mainsRejection)}
          items={options}
          disabled={!!paramSelectionChange}
          className="sb-width-ml"
          onChange={value => this.handleOnParameterChange('mainsRejection', value)}
          onBlur={this.handleOnBlur}
        />
      </Label>
    )
  }

  renderInputLevelFields(inputLevels = []) {
    const {
      module,
      computeDirtyState,
      paramSelectionChange,
      snippetPathPrefix,
      paramsSelected,
      intl: { formatMessage: _ },
    } = this.props

    return (
      <>
        {inputLevels.map(inputLevel => {
          const inputLevelOptions = module.getOptions(inputLevel)
          const inputLevelValue = module.parameters[inputLevel]
          return (
            <Label
              title={
                <>
                  {paramSelectionChange && (
                    <SbCheckbox
                      onClick={evt =>
                        this.handleParamsSelectionChange(
                          evt.target.checked,
                          `${snippetPathPrefix}parameters.${inputLevel}`,
                        )
                      }
                      checked={paramsSelected[`${snippetPathPrefix}parameters.${inputLevel}`] === SELECTED}
                    />
                  )}
                  {inputLevels.length === 1 ? _(t.inputLevelSingle) : _(t[inputLevel])}
                </>
              }
              className={`test-module-${inputLevel}`}
              isDirty={computeDirtyState && module.isDirty('parameters', inputLevel)}
              key={inputLevel}
            >
              <SbDropdown
                title={getDropdownLabelByValue(inputLevelOptions, inputLevelValue)}
                value={inputLevelValue}
                options={inputLevelOptions}
                className="sb-width-ml"
                onChange={value => this.handleOnParameterChange(inputLevel, value)}
                onBlur={this.handleOnBlur}
              />
            </Label>
          )
        })}
      </>
    )
  }

  render() {
    const {
      module,
      moduleIndex,
      device,
      computeDirtyState,
      paramsSelected,
      paramSelectionChange,
      snippetPathPrefix,
      intl: { formatMessage: _ },
    } = this.props

    if (!module) {
      return null
    }
    const maxSamplingRate = module.getMaxSamplingRate()
    const { samplingRate } = module
    const { tare: tareObj } = module.deviceSpecific || {}
    const moduleType = module.getType() || null
    const samplingRateOptions = (device.getSamplingRateOptions() || []).map(
      ({ value, rawDescription, rawDescriptionValues }) => ({
        value,
        label: _(rawDescription, rawDescriptionValues),
      }),
    )

    return (
      <ValidationGroup name={`Module-${moduleIndex}`}>
        {module.isSupported() && (
          <>
            {'name' in module ? (
              <LabeledField
                id="name"
                source="name"
                isDirty={computeDirtyState && module.isDirty('name')}
                label={
                  <>
                    {paramSelectionChange && (
                      <SbCheckbox
                        onClick={evt =>
                          this.handleParamsSelectionChange(evt.target.checked, `${snippetPathPrefix}name`)
                        }
                        checked={paramsSelected[`${snippetPathPrefix}name`] === SELECTED}
                      />
                    )}
                    {_(t.name)}
                  </>
                }
                labelClassName="test-module-name"
                className="sb-width-ml"
                disabled={!!paramSelectionChange}
                Component={TextBox}
                value={module.name}
                onChange={e => this.handleOnChange({ name: { $set: e.target.value } })}
                onBlur={this.handleOnBlur}
                validators={[required(t.nameIsRequired)]}
              />
            ) : (
              moduleType && (
                <Label title={_(t.moduleType)} className="test-module-type">
                  {moduleType}
                </Label>
              )
            )}
            {!module.isController() && samplingRateOptions.length > 1 && module.channels.length && (
              <>
                <LabeledField
                  id="samplingRateCategory"
                  source="samplingRateCategory"
                  isDirty={
                    computeDirtyState &&
                    (module.isDirty('samplingRate') || module.isDirty('deviceSpecific', 'samplingRate')) &&
                    !!module.getActiveChannels().length
                  }
                  label={
                    <>
                      {paramSelectionChange && (
                        <SbCheckbox
                          onClick={evt =>
                            this.handleParamsSelectionChange(evt.target.checked, `${snippetPathPrefix}samplingRate`)
                          }
                          checked={paramsSelected[`${snippetPathPrefix}samplingRate`] === SELECTED}
                        />
                      )}
                      {_(t.samplingRate)}
                    </>
                  }
                  disabled={!!paramSelectionChange || !samplingRate}
                  labelClassName="test-module-sampling-rate"
                  className="sb-width-ml dropdown"
                  Component={SbDropdown}
                  items={samplingRate ? samplingRateOptions : []}
                  value={samplingRate}
                  title={getDropdownLabelByValue(samplingRateOptions, samplingRate)}
                  onChange={value => this.handleOnSamplingRateChange(value)}
                  onBlur={this.handleOnSamplingRateBlur}
                />
                {module.getSamplingRateValue() > maxSamplingRate && (
                  <div className="label-alerts">
                    <SbInlineMessage
                      type={INLINE_MESSAGE_TYPE_WARNING}
                      title={_(t.samplingRate)}
                      message={_(t.maxSamplingRateOnThisModuleIs, { maxSamplingRate })}
                    />
                  </div>
                )}
              </>
            )}
            {tareObj && (
              <Label
                className="test-module-tare"
                title={
                  <>
                    {paramSelectionChange && (
                      <SbCheckbox
                        onClick={evt =>
                          this.handleParamsSelectionChange(
                            evt.target.checked,
                            `${snippetPathPrefix}deviceSpecific.tare`,
                          )
                        }
                        checked={paramsSelected[`${snippetPathPrefix}deviceSpecific.tare`] === SELECTED}
                      />
                    )}
                    {_(t.tareFunctionality)}
                  </>
                }
                isDirty={computeDirtyState && module.isDirty('deviceSpecific', 'tare', 'manual', 'enabled')}
              >
                <SwitchingControl
                  id="tare"
                  checked={tareObj.manual.enabled}
                  disabled={!!paramSelectionChange}
                  onChange={value =>
                    this.handleOnChange({
                      deviceSpecific: { tare: { manual: { enabled: { $set: value } } } },
                    })
                  }
                  onBlur={this.handleOnBlur}
                  textTrue={_(t.on)}
                  textFalse={_(t.off)}
                />
              </Label>
            )}
            {this.renderInputLevelFields(module.getInputLevelNames())}
            {this.renderMainsRejection()}
          </>
        )}
        {!module.isSupported() && <span>{_(t.thisModuleIsNotSupportedInCurrentSoftwareVersion)}</span>}
      </ValidationGroup>
    )
  }
}

const moduleDetailContentSelector = (state, props) => {
  const { unitId, moduleIndex } = selectMeasurementUnitParams(state, props)
  const device = props.measurementDevice || getProjectDeviceById(state, unitId)
  const module = moduleIndex === urlPathController ? device.deviceSpecific.controller : device.modules[moduleIndex]
  const isValidDevice = !!props.paramSelectionChange || isValid(state, `Device-${unitId}`)
  return {
    moduleIndex,
    module,
    unitId,
    isValidDevice,
    device,
    snippetPathPrefix: `$.${
      moduleIndex === urlPathController ? 'deviceSpecific.controller' : `modules[${moduleIndex}]`
    }.`,
  }
}

export const ModuleDetailContent = withRouter(injectIntl(connect(moduleDetailContentSelector)(_ModuleDetailContent)))
