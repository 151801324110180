import { getDefaultModalState } from 'skybase-ui/skybase-components/sb-modal/sb-modal-utils'
import { MESSAGE_MODAL_ID } from 'skybase-ui/skybase-components/sb-message-modal/constants'
import { MODAL_OPEN } from 'skybase-ui/skybase-core/base/actions'

import { STATE_KEY_NAME } from './constants'

export const modalsStateKeyName = STATE_KEY_NAME

// It's here just temporarily, until SkyBase fix it.
const defaultMessageModalState = {
  title: '',
  message: '',
  translate: false,
}

/**
 * @description
 * Returns a default state for the reducer.
 *
 * @returns {Object}
 */
const getDefaultState = () => ({
  currentModalId: null,
  modals: {
    ERROR: getDefaultModalState(),
    WARN: getDefaultModalState(),
    INFO: getDefaultModalState(),
    [MESSAGE_MODAL_ID]: defaultMessageModalState,
  },
})

/**
 * @description
 * Handles all actions related to modal dialogs.
 *
 * @param state
 * @param action
 *
 * @returns {Object}
 */
export const modalReducer = (state = getDefaultState(), action = {}) => {
  const { type } = action
  const { modalId } = action.payload || {}

  const { modals } = state

  if (type === MODAL_OPEN) {
    const newModals = modalId !== null ? { ...modals, [modalId]: action.payload } : getDefaultState()
    return {
      ...state,
      modals: newModals,
      currentModalId: modalId,
    }
  }

  return state
}
