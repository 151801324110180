import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { omit } from 'lodash'
import { connect } from 'react-redux'
import { SbTextbox } from 'skybase-ui/skybase-components/sb-textbox/sb-textbox'
import { setAlerts } from '@/fleet-configuration/validation/actions'
import { getControlId } from '@/fleet-configuration/components/get-control-id'
import { getFlattenedAlerts } from '@/fleet-configuration/components/form/get-flattened-alerts'
import { ComponentValidator } from '@/fleet-configuration/components/component-validator'
import { ComponentCommitter } from '@/fleet-configuration/components/component-committer/component-committer'
import './text-box.scss'

class _TextBox extends React.Component {
  static defaultProps = {
    validators: [],
    dependentValidators: [],
    id: '',
    className: '',
    type: '',
    clearAlertsOnUnmount: false,
    getValidationRef: () => {},
    onChange: () => {},
    onValueChange: () => {},
    onCommit: null,
    onBlur: null,
    value: '',
  }

  static propTypes = {
    validators: PropTypes.arrayOf(PropTypes.func),
    dispatch: PropTypes.func.isRequired,
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    validation: PropTypes.object.isRequired,
    className: PropTypes.string,
    type: PropTypes.string,
    clearAlertsOnUnmount: PropTypes.bool,
    getValidationRef: PropTypes.func,
    dependentValidators: PropTypes.arrayOf(PropTypes.func),
    onChange: PropTypes.func,
    onValueChange: PropTypes.func,
    onCommit: PropTypes.func,
    onBlur: PropTypes.func,
  }

  static contextTypes = {
    formNames: PropTypes.arrayOf(PropTypes.string),
    registerValidator: PropTypes.func,
    unRegisterValidator: PropTypes.func,
  }

  constructor(props, context) {
    super(props, context)
    this.validator = new ComponentValidator(this)
    this.committer = new ComponentCommitter(this)
  }

  componentDidMount() {
    this.validator.componentDidMount()
  }

  componentWillUnmount() {
    const { id, dispatch, clearAlertsOnUnmount } = this.props
    if (clearAlertsOnUnmount) {
      dispatch(setAlerts([], getControlId(this.context, id)))
    }
    this.validator.componentWillUnmount()
  }

  handleOnChange = (event, ...args) => {
    const { onChange } = this.props
    onChange(event)
    this.validator.handleOnChange()
    this.committer.handleOnChange(event, ...args)
  }

  handleOnValueChange = values => {
    const { onValueChange } = this.props
    const event = { target: { value: values.value }, values }
    onValueChange(event)
    this.validator.handleOnChange()
    this.committer.handleOnChange(event)
  }

  formatUnsignedNumber = value => value.replace('-', '')

  isUnsignedNumber = () => {
    const { type } = this.props
    return type === 'unsigned-number'
  }

  render() {
    const { id, value, validation, className, type } = this.props
    const alerts = getFlattenedAlerts(this.context, validation, id)
    const controlId = getControlId(this.context, id)

    const numberTypeParams = { type }
    if (this.isUnsignedNumber() || type === 'number') {
      numberTypeParams.type = 'number'
      numberTypeParams.onValueChange = this.handleOnValueChange
    }
    return (
      <SbTextbox
        spellCheck="false"
        autoComplete="off"
        maxLength={32}
        onValidate={this.validator.handleOnValidate}
        onValidated={this.validator.handleOnValidated}
        format={this.isUnsignedNumber() ? this.formatUnsignedNumber : undefined}
        {...omit(this.props, [
          'dispatch',
          'validation',
          'validators',
          'getValidationRef',
          'dependentValidators',
          'onCommit',
          'clearAlertsOnUnmount',
          'onValueChange',
        ])}
        {...numberTypeParams}
        onChange={this.handleOnChange}
        onBlur={this.committer.handleOnBlur}
        className={classNames(className, 'text-box', {
          invalid: alerts.length,
        })}
        id={controlId instanceof Array ? controlId.join('') : controlId}
        // TODO: workaround due to skybase can't handle numeric values properly
        // move this fix to skybase
        value={value === 0 ? '0' : value}
      />
    )
  }
}

const selectTextBox = state => ({
  validation: state.validation,
})

export const TextBox = connect(selectTextBox)(_TextBox)
