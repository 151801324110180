import React from 'react'
import PropTypes from 'prop-types'
// TODO: somehow axios does not register with eslint - triggering error (even it it is node_modules hard dependency). Find out why
// eslint-disable-next-line import/no-unresolved
import axios from 'axios'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { debounce } from 'lodash'
import { getStudioAPIHost } from '@/utils/url'

class _ChannelConnectionScheme extends React.Component {
  static propTypes = {
    src: PropTypes.string,
    mimeType: PropTypes.string,
  }

  static defaultProps = {
    src: undefined,
    mimeType: undefined,
  }

  constructor(props) {
    super(props)
    this.updateConnectionImageDebounced = debounce(this.updateConnectionImage, 500)
    this.state = { imgBase64: undefined }
  }

  async componentDidMount() {
    await this.updateConnectionImage()
  }

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { src: prevSrc, mimeType: prevMimeType } = this.props
    const { src: newSrc, mimeType: newMimeType } = nextProps

    if (prevSrc !== newSrc || prevMimeType !== newMimeType) {
      // prevent crash when decoding image in an incorrect way
      if (prevMimeType !== newMimeType) {
        // do not use setState as that would create new render loop call (which we do not need)
        this.state.imgBase64 = undefined
      }
      await this.updateConnectionImageDebounced()
    }
  }

  clearConnectionImage = () => {
    this.setState({ imgBase64: undefined })
  }

  updateConnectionImage = async () => {
    const { src } = this.props

    if (!src) {
      this.clearConnectionImage()
      return
    }

    const { data, status } = await axios.get(`${getStudioAPIHost()}/api/components/images/${src}`, {
      cache: true,
    })

    if (!data || status === 404) {
      this.clearConnectionImage()
      return
    }

    this.setState({ imgBase64: data.base64 })
  }

  base64ToUnicode = str =>
    // Bytestream -> percent-encoding -> original unicode string
    decodeURIComponent(
      atob(str)
        .split('')
        .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    )

  render() {
    const { imgBase64 } = this.state
    const { mimeType } = this.props
    if (imgBase64 && mimeType) {
      if (mimeType === 'image/svg+xml') {
        // eslint-disable-next-line react/no-danger
        return <span dangerouslySetInnerHTML={{ __html: this.base64ToUnicode(imgBase64) }} />
      }
      return <img src={`data:${mimeType};base64,${imgBase64}`} alt="" />
    }
    return null
  }
}

export const ChannelConnectionScheme = injectIntl(connect()(_ChannelConnectionScheme))
