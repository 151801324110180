/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  snippetWasSuccessfullyImported: {
    id: 'importPreset.snippetWasSuccessfullyImported',
    defaultMessage: 'Snippet was successfully imported',
  },
  snippetFromSelectedFileIsAlreadyPartOfTheSystem: {
    id: 'importPreset.snippetFromSelectedFileIsAlreadyPartOfTheSystemImportWasCanceled',
    defaultMessage: 'Snippet from selected file is already part of the system. Import was canceled',
  },
  theSystemWasUnableToFindSelectedSnippet: {
    id: 'importPreset.theSystemWasUnableToFindSelectedSnippet',
    defaultMessage: 'The system was unable to find selected snippet. Import was canceled',
  },
  theContentOfTheExistingSnippetSnippetWillBeOverridden: {
    id: 'importPreset.theContentOfTheExistingSnippetSnippetWillBeOverriddenByContentFromTheSelectedFile',
    defaultMessage:
      'The content of the existing snippet "{snippet}" will be overridden by content from the selected file',
  },
  updateExistingSnippet: {
    id: 'importPreset.updateExistingSnippet',
    defaultMessage: 'Update existing snippet?',
  },
  import: {
    id: 'importPreset.import',
    defaultMessage: 'Import',
  },
  theImportedFileIsMalformed: {
    id: 'importPreset.theConfigurationSnippetFileHasNotBeenImportedTheImportedFileIsMalformed',
    defaultMessage: 'The configuration snippet "{file}" has not been imported - the imported file is malformed',
  },
  theImportedFileIsNotSupported: {
    id: 'importPreset.theConfigurationSnippetFileHasNotBeenImportedTheImportedFileIsNotSupported',
    defaultMessage: 'The configuration snippet "{file}" has not been imported - the imported file is not supported',
  },
})
