import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { getProjectDeviceModuleById } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import { MeasurementButtons } from '@/fleet-configuration/pages/measurement-screen/measurement-buttons/measurement-buttons'
import { UnitIllustration } from '@/fleet-configuration/page-components/unit-illustration/unit-illustration'
import { ConfigScreenLayout } from '@/fleet-configuration/page-components/config-screen-layout/config-screen-layout'
import { selectMeasurementUnitParams } from '@/fleet-configuration/select-measurement-unit-params'
import { ModuleDetailContent } from '@/fleet-configuration/page-components/module/module-detail-content'
import { withRouter } from '@/common/router'
import { messages as t } from './module-detail-i18n'

const _ModuleDetail = ({ deviceId, intl: { formatMessage: _ }, module = null }) => {
  if (!module) {
    return null
  }

  let title
  if (module.isSupported()) {
    title = module.isController() ? _(t.controller) : _(t.module)
  } else {
    title = _(t.unsupportedModule)
  }
  return (
    <ConfigScreenLayout
      title={
        <>
          {title}
          <MeasurementButtons deviceId={deviceId} />
        </>
      }
      illustration={
        <>
          <p className="component-name">&quot;{`${module.name ? `${module.name} |` : ''} ${module.getType()}`}&quot;</p>
          <UnitIllustration />
        </>
      }
      parameters={<ModuleDetailContent />}
    />
  )
}

_ModuleDetail.propTypes = {
  deviceId: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  module: PropTypes.object,
}

const selectModuleDetail = (state, props) => {
  const { unitId, moduleIndex } = selectMeasurementUnitParams(state, props)
  const module = getProjectDeviceModuleById(state, unitId, moduleIndex)
  return {
    module,
    deviceId: unitId,
  }
}

export const ModuleDetail = withRouter(injectIntl(connect(selectModuleDetail)(_ModuleDetail)))
