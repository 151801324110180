import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

import { OAuth } from 'skybase-oauth/oauth'
import { SbLeftColumn, SB_LEFT_COLUMN_STATE_NAME } from 'skybase-ui/skybase-components/sb-left-column'

import { PoweredBy } from '@/common/poweredBy/poweredBy'
import { Tour } from '@/common/tour'
import { replaceDuplicateSlashes } from '@/utils'
import { Menu, getMenuStructure } from '@/common/menu'
import { Header } from '@/containers/header'

import './app.scss'

const _App = props => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { children } = props
  const { isHidden } = useSelector(state => state[SB_LEFT_COLUMN_STATE_NAME])
  const subMenuKey = pathname.split('/')[1] ?? ''
  const subMenu = getMenuStructure().find(menuItem => menuItem.url === subMenuKey)

  const currentUrl = pathname
  const isReady = true
  const hiddenLeftPanels = isHidden
  const hiddenInnerMenu = !!subMenu?.hiddenInnerMenu
  const trimCurrentUrl = replaceDuplicateSlashes(currentUrl, '')
  let hasSideMenu = false

  if (trimCurrentUrl !== '') {
    hasSideMenu =
      getMenuStructure().findIndex(menu => !menu.hiddenSideMenu && currentUrl.indexOf(menu.url) !== -1) !== -1
  }

  // Hide the side menu whenever we are in the Local SDK or Local Backend Ownership mode.
  if (!OAuth.isInNormalMode) {
    hasSideMenu = false
  }

  return (
    <div className={classNames('fl-row stretch-app-page', { 'side-menu-visible': hasSideMenu && !hiddenLeftPanels })}>
      <SbLeftColumn isCollapsed className="dark">
        <Menu currentUrl={currentUrl} onMenuItemClick={navigate} isSideMenu />
      </SbLeftColumn>
      <div className="app-wrapper fl-container-column sb-width-100pct">
        <Header />
        <div className="app-content-container fl-row">
          {!hiddenLeftPanels && !hiddenInnerMenu && (
            <SbLeftColumn
              className={classNames({ 'is-home': currentUrl === '/', 'client-app': !OAuth.isInNormalMode })}
            >
              <Menu currentUrl={currentUrl} onMenuItemClick={navigate} className="dark" />
              <PoweredBy isHomepage={currentUrl === '/'} />
            </SbLeftColumn>
          )}
          <div className="content-page-container">{isReady && children}</div>
        </div>
      </div>

      {OAuth.isInLocalSDKMode && <Tour />}
    </div>
  )
}

_App.propTypes = {
  children: PropTypes.node.isRequired,
}

export default _App
