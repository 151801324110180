/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  restore: {
    id: 'backupConfigurationConfirm.restore',
    defaultMessage: 'Restore',
  },
  restoreConfiguration: {
    id: 'backupConfigurationConfirm.restoreConfiguration',
    defaultMessage: 'Restore Configuration?',
  },
  areYouSureYouWantToRestoreConfiguration: {
    id: 'backupConfigurationConfirm.areYouSureYouWantToRestoreConfiguration',
    defaultMessage: 'Are you sure you want to restore configuration?',
  },
  thisActionCannotBeUndone: {
    id: 'backupConfigurationConfirm.thisActionCannotBeUndone',
    defaultMessage: 'This action cannot be undone.',
  },
  dontForgetToUseWriteSettingsToDevice: {
    id: 'backupConfigurationConfirm.dontForgetToUseWriteSettingsToDevice',
    defaultMessage: 'Don\'t forget to use "Write Settings to Device"',
  },
  configurationWasSuccessfullyLoaded: {
    id: 'backupConfigurationConfirm.configurationWasSuccessfullyLoaded',
    defaultMessage: 'Configuration was successfully loaded',
  },
})
