import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  pageName: {
    id: 'licensing-management-page.pageName',
    defaultMessage: 'Management',
  },
  details: {
    id: 'licensing-management-page.details',
    defaultMessage: 'Details',
  },
  assignLicense: {
    id: 'licensing-management-page.assignLicense',
    defaultMessage: 'Assign License',
  },
  revokeLicense: {
    id: 'licensing-management-page.revokeLicense',
    defaultMessage: 'Revoke license',
  },
  licenseWasRevoked: {
    id: 'licensing-management-page.licenseWasRevoked',
    defaultMessage: 'License "{name}" was revoked successfully.',
  },
  revoke: {
    id: 'licensing-management-page.revoke',
    defaultMessage: 'Revoke',
  },
  updateLicenses: {
    id: 'licensing-management-page.updateLicenses',
    defaultMessage: 'Update licenses',
  },
  error: {
    id: 'licensing-management-page.error',
    defaultMessage: 'Error',
  },
  success: {
    id: 'licensing-management-page.success',
    defaultMessage: 'Success',
  },
  warning: {
    id: 'licensing-management-page.warning',
    defaultMessage: 'Warning',
  },
  cannotUpdateEmptyContainer: {
    id: 'licensing-management-page.cannotUpdateEmptyContainer',
    defaultMessage: 'Cannot call update on an empty container.',
  },
})
