/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  configurationOfDeviceDeviceNameAtCurrentTime: {
    id: 'backupActions.configurationOfDeviceDeviceNameAtCurrentTime',
    defaultMessage: 'Configuration of device "{DEVICE_NAME}" at {CURRENT_TIME}',
  },
  backedUpConfigurationNotCompatible: {
    id: 'backupActions.backedUpConfigurationIsNotCompatibleWithDeviceHardwareConfiguration',
    defaultMessage: 'Backed up configuration is not compatible with device hardware configuration',
  },
  backupRestorationFailed: {
    id: 'backupActions.backupRestorationFailed',
    defaultMessage: 'Backup restoration failed',
  },
})
