import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { SbNotFoundPage } from 'skybase-pages'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { Sb21Layout } from 'skybase-ui/skybase-components/layouts'
import { TenantsList, TenantTab, CreateTenantButton } from 'skybase-oauth/tenants/components'
import { withAcl, aclShape } from 'skybase-oauth/auth'
import { fetchTenant } from 'skybase-oauth/tenants/utils'
import { changeTenantListConfig } from 'skybase-oauth/tenants/actions'
import { oAuthPermissions } from 'skybase-oauth/oauth-permissions'

import { withRouter } from '@/common/router'
import { isValidGuid } from '@/utils'
import { messages as menuT } from '@/common/menu/menu-i18n'

import { messages as t } from './tenants-page-i18n'

export class _TenantsPage extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    acl: aclShape.isRequired,
    handleFetchTenant: PropTypes.func.isRequired,
    handleChangeTenantListConfig: PropTypes.func.isRequired,
    router: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      params: PropTypes.shape({
        tenantId: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      error: null,
    }
  }

  componentDidMount() {
    const {
      handleFetchTenant,
      router: {
        params: { tenantId },
      },
      acl: { read },
    } = this.props

    if (read && tenantId) {
      if (isValidGuid(tenantId)) {
        handleFetchTenant(tenantId, this.handleOnFetchError)
      } else {
        this.handleOnFetchError({ message: t.tenantNotFound, tenantId })
      }
    }
  }

  static getDerivedStateFromProps(props) {
    const {
      router: {
        params: { tenantId },
      },
    } = props

    if (!tenantId) {
      return { error: null }
    }

    return null
  }

  handleOnFetchError = ({ message, tenantId = null }) => {
    const {
      intl: { formatMessage: _ },
    } = this.props

    this.setState({ error: _(message, { tenantId }) })
  }

  handleOnManageAssignedTenantsClick = tenantId => {
    const {
      router: { navigate },
      handleChangeTenantListConfig,
    } = this.props

    // Reset the pageNumber to the first page
    handleChangeTenantListConfig({ pageNumber: 1, pageSize: 10, pageCount: 0 })

    navigate(`/identity-management/user-management/tenants/${tenantId}/assigned`)
  }

  renderError = message => {
    return <SbNotFoundPage {...this.props} message={message} />
  }

  render() {
    const { error } = this.state
    const {
      intl: { formatMessage: _ },
    } = this.props

    if (error) {
      return this.renderError(error)
    }

    return (
      <Sb21Layout
        title={_(t.pageName)}
        breadcrumbs={[
          {
            path: '/',
            title: _(menuT.home),
          },
          {
            path: '/identity-management',
            title: _(menuT.identityManagement),
          },
          {
            path: '/identity-management/user-management',
            title: _(menuT.userManagement),
          },
          _(t.pageName),
        ]}
      >
        <div>
          <div className="sb-heading fl-row fl-align-items-center">
            <h1>{_(t.pageName)}</h1>
            <CreateTenantButton />
          </div>
          <TenantsList />
        </div>
        <TenantTab onManageAssignedTenantsClick={this.handleOnManageAssignedTenantsClick} />
      </Sb21Layout>
    )
  }
}

const mapDispatchToProps = (dispatch, { intl: { formatMessage } }) => {
  return {
    handleFetchTenant: (tenantId, onError) => dispatch(fetchTenant(tenantId, formatMessage, onError)),
    handleChangeTenantListConfig: config => dispatch(changeTenantListConfig(config)),
  }
}

const withAclComponent = withAcl(_TenantsPage, {
  readPermissions: [oAuthPermissions.kiconnectTenantsRead],
})

export const TenantsPage = withRouter(injectIntl(connect(null, mapDispatchToProps)(withAclComponent)))
