/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  errorCalibrationData: {
    id: 'calibrationInformation.errorCalibrationData',
    defaultMessage: 'Calibration data are not reachable at this moment.{newLine}Please visit this page later.',
  },
  theCalibrationOfYourDeviceExpiredOrIsAboutToExpire: {
    id: 'calibrationInformation.theCalibrationOfYourDeviceExpiredOrIsAboutToExpireNewLineCalibrateYourDeviceToAchievePreciseMeasurements',
    defaultMessage:
      'The calibration of your device expired or is about to expire.{newLine}Calibrate your device to achieve precise measurements',
  },
  noCalibrationData: {
    id: 'calibrationInformation.noCalibrationData',
    defaultMessage: 'No calibration data',
  },
  expiry: {
    id: 'calibrationInformation.expiryDate',
    defaultMessage: 'expiry: {date}',
  },
  lastCalibrationDate: {
    id: 'calibrationInformation.lastCalibrationDate',
    defaultMessage: 'Last calibration date: {date}',
  },
  expiration: {
    id: 'calibrationInformation.expiration',
    defaultMessage: 'Expiration',
  },
  lastCalibration: {
    id: 'calibrationInformation.lastCalibration',
    defaultMessage: 'Last calibration',
  },
  statusAsOf: {
    id: 'calibrationInformation.statusAsOf',
    defaultMessage: 'Status as of',
  },
})
