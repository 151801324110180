import { getExtendedDeviceCalibrationByDeviceId } from '@/fleet-configuration/data-fleet/devices-calibrations/devices-calibrations-selector'
import { getProjectDeviceById } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'

export const calibrationInformationSelector = (state, props) => {
  const deviceCalibrationData = getExtendedDeviceCalibrationByDeviceId(state, props.deviceId)
  const projectDevice = getProjectDeviceById(state, props.deviceId)
  let hasOnlyVirtualModules = true
  if (projectDevice?.deviceSpecific?.controller?.isVirtual && !projectDevice.deviceSpecific.controller.isVirtual()) {
    hasOnlyVirtualModules = false
  }
  const physicalModules = projectDevice?.modules?.filter(module => !module.isVirtual())
  if (physicalModules?.length) {
    hasOnlyVirtualModules = false
  }

  return {
    deviceCalibrationData,
    hasOnlyVirtualModules,
  }
}
