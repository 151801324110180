import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { loaderSize } from 'skybase-ui/skybase-components/sb-loader/constants'
import { SbFullLayout } from 'skybase-ui/skybase-components/layouts/sb-full-layout'
import { withRouter } from '@/common/router'
import { configurationSettingsSelector } from '@/fleet-configuration/pages/configuration/configuration-settings/configuration-settings-selector'
import { loadProjectDeviceIfEmpty } from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { WizardButtons } from '@/fleet-configuration/page-components/wizard/wizard-buttons/wizard-buttons'
import { WizardNavigation } from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation'
import {
  configurationDetailBreadcrumbs,
  configurationDetailHeading,
} from '@/fleet-configuration/pages/configuration/configuration-detail-components'
import { MeasurementScreenContainer } from '@/fleet-configuration/pages/measurement-screen/measurement-screen-container/measurement-screen-container'
import { ChannelGeneralTab } from '@/fleet-configuration/page-components/channel/channel-general-tab'
import { ModuleDetail } from '@/fleet-configuration/page-components/module/module-detail'
import { MeasurementUnitDetail } from '@/fleet-configuration/page-components/measurement-unit/measurement-unit-detail'
import '@/fleet-configuration/pages/measurement-screen/measurement-screen.scss'
import { fleetInit } from '@/fleet-configuration/fleet-init'

export const ConfigurationSettings = withRouter(props => {
  const { device, deviceId, moduleIndex, channelIndex } = useSelector(state =>
    configurationSettingsSelector(state, props),
  )
  const dispatch = useDispatch()
  const { formatMessage: _ } = useIntl()
  useEffect(() => {
    dispatch(loadProjectDeviceIfEmpty(deviceId))
  }, [deviceId, dispatch])

  useEffect(() => {
    dispatch(fleetInit(_))
  }, [_, dispatch])

  let ScreenComponent = null
  if (deviceId && (moduleIndex || moduleIndex === 0) && (channelIndex || channelIndex === 0)) {
    ScreenComponent = <ChannelGeneralTab />
  } else if (deviceId && (moduleIndex || moduleIndex === 0)) {
    ScreenComponent = <ModuleDetail />
  } else if (deviceId) {
    ScreenComponent = <MeasurementUnitDetail />
  }

  return (
    <SbFullLayout
      title={device ? device.name : undefined}
      className="configuration-detail-page skybase-fixes"
      breadcrumbs={configurationDetailBreadcrumbs(_, device)}
    >
      {device ? (
        <div className="p-r-20 fl-container-column">
          {configurationDetailHeading(device)}
          <WizardNavigation deviceId={deviceId} step={1} />
          <MeasurementScreenContainer>{ScreenComponent}</MeasurementScreenContainer>
          <WizardButtons deviceId={deviceId} step={1} />
        </div>
      ) : (
        <SbLoader show size={loaderSize.L} />
      )}
    </SbFullLayout>
  )
})
