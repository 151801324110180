import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbButton } from 'skybase-ui/skybase-components/sb-button'
import { SbDynamicTabs, SbTab } from 'skybase-ui/skybase-components/sb-dynamic-tabs'
import { Sb21Layout } from 'skybase-ui/skybase-components/layouts'

import { withRouter } from '@/common/router'
import {
  NotificationsList,
  NotificationDetails,
  notificationShape,
  visitNotification,
  visitAllNotifications,
} from '@/iot-hub/components/notifications'
import { getIotHubNotifications } from '@/iot-hub/selectors'
import { messages as menuT } from '@/common/menu/menu-i18n'
import { messages as t } from './notifications-page-i18n'

import '@/iot-hub/components/notifications/notifications.scss' // eslint-disable-line import/extensions

class _NotificationsPage extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    notifications: PropTypes.arrayOf(notificationShape).isRequired,
    handleVisitNotification: PropTypes.func.isRequired,
    handleVisitAllNotifications: PropTypes.func.isRequired,
    router: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      location: PropTypes.shape({
        state: PropTypes.shape({
          selectedId: PropTypes.string,
        }),
      }).isRequired,
    }).isRequired,
  }

  static defaultProps = {}

  constructor(props) {
    super(props)

    this.state = {
      selectedId: null,
    }
  }

  componentDidMount() {
    const {
      router: { location },
    } = this.props
    const selectedId = location?.state?.selectedId

    if (selectedId) {
      this.handleOnSelectNotification(selectedId, false)
    }
  }

  componentWillUnmount() {
    const { handleVisitAllNotifications } = this.props

    handleVisitAllNotifications()
  }

  handleOnSelectNotification = (selectedId, visited) => {
    if (!visited) {
      const { handleVisitNotification } = this.props
      handleVisitNotification(selectedId)
    }

    this.setState({ selectedId })
  }

  handleGoToLocation = redirectLocation => {
    const {
      router: { navigate },
    } = this.props

    if (redirectLocation) {
      navigate(redirectLocation)
    }
  }

  render() {
    const { selectedId } = this.state
    const {
      intl: { formatMessage: _ },
      notifications,
    } = this.props
    const selectedNotification = notifications.find(notification => notification.id === selectedId)

    return (
      <Sb21Layout
        title={_(t.pageName)}
        breadcrumbs={[
          {
            path: '/',
            title: _(menuT.home),
          },
          {
            path: '/iot-hub',
            title: _(menuT.iotHub),
          },
          _(t.pageName),
        ]}
      >
        <div>
          <h1>{_(t.pageName)}</h1>
          <NotificationsList
            notifications={notifications}
            selectedId={selectedId}
            onRowSelect={this.handleOnSelectNotification}
          />
        </div>
        <SbDynamicTabs>
          <SbTab tabId="notification-details">
            <SbTab.Title>{_(t.details)}</SbTab.Title>
            <SbTab.Content>
              <NotificationDetails data={selectedNotification} />
            </SbTab.Content>
            {selectedNotification && selectedNotification?.redirectLocation && (
              <SbTab.Footer>
                <SbButton
                  className="go-to-page-icon sb-width-100pct"
                  onClick={() => this.handleGoToLocation(selectedNotification.redirectLocation)}
                  icon="sbi-go-to"
                >
                  {_(t.goToLocation)}
                </SbButton>
              </SbTab.Footer>
            )}
          </SbTab>
        </SbDynamicTabs>
      </Sb21Layout>
    )
  }
}

const mapStateToProps = state => {
  const notifications = getIotHubNotifications(state)

  return {
    notifications,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleVisitNotification: id => dispatch(visitNotification(id)),
    handleVisitAllNotifications: id => dispatch(visitAllNotifications(id)),
  }
}

export const NotificationsPage = withRouter(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(_NotificationsPage)),
)
