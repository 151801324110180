/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  validationError: {
    id: 'Validation Error',
    defaultMessage: 'Validation Error',
  },
  warning: {
    id: 'Warning',
    defaultMessage: 'Warning',
  },
})
