/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  familyDeviceType: {
    id: 'issuesToPayAttentionTable.familyDeviceType',
    defaultMessage: 'Family / Device Type',
  },
  serialNumber: {
    id: 'issuesToPayAttentionTable.serialNumber',
    defaultMessage: 'Serial Number',
  },
  typeNumber: {
    id: 'issuesToPayAttentionTable.typeNumber',
    defaultMessage: 'Type Number',
  },
  all: {
    id: 'issuesToPayAttentionTable.all',
    defaultMessage: 'All',
  },
  cable: {
    id: 'issuesToPayAttentionTable.cable',
    defaultMessage: 'Cable',
  },
  sensor: {
    id: 'issuesToPayAttentionTable.sensor',
    defaultMessage: 'Sensor',
  },
  device: {
    id: 'issuesToPayAttentionTable.device',
    defaultMessage: 'Device',
  },
  module: {
    id: 'issuesToPayAttentionTable.deviceModule',
    defaultMessage: 'Device module',
  },
  controller: {
    id: 'issuesToPayAttentionTable.deviceController',
    defaultMessage: 'Device controller',
  },
  unknown: {
    id: 'issuesToPayAttentionTable.unknown',
    defaultMessage: 'Unknown',
  },
  kiDaq: {
    id: 'issuesToPayAttentionTable.kiDaq',
    defaultMessage: 'KiDAQ',
  },
  labamp: {
    id: 'issuesToPayAttentionTable.labamp',
    defaultMessage: 'Labamp',
  },
  forcePlate: {
    id: 'issuesToPayAttentionTable.forcePlate',
    defaultMessage: 'Force plate',
  },
  syncBox: {
    id: 'issuesToPayAttentionTable.syncBox',
    defaultMessage: 'Sync box',
  },
  startBlock: {
    id: 'issuesToPayAttentionTable.startBlock',
    defaultMessage: 'Start block',
  },
  daqBox: {
    id: 'issuesToPayAttentionTable.daqBox',
    defaultMessage: 'DAQ box',
  },
})
