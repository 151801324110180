import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  activated: {
    id: 'manage-licenses.activated',
    defaultMessage: 'Activated',
  },
  notActivated: {
    id: 'manage-licenses.notActivated',
    defaultMessage: 'Not Activated',
  },
  activatedByOther: {
    id: 'manage-licenses.activatedByOther',
    defaultMessage: 'Activated by other',
  },
  licenseName: {
    id: 'manage-licenses.licenseName',
    defaultMessage: 'Name',
  },
  productCode: {
    id: 'manage-licenses.productCode',
    defaultMessage: 'Product Code',
  },
  assignmentTime: {
    id: 'manage-licenses.assignmentTime',
    defaultMessage: 'Assignment Time',
  },
  quantity: {
    id: 'manage-licenses.quantity',
    defaultMessage: 'Quantity',
  },
  orderIdentification: {
    id: 'manage-licenses.orderIdentification',
    defaultMessage: 'Order Identification',
  },
  ticketId: {
    id: 'manage-licenses.ticketId',
    defaultMessage: 'Ticket ID',
  },
  ticketIdPlaceholder: {
    id: 'manage-licenses.ticketIdPlaceholder',
    defaultMessage: 'Enter a Ticket ID',
  },
  activation: {
    id: 'manage-licenses.activation',
    defaultMessage: 'Activation',
  },
  licenseFetchError: {
    id: 'manage-licenses.licenseFetchError',
    defaultMessage: 'There was an error while fetching licenses.',
  },
  licenseAssignError: {
    id: 'manage-licenses.licenseAssignError',
    defaultMessage: 'There was an error while assigning licenses.',
  },
  licenseRemoveError: {
    id: 'manage-licenses.licenseRemoveError',
    defaultMessage: 'There was an error while removing licenses.',
  },
  licenseUpdateSuccess: {
    id: 'manage-licenses.licenseUpdateSuccess',
    defaultMessage: 'License update was successful.',
  },
  licenseUpdateError: {
    id: 'manage-licenses.licenseUpdateError',
    defaultMessage: 'There was an error while updating licenses.',
  },
  licenseUpdateNotApplied: {
    id: 'manage-licenses.licenseUpdateNotApplied',
    defaultMessage: 'License updating was not applied for the specified tenant.',
  },
  ticketContainesNoLicenses: {
    id: 'manage-licenses.ticketContainesNoLicenses',
    defaultMessage: 'Ticket with ID "{ticketId}" does not contain any license.',
  },
  ticketNotFound: {
    id: 'manage-licenses.ticketNotFound',
    defaultMessage: 'Ticket with ID "{ticketId}" was not found.',
  },
  licenseContentsFetchError: {
    id: 'manage-licenses.licenseContentsFetchError',
    defaultMessage: 'There was an error while fetching license contents.',
  },
  selectALicense: {
    id: 'manage-licenses.selectALicense',
    defaultMessage: 'You should first select a license to see its details.',
  },
  assignNewLicense: {
    id: 'manage-licenses.assignNewLicense',
    defaultMessage: 'Assign a new license',
  },
  proceed: {
    id: 'manage-licenses.proceed',
    defaultMessage: 'Proceed',
  },
  assign: {
    id: 'manage-licenses.assign',
    defaultMessage: 'Assign',
  },
  ticketidCannotBeEmpty: {
    id: 'manage-licenses.ticketidCannotBeEmpty',
    defaultMessage: 'The Ticket ID cannot be empty.',
  },
  ticketIdIncorrectPattern: {
    id: 'manage-licenses.ticketIdIncorrectPattern',
    defaultMessage: 'Ticket ID has an incorrect pattern. Please use the pattern: XXXXX-XXXXX-XXXXX-XXXXX-XXXXX',
  },
  tenantName: {
    id: 'manage-licenses.tenantName',
    defaultMessage: 'Tenant Name',
  },
  back: {
    id: 'manage-licenses.back',
    defaultMessage: 'Back',
  },
  licensesWereAssigned: {
    id: 'manage-licenses.licensesWereAssigned',
    defaultMessage: 'The following licenses were successfully assigned: {licenses}.',
  },
})
