import { getProjectDeviceModuleById } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'

export const channelGeneralTabSelector = (
  state,
  {
    router: {
      params: { unitId, moduleId, channelId },
    },
  },
) => {
  const module = getProjectDeviceModuleById(state, unitId, moduleId)
  const channel = module && module.channels[channelId]
  return { deviceId: unitId, channel }
}
