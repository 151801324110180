import React from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import uniq from 'lodash/uniq'

import { OAuth } from 'skybase-oauth'
import { convertToEditableList, convertFromEditableList } from 'skybase-oauth/utils'
import { SbButton } from 'skybase-ui/skybase-components/sb-button'
import { FormComponent } from 'skybase-oauth/common/components'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'

import { HintRenderer } from '@/common/hint-renderer'
import { dataTrasnferConfigurationShape } from '../shapes'
import { transferFormObject, supportedTransferMethods, certificateTypes, CA_CERTIFICATE } from './constants'
import { getTranferConfigurationValidationRules, caCertificateRule } from './validation-rules'
import { messages as t } from './datasources-i18n'

const { ENDPOINTS, TOPIC, TIME_TRESHOLD, MESSAGE_TRESHOLD, ENCODING, BROKER_VERIFY_TIMEOUT_MS } = transferFormObject
const { ZMQPUBCLIENT, STREAM } = supportedTransferMethods
const { OAUTH_KAFKA, TLS_CUSTOM } = certificateTypes

class _TransferForm extends FormComponent {
  static propTypes = {
    ...this.propTypes,
    method: PropTypes.oneOf(Object.values(supportedTransferMethods)).isRequired,
    data: dataTrasnferConfigurationShape.isRequired,
    handleUpdate: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    deviceOffline: PropTypes.bool.isRequired,
    isActiveTranfer: PropTypes.bool.isRequired,
    supportedEncodings: PropTypes.arrayOf(PropTypes.string),
    certificate: PropTypes.shape({
      customCaResource: PropTypes.shape({ href: PropTypes.string }),
      certificateType: PropTypes.oneOf([OAUTH_KAFKA, TLS_CUSTOM]),
    }),
  }

  static defaultProps = {
    ...this.defaultProps,
    supportedEncodings: [],
    certificate: {},
  }

  constructor(props) {
    super(props)

    const {
      intl: { formatMessage: _ },
      method,
    } = props

    this.dataInState = true
    this.state = {
      ...this.state,
      data: Object.keys(props.data).reduce((acc, key) => {
        if (key === ENDPOINTS && method === STREAM) {
          // Merge the KAFKA_BROKER_LIST with the list from the tranfer config api
          const mergedList = uniq([...(OAuth.config.KAFKA_BROKER_LIST || []), ...(props.data[key] || [])])

          // return the converted list as an editable list structure
          return {
            ...acc,
            [key]: convertToEditableList(mergedList),
          }
        }

        return {
          ...acc,
          [key]: props.data[key],
        }
      }, {}),
      method,
    }

    if (method === STREAM) {
      this.beforeConfirm = [{ name: ENDPOINTS, normalize: convertFromEditableList }]
    }

    this.validationRules = getTranferConfigurationValidationRules(_, method)
    this.updateValidationRules = SbEmitter.on('update-kafka-validation-rules', this.handleUpdateKafkaValidationRules)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.method !== state.method) {
      return { data: {}, method: props.method }
    }

    return null
  }

  componentWillUnmount() {
    this.updateValidationRules?.()
  }

  handleUpdateKafkaValidationRules = certificateType => {
    const {
      intl: { formatMessage: _ },
    } = this.props

    this.validationRules = this.validationRules.filter(rule => !rule.attributes.includes(CA_CERTIFICATE))
    if (certificateType === TLS_CUSTOM) {
      this.validationRules = this.validationRules.concat(caCertificateRule(_))
    } else if (certificateType === OAUTH_KAFKA) {
      // Reset the error for caCertificate attribute
      this.setState(prevState => {
        const { [CA_CERTIFICATE]: oldAttribute, ...restErrors } = prevState.errors

        return { errors: restErrors }
      })
    }

    this.forceUpdate()
  }

  handleOnChangeParams = (attribute, value) => {
    this.setState(prevState => {
      return {
        data: {
          ...prevState.data,
          [attribute]: value,
        },
      }
    })
  }

  handleOnConfirm = data => {
    const { handleUpdate } = this.props

    handleUpdate(data)
  }

  renderConfirmButton = () => {
    const {
      loading,
      intl: { formatMessage: _ },
      deviceOffline,
      isActiveTranfer,
      disabled,
      acl: { write },
    } = this.props

    return (
      write && (
        <HintRenderer
          showHint={deviceOffline}
          hintData={_(t.cannotStartDataTransferWhileDeviceOffline)}
          className="sb-width-100pct"
        >
          <SbButton
            id="datastream-button"
            loading={loading}
            className="sb-width-100pct primary"
            onClick={this.handleOnConfirmClick}
            disabled={loading || deviceOffline || (!isActiveTranfer && disabled)}
          >
            {isActiveTranfer ? _(t.stop) : _(t.start)}
          </SbButton>
        </HintRenderer>
      )
    )
  }

  renderInputs = () => {
    const {
      method,
      intl: { formatMessage: _ },
      supportedEncodings,
      certificate: { certificateType, customCaResource },
    } = this.props
    const {
      data: { endpoints },
    } = this.state
    const encodingItems =
      supportedEncodings?.map?.(sEncoding => {
        return { value: sEncoding, name: sEncoding }
      }) || []

    return (
      <>
        {method === STREAM &&
          this.renderEditableListWithLabel(ENDPOINTS, _(t.endpoints), {
            placeholder: _(t.endpointsPlaceholder),
            controls: {
              remove: endpoints?.length > 1 ? 'enabled' : 'disabled',
              arrows: 'hidden',
            },
          })}
        {method === ZMQPUBCLIENT &&
          this.renderInputWithLabel(ENDPOINTS, _(t.zmqEndpoint), {
            placeholder: _(t.zmqEndpointPlaceholder),
            labelClassName: 'datastream-zmq-endpoint',
          })}
        {this.renderInputWithLabel(TOPIC, _(t.topic), {
          placeholder: _(t.topicPlaceholder),
          labelClassName: 'datastream-topic',
        })}
        {this.renderInputWithLabel(TIME_TRESHOLD, _(t.timeTreshold), {
          placeholder: _(t.timeTresholdPlaceholder),
          type: 'number',
          negative: false,
          fraction: 0,
          addonRight: <div className="addon-static-box">ms</div>,
          labelClassName: 'datastream-time-treshold',
        })}
        {this.renderInputWithLabel(MESSAGE_TRESHOLD, _(t.messageTreshold), {
          placeholder: _(t.messageTresholdPlaceholder),
          negative: false,
          fraction: 0,
          type: 'number',
          labelClassName: 'datastream-message-treshold',
        })}
        {method === STREAM &&
          this.renderInputWithLabel(BROKER_VERIFY_TIMEOUT_MS, _(t.brokerVerifyTimeout), {
            placeholder: _(t.brokerVerifyTimeoutPlaceholder),
            type: 'number',
            negative: false,
            fraction: 0,
            addonRight: <div className="addon-static-box">ms</div>,
          })}
        {encodingItems?.length > 0 &&
          this.renderInputWithLabel(ENCODING, _(t.encoding), {
            componentType: 'select',
            items: [{ value: '', name: _(t.automatic) }].concat(encodingItems),
            labelClassName: 'datastream-encoding',
          })}
        {method === STREAM &&
          !!customCaResource &&
          this.renderInputWithLabel(CA_CERTIFICATE, _(t.customKafkaCertificate), {
            componentType: 'textarea',
            disabled: certificateType === OAUTH_KAFKA,
            style: { resize: 'none', height: '66px' },
            placeholder: _(t.customKafkaCertificatePlaceholder),
            labelClassName: 'custom-kafka-certificate',
          })}
      </>
    )
  }
}

export const TransferForm = injectIntl(_TransferForm)
