import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { MeasurementButtons } from '@/fleet-configuration/pages/measurement-screen/measurement-buttons/measurement-buttons'
import { channelGeneralTabSelector } from './channel-general-tab-selector'
import { ChannelParameters } from './channel-parameters'
import { UnitIllustration } from '../unit-illustration/unit-illustration'
import { ConfigScreenLayout } from '../config-screen-layout/config-screen-layout'
import { messages as t } from './channel-general-tab-i18n'
import { ChainPanel } from '@/fleet-configuration/page-components/chain-panel/chain-panel'
import { withRouter } from '@/common/router'

const _ChannelGeneralTab = ({ deviceId, intl: { formatMessage: _ }, channel = null }) => (
  <ConfigScreenLayout
    title={
      <>
        {_(t.channel)}
        <MeasurementButtons deviceId={deviceId} />
      </>
    }
    illustration={
      channel ? (
        <>
          <p className="component-name">&quot;{`${_(t.channel)} ${channel.getChannelIndex() + 1}`}&quot;</p>
          <UnitIllustration />
        </>
      ) : (
        _(t.requestedChannelDoesNotExist)
      )
    }
    chainPanel={<ChainPanel />}
    parameters={channel && <ChannelParameters />}
  />
)

_ChannelGeneralTab.propTypes = {
  deviceId: PropTypes.string.isRequired,
  channel: PropTypes.object,
  intl: intlShape.isRequired,
}

export const ChannelGeneralTab = withRouter(injectIntl(connect(channelGeneralTabSelector)(_ChannelGeneralTab)))
