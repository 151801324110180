import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { batchActions } from 'redux-batched-actions'
// TODO: break this cycle (even if it does not create bugs right now)
// eslint-disable-next-line import/no-cycle
import { clearAlerts } from './clear-alerts'
import { ALERT_ORIGIN_FRONTEND } from './constants'

export const ALERTS_REMOVE = getActionName('ALERTS_REMOVE')
export const alertsRemove = (alerts, id) => createAction(ALERTS_REMOVE, { alerts, id })

export const ALERTS_ADD = getActionName('ALERTS_ADD')
export const alertsAdd = (alerts, id) => createAction(ALERTS_ADD, { alerts, id })

export const ALERTS_STORE_RETURN_ACTIONS = getActionName('ALERTS_STORE_RETURN_ACTIONS')
export const storeReturnActions = (returnActions, id) =>
  createAction(ALERTS_STORE_RETURN_ACTIONS, { returnActions, id })

export const setAlerts =
  (alerts, id, returnActions, directDispatch = true) =>
  (dispatch, getState) => {
    const { validation } = getState()
    const batchableActions = []
    const alertsToClear = clearAlerts(dispatch, validation, id, { origin: ALERT_ORIGIN_FRONTEND }, false)
    if (alertsToClear) {
      batchableActions.push(alertsToClear)
    }
    if (alerts && alerts.length) {
      batchableActions.push(alertsAdd(alerts, id))
    }
    if (returnActions) {
      batchableActions.push(storeReturnActions(returnActions, id))
    }
    if (!directDispatch) {
      return batchableActions
    }
    if (batchableActions.length) {
      dispatch(batchActions(batchableActions))
    }
    return undefined
  }
