/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  kiConnectIcon: {
    id: 'poweredBy.kiConnectIcon',
    defaultMessage: 'KiConnect icon',
  },
  poweredBy: {
    id: 'poweredBy.poweredBy',
    defaultMessage: 'Powered by',
  },
})
