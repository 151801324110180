/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  configurationSnippetDetail: {
    id: 'configurationSnippetDetail.configurationSnippetDetail',
    defaultMessage: 'Configuration snippet detail',
  },
  home: {
    id: 'configurationSnippetDetail.home',
    defaultMessage: 'Home',
  },
  fleetOverview: {
    id: 'configurationSnippetDetail.fleetOverview',
    defaultMessage: 'Hardware',
  },
  presets: {
    id: 'configurationSnippetDetail.presets',
    defaultMessage: 'Presets',
  },
  applyToDevices: {
    id: 'configurationSnippetDetail.applyToDevices',
    defaultMessage: 'Apply to devices',
  },
  snippetUpdateFailed: {
    id: 'configurationSnippetDetail.snippetUpdateFailed',
    defaultMessage: 'Snippet update failed',
  },
  export: {
    id: 'configurationSnippetDetail.export',
    defaultMessage: 'Export',
  },
  import: {
    id: 'configurationSnippetDetail.import',
    defaultMessage: 'Import',
  },
  valueCantBeEmpty: {
    id: 'configurationSnippetDetail.valueCantBeEmpty',
    defaultMessage: "Value can't be empty",
  },
  configuration: {
    id: 'configurationSnippetDetail.configuration',
    defaultMessage: 'Configuration',
  },
})
