import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export const ListViewItem = ({ children, isSelected = false, className = null, style = null, onClick = undefined }) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      className={classNames(
        'list-view-item',
        'list-view-item-content',
        { 'list-view-item-selected': isSelected },
        className,
      )}
      style={style}
      onClick={onClick}
    >
      {children}
    </li>
  )
}

ListViewItem.propTypes = {
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
}
