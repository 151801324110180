import React from 'react'
import * as ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import { OAuthComponentFactory } from 'skybase-oauth/oauth/oauth-component-factory'
import { OAuth } from 'skybase-oauth/oauth'
import { registerAxiosOAuthTokenInterceptor } from 'skybase-oauth/oauth/interceptors/axios-interceptor'
import { SbToastrContainer } from 'skybase-ui/skybase-components/sb-toastr'
import { setAppRoot, initialize } from 'skybase-ui/skybase-core/base/actions'
import { getAppRoot } from 'skybase-ui/skybase-core/utils/browser'
import { notificationsLocalStorageService } from 'skybase-ui/skybase-core/notifications/notifications-localstorage-service'
import { emitterLogger } from 'skybase-ui/skybase-core/emitter/emitter-logger'
import { emitToastOnErrorService } from 'skybase-ui/skybase-components/sb-toastr/services'
import { combineLocations } from 'skybase-ui/skybase-core/notifications/combine-locations'
import { SbErrorBoundary } from 'skybase-pages/sb-error-boundary'
import 'skybase-pages/sb-error-boundary/sb-error-page.scss'

import Modals from './containers/modals/modals'
import { CustomIntlProvider } from './containers/custom-intl-provider'
import { initializeApp } from './common/actions'
import { initializeLanguagesAndCulture } from './common/locales'
import { InitServices, clientAppWSManager } from './common/init-services'

import { store } from './stores'
import Routes from './routes'
import { getMode } from './utils'
import { Announcement } from './common/announcement/announcement'
import { Eula } from '@/common/eula/eula'
import { areSkydaqAPISDisabled } from '@/utils'

const { REACT_APP_OAUTH_CONFIG_PATH } = process.env

// eslint-disable-next-line
const OAuthWrapper = ({ renderFactory, children }) =>
  renderFactory ? <OAuthComponentFactory>{children}</OAuthComponentFactory> : children

fetch(REACT_APP_OAUTH_CONFIG_PATH || '/oAuthConfig.json')
  .then(
    response => response.json(),
    err => {
      console.error('Could not fetch config file: ', err)
    },
  )
  .then(
    config => {
      // initialize skybase-oauth services
      OAuth.setConfig(config)
      // register axios interceptor - required for fleet configuration module
      registerAxiosOAuthTokenInterceptor()

      store.dispatch(setAppRoot(getAppRoot()))

      // initialize the storage for the notification service
      notificationsLocalStorageService(store)

      // initialize locations for redirect for notifications
      combineLocations({}) // Add custom locations to parameters here

      // initialize toast to error message service.
      emitToastOnErrorService(store.dispatch)

      if (getMode() !== 'production') {
        emitterLogger()
      }

      // Sets up languages and cultures.
      initializeLanguagesAndCulture(store.dispatch)

      // Initialize the internals to this app
      store.dispatch(initializeApp())
      // Initialize all of the SkyBase included modules
      store.dispatch(initialize())

      if (!OAuth.isInNormalMode) {
        clientAppWSManager(store)
      }

      const MainComponent = () => {
        const protectedContent = (
          <>
            {OAuth.isInNormalMode && !areSkydaqAPISDisabled() && <Announcement />}
            <Routes />
            <Modals />
            <SbToastrContainer position="top-right" />
            <InitServices />
          </>
        )
        const useEula = OAuth.isInNormalMode && config.USE_EULA && !['false', '0'].includes(config.USE_EULA)
        return (
          <Provider store={store}>
            <CustomIntlProvider>
              <SbErrorBoundary>
                <OAuthWrapper renderFactory={!OAuth.isInLocalSDKMode}>
                  {useEula ? <Eula>{protectedContent}</Eula> : protectedContent}
                </OAuthWrapper>
              </SbErrorBoundary>
            </CustomIntlProvider>
          </Provider>
        )
      }

      const root = ReactDOM.createRoot(document.getElementById('app'))
      root.render(<MainComponent />)
    },
    err => console.error('Could not parse config file: ', err),
  )
