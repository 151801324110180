import React from 'react'
import PropTypes from 'prop-types'
import { omit } from 'lodash'
import { connect } from 'react-redux'
import { Label } from './label'

const _Field = ({ id, label, validation, Component, labelClassName = '', isDirty = false, ...rest }) => {
  const labelProps = omit(rest, ['onChange', 'onBlur', 'data-testid'])
  const passOnProps = omit(rest, ['dispatch', 'isDirty', 'labelClassName'])
  return (
    <Label {...labelProps} title={label} isDirty={isDirty} className={labelClassName}>
      <Component {...passOnProps} />
    </Label>
  )
}

_Field.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  validation: PropTypes.object.isRequired,
  Component: PropTypes.elementType.isRequired,
  labelClassName: PropTypes.string,
  isDirty: PropTypes.bool,
}

_Field.contextTypes = {
  formNames: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const fieldSelector = state => ({ validation: state.validation })

export const LabeledField = connect(fieldSelector)(_Field)
