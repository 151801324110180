import React from 'react'

import { WSClients } from '@/common/websocket'

// Component for registering services, which requires the full state of permissions and accessToken
// This component will only be called once!
export class InitServices extends React.Component {
  componentDidMount() {
    // register all WS client instances
    if (!WSClients.isInitialized) {
      WSClients.registerWSClients(true)
    }
  }

  componentWillUnmount() {
    // unregister all WS client instances
    // WSClients.unregisterWSClients()
  }

  shouldComponentUpdate() {
    return false
  }

  render() {
    return null
  }
}

export { clientAppWSManager } from './client-app-ws-manager'
