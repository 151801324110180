import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  pageName: {
    id: 'iot-hub-resources-page.pageName',
    defaultMessage: 'Resources',
  },
  details: {
    id: 'iot-hub-resources-page.details',
    defaultMessage: 'Details',
  },
  deviceNotFound: {
    id: 'iot-hub-resources-page.deviceNotFound',
    defaultMessage: 'Device with ID "{deviceId}" not found.',
  },
  deviceResourceNotFound: {
    id: 'iot-hub-resources-page.deviceResourceNotFound',
    defaultMessage: 'Device Resource with resource path "{resourcePath}" not found.',
  },
  loadingResources: {
    id: 'iot-hub-resources-page.loadingResources',
    defaultMessage: 'Loading resources',
  },
})
