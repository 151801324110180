/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  noDevicesFound: {
    id: 'snippetsTableModal.noDevicesFound',
    defaultMessage: 'No devices found',
  },
  selectDevices: {
    id: 'snippetsTableModal.selectDevices',
    defaultMessage: 'Select devices',
  },
  cancel: {
    id: 'snippetsTableModal.cancel',
    defaultMessage: 'Cancel',
  },
  apply: {
    id: 'snippetsTableModal.apply',
    defaultMessage: 'Apply',
  },
  presetsWereSuccessfullyApplied: {
    id: 'snippetsTableModal.presetsWereSuccessfullyApplied',
    defaultMessage: 'Presets were successfully applied',
  },
  somePresetsCouldNotHaveBeenAppliedCorrectly: {
    id: 'snippetsTableModal.somePresetsCouldNotHaveBeenAppliedCorrectly',
    defaultMessage: 'Some presets could not have been applied correctly',
  },
  devicesSelectedNumSelectedOfNumDevices: {
    id: 'snippetsTableModal.devicesSelectedNumSelectedOfNumDevices',
    defaultMessage: 'Selected devices: {numSelected} of {numDevices}',
  },
})
