import {
  getChainEntries,
  getChainByChannelDetails,
  getChainsByModuleDetails,
  isChainLoaded,
} from '@/fleet-configuration/data-fleet/chain/chain-selectors'
import { getProjectDeviceById } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import { urlPathController } from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation-constants'
import { createSelector } from 'reselect'
import { memoSelector } from '@/utils/memo-selector'
import { getChainCatalogEntry } from '@/fleet-configuration/data-fleet/chain-catalog/chain-catalog-selectors'
import { getSensorDisplayDataFromCatalog } from '@/fleet-configuration/data-fleet/chain-certificate/chain-certificate-selector'

const urlParamsSelector = memoSelector(
  (state, props, { getAreDependencyArgsTheSame, getCachedResult, setCachedResult }) => {
    const {
      router: {
        params: { unitId, moduleId, channelId },
      },
    } = props

    return getAreDependencyArgsTheSame(unitId, moduleId, channelId)
      ? getCachedResult()
      : setCachedResult({ deviceId: unitId, moduleId, channelId })
  },
)

const getTypeNumberAndSerialNumberByDeviceAndModuleUrlSegment = (device, moduleUrlSegment) => {
  const module =
    moduleUrlSegment === urlPathController ? device.deviceSpecific.controller : device.modules[moduleUrlSegment]
  return { typeNumber: module.getType(), serialNumber: module.parametersReadable?.snr || device.serialNumber }
}

const getModuleChains = memoSelector(
  (state, props, { getAreDependencyArgsTheSame, getCachedResult, setCachedResult }) => {
    const {
      router: {
        params: { unitId, moduleId },
      },
    } = props
    const device = getProjectDeviceById(state, unitId)
    if (!device) {
      return null
    }
    const { typeNumber, serialNumber } = getTypeNumberAndSerialNumberByDeviceAndModuleUrlSegment(device, moduleId)
    // we have dependency on actual chain from redux - we are fetching data from it so if it changes, invalidate cache too
    if (getAreDependencyArgsTheSame(typeNumber, serialNumber, getChainEntries(state))) {
      return getCachedResult()
    }
    return setCachedResult(getChainsByModuleDetails(state, typeNumber, serialNumber))
  },
)
const getChannelChain = memoSelector(
  (state, props, { getAreDependencyArgsTheSame, getCachedResult, setCachedResult }) => {
    const {
      router: {
        params: { unitId, moduleId, channelId },
      },
    } = props
    const device = getProjectDeviceById(state, unitId)
    if (!device) {
      return undefined
    }
    const { typeNumber, serialNumber } = getTypeNumberAndSerialNumberByDeviceAndModuleUrlSegment(device, moduleId)
    // we have dependency on actual chain from redux - we are fetching data from it so if it changes, invalidate cache too
    return getAreDependencyArgsTheSame(typeNumber, serialNumber, channelId, getChainEntries(state))
      ? getCachedResult()
      : setCachedResult(getChainByChannelDetails(state, typeNumber, serialNumber, channelId))
  },
)

const sensorCatalogSelector = (state, props) => {
  const chain = getChannelChain(state, props)
  if (!chain?.sensor?.typeNumber) {
    return undefined
  }
  return getChainCatalogEntry(state, chain.sensor.typeNumber)
}

const cableCatalogSelector = (state, props) => {
  const chain = getChannelChain(state, props)
  if (!chain?.cable?.typeNumber) {
    return undefined
  }
  return getChainCatalogEntry(state, chain.cable.typeNumber)
}

export const chainPanelSelector = createSelector(
  [urlParamsSelector, isChainLoaded, getModuleChains, getChannelChain, sensorCatalogSelector, cableCatalogSelector],
  ({ deviceId, channelId }, isLoaded, moduleChains, channelChain, sensorCatalog, cableCatalog) => ({
    isLoaded,
    chain: channelChain,
    deviceId,
    channelId,
    sensorCatalog,
    sensorDisplayData: sensorCatalog && getSensorDisplayDataFromCatalog(sensorCatalog),
    cableCatalog,
  }),
)
