import { REQUIRED, LIST, RANGE } from 'skybase-oauth/validator'
import { messages as a } from 'skybase-oauth/messages-i18n'

import { transferFormObject, supportedTransferMethods, CA_CERTIFICATE } from './constants'
import { messages as t } from './datasources-i18n'

const { ENDPOINTS, TOPIC, TIME_TRESHOLD, MESSAGE_TRESHOLD, BROKER_VERIFY_TIMEOUT_MS } = transferFormObject
const { ZMQPUBCLIENT } = supportedTransferMethods

const getRangeErrorMessage = (attribute, { min, max }, _) => {
  switch (attribute) {
    case TIME_TRESHOLD:
      return `${_(t.paramIsOutOfRange, { param: _(t.timeTreshold), min, max })}`
    case MESSAGE_TRESHOLD:
      return `${_(t.paramIsOutOfRange, {
        param: _(t.messageTreshold),
        min,
        max,
      })}`
    case BROKER_VERIFY_TIMEOUT_MS:
      return `${_(t.paramIsOutOfRange, {
        param: _(t.brokerVerifyTimeout),
        min,
        max,
      })}`
    default:
      return null
  }
}

const getErrorMessage = (attribute, _) => {
  switch (attribute) {
    case ENDPOINTS:
      return `${_(a.paramIsRequired, { param: _(t.endpoints) })}`
    case TOPIC:
      return `${_(a.paramIsRequired, { param: _(t.topic) })}`
    case CA_CERTIFICATE:
      return `${_(a.paramIsRequired, { param: _(t.customKafkaCertificate) })}`
    default:
      return null
  }
}

export const caCertificateRule = _ => ({
  attributes: [CA_CERTIFICATE],
  condition: REQUIRED,
  message: attribute => getErrorMessage(attribute, _),
})

// Kafka validation rules
const dataSourceTransferConfigurationStreamValidationRules = _ => [
  {
    attributes: [ENDPOINTS, TOPIC, TIME_TRESHOLD, MESSAGE_TRESHOLD, BROKER_VERIFY_TIMEOUT_MS],
    condition: REQUIRED,
    message: attribute => getErrorMessage(attribute, _),
  },
  {
    attributes: [ENDPOINTS],
    condition: LIST,
    message: () => _(t.endpointCannotBeEmpty),
    listCondition: value => value.trim() !== '',
  },
  {
    attributes: [TIME_TRESHOLD],
    condition: RANGE,
    min: 0,
    max: 900000,
    message: (attribute, value, { min, max }) => getRangeErrorMessage(attribute, { min, max }, _),
  },
  {
    attributes: [MESSAGE_TRESHOLD],
    condition: RANGE,
    min: 1,
    max: 10000000,
    message: (attribute, value, { min, max }) => getRangeErrorMessage(attribute, { min, max }, _),
  },
  {
    attributes: [BROKER_VERIFY_TIMEOUT_MS],
    condition: RANGE,
    min: 10,
    max: 60000,
    message: (attribute, value, { min, max }) => getRangeErrorMessage(attribute, { min, max }, _),
  },
]

// ZeroMQ validation rules
const dataSourceTransferConfigurationZeroMQValidationRules = _ => [
  {
    attributes: [ENDPOINTS, TOPIC, MESSAGE_TRESHOLD],
    condition: REQUIRED,
    message: attribute => getErrorMessage(attribute, _),
  },
  {
    attributes: [TIME_TRESHOLD],
    condition: RANGE,
    min: 0,
    max: 900000,
    message: (attribute, value, { min, max }) => getRangeErrorMessage(attribute, { min, max }, _),
  },
  {
    attributes: [MESSAGE_TRESHOLD],
    condition: RANGE,
    min: 1,
    max: 10000000,
    message: (attribute, value, { min, max }) => getRangeErrorMessage(attribute, { min, max }, _),
  },
]

export const getTranferConfigurationValidationRules = (_, method) => {
  if (method === ZMQPUBCLIENT) {
    return dataSourceTransferConfigurationZeroMQValidationRules(_)
  }

  // STREAM
  return dataSourceTransferConfigurationStreamValidationRules(_)
}
