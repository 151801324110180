import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { openModal } from 'skybase-ui/skybase-core/base/actions'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { SbCheckbox } from 'skybase-ui/skybase-components/sb-checkbox/sb-checkbox'
import { loaderSize } from 'skybase-ui/skybase-components/sb-loader/constants'
import { SbHint } from 'skybase-ui/skybase-components/sb-hint'
import { withRouter } from '@/common/router'
import { GREEN, RED, StatusBullet } from '@/common/status-bullet'
import { loadChainIfEmpty } from '@/fleet-configuration/data-fleet/chain/chain-actions'
import { tryLoadChainCatalogForAllChainItems } from '@/fleet-configuration/data-fleet/chain-catalog/chain-catalog-actions'
import { loadProjectDeviceIfEmpty } from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { IdentifyButton } from '@/fleet-configuration/components/identify-button/identify-button'
import {
  ADD_COMPONENT_MODAL_ID,
  addComponentModalPossibleComponents,
} from '@/fleet-configuration/page-components/wizard/add-component-modal/add-component-modal-constants'
import { showOnlyActiveChannels } from '@/fleet-configuration/data-fleet/devices-show-only-active/devices-show-only-active-actions'
import { ListView } from '@/fleet-configuration/components/list-view/list-view'
import { ListViewItem } from '@/fleet-configuration/components/list-view/list-view-item'
import { DeviceComponentIcon } from '@/fleet-configuration/device-component-icon'
import { getNavigationSelectionId } from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation-selector'
import {
  wizardNavigationSelection,
  navigationSelectionItemType,
} from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation-constants'
import { ChannelMenu } from '@/fleet-configuration/page-components/wizard/wizard-menu-channel/channel-menu'
import { loadDevicesIfEmpty } from '@/fleet-configuration/data-fleet/devices/devices-actions'
import { loadDeviceCalibrationsIfEmpty } from '@/fleet-configuration/data-fleet/devices-calibrations/devices-calibrations-actions'
import { CALIBRATION_STATUSES } from '@/fleet-configuration/data-fleet/chain-certificate/chain-certificate-constants'
import { CABLE_COMPATIBILITY } from '@/fleet-configuration/utils/cable-utils-constants'
import { wizardMenuSelector } from './wizard-menu-selector'
import { messages as t } from './wizard-menu-i18n'
import './wizard-menu.scss'
import '@/fleet-configuration/dirty.scss'

const { INDETERMINATE, SELECTED, UNSELECTED } = wizardNavigationSelection

export const WizardMenu = withRouter(
  ({
    baseUrl = null,
    explicitDeviceRef = undefined,
    showContextMenu = false,
    showChainComponents = true,
    computeDirtyState = true,
    rootId = null,
    rowSelectionChange = null,
    rowsSelected = {},
    ...props
  }) => {
    const {
      onlyActive,
      deviceId,
      moduleIndex,
      channelIndex,
      device,
      isDeviceCalibrable,
      hasLED,
      deviceChain, // this is used for effects = same object until chain actually changes
      deviceChainWithNamesAndCalibrationStatus, // this is derived object that changes with each selector execution (can't be used for useEffect's 2nd param)
      deviceCalibration,
    } = useSelector(state => wizardMenuSelector(state, { ...props, explicitDeviceRef }))
    const [shrunk, setShrunk] = useState({})
    const { formatMessage: _ } = useIntl()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
      ;(async () => {
        let loadProjectDevicePromise = null
        if (!explicitDeviceRef) {
          loadProjectDevicePromise = dispatch(loadProjectDeviceIfEmpty(deviceId))
        }

        /* load devices and once they are loaded load calibrations - do not block any other loads in this function */
        const devicesDispatchResponse = dispatch(loadDevicesIfEmpty())
        if (devicesDispatchResponse.then) {
          devicesDispatchResponse.then(() => {
            dispatch(loadDeviceCalibrationsIfEmpty(deviceId))
          })
        } else {
          dispatch(loadDeviceCalibrationsIfEmpty(deviceId))
        }

        if (showChainComponents) {
          const chain = await dispatch(loadChainIfEmpty())

          // now go thru whole device's chain and fetch every sensor's and cable's name
          const projectDevice = await loadProjectDevicePromise
          const deviceInUse = explicitDeviceRef || projectDevice
          const typeAndSerialNumbersUsed = deviceInUse.modules.map(module => ({
            typeNumber: module.getType(),
            serialNumber: module.parametersReadable?.snr || deviceInUse.serialNumber,
          }))
          const chainItemsUsedInMenu = chain.filter(chainEntry =>
            typeAndSerialNumbersUsed.some(
              ({ typeNumber, serialNumber }) =>
                chainEntry.channel.typeNumber === typeNumber && chainEntry.channel.serialNumber === serialNumber,
            ),
          )

          dispatch(tryLoadChainCatalogForAllChainItems(chainItemsUsedInMenu))
        }
      })()
    }, [dispatch, deviceId, explicitDeviceRef, showChainComponents, deviceChain])

    const toggleShrunkFactory = propId => evt => {
      evt.stopPropagation()
      setShrunk({ ...shrunk, [propId]: !shrunk[propId] })
    }
    const getExpansionChevron = propId => (shrunk[propId] ? 'sbi-chevron-right' : 'sbi-chevron-down')

    const handleOnShowOnlyActiveClicked = () => {
      dispatch(showOnlyActiveChannels(!onlyActive))
    }

    const navigateToSubpageFactory =
      (shrunkKey, navDeviceId, navModuleIndex = null, navChannelIndex = null) =>
      () => {
        if (shrunkKey) {
          setShrunk({ ...shrunk, [shrunkKey]: false })
        }
        let navigatePath = baseUrl ? `${baseUrl}${navDeviceId}` : null
        if (navModuleIndex !== null) {
          navigatePath += `/module/${navModuleIndex}`
          if (navChannelIndex !== null) {
            navigatePath += `/channel/${navChannelIndex}`
          }
        }
        navigate(navigatePath)
      }

    return (
      <div className="fl-container-row wizard-menu">
        {device ? (
          <ListView stretch={false}>
            <ListViewItem>
              <div className="item-row components-heading">
                <h2>{_(t.components)}</h2>
              </div>
            </ListViewItem>
            <ListViewItem>
              <div className="item-row show-only-active">
                <SbCheckbox
                  id="show-only-active"
                  checked={onlyActive}
                  onClick={handleOnShowOnlyActiveClicked}
                  value="1"
                >
                  {_(t.showOnlyActiveChannels)}
                </SbCheckbox>
              </div>
            </ListViewItem>
            <ListViewItem onClick={navigateToSubpageFactory(rootId || device.id, rootId || device.id)}>
              <div
                className={classNames('item-row test-type-measurement-unit', {
                  'current-page-item': moduleIndex === undefined,
                })}
              >
                <span
                  className={`wizard-menu-toggle m-l-10 ${getExpansionChevron(device.id)}`}
                  onClick={toggleShrunkFactory(device.id)}
                />
                {rowSelectionChange &&
                  (() => {
                    const checkedIdentifier = getNavigationSelectionId(
                      navigationSelectionItemType.FULL_DEVICE,
                      device.id,
                    )
                    return (
                      <div
                        className="row-selection-wrapper m-l-10"
                        onClick={evt => {
                          evt.stopPropagation()
                        }}
                      >
                        <SbCheckbox
                          checked={rowsSelected[checkedIdentifier] === SELECTED}
                          indeterminate={rowsSelected[checkedIdentifier] === INDETERMINATE}
                          onClick={evt => {
                            rowSelectionChange(
                              navigationSelectionItemType.FULL_DEVICE,
                              checkedIdentifier,
                              evt.target.checked ? SELECTED : UNSELECTED,
                            )
                          }}
                        />
                      </div>
                    )
                  })()}
                <DeviceComponentIcon className="m-l-10" types={device.types} />
                <span className="m-l-10 wizard-menu-name-wrapper">
                  <span
                    className={classNames('wizard-menu-name', {
                      'status-dirty': computeDirtyState && device.isDirty(),
                    })}
                  >
                    {device.name}
                  </span>
                </span>
                <StatusBullet
                  title={device.online ? _(t.online) : _(t.offline)}
                  className="m-r-10 m-l-5"
                  status={device.online ? GREEN : RED}
                />
                {isDeviceCalibrable && (
                  <div className="device-calibration-status m-r-10">
                    {(() => {
                      if (!deviceCalibration.isLoaded) {
                        return <SbLoader size={loaderSize.XXS} show />
                      }
                      if (deviceCalibration.isExpiring) {
                        return (
                          <SbHint
                            hintData={_(t.calibrationExpired)}
                            position={{ vertical: 'top', horizontal: 'center' }}
                          >
                            <span className="sbi-help-info calibration-information" />
                          </SbHint>
                        )
                      }
                      if (deviceCalibration.calibrationDate) {
                        return <span className="sbi-big-success calibration-valid" />
                      }
                      return (
                        <SbHint
                          hintData={_(t.calibrationStatusUnknown)}
                          position={{ vertical: 'top', horizontal: 'center' }}
                        >
                          <span className="sbi-big-hint calibration-unknown" />
                        </SbHint>
                      )
                    })()}
                  </div>
                )}
                {hasLED && <IdentifyButton online={device.online} deviceId={device.id} className="m-r-10" />}
              </div>
              {rowSelectionChange &&
                !shrunk[device.id] &&
                (() => {
                  const checkedIdentifier = getNavigationSelectionId(
                    navigationSelectionItemType.DEVICE_CONFIG_PROPS,
                    device.id,
                  )
                  return (
                    <div
                      className={classNames('item-row test-type-device-settings', {
                        'current-page-item': moduleIndex === undefined,
                      })}
                    >
                      <SbCheckbox
                        className="m-l-50"
                        checked={rowsSelected[checkedIdentifier] === SELECTED}
                        indeterminate={rowsSelected[checkedIdentifier] === INDETERMINATE}
                        onClick={evt => {
                          rowSelectionChange(
                            navigationSelectionItemType.DEVICE_CONFIG_PROPS,
                            checkedIdentifier,
                            evt.target.checked ? SELECTED : UNSELECTED,
                          )
                        }}
                      >
                        {_(t.settings)}
                      </SbCheckbox>
                    </div>
                  )
                })()}
            </ListViewItem>
            {!shrunk[device.id] && (
              <>
                {device.deviceSpecific?.controller &&
                  !device.deviceSpecific.controller.isVirtual() &&
                  (() => {
                    const module = device.deviceSpecific.controller
                    return (
                      <ListViewItem>
                        <div
                          onClick={
                            baseUrl ? navigateToSubpageFactory(null, rootId || device.id, 'controller') : undefined
                          }
                          className={classNames('item-row test-type-controller', {
                            'current-page-item': moduleIndex === 'controller' && channelIndex === undefined,
                          })}
                        >
                          <DeviceComponentIcon className="m-l-45" types={module.types} width={25} height={25} />
                          <span className="m-l-10 wizard-menu-name-wrapper">
                            <span
                              className={classNames('wizard-menu-name', {
                                'status-dirty': computeDirtyState && module.isDirty(),
                              })}
                            >
                              {_(t.controllerType, { type: module.getType() })}
                            </span>
                          </span>
                        </div>
                      </ListViewItem>
                    )
                  })()}
                {device.modules.map((module, rowModuleIndex) => {
                  const moduleItemIdentifier = `${device.id}/${module.resourcePath}`
                  const moduleTypeNumber = module.getType()
                  const moduleSerialNumber = module.parametersReadable?.snr || device.serialNumber
                  return (
                    <React.Fragment key={moduleItemIdentifier}>
                      {!module.isVirtual() && (
                        <ListViewItem
                          onClick={
                            baseUrl
                              ? navigateToSubpageFactory(moduleItemIdentifier, rootId || device.id, rowModuleIndex)
                              : undefined
                          }
                        >
                          <div
                            className={classNames(`item-row test-type-module-${rowModuleIndex}`, {
                              'current-page-item': moduleIndex === rowModuleIndex && channelIndex === undefined,
                            })}
                          >
                            <span
                              className={`wizard-menu-toggle m-l-20 ${getExpansionChevron(moduleItemIdentifier)} ${
                                shrunk[moduleItemIdentifier] ? 'test-module-expanded' : 'test-module-collapsed'
                              }`}
                              onClick={toggleShrunkFactory(moduleItemIdentifier)}
                            />
                            {rowSelectionChange &&
                              (() => {
                                const checkedIdentifier = getNavigationSelectionId(
                                  navigationSelectionItemType.FULL_MODULE,
                                  device.id,
                                  rowModuleIndex,
                                )
                                return (
                                  <div
                                    className="row-selection-wrapper m-l-10"
                                    onClick={evt => {
                                      evt.stopPropagation()
                                    }}
                                  >
                                    <SbCheckbox
                                      checked={rowsSelected[checkedIdentifier] === SELECTED}
                                      indeterminate={rowsSelected[checkedIdentifier] === INDETERMINATE}
                                      onClick={evt => {
                                        rowSelectionChange(
                                          navigationSelectionItemType.FULL_MODULE,
                                          checkedIdentifier,
                                          evt.target.checked ? SELECTED : UNSELECTED,
                                        )
                                      }}
                                    />
                                  </div>
                                )
                              })()}
                            <DeviceComponentIcon className="m-l-10" types={module.types} width={25} height={25} />
                            <span className="m-l-10 wizard-menu-name-wrapper">
                              <span
                                className={classNames('wizard-menu-name', {
                                  'status-dirty': computeDirtyState && module.isDirty(),
                                })}
                              >
                                {_(t.moduleType, { type: module.getType() })}
                              </span>
                            </span>
                          </div>
                          {rowSelectionChange &&
                            !shrunk[moduleItemIdentifier] &&
                            (() => {
                              const checkedIdentifier = getNavigationSelectionId(
                                navigationSelectionItemType.MODULE_CONFIG_PROPS,
                                device.id,
                                rowModuleIndex,
                              )
                              return (
                                <div
                                  className={classNames('item-row test-type-module-settings', {
                                    'current-page-item': moduleIndex === rowModuleIndex && channelIndex === undefined,
                                  })}
                                >
                                  <SbCheckbox
                                    className="m-l-75"
                                    checked={rowsSelected[checkedIdentifier] === SELECTED}
                                    indeterminate={rowsSelected[checkedIdentifier] === INDETERMINATE}
                                    onClick={evt => {
                                      rowSelectionChange(
                                        navigationSelectionItemType.MODULE_CONFIG_PROPS,
                                        checkedIdentifier,
                                        evt.target.checked ? SELECTED : UNSELECTED,
                                      )
                                    }}
                                  >
                                    {_(t.settings)}
                                  </SbCheckbox>
                                </div>
                              )
                            })()}
                        </ListViewItem>
                      )}
                      {!shrunk[moduleItemIdentifier] &&
                        module[onlyActive ? 'getActiveChannels' : 'getChannels']().map(channel => {
                          const channelItemIdentifier = `${baseUrl}${rootId || device.id}/${channel.resourcePath}`
                          const rowChannelIndex = channel.getChannelIndex()
                          const slot = `channels/${rowChannelIndex + 1}`
                          const channelChain =
                            deviceChainWithNamesAndCalibrationStatus?.find(
                              chainEntry =>
                                chainEntry.channel.slot === slot &&
                                chainEntry.channel.typeNumber === moduleTypeNumber &&
                                chainEntry.channel.serialNumber === moduleSerialNumber,
                            ) || {}
                          return (
                            <ListViewItem
                              key={channelItemIdentifier}
                              className={classNames({
                                'selected-channel-item':
                                  moduleIndex === rowModuleIndex && channelIndex === rowChannelIndex,
                                clickable: !!baseUrl,
                              })}
                              onClick={
                                baseUrl
                                  ? navigateToSubpageFactory(
                                      channelItemIdentifier,
                                      rootId || device.id,
                                      rowModuleIndex,
                                      rowChannelIndex,
                                    )
                                  : undefined
                              }
                            >
                              <div
                                className={classNames('item-row test-type-channel', {
                                  'current-page-item':
                                    moduleIndex === rowModuleIndex && channelIndex === rowChannelIndex,
                                })}
                              >
                                {showChainComponents && (
                                  <span
                                    className={`wizard-menu-toggle m-l-20 ${getExpansionChevron(
                                      channelItemIdentifier,
                                    )} ${
                                      shrunk[channelItemIdentifier] ? 'test-channel-collapsed' : 'test-channel-expanded'
                                    }`}
                                    onClick={toggleShrunkFactory(channelItemIdentifier)}
                                  />
                                )}
                                {rowSelectionChange &&
                                  (() => {
                                    const checkedIdentifier = getNavigationSelectionId(
                                      navigationSelectionItemType.CHANNEL,
                                      device.id,
                                      rowModuleIndex,
                                      rowChannelIndex,
                                    )
                                    return (
                                      <div
                                        className="row-selection-wrapper m-l-75"
                                        onClick={evt => {
                                          evt.stopPropagation()
                                        }}
                                      >
                                        <SbCheckbox
                                          checked={rowsSelected[checkedIdentifier] === SELECTED}
                                          indeterminate={rowsSelected[checkedIdentifier] === INDETERMINATE}
                                          onClick={evt => {
                                            rowSelectionChange(
                                              navigationSelectionItemType.CHANNEL,
                                              checkedIdentifier,
                                              evt.target.checked ? SELECTED : UNSELECTED,
                                            )
                                          }}
                                        />
                                      </div>
                                    )
                                  })()}
                                <span
                                  className={`sbi-calibration ${
                                    showChainComponents || rowSelectionChange ? 'm-l-10' : 'm-l-45'
                                  }`}
                                />
                                <span className="m-l-10 wizard-menu-name-wrapper">
                                  <span
                                    className={classNames('wizard-menu-name', {
                                      'status-dirty': computeDirtyState && channel.isDirty(),
                                    })}
                                  >
                                    {_(t.channelIndex, { index: rowChannelIndex + 1 })}
                                  </span>
                                </span>
                                {showContextMenu && (
                                  <ChannelMenu
                                    channelChain={channelChain}
                                    channelIndex={rowChannelIndex}
                                    className="m-r-10"
                                  />
                                )}
                              </div>
                              {showChainComponents && !shrunk[channelItemIdentifier] && (
                                <>
                                  <div
                                    className={classNames(
                                      `item-row ${
                                        channelChain.sensor?.typeNumber ? 'sensor-component' : 'empty-component'
                                      }`,
                                      {
                                        'current-page-item':
                                          moduleIndex === rowModuleIndex && channelIndex === rowChannelIndex,
                                      },
                                    )}
                                    onClick={() => {
                                      dispatch(
                                        openModal(ADD_COMPONENT_MODAL_ID, {
                                          deviceId: device.id,
                                          moduleIndex: rowModuleIndex,
                                          channelIndex: rowChannelIndex,
                                          componentToAdd: addComponentModalPossibleComponents.SENSOR,
                                        }),
                                      )
                                    }}
                                  >
                                    <span className="sbi-sensor m-l-45" />
                                    {(() => {
                                      const sensor = channelChain.sensor || {}
                                      let textToDisplay
                                      if (sensor.name) {
                                        textToDisplay = sensor.name
                                      } else if (sensor.typeNumber) {
                                        textToDisplay = `${sensor.typeNumber} / ${sensor.serialNumber || '-'}`
                                      } else {
                                        textToDisplay = _(t.noSensor)
                                      }
                                      return (
                                        <span className="wizard-menu-name m-l-10" title={textToDisplay}>
                                          {textToDisplay}
                                        </span>
                                      )
                                    })()}
                                    {(() => {
                                      if (!channelChain?.sensor?.calibrationStatus) {
                                        return null
                                      }
                                      if (channelChain.sensor.calibrationStatus === CALIBRATION_STATUSES.VALID) {
                                        return (
                                          <span className="sensor-certificate-status sbi-big-success calibration-valid" />
                                        )
                                      }
                                      if (channelChain.sensor.calibrationStatus === CALIBRATION_STATUSES.EXPIRED) {
                                        return (
                                          <SbHint hintData={_(t.calibrationExpired)}>
                                            <span className="sensor-certificate-status sbi-help-info calibration-information" />
                                          </SbHint>
                                        )
                                      }
                                      return (
                                        <SbHint hintData={_(t.calibrationStatusUnknown)}>
                                          <span className="sensor-certificate-status sbi-big-hint calibration-unknown" />
                                        </SbHint>
                                      )
                                    })()}
                                  </div>
                                  <div
                                    className={classNames(
                                      `item-row ${
                                        channelChain.cable?.typeNumber ? 'cable-component' : 'empty-component'
                                      }`,
                                      {
                                        'current-page-item':
                                          moduleIndex === rowModuleIndex && channelIndex === rowChannelIndex,
                                      },
                                    )}
                                    onClick={() => {
                                      dispatch(
                                        openModal(ADD_COMPONENT_MODAL_ID, {
                                          deviceId: device.id,
                                          moduleIndex: rowModuleIndex,
                                          channelIndex: rowChannelIndex,
                                          componentToAdd: addComponentModalPossibleComponents.CABLE,
                                        }),
                                      )
                                    }}
                                  >
                                    <span className="sbi-cable-kids m-l-45" />

                                    {(() => {
                                      let textToDisplay
                                      const cable = channelChain.cable || {}
                                      if (cable.name) {
                                        textToDisplay = cable.name
                                      } else {
                                        textToDisplay = cable.typeNumber
                                          ? _(t.typeNumberCable, { typeNumber: cable.typeNumber })
                                          : _(t.noCable)
                                      }
                                      return (
                                        <span className="wizard-menu-name m-l-10" title={textToDisplay}>
                                          {textToDisplay}
                                        </span>
                                      )
                                    })()}
                                    {(() => {
                                      switch (channelChain?.cable?.compatibilityStatus) {
                                        case CABLE_COMPATIBILITY.INVALID:
                                          return (
                                            <span className="sensor-cable-compatibility sbi-big-error compatibility-error" />
                                          )
                                        case CABLE_COMPATIBILITY.VALID:
                                          return (
                                            <SbHint hintData={_(t.compatibilityWithSensorGuaranteed)}>
                                              <span className="sensor-cable-compatibility sbi-big-success compatibility-valid" />
                                            </SbHint>
                                          )
                                        case CABLE_COMPATIBILITY.UNKNOWN:
                                          return (
                                            <span className="sensor-cable-compatibility sbi-minus-circle compatibility-unknown" />
                                          )
                                        default:
                                          return null
                                      }
                                    })()}
                                  </div>
                                </>
                              )}
                            </ListViewItem>
                          )
                        })}
                    </React.Fragment>
                  )
                })}
              </>
            )}
          </ListView>
        ) : (
          <SbLoader show />
        )}
      </div>
    )
  },
)

WizardMenu.propTypes = {
  baseUrl: PropTypes.string,
  explicitDeviceRef: PropTypes.object,
  showContextMenu: PropTypes.bool,
  showChainComponents: PropTypes.bool,
  computeDirtyState: PropTypes.bool,
  rootId: PropTypes.string,
  rowSelectionChange: PropTypes.func,
  rowsSelected: PropTypes.object,
}
