/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  presetDatetime: {
    id: 'snippetsActions.presetDatetime',
    defaultMessage: 'Preset {DATETIME}',
  },
  newPresetCreated: {
    id: 'snippetsActions.newPresetCreated',
    defaultMessage: 'New preset created',
  },
  couldNotApplyPresetOnTheDevice: {
    id: 'snippetsActions.couldNotApplyPresetOnTheDevice',
    defaultMessage: 'Could not apply preset on the device',
  },
})
