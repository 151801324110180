import { COMMIT_TIME_MS } from './component-committer-constants'

export class ComponentCommitter {
  constructor(component) {
    this.component = component
    this.commitTimer = null
  }

  handleOnChange = (event, ...args) => {
    const { onCommit } = this.component.props
    if (onCommit) {
      const value = event?.target?.value || event
      clearTimeout(this.commitTimer)
      this.commitTimer = setTimeout(onCommit, COMMIT_TIME_MS, value, ...args)
    }
  }

  handleOnBlur = (event, ...args) => {
    const { onBlur, onCommit, value: componentValue, disabled } = this.component.props

    if (disabled) {
      return
    }
    if (onCommit) {
      clearTimeout(this.commitTimer)
      let value
      if (componentValue !== undefined) {
        value = componentValue
      } else if (event?.target?.value !== undefined) {
        value = event.target.value
      } else {
        value = event
      }
      onCommit(value, ...args)
    }
    if (onBlur) {
      onBlur(event, ...args)
    }
  }
}
