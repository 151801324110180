import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbHint } from 'skybase-ui/skybase-components/sb-hint'
import { horizontalPosition, verticalPosition } from 'skybase-ui/skybase-components/sb-hint/constants'
import { SbModal, SbModalHeader } from 'skybase-ui/skybase-components/sb-modal'
import { SbButton } from 'skybase-ui/skybase-components/sb-button'
import { SbLabel } from 'skybase-ui/skybase-components/sb-label'
import { SbTextbox } from 'skybase-ui/skybase-components/sb-textbox'
import { SbInlineMessage } from 'skybase-ui/skybase-components/sb-inline-message/sb-inline-message'
import { INLINE_MESSAGE_TYPE_ERROR } from 'skybase-ui/skybase-components/sb-inline-message/constants'
import { SbTextarea } from 'skybase-ui/skybase-components/sb-textarea'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { CALIBRATION_EXPLANATION_URLS_BY_LANGUAGE } from '@/fleet-configuration/data-fleet/devices-calibrations/devices-calibrations-constants'
import { email as emailValidator } from '@/fleet-configuration/validation/validators'
import { calibrationBoxSelector } from '@/fleet-configuration/components/calibration/calibration-box/calibration-box-selector'
import {
  sendOrderCalibrationMail,
  setCalibrationFormData,
  setCalibrationFormDataErrors,
  tryLoadCalibrationUserData,
} from '@/fleet-configuration/data-fleet/calibration/calibration-actions'
import { messages as t } from './calibration-box-i18n'
import { loadProjectDevice } from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'

const REQUIRED_ORDER_CALIBRATION_FIELDS = ['email', 'name', 'company', 'typeNumber', 'serialNumber']

class _CalibrationBox extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    dispatch: PropTypes.func.isRequired,
    languageOfLocale: PropTypes.string.isRequired,
    typeNumber: PropTypes.string.isRequired,
    serialNumber: PropTypes.string.isRequired,
    calibrationContent: PropTypes.any.isRequired,
    userId: PropTypes.string,
    tenantId: PropTypes.string,
    userData: PropTypes.object,
    formDataErrors: PropTypes.object,
    formData: PropTypes.object,
    deviceId: PropTypes.string,
    device: PropTypes.object,
  }

  static defaultProps = {
    userId: '',
    tenantId: '',
    userData: null,
    formDataErrors: {},
    formData: null,
    deviceId: null,
    device: null,
  }

  createCalibrationId(typeNumber, serialNumber) {
    return JSON.stringify({ typeNumber, serialNumber })
  }

  componentDidMount() {
    const { dispatch, tenantId, userId, typeNumber, serialNumber, formData, deviceId, device } = this.props
    dispatch(tryLoadCalibrationUserData(tenantId, userId))
    const currentCalibrationId = this.createCalibrationId(typeNumber, serialNumber)
    if (formData && formData.id !== currentCalibrationId) {
      dispatch(setCalibrationFormData(null))
    }
    if (deviceId && !device) {
      dispatch(loadProjectDevice(deviceId))
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch, typeNumber, serialNumber, formData, deviceId, device } = this.props
    // if we are on same calibration item, do not bother
    if (prevProps.typeNumber === typeNumber && prevProps.serialNumber === serialNumber) {
      return
    }
    // cleanup as we are on new item
    if (formData) {
      dispatch(setCalibrationFormData(null))
    }
    if (deviceId && !device) {
      dispatch(loadProjectDevice(deviceId))
    }
  }

  orderCalibrationClickHandler = () => {
    const {
      dispatch,
      userData,
      typeNumber,
      serialNumber,
      device,
      intl: { formatMessage: _ },
    } = this.props

    const newFormData = {
      ...userData,
      id: JSON.stringify({ typeNumber, serialNumber }),
      typeNumber,
      serialNumber,
    }
    if (device?.isKGate()) {
      newFormData.note = _(t.calibrateAlsoItsModules, {
        modules:
          '\n' +
          device.modules
            .map((module, index) =>
              _(t.calibrationModuleLine, {
                index: index + 1,
                typeNumber: module.getType(),
                serialNumber: module.parametersReadable?.snr,
              }),
            )
            .join(',\n'),
      })
    }
    dispatch(setCalibrationFormData(newFormData))
    // reset validation errors according to current values (that possibly changed in this function)
    this.handleValidationError('email')(this.emailFieldValidators(userData.email).filter(alert => !!alert))
  }

  orderCalibrationModalClickOverlay = () => {
    const { dispatch } = this.props
    dispatch(setCalibrationFormData(null))
  }

  orderCalibration = async () => {
    const {
      formData: { email, name, company, note, typeNumber, serialNumber },
      intl: { formatMessage: _ },
    } = this.props
    const isCalibrationMailSent = sendOrderCalibrationMail(
      `${_(t.formSubject)} ${_(t.formRequestTypeCalibration)} (${_(t.formSubjectAdd)})`,
      email,
      name,
      company,
      typeNumber,
      serialNumber,
      note,
    )
    if (isCalibrationMailSent) {
      this.orderCalibrationModalClickOverlay()
    }
  }

  handleOrderCalibrationParamChange(field) {
    const { dispatch } = this.props
    return evtOrValue => {
      const { formData } = this.props
      const value = evtOrValue?.target ? evtOrValue.target.value : evtOrValue
      dispatch(setCalibrationFormData({ ...formData, [field]: value }))
    }
  }

  handleValidationError(property) {
    const { dispatch } = this.props
    return alert => dispatch(setCalibrationFormDataErrors(property, alert))
  }

  isOrderCalibrationButtonDisabled = () => {
    const { formData, formDataErrors } = this.props
    return (
      REQUIRED_ORDER_CALIBRATION_FIELDS.some(field => !formData[field]?.trim()) ||
      Object.values(formDataErrors).some(error => error.length)
    )
  }

  emailFieldValidators = value => [emailValidator(t.emailAddressProvidedIsNotValid, value)]

  renderOrderCalibrationModal() {
    const {
      formData: { email, name, company, note, typeNumber, serialNumber },
      formDataErrors,
      userData,
      intl: { formatMessage: _ },
    } = this.props
    return (
      <SbModal
        onClickOverlay={() => this.orderCalibrationModalClickOverlay()}
        onSubmit={() => this.orderCalibration()}
        width="566px"
        Header={
          <SbModalHeader
            onCloseBtnClick={() => this.orderCalibrationModalClickOverlay()}
            id={uuidv4()}
            title={_(t.calibrationServiceOrder)}
          />
        }
        Footer={
          <div className="sb-modal-footer fl-container fl-justify-end">
            <div className="sb-modal-left">
              <button
                disabled={this.isOrderCalibrationButtonDisabled()}
                className="sb-btn primary test-order-send-button"
                onClick={() => this.orderCalibration()}
                type="button"
              >
                {_(t.send)}
              </button>
            </div>
            <div className="sb-modal-center" />
            <div className="sb-modal-right">
              <button
                className="sb-btn test-order-calibration-cancel-button"
                onClick={() => this.orderCalibrationModalClickOverlay()}
                type="button"
              >
                {_(t.cancel)}
              </button>
            </div>
          </div>
        }
      >
        {userData ? (
          <>
            <SbLabel title={_(t.email)} required>
              <SbTextbox
                maxLength={64}
                value={email}
                onChange={this.handleOrderCalibrationParamChange('email')}
                className="test-order-calibration-email"
                onValidate={this.emailFieldValidators}
                onValidated={this.handleValidationError('email')}
                hasError={!!formDataErrors.email?.length}
              />
              {formDataErrors.email?.length ? (
                <SbInlineMessage
                  title={_(t.validationError)}
                  type={INLINE_MESSAGE_TYPE_ERROR}
                  message={formDataErrors.email[0].message}
                />
              ) : null}
            </SbLabel>
            <div className="order-calibration-columns-2">
              <SbLabel title={_(t.fullName)} required>
                <SbTextbox
                  maxLength={64}
                  value={name}
                  onChange={this.handleOrderCalibrationParamChange('name')}
                  className="test-order-calibration-name"
                />
              </SbLabel>
              <SbLabel title={_(t.company)} required>
                <SbTextbox
                  maxLength={64}
                  value={company}
                  onChange={this.handleOrderCalibrationParamChange('company')}
                  className="test-order-calibration-company"
                />
              </SbLabel>
            </div>
            <div className="order-calibration-columns-2">
              <SbLabel title={_(t.typeNumber)} required>
                <SbTextbox
                  maxLength={16}
                  value={typeNumber}
                  onChange={this.handleOrderCalibrationParamChange('typeNumber')}
                  className="test-order-calibration-typeNumber"
                />
              </SbLabel>
              <SbLabel title={_(t.serialNumber)} required>
                <SbTextbox
                  maxLength={32}
                  value={serialNumber}
                  onChange={this.handleOrderCalibrationParamChange('serialNumber')}
                  className="test-order-calibration-serialNumber"
                />
              </SbLabel>
            </div>
            <SbLabel title={_(t.note)}>
              <SbTextarea
                maxLength={1024}
                className="order-calibration-form-textarea test-order-calibration-note"
                value={note}
                onChange={this.handleOrderCalibrationParamChange('note')}
                placeholder={_(t.note)}
              />
            </SbLabel>
          </>
        ) : (
          <SbLoader show />
        )}
      </SbModal>
    )
  }

  render() {
    const {
      languageOfLocale,
      calibrationContent,
      intl: { formatMessage: _ },
      formData,
      userData,
    } = this.props

    return (
      <div className="calibration-wrapper">
        <h2 className="calibration-heading">
          {_(t.calibration)}
          <SbHint
            wrapperClassName="calibration-heading-hint"
            position={{ horizontal: horizontalPosition.CENTER, vertical: verticalPosition.TOP }}
            hintData={<>{_(t.calibrationExplanationHint, { newLine: <br /> })}</>}
          >
            <a
              href={
                CALIBRATION_EXPLANATION_URLS_BY_LANGUAGE[languageOfLocale] ||
                CALIBRATION_EXPLANATION_URLS_BY_LANGUAGE.en
              }
              target="_blank"
              rel="noreferrer"
              className="sbi-help-info"
            >
              {_(t.calibrationExplanationHint, { newLine: <br /> })}
            </a>
          </SbHint>
        </h2>
        {calibrationContent}
        {userData ? (
          <>
            <SbButton className="order-calibration primary" onClick={this.orderCalibrationClickHandler}>
              {_(t.orderCalibration)}
            </SbButton>
            {formData && this.renderOrderCalibrationModal()}
          </>
        ) : (
          <SbLoader show />
        )}
      </div>
    )
  }
}

export const CalibrationBox = injectIntl(connect(calibrationBoxSelector)(_CalibrationBox))
