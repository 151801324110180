export const unsupportedChannelFactory = (channel, deviceId, moduleIndex, channelIndex) => {
  class ChannelModel {
    constructor() {
      Object.assign(this, channel, { disable: true })
      if (!this.path) {
        this.path = 'unsupported'
      }
    }

    getDeviceId() {
      return deviceId
    }

    getModuleIndex() {
      return moduleIndex
    }

    getChannelIndex() {
      return channelIndex
    }

    getChannelType() {
      return '-'
    }

    getConnectionImage() {
      return { src: undefined, mimeType: undefined }
    }

    isEditablePhysicalRangeFrom() {
      return false
    }

    setParameter() {}

    setDefaultsIfNotSet() {
      this.parameters.enabled = false
    }

    isSupported() {
      return false
    }

    validate() {
      return []
    }

    toDto() {
      return this
    }

    hasField() {
      return false
    }

    subResourceHasField() {
      return false
    }

    hasOption() {
      return false
    }

    isActive() {
      return false
    }

    isDirty() {
      return false
    }

    updateSpanningChannels() {}

    isPartiallyActive() {
      return false
    }

    isNegativeSensitivityAllowed() {
      return false
    }

    isLabAmp() {
      return false
    }

    isKiDAQ() {
      return false
    }
  }

  return new ChannelModel()
}
