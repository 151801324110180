import { get, reduce } from 'lodash'
import {
  greaterEqualThan,
  greaterThan,
  isNumber,
  lessEqualThan,
  lessThan,
  maxLength,
  notEqual,
  required,
} from '@/fleet-configuration/validation/validators'
import {
  getSensitivitySensorUnitFactor,
  getZeroOffsetToBaseUnit,
} from '@/fleet-configuration/data-fleet/project-devices/channel-utils'
import { messages as t } from '@/fleet-configuration/data-fleet/project-devices/channel-model-factory-i18n'

export class ChannelValidator {
  validationRules = {}

  constructor(catalog, deviceId, moduleIndex, channelIndex) {
    this.catalog = catalog
    this.deviceId = deviceId
    this.moduleIndex = moduleIndex
    this.channelIndex = channelIndex
  }

  // when declaring validators always use channel instead of srcChannel
  // like channel.minRangeTo()
  initValidationRules(srcChannel) {
    this.validationRules = {}

    const { catalog } = this

    this.initValidationRule('parameters.name', () => [required(t.signalNameIsRequired)])

    if (srcChannel.hasField('signalType')) {
      this.initValidationRule('parameters.signalType', () => [required(t.signalTypeIsRequired)])
    }
    if (srcChannel.hasField('sensorType')) {
      this.initValidationRule('parameters.sensorType', () => [required(t.sensorTypeIsRequired)])
    }
    if (srcChannel.hasField('sensorConnectorType')) {
      this.initValidationRule('parameters.sensorConnectorType', () => [required(t.sensorConnectorTypeIsRequired)])
    }
    if (srcChannel.hasField('sensorTypeDetail')) {
      this.initValidationRule('parameters.sensorTypeDetail', () => [required(t.sensorTypeDetailIsRequired)])
    }
    if (srcChannel.hasField('stage')) {
      this.initValidationRule('parameters.stage', () => [required(t.sensorTypeIsRequired)])
    }
    if (srcChannel.hasField('physicalQuantity')) {
      this.initValidationRule('parameters.physicalQuantity', () => [required(t.physicalQuantityIsRequired)])
    }

    if (srcChannel.hasField('pulseFactor')) {
      this.initValidationRule('parameters.pulseFactor', channel => {
        const pulseFactorValidators = [
          required(t.pulseFactorIsRequired),
          isNumber(t.pulseFactorShouldBeANumber),
          notEqual(t.pulseFactorShouldNotBeZero, 0),
        ]

        if (srcChannel.hasField('sensitivity') && srcChannel.hasField('unit')) {
          const sensitivitySensorUnitFactor = getSensitivitySensorUnitFactor(channel.getChannelOptions(), channel)

          if (channel.min('sensitivity')) {
            pulseFactorValidators.push(
              greaterEqualThan(
                t.sensitivityShouldBeHigherThanOrEqualToOtherValue,
                sensitivitySensorUnitFactor * channel.min('sensitivity'),
              ),
            )
          }

          if (channel.max('sensitivity')) {
            pulseFactorValidators.push(
              lessEqualThan(
                t.sensitivityShouldBeLowerThanOrEqualToOtherValue,
                sensitivitySensorUnitFactor * channel.max('sensitivity'),
              ),
            )
          }
        }

        return pulseFactorValidators
      })
    } else if (srcChannel.hasField('sensitivity') && srcChannel.hasField('unit')) {
      this.initValidationRule('parameters.sensitivity', channel => {
        const sensitivityValidators = [
          required(t.sensitivityIsRequired),
          isNumber(t.sensitivityShouldBeANumber),
          notEqual(t.sensitivityShouldNotBeZero, 0),
        ]

        const sensitivitySensorUnitFactor = getSensitivitySensorUnitFactor(channel.getChannelOptions(), channel)

        if (channel.min('sensitivity')) {
          sensitivityValidators.push(
            greaterEqualThan(
              t.sensitivityShouldBeHigherThanOrEqualToOtherValue,
              sensitivitySensorUnitFactor * channel.min('sensitivity'),
            ),
          )
        }

        if (channel.max('sensitivity')) {
          sensitivityValidators.push(
            lessEqualThan(
              t.sensitivityShouldBeLowerThanOrEqualToOtherValue,
              sensitivitySensorUnitFactor * channel.max('sensitivity'),
            ),
          )
        }

        return sensitivityValidators
      })
    }

    if (srcChannel.hasField('sensitivityUnit') && !srcChannel.hasField('pulseFactor')) {
      this.initValidationRule('parameters.sensitivityUnit', () => [required(t.sensitivityUnitIsRequired)])
    }

    if (srcChannel.hasField('physicalUnit')) {
      this.initValidationRule('parameters.physicalUnit', () => [required(t.physicalUnitIsRequired)])
    }
    if (srcChannel.hasCustomUnit()) {
      this.initValidationRule('parameters.physicalUnit', () => [required(t.customUnitIsRequired)])
    }

    if (srcChannel.hasField('timeBase')) {
      this.initValidationRule('parameters.timeBase', channel => {
        const timeBaseValidators = [required(t.timeBaseIsRequired), isNumber(t.timeBaseShouldBeNumber)]

        if (channel.min('timeBase')) {
          timeBaseValidators.push(greaterEqualThan(t.timeBaseGreaterEqualThan, channel.min('timeBase')))
        }

        if (channel.max('timeBase')) {
          timeBaseValidators.push(lessEqualThan(t.timeBaseLesserEqualThan, channel.max('timeBase')))
        }

        return timeBaseValidators
      })
    }

    if (srcChannel.hasField('zeroOffset')) {
      this.initValidationRule('parameters.zeroOffset', channel => {
        const validationRules = [required(t.offsetIsRequired), isNumber(t.offsetShouldBeANumber)]

        // if range is available, add also max range check...
        const range = channel.getRange()
        if (range && channel.parameters.sensitivity && (!channel.hasCustomUnit() || channel.isLabAmp())) {
          const rangeValueInBaseUnit = getZeroOffsetToBaseUnit(channel, get(range, 'max.to'))
          if (rangeValueInBaseUnit) {
            validationRules.push(lessThan(t.zeroPointCorrectionMustBeLesserThanOtherValue, rangeValueInBaseUnit))
          }
        }

        return validationRules
      })
    }

    this.initValidationRule('parameters.physicalRange[0]', channel => {
      const physicalUnit =
        channel.getOptionDescriptionOrEmpty('physicalUnit', channel.parameters.physicalUnit) ||
        channel.parameters.physicalUnit

      const physicalRangeRules = [
        required(t.physicalRangeFromIsRequired),
        isNumber(t.physicalRangeFromShouldBeANumber),
        lessEqualThan(
          t.physicalRangeFromShouldBeLowerThanPhysicalRangeTo,
          (channel.parameters.physicalRange || [])[1],
          { unit: physicalUnit },
        ),
      ]

      if (channel.hasCustomUnit() && !channel.isLabAmp()) {
        physicalRangeRules.push(value => {
          if (channel.parameters.sensitivity < 0) {
            return greaterEqualThan(t.physicalRangeFromShouldBeGreaterThanOrEqualToOtherValue, channel.maxRangeTo(), {
              unit: physicalUnit,
            })(value)
          }
          return greaterEqualThan(t.physicalRangeFromShouldBeGreaterThanOrEqualToOtherValue, channel.minRangeFrom(), {
            unit: physicalUnit,
          })(value)
        })
        return physicalRangeRules
      }

      if (!channel.hasField('physicalRange', '0')) {
        return []
      }

      if (channel.maxRangeFrom() !== channel.minRangeFrom()) {
        physicalRangeRules.push(
          greaterEqualThan(t.physicalRangeFromShouldBeGreaterThanOrEqualToOtherValue, channel.minRangeFrom(), {
            unit: channel.parameters.physicalUnit,
          }),
          lessEqualThan(
            t.physicalRangeFromShouldBeLowerThanOrEqualToOtherValue,
            channel.maxRangeFrom(), // gantner can't process higher values than maxRangeFrom
            { unit: physicalUnit },
          ),
        )
      }
      return physicalRangeRules
    })

    this.initValidationRule('parameters.physicalRange[1]', channel => {
      if (!channel.hasField('physicalRange', '1')) {
        return []
      }

      const physicalUnit =
        channel.getOptionDescriptionOrEmpty('physicalUnit', channel.parameters.physicalUnit) ||
        channel.parameters.physicalUnit

      const isBridgeSensorType = channel.isBridgeSensorType()

      let minToRange
      if (isBridgeSensorType) {
        minToRange = 0
      } else {
        const sensitivityActiveFieldValue = channel.parameters.sensitivity || channel.parameters.pulseFactor || 1
        if (sensitivityActiveFieldValue < 0 && !channel.isLabAmp()) {
          minToRange = channel.maxRangeTo()
        } else {
          minToRange = channel.minRangeTo()
        }
      }
      const physicalRangeRules = [
        required(t.physicalRangeToIsRequired),
        isNumber(t.physicalRangeToShouldBeANumber),
        // handle minimal allowed "to" value (it's right-side field = larger value out of physical ranges)
        greaterEqualThan(t.physicalRangeShouldGreaterThan, minToRange, {
          unit: physicalUnit,
        }),
      ]

      // if we show both From and To physical ranges
      if (channel.hasField('physicalRange', '0') || channel.hasCustomUnit()) {
        physicalRangeRules.push(
          // From value should always be numerically lower than To value (left field's form input value vs right field's value)
          greaterEqualThan(t.physicalRangeFromShouldBeLowerThanPhysicalRangeTo, channel.parameters.physicalRange?.[0], {
            unit: physicalUnit,
          }),
        )
        // handle maximal allowed "to" value
        physicalRangeRules.push(value => {
          if (channel.parameters.sensitivity < 0 && !channel.isLabAmp()) {
            return lessEqualThan(t.physicalRangeShouldBeLowerThanOrEqualToOtherValue, channel.minRangeFrom(), {
              unit: physicalUnit,
            })(value)
          }
          return lessEqualThan(t.physicalRangeShouldBeLowerThanOrEqualToOtherValue, channel.maxRangeTo(), {
            unit: physicalUnit,
          })(value)
        })
      } else if (!isBridgeSensorType) {
        const higherValue = Math.max(channel.minRangeFrom(), channel.maxRangeTo())
        physicalRangeRules.push(
          lessEqualThan(t.physicalRangeShouldBeLowerThanOrEqualToOtherValue, higherValue, {
            unit: physicalUnit,
          }),
        )
      }

      return physicalRangeRules
    })

    if (srcChannel.hasField('physicalUnit')) {
      const physicalUnitRules = [maxLength(t.customUnitMustBeAtMostNCharactersLong, 6)]
      const { sensorType, physicalQuantity } = srcChannel.parameters
      if (
        (srcChannel.isAnalogInput() && (sensorType !== 'Potentiometer' || physicalQuantity !== '')) ||
        srcChannel.hasField('customUnit')
      ) {
        physicalUnitRules.push(required(t.customUnitIsRequired))
      }
      this.initValidationRule('parameters.physicalUnit', () => physicalUnitRules)
    }

    if (srcChannel.hasField('switchingLevelLower') && srcChannel.hasField('switchingLevelUpper')) {
      const switchingLevelLowerUnit = srcChannel.unit('switchingLevelLower') || ''
      const switchingLevelUpperUnit = srcChannel.unit('switchingLevelUpper') || ''

      this.initValidationRule('parameters.switchingLevelLower', channel => [
        required(t.switchingLevelLowerBoundNeedsToBeDefined),
        lessEqualThan(t.switchingLevelLowerBoundNeedsToBeAtMostUpperBound, channel.max('switchingLevelLower'), {
          upperBound: channel.max('switchingLevelLower'),
          unit: switchingLevelLowerUnit,
        }),
        greaterEqualThan(t.switchingLevelLowerBoundNeedsToBeAtLeastLowerBound, channel.min('switchingLevelLower'), {
          lowerBound: channel.min('switchingLevelLower'),
          unit: switchingLevelLowerUnit,
        }),
      ])

      // noinspection JSUnresolvedReference
      this.initValidationRule('parameters.switchingLevelUpper', channel => [
        required(t.switchingLevelUpperBoundNeedsToBeDefined),
        greaterEqualThan(
          t.switchingLevelLowerBoundNeedsToBeLowerThenUpperBound,
          channel.parameters.switchingLevelLower,
        ),
        lessEqualThan(t.switchingLevelUpperBoundNeedsToBeAtMostUpperBound, channel.max('switchingLevelUpper'), {
          upperBound: channel.max('switchingLevelUpper'),
          unit: switchingLevelUpperUnit,
        }),
        greaterEqualThan(t.switchingLevelUpperBoundNeedsToBeAtLeastLowerBound, channel.min('switchingLevelUpper'), {
          lowerBound: channel.min('switchingLevelUpper'),
          unit: switchingLevelUpperUnit,
        }),
      ])
    }

    // this.hasField() takes into account visibility - that is not good enough
    // we need to clear validation error if it was set by other options and is now invisible
    if (catalog.getField('filterFreq2')) {
      this.initValidationRule('parameters.filterFreq2', channel =>
        srcChannel.hasField('filterFreq2')
          ? [greaterThan(t.upperCuttoffFrequencyValidationMsg, channel.parameters.filterFreq1)]
          : [],
      )
    }

    if (srcChannel.subResourceHasField('highpass', 'frequency')) {
      this.initValidationRule('deviceSpecific.highpass.frequency', channel => {
        const freqRules = []
        const min = channel.getSubResourceMin('highpass', 'frequency')
        const max = channel.getSubResourceMax('highpass', 'frequency')
        if (!isNaN(min)) {
          freqRules.push(greaterEqualThan(t.minimalSupportedFrequencyIsFrequency, min, { frequency: min }))
        }
        if (!isNaN(max)) {
          freqRules.push(lessEqualThan(t.maximalSupportedFrequencyIsFrequency, max, { frequency: max }))
        }
        return freqRules
      })
    }

    if (srcChannel.subResourceHasField('notch', 'frequency')) {
      this.initValidationRule('deviceSpecific.notch.frequency', channel => {
        const freqRules = []
        const min = channel.getSubResourceMin('notch', 'frequency')
        const max = channel.getSubResourceMax('notch', 'frequency')
        if (!isNaN(min)) {
          freqRules.push(greaterEqualThan(t.minimalSupportedFrequencyIsFrequency, min, { frequency: min }))
        }
        if (!isNaN(max)) {
          freqRules.push(lessEqualThan(t.maximalSupportedFrequencyIsFrequency, max, { frequency: max }))
        }
        return freqRules
      })
    }
    if (srcChannel.subResourceHasField('notch', 'qFactor')) {
      this.initValidationRule('deviceSpecific.notch.qFactor', channel => {
        const freqRules = []
        const min = channel.getSubResourceMin('notch', 'qFactor')
        const max = channel.getSubResourceMax('notch', 'qFactor')
        if (!isNaN(min)) {
          freqRules.push(greaterEqualThan(t.minimalSupportedQFactorIsQFactor, min, { qFactor: min }))
        }
        if (!isNaN(max)) {
          freqRules.push(lessEqualThan(t.maximalSupportedQFactorIsQFactor, max, { qFactor: max }))
        }
        return freqRules
      })
    }

    if (srcChannel.subResourceHasField('lowpass', 'frequency')) {
      this.initValidationRule('deviceSpecific.lowpass.frequency', channel => {
        const freqRules = []
        const min = channel.getSubResourceMin('lowpass', 'frequency')
        const max = channel.getSubResourceMax('lowpass', 'frequency')
        if (!isNaN(min)) {
          freqRules.push(greaterEqualThan(t.minimalSupportedFrequencyIsFrequency, min, { frequency: min }))
        }
        if (!isNaN(max)) {
          freqRules.push(lessEqualThan(t.maximalSupportedFrequencyIsFrequency, max, { frequency: max }))
        }
        return freqRules
      })
    }
  }

  initValidationRule(field, validationCallback) {
    this.validationRules[field] = validationCallback
  }

  validate(channel) {
    const { deviceId, moduleIndex, channelIndex, validationRules } = this
    const getFieldPath = field => `${channel.path}${field.replace('parameters.', '')}`
    if (!channel.isActive()) {
      return reduce(
        validationRules,
        (acc, validationCallback, field) => {
          acc.push({
            path: getFieldPath(field),
            deviceId,
            moduleIndex,
            channelIndex,
            alerts: [],
          })
          return acc
        },
        [],
      )
    }

    return reduce(
      validationRules,
      (acc, validationCallback, field) => {
        const value = get(channel, field)
        const alerts = validationCallback(channel)
          .map(validator => validator(value))
          .filter(error => error)
        acc.push({
          path: getFieldPath(field),
          deviceId,
          moduleIndex,
          channelIndex,
          alerts,
        })
        return acc
      },
      [],
    )
  }
}
