import { messages as oa } from 'skybase-oauth/messages-i18n'

import { showErrorToast } from '@/common/services/show-toast'
import { capitalizeFirstLetter } from '@/utils/various-utils'

import { licensingErrorCodes, licenseErrorTypes } from '../../constants'

import { messages as t } from './licenses-i18n'

export const formatLicensingErrorMessage = message => {
  return message ? `${capitalizeFirstLetter(message)}.` : null
}

export const getLicenseErrorByType = type => {
  switch (type) {
    case licenseErrorTypes.ASSIGN:
      return t.licenseAssignError
    case licenseErrorTypes.REMOVE:
      return t.licenseRemoveError
    case licenseErrorTypes.UPDATE:
      return t.licenseUpdateError
    default:
      return t.licenseFetchError
  }
}

export const handleLicensesOfTicketApiErrors = ({ errorCode, errorText }, ticketId, type = licenseErrorTypes.FETCH) => {
  const { TICKET_NOT_FOUND, TICKET_CONTAINS_NO_LICENSES } = licensingErrorCodes
  let message
  switch (errorCode) {
    case TICKET_NOT_FOUND:
      message = {
        message: t.ticketNotFound,
        params: { ticketId: ticketId?.toUpperCase() || '' },
      }
      break
    case TICKET_CONTAINS_NO_LICENSES:
      message = t.ticketContainesNoLicenses
      break
    default:
      message = formatLicensingErrorMessage(errorText) || getLicenseErrorByType(type)
  }

  showErrorToast(message, oa.error)
}

export const getSelectedLicenseNamesAsString = (licenses, selectedIds) =>
  licenses
    .filter(license => selectedIds.includes(license.ticketLicenseId))
    .map(license => license.itemName)
    .join(', ')
