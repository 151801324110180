import axios from 'axios'
import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { getStudioAPIHost } from '@/utils/url'
import { batchActions } from 'redux-batched-actions'
import {
  getChainEntries,
  getChainsByModuleDetails,
  isChainLoaded,
} from '@/fleet-configuration/data-fleet/chain/chain-selectors'
import { getActiveProjectId } from '@/fleet-configuration/data-fleet/projects/projects-selectors'
import { loadProjectsIfEmpty } from '@/fleet-configuration/data-fleet/projects/projects-actions'

export const REMOVE_ALL_CHAIN_COMPONENTS = getActionName('REMOVE_ALL_CHAIN_COMPONENTS')
export const removeAllChainComponents = () => createAction(REMOVE_ALL_CHAIN_COMPONENTS)

export const REMOVE_CHAIN_ENTRY = getActionName('REMOVE_CHAIN_ENTRY')
export const removeChainEntry = id => createAction(REMOVE_CHAIN_ENTRY, { id })

export const SET_CHAIN_COMPONENT = getActionName('SET_CHAIN_COMPONENT')
export const setChainComponent = element => createAction(SET_CHAIN_COMPONENT, element)

export const CHAIN_LOADED = getActionName('CHAIN_LOADED')
export const chainLoaded = () => createAction(CHAIN_LOADED)

export const loadChain = () => async (dispatch, getState) => {
  let projectId = getActiveProjectId(getState())
  if (!projectId) {
    await dispatch(loadProjectsIfEmpty())
    projectId = getActiveProjectId(getState())
  }
  const { data } = await axios.get(`${getStudioAPIHost()}/api/chain?projectId=${projectId}`, { cache: true })
  const chain = new Array(data.length)
  dispatch(
    batchActions([
      removeAllChainComponents(),
      ...data.map((component, index) => {
        const newChainComponent = { id: component.chainId, ...component }
        chain[index] = newChainComponent
        return setChainComponent(newChainComponent)
      }),
      chainLoaded(),
    ]),
  )
  return chain
}

export const loadChainIfEmpty = () => (dispatch, getState) =>
  isChainLoaded(getState()) ? getChainEntries(getState()) : loadChain()(dispatch, getState)

export const loadChainById = chainId => async () => {
  const { data } = await axios.get(`${getStudioAPIHost()}/api/chain/${chainId}`)
  return data
}

export const storeNewChain = (typeNumber, serialNumber, channelIndex) => async (dispatch, getState) => {
  const chainData = {
    projectId: getActiveProjectId(getState()),
    channel: {
      typeNumber,
      serialNumber,
      slot: `channels/${parseInt(channelIndex, 10) + 1}`,
      userPreferences: {},
    },
  }
  const {
    headers: { location },
  } = await axios.post(`${getStudioAPIHost()}/api/chain`, chainData)
  const chainId = location.replace('/api/chain/', '')
  const chainComponent = { ...chainData, id: chainId, chainId }
  dispatch(setChainComponent(chainComponent))
  return chainComponent
}

export const deleteChainEntry = chainId => dispatch => {
  axios.delete(`${getStudioAPIHost()}/api/chain/${chainId}`)
  dispatch(removeChainEntry(chainId))
}
/*
 * use custom version of debounce
 *  - this way it will actually work (not creating new debounced function on every call rendering it useless)
 *  - and will merge requests that need to be merged together (meaning different requests will not be merged together)
 */
let chainUpdatedPromise
let chainUpdatedPromiseResolveFnc
let chainUpdatedPromiseRejectFnc
let updateChainTimeoutId
let lastChainValues
const executeUpdateChain = (dispatch, getState) => {
  const { id, ...updateChainData } = lastChainValues // remove "id" to make DTO - id used only by UI and is not known on BE
  const responsePromise = axios.put(`${getStudioAPIHost()}/api/chain/${id}`, updateChainData)
  // self-repair code - try to find duplicate entries for this exact same chain and remove them. Ideally this should never do anything
  const duplicates = getChainsByModuleDetails(
    getState(),
    updateChainData.channel.typeNumber,
    updateChainData.channel.serialNumber,
  ).filter(entry => entry.channel.slot === updateChainData.channel.slot && entry.id !== id)
  if (duplicates.length) {
    // remove from local state in a batch
    dispatch(batchActions(duplicates.map(duplicateEntry => removeChainEntry(duplicateEntry.id))))
    // remove from BE as well (API does not have batching)
    duplicates.forEach(duplicate => axios.delete(`${getStudioAPIHost()}/api/chain/${duplicate.id}`))
  }
  lastChainValues = null
  responsePromise.then(chainUpdatedPromiseResolveFnc, chainUpdatedPromiseRejectFnc)
  chainUpdatedPromise = null
}
export const updateChain = chain => (dispatch, getState) => {
  if (!chainUpdatedPromise) {
    chainUpdatedPromise = new Promise((resolve, reject) => {
      chainUpdatedPromiseResolveFnc = resolve
      chainUpdatedPromiseRejectFnc = reject
    })
  }
  clearTimeout(updateChainTimeoutId)
  // if this is merge of requests
  if (!lastChainValues || lastChainValues.id === chain.id) {
    lastChainValues = { ...lastChainValues, ...chain }
  } else if (lastChainValues) {
    // if this is new chain request - execute immediately old one and then define new one
    executeUpdateChain(dispatch, getState)
    lastChainValues = chain
  }

  dispatch(setChainComponent(lastChainValues))
  // postpone execution of new chain update request
  updateChainTimeoutId = setTimeout(executeUpdateChain, 300, dispatch, getState)
  return chainUpdatedPromise
}
