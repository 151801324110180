import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { SyntaxHighlighter } from '@/common/syntax-highlighter'

export class CodeBlock extends React.PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    language: PropTypes.string,
  }

  static defaultProps = {
    language: null,
  }

  render() {
    const { language, value } = this.props

    if (language === 'icon') {
      const [icon, color] = value.split(':')
      return <span className={classNames('md-sb-icon', icon)} style={{ color: color || '#0D7FC2' }} />
    }

    return <SyntaxHighlighter language={language}>{value}</SyntaxHighlighter>
  }
}
