import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { openModal } from 'skybase-ui/skybase-core/base/actions'
import { SbButton } from 'skybase-ui/skybase-components/sb-button'

import { ADD_DEVICE_BY_IP_MODAL_ID } from './constants'
import { messages as t } from './devices-i18n'

export const AddDeviceByIpButton = ({ className = null }) => {
  const { formatMessage: _ } = useIntl()
  const dispatch = useDispatch()

  const handleOnClick = () => {
    dispatch(openModal(ADD_DEVICE_BY_IP_MODAL_ID))
  }

  return (
    <SbButton
      id="add-device-by-ip-button"
      className={classNames('primary', className)}
      icon="sbi-plus"
      onClick={handleOnClick}
    >
      {_(t.addDeviceByIp)}
    </SbButton>
  )
}

AddDeviceByIpButton.propTypes = {
  className: PropTypes.string,
}
