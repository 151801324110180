import { reduce } from 'lodash'
import {
  getConfigurationOptions,
  getMirrorResourcesFields,
  getInitializingFields,
  getDirtyExcludableFields,
  getConfigurationParameters,
  getCatalogIdByCatalogTypes,
  getComponentFromCatalog,
} from '@/fleet-configuration/data-fleet/catalog/catalog-selectors'

// TODO: SDAQ-9929 catalog should be easy to cache as it does not modify any data.
//  Maybe we should start to do just that! (better perf & memory for large number of channels)
export const channelCatalogFactory = (moduleCatalog, channelTypes) => {
  const channelConfigurationOptions = getConfigurationOptions(moduleCatalog.data, channelTypes)
  const mirrorResourceFields = getMirrorResourcesFields(moduleCatalog.data, channelTypes)
  const initializingFields = getInitializingFields(moduleCatalog.data, channelTypes)
  const dirtyExcludableFields = getDirtyExcludableFields(moduleCatalog.data, channelTypes)
  const configurationParameters = getConfigurationParameters(moduleCatalog.data, channelTypes)

  const subResources = {}
  class ChannelCatalog {
    getSubResource(subResourceTypes) {
      const key = JSON.stringify(subResourceTypes)
      if (!subResources[key]) {
        subResources[key] = getConfigurationOptions(moduleCatalog.data, subResourceTypes)
      }
      return subResources[key]
    }

    hasField(field) {
      return !!channelConfigurationOptions[field]
    }

    isEnum(field) {
      const configuration = channelConfigurationOptions[field]
      // TODO: enum is reserved keyword, rename enum to options on backend
      return !!configuration && configuration.enum
    }

    getAllEnums() {
      return reduce(
        channelConfigurationOptions,
        (acc, item, key) => {
          if (item.enum) {
            acc[key] = item
          }
          return acc
        },
        {},
      )
    }

    getConfigurationParameters() {
      return configurationParameters
    }

    getField(field) {
      return channelConfigurationOptions[field]
    }

    getEnum(field) {
      const configuration = channelConfigurationOptions[field]
      return configuration.enum
    }

    getEnumOption = (field, value) => {
      const enumOptions = this.getEnum(field)
      return enumOptions.find(o => o.value === value)
    }

    getDependencies(field) {
      const configuration = channelConfigurationOptions[field]
      if (configuration && configuration.dependencies) {
        return configuration.dependencies
      }
      return []
    }

    getType(field) {
      const { type } = channelConfigurationOptions[field]
      if (type === 'array') {
        return [type, channelConfigurationOptions[field]?.items?.type]
      }
      return type
    }

    min(field) {
      const { min } = channelConfigurationOptions[field]
      return min
    }

    max(field) {
      const { max } = channelConfigurationOptions[field]
      return max
    }

    getMirrorResourcesFields() {
      return mirrorResourceFields
    }

    getInitializingFields() {
      return initializingFields
    }

    getInitializationFieldsOfField(fieldToCheck) {
      return (channelConfigurationOptions[fieldToCheck] || {}).initializes
    }

    getDirtyExcludableFields() {
      return dirtyExcludableFields
    }

    getExcludeFromDirtyCheckFieldsOfOption(fieldToCheckOption, value) {
      return (this.getEnumOption(fieldToCheckOption, value) || {}).excludeFromDirtyCheck || []
    }
  }
  return new ChannelCatalog()
}

const catalogComponentChannelMap = {}
export const getChannelCatalogByTypes = (getState, moduleTypes, channelTypes, moduleComponents = null) => {
  let channelCatalog
  const moduleTypesId = getCatalogIdByCatalogTypes(moduleTypes)
  const channelTypesId = getCatalogIdByCatalogTypes(channelTypes)
  if (!catalogComponentChannelMap[moduleTypesId]) {
    catalogComponentChannelMap[moduleTypesId] = {}
  }
  channelCatalog = catalogComponentChannelMap[moduleTypesId][channelTypesId]
  if (!channelCatalog) {
    channelCatalog = channelCatalogFactory(
      moduleComponents || getComponentFromCatalog(getState(), moduleTypesId),
      channelTypes,
    )
    catalogComponentChannelMap[moduleTypesId][channelTypesId] = channelCatalog
  }
  return channelCatalog
}
