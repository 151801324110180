/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  all: {
    id: 'devicesTableRenderer.all',
    defaultMessage: 'All',
  },
  online: {
    id: 'devicesTableRenderer.online',
    defaultMessage: 'Online',
  },
  offline: {
    id: 'devicesTableRenderer.offline',
    defaultMessage: 'Offline',
  },
  status: {
    id: 'devicesTableRenderer.status',
    defaultMessage: 'Status',
  },
  name: {
    id: 'devicesTableRenderer.name',
    defaultMessage: 'Name',
  },
  serialNumber: {
    id: 'devicesTableRenderer.serialNumber',
    defaultMessage: 'Serial Number',
  },
  deviceType: {
    id: 'devicesTableRenderer.deviceType',
    defaultMessage: 'Device Type',
  },
  typeNumber: {
    id: 'devicesTableRenderer.typeNumber',
    defaultMessage: 'Type Number',
  },
  daq: {
    id: 'devicesTableRenderer.daq',
    defaultMessage: 'DAQ',
  },
  kiDaq: {
    id: 'devicesTableRenderer.kiDaq',
    defaultMessage: 'KiDAQ',
  },
  labamp: {
    id: 'devicesTableRenderer.labamp',
    defaultMessage: 'Labamp',
  },
  forcePlate: {
    id: 'fleetOverview.forcePlate',
    defaultMessage: 'Force plate',
  },
  syncBox: {
    id: 'fleetOverview.syncBox',
    defaultMessage: 'Sync box',
  },
  startBlock: {
    id: 'fleetOverview.startBlock',
    defaultMessage: 'Start block',
  },
  daqBox: {
    id: 'fleetOverview.daqBox',
    defaultMessage: 'DAQ box',
  },
  unknown: {
    id: 'devicesTableRenderer.unknown',
    defaultMessage: 'Unknown',
  },
  yes: {
    id: 'devicesTableRenderer.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'devicesTableRenderer.no',
    defaultMessage: 'No',
  },
  configurationIsNotWrittenToDevice: {
    id: 'devicesTableRenderer.configurationIsNotWrittenToDevice',
    defaultMessage: 'Configuration is not written to Device',
  },
  copiedText: {
    id: 'devicesTableRenderer.copiedText',
    defaultMessage: 'Copied "{text}"',
  },
})
