import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import KiconnectIcon from './kiconnect.png'
import { messages as t } from './poweredBy-i18n'
import './poweredBy.scss'

export const PoweredBy = ({ isHomepage = false }) => {
  const { formatMessage: _ } = useIntl()
  if (!isHomepage) {
    return null
  }
  return (
    <div className="powered-by">
      <img src={KiconnectIcon} alt={_(t.kiConnectIcon)} className="powered-by-logo" />
      <div className="powered-by-text m-l-10">
        <p className="powered-by-description-part">{_(t.poweredBy)}</p>
        <p className="powered-by-subject-part">KiConnect</p>
      </div>
    </div>
  )
}

PoweredBy.propTypes = {
  isHomepage: PropTypes.bool.isRequired,
}
