import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { FormComponent } from 'skybase-oauth/common/components'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbLoaderButton } from 'skybase-ui/skybase-components/sb-button'
import { SbModal, SbModalHeader } from 'skybase-ui/skybase-components/sb-modal'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'

import { getAssignTicketValidationRules } from './validation-rules'
import { messages as t } from './licenses-i18n'

class _EnterLicenseTicketIdModal extends FormComponent {
  static propTypes = {
    ...this.propTypes,
    intl: intlShape.isRequired,
    handleOnCloseModal: PropTypes.func.isRequired,
    onProceed: PropTypes.func.isRequired,
    initialTicketId: PropTypes.string,
  }

  constructor(props) {
    super(props)

    const {
      intl: { formatMessage: _ },
      initialTicketId,
    } = props

    this.dataInState = true
    this.isModal = true
    this.state = {
      ...this.state,
      data: {
        ticketId: initialTicketId || '',
      },
    }
    this.validationRules = getAssignTicketValidationRules(_)
  }

  handleOnClose = () => {
    const { handleOnCloseModal } = this.props

    handleOnCloseModal()
  }

  handleOnConfirm = data => {
    const { onProceed } = this.props

    onProceed(data)
  }

  handleOnChangeParams = (attribute, value) => {
    this.setState(prevState => {
      return {
        data: {
          ...prevState.data,
          [attribute]: value,
        },
      }
    })
  }

  renderHeader = () => {
    const {
      intl: { formatMessage: _ },
    } = this.props

    return <SbModalHeader title={_(t.assignNewLicense)} onCloseBtnClick={this.handleOnClose} />
  }

  renderFooter = () => {
    const { loading } = this.props
    const {
      intl: { formatMessage: _ },
    } = this.props

    return (
      <div className="sb-modal-footer fl-container fl-justify-end">
        <div className="sb-modal-left" />
        <div className="sb-modal-center" />
        <div className="sb-modal-right">
          <SbLoaderButton
            loading={loading}
            disabled={loading}
            className="primary"
            onClick={this.handleOnConfirmClick}
            id="enter-license-ticket-modal-proceed-button"
          >
            {_(t.proceed)}
          </SbLoaderButton>
        </div>
      </div>
    )
  }

  renderInputs = () => {
    const {
      intl: { formatMessage: _ },
    } = this.props

    return (
      <>
        {this.renderInputWithLabel('ticketId', _(t.ticketId), {
          placeholder: _(t.ticketIdPlaceholder),
          style: { textTransform: 'uppercase' },
          labelClassName: 'm-b-0',
          id: 'enter-license-ticket-modal-ticketid-input',
        })}
      </>
    )
  }

  render() {
    return (
      <SbModal
        Header={this.renderHeader()}
        Footer={this.renderFooter()}
        width={310}
        height="auto"
        className="enter-license-ticket-id-modal"
      >
        <div className="sb-message-modal-content">
          <FormattedMessage
            id="manage-licenses.enterYourTicketId"
            defaultMessage="Please enter your <strong>Ticket ID</strong> and proceed to license assignment."
            values={{
              strong: text => <strong>{text}</strong>,
            }}
          />

          <div className="m-t-15">{this.renderInputs()}</div>
        </div>
      </SbModal>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleOnCloseModal: () => dispatch(closeModal()),
  }
}

export const EnterLicenseTicketIdModal = injectIntl(connect(null, mapDispatchToProps)(_EnterLicenseTicketIdModal))
