// TODO: somehow axios does not register with eslint - triggering error (even it it is node_modules hard dependency). Find out why
// eslint-disable-next-line import/no-unresolved
import axios from 'axios'
import { batchActions } from 'redux-batched-actions'
import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { getStudioAPIHost } from '@/utils/url'
import { getActiveProjectId } from '@/fleet-configuration/data-fleet/projects/projects-selectors'
import { removeDeviceEntry } from '@/fleet-configuration/data-fleet/devices/devices-actions'
import { handleHttpError } from '@/fleet-configuration/utils/handleHttpError'
import { componentFamily } from '@/fleet-configuration/data-fleet/components/components-constants'
import { messages as t } from './components-actions-i18n'
import { removeProjectDevice } from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import {
  areProjectComponentsLoaded,
  getProjectComponents,
} from '@/fleet-configuration/data-fleet/components/components-selectors'

export const REMOVE_ALL_COMPONENTS = getActionName('REMOVE_ALL_COMPONENTS')
export const removeAllComponents = () => createAction(REMOVE_ALL_COMPONENTS)

export const SET_COMPONENT = getActionName('SET_COMPONENT')
export const setComponent = element => createAction(SET_COMPONENT, element)

export const REMOVE_COMPONENT = getActionName('REMOVE_COMPONENT')
export const removeComponent = element => createAction(REMOVE_COMPONENT, element)

export const COMPONENTS_LOADED = getActionName('COMPONENTS_LOADED')
export const componentsLoaded = () => createAction(COMPONENTS_LOADED)

export const loadActiveProjectComponents = () => async (dispatch, getState) => {
  const projectId = getActiveProjectId(getState())
  const { data } = await axios.get(`${getStudioAPIHost()}/api/projects/${projectId}/components`)
  const actions = [
    removeAllComponents(),
    ...data.map(component => setComponent({ id: component.componentId, ...component })),
    componentsLoaded(),
  ]
  dispatch(batchActions(actions))
  return data
}

export const loadActiveProjectComponentsIfEmpty =
  () =>
  (dispatch, getState, ...rest) => {
    if (!areProjectComponentsLoaded(getState())) {
      return loadActiveProjectComponents()(dispatch, getState, ...rest)
    }
    return getProjectComponents(getState())
  }

export const addComponentToActiveProject = component => async (dispatch, getState) => {
  let deviceLocation
  const projectId = getActiveProjectId(getState())
  // if it's device and it first needs to be onboarded, do so
  /*
   This does not apply any more - in this project, onboarding is done outside this application
  if (component.family === 'device' && component.status === deviceEventStatusList.DISCOVERED) {
    await dispatch(onBoardDeviceWithLoader(component.protocolIndependentId, component.deviceId))
  }
   */
  const componentEndpoint = [componentFamily.DEVICE, componentFamily.CONTROLLER, componentFamily.MODULE].includes(
    component.family,
  )
    ? `/api/projects/${projectId}/components`
    : '/api/chain/components'
  try {
    const result = await axios.post(
      `${getStudioAPIHost()}${componentEndpoint}`,
      { projectId, ...component, deviceId: component.deviceId || component.protocolIndependentId },
      {
        customErrorHandler: error => {
          console.error(error)
          handleHttpError(
            t.couldNotAddDeviceIntoProject,
            t.addingDeviceFailedScanningForAvailableDevicesPleaseTryAgainLater,
          )
        },
      },
    )
    deviceLocation = result.headers.location
  } catch (error) {
    dispatch(removeDeviceEntry({ id: component.deviceId }))
    throw error
  }
  if (deviceLocation) {
    const { data: returnedComponent } = await axios.get(`${getStudioAPIHost()}${deviceLocation}`)
    const addedComponent = { id: returnedComponent.componentId, ...returnedComponent }
    dispatch(setComponent(addedComponent))
    return addedComponent
  }
  // fallback mechanism - reload everything
  await dispatch(loadActiveProjectComponents())
  return component
}

export const removeComponentActiveProject =
  (component, suppressErrors = false) =>
  async dispatch => {
    const { id, family, projectId } = component
    const componentEndpoint = ['device', 'controller', 'module'].includes(family)
      ? `/api/projects/${projectId}/components/${id}`
      : `/api/chain/components/${id}`
    await axios.delete(
      `${getStudioAPIHost()}${componentEndpoint}`,
      suppressErrors
        ? {
            customErrorHandler: error => {
              console.error(error)
            },
          }
        : {},
    )
    dispatch(batchActions([removeComponent(component), removeProjectDevice(component.deviceId)]))
  }

export const updateComponentInActiveProject = component => async dispatch => {
  const { id } = component
  await axios.put(`${getStudioAPIHost()}/api/chain/components/${id}`, component)
  dispatch(setComponent(component))
}
