import React, { PureComponent } from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'

import { notificationTypes } from './constants'
import { getBadgeTitle, getBadgeIconClassName } from './utils'

const { ERROR, WARNING, SUCCESS, INFO } = notificationTypes

class _NotificationBadge extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    className: PropTypes.string,
    type: PropTypes.oneOf([ERROR, WARNING, SUCCESS, INFO]),
  }

  static defaultProps = {
    type: INFO,
    className: null,
  }

  render() {
    const {
      className,
      type,
      intl,
      intl: { formatMessage },
      ...rest
    } = this.props

    return (
      <span {...rest} className={classNames('notification-badge', type, className)}>
        <i className={getBadgeIconClassName(type)} />
        {getBadgeTitle(type, formatMessage)}
      </span>
    )
  }
}

export const NotificationBadge = injectIntl(_NotificationBadge)
