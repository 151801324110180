export const componentSource = {
  INVENTORY: 'inventory',
  CATALOG: 'catalog',
}

export const componentFamily = {
  DEVICE: 'device',
  MODULE: 'module',
  CONTROLLER: 'controller',
  CABLE: 'cable',
  SENSOR: 'sensor',
}
