import { getProjectDevices } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import { deviceSyncOperationNames } from './device-sync-constants'
import { createSelector } from 'reselect'

export const getDeviceSyncStatuses = state => state.entries.deviceSyncStatus

export const selectDeviceSyncStatus = (state, deviceId) => getDeviceSyncStatuses(state)[deviceId]

export const getDeviceSyncStartedProcessName = deviceId => `SYNC_STARTED_${deviceId}`

export const hasDeviceSyncFailedBySyncStatuses = (syncStatuses, deviceId) => {
  const syncStatus = syncStatuses[deviceId]
  return !!(syncStatus && syncStatus.operationStatusCode && syncStatus.operationStatusCode !== '200')
}

export const hasAnyDeviceSyncFailed = createSelector(
  getProjectDevices,
  getDeviceSyncStatuses,
  (devices, syncStatuses) =>
    devices.some(({ id: deviceId }) => {
      return hasDeviceSyncFailedBySyncStatuses(syncStatuses, deviceId)
    }),
)

export const hasSyncSucceededBySyncStatuses = (syncStatuses, deviceId) => {
  return syncStatuses[deviceId]?.operationStatusCode === '200'
}

export const hasSyncSucceededOnAllDevices = createSelector(
  getProjectDevices,
  getDeviceSyncStatuses,
  (devices, syncStatuses) =>
    !!devices.length &&
    devices.every(({ id: deviceId }) => {
      return hasSyncSucceededBySyncStatuses(syncStatuses, deviceId)
    }),
)

export const isDeviceBeingSyncedBySyncStatuses = (syncStatuses, deviceId) => {
  return syncStatuses[deviceId]?.operationName === deviceSyncOperationNames.STARTED
}

export const isAnyDeviceBeingSynced = createSelector(
  getProjectDevices,
  getDeviceSyncStatuses,
  (projectDevices, syncStatuses) => {
    return projectDevices.some(device => isDeviceBeingSyncedBySyncStatuses(syncStatuses, device.id))
  },
)

export const isDeviceBeingSynced = (state, deviceId) =>
  (selectDeviceSyncStatus(state, deviceId) || {}).operationName === deviceSyncOperationNames.STARTED
