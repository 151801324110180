import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { buttonPositions, showSimpleModal } from '@/common/modals'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { loaderSize } from 'skybase-ui/skybase-components/sb-loader/constants'
import { SbDataTable } from 'skybase-ui/skybase-components/sb-data-table/sb-data-table'
import { SbBadge } from 'skybase-ui/skybase-components/sb-badge'
import { SbHint } from 'skybase-ui/skybase-components/sb-hint'
import { horizontalPosition, verticalPosition } from 'skybase-ui/skybase-components/sb-hint/constants'
import { getOAuthState } from 'skybase-oauth/utils'
import { AUTH_STATE_KEY_NAME } from 'skybase-oauth/oauth/constants'
import { withAclList } from 'skybase-oauth/auth/with-acl-list'
import {
  loadFirmware,
  setFirmwareData,
  updateFirmware,
} from '@/fleet-configuration/data-fleet/firmware/firmware-actions'
import { GREEN, RED, DARK, StatusBullet } from '@/common/status-bullet'
import { firmwareStates } from '@/fleet-configuration/data-fleet/firmware/firmware-constants'
import { showConfirmModal } from '@/fleet-configuration/components/confirm-modal/confirm-modal-actions'
import { loadProjectDeviceIfEmpty } from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { loadDeviceBackupsIfEmpty } from '@/fleet-configuration/data-fleet/backup/backup-actions'
import { loadDeviceCalibrationsIfEmpty } from '@/fleet-configuration/data-fleet/devices-calibrations/devices-calibrations-actions'
import { MAX_DEVICE_BACKUPS } from '@/fleet-configuration/components/backup-configuration/backup-configuration-constants'
import { showBackupConfigurationConfirm } from '@/fleet-configuration/components/backup-configuration/backup-configuration-confirm'
import { fleetOverviewExpandedRowSelector } from '@/fleet-configuration/pages/fleet-overview/fleet-overview-expanded-row/fleet-overview-expanded-row-selector'
import { getFirmwareUpdateReasonByCode } from '@/fleet-configuration/data-fleet/firmware/firmware-utils'
import { CalibrationBox } from '@/fleet-configuration/components/calibration/calibration-box/calibration-box'
import { CalibrationInformation } from '@/fleet-configuration/components/calibration/calibration-information/calibration-information'
import { getDeviceMeasurementRunning } from '@/fleet-configuration/data-fleet/devices-measurement-running/devices-measurement-running-actions'
import { messages as t } from './fleet-overview-expanded-row-i18n'
import './fleet-overview-expanded-row.scss'

const {
  UPDATE_FINISHED_UP_TO_DATE,
  UPDATE_FINISHED_UPDATE_AVAILABLE,
  UPDATE_AVAILABLE,
  UP_TO_DATE,
  UPDATE_IN_PROGRESS,
} = firmwareStates

class _FleetOverviewExpandedRow extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    dispatch: PropTypes.func.isRequired,
    deviceId: PropTypes.string.isRequired,
    piid: PropTypes.string.isRequired,
    isLoadingBackups: PropTypes.bool.isRequired,
    canBackup: PropTypes.bool.isRequired,
    deviceDataTableRows: PropTypes.array.isRequired,
    projectDevice: PropTypes.object,
    backups: PropTypes.array,
    onNoBackupClick: PropTypes.func,
    firmwareVersion: PropTypes.string,
    firmwareState: PropTypes.string,
    newFirmwareVersion: PropTypes.string,
    firmwareUpdateResultCode: PropTypes.number,
    lastFirmwareUpdateTime: PropTypes.string,
    deviceCalibrationData: PropTypes.object,
    typeNumber: PropTypes.string,
    serialNumber: PropTypes.string,
    userId: PropTypes.string,
    tenantId: PropTypes.string,
    aclList: PropTypes.shape({
      'kiconnect.devices.swupdate': PropTypes.bool,
    }).isRequired,
  }

  static defaultProps = {
    projectDevice: null,
    backups: null,
    onNoBackupClick: null,
    firmwareVersion: undefined,
    firmwareState: 'Unknown',
    newFirmwareVersion: undefined,
    firmwareUpdateResultCode: null,
    lastFirmwareUpdateTime: null,
    deviceCalibrationData: null,
    typeNumber: '',
    serialNumber: '',
    userId: '',
    tenantId: '',
  }

  async componentDidMount() {
    const { dispatch, deviceId, piid, firmwareVersion } = this.props
    if (deviceId && !firmwareVersion) {
      // if we failed to fetch firmware last time - mark it so that we are trying to re-fetch it again
      if (firmwareVersion === null) {
        dispatch(setFirmwareData(deviceId, undefined))
      }
      dispatch(loadFirmware(deviceId))
    }
    await dispatch(loadProjectDeviceIfEmpty(deviceId))
    dispatch(loadDeviceCalibrationsIfEmpty(deviceId))
    const { canBackup } = this.props
    if (canBackup) {
      dispatch(loadDeviceBackupsIfEmpty(piid))
    }
  }

  componentDidUpdate(prevProps) {
    const { deviceId, dispatch, firmwareVersion } = this.props
    if (prevProps.deviceId !== deviceId && !firmwareVersion) {
      dispatch(loadFirmware(deviceId))
    }
  }

  factoryHandleBackupUsage = backupRow => {
    const {
      dispatch,
      deviceId,
      intl: { formatMessage: _ },
    } = this.props

    return evt => {
      evt.preventDefault()
      evt.stopPropagation()
      showBackupConfigurationConfirm(_, dispatch, deviceId, backupRow)
    }
  }

  getDeviceDetailTableColumns() {
    const {
      intl: { formatMessage: _ },
    } = this.props

    return [
      { name: 'type', label: _(t.module), cellsClassName: 'sb-width-l' },
      { name: 'documentation', label: _(t.documentation) },
    ]
  }

  getDeviceDetailTableCellFormatter = (value, key, row) => {
    if (key === 'type') {
      return this.renderType(value, key, row)
    }
    if (key === 'documentation') {
      return this.renderDocumentation(value)
    }
    return value
  }

  renderType(value, key, row) {
    const {
      intl: { formatMessage: _ },
    } = this.props
    return `${_(row.isController ? t.controller : t.module)} ${value}`
  }

  renderDocumentation(value) {
    const {
      intl: { formatMessage: _ },
    } = this.props
    return value ? (
      <a href={value} target="_blank" rel="noopener noreferrer">
        {_(t.link)}
      </a>
    ) : (
      ''
    )
  }

  renderDeviceDetailTableContent() {
    const { deviceDataTableRows } = this.props

    return deviceDataTableRows.length ? (
      <div className="device-detail-table-wrapper">
        <SbDataTable
          className="device-detail-table"
          data={deviceDataTableRows}
          cellFormatter={this.getDeviceDetailTableCellFormatter}
          columns={this.getDeviceDetailTableColumns()}
        />
      </div>
    ) : null
  }

  handleFirmwareUpdateClicked = async () => {
    const {
      dispatch,
      projectDevice,
      intl: { formatMessage: _ },
      firmwareVersion,
      newFirmwareVersion,
      deviceId,
    } = this.props
    if (projectDevice.online) {
      let isCanceled = false
      dispatch(
        showSimpleModal({
          title: _(t.firmwareUpdate),
          buttons: [],
          message: <SbLoader size={loaderSize.L} show />,
          className: 'firmware-update-modal-loader',
          onClickOverlay: () => {
            dispatch(closeModal())
            isCanceled = true
          },
        }),
      )
      const isRunning = await dispatch(getDeviceMeasurementRunning(projectDevice))
      if (isCanceled) {
        return
      }
      dispatch(
        showConfirmModal(_, _(t.firmwareUpdate), updateFirmware(deviceId), {
          modalMessage: (
            <FormattedMessage
              id="fleetOverviewExpandedRow.firmwareOfTheDeviceDeviceNameWillBeUpdated"
              defaultMessage="The firmware of the device {deviceName} will be updated from version {currentVersion} to version {newVersion}.{newLine}It can take several minutes to install the firmware update on the device.{newLine}{newLine}Further, the device might automatically reboot to apply the firmware update, which can also take several minutes.{newLine}During this update process the device will not be available.{newLine}{newLine}{measurementRunningWarning}Continue?"
              values={{
                deviceName: <b>&quot;{projectDevice.name}&quot;</b>,
                currentVersion: <i>&quot;{firmwareVersion}&quot;</i>,
                newVersion: <i>&quot;{newFirmwareVersion}&quot;</i>,
                newLine: <br />,
                measurementRunningWarning: isRunning ? (
                  <>
                    <span className="error">{_(t.runningMeasurementWillBeStoppedDuringFirmwareUpdate)}</span>
                    <br />
                    <br />
                  </>
                ) : null,
              }}
            />
          ),
          className: 'firmware-update-confirm-modal',
        }),
      )
    } else {
      dispatch(
        showSimpleModal({
          title: _(t.firmwareUpdate),
          message: (
            <FormattedMessage
              id="fleetOverviewExpandedRow.deviceIsOfflineAndCannotBeUpdated"
              defaultMessage="The device {deviceName} is offline and it cannot be updated.{newLine}To be able to update device firmware, the device must be online.{newLine}Connect the device, turn it on and try again"
              values={{ deviceName: <b>&quot;{projectDevice.name}&quot;</b>, newLine: <br /> }}
            />
          ),
          buttons: [{ position: buttonPositions.RIGHT, title: _(t.close), action: closeModal }],
          onClickOverlay: () => dispatch(closeModal()),
          className: 'firmware-update-offline-modal',
        }),
      )
    }
  }

  renderFirmwareVersionContent() {
    const {
      projectDevice,
      firmwareVersion,
      firmwareState,
      newFirmwareVersion,
      firmwareUpdateResultCode,
      lastFirmwareUpdateTime,
      intl: { formatMessage: _, formatDate },
      aclList,
    } = this.props
    let content
    const canUserUpdateDevices = aclList['kiconnect.devices.swupdate']
    if (firmwareVersion === undefined) {
      content = <SbLoader show />
    } else if (!firmwareVersion) {
      content = null
    } else {
      if (firmwareState === UPDATE_IN_PROGRESS) {
        content = (
          <>
            <div className="firmware-update-loader-wrapper">
              <SbLoader show />
              <span className="test-firmware-version">{firmwareVersion}</span>
            </div>
            <span className="test-firmware-status">{_(t.updating)}</span>
          </>
        )
      } else {
        let statusColor
        let statusText
        if (projectDevice?.isLabAmp()) {
          statusColor = DARK
          statusText = null
        } else {
          if ([UP_TO_DATE, UPDATE_FINISHED_UP_TO_DATE].includes(firmwareState)) {
            statusColor = GREEN
            statusText = `(${_(t.upToDate)})`
          } else if ([UPDATE_FINISHED_UPDATE_AVAILABLE, UPDATE_AVAILABLE].includes(firmwareState)) {
            statusColor = RED
            statusText = canUserUpdateDevices ? (
              <SbHint hintData={_(t.newVersionFwVersion, { fwVersion: newFirmwareVersion })}>
                <span className="mark-has-hint">({_(t.updateAvailable)})</span>
              </SbHint>
            ) : (
              <SbHint hintData={_(t.missingPermissionsForSWUpdate, { newLine: <br /> })}>
                <span className="no-update-permission">({_(t.updateAvailable)})</span>
              </SbHint>
            )
          } else {
            statusColor = DARK
            statusText = `(${_(t.noInformationForUpdate)})`
          }
          if ([UPDATE_FINISHED_UP_TO_DATE, UPDATE_FINISHED_UPDATE_AVAILABLE].includes(firmwareState)) {
            statusText = (
              <>
                {statusText}
                <div className="last-update-result">
                  {firmwareUpdateResultCode === 1 ? (
                    <SbHint
                      hintData={_(t.lastFirmwareUpdateOnDateTimeFinishedSuccessfully, {
                        dateTime: formatDate(lastFirmwareUpdateTime, { format: 'date-minutes-12hour-numeric' }),
                      })}
                    >
                      <span>{_(t.lastUpdateSucceed)}</span>
                    </SbHint>
                  ) : (
                    <SbHint
                      hintClassName="align-center"
                      position={{ horizontal: horizontalPosition.CENTER, vertical: verticalPosition.TOP }}
                      hintData={_(t.lastFirmwareUpdateOnDateTimeFailedTheReasonReason, {
                        newLine: <br />,
                        dateTime: formatDate(lastFirmwareUpdateTime, { format: 'date-minutes-12hour-numeric' }),
                        reason: getFirmwareUpdateReasonByCode(firmwareUpdateResultCode, _),
                      })}
                    >
                      <span>{_(t.lastUpdateFailed)}</span>
                    </SbHint>
                  )}
                </div>
              </>
            )
          }
        }
        content = (
          <>
            <StatusBullet status={statusColor}>
              <span className="test-firmware-version">{firmwareVersion}</span>
            </StatusBullet>
            <span className="test-firmware-status">{statusText}</span>
          </>
        )
      }
      if (
        canUserUpdateDevices &&
        [UPDATE_AVAILABLE, UPDATE_FINISHED_UPDATE_AVAILABLE].includes(firmwareState) &&
        !projectDevice?.isLabAmp()
      ) {
        content = (
          <div className="firmware-update-available" onClick={this.handleFirmwareUpdateClicked}>
            {content}
          </div>
        )
      }
    }
    return (
      <div className="firmware-wrapper">
        <h2 className="firmware-header">{_(t.firmware)}</h2>
        {content}
      </div>
    )
  }

  renderBackupContent() {
    const {
      intl: { formatMessage: _ },
      backups,
      isLoadingBackups,
      canBackup,
      onNoBackupClick,
      deviceId,
      piid,
    } = this.props

    let content
    if (!canBackup) {
      content = _(t.theConfigurationNotSupported)
    } else if (isLoadingBackups) {
      content = <SbLoader show />
    } else {
      content = (
        <>
          {backups.length === 0 ? (
            <p>
              {_(t.thereAreNoBackupsInSystemYet)}
              <br />
              {_(t.clickBackupConfigurationQuickActionAtTheTopToCreateYourFirst, {
                BACKUP_CONFIGURATION: (
                  <span
                    onClick={onNoBackupClick ? evt => onNoBackupClick(evt, deviceId, piid) : null}
                    style={{
                      textDecoration: onNoBackupClick ? 'underline' : 'none',
                      cursor: onNoBackupClick ? 'pointer' : 'initial',
                    }}
                  >
                    {_(t.backupConfiguration)}
                  </span>
                ),
              })}
            </p>
          ) : (
            backups.map(backup => {
              return (
                <p
                  className="expanded-row-backup"
                  role="presentation"
                  tabIndex={-1}
                  key={backup.id}
                  onClick={this.factoryHandleBackupUsage(backup)}
                >
                  {backup.name}
                </p>
              )
            })
          )}
          {backups.length === MAX_DEVICE_BACKUPS && (
            <SbBadge className="required-license-badge">{_(t.upgradeYourLicenseToGetMore)}</SbBadge>
          )}
        </>
      )
    }
    return (
      <div className="expanded-row-backup-wrapper">
        <h2>{_(t.recentConfigurations)}</h2>
        {content}
      </div>
    )
  }

  renderDeviceCalibrationContent() {
    const {
      deviceCalibrationData,
      intl: { locale },
      serialNumber,
      typeNumber,
      userId,
      tenantId,
      deviceId,
      projectDevice,
    } = this.props
    const languageOfLocale = locale.match(/^[a-z]+/i)?.[0].toLowerCase()
    // kgate is always calibrable, but has special logic to it
    if (projectDevice?.isKGate()) {
      return (
        <CalibrationBox
          languageOfLocale={languageOfLocale}
          calibrationContent={null}
          typeNumber={typeNumber}
          serialNumber={serialNumber}
          deviceId={deviceId}
          userId={userId}
          tenantId={tenantId}
        />
      )
    }

    // if it's not calibrable
    if (!deviceCalibrationData.isCalibrable) {
      return null
    }

    return (
      <CalibrationBox
        languageOfLocale={languageOfLocale}
        calibrationContent={<CalibrationInformation deviceId={deviceId} />}
        typeNumber={typeNumber}
        serialNumber={serialNumber}
        userId={userId}
        tenantId={tenantId}
      />
    )
  }

  render() {
    return (
      <div className="fleet-overview-expanded-row">
        {this.renderDeviceDetailTableContent()}
        {this.renderDeviceCalibrationContent()}
        {this.renderFirmwareVersionContent()}
        {this.renderBackupContent()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { id: userId, tenant: tenantId } = getOAuthState(state)[AUTH_STATE_KEY_NAME]

  return {
    userId,
    tenantId,
  }
}

const selectorConnector = (state, props) => ({
  ...mapStateToProps(state),
  ...fleetOverviewExpandedRowSelector(state, props),
})

export const FleetOverviewExpandedRow = withAclList(injectIntl(connect(selectorConnector)(_FleetOverviewExpandedRow)), [
  'kiconnect.devices.swupdate',
])
