import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { loaderSize } from 'skybase-ui/skybase-components/sb-loader/constants'
import { SbInlineMessage } from 'skybase-ui/skybase-components/sb-inline-message'
import {
  INLINE_MESSAGE_TYPE_ERROR,
  INLINE_MESSAGE_TYPE_WARNING,
} from 'skybase-ui/skybase-components/sb-inline-message/constants'
import { fleetInit } from '@/fleet-configuration/fleet-init'
import { areDevicesLoaded } from '@/fleet-configuration/data-fleet/devices/devices-selectors'
import { useEquipment } from '@/fleet-configuration/equipment/hooks'
import { families } from '@/fleet-configuration/equipment/constants'
import { loadActiveProjectComponentsIfEmpty } from '@/fleet-configuration/data-fleet/components/components-actions'
import { loadProjectDeviceIfEmpty } from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { loadProjectsIfEmpty } from '@/fleet-configuration/data-fleet/projects/projects-actions'
import { getProjectDevices } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import { loadDevicesIfEmpty } from '@/fleet-configuration/data-fleet/devices/devices-actions'
import { IssuesToPayAttentionTable } from './issues-to-pay-attention-table'
import { messages as t } from './issues-to-pay-attention-i18n'
import { createSelector } from 'reselect'

const MAX_NUMBER_OF_ITEMS = 1000

const issuesToPayAttentionSelector = createSelector(
  [getProjectDevices, areDevicesLoaded],
  (devices, areDevicesAlreadyLoaded) => {
    return { devices, areDevicesLoading: !areDevicesAlreadyLoaded }
  },
)

export const IssuesToPayAttention = ({ testsRequestId = null }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingProjectDevices, setIsLoadingProjectDevices] = useState(true)
  const [hasCrashed, setHasCrashed] = useState(false)
  const [hasTooManyItems, setHasTooManyItems] = useState(false)

  const { data: allEquipments, loading: areEquipmentsLoading, error: hasEquipmentError } = useEquipment(testsRequestId)

  if (hasEquipmentError && !hasCrashed) {
    setHasCrashed(true)
    setIsLoadingProjectDevices(false)
  }

  // use only those equipments, that can have issue (which are sensors and devices that are labamp)
  const equipments = useMemo(
    () =>
      allEquipments.filter(equipment => equipment.family === families.SENSOR || equipment.typeNumber?.startsWith('55')),
    [allEquipments],
  )

  const { formatMessage: _ } = useIntl()
  const dispatch = useDispatch()

  const { devices } = useSelector(issuesToPayAttentionSelector)

  // fetch all project devices
  useEffect(() => {
    dispatch(loadDevicesIfEmpty()) // this is a dependency that would be loaded anyway - so start to load it ASAP to reduce wait times
    Promise.resolve(dispatch(fleetInit(_)))
      .then(async () => {
        await dispatch(loadProjectsIfEmpty(testsRequestId))
        const components = await dispatch(loadActiveProjectComponentsIfEmpty(testsRequestId))
        if (components.length > MAX_NUMBER_OF_ITEMS) {
          setHasTooManyItems(true)
          return
        }
        await Promise.allSettled(
          components.map(component => {
            if (component.family !== families.DEVICE) {
              return null
            }
            return dispatch(loadProjectDeviceIfEmpty(component.deviceId))
          }),
        )
        setIsLoadingProjectDevices(false)
      })
      .catch(() => {
        // there's a need to wait for another tick - without this, rerender sometimes does not happen
        //  it was an issue in unit test "Should display inline error message when loading dependencies fail"
        setTimeout(() => {
          setHasCrashed(true)
          setIsLoadingProjectDevices(false)
        })
      })
  }, [_, dispatch, testsRequestId])

  // Check if we have too many items to continue AND mark if we are still loading some dependencies
  useEffect(() => {
    if (!areEquipmentsLoading && !isLoadingProjectDevices) {
      setIsLoading(false)
    } else {
      return
    }

    if (equipments.length + devices.length > MAX_NUMBER_OF_ITEMS) {
      setHasTooManyItems(true)
    }
  }, [equipments, areEquipmentsLoading, devices, isLoadingProjectDevices])

  // RENDER PART
  if (hasCrashed) {
    return (
      <div data-testid="hasCrashed">
        <SbInlineMessage
          className="error-container"
          type={INLINE_MESSAGE_TYPE_ERROR}
          title={_(t.failedToRetrieveAnyIssuesToPayAttentionTo)}
          message={_(t.pleaseTryRefreshingThePageOrTryAccessingTheServiceLater)}
        />
      </div>
    )
  }
  if (hasTooManyItems) {
    return (
      <div data-testid="hasTooManyItems">
        <SbInlineMessage
          className="error-container"
          type={INLINE_MESSAGE_TYPE_WARNING}
          message={_(t.thereAreTooManyHardwareItemsForCheckingTheirStatus)}
        />
      </div>
    )
  }
  if (isLoading) {
    return (
      <div className="fl-container fl-center-center">
        <SbLoader size={loaderSize.L} show />
      </div>
    )
  }
  return (
    <div data-testid="issues-table">
      <IssuesToPayAttentionTable equipments={equipments} devices={devices} testsRequestId={testsRequestId} />
    </div>
  )
}

IssuesToPayAttention.propTypes = {
  testsRequestId: PropTypes.string,
}
