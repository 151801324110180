/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  identifyDevice: {
    id: 'identifyButton.identifyDevice',
    defaultMessage: 'Identify device',
  },
  identifyCantBeCalledOnOfflineDevice: {
    id: 'identifyButton.identifyCantBeCalledOnOfflineDevice',
    defaultMessage: "Identify can't be called on offline device",
  },
})
