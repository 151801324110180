const trySetDefault = fnc => {
  try {
    fnc()
    return []
  } catch (e) {
    return [e.message]
  }
}

export class DefaultValueSetter {
  constructor(channel, channelUpdater, options, catalog) {
    this.channel = channel
    this.channelUpdater = channelUpdater
    this.options = options
    this.catalog = catalog
  }

  setDefaultsIfNotSet() {
    let updatedChannel = this.channel
    const errors = Array.prototype.concat(
      trySetDefault(() => {
        if (updatedChannel.isUndefined('physicalQuantity')) {
          updatedChannel = this.setDefaultPhysicalQuantity()
        }
      }),
      trySetDefault(() => {
        if (updatedChannel.isUndefined('filterType')) {
          updatedChannel = this.setDefaultFilterType()
        }
      }),
    )
    if (errors.length) {
      throw new Error(errors.join(',\n\n '))
    }
    return updatedChannel
  }

  setDefaultPhysicalQuantity() {
    const physicalQuantityOption =
      this.options.getPhysicalQuantityOption() || this.options.getOptions('physicalQuantity')[0]
    return this.channelUpdater.updateParameterValue(this.channel, 'physicalQuantity', physicalQuantityOption.value)
  }

  // TODO: remove this after filters are properly working on backend
  setDefaultFilterType() {
    const firstOption = this.options.getOptions('filterType')[0]
    return this.channelUpdater.updateParameterValue(this.channel, 'filterType', firstOption.value)
  }
}
