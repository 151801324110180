import React, { useState, useEffect, useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { escapeRegExp } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { SbModal } from 'skybase-ui/skybase-components/sb-modal'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { loaderSize } from 'skybase-ui/skybase-components/sb-loader/constants'
import { selectCurrentLanguage } from 'skybase-ui/skybase-core/locales'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'
import { getHelpFileContents } from '@/common/help-component/help-component-actions'
import { messages as t } from './help-component-modal-i18n'
import { useIntl } from 'react-intl'

let effectTicket = 0
export const HelpComponentModal = ({ pathname, helpFilesMap = null }) => {
  const language = useSelector(selectCurrentLanguage)
  const dispatch = useDispatch()
  const { formatMessage: _ } = useIntl()

  const [currentPath, setCurrentPath] = useState(pathname)
  const [markdownContent, setMarkdownContent] = useState(null)

  const { menuSortedFilesMap, matchesToRegexpFileMap } = useMemo(() => {
    const currentLanguageFilesMap = helpFilesMap.filter(entry => entry.language === language)
    const menuSortedFilesMapCalculated = [...currentLanguageFilesMap].sort((a, b) => a.menuOrder - b.menuOrder)
    const matchesToRegexpFileMapCalculated = currentLanguageFilesMap.map(entry => ({
      ...entry,
      match: new RegExp(
        escapeRegExp(entry.match)
          .replace(/\\\*\\\*/g, '.*')
          .replace(/\\\*/g, '[^/]*'),
      ),
    }))
    return {
      menuSortedFilesMap: menuSortedFilesMapCalculated,
      matchesToRegexpFileMap: matchesToRegexpFileMapCalculated,
    }
  }, [helpFilesMap, language])

  useEffect(() => {
    const currentMarkdownEntry = matchesToRegexpFileMap.find(entry => {
      return entry.match.test(currentPath)
    })
    effectTicket += 1
    const ticket = effectTicket

    ;(async () => {
      const content = await getHelpFileContents(currentMarkdownEntry.filePath)
      if (ticket === effectTicket) {
        setMarkdownContent(content)
      }
    })()
    // do not consider matchesToRegexpFileMap - this file does not change, but object reference might
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath])

  const menuContent = useMemo(() => {
    const currentMarkdownEntry = matchesToRegexpFileMap.find(entry => {
      return entry.match.test(currentPath)
    })
    return menuSortedFilesMap.map(helpEntry => (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        key={helpEntry.filePath}
        className={classnames('menu-item btn-link', {
          'sbi-chevron-right': helpEntry.filePath === currentMarkdownEntry?.filePath,
        })}
        onClick={() => {
          setCurrentPath(helpEntry.match)
        }}
      >
        {helpEntry.title}
      </a>
    ))
    // helpFilesMap+language is source for both matchesToRegexpFileMap and menuSortedFilesMapso no need to have those in dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [helpFilesMap, language, currentPath])

  return (
    <SbModal
      width="90%"
      height="90%"
      className="help-component-modal"
      title={_(t.helpKiCloudTest)}
      Footer={<></> /* eslint-disable-line react/jsx-no-useless-fragment */}
      onClickOverlay={() => dispatch(closeModal())}
    >
      {helpFilesMap ? (
        <>
          <div className="help-menu">{menuContent}</div>
          <div className="help-content">
            {markdownContent ? (
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdownContent}</ReactMarkdown>
            ) : (
              <SbLoader show />
            )}
          </div>
        </>
      ) : (
        <SbLoader size={loaderSize.L} show />
      )}
    </SbModal>
  )
}

HelpComponentModal.propTypes = {
  pathname: PropTypes.string.isRequired,
  helpFilesMap: PropTypes.array,
}
