import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { loaderSize, loaderOrientation, loaderType } from 'skybase-ui/skybase-components/sb-loader/constants'
import { SbModal } from 'skybase-ui/skybase-components/sb-modal'
import { logOut } from 'skybase-oauth/oauth/actions'
import { confirmEula, loadEulaState, setEulaReviewMode, setEulaStatus } from './eula-actions'
import { eulaSelector } from './eula-selector'
import { messages as t } from './eula-i18n'
import './eula.scss'
import classnames from 'classnames'

class _Eula extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    confirmed: PropTypes.bool,
    isInReview: PropTypes.bool,
    content: PropTypes.string,
  }

  static defaultProps = {
    content: '',
    confirmed: false,
    isInReview: false,
  }

  constructor(props) {
    super(props)
    this.state = { rejected: false, loading: true }
  }

  async componentDidMount() {
    const { dispatch } = this.props
    await dispatch(loadEulaState())
    this.setState({ loading: false })
  }

  handleOnConfirm = async () => {
    const { dispatch } = this.props
    this.setState({ loading: true })
    await dispatch(confirmEula())
    dispatch(setEulaStatus({ confirmed: true }))
    this.setState({ loading: false })
  }

  handleOnClose = () => {
    const { dispatch } = this.props
    dispatch(setEulaReviewMode(false))
  }

  render() {
    const {
      intl: { formatMessage: _ },
      children,
      confirmed,
      content,
      isInReview,
      dispatch,
    } = this.props
    const { loading, rejected } = this.state
    if (loading) {
      return (
        <SbLoader
          orientation={loaderOrientation.VERTICAL}
          containerClassName="loader-in-screen-center"
          loadingText={_(t.loading)}
          type={loaderType.OVERLAY}
          size={loaderSize.L}
          show
        />
      )
    }
    if (confirmed && !isInReview) {
      return children
    }
    let modalContent
    let footer
    if (rejected) {
      modalContent = (
        <>
          <h1 className="title-message align-center">{_(t.cannotWithoutConsentToSLA)}</h1>
          <p>
            {_(t.toGoBackToLogInScreenClickHere, {
              here: (
                <button
                  type="button"
                  className="btn btn-link inline"
                  onClick={() => {
                    dispatch(logOut())
                  }}
                >
                  {_(t.here)}
                </button>
              ),
            })}
          </p>
        </>
      )
      footer = <></> /* eslint-disable-line react/jsx-no-useless-fragment */
    } else {
      modalContent = <ReactMarkdown>{content}</ReactMarkdown>
      footer = (
        <div className="sb-modal-footer fl-container fl-justify-end">
          {isInReview ? (
            <button
              className="sb-btn primary sb-margin-left-30px test-eula-close"
              onClick={this.handleOnClose}
              type="button"
            >
              {_(t.close)}
            </button>
          ) : (
            <>
              <button
                className="sb-btn danger test-eula-reject"
                onClick={() => {
                  this.setState({ rejected: true })
                }}
                type="button"
              >
                {_(t.disagree)}
              </button>
              <button
                className="sb-btn primary sb-margin-left-30px test-eula-confirm"
                onClick={this.handleOnConfirm}
                type="button"
              >
                {_(t.agree)}
              </button>
            </>
          )}
        </div>
      )
    }

    return (
      <>
        {isInReview ? children : null}
        <SbModal
          className={classnames('eula-modal', { 'is-rejection-eula': rejected })}
          width={rejected ? 'initial' : '95%'}
          height={rejected ? 'initial' : '95%'}
          Header={rejected ? <></> : null /* eslint-disable-line react/jsx-no-useless-fragment */}
          title={_(t.softwareLicenseAgreement)}
          Footer={footer}
          onClickOverlay={isInReview ? this.handleOnClose : undefined}
        >
          {modalContent}
        </SbModal>
      </>
    )
  }
}

export const Eula = injectIntl(connect(eulaSelector)(_Eula))
