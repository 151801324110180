import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  writeToDeviceDeviceWasUnsuccessful: {
    id: 'deviceSyncListener.writeToDeviceDeviceWasUnsuccessful',
    defaultMessage: 'Write to device "{device}" was unsuccessful',
  },
  failedToFetchCatalog: {
    id: 'equipment.failedToLoadCatalog',
    defaultMessage: 'Failed to get catalog elements, please try again later',
  },
  componentNotFound: {
    id: 'equipment.componentNotFound',
    defaultMessage: 'Component not found',
  },
  measureModeWriteConfigurationToTheDevice: {
    id: 'projectDevicesActions.theDeviceIsInMeasureModeToWriteTheConfigurationToTheDeviceTheRunningMeasurementWillBeStoppedAndRestartedSubsequentlyToTheWritingProcedureAreYouSureYouWantToProceed',
    defaultMessage:
      'The device is in measure mode. To write the configuration to the device, the running measurement will be stopped and restarted subsequently to the writing procedure.{newLine}Are you sure you want to proceed?',
  },
  interruptMeasurement: {
    id: 'projectDevicesActions.interruptMeasurement',
    defaultMessage: 'Interrupt measurement',
  },
  interruptRunningMeasurement: {
    id: 'projectDevicesActions.interruptRunningMeasurement',
    defaultMessage: 'Interrupt running measurement',
  },
})
