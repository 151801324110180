/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  identifyCalled: {
    id: 'wizardMenu.identifyCalled',
    defaultMessage: 'Identify called',
  },
  identifyDevice: {
    id: 'wizardMenu.identifyDevice',
    defaultMessage: 'Identify device',
  },
  identifyCantBeCalledOnOfflineDevice: {
    id: 'wizardMenu.identifyCantBeCalledOnOfflineDevice',
    defaultMessage: "Identify can't be called on offline device",
  },
  online: {
    id: 'wizardMenu.online',
    defaultMessage: 'Online',
  },
  offline: {
    id: 'wizardMenu.offline',
    defaultMessage: 'Offline',
  },
  channelIndex: {
    id: 'wizardMenu.channelIndex',
    defaultMessage: 'Channel {index}',
  },
  noSensor: {
    id: 'wizardMenu.noSensor',
    defaultMessage: 'No sensor',
  },
  noCable: {
    id: 'wizardMenu.noCable',
    defaultMessage: 'No cable',
  },
  components: {
    id: 'wizardMenu.components',
    defaultMessage: 'Components',
  },
  showOnlyActiveChannels: {
    id: 'wizardMenu.showOnlyActiveChannels',
    defaultMessage: 'Show only active channels',
  },
  moduleType: {
    id: 'wizardMenu.moduleType',
    defaultMessage: 'Module | {type}',
  },
  controllerType: {
    id: 'wizardMenu.controllerType',
    defaultMessage: 'Controller | {type}',
  },
  typeNumberCable: {
    id: 'wizardMenu.typeNumberCable',
    defaultMessage: '{typeNumber} cable',
  },
  settings: {
    id: 'wizardMenu.settings',
    defaultMessage: 'Settings',
  },
  calibrationExpired: {
    id: 'wizardMenu.calibrationExpired',
    defaultMessage: 'Calibration expired',
  },
  calibrationStatusUnknown: {
    id: 'wizardMenu.calibrationStatusUnknown',
    defaultMessage: 'Calibration status unknown',
  },
  compatibilityWithSensorGuaranteed: {
    id: 'wizardMenu.compatibilityWithSensorGuaranteed',
    defaultMessage: 'Compatibility with sensor guaranteed',
  },
})
