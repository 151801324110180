import { filter } from 'lodash'
// TODO: break this cycle (even if it does not create bugs right now)
// eslint-disable-next-line import/no-cycle
import { alertsRemove } from './actions'
import { filterAlerts } from './filter-alerts'

export const clearAlerts = (dispatch, validation, id, filterPredicate, directDispatch = true) => {
  let previousAlerts = filterAlerts(validation, id)
  if (filterPredicate) {
    previousAlerts = filter(previousAlerts, filterPredicate)
  }
  if (previousAlerts && previousAlerts.length) {
    const removeAlerts = alertsRemove(
      previousAlerts.map(alert => alert.id),
      id,
    )
    if (!directDispatch) {
      return removeAlerts
    }
    dispatch(removeAlerts)
  }
  return undefined
}

export const clearAlertsByControlPrefix = (dispatch, validation, controlPrefix) => {
  if (validation && validation.controls) {
    Object.entries(validation.controls).forEach(([key]) => {
      if (key.startsWith(controlPrefix)) {
        clearAlerts(dispatch, validation, key)
      }
    })
  }
}
