import React from 'react'
import { showConfirmModal } from '@/fleet-configuration/components/confirm-modal/confirm-modal-actions'
import { restoreBackup } from '@/fleet-configuration/data-fleet/backup/backup-actions'
import { messages as t } from './backup-configuration-confirm-i18n'
import { showSuccessToast } from '@/common/services/show-toast'

export const showBackupConfigurationConfirm = (_, dispatch, deviceId, deviceBackupRow, onDismiss = null) => {
  return dispatch(
    showConfirmModal(_, _(t.restoreConfiguration), restoreBackup(deviceId, deviceBackupRow.id), {
      modalMessage: (
        <>
          {_(t.areYouSureYouWantToRestoreConfiguration)}
          <br />
          {_(t.thisActionCannotBeUndone)}
        </>
      ),
      confirmText: _(t.restore),
      afterConfirmAction: () => {
        if (onDismiss) {
          onDismiss()
        }
        showSuccessToast(_(t.dontForgetToUseWriteSettingsToDevice), _(t.configurationWasSuccessfullyLoaded))
      },
      className: 'backup-confirm-modal',
    }),
  )
}
