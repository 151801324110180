import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { createAction } from 'skybase-ui/skybase-core/base/create-action'

export const CLEAR_DEVICE_SYNC_STATUSES = getActionName('CLEAR_DEVICE_SYNC_STATUSES')

export const clearDeviceSyncStatuses = () => createAction(CLEAR_DEVICE_SYNC_STATUSES, {})

export const DEVICE_SYNC_STATUS_UPDATE = getActionName('DEVICE_SYNC_STATUS_UPDATE')

export const deviceSyncStatusUpdate = syncMessage => createAction(DEVICE_SYNC_STATUS_UPDATE, syncMessage)
