/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  anErrorOccurredWhileFetchingCompanyInformation: {
    id: 'equipment.anErrorOccurredWhileFetchingCompanyInformation',
    defaultMessage: 'An error occurred while fetching company information.',
  },
  anErrorOccurredWhileFetchingTheUsers: {
    id: 'equipment.anErrorOccurredWhileFetchingTheUsers',
    defaultMessage: 'An error occurred while fetching the users.',
  },
  yourOrderCalibrationWasSuccessfullySent: {
    id: 'equipment.yourOrderCalibrationWasSuccessfullySent',
    defaultMessage: 'Your order calibration was successfully sent',
  },
  pleaseTryAgainLater: {
    id: 'equipment.pleaseTryAgainLater',
    defaultMessage: 'Please try again later',
  },
  pleaseContactAdmin: {
    id: 'equipment.pleaseContactAdmin',
    defaultMessage: 'Please contact your administrator',
  },
  yourCalibrationOrderCouldNotBeSent: {
    id: 'equipment.yourCalibrationOrderCouldNotBeSent',
    defaultMessage: 'Your calibration order could not be sent',
  },
  yourCalibrationOrderIsForbidden: {
    id: 'equipment.yourCalibrationOrderIsForbidden',
    defaultMessage: 'You do not have a permission to send an Email.',
  },
})
