import { wizardNavigationSelection } from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation-constants'

const { SELECTED, INDETERMINATE, UNSELECTED } = wizardNavigationSelection

export const resolveStateFromConfigSnippet = (checkAbleItems, checkedItems) => {
  if (!checkAbleItems.length) {
    return SELECTED
  }
  let hasTrue = false
  let hasFalse = false
  checkAbleItems.some(item => {
    if (checkedItems.includes(item)) {
      hasTrue = true
    } else {
      hasFalse = true
    }
    return hasTrue && hasFalse
  })
  if (hasTrue) {
    if (hasFalse) {
      return INDETERMINATE
    }
    return SELECTED
  }
  return UNSELECTED
}
