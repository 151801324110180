import { createSelector } from 'reselect'
import { IOT_HUB_STATE_KEY_NAME } from './constants'
import { DEVICES_STATE_KEY_NAME, DEVICES_STATE_KEYS, deviceStatuses } from './components/devices/constants'
import {
  DEVICE_DATA_SOURCES_KEY_NAME,
  DEVICES_DATA_SOURCE_STATE_KEYS,
} from './components/devices/datasources/constants'
import { NOTIFICATIONS_STATE_KEY_NAME } from './components/notifications/constants'

const { UPDATE_DEVICE, LIST, RESOURCE_DETAILS, DEVICE_ONLINE_STATUS, DEVICE_REGISTER_STATUS } = DEVICES_STATE_KEYS
const { DATA_SOURCES_LIST, DATA_SOURCE_DETAILS, DATA_SOURCE_CONFIGURATION, DATA_TRANSFER, RUNNING_MEASUREMENTS } =
  DEVICES_DATA_SOURCE_STATE_KEYS

// Devices

export const getIotHubState = state => state[IOT_HUB_STATE_KEY_NAME]

export const getIotHubDevicesState = state => getIotHubState(state)[DEVICES_STATE_KEY_NAME]

export const getIotHubDeviceDetailsState = state => getIotHubDevicesState(state)[UPDATE_DEVICE]

export const getIotHubDeviceOnlineState = state => getIotHubDevicesState(state)[DEVICE_ONLINE_STATUS]

export const getIotHubDeviceRegisterState = state => getIotHubDevicesState(state)[DEVICE_REGISTER_STATUS]

export const getIotHubDevicesListState = state => getIotHubDevicesState(state)[LIST]

export const getIotHubDevicesDeviceIpByValue = state => getIotHubDevicesState(state).addDeviceByIpValue

export const getOwningDeviceIds = state => getIotHubDevicesState(state).owningDeviceIds

export const getDiscoveringDevicesState = state => getIotHubDevicesState(state).discoveringDevices

const defaultOnlineOfflineState = { online: 0, offline: 0 }
export const getIotHubDeviceOnlineOfflineDevicesCount = createSelector(
  [getIotHubDevicesListState, getIotHubDeviceOnlineState],
  (iotHubDevicesListState, liveDeviceStatuses) => {
    const { data: devices } = iotHubDevicesListState

    return (
      devices?.reduce((counts, d) => {
        const liveStatus = liveDeviceStatuses?.[d.id] || d.status
        const isOnline = liveStatus === deviceStatuses.ONLINE

        if (isOnline) {
          return { online: counts.online + 1, offline: counts.offline }
        }

        return { online: counts.online, offline: counts.offline + 1 }
      }, defaultOnlineOfflineState) || defaultOnlineOfflineState
    )
  },
)

// Device Resources

export const getIotHubDeviceResourceDetailsState = state => getIotHubDevicesState(state)[RESOURCE_DETAILS]

// Device Data Sources

export const getIotHubDeviceDataSourceState = state => getIotHubState(state)[DEVICE_DATA_SOURCES_KEY_NAME]

export const getIotHubDeviceDataSourcesListState = state => getIotHubDeviceDataSourceState(state)[DATA_SOURCES_LIST]

export const getIotHubDeviceDataSourceDetailsState = state => getIotHubDeviceDataSourceState(state)[DATA_SOURCE_DETAILS]

export const getIotHubDeviceDataSourceConfigurationState = state =>
  getIotHubDeviceDataSourceState(state)[DATA_SOURCE_CONFIGURATION]

export const getIotHubDeviceDataSourceTransferState = state => getIotHubDeviceDataSourceState(state)[DATA_TRANSFER]

export const getIotHubDeviceDataSourceRunningMeasurementsState = state =>
  getIotHubDeviceDataSourceState(state)[RUNNING_MEASUREMENTS]

// Notifications

export const getIotHubNotifications = state => getIotHubState(state)[NOTIFICATIONS_STATE_KEY_NAME]
