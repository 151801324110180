import React from 'react'
import PropTypes from 'prop-types'
import './config-screen-layout.scss'

export const ConfigScreenLayout = ({
  illustration = null,
  parameters = null,
  title = null,
  titleClassName = 'fl-container config-screen-layout-title',
  chainPanel = null,
}) => (
  <div className="fl-container-column fl-grow-1">
    {title && <h2 className={titleClassName}>{title}</h2>}
    <div className="config-screen-layout">
      <div className="config-screen-left-side">
        <div className="config-screen-layout-illustration">
          <div className="fl-container-column fl-grow-1">{illustration}</div>
        </div>
        <div className="config-screen-layout-chain-panel">
          <div className="fl-container-column fl-grow-1">{chainPanel}</div>
        </div>
      </div>
      <div className="config-screen-layout-parameters">{parameters}</div>
    </div>
  </div>
)

ConfigScreenLayout.propTypes = {
  illustration: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  parameters: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleClassName: PropTypes.string,
  chainPanel: PropTypes.node,
}
