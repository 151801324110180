import { OAuth } from 'skybase-oauth'
import { trialServiceApiEndpoints, trialServiceStates } from './constants'

const { NONE } = trialServiceStates

export const getTrialSimulatorApi = () =>
  OAuth.fetchOAuth(`${OAuth.config.API_ENDPOINT_URL}${trialServiceApiEndpoints.GET}`)

export const deleteTrialSimulatorApi = () =>
  OAuth.fetchOAuth(`${OAuth.config.API_ENDPOINT_URL}${trialServiceApiEndpoints.GET}`, {
    method: 'DELETE',
  })

export const pullTrialSimulator = (expectedStatus, delayBetweenCalls = 1000, numberOfCalls = 15) =>
  new Promise((resolve, reject) => {
    let timeout = null
    let counter = 0

    const retry = call => {
      counter += 1
      if (counter <= numberOfCalls) {
        timeout = setTimeout(call, delayBetweenCalls)
      } else {
        return reject(new Error('Timeout'))
      }

      return null
    }

    const pull = async () => {
      try {
        const simulator = await getTrialSimulatorApi()

        const { id, status } = simulator

        if (id && status === expectedStatus) {
          clearTimeout(timeout)
          return resolve(simulator)
        }

        if (expectedStatus === NONE && status === NONE) {
          clearTimeout(timeout)
          return reject(new Error(NONE))
        }

        return retry(pull)
      } catch (e) {
        return retry(pull)
      }
    }

    timeout = setTimeout(pull, delayBetweenCalls)
  })
