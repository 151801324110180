import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export const Card = ({ title, children = null, className = null }) => {
  return (
    <div className={classNames('card', className)}>
      <h2>{title}</h2>

      <div className="content">{children}</div>
      <div />
    </div>
  )
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
}
