import { batchActions } from 'redux-batched-actions'

import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { openModal } from 'skybase-ui/skybase-core/base/actions'

import { MODULE_NAME } from '@/constants'
import { DEVICES_STATE_KEYS, RESOURCE_MODAL_ID } from './constants'

const { UPDATE_DEVICE, LIST, RESOURCE_DETAILS } = DEVICES_STATE_KEYS

const getActionNameFactory = constant => getActionName(constant, MODULE_NAME)

// Devices

export const SET_DEVICES = getActionNameFactory('SET_DEVICES')
export const setDevices = devices => createAction(SET_DEVICES, { devices })

export const CHANGE_DEVICE_PARAMS = getActionNameFactory('CHANGE_DEVICE_PARAMS')
export const changeDeviceParams = (key, attribute, value) =>
  createAction(CHANGE_DEVICE_PARAMS, { key, attribute, value })

export const CLEAR_DEVICE_PARAMS = getActionNameFactory('CLEAR_DEVICE_PARAMS')
export const clearDeviceParams = key => createAction(CLEAR_DEVICE_PARAMS, { key })

export const CHANGE_DEVICE_STATE = getActionNameFactory('CHANGE_DEVICE_STATE')
export const changeDeviceState = (key, state) => createAction(CHANGE_DEVICE_STATE, { key, state })

export const LOAD_DEVICE_TO_UPDATE = getActionNameFactory('LOAD_DEVICE_TO_UPDATE')
export const loadDeviceToUpdate = device => createAction(LOAD_DEVICE_TO_UPDATE, { device })

export const ENABLE_DEVICE_STATUS_NOTIFICATIONS = getActionNameFactory('ENABLE_DEVICE_STATUS_NOTIFICATIONS')
export const enableDeviceStatusNotifications = enable => createAction(ENABLE_DEVICE_STATUS_NOTIFICATIONS, { enable })

export const UPDATE_DEVICE_PARAM = getActionNameFactory('UPDATE_DEVICE_PARAM')
// param: { key, value }
export const updateDeviceParam = (deviceId, param) => createAction(UPDATE_DEVICE_PARAM, { deviceId, param })

export const UPDATE_DEVICE_ONLINE_STATUS = getActionNameFactory('UPDATE_DEVICE_ONLINE_STATUS')
export const updateDeviceOnlineStatus = (deviceId, status) =>
  createAction(UPDATE_DEVICE_ONLINE_STATUS, { deviceId, status })

export const SET_INITIAL_DEVICES_ONLINE_STATUS = getActionNameFactory('SET_INITIAL_DEVICES_ONLINE_STATUS')
export const setInitialDevicesOnlineStatus = devicesWithOnlineStatus =>
  createAction(SET_INITIAL_DEVICES_ONLINE_STATUS, { devices: devicesWithOnlineStatus })

export const UPDATE_DEVICE_REGISTER_STATUS = getActionNameFactory('UPDATE_DEVICE_REGISTER_STATUS')
export const updateDeviceRegisterStatus = (deviceId, status) =>
  createAction(UPDATE_DEVICE_REGISTER_STATUS, { deviceId, status })

export const SET_DISCOVERING_DEVICES = getActionNameFactory('SET_DISCOVERING_DEVICES')
export const setDiscoveringDevices = discovering => createAction(SET_DISCOVERING_DEVICES, { discovering })

// Update device in both the table and details view (if active)
export const UPDATE_DEVICE_DATA = getActionNameFactory('UPDATE_DEVICE_DATA')
export const updateDeviceData = device => createAction(UPDATE_DEVICE_DATA, { device })

export const CHANGE_SEARCH_DEVICE_VALUE = getActionNameFactory('CHANGE_SEARCH_DEVICE_VALUE')
export const changeSearchDeviceValue = value => createAction(CHANGE_SEARCH_DEVICE_VALUE, { value })

export const REMOVE_DEVICE = getActionNameFactory('REMOVE_DEVICE')
export const removeDevice = deviceId => createAction(REMOVE_DEVICE, { deviceId })

export const ADD_DEVICE = getActionNameFactory('ADD_DEVICE')
export const addDevice = device => createAction(ADD_DEVICE, { device })

export const SAVE_DEVICE_BY_IP_VALUE = getActionNameFactory('SAVE_DEVICE_BY_IP_VALUE')
export const saveDeviceByIPValue = ipAddress => createAction(SAVE_DEVICE_BY_IP_VALUE, { ipAddress })

export const SET_OWNING_DEVICE_ID = getActionNameFactory('SET_OWNING_DEVICE_ID')
export const setOwningDeviceId = deviceId => createAction(SET_OWNING_DEVICE_ID, { deviceId })

export const UNSET_OWNING_DEVICE_ID = getActionNameFactory('UNSET_OWNING_DEVICE_ID')
export const unsetOwningDeviceId = deviceId => createAction(UNSET_OWNING_DEVICE_ID, { deviceId })

// Device Resources

export const LOAD_DEVICE_RESOURCE_TO_UPDATE = getActionNameFactory('LOAD_DEVICE_RESOURCE_TO_UPDATE')
export const loadDeviceResourceToUpdate = resource => createAction(LOAD_DEVICE_RESOURCE_TO_UPDATE, { resource })

export const LOAD_DEVICE_RESOURCE_INTERFACE_TO_UPDATE = getActionNameFactory('LOAD_DEVICE_RESOURCE_INTERFACE_TO_UPDATE')
export const loadDeviceResourceInterfaceToUpdate = resourceInterface =>
  createAction(LOAD_DEVICE_RESOURCE_INTERFACE_TO_UPDATE, { resourceInterface })

export const CHANGE_DEVICE_RESOURCE_INTERFACE_RETRIEVE_STATE = getActionNameFactory(
  'CHANGE_DEVICE_RESOURCE_INTERFACE_RETRIEVE_STATE',
)
export const changeDeviceResourceInterfaceRetrieveState = state =>
  createAction(CHANGE_DEVICE_RESOURCE_INTERFACE_RETRIEVE_STATE, { state })

export const CHANGE_DEVICE_RESOURCE_INTERFACE_UPDATE_STATE = getActionNameFactory(
  'CHANGE_DEVICE_RESOURCE_INTERFACE_UPDATE_STATE',
)
export const changeDeviceResourceInterfaceUpdateState = state =>
  createAction(CHANGE_DEVICE_RESOURCE_INTERFACE_UPDATE_STATE, { state })

export const SET_DEVICE_RESOURCE_INTERFACE_ERRORS = getActionNameFactory('SET_DEVICE_RESOURCE_INTERFACE_ERRORS')
export const setDeviceResourceInterfaceErrors = errors => createAction(SET_DEVICE_RESOURCE_INTERFACE_ERRORS, { errors })

// Factory actions

export const changeUpdateDeviceParams = (attribute, value) => changeDeviceParams(UPDATE_DEVICE, attribute, value)

export const clearUpdateDeviceParams = () => clearDeviceParams(UPDATE_DEVICE)

export const clearDeviceResourceParams = () => clearDeviceParams(RESOURCE_DETAILS)

export const clearAllDeviceForms = () => dispatch => {
  dispatch(batchActions([clearUpdateDeviceParams()]))
}

export const changeUpdateDeviceState = state => changeDeviceState(UPDATE_DEVICE, state)

export const changeListDevicesState = state => changeDeviceState(LIST, state)

export const changeDeviceResourceState = state => changeDeviceState(RESOURCE_DETAILS, state)

export const showResourceModal = ({ json, ...rest } = {}) =>
  openModal(RESOURCE_MODAL_ID, {
    json,
    ...rest,
  })
