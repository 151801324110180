import { OAuth } from 'skybase-oauth'

import { replaceDuplicateSlashes, objectToQuery } from '@/utils'
import { resourceStructures } from './components/devices/constants'
import { iotHubApi, unauthorizedDeviceErrorNamespaces } from './constants'

const { fetchOAuth } = OAuth
const { DEVICES } = iotHubApi
const { FLAT } = resourceStructures

// Return an async fetch request with a middleware for handling the unathorized device state in the Backend Mode of the Client App.
const fetchOAuthWith401Middleware = (url, options = {}) =>
  fetchOAuth(url, { ...options, handle401: OAuth.isInNormalMode }).catch(e => {
    if (OAuth.isInLocalBackendMode) {
      const error = e?.errors?.[0]

      // If the namespace is one of [sdk, sdk.grpc, sdk.plgd], it means that the error is an invalid access token.
      // In this case, handle the 401 as usual with redirection to the login page.
      if (error && e.status === 401 && unauthorizedDeviceErrorNamespaces.includes(error.namespace)) {
        return OAuth._handle401Error(e.status)
      }

      throw e
    }

    // Do not handle anything here, unless we are in the Backend Mode of the Client App
    throw e
  })

// Devices

export const getDevicesApi = () => fetchOAuth(`${OAuth.config.API_ENDPOINT_URL}${DEVICES}?resourceStructure=array`)

export const getDeviceApi = (deviceId, resourceStructure = FLAT) =>
  fetchOAuthWith401Middleware(
    `${OAuth.config.API_ENDPOINT_URL}${DEVICES}/${deviceId}?resourceStructure=${resourceStructure}`,
  )

// Call the API which deletes the device shadow (zombie device)
export const deleteDeviceApi = deviceId =>
  fetchOAuthWith401Middleware(`${OAuth.config.API_ENDPOINT_URL}/api/v1/devices/${deviceId}`, {
    method: 'DELETE',
  })

export const getDeviceAuthCodeApi = deviceId =>
  fetchOAuth(`${OAuth.config.IDENTITY_SERVER_URL}/connect/token/plgdhub?deviceId=${deviceId}&usePlgdHub=true`, {
    credentials: 'include',
  })

export const requestStreamingAuthInfoApi = shareWithTenant =>
  fetchOAuth(`${OAuth.config.IDENTITY_SERVER_URL}/connect/token/streaming?shareWithTenant=${shareWithTenant}`, {
    credentials: 'include',
  })

export const getDeviceResourceApi = ({ resourcePath, resourceInterface = null }) => {
  const endpointUrl = replaceDuplicateSlashes(`/${resourcePath}`)
  const resourceInterfaceQuery = resourceInterface ? `?interface=${resourceInterface}` : ''
  return fetchOAuthWith401Middleware(
    `${OAuth.config.API_ENDPOINT_URL}${DEVICES}${endpointUrl}${resourceInterfaceQuery}`,
  )
}

export const setDeviceResourceApi = ({ resourcePath, resourceInterface = null, data }) => {
  const endpointUrl = replaceDuplicateSlashes(`/${resourcePath}`)
  const resourceInterfaceQuery = resourceInterface ? `?interface=${resourceInterface}` : ''
  return fetchOAuthWith401Middleware(
    `${OAuth.config.API_ENDPOINT_URL}${DEVICES}${endpointUrl}${resourceInterfaceQuery}`,
    {
      method: 'PUT',
      body: JSON.stringify(data),
    },
  )
}

export const getDeviceDataSourcesApi = deviceId =>
  fetchOAuth(`${OAuth.config.API_ENDPOINT_URL}${DEVICES}/${deviceId}/datasources`)

export const getDeviceDataSourceApi = (deviceId, dataSourceId) =>
  fetchOAuthWith401Middleware(`${OAuth.config.API_ENDPOINT_URL}${DEVICES}/${deviceId}/datasources/${dataSourceId}`)

export const setDeviceDataSourceApi = (deviceId, dataSourceId, data) =>
  fetchOAuthWith401Middleware(`${OAuth.config.API_ENDPOINT_URL}${DEVICES}/${deviceId}/datasources/${dataSourceId}`, {
    method: 'PUT',
    body: JSON.stringify(data),
  })

export const getDeviceDataSourceConfigurationApi = (deviceId, dataSourceId) =>
  fetchOAuthWith401Middleware(
    `${OAuth.config.API_ENDPOINT_URL}${DEVICES}/${deviceId}/datasources/${dataSourceId}/configuration`,
  )

export const setDeviceDataSourceConfigurationApi = (deviceId, dataSourceId, data) =>
  fetchOAuthWith401Middleware(
    `${OAuth.config.API_ENDPOINT_URL}${DEVICES}/${deviceId}/datasources/${dataSourceId}/configuration`,
    {
      method: 'PUT',
      body: JSON.stringify(data),
    },
  )

export const getDeviceDataSourceTransferConfigurationApi = (deviceId, dataSourceId, transferMethod) =>
  fetchOAuthWith401Middleware(
    `${OAuth.config.API_ENDPOINT_URL}${DEVICES}/${deviceId}/datasources/${dataSourceId}/transfer-configuration?transferMethod=${transferMethod}`,
  )

export const startDeviceDataSourceApi = (deviceId, dataSourceId, transferMethod, data) =>
  fetchOAuthWith401Middleware(
    `${OAuth.config.API_ENDPOINT_URL}${DEVICES}/${deviceId}/datasources/${dataSourceId}/start?transferMethod=${transferMethod}`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
  )

export const stopDeviceDataSourceApi = (deviceId, dataSourceId) =>
  fetchOAuthWith401Middleware(
    `${OAuth.config.API_ENDPOINT_URL}${DEVICES}/${deviceId}/datasources/${dataSourceId}/stop`,
    {
      method: 'POST',
    },
  )

export const ownDeviceApi = deviceId =>
  fetchOAuthWith401Middleware(`${OAuth.config.API_ENDPOINT_URL}${DEVICES}/${deviceId}/own`, {
    method: 'POST',
  })

export const disownDeviceApi = deviceId =>
  fetchOAuthWith401Middleware(`${OAuth.config.API_ENDPOINT_URL}${DEVICES}/${deviceId}/disown`, {
    method: 'POST',
  })

/**
 * @param {String} deviceId
 * @param {Object} params - { cloudUrl, cloudId, authCode }
 */
export const onboardDeviceApi = (deviceId, params) =>
  fetchOAuthWith401Middleware(
    `${OAuth.config.API_ENDPOINT_URL}${DEVICES}/${deviceId}/onboard?${objectToQuery(params)}`,
    {
      method: 'POST',
    },
  )

export const offboardDeviceApi = deviceId =>
  fetchOAuthWith401Middleware(`${OAuth.config.API_ENDPOINT_URL}${DEVICES}/${deviceId}/offboard`, {
    method: 'POST',
  })

export const getDeviceByIpApi = ipAddress =>
  fetchOAuthWith401Middleware(`${OAuth.config.API_ENDPOINT_URL}${DEVICES}?ip=${ipAddress}`)

// Returns configuration used for onboarding a device { id: cloudId, coapGateway: cloudUrl }
export const getOcfCloudConfiguration = () => fetchOAuth(`${OAuth.config.OCFCLOUD_CONFIGURATION_URL}`)

// Set the fetchOAuth to the window object so it is accessible from the browser console only when in developement
if (process?.env?.NODE_ENV === 'development') {
  window.fetchOAuth = fetchOAuth
}
