import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  type: {
    id: 'notifications.type',
    defaultMessage: 'Type',
  },
  time: {
    id: 'notifications.time',
    defaultMessage: 'Time',
  },
  title: {
    id: 'notifications.title',
    defaultMessage: 'Title',
  },
  message: {
    id: 'notifications.message',
    defaultMessage: 'Message',
  },
  payload: {
    id: 'notifications.payload',
    defaultMessage: 'Payload',
  },
  location: {
    id: 'notifications.location',
    defaultMessage: 'Location',
  },
  error: {
    id: 'notifications.error',
    defaultMessage: 'Error',
  },
  success: {
    id: 'notifications.success',
    defaultMessage: 'Success',
  },
  warning: {
    id: 'notifications.warning',
    defaultMessage: 'Warning',
  },
  info: {
    id: 'notifications.info',
    defaultMessage: 'Info',
  },
  selectANotification: {
    id: 'notifications.selectANotification',
    defaultMessage: 'First select a notification to see its details.',
  },
})
