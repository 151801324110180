import { camelCase } from 'lodash'

export const camelCaseToDashSeparator = input =>
  input.replace(/([a-z])([A-Z])/g, (match, lower, upper) => `${lower}-${upper.toLowerCase()}`)

export const toCssString = input =>
  typeof input === 'string' ? input.replace(/^(-?[^_a-zA-Z])/, '_$1').replace(/[^_a-zA-Z0-9-]/g, '-') : input

export const toHTMLSafeString = input =>
  input
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')

export const toObjectKey = input =>
  camelCase(input)
    .replace(/[^a-z0-9]/gi, '')
    .replace(/(^\d+)(.*)/g, '$2')

export const toCatalogObjectKey = input => `catalog ${input}`

export const interpolateNumericPlaceholders = (template, argumentsArray) =>
  template.replace(/\$\{([0-9]+)\}/g, (match, index) => (argumentsArray || [])[index])
