import PropTypes from 'prop-types'
import { families } from './constants'

export const equipmentShape = PropTypes.shape({
  family: PropTypes.oneOf([...Object.values(families), '']),
  id: PropTypes.string,
  serialNumber: PropTypes.string,
  typeName: PropTypes.string,
  typeNumber: PropTypes.string,
  userNotes: PropTypes.string,
  icon: PropTypes.string,
})

export const calibrationShape = PropTypes.shape({
  calibrationDate: PropTypes.string,
  calibrationIntervalDays: PropTypes.number,
  serialNumber: PropTypes.string,
  typeNumber: PropTypes.string,
})
