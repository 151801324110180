/*
  General app's configuration.

  Important note:
  This file must be readable even without the need for Babel transpilation.
*/

/**
 * @description
 * Contains all the important settings that should be configurable.
 *
 * @public
 */
const config = {
  // Application name that will be displayed.
  appName: 'KiCloud',
  // name to be used in actions, etc..
  actionNamespace: 'digital-hub-ui',
  // Relative path to the UI app. It can't contain a slash at the end, e.g. `/my-relative-path/blah`.
  publicUrl: '/',
  // This is the name of the application that will show in the logger
  moduleName: 'digital-hub-ui',
  // All supported cultures ordered by priority. The most important is considered default.
  cultures: ['en', 'de', 'es', 'ko', 'ru', 'vi', 'zh'],
  // All supported languages ordered by priority. The most important is considered default.
  // languages: ['en', 'de', 'es', 'ko', 'ru', 'vi', 'zh_CN'],
  languages: ['en'],
  // Maps the language selection to the culture.
  languageCultureMap: { en: 'en-150', de: 'de', es: 'es', ko: 'ko', ru: 'ru', zh_CN: 'zh' },
  // Default-Culture
  defaultCulture: 'en-150',
}

module.exports = config
exports.default = config
