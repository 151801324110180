import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  active: {
    id: 'channelParametersForm.Active',
    defaultMessage: 'Active',
  },
  on: {
    id: 'channelParametersForm.On',
    defaultMessage: 'On',
  },
  off: {
    id: 'channelParametersForm.Off',
    defaultMessage: 'Off',
  },
  name: {
    id: 'channelParametersForm.Name',
    defaultMessage: 'Name',
  },
  sensorDetailType: {
    id: 'channelParametersForm.Sensor Detail Type',
    defaultMessage: 'Sensor Detail Type',
  },
  type: {
    id: 'channelParametersForm.Type',
    defaultMessage: 'Type',
  },
  connectionScheme: {
    id: 'channelParametersForm.Connection Scheme',
    defaultMessage: 'Connection Scheme',
  },
  physicalQuantity: {
    id: 'channelParametersForm.Physical Quantity',
    defaultMessage: 'Physical Quantity',
  },
  customUnit: {
    id: 'channelParametersForm.Custom Unit',
    defaultMessage: 'Custom Unit',
  },
  zeroDeviation: {
    id: 'channelParametersForm.Zero Deviation',
    defaultMessage: 'Zero Deviation',
  },
  physicalRange: {
    id: 'channelParametersForm.Physical Range',
    defaultMessage: 'Physical Range',
  },
  filterSettings: {
    id: 'channelParametersForm.Filter Settings',
    defaultMessage: 'Filter Settings',
  },
  timeConstant: {
    id: 'channelParametersForm.Time Constant',
    defaultMessage: 'Time Constant',
  },
  filterType: {
    id: 'channelParametersForm.Filter Type',
    defaultMessage: 'Filter Type',
  },
  filterCharacteristics: {
    id: 'channelParametersForm.Filter Characteristics',
    defaultMessage: 'Filter Characteristics',
  },
  filterOrder: {
    id: 'channelParametersForm.Filter Order',
    defaultMessage: 'Filter Order',
  },
  lowerCutoffFrequency: {
    id: 'channelParametersForm.Lower Cutoff Frequency',
    defaultMessage: 'Lower Cutoff Frequency',
  },
  cutoffFrequency: {
    id: 'channelParametersForm.Cutoff Frequency',
    defaultMessage: 'Cutoff Frequency',
  },
  upperCutoffFrequency: {
    id: 'channelParametersForm.Upper Cutoff Frequency',
    defaultMessage: 'Upper Cutoff Frequency',
  },
  signalNameIsRequired: {
    id: 'channelParametersForm.Signal Name is required',
    defaultMessage: 'Signal Name is required',
  },
  sensitivityScaling: {
    id: 'channelParametersForm.Sensitivity / Scaling',
    defaultMessage: 'Sensitivity / Scaling',
  },
  butterworth: {
    id: 'channelParametersForm.Butterworth',
    defaultMessage: 'Butterworth',
  },
  firstOrder: {
    id: 'channelParametersForm.1st Order',
    defaultMessage: '1st Order',
  },
  fourthOrder: {
    id: 'channelParametersForm.4th Order',
    defaultMessage: '4th Order',
  },
  kiXactRanges: {
    id: 'channelParametersForm.KiXact ranges:',
    defaultMessage: 'KiXact ranges:',
  },
  frequency: {
    id: 'channelParametersForm.Frequency',
    defaultMessage: 'Frequency',
  },
  temperature: {
    id: 'channelParametersForm.Temperature',
    defaultMessage: 'Temperature',
  },
  frequencyShouldBeGreaterThan0: {
    id: 'channelParametersForm.Frequency should be greater than 0',
    defaultMessage: 'Frequency should be greater than 0',
  },
  ambientTemperature: {
    id: 'channelParametersForm.Ambient Temperature',
    defaultMessage: 'Ambient Temperature',
  },
  signalRange: {
    id: 'channelParametersForm.Signal Range',
    defaultMessage: 'Signal Range',
  },
  expectedValue: {
    id: 'channelParametersForm.(expected value)',
    defaultMessage: '(expected value)',
  },
  offset: {
    id: 'channelParametersForm.Offset',
    defaultMessage: 'Offset',
  },
  channelType: {
    id: 'channelParametersForm.Channel type',
    defaultMessage: 'Channel type',
  },
  analogInput: {
    id: 'channelParametersForm.Analog Input',
    defaultMessage: 'Analog Input',
  },
  digitalInput: {
    id: 'channelParametersForm.Digital Input',
    defaultMessage: 'Digital Input',
  },
  unknownInput: {
    id: 'channelParametersForm.Unknown Input',
    defaultMessage: 'Unknown Input',
  },
  signalDetailType: {
    id: 'channelParametersForm.Signal Detail Type',
    defaultMessage: 'Signal Detail Type',
  },
  unit: {
    id: 'channelParametersForm.Unit',
    defaultMessage: 'Unit',
  },
  physicalUnit: {
    id: 'channelParametersForm.Physical Unit',
    defaultMessage: 'Physical Unit',
  },
  zeroMeasurandOffset: {
    id: 'channelParametersForm.Zero Measurand Offset',
    defaultMessage: 'Zero Measurand Offset',
  },
  supply: {
    id: 'channelParametersForm.Supply',
    defaultMessage: 'Supply',
  },
  shuntResistance: {
    id: 'channelParametersForm.Shunt Resistance',
    defaultMessage: 'Shunt Resistance',
  },
  signal: {
    id: 'channelParametersForm.Signal',
    defaultMessage: 'Signal',
  },
  timeBase: {
    id: 'channelParametersForm.Time Base',
    defaultMessage: 'Time Base',
  },
  timeBaseTooltip: {
    id: 'channelParametersForm.Use time base value of 5 for signal frequencies below 0.5 Hz',
    defaultMessage: 'Use time base value of 5 for signal frequencies below 0.5 Hz',
  },
  supplyPrinciple: {
    id: 'channelParametersForm.Supply Principle',
    defaultMessage: 'Supply Principle',
  },
  synchronisation: {
    id: 'channelParametersForm.Synchronisation',
    defaultMessage: 'Synchronisation',
  },
  pulseFactor: {
    id: 'channelParametersForm.Pulse factor',
    defaultMessage: 'Pulse factor',
  },
  pulseFactorTooltip: {
    id: 'channelParametersForm.Defines the number of pulses per second per desired physical unit',
    defaultMessage: 'Defines the number of pulses per second per desired physical unit',
  },
  genericChannel: {
    id: 'channelParametersForm.Generic channel',
    defaultMessage: 'Generic channel',
  },
  supplyCurrent: {
    id: 'channelParametersForm.Supply current',
    defaultMessage: 'Supply current',
  },
  highPass: {
    id: 'channelParametersForm.High Pass',
    defaultMessage: 'High Pass',
  },
  order: {
    id: 'channelParametersForm.Order',
    defaultMessage: 'Order',
  },
  cuttoffFrequency: {
    id: 'channelParametersForm.Cuttoff Frequency',
    defaultMessage: 'Cuttoff Frequency',
  },
  lowPass: {
    id: 'channelParametersForm.Low Pass',
    defaultMessage: 'Low Pass',
  },
  algorithm: {
    id: 'channelParametersForm.Algorithm',
    defaultMessage: 'Algorithm',
  },
  notch: {
    id: 'channelParametersForm.Notch',
    defaultMessage: 'Notch',
  },
  centerFrequency: {
    id: 'channelParametersForm.Center Frequency',
    defaultMessage: 'Center Frequency',
  },
  qFactor: {
    id: 'channelParametersForm.Q-Factor',
    defaultMessage: 'Q-Factor',
  },
  switchingLevels: {
    id: 'channelParametersForm.Switching levels',
    defaultMessage: 'Switching levels',
  },
  switchingLevelsTooltip: {
    id: 'channelParametersForm.Defines the lower and upper threshold for switching digital channel state',
    defaultMessage: 'Defines the lower and upper threshold for switching digital channel state',
  },
  inputConfiguration: {
    id: 'channelParametersForm.Input Configuration',
    defaultMessage: 'Input Configuration',
  },
  constantTimeConstant: {
    id: 'channelParametersForm.Constant ({timeConstant})',
    defaultMessage: 'Constant ({timeConstant})',
  },
  inputDifferenceWarning: {
    id: 'channelParametersForm.Input Difference Warning',
    defaultMessage: 'The specified sensor measures "{measurand}", not "{selectedPhysicalQuantity}"',
  },
  warning: {
    id: 'channelParametersForm.warning',
    defaultMessage: 'Warning',
  },
  thisSensitivityDoesNotMatch: {
    id: 'channelParametersForm.thisSensitivityDoesNotMatchTheSensitivityOfTheCalibratedRangeThatSuitsTheSpecifiedMaximumMeasuringTheBest',
    defaultMessage:
      'This sensitivity does not match the sensitivity of the calibrated range that suits the specified physical range the best',
  },
  physicalRangeDoesNotMatch: {
    id: 'channelParametersForm.thisPhysicalRangeDoesNotMatchTheSpecifiedMaximumMeasuringValue',
    defaultMessage: 'This physical range does not match the specified maximum measuring value',
  },
  specifiedSensorMeasuresSensorsTypeNotProvidedType: {
    id: 'channelParametersForm.specifiedSensorMeasuresSensorsTypeNotProvidedType',
    defaultMessage: 'The specified sensor is a "{sensorsType}" sensor, not a "{providedType}" sensor',
  },
})
