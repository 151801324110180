import React from 'react'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { isEmpty } from 'lodash'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { SbLabel } from 'skybase-ui/skybase-components/sb-label'
import { SbHint } from 'skybase-ui/skybase-components/sb-hint'
import { loaderSize } from 'skybase-ui/skybase-components/sb-loader/constants'
import { withRouter } from '@/common/router'
import { ListView } from '@/fleet-configuration/components/list-view/list-view'
import { ListViewItem } from '@/fleet-configuration/components/list-view/list-view-item'
import { CABLE_COMPATIBILITY } from '@/fleet-configuration/utils/cable-utils-constants'
import { steps } from '@/fleet-configuration/page-components/wizard/wizard-constants'
import { wizardSummarySelector } from './wizard-summary-selector'
import { deviceTimeSyncModes } from '@/fleet-configuration/data-fleet/device-online-status/device-online-status-constants'
import { SbInlineMessage } from 'skybase-ui/skybase-components/sb-inline-message'
import { INLINE_MESSAGE_TYPE_WARNING } from 'skybase-ui/skybase-components/sb-inline-message/constants'
import { messages as t } from './wizard-summary-i18n'
import './wizard-summary.scss'

export const WizardSummary = withRouter(props => {
  const {
    device,
    channelInfo,
    isDeviceCalibrable,
    isDeviceValid,
    deviceCalibration,
    deviceChainWithNamesAndCalibrationStatus,
  } = useSelector(state => wizardSummarySelector(state, props))
  const { formatMessage: _ } = useIntl()

  const { baseUrl, rootId } = props
  const navigate = useNavigate()
  const navigateToSubpageFactory =
    (backStep, navDeviceId, navModuleIndex = null, navChannelIndex = null) =>
    () => {
      let navigatePath = `${baseUrl}${backStep}/${navDeviceId}`
      if (navModuleIndex !== null) {
        navigatePath += `/module/${navModuleIndex}`
        if (navChannelIndex !== null) {
          navigatePath += `/channel/${navChannelIndex}`
        }
      }
      navigate(navigatePath)
    }

  const fillMessage = (template, parameters, key = uuidv4()) => {
    if (!Array.isArray(template)) {
      return _(t.invalidErrorMessage)
    }
    return <div key={key}>{template.map(part => parameters[part.value] || part.value).join('')}</div>
  }

  const hasBadStatusEntries =
    deviceChainWithNamesAndCalibrationStatus?.some(
      chainEntry =>
        chainEntry.cable?.compatibilityStatus === CABLE_COMPATIBILITY.UNKNOWN ||
        chainEntry.cable?.compatibilityStatus === CABLE_COMPATIBILITY.INVALID,
    ) || !isDeviceValid

  const hasBadCalibrationEntries =
    isDeviceCalibrable && (deviceCalibration.isExpiring !== false || !deviceCalibration.isLoaded)
  const statusData = {
    calibrationExpired: {
      hintData: _(t.calibrationExpired),
      iconClass: 'calibration-certificate sbi-help-info calibration-information',
    },
    calibrationUnknown: {
      hintData: _(t.calibrationStatusUnknown),
      iconClass: 'calibration-certificate sbi-big-hint calibration-unknown',
    },
    calibrationRequestError: {
      hintData: _(t.errorCalibrationData, { newLine: <br /> }),
      iconClass: 'calibration-certificate sbi-big-hint calibration-error',
    },
    compatibilityInvalid: {
      hintData: _(t.incompatibleWithDeviceDueToMismatchingConnectors),
      iconClass: 'sensor-cable-compatibility sbi-big-error compatibility-invalid',
    },
    compatibilityUnknown: {
      iconClass: 'sensor-cable-compatibility sbi-minus-circle compatibility-unknown',
    },
    validationError: {
      iconClass: 'channel-validation sbi-big-error',
    },
    statusWellSetUp: {
      hintData: _(t.statusWellSetUp),
      iconClass: 'sensor-cable-compatibility sbi-big-success compatibility-valid',
      statusMessage: _(t.statusWellSetUp),
    },
  }
  const renderStatusComponentRow = (componentKey, hintData, iconClass, statusMessage, navigateTo) => {
    const key = componentKey || uuidv4()
    const itemRowProps = { className: classNames('m-l-20 status-component-row item-row', { clickable: !!navigateTo }) }
    if (navigateTo) {
      itemRowProps.onClick = navigateTo
    }
    return (
      <ListViewItem key={key}>
        <div {...itemRowProps}>
          {hintData && (
            <SbHint hintData={hintData} position={{ vertical: 'top', horizontal: 'center' }}>
              <span className={iconClass} />
            </SbHint>
          )}

          {hintData && Array.isArray(hintData) ? (
            <div className="status-message">
              {hintData.map((line, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`${key}-${index}`} title={line}>
                  {line}
                </div>
              ))}
            </div>
          ) : (
            <span className="status-message" title={statusMessage}>
              {statusMessage}
            </span>
          )}

          {navigateTo && <span className="m-l-10 m-r-20 sbi-chevron-right" />}
        </div>
      </ListViewItem>
    )
  }

  const renderStatusComponent = (backStep, hintData, iconClass, message, extraProps = {}) => {
    return renderStatusComponentRow(
      null,
      hintData,
      iconClass,
      _(message, { deviceName: device.name, ...extraProps }),
      navigateToSubpageFactory(backStep, rootId || device.id, null, null),
    )
  }

  const renderFilter = info => {
    const filterTypesDeviceSpecific = ['highpass', 'lowpass', 'notch']
    const filterProperties = [] // Store filter properties

    // Function to add filter properties when multiple active
    const addFilterProperties = (filterType, data) => {
      if (filterType === 'notch') {
        filterProperties.push(
          <div key={filterType} className="property-row">
            <div className="property-title">{_(t.filterType)}</div>
            <div className="property-value">{_(t.notch)}</div>
          </div>,
          <div className="property-row" key={`filterNotch_${filterType}`}>
            <div className="property-title">{_(t.centerFrequency)}</div>
            <div className="property-value">{data.frequency}</div>
            <div className="property-unit">{_(t.filterUnit)}</div>
          </div>,
          <div className="property-row" key={`filterNotch_${filterType}_qFactor`}>
            <div className="property-title">{_(t.qFactor)}</div>
            <div className="property-value">{data.qFactor}</div>
          </div>,
        )
      } else if (filterType === 'lowpass' || filterType === 'highpass') {
        filterProperties.push(
          <div key={filterType} className="property-row">
            <div className="property-title">{_(t.filterType)}</div>
            <div className="property-value">{_(t[filterType])}</div>
          </div>,
          <div className="property-row" key={`filterCutoffFrequency_${filterType}`}>
            <div className="property-title">{_(t.filterCutoffFrequency)}</div>
            <div className="property-value">{data.frequency}</div>
            <div className="property-unit">{_(t.filterUnit)}</div>
          </div>,
        )
      }
    }

    // datasource is in info.parameters and not in info.deviceSpecific
    if (info.parameters.filterType) {
      const filterTypeValue = info.parameters.filterType.toLowerCase()
      const filterTypeRender = {
        bandpass: (
          <>
            <div key={filterTypeValue} className="property-row">
              <div className="property-title">{_(t.filterType)}</div>
              <div className="property-value">{_(t.bandpass)}</div>
            </div>
            <div className="property-row" key="filterCutoffFrequencyLow">
              <div className="property-title">{_(t.filterCutoffFrequencyLow)}</div>
              <div className="property-value">{info.parameters.filterFreq1}</div>
              <div className="property-unit">{_(t.filterUnit)}</div>
            </div>
            <div className="property-row" key="filterCutoffFrequencyUp">
              <div className="property-title">{_(t.filterCutoffFrequencyUp)}</div>
              <div className="property-value">{info.parameters.filterFreq2}</div>
              <div className="property-unit">{_(t.filterUnit)}</div>
            </div>
          </>
        ),
        lowpass: (
          <>
            <div key={filterTypeValue} className="property-row">
              <div className="property-title">{_(t.filterType)}</div>
              <div className="property-value">{_(t.lowpass)}</div>
            </div>
            <div className="property-row" key="filterCutoffFrequency">
              <div className="property-title">{_(t.filterCutoffFrequency)}</div>
              <div className="property-value">{info.parameters.filterFreq1}</div>
              <div className="property-unit">{_(t.filterUnit)}</div>
            </div>
          </>
        ),
        highpass: (
          <>
            <div key={filterTypeValue} className="property-row">
              <div className="property-title">{_(t.filterType)}</div>
              <div className="property-value">{_(t.highpass)}</div>
            </div>
            <div className="property-row" key="filterCutoffFrequency">
              <div className="property-title">{_(t.filterCutoffFrequency)}</div>
              <div className="property-value">{info.parameters.filterFreq1}</div>
              <div className="property-unit">{_(t.filterUnit)}</div>
            </div>
          </>
        ),
      }

      const renderedFilterType = filterTypeRender[filterTypeValue]
      if (renderedFilterType) {
        filterProperties.push(renderedFilterType)
      }
    }
    if (!isEmpty(info.deviceSpecific)) {
      Object.entries(info.deviceSpecific).forEach(([filterType, data]) => {
        if (filterTypesDeviceSpecific.includes(filterType) && data.enabled) {
          addFilterProperties(filterType, data)
        }
      })
    }

    return filterProperties.length > 0 && filterProperties
  }

  const deviceSyncWarning =
    device?.clockSettings?.syncMode === deviceTimeSyncModes.MANUAL ? (
      <SbInlineMessage
        className="sync-warning"
        type={INLINE_MESSAGE_TYPE_WARNING}
        title={_(t.recommendedUsePtpNtpTimeSynchronization)}
        icon
      />
    ) : null

  let statusCalibrationComponent
  if (deviceCalibration.isLoaded) {
    if (hasBadCalibrationEntries && deviceCalibration.isExpiring) {
      statusCalibrationComponent = renderStatusComponent(
        steps[0].urlSegment,
        statusData.calibrationExpired.hintData,
        statusData.calibrationExpired.iconClass,
        t.calibrationStatusDeviceExpired,
      )
    } else {
      statusCalibrationComponent = renderStatusComponent(
        steps[0].urlSegment,
        statusData.calibrationUnknown.hintData,
        statusData.calibrationUnknown.iconClass,
        t.calibrationStatusDeviceUnknown,
      )
    }
  } else if (deviceCalibration.hasRequestError) {
    statusCalibrationComponent = renderStatusComponent(
      steps[0].urlSegment,
      statusData.calibrationRequestError.hintData,
      statusData.calibrationRequestError.iconClass,
      t.errorCalibrationData,
      { newLine: <br /> },
    )
  } else if (isDeviceCalibrable) {
    statusCalibrationComponent = <SbLoader size={loaderSize.XXS} show />
  }

  return (
    <div className="fl-container-row wizard-summary">
      {device ? (
        <ListView stretch={false}>
          <ListViewItem>
            <div className="item-row components-heading">
              <h2>{_(t.summary)}</h2>
            </div>
          </ListViewItem>
          <ListViewItem>
            <div className="item-row components-heading status-section">
              <h3>{_(t.status)}</h3>
              {/* TODO show status data */}
            </div>
          </ListViewItem>
          <div className="status-section">
            {deviceSyncWarning}
            {statusCalibrationComponent}
            {hasBadStatusEntries || hasBadCalibrationEntries
              ? deviceChainWithNamesAndCalibrationStatus?.map(chainEntry => {
                  const cable = chainEntry.cable || {}
                  const module = device.modules.find(
                    moduleCandidate =>
                      moduleCandidate.getType() === chainEntry.channel.typeNumber &&
                      (moduleCandidate.parametersReadable?.snr || device.serialNumber) ===
                        chainEntry.channel.serialNumber,
                  )
                  const channelId = chainEntry.channel.slot.split('/')[1] - 1

                  if (cable.compatibilityStatus === CABLE_COMPATIBILITY.INVALID) {
                    const { hintData, iconClass } = statusData.compatibilityInvalid
                    const cableName = cable.name || cable.typeNumber

                    const navigateToSubpage = navigateToSubpageFactory(
                      steps[0].urlSegment,
                      rootId || device.id,
                      module.getModuleId(),
                      channelId,
                    )

                    return renderStatusComponentRow(
                      JSON.stringify(chainEntry.channel),
                      hintData,
                      iconClass,
                      _(t.cableCableCantBeUsedWithThisDevice, { cable: cableName }),
                      navigateToSubpage,
                    )
                  }

                  return null
                })
              : renderStatusComponentRow(
                  null,
                  statusData.statusWellSetUp.hintData,
                  statusData.statusWellSetUp.iconClass,
                  statusData.statusWellSetUp.statusMessage,
                )}
            {channelInfo.map(info => {
              // show all validation errors of a channel to indicate why "Write to Device" is not allowed
              if (Object.keys(info.alert).length > 0) {
                const { iconClass } = statusData.validationError
                const navigateToSubpage = navigateToSubpageFactory(
                  steps[1].urlSegment,
                  rootId || device.id,
                  info.rowModuleIndex,
                  info.rowChannelIndex,
                )

                const errorComponents = (
                  <>
                    {Object.entries(info.alert).map(([, { message, parameters }]) =>
                      fillMessage(message.defaultMessage, parameters, message.id),
                    )}
                  </>
                )

                return renderStatusComponentRow(
                  null,
                  errorComponents,
                  iconClass,
                  `${info.moduleType} - ${_(t.channelIndex, { index: info.rowChannelIndex + 1 })}`,
                  navigateToSubpage,
                )
              }

              return null
            })}
          </div>

          <ListViewItem>
            <div className="item-row components-heading">
              <h3>{_(t.settingsOverview)}</h3>
            </div>
          </ListViewItem>
          <div className="settings-section">
            {channelInfo.map(info => (
              <div className="settings-item-row" key={info.resourcePath}>
                <div className="channel-info">
                  <SbLabel title={info.moduleType}>{_(t.channelIndex, { index: info.rowChannelIndex + 1 })}</SbLabel>
                </div>
                {info.parameters && (
                  <div className="property-container">
                    <div className="property-row" key="sensitivityScaling">
                      <div className="property-title">{_(t.sensitivityScaling)}</div>
                      <div className="property-value">{`${device.isLabAmp() && info.isChargeType ? '-' : ''}${
                        info.parameters.sensitivity
                      }`}</div>
                      <div className="property-unit">{info.parameters.sensitivityUnit}</div>
                    </div>
                    <div className="property-row" key="physicalRange">
                      <div className="property-title">{_(t.physicalRange)}</div>
                      <div className="property-value">{info.parameters.physicalRange.join(' - ')}</div>
                      <div className="property-unit">{info.parameters.physicalUnit}</div>
                    </div>
                    <div className="property-row" key="samplingRate">
                      <div className="property-title">{_(t.samplingRate)}</div>
                      <div className="property-value">{info.sampleRate}</div>
                      <div className="property-unit">{_(t.sampleUnit)}</div>
                    </div>
                    {renderFilter(info)}
                  </div>
                )}
              </div>
            ))}
          </div>
        </ListView>
      ) : (
        <SbLoader show />
      )}
    </div>
  )
})

WizardSummary.propTypes = {
  baseUrl: PropTypes.string.isRequired,
}
