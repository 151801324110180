export const deviceEventStatusToOnlineMap = {
  online: true,
  discovered: true,
  offline: false,
  unknown: null,
}

export const deviceIgnoredUpdateStatuses = ['registered']

export const deviceEventStatusList = {
  ONLINE: 'online',
  DISCOVERED: 'discovered',
  OFFLINE: 'offline',
  UNHEALTHY: 'unhealthy',
  UNKNOWN: 'unknown',
  UNSUPPORTED: 'unsupported', // this one is not returned by BE
}

export const deviceTimeSyncModes = {
  MANUAL: 'DISABLED',
  PTP: 'PTP_ONLY',
  NTP: 'NTP_ONLY',
}

export const SET_DEVICE_ONLINE_EVENT = 'set-device-online'
