import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { SbButton } from 'skybase-ui/skybase-components/sb-button'
import { STATES } from 'skybase-oauth/constants'
import { FormComponent } from 'skybase-oauth/common/components'

import { HintRenderer } from '@/common/hint-renderer'
import { getIotHubDeviceDataSourceState } from '@/iot-hub/selectors'
import { updateDeviceDataSource } from '../utils'
import { deviceDataSourceShape } from '../shapes'
import { messages as t } from './datasources-i18n'

class _DataSourceUpdate extends FormComponent {
  static propTypes = {
    ...this.propTypes,
    data: deviceDataSourceShape.isRequired,
    handleUpdate: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    ...this.defaultProps,
  }

  constructor(props) {
    super(props)

    this.dataInState = true
    this.state = {
      ...this.state,
      id: props.data?.id || null,
      data: props.data,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      data,
      data: { id: nextId },
    } = nextProps
    const { id } = prevState

    if (id !== nextId) {
      return {
        errors: {},
        id: nextId,
        data,
      }
    }

    return null
  }

  handleOnChangeParams = (attribute, value) => {
    this.setState(prevState => {
      return {
        data: {
          ...prevState.data,
          [attribute]: value,
        },
      }
    })
  }

  handleOnConfirm = () => {
    const { data: { id, deviceId, encoding, description } = {} } = this.state
    const { handleUpdate } = this.props

    if (id && deviceId) {
      handleUpdate(deviceId, id, { encoding, description })
    }
  }

  renderConfirmButton = () => {
    const {
      loading,
      intl: { formatMessage: _ },
      disabled: deviceOffline,
      acl: { write },
    } = this.props
    const { data } = this.dataInState ? this.state : this.props
    const confirmButtonDisabled = data.id !== undefined && data.id === null

    return (
      write && (
        <HintRenderer
          showHint={deviceOffline}
          hintData={_(t.cannotUpdateDataSourceWhileDeviceOffline)}
          className="sb-width-100pct"
        >
          <SbButton
            loading={loading}
            className="sb-width-100pct primary"
            onClick={this.handleOnConfirm}
            disabled={loading || confirmButtonDisabled || deviceOffline}
          >
            {_(t.update)}
          </SbButton>
        </HintRenderer>
      )
    )
  }

  renderInputs = () => {
    const {
      intl: { formatMessage: _ },
      data: { encoding, supportedEncodings },
    } = this.props
    const encodingItems = supportedEncodings?.map?.(sEncoding => {
      return { value: sEncoding, name: sEncoding }
    }) || [{ value: encoding, name: encoding }]

    return (
      <>
        {this.renderInputWithLabel('description', _(t.description), {
          labelClassName: 'datasource-update-description',
          componentType: 'textarea',
          placeholder: _(t.descriptionPlaceholder),
        })}
        {this.renderInputWithLabel('encoding', _(t.encoding), {
          labelClassName: 'datasource-update-encoding',
          componentType: 'select',
          items: encodingItems,
        })}
      </>
    )
  }
}

const mapStateToProps = state => {
  const { updateDataSourceState } = getIotHubDeviceDataSourceState(state)

  return {
    loading: updateDataSourceState === STATES.LOADING,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleUpdate: (deviceId, dataSourceId, data) => dispatch(updateDeviceDataSource(deviceId, dataSourceId, data)),
  }
}

export const DataSourceUpdate = injectIntl(connect(mapStateToProps, mapDispatchToProps)(_DataSourceUpdate))
