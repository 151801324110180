// THIS FILE MAY BE REMOVED IN THE FUTURE, CURRENTLY IT IS NOT USED, THERE IS GOING TO BE A DECISION ABOUT IT SOON.
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
// import { batchActions } from 'redux-batched-actions'
import PropTypes from 'prop-types'

import { SbEmitter } from 'skybase-ui/skybase-core/emitter'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbLoaderButton, SbButton } from 'skybase-ui/skybase-components/sb-button'
import { SbModal, SbModalHeader } from 'skybase-ui/skybase-components/sb-modal'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'

import { JSONEditor } from '@/common/json-editor'
import { messages as t } from './resources-i18n'

class _ResourceModal extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    handleOverlayClick: PropTypes.func.isRequired,
    json: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]).isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      interfaceJSON: props.json,
      hasError: false,
    }
  }

  componentWillUnmount() {
    const { interfaceJSON } = this.state

    SbEmitter.emit('resource-type-modal-data', false, { interfaceJSON })
  }

  handleOnClose = () => {
    const { handleOverlayClick } = this.props

    handleOverlayClick()
  }

  handleOnConfirmClick = () => {
    console.log('confirm click')
  }

  handleOnRetrieveClick = () => {
    console.log('retrieve click')
  }

  handleOnSettingsChange = interfaceJSON => this.setState({ interfaceJSON })

  handleOnSettingsError = error => this.setState({ hasError: error.length > 0 })

  renderHeader = () => {
    const {
      intl: { formatMessage: _ },
    } = this.props

    return <SbModalHeader title={_(t.retrieveOrUpdate)} onCloseBtnClick={this.handleOnClose} />
  }

  renderFooter = () => {
    // const { loading } = this.props
    const { hasError } = this.state
    const {
      intl: { formatMessage: _ },
    } = this.props

    return (
      <div className="sb-modal-footer fl-container fl-justify-end">
        <div className="sb-modal-left" />
        <div className="sb-modal-center" />
        <div className="sb-modal-right">
          <SbButton className="m-r-10" onClick={this.handleOnRetrieveClick}>
            {_(t.retrieve)}
          </SbButton>
          <SbLoaderButton
            // loading={loading}
            disabled={hasError}
            className="primary"
            onClick={this.handleOnConfirmClick}
          >
            {_(t.update)}
          </SbLoaderButton>
        </div>
      </div>
    )
  }

  render() {
    const { interfaceJSON } = this.state

    return (
      <SbModal
        Header={this.renderHeader()}
        Footer={this.renderFooter()}
        width={500}
        height="auto"
        className="resource-modal"
        onClickOverlay={this.handleOnClose}
      >
        <div className="sb-message-modal-content">
          <JSONEditor
            json={interfaceJSON}
            onChange={this.handleOnSettingsChange}
            onError={this.handleOnSettingsError}
            autofocus
          />
        </div>
      </SbModal>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleOnChangeParams: () => {},
    handleOnConfirm: () => {},
    handleOverlayClick: () => dispatch(closeModal()),
  }
}

export const ResourceModal = injectIntl(connect(null, mapDispatchToProps)(_ResourceModal))
