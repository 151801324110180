import React from 'react'
import { ReactComponent as ModuleIcon } from '@/fleet-configuration/icons/Module-Icon.svg'
import { ReactComponent as MeasurementUnitIcon } from '@/fleet-configuration/icons/Unit-Selected.svg'
import { ReactComponent as LabampIcon } from '@/fleet-configuration/icons/LabampIcon.svg'
import { ReactComponent as ChannelIcon } from '@/fleet-configuration/icons/Channel.svg'
import PropTypes from 'prop-types'

export const DeviceComponentIcon = ({ width = 30, height = 30, types = [], ...rest }) => {
  const knownIcons = [
    { Icon: LabampIcon, types: ['x.com.kistler.labamp'] },
    { Icon: LabampIcon, types: ['x.com.kistler.labamp.syncbox'] }, // only added on ui not supported
    { Icon: MeasurementUnitIcon, types: ['x.com.kistler.kgate'] },
    {
      Icon: ModuleIcon,
      types: ['x.com.kistler.kistudio.module', 'x.com.kistler.kgate.module', 'x.com.kistler.kgate.controller'],
    },
    { Icon: ChannelIcon, types: ['x.com.kistler.acquisition.channel'] },
  ]

  const component = knownIcons.find(({ types: iconTypes }) => iconTypes.some(type => types.includes(type)))
  const Icon = component ? component.Icon : MeasurementUnitIcon
  return <Icon width={width} height={height} {...rest} />
}

DeviceComponentIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  types: PropTypes.array,
}
