import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  serialNumber: {
    id: 'equipment.serialNumber',
    defaultMessage: 'Serial Number',
  },
  typeNumber: {
    id: 'equipment.typeNumber',
    defaultMessage: 'Type Number',
  },
  family: {
    id: 'equipment.family',
    defaultMessage: 'Family',
  },
  name: {
    id: 'equipment.name',
    defaultMessage: 'Type Name',
  },
  notes: {
    id: 'equipment.notes',
    defaultMessage: 'Notes',
  },
  serialNumberPlaceholder: {
    id: 'equipment.serialNumberPlaceholder',
    defaultMessage: 'Enter a Serial Number',
  },
  typeNumberPlaceholder: {
    id: 'equipment.typeNumberPlaceholder',
    defaultMessage: 'Enter a Type Number',
  },
  selectFamily: {
    id: 'equipment.selectFamily',
    defaultMessage: 'Select',
  },
  namePlaceholder: {
    id: 'equipment.namePlaceholder',
    defaultMessage: 'Enter a Type Name',
  },
  notePlaceholder: {
    id: 'equipment.notePlaceholder',
    defaultMessage: 'Enter a Note',
  },
  noEquipmentFound: {
    id: 'equipment.noEquipmentFound',
    defaultMessage: 'No equipment found.',
  },
  cable: {
    id: 'equipment.cable',
    defaultMessage: 'Cable',
  },
  sensor: {
    id: 'equipment.sensor',
    defaultMessage: 'Sensor',
  },
  device: {
    id: 'equipment.device',
    defaultMessage: 'Device',
  },
  module: {
    id: 'constants.deviceModule',
    defaultMessage: 'Device module',
  },
  controller: {
    id: 'constants.deviceController',
    defaultMessage: 'Device controller',
  },
  unknown: {
    id: 'equipment.unknown',
    defaultMessage: 'Unknown',
  },
  addNew: {
    id: 'equipment.addNew',
    defaultMessage: 'Add new',
  },
  addNewEquipment: {
    id: 'equipment.addNewEquipment',
    defaultMessage: 'Add new equipment',
  },
  editEquipment: {
    id: 'equipment.editEquipment',
    defaultMessage: 'Edit equipment',
  },
  paramRequiresAtLeastMinCharacters: {
    id: 'equipment.paramRequiresAtLeastMinCharacters',
    defaultMessage: '{param} must be at least {min} characters long.',
  },
  save: {
    id: 'equipment.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'equipment.cancel',
    defaultMessage: 'Cancel',
  },
  atLeastOneFieldHasToBeFilled: {
    id: 'equipment.atLeastOneFieldHasToBeFilled',
    defaultMessage: 'At least one field has to be filled.',
  },
  delete: {
    id: 'equipment.delete',
    defaultMessage: 'Delete',
  },
  deleteEquipment: {
    id: 'equipment.deleteEquipment',
    defaultMessage: 'Delete equipment',
  },
  deleteDesc: {
    id: 'equipment.deleteDesc',
    defaultMessage: 'Are you sure you want to delete this equipment?',
  },
  deleteDesc2: {
    id: 'equipment.deleteDesc2',
    defaultMessage: 'This action cannot be undone.',
  },
  equipmentWasSuccessfullyAdded: {
    id: 'equipment.equipmentWasSuccessfullyAdded',
    defaultMessage: 'Equipment was successfully added.',
  },
  equipmentWasSuccessfullyUpdated: {
    id: 'equipmentModal.equipmentWasSuccessfullyUpdated',
    defaultMessage: 'Equipment was successfully updated',
  },
  failedToAddAnEquipment: {
    id: 'equipment.failedToAddAnEquipment',
    defaultMessage: 'Failed to add the equipment.',
  },
  failedToUpdateAnEquipment: {
    id: 'equipmentModal.failedToUpdateAnEquipment',
    defaultMessage: 'Failed to update an equipment',
  },
  equipmentWasSuccessfullyDeleted: {
    id: 'equipment.equipmentWasSuccessfullyDeleted',
    defaultMessage: 'Equipment was successfully deleted.',
  },
  failedToDeleteAnEquipment: {
    id: 'equipment.failedToDeleteAnEquipment',
    defaultMessage: 'Failed to delete the equipment.',
  },
  failedToFetchCatalog: {
    id: 'equipment.failedToLoadCatalog',
    defaultMessage: 'Failed to get catalog elements, please try again later',
  },
  componentNotFound: {
    id: 'equipment.componentNotFound',
    defaultMessage: 'Component not found',
  },
  documentation: {
    id: 'equipment.documentation',
    defaultMessage: 'Documentation',
  },
  documentationNotAvailable: {
    id: 'equipment.documentationNotAvailable',
    defaultMessage: 'Documentation is not available.',
  },
  documentationOfNotAvailable: {
    id: 'equipment.documentationOfNotAvailable',
    defaultMessage: 'Documentation of {typeNumber} is not available.',
  },
  calibration: {
    id: 'equipment.calibration',
    defaultMessage: 'Calibration',
  },
  calibrationOfYourEquipmentExpiredOrIsAboutToExpire: {
    id: 'equipment.calibrationOfYourEquipmentExpiredOrIsAboutToExpire',
    defaultMessage:
      'The calibration of your equipment has expired or is about to expire.{newLine}Calibration of your equipment is recommended to achieve precise measurements.',
  },
  noCalibrationData: {
    id: 'equipment.noCalibrationData',
    defaultMessage: 'No calibration data',
  },
  notAvailable: {
    id: 'fleetOverviewExpandedRow.notAvailable',
    defaultMessage: 'Not available',
  },
  errorCalibrationData: {
    id: 'fleetOverviewExpandedRow.errorCalibrationData',
    defaultMessage: 'Calibration data are not reachable at this moment.{newLine}Please visit this page later.',
  },
  expiry: {
    id: 'equipment.expiry',
    defaultMessage: 'expiry: {date}',
  },
  lastCalibrationDateDate: {
    id: 'equipment.lastCalibrationDateDate',
    defaultMessage: 'Last calibration date: {date}',
  },
  calibrationExplanationHint: {
    id: 'equipment.calibrationExplanationHint',
    defaultMessage:
      'Why is calibration so important?{newLine}{newLine}Calibration verifies the precision and reproducibility of measurement instruments, such as sensors and measuring systems. {newLine}The Instruments that are calibrated are the prerequisite for precise, reliable and reproducible measurement results.{newLine}{newLine}For details, click on the icon and read more.',
  },
  notificationsIssueSToPayAttention: {
    id: 'equipment.notificationsIssueSToPayAttention',
    defaultMessage: '{notifications} {notifications, plural, one {issue} other {issues}} to pay attention',
  },
  calibrationCertificateNoAccess: {
    id: 'equipment.navigateToCalibrationCertificate',
    defaultMessage: 'Obtaining of the Calibration Certificate of the {equipment} failed.',
  },
  calibrationCertificateIsNotAvailable: {
    id: 'equipment.calibrationCertificateIsNotAvailable',
    defaultMessage: 'Calibration certificate is not available',
  },
  calibrationCertificate: {
    id: 'equipment.calibrationCertificate',
    defaultMessage: 'Calibration certificate',
  },
  orderCalibration: {
    id: 'equipment.orderCalibration',
    defaultMessage: 'Order calibration',
  },
  calibrationServiceOrder: {
    id: 'equipment.calibrationServiceOrder',
    defaultMessage: 'Calibration service order',
  },
  email: {
    id: 'equipment.email',
    defaultMessage: 'Email',
  },
  fullName: {
    id: 'equipment.fullName',
    defaultMessage: 'Full Name',
  },
  company: {
    id: 'equipment.company',
    defaultMessage: 'Company',
  },
  force: {
    id: 'equipment.force',
    defaultMessage: 'Force',
  },
  quantity: {
    id: 'equipment.quantity',
    defaultMessage: 'Quantity',
  },
  note: {
    id: 'equipment.note',
    defaultMessage: 'Note',
  },
  send: {
    id: 'equipment.send',
    defaultMessage: 'Send',
  },
  yourOrderCalibrationWasSuccessfullySent: {
    id: 'equipment.yourOrderCalibrationWasSuccessfullySent',
    defaultMessage: 'Your order calibration was successfully sent',
  },
  pleaseTryAgainLater: {
    id: 'equipment.pleaseTryAgainLater',
    defaultMessage: 'Please try again later',
  },
  pleaseContactAdmin: {
    id: 'equipment.pleaseContactAdmin',
    defaultMessage: 'Please contact your administrator',
  },
  yourCalibrationOrderCouldNotBeSent: {
    id: 'equipment.yourCalibrationOrderCouldNotBeSent',
    defaultMessage: 'Your calibration order could not be sent',
  },
  yourCalibrationOrderIsForbidden: {
    id: 'equipment.yourCalibrationOrderIsForbidden',
    defaultMessage: 'You do not have a permission to send an Email.',
  },
  formSubject: {
    id: 'equipment.form.subject',
    defaultMessage: 'Customer request for',
  },
  formSubjectAdd: {
    id: 'equipment.form.subject.add',
    defaultMessage: 'from KiCloud',
  },
  formBodyRequestType: {
    id: 'equipment.form.body.requestType',
    defaultMessage: 'Request type',
  },
  formRequestTypeCalibration: {
    id: 'equipment.form.body.requestType.calibration',
    defaultMessage: 'Calibration',
  },
  formBodyTypeNr: {
    id: 'equipment.form.body.TypeNr',
    defaultMessage: 'Type Number',
  },
  formBodySerialNr: {
    id: 'equipment.form.body.SerialNr',
    defaultMessage: 'Serial Number',
  },
  formBodyPersonalInfo: {
    id: 'equipment.form.body.PersonalInfo',
    defaultMessage: 'Personal information',
  },
  formBodyEmail: {
    id: 'equipment.form.body.email',
    defaultMessage: 'Email',
  },
  formBodyFullName: {
    id: 'equipment.form.body.fullName',
    defaultMessage: 'Name',
  },
  formBodyCompany: {
    id: 'equipment.form.body.company',
    defaultMessage: 'Company',
  },
  formBodyNote: {
    id: 'equipment.form.body.note',
    defaultMessage: 'Note',
  },
  error: {
    id: 'equipment.error',
    defaultMessage: 'Error',
  },
  anErrorOccurredWhileFetchingCompanyInformation: {
    id: 'equipment.anErrorOccurredWhileFetchingCompanyInformation',
    defaultMessage: 'An error occurred while fetching company information.',
  },
  anErrorOccurredWhileFetchingTheUsers: {
    id: 'equipment.anErrorOccurredWhileFetchingTheUsers',
    defaultMessage: 'An error occurred while fetching the users.',
  },
  emailAddressProvidedIsNotValid: {
    id: 'equipment.emailAddressProvidedIsNotValid',
    defaultMessage: 'Email address provided is not valid',
  },
  validationError: {
    id: 'equipment.validationError',
    defaultMessage: 'Validation error',
  },
  copiedText: {
    id: 'equipment.copiedText',
    defaultMessage: 'Copied "{text}"',
  },
  expiration: {
    id: 'equipment.expiration',
    defaultMessage: 'Expiration',
  },
  lastCalibration: {
    id: 'equipment.lastCalibration',
    defaultMessage: 'Last calibration',
  },
  statusAsOf: {
    id: 'equipment.statusAsOf',
    defaultMessage: 'Status as of',
  },
  all: {
    id: 'equipment.all',
    defaultMessage: 'All',
  },
})
