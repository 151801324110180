import React from 'react'

import AndroidDeviceOnboardingQr from './assets/android_device_onboarding_qr.png'
import IOSDeviceOnboardingQr from './assets/ios_device_onboarding_qr.png'

export const DeviceOnboarding = () => {
  return (
    <div id="device-onboarding" className="device-onboarding">
      <div className="qr-container">
        <img src={AndroidDeviceOnboardingQr} alt="Android Device Onboarding QR" />
        <a
          href="https://play.google.com/store/apps/details?id=com.kistler.deviceonboardr&pli=1"
          target="_blank"
          rel="noreferrer"
          id="android-app-link"
        >
          Android
        </a>
      </div>

      <div className="qr-container">
        <img src={IOSDeviceOnboardingQr} alt="iOS Device Onboarding QR" />
        <a
          href="https://apps.apple.com/ch/app/device-onboarding/id1565107735?l=en"
          target="_blank"
          rel="noreferrer"
          id="ios-app-link"
        >
          iOS
        </a>
      </div>
    </div>
  )
}
