import React from 'react'
import { useIntl } from 'react-intl'

import { OAuth } from 'skybase-oauth/oauth'
import { SbFullLayout } from 'skybase-ui/skybase-components/layouts'

import { messages as menuT } from '@/common/menu/menu-i18n'
import { messages as t } from './info-page-i18n'
import './info-page.scss'

export const InfoPage = () => {
  const { formatMessage: _ } = useIntl()
  return (
    <SbFullLayout
      title={_(t.pageName)}
      breadcrumbs={[
        {
          path: '/',
          title: _(menuT.home),
        },
        {
          path: '/iot-hub',
          title: _(menuT.iotHub),
        },
        {
          path: '/iot-hub/about',
          title: _(menuT.about),
        },
        _(t.pageName),
      ]}
    >
      <div id="info-page">
        <div className="app-title">
          <strong>Client </strong>App
        </div>
        <span className="version">
          {`${_(t.version)} `} {OAuth.config?.CLIENT_APP_VERSION?.trim?.() || _(t.unknown)}
        </span>
      </div>
    </SbFullLayout>
  )
}
