// TODO: somehow axios does not register with eslint - triggering error (even it it is node_modules hard dependency). Find out why
// eslint-disable-next-line import/no-unresolved
import axios from 'axios'
import { batchActions } from 'redux-batched-actions'
import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter'
import { getStudioAPIHost } from '@/utils/url'
import { areProjectsLoaded, getProjects } from '@/fleet-configuration/data-fleet/projects/projects-selectors'
import { PROJECTS_LOADED_EVENT } from '@/fleet-configuration/data-fleet/projects/projects-constants'

export const REMOVE_ALL_PROJECTS = getActionName('REMOVE_ALL_PROJECTS')
export const removeAllProjects = () => createAction(REMOVE_ALL_PROJECTS)

export const SET_PROJECT = getActionName('SET_PROJECT')
export const setProject = project => createAction(SET_PROJECT, project)

export const REMOVE_PROJECT = getActionName('REMOVE_PROJECT')
export const removeProject = project => createAction(REMOVE_PROJECT, project)

export const PROJECTS_LOADED = getActionName('PROJECTS_LOADED')
export const projectsLoaded = () => createAction(PROJECTS_LOADED)

let projectsRequest = null
export const loadProjects = () => async dispatch => {
  // deduplicate running requests
  if (!projectsRequest) {
    projectsRequest = axios.get(`${getStudioAPIHost()}/api/projects/default`)
  }
  const { data } = await projectsRequest
  projectsRequest = null

  const actions = [removeAllProjects(), setProject(data), projectsLoaded()]
  dispatch(batchActions(actions))
  SbEmitter.emit(PROJECTS_LOADED_EVENT)
  return data
}

export const loadProjectsIfEmpty = () => (dispatch, getState) =>
  areProjectsLoaded(getState()) ? getProjects(getState()) : loadProjects()(dispatch, getState)

const updateProject = async project => {
  await axios.put(`${getStudioAPIHost()}/api/projects/${project.id}`, project)
  return Promise.resolve(project)
}

const createProject = async project => {
  const {
    headers: { location },
  } = await axios.post(`${getStudioAPIHost()}/api/projects`, project)
  const { data } = await axios.get(location)
  return data
}

export const saveProject = project => async dispatch => {
  const modifiedProject = await (project.id ? updateProject(project) : createProject(project))
  dispatch(setProject(modifiedProject))
  return modifiedProject
}

export const deleteProject = project => async dispatch => {
  await axios.delete(`${getStudioAPIHost()}/api/projects/${project.id}`)
  dispatch(removeProject(project))
}
