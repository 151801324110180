import { find, flatten } from 'lodash'
import { option as toOption } from '@/common/options/option'
import { mapOptions } from '@/common/options/map-options'
import { getFieldName, getDependentOptions, hasCustomUnit } from './channel-utils'
import { messages as t } from './channel-options-i18n'

export class ChannelOptions {
  constructor(channelCatalog, channel) {
    this.catalog = channelCatalog
    this.channel = channel
    this.optionsCache = {}
    this.subResourceOptionsCache = {}
  }

  getBaseUnit() {
    const unitOptions = this.getCatalogOptions('unit')
    const baseUnitOption = unitOptions.find(({ changes }) =>
      changes.some(change => getFieldName(change.field) === 'unitFactor' && change.values.value === 1),
    )
    return baseUnitOption.value
  }

  getOptionDescription(field, value) {
    const option = this.getOption(field, value)
    return option.description
  }

  hasOption(field, value) {
    const option = this.getOption(field, value)
    return !option.unknown
  }

  getOption(field, value) {
    const options = this.getEnumOptions(field)
    const option = find(options, { value })

    if (!option) {
      // empty value option should not require defining actual options in catalog
      // also invisible fields with empty value should not require it
      if (!value || options.length === 0 || !this.channel.hasField(field)) {
        return { value: '', description: '' }
      }

      const isArbitraryValueAllowed = ['unit', 'hwRange'].includes(field)
      if (!isArbitraryValueAllowed) {
        console.error(
          `In Channel "${this.channel.resourcePath}" (${
            this.channel.signalName
          }) Value "${value}" is not a valid enum value for ${field}. Valid values are: ${JSON.stringify(options)}`,
        )
      }
      return { value: 'invalid value', rawDescription: t.invalidValue, unknown: true }
    }

    return option
  }

  getOptions(field) {
    if (!this.optionsCache[field]) {
      this.optionsCache[field] = mapOptions(this.getEnumOptions(field), 'value', ['description', 'rawDescription'])
    }
    return this.optionsCache[field]
  }

  getEnumOptions(field) {
    switch (field) {
      case 'hwRange':
        return this.getHwRangeOptions()
      case 'filterFreq1':
        return this.getFrequencyOptions('filterFreq1')
      case 'filterFreq2':
        return this.getFrequencyOptions('filterFreq2')
      case 'sensitivityUnit':
        return this.getSensitivityUnitOptions()
      case 'pulseUnit':
        return this.getPulseUnitOptions()
      case 'physicalUnit':
        return this.getPhysicalUnitOptions()
      case 'sensorConnectorType':
        return this.getSensorConnectorTypeOptions()
      case 'physicalQuantity':
        return this.getPhysicalQuantityOptions()
      case 'measurementRange':
        return this.getMeasurementRangeOptions()
      default:
        return this.getCatalogOptionsVisible(field)
    }
  }

  getSubResourceOptions(subResource, field) {
    if (!this.subResourceOptionsCache[subResource]) {
      this.subResourceOptionsCache[subResource] = {}
    }
    if (!this.subResourceOptionsCache[subResource][field]) {
      this.subResourceOptionsCache[subResource][field] = mapOptions(
        this.getSubResourceEnumOptions(subResource, field),
        'value',
        ['description', 'rawDescription'],
      )
    }
    return this.subResourceOptionsCache[subResource][field]
  }

  getSubResourceEnumOptions(subResourceName, field) {
    const subResource = this.channel.getSubResource(subResourceName)
    return (subResource[field] && subResource[field].enum) || []
  }

  getHwRangeOptions() {
    return this.getCatalogOptionsVisible('hwRange')
  }

  getFrequencyOptions(field) {
    const options = this.getCatalogOptionsVisible(field)
    return options.map(o => ({ ...o, description: `${parseFloat(o.value)} Hz` }))
  }

  getPulseUnitOptions() {
    if (hasCustomUnit(this.channel)) {
      return [
        {
          value: this.channel.parameters.physicalUnit,
          description: `Hz / ${this.channel.parameters.physicalUnit}`,
          changes: [],
        },
      ]
    }
    return this.catalog.hasField('pulseUnit') ? this.getCatalogOptionsVisible('pulseUnit') : []
  }

  getSensitivityUnitOptions() {
    if (this.channel.isBridgeSensorType()) {
      return [
        toOption('V/V', 'V/V', {
          sensorUnitOption: toOption('V', 'V', {
            unitFactor: 1,
            unitOffset: 0,
          }),
          physicalUnitOption: toOption('V', 'V'),
        }),
        toOption('mV/V', 'mV/V', {
          sensorUnitOption: toOption('mV', 'mV', {
            unitFactor: 1000,
            unitOffset: 0,
          }),
          physicalUnitOption: toOption('mV', 'mV'),
        }),
      ]
    }

    const unitUnitOptions = this.catalog.hasField('unit') ? this.getCatalogOptionsVisible('unit') : []
    const physicalUnitOptions = this.getPhysicalUnitOptions()

    return flatten(
      physicalUnitOptions.map(physicalUnitOption =>
        unitUnitOptions.map(sensorUnitOption => ({
          value: `${sensorUnitOption.value}/${physicalUnitOption.value}`,
          description: `${sensorUnitOption.description}/${physicalUnitOption.description}`,
          sensorUnitOption,
          physicalUnitOption,
        })),
      ),
    )
  }

  getPhysicalUnitOptions() {
    if (hasCustomUnit(this.channel)) {
      return [
        {
          value: this.channel.parameters.physicalUnit,
          description: this.channel.parameters.physicalUnit,
          changes: [],
        },
      ]
    }
    return this.catalog.hasField('physicalUnit') ? this.getCatalogOptionsVisible('physicalUnit') : []
  }

  getPhysicalQuantityOptions() {
    const isTemperatureSensorType = this.channel.isTemperatureSensorType()
    return this.getCatalogOptionsVisible('physicalQuantity').filter(
      ({ value }) => isTemperatureSensorType || value !== 'Temperature',
    )
  }

  getMeasurementRangeOptions() {
    const { physicalQuantity } = this.channel.parameters
    switch (physicalQuantity) {
      case 'Acceleration':
        return [toOption('g-force', 'g')]
      case 'Force':
        return [toOption('kN')]
      case 'Pressure':
        return [toOption('bar')]
      case 'Torque':
        return [toOption('Nm')]
      case 'Voltage':
        return [toOption('V')]
      case 'Current':
        return [toOption('A')]
      case 'Resistance':
        return [toOption('Ω')]
      case 'Charge':
        return [toOption('pC')]
      default:
        return [toOption('m.u.')]
    }
  }

  getSensorConnectorTypeOptions() {
    return this.getCatalogOptionsVisible('sensorConnectorType')
  }

  getPhysicalQuantityOption() {
    const unitOption = this.catalog.getEnumOption('physicalUnit', this.channel.parameters.physicalUnit)
    const physicalQuantityOption = this.catalog
      .getEnum('physicalQuantity')
      .find(o =>
        unitOption && o.filters
          ? o.filters.some(f => f.values.groups.includes(unitOption.group))
          : o.value === 'Custom value',
      )
    return physicalQuantityOption
  }

  getCatalogOptionsVisible(field) {
    const options = this.getCatalogOptions(field)
    const { channelsPerConnector } = this.catalog.getConfigurationParameters()
    const visibleOptions = options.filter(o => {
      if (o.visible === false) {
        return false
      }
      if (o.channelsUsed && o.channelsUsed > 1) {
        if (channelsPerConnector) {
          const groupIndex = this.channel.getChannelIndex() % channelsPerConnector
          // check if it's "first" available position within connector
          if (groupIndex % o.channelsUsed === 0) {
            // check if this option still fits into single connector
            return groupIndex + o.channelsUsed <= channelsPerConnector
          }
          return false
        }
        // presume all channels are 1 connector
        return this.channel.getChannelIndex() % o.channelsUsed === 0
      }
      return true

      // NOTE if "channelsPerConnector" and "channelsUsed" is not enough, we can still explicitly
      // program in new parameter "channelsAllowed" that will directly give index to allowed indexes
    })
    const untranslatableFields = ['physicalUnit', 'unit', 'hwRange', 'filterFreq1', 'filterFreq2']
    if (untranslatableFields.includes(field)) {
      return visibleOptions
    }
    return this.markTranslateOptions(visibleOptions)
  }

  getCatalogOptions(field) {
    let options = this.catalog.getEnum(field)
    const dependentOptions = getDependentOptions(this.catalog, this.channel, this, field)
    for (let i = 0; i < dependentOptions.length; i += 1) {
      const option = dependentOptions[i]
      let filter = find(option.filters, f => getFieldName(f.field) === field)

      if (filter && filter.redirect) {
        // in case of filter redirect we have to use filter option from the redirected field
        const fieldName = getFieldName(filter.redirect)
        const redirectedOption = this.getOption(fieldName, this.channel.parameters[fieldName])
        filter = find(redirectedOption.filters, f => getFieldName(f.field) === field)
      }

      if (filter && filter.values) {
        const { groups } = filter.values
        if (groups) {
          options = options.filter(o => groups.includes(o.group))
          break
        }
      }
    }
    return options
  }

  markTranslateOptions = options =>
    options.map(fullOption => {
      const { description, ...option } = fullOption
      return {
        ...option,
        rawDescription: description,
      }
    })

  getHwRangeBounds = hwRangeOption => {
    const lowerBound = this.findChange(hwRangeOption, 'minValue')
    const upperBound = this.findChange(hwRangeOption, 'maxValue')
    return { lowerBound, upperBound }
  }

  findChange(option, fieldName) {
    const { changes } = option
    return changes.find(change => getFieldName(change.field) === fieldName).values.value
  }
}
