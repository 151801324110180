import React from 'react'
import PropTypes from 'prop-types'
import { ReloadSettingsButton } from '@/fleet-configuration/components/reload-settings-button/reload-settings-button'
import './measurement-buttons.scss'

export const MeasurementButtons = ({ deviceId }) => (
  <div className="measurement-buttons-on-right">
    <ReloadSettingsButton deviceId={deviceId} />
  </div>
)
MeasurementButtons.propTypes = {
  deviceId: PropTypes.string.isRequired,
}
