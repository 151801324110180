import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { getTenantApi } from 'skybase-oauth/rest'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'
import { messages as oa } from 'skybase-oauth/messages-i18n'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter'

import { showSuccessToast } from '@/common/services/show-toast'
import { useIsMounted } from '@/hooks/use-is-mounted'
import { getLicensesOfTicketApi, assignLicensesToUserApi } from '../../rest'
import { licenseErrorTypes } from '../../constants'
import { assignLicenseSteps } from './constants'
import { handleLicensesOfTicketApiErrors, getSelectedLicenseNamesAsString } from './utils'
import { messages as t } from './licenses-i18n'
import { EnterLicenseTicketIdModal } from './_enter-license-ticket-id-modal'
import { AssignLicenseFromListModal } from './_assign-license-from-list-modal'

const { ENTER_TICKET_ID, ASSIGN_LICENSE } = assignLicenseSteps

export const AssignLicenseModal = ({ tenantId, containerSerial = null, ...props }) => {
  const isMounted = useIsMounted()
  const dispatch = useDispatch()
  const [fetching, setFetching] = useState(false)
  const [step, setStep] = useState(ENTER_TICKET_ID)
  const [params, setParams] = useState({
    tenantName: '',
    initialTicketId: '',
  })
  const [licenses, setLicenses] = useState([])
  const ticketBelongsToCurrentTenant =
    !!containerSerial &&
    licenses?.findIndex?.(
      license => license.containerSerial === containerSerial && license.userIdentification === tenantId,
    ) !== -1
  const ticketHasOwner = licenses?.some?.(license => license.userIdentification || license.containerSerial)

  const fetchLicensesOfTicket = async ({ ticketId }) => {
    try {
      setFetching(true)
      const { name } = await getTenantApi(tenantId)
      const licensesOfTicket = await getLicensesOfTicketApi(ticketId)

      if (isMounted.current) {
        setLicenses(licensesOfTicket)
        setStep(ASSIGN_LICENSE)
        setFetching(false)
        setParams({
          tenantName: name,
          initialTicketId: ticketId,
        })
      }
    } catch (error) {
      if (isMounted.current) {
        setFetching(false)
      }
      handleLicensesOfTicketApiErrors(error, ticketId)
    }
  }

  const assignLicensesToUser = async ({ licenseIds }) => {
    const { initialTicketId: ticketId } = params
    const licensesString = getSelectedLicenseNamesAsString(licenses, licenseIds)

    try {
      setFetching(true)
      await assignLicensesToUserApi({ ticketId, userId: tenantId, licenseIds })

      if (isMounted.current) {
        setFetching(false)

        // Show a success toast with the names of assigned licenses
        showSuccessToast(
          {
            message: t.licensesWereAssigned,
            params: { licenses: licensesString },
          },
          oa.success,
        )

        // Re-fetch the licenses list
        SbEmitter.emit('licensing.getLicenses')

        // Close the assign license modal
        dispatch(closeModal())
      }
    } catch (error) {
      if (isMounted.current) {
        setFetching(false)
      }
      handleLicensesOfTicketApiErrors(error, ticketId, licenseErrorTypes.ASSIGN)
    }
  }

  if (step === ASSIGN_LICENSE) {
    return (
      <AssignLicenseFromListModal
        {...props}
        data={licenses}
        onAssign={assignLicensesToUser}
        loading={fetching}
        licenseTicketId={params.initialTicketId}
        tenantName={params.tenantName}
        onBack={() => setStep(ENTER_TICKET_ID)}
        ticketBelongsToCurrentTenant={ticketBelongsToCurrentTenant}
        ticketHasOwner={ticketHasOwner}
      />
    )
  }

  return (
    <EnterLicenseTicketIdModal
      {...props}
      initialTicketId={params.initialTicketId}
      onProceed={fetchLicensesOfTicket}
      loading={fetching}
    />
  )
}

AssignLicenseModal.propTypes = {
  tenantId: PropTypes.string.isRequired,
  containerSerial: PropTypes.string,
}
