import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  pageName: {
    id: 'tenants-page.pageName',
    defaultMessage: 'Tenants',
  },
  assignedPageName: {
    id: 'tenants-page.assignedPageName',
    defaultMessage: 'Assigned tenants',
  },
  tenantNotFound: {
    id: 'tenants-page.tenantNotFound',
    defaultMessage: 'Tenant with ID "{tenantId}" was not found.',
  },
  loading: {
    id: 'tenants-page.loading',
    defaultMessage: 'Loading',
  },
  invalidGuid: {
    id: 'tenants-page.invalidGuid',
    defaultMessage: 'Invalid GUID.',
  },
})
