import { useEffect, useRef } from 'react'

// Hook for checking if the component is mounted
export const useIsMounted = () => {
  const componentIsMounted = useRef(false)
  useEffect(() => {
    componentIsMounted.current = true

    return () => {
      componentIsMounted.current = false
    }
  }, [])
  return componentIsMounted
}
