import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { SbFullLayout } from 'skybase-ui/skybase-components/layouts/sb-full-layout'
import { SbDataTable } from 'skybase-ui/skybase-components/sb-data-table'
import { SbTextbox } from 'skybase-ui/skybase-components/sb-textbox/sb-textbox'
import { SbHint } from 'skybase-ui/skybase-components/sb-hint/sb-hint'
import { hintPositions } from 'skybase-ui/skybase-components/sb-hint/constants'
import { checkboxLabelPosition } from 'skybase-ui/skybase-components/sb-checkbox/constants'
import { SbCheckbox } from 'skybase-ui/skybase-components/sb-checkbox/sb-checkbox'
import { SbLink } from 'skybase-ui/skybase-components/sb-link/sb-link'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { loaderSize } from 'skybase-ui/skybase-components/sb-loader/constants'
import { showErrorToast } from '@/common/services/show-toast'
import { withRouter } from '@/common/router'
import { fleetInit } from '@/fleet-configuration/fleet-init'
import { devicesTableRenderer } from '@/fleet-configuration/page-components/devices-table-renderer/devices-table-renderer'
import {
  loadDevices,
  loadDevicesIfEmpty,
  setDeviceName,
  storeDeviceName,
} from '@/fleet-configuration/data-fleet/devices/devices-actions'
import { loadProjectsIfEmpty } from '@/fleet-configuration/data-fleet/projects/projects-actions'
import { ConfigurationSelector } from '@/fleet-configuration/pages/configuration/configuration-selector'
import { dataTableFocusComponent } from '@/fleet-configuration/utils/data-table-utils'
import { BackupConfigurationModal } from '@/fleet-configuration/components/backup-configuration/backup-configuration-modal'
import { loadProjectDeviceIfEmpty } from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { IdentifyButton } from '@/fleet-configuration/components/identify-button/identify-button'
import { messages as t } from './configuration-i18n'

let requestedDevices = []
export const Configuration = withRouter(props => {
  const { formatMessage: _, formatDate } = useIntl()
  const dispatch = useDispatch()

  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNumber: 1,
  })
  const [backupModalIds, setBackupModalIds] = useState(null)
  const [isTableLoading, setIsTableLoading] = useState(true)

  const setOfflineDeviceUrl = showOfflineDevice => {
    const { router } = props
    router.navigate({ search: `offlineDevice=${showOfflineDevice ? 'yes' : 'no'}` })
  }

  const { devices, areOfflineDevicesShown } = useSelector(state => ConfigurationSelector(state, props))

  useEffect(() => {
    Promise.allSettled([dispatch(fleetInit(_)), dispatch(loadDevicesIfEmpty()), dispatch(loadProjectsIfEmpty())]).then(
      () => {
        setIsTableLoading(false)
      },
    )
    setOfflineDeviceUrl(false)
    // setOfflineDeviceUrl function should not be dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_, dispatch])

  let focusName
  const handleOnNameFocus = (evt, row) => {
    focusName = row.name
    dataTableFocusComponent(evt)
  }

  const handleOnNameChange = (newName, row) => {
    dispatch(setDeviceName(row.id, newName))
  }

  const handleOnNameBlur = async row => {
    if (focusName !== row.name) {
      try {
        await dispatch(storeDeviceName(row.id, row.name))
      } catch (e) {
        showErrorToast(_(t.deviceNameUpdateFailed))
        dispatch(loadDevices())
      }
    }
  }

  const handleOnShowOfflineDevicesToggle = () => {
    setOfflineDeviceUrl(!areOfflineDevicesShown)
  }

  const renderName = (value, row) => {
    const editComponent = (
      <SbTextbox
        className={`editable-component ${row.isDirty ? 'status-dirty' : ''}`}
        value={value}
        disabled={!row.online}
        onFocus={evt => handleOnNameFocus(evt, row)}
        onChange={evt => handleOnNameChange(evt.target.value, row)}
        onBlur={() => handleOnNameBlur(row)}
        onClick={evt => evt.stopPropagation()}
        size={Math.max(1, (value || '').length - 1)}
      />
    )
    if (row.isDirty) {
      return (
        <SbHint position={hintPositions.topRight} hintData={_(t.configurationIsNotWrittenToDevice)}>
          {editComponent}
        </SbHint>
      )
    }
    return editComponent
  }

  const renderLastModified = value => {
    if (value) {
      return formatDate(value, { format: 'date-minutes-12hour-numeric' })
    }
    return ''
  }

  const handleOnBackupIconClick = (evt, deviceId, piid) => {
    setBackupModalIds([deviceId, piid])
  }

  const dismissBackupModal = () => setBackupModalIds(null)

  const renderActions = row => {
    const { id, online, hasLED, canBackup, isProjectDeviceLoaded } = row

    const identifyIcon = hasLED ? <IdentifyButton deviceId={id} online={online} /> : null

    const backupIcon = (
      <SbHint
        hintData={canBackup ? _(t.backupDeviceConfiguration) : _(t.configurationBackupRestoreNotSupported)}
        position={canBackup ? hintPositions.topCenter : hintPositions.middleLeft}
      >
        <span
          className={classNames('sbi-backup-manager-kids icon-link', { 'icon-disabled': !canBackup })}
          onClick={canBackup ? evt => handleOnBackupIconClick(evt, id, row.protocolIndependentId) : null}
        />
      </SbHint>
    )

    let configureLink
    if (!isProjectDeviceLoaded) {
      configureLink = (
        <SbHint hintData={_(t.configureDevice)} position={hintPositions.topCenter}>
          <span className="config-loading">
            <SbLoader show size={loaderSize.XXS} />
          </span>
        </SbHint>
      )
    } else {
      configureLink = (
        <SbLink to={`/configuration/hardware-setup/${id}`}>
          <SbHint hintData={_(t.configureDevice)} position={hintPositions.topCenter}>
            <span className="device-config-link sbi-setting-kids icon-link" />
          </SbHint>
        </SbLink>
      )
    }

    // items are shown from right to left
    const content = (
      <>
        {configureLink}
        {backupIcon}
        {identifyIcon}
      </>
    )

    return (
      <div className="actions-wrapper">
        <div onClick={evt => evt.stopPropagation()} className="show-on-hover">
          {content}
        </div>
      </div>
    )
  }

  const cellFormatter = (value, key, row) => {
    if (key === 'name') {
      return renderName(value, row)
    }
    if (devicesTableRenderer.commonCellFormatterFields.includes(key)) {
      return devicesTableRenderer.cellFormatter(value, key, row)
    }
    if (key === 'lastModifiedTimestamp') {
      return renderLastModified(value)
    }
    if (key === '_actions') {
      return renderActions(row)
    }
    return value
  }

  const rowFormatter = row => {
    if (!requestedDevices.includes(row.id)) {
      const loadDeviceResult = dispatch(loadProjectDeviceIfEmpty(row.id))
      if (loadDeviceResult.then) {
        requestedDevices.push(row.id)
        loadDeviceResult
          .catch(() => ({})) // we are interested only in cleanup, hence empty .catch, so last .then is called even if request fails
          .then(() => {
            requestedDevices = requestedDevices.filter(id => id !== row.id)
          })
      }
    }
    return {}
  }

  devicesTableRenderer.initialize(_)
  const tableColumns = [
    ...devicesTableRenderer.commonTableColumns.filter(column => column.name !== 'daq'),
    { name: 'lastModifiedTimestamp', label: _(t.lastModified) },
    { name: '_actions', label: ' ', sortable: false },
  ]

  return (
    <SbFullLayout
      title={_(t.configuration)}
      className="configuration-page"
      breadcrumbs={[
        {
          path: '/',
          title: _(t.home),
        },
        {
          path: '/configuration/devices',
          title: _(t.configuration),
        },
        _(t.deviceConfiguration),
      ]}
    >
      <div>
        <div className="fl-row fl-justify-sb fl-align-items-center">
          <div className="sb-margin-right-5 fl-container layout-title-with-buttons">
            <h1 className="title-name">{_(t.deviceConfiguration)}</h1>
          </div>
          <SbCheckbox
            id="showOfflineDevices"
            type="switch-control"
            className="m-r-20 test-show-offline-devices"
            onClick={handleOnShowOfflineDevicesToggle}
            checked={!!areOfflineDevicesShown}
            labelPosition={checkboxLabelPosition.LEFT}
            value="1"
          >
            {_(t.showOfflineDevices)}
          </SbCheckbox>
        </div>
        <SbDataTable
          id="fleet-overview-table"
          className="list-table"
          columns={tableColumns}
          data={devices}
          loading={isTableLoading}
          rowsUniqueKeyName="id"
          emptyMessage={_(t.noDevicesFound)}
          cellFormatter={cellFormatter}
          rowFormatter={rowFormatter}
          enablePagination
          asyncData={false}
          paginationProps={{
            pageSizeDropdownProps: {
              className: 'min-width-100px',
            },
            ...pagination,
            onChange: setPagination,
          }}
        />
        {backupModalIds && (
          <BackupConfigurationModal
            deviceId={backupModalIds[0]}
            piid={backupModalIds[1]}
            onDismiss={dismissBackupModal}
          />
        )}
      </div>
    </SbFullLayout>
  )
})
