import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  deviceName: {
    id: 'devices.deviceName',
    defaultMessage: 'Name',
  },
  deviceStatus: {
    id: 'devices.deviceStatus',
    defaultMessage: 'Status',
  },
  deviceSecurity: {
    id: 'devices.deviceSecurity',
    defaultMessage: 'Security',
  },
  errorDuringDevicesFetch: {
    id: 'devices.errorDuringDevicesFetch',
    defaultMessage: 'There was an error while fetching devices.',
  },
  errorDuringDeviceResourceFetch: {
    id: 'devices.errorDuringDeviceResourceFetch',
    defaultMessage: 'There was an error while fetching device resources.',
  },
  errorDuringDeviceDataSourcesFetch: {
    id: 'devices.errorDuringDeviceDataSourcesFetch',
    defaultMessage: 'There was an error while fetching device data sources.',
  },
  errorDuringDeviceDataSourceConfigurationFetch: {
    id: 'devices.errorDuringDeviceDataSourceConfigurationFetch',
    defaultMessage: 'There was an error while fetching device data source configuration.',
  },
  errorDuringDeviceDataSourceTranferConfigurationFetch: {
    id: 'devices.errorDuringDeviceDataSourceTranferConfigurationFetch',
    defaultMessage: 'There was an error while fetching device data source transfer configuration.',
  },
  errorDuringDeviceDataSourceTranferStart: {
    id: 'devices.errorDuringDeviceDataSourceTranferStart',
    defaultMessage: 'There was an error while starting a device data source transfer.',
  },
  errorDuringDeviceDataSourceTranferStop: {
    id: 'devices.errorDuringDeviceDataSourceTranferStop',
    defaultMessage: 'There was an error while stopping a device data source transfer.',
  },
  errorDuringDeviceDataSourceConfigurationUpdate: {
    id: 'devices.errorDuringDeviceDataSourceConfigurationUpdate',
    defaultMessage: 'There was an error while updating device data source configuration.',
  },
  errorDuringDeviceDataSourceUpdate: {
    id: 'devices.errorDuringDeviceDataSourceUpdate',
    defaultMessage: 'There was an error while updating a device data source.',
  },
  dataSourceSuccessfullyUpdated: {
    id: 'devices.dataSourceSuccessfullyUpdated',
    defaultMessage: 'Data Source successfully updated.',
  },
  dataSourceConfigurationSuccessfullyUpdated: {
    id: 'devices.dataSourceConfigurationSuccessfullyUpdated',
    defaultMessage: 'Data Source configuration successfully updated.',
  },
  online: {
    id: 'devices.online',
    defaultMessage: 'Online',
  },
  offline: {
    id: 'devices.offline',
    defaultMessage: 'Offline',
  },
  registered: {
    id: 'devices.registered',
    defaultMessage: 'Registered',
  },
  unregistered: {
    id: 'devices.unregistered',
    defaultMessage: 'Unregistered',
  },
  unknown: {
    id: 'devices.unknown',
    defaultMessage: 'Unknown',
  },
  uninitialized: {
    id: 'devices.uninitialized',
    defaultMessage: 'Uninitialized',
  },
  search: {
    id: 'devices.search',
    defaultMessage: 'Search',
  },
  statusOfTheDeviceHasChangedTo: {
    id: 'devices.statusOfTheDeviceHasChangedTo',
    defaultMessage: 'Device "{deviceName}" went {status}.',
  },
  registerStatusOfTheDeviceHasChangedTo: {
    id: 'devices.registerStatusOfTheDeviceHasChangedTo',
    defaultMessage: 'Device "{deviceName}" was {status}.',
  },
  statusOfTheDeviceTitle: {
    id: 'devices.statusOfTheDeviceTitle',
    defaultMessage: 'Device Status Change',
  },
  notifications: {
    id: 'devices.notifications',
    defaultMessage: 'Notifications',
  },
  selectADevice: {
    id: 'devices.selectADevice',
    defaultMessage: 'Select a device',
  },
  manufacturer: {
    id: 'devices.manufacturer',
    defaultMessage: 'Manufacturer',
  },
  modelNumber: {
    id: 'devices.modelNumber',
    defaultMessage: 'Model number',
  },
  serialNumber: {
    id: 'devices.serialNumber',
    defaultMessage: 'Serial number',
  },
  endpoints: {
    id: 'devices.endpoints',
    defaultMessage: 'Endpoints',
  },
  types: {
    id: 'devices.types',
    defaultMessage: 'Types',
  },
  resources: {
    id: 'devices.resources',
    defaultMessage: 'Resources',
  },
  dataSources: {
    id: 'devices.dataSources',
    defaultMessage: 'Data Sources',
  },
  resourceSuccessfullyUpdated: {
    id: 'devices.resourceSuccessfullyUpdated',
    defaultMessage: 'Resource was successfully updated.',
  },
  youHaveBeenRedirectedToDevicesList: {
    id: 'devices.youHaveBeenRedirectedToDevicesList',
    defaultMessage:
      'Resource was successfully updated. You have been redirected to the devices list, because the device is expected to go offline.',
  },
  dataSourceTransferStarted: {
    id: 'devices.dataSourceTransferStarted',
    defaultMessage: 'Data source transfer started.',
  },
  dataSourceTransferStopped: {
    id: 'devices.dataTransferStopped',
    defaultMessage: 'Data source transfer stopped.',
  },
  createDataCustomSetTitle: {
    id: 'devices.createDataCustomSetTitle',
    defaultMessage: 'Streaming finished',
  },
  createDataCustomSetMessage: {
    id: 'devices.createDataCustomSetMessage',
    defaultMessage: 'Would you like to create a custom data set? You will be redirected to the jBeam Service UI.',
  },
  unsecured: {
    id: 'devices.unsecured',
    defaultMessage: 'Unsecured',
  },
  readyToBeOwned: {
    id: 'devices.readyToBeOwned',
    defaultMessage: 'Ready to be owned',
  },
  owned: {
    id: 'devices.owned',
    defaultMessage: 'Owned',
  },
  ownedByOther: {
    id: 'devices.ownedByOther',
    defaultMessage: 'Owned by other',
  },
  takeOwnership: {
    id: 'devices.takeOwnership',
    defaultMessage: 'Take ownership',
  },
  resetOwnership: {
    id: 'devices.resetOwnership',
    defaultMessage: 'Reset ownership',
  },
  onboard: {
    id: 'devices.onboard',
    defaultMessage: 'Onboard',
  },
  offboard: {
    id: 'devices.offboard',
    defaultMessage: 'Offboard',
  },
  notAvailable: {
    id: 'devices.notAvailable',
    defaultMessage: 'Not available',
  },
  failed: {
    id: 'devices.failed',
    defaultMessage: 'Failed',
  },
  registering: {
    id: 'devices.registering',
    defaultMessage: 'Registering',
  },
  readyToRegister: {
    id: 'devices.readyToRegister',
    defaultMessage: 'Ready to register',
  },
  onboardingStatus: {
    id: 'devices.onboardingStatus',
    defaultMessage: 'Onboarding status',
  },
  ownershipStatus: {
    id: 'devices.ownershipStatus',
    defaultMessage: 'Ownership status',
  },
  securedDevice: {
    id: 'devices.securedDevice',
    defaultMessage: 'Secured Device',
  },
  enabled: {
    id: 'devices.enabled',
    defaultMessage: 'Enabled',
  },
  disabled: {
    id: 'devices.disabled',
    defaultMessage: 'Disabled',
  },
  ownerId: {
    id: 'devices.ownerId',
    defaultMessage: 'Owner ID',
  },
  errorWhileOwningADevice: {
    id: 'devices.errorWhileOwningADevice',
    defaultMessage: 'Error while attempting to own a device.',
  },
  errorWhileDisowningADevice: {
    id: 'devices.errorWhileDisowningADevice',
    defaultMessage: 'Error while attempting to disown a device.',
  },
  errorWhileOnboardingADevice: {
    id: 'devices.errorWhileOnboardingADevice',
    defaultMessage: 'Error while attempting to onboard a device.',
  },
  onboardingFailed: {
    id: 'devices.onboardingFailed',
    defaultMessage: 'Onboarding of the device has failed.',
  },
  onboardingIsStuckTitle: {
    id: 'devices.onboardingIsStuckTitle',
    defaultMessage: 'Onboarding is stuck',
  },
  onboardingIsStuck: {
    id: 'devices.onboardingIsStuck',
    defaultMessage:
      'Onboarding of the device is stuck. Try restarting the device, factory reset or check the logs on the device.',
  },
  deviceWasOwned: {
    id: 'devices.deviceWasOwned',
    defaultMessage: 'You have taken ownership of this device.',
  },
  deviceWasOwnedButFailedToUpdateSWUResource: {
    id: 'devices.deviceWasOwnedButFailedToUpdateSWUResource',
    defaultMessage:
      'You have taken ownership of this device. However, it was not possible to configure the /oc/swu resource!',
  },
  deviceWasOwnedButFailedToGetSWUResource: {
    id: 'devices.deviceWasOwnedButFailedToGetSWUResource',
    defaultMessage:
      'You have taken ownership of this device. However, it was not possible to get information about the /oc/swu resource!',
  },
  deviceWasDisowned: {
    id: 'devices.deviceWasDisowned',
    defaultMessage:
      'Ownership of this device has been reset. The device was removed from your list. If you added this device manually by Add device by IP, you have to add it again, if not, the device will appear automatically.',
  },
  deviceOnboarded: {
    id: 'devices.deviceOnboarded',
    defaultMessage: 'Device has been onboarded.',
  },
  addDeviceByIpAddress: {
    id: 'devices.addDeviceByIpAddress',
    defaultMessage: 'Add device by IP address',
  },
  addDeviceByIp: {
    id: 'devices.addDeviceByIp',
    defaultMessage: 'Add device by IP',
  },
  startDeviceDiscovery: {
    id: 'devices.startDeviceDiscovery',
    defaultMessage: 'Start Device Discovery',
  },
  discoveringDevices: {
    id: 'devices.discoveringDevices',
    defaultMessage: 'Discovering Devices',
  },
  startDeviceDiscoveryHint: {
    id: 'devices.startDeviceDiscoveryHint',
    defaultMessage:
      'When this button is disabled, the device discovery is already running. It will stop after {seconds} seconds.',
  },
  finding: {
    id: 'devices.finding',
    defaultMessage: 'Finding',
  },
  addDevice: {
    id: 'devices.addDevice',
    defaultMessage: 'Add Device',
  },
  ipAddress: {
    id: 'devices.ipAddress',
    defaultMessage: 'IP Address',
  },
  deviceWithIpWasAddedToTheList: {
    id: 'devices.deviceWithIpWasAddedToTheList',
    defaultMessage: 'Device with IP address {ipAddress} was added to the list and was pre opened in the details tab.',
  },
  enterDeviceIpAddress: {
    id: 'devices.enterDeviceIpAddress',
    defaultMessage: 'Enter device IP address',
  },
  deviceHasTheSamePIIDorSerialNumber: {
    id: 'devices.deviceHasTheSamePIIDorSerialNumber',
    defaultMessage:
      '{itemCount, plural, one {Device} other {Devices}} {deviceNames} {itemCount, plural, one {has} other {have}} the same PIID or Serial Number.',
  },
  multipleDevicesHasTheSamePIIDorSerialNumber: {
    id: 'devices.multipleDevicesHasTheSamePIIDorSerialNumber',
    defaultMessage: 'Multiple devices have the same PIID or Serial Number.',
  },
  or: {
    id: 'devices.or',
    defaultMessage: 'or',
  },
  removeDevice: {
    id: 'devices.removeDevice',
    defaultMessage: 'Remove device',
  },
  deleteDeviceDesc: {
    id: 'devices.deleteDeviceDesc',
    defaultMessage: 'Are you sure you want to remove the device "{deviceName}"?',
  },
  deleteDeviceDesc2: {
    id: 'devices.deleteDeviceDesc2',
    defaultMessage: 'The device will disappear and has to be onboarded again.',
  },
  remove: {
    id: 'devices.remove',
    defaultMessage: 'Remove',
  },
  deviceWasSuccessfullyRemoved: {
    id: 'devices.deviceWasSuccessfullyRemoved',
    defaultMessage:
      'The device "{deviceName}" has been removed from the KiCloud. If you want to use this device in KiCloud, you have to onboard it again.',
  },
  failedToRemoveThisDevice: {
    id: 'devices.failedToRemoveThisDevice',
    defaultMessage: 'Failed to remove this device: {errorMessage}',
  },
  removedAnOfflineDeviceFromKiCloud: {
    id: 'devices.removedAnOfflineDeviceFromKiCloud',
    defaultMessage: 'Removes an offline device from the KiCloud',
  },
})
