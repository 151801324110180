import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { identifyDevice } from '@/fleet-configuration/data-fleet/devices/devices-actions'
import { horizontalPosition, verticalPosition } from 'skybase-ui/skybase-components/sb-hint/constants'
import { SbHint } from 'skybase-ui/skybase-components/sb-hint/sb-hint'
import { messages as t } from './identify-button-i18n'
import './identify-button.scss'

export const IdentifyButton = ({ deviceId, online, className = '' }) => {
  const { formatMessage: _ } = useIntl()
  const dispatch = useDispatch()
  const [isBlinking, setIsBlinking] = useState(false)

  const handleOnIdentifyIconClick = async evt => {
    evt.stopPropagation()
    if (!online) {
      return
    }

    setIsBlinking(true)
    await dispatch(identifyDevice(deviceId))
    setTimeout(setIsBlinking, 3000, false)
  }
  return (
    <SbHint
      hintData={online ? _(t.identifyDevice) : _(t.identifyCantBeCalledOnOfflineDevice)}
      position={{ horizontal: horizontalPosition.CENTER, vertical: verticalPosition.TOP }}
    >
      <span
        disabled={!online}
        className={classNames('sbi-bulb-kids identify-button icon-link', className, {
          blinking: isBlinking,
          'icon-disabled': !online,
        })}
        onClick={handleOnIdentifyIconClick}
      />
    </SbHint>
  )
}

IdentifyButton.propTypes = {
  deviceId: PropTypes.string.isRequired,
  online: PropTypes.bool.isRequired,
  className: PropTypes.string,
}
