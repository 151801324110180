import { omit } from 'lodash'
import {
  ADD_PROJECT_DEVICE_DIRTY,
  CLEAR_PROJECT_DEVICE_DIRTY,
  CLEAR_WHOLE_DEVICE_DIRTY_STATE,
} from './project-devices-dirty-actions'

export const projectDevicesDirtyReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case ADD_PROJECT_DEVICE_DIRTY:
      return { ...state, ...action.payload }
    case CLEAR_PROJECT_DEVICE_DIRTY:
      return { ...omit(state, action.payload.id) }
    case CLEAR_WHOLE_DEVICE_DIRTY_STATE:
      return {}
    default:
      return state
  }
}
