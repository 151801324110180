import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import './title-with-tooltip.scss'

const _TitleWithTooltip = ({ titleText, tooltip, intl: { formatMessage: _ } }) => (
  <>
    {_(titleText)}
    <span title={_(tooltip)} className="tooltip-icon sbi-help-circle" />
  </>
)

_TitleWithTooltip.propTypes = {
  intl: intlShape.isRequired,
  titleText: PropTypes.object.isRequired,
  tooltip: PropTypes.object.isRequired,
}

export const TitleWithTooltip = injectIntl(_TitleWithTooltip)
