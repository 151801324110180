import { v4 } from 'uuid'

import { showInfoToast } from 'skybase-ui/skybase-components/sb-toastr/actions'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'

import { store } from '@/stores'
import { replaceDuplicateSlashes, isBrowserTabActive } from '@/utils'
import { logNotification, notificationTypes, NOTIFICATIONS_PAGE_URL } from '@/iot-hub/components/notifications'

import { messages as t } from './resources-i18n'

const handleOnToastClick = (onRemove, deviceId, notificationId) => {
  // Remove/Hide the sw licensing toast
  onRemove()

  // Redirect to the notifications page
  SbEmitter.emit('navigate', NOTIFICATIONS_PAGE_URL, {
    state: { selectedId: notificationId },
  })
}

export const resourceStatusListener = (message, info) => {
  const parsedData = JSON.parse(message.data)
  const { deviceId, href, deviceName } = info
  const title = t.changeOnResourceTitle
  const text = {
    message: t.changeOnResourceOccured,
    params: { href, deviceName },
  }
  const notificationId = v4()

  // Show toast only when browser tab is active
  if (isBrowserTabActive()) {
    // Show toast
    store.dispatch(
      showInfoToast(
        {
          title,
          message: text,
        },
        {
          className: 'sb-cursor-pointer',
          hideTime: 5000,
          closeButton: true,
          onClick: (event, onRemove) => handleOnToastClick(onRemove, deviceId, notificationId),
        },
      ),
    )
  }

  // Log notification
  store.dispatch(
    logNotification({
      id: notificationId,
      type: notificationTypes.INFO,
      title,
      message: text,
      payload: parsedData,
      redirectLocation: replaceDuplicateSlashes(`/iot-hub/devices/${deviceId}/resources/${href}`),
    }),
  )
}
