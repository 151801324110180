import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { aclShape } from 'skybase-oauth/auth'
import { OAuth } from 'skybase-oauth/oauth'
import { SbButton } from 'skybase-ui/skybase-components/sb-button'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'

import { showErrorToast } from '@/common/services/show-toast'
import { HintRenderer } from '@/common/hint-renderer'
import { resourceStructures } from '@/iot-hub/components/devices/constants'
import { getIotHubDeviceDataSourceConfigurationState, getIotHubDeviceDataSourceDetailsState } from '@/iot-hub/selectors'
import { getDeviceApi, getDeviceResourceApi } from '@/iot-hub/rest'
import { startDataTransfer, stopDataTransfer } from '../utils'
import { TransferForm } from './_transfer-form'
import { openDataSourceZMQGraph, setDeviceDataTransferConfiguration } from './actions'
import {
  transferFormObject,
  supportedTransferMethods,
  encodings,
  ACQUISITION_CHANNEL,
  certificateTypes,
} from './constants'
import { mapDataToTransferForm, mapDataFromTransferForm } from './utils'
import { messages as t } from './datasources-i18n'
import { deviceStatuses } from '../constants'
import { dataTransferKafkaConfigurationShape, dataTransferZeroMQConfigurationShape } from '../shapes'

const { OFFLINE, ONLINE } = deviceStatuses
const { ENDPOINTS, TOPIC, ENCODING } = transferFormObject
const { OAUTH_KAFKA, TLS_CUSTOM } = certificateTypes

class _DataSourceTransfer extends PureComponent {
  static propTypes = {
    method: PropTypes.oneOf(Object.values(supportedTransferMethods)).isRequired,
    data: PropTypes.oneOfType([dataTransferKafkaConfigurationShape, dataTransferZeroMQConfigurationShape]),
    loading: PropTypes.bool.isRequired,
    fetching: PropTypes.bool.isRequired,
    deviceId: PropTypes.string.isRequired,
    dataSourceId: PropTypes.string.isRequired,
    deviceStatus: PropTypes.oneOf([OFFLINE, ONLINE]).isRequired,
    handleStartDataTransfer: PropTypes.func.isRequired,
    handleStopDataTransfer: PropTypes.func.isRequired,
    isActiveTranfer: PropTypes.bool.isRequired,
    acl: aclShape.isRequired,
    configuration: PropTypes.object.isRequired, // eslint-disable-line
    supportedEncodings: PropTypes.arrayOf(PropTypes.string),
    streaming: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
    handleOpenDataSoruceZMQGraph: PropTypes.func.isRequired,
    handleSetDeviceDataTransferConfiguration: PropTypes.func.isRequired,
    customCaResource: PropTypes.shape({
      href: PropTypes.string,
    }),
  }

  static defaultProps = {
    data: {},
    supportedEncodings: [],
    customCaResource: null,
  }

  constructor(props) {
    super(props)

    this.state = {
      shareWithTenant: false,
      openingGraph: false,
      certificateType: OAUTH_KAFKA,
      caCertificate: '',
      fetchingCaCert: false,
    }
  }

  async componentDidMount() {
    const { customCaResource } = this.props
    try {
      this.setState({ fetchingCaCert: true })
      const { caCertificate } = await getDeviceResourceApi({ resourcePath: customCaResource?.href })
      const certificateType = TLS_CUSTOM
      this.setState({ certificateType, caCertificate, fetchingCaCert: false })
      SbEmitter.emit('update-kafka-validation-rules', certificateType)
    } catch (e) {
      // handle
      this.setState({ fetchingCaCert: false })
    }
  }

  handleUpdate = data => {
    const { shareWithTenant, certificateType } = this.state
    const {
      dataSourceId,
      deviceId,
      method,
      handleStartDataTransfer,
      handleStopDataTransfer,
      isActiveTranfer,
      configuration,
      handleSetDeviceDataTransferConfiguration,
    } = this.props

    if (isActiveTranfer) {
      const jBeamData = {
        [ENCODING]: data[ENCODING],
        frameScanCount: configuration?.frameScanCount,
        samplingRate: configuration?.samplingRate,
        [TOPIC]: data[TOPIC],
        [ENDPOINTS]: data[ENDPOINTS],
      }
      handleStopDataTransfer(deviceId, dataSourceId, jBeamData)
    } else {
      handleSetDeviceDataTransferConfiguration(data)
      handleStartDataTransfer(deviceId, dataSourceId, method, {
        ...mapDataFromTransferForm(data, method),
        shareWithTenant,
        caCertificate: data?.caCertificate,
        certificateType,
      })
    }
  }

  renderLoader = () => {
    return (
      <div className="fl-row fl-justify-center">
        <SbLoader show />
      </div>
    )
  }

  handleShowGraph = async () => {
    const { handleOpenDataSoruceZMQGraph, deviceId, dataSourceId, data, configuration } = this.props

    this.setState({ openingGraph: true })

    try {
      // Fetch the device with ARRAY structure to get its resources
      const device = await getDeviceApi(deviceId, resourceStructures.ARRAY)

      // Filter out all the resources except for the ACQUISITION_CHANNEL
      // Map the resources, only leave the href as an array of hrefs
      const channelsHrefs =
        device?.resources
          ?.filter?.(resource => resource.types.includes(ACQUISITION_CHANNEL))
          ?.map?.(resource => resource.href) || []

      // Fetch all resources in the array and return the required attributes for channels
      const channelResources = await Promise.all(
        channelsHrefs.map(async href => {
          const resource = await getDeviceResourceApi({ resourcePath: href })

          return {
            dataSourceId: resource.dataSourceId,
            enabled: resource.enabled,
            dataOffset: resource.dataOffset,
            dataType: resource.dataType,
          }
        }),
      )

      // Create an array structure out of the resource array which is used in the ZMQ subscribtion
      const channels = channelResources
        .filter(resource => resource.dataSourceId === dataSourceId && resource.enabled && resource.dataOffset >= 0)
        .map(resource => ({
          offset: resource.dataOffset,
          type: resource.dataType,
        }))

      handleOpenDataSoruceZMQGraph({
        topic: data[TOPIC] || data.topicName,
        channels,
        samplingRate: configuration?.samplingRate,
        epoch: configuration?.epoch,
      })

      this.setState({ openingGraph: false })
    } catch (e) {
      showErrorToast(t.couldNotOpenGraphErrorMessage, t.graphError)
      this.setState({ openingGraph: false })
    }
  }

  render() {
    const { openingGraph, certificateType, caCertificate, fetchingCaCert } = this.state
    const {
      fetching,
      loading,
      method,
      data,
      deviceStatus,
      supportedEncodings,
      isActiveTranfer,
      streaming,
      acl,
      intl: { formatMessage: _ },
      customCaResource,
    } = this.props

    if (isEmpty(data) || fetching || fetchingCaCert) {
      return this.renderLoader()
    }

    const mappedData = { ...mapDataToTransferForm(data, method), caCertificate }
    const deviceOffline = deviceStatus === OFFLINE
    const disabled = isActiveTranfer || loading || deviceOffline || streaming
    const kislerStreamSelected =
      supportedEncodings.includes(encodings.KISTLER_STREAM_V2) &&
      [...(supportedEncodings.length === 1 ? [''] : []), encodings.KISTLER_STREAM_V2].includes(mappedData.encoding)
    const graphButtonDisabled = !streaming || !kislerStreamSelected || deviceOffline || loading || openingGraph

    return (
      <>
        {!!customCaResource && method === supportedTransferMethods.STREAM && (
          <div className="fl-container horizontal-radio-buttons m-b-10">TLS (Custom)</div>
        )}

        <TransferForm
          method={method}
          data={mappedData}
          loading={loading}
          disabled={disabled}
          deviceOffline={deviceOffline}
          supportedEncodings={supportedEncodings}
          isActiveTranfer={isActiveTranfer}
          handleUpdate={this.handleUpdate}
          acl={acl}
          certificate={{
            customCaResource,
            certificateType,
          }}
        />
        {method === supportedTransferMethods.ZMQPUBCLIENT && OAuth.isInLocalSDKMode && (
          <HintRenderer
            showHint={!kislerStreamSelected}
            hintData={_(t.kislerStreamNotSelectedSelected)}
            className="sb-width-100pct"
          >
            <SbButton
              id="datastream-show-graph-button"
              className="sb-width-100pct show-graph-button"
              onClick={this.handleShowGraph}
              disabled={graphButtonDisabled}
              icon="sbi-graph-line"
              loading={openingGraph}
            >
              {_(t.showGraph)}
            </SbButton>
          </HintRenderer>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  const { data: configuration } = getIotHubDeviceDataSourceConfigurationState(state)
  const {
    data: { supportedEncodings = [] },
  } = getIotHubDeviceDataSourceDetailsState(state)

  return {
    configuration,
    supportedEncodings,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    intl: { formatMessage: _ },
  } = ownProps

  return {
    handleStartDataTransfer: (deviceId, dataSourceId, transferMethod, data) =>
      dispatch(startDataTransfer(deviceId, dataSourceId, transferMethod, data)),
    handleStopDataTransfer: (deviceId, dataSourceId, jBeamData) =>
      dispatch(stopDataTransfer(deviceId, dataSourceId, jBeamData, _)),
    handleOpenDataSoruceZMQGraph: data => dispatch(openDataSourceZMQGraph(data)),
    handleSetDeviceDataTransferConfiguration: data => dispatch(setDeviceDataTransferConfiguration(data)),
  }
}

export const DataSourceTransfer = injectIntl(connect(mapStateToProps, mapDispatchToProps)(_DataSourceTransfer))
