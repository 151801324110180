import { batchActions } from 'redux-batched-actions'
import { debounce } from 'lodash'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter'
import { updateDeviceById } from '@/fleet-configuration/data-fleet/devices/devices-actions'
import { updateProjectDeviceById } from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { getDeviceById } from '@/fleet-configuration/data-fleet/devices/devices-selectors'
import { DEVICE_STATUS_LISTENER_NAME } from '@/iot-hub/components'
import {
  deviceEventStatusList,
  deviceEventStatusToOnlineMap,
  deviceIgnoredUpdateStatuses,
  SET_DEVICE_ONLINE_EVENT,
} from './device-online-status-constants'
import {
  getUnresolvedDeviceOnlineStatusMessages,
  notifyProjectDeviceOnlineStatusChange,
  notifyProjectDeviceOnlineStatusChangeShowMessage,
  resolveDeviceOnlineStatusMessage,
  setDeviceOnlineStatus,
} from './device-online-status-actions'
import { getDeviceOnlineStatus } from './device-online-status-selector'
import { DEVICES_LOADED_EVENT } from '@/fleet-configuration/data-fleet/devices/devices-constants'

let storedEvents = {}
const storeHandler = message => {
  storedEvents[message.deviceId] = message
}
const executeStoredEvents = () => {
  SbEmitter.off(DEVICE_STATUS_LISTENER_NAME, storeHandler)
  Object.values(storedEvents).forEach(event => SbEmitter.emit(DEVICE_STATUS_LISTENER_NAME, event))
  storedEvents = {}
}
export const storeDeviceOnlineStatusEventsUntilInit = () => {
  SbEmitter.on(DEVICE_STATUS_LISTENER_NAME, storeHandler)
}

export const initDeviceOnlineStatusListener = (eventListener, dispatch, getState) => {
  let statusUpdateBatch = []
  const scheduleExecDebouncedEvents = debounce(() => {
    dispatch(batchActions(statusUpdateBatch.map(({ deviceId, status }) => setDeviceOnlineStatus(deviceId, status))))
    const state = getState()
    statusUpdateBatch.forEach(({ deviceId }) => {
      const device = getDeviceById(state, deviceId)
      const status = getDeviceOnlineStatus(state, deviceId)
      SbEmitter.emit(SET_DEVICE_ONLINE_EVENT, { ...device, status })
      if (device?.status !== status) {
        dispatch(
          updateDeviceById(deviceId, {
            online: { $set: deviceEventStatusToOnlineMap[status] },
            status: { $set: status },
          }),
        )
      }
      dispatch(
        updateProjectDeviceById(deviceId, {
          online: { $set: deviceEventStatusToOnlineMap[status] },
          status: { $set: status },
        }),
      )
    })

    statusUpdateBatch = []
  }, 150)

  SbEmitter.on(DEVICE_STATUS_LISTENER_NAME, message => {
    const { deviceId, status } = message

    if (deviceIgnoredUpdateStatuses.includes(status) || getDeviceOnlineStatus(getState(), deviceId) === status) {
      return
    }

    // unregister means we no longer know actual online status of device
    //    offboarded devices don't get updates
    if (status === 'unregistered') {
      statusUpdateBatch.push({ deviceId, status: deviceEventStatusList.UNKNOWN })
      scheduleExecDebouncedEvents()
      return
    }

    dispatch(notifyProjectDeviceOnlineStatusChange(message))

    // batch update device online status
    statusUpdateBatch.push({ deviceId, status })
    scheduleExecDebouncedEvents()
  })

  SbEmitter.on(DEVICES_LOADED_EVENT, ({ devices = [] }) => {
    const unresolvedMessages = getUnresolvedDeviceOnlineStatusMessages()
    Object.values(unresolvedMessages).forEach(message => {
      const { deviceId, status } = message
      const missingDevice = devices.find(({ id }) => id === deviceId)
      if (missingDevice) {
        resolveDeviceOnlineStatusMessage(deviceId)
        notifyProjectDeviceOnlineStatusChangeShowMessage(missingDevice, status, dispatch)
      }
    })
  })

  executeStoredEvents()
}
