import { CLEAR_DEVICE_SYNC_STATUSES, DEVICE_SYNC_STATUS_UPDATE } from './device-sync-actions'

export const deviceSyncStatusReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case CLEAR_DEVICE_SYNC_STATUSES: {
      return {}
    }
    case DEVICE_SYNC_STATUS_UPDATE: {
      const { deviceId } = action.payload
      const payload = Object.values(action.payload).length === 1 ? undefined : action.payload
      return {
        ...state,
        [deviceId]: payload,
      }
    }
    default:
      return state
  }
}
