import { batchActions } from 'redux-batched-actions'

import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { openModal } from 'skybase-ui/skybase-core/base/actions'

import { MODULE_NAME } from '@/constants'
import { DEVICES_DATA_SOURCE_STATE_KEYS, DATASOURCE_GRAPH_MODAL_KEY } from './constants'

const { DATA_SOURCES_LIST, DATA_SOURCE_DETAILS, DATA_SOURCE_CONFIGURATION, DATA_TRANSFER } =
  DEVICES_DATA_SOURCE_STATE_KEYS

const getActionNameFactory = constant => getActionName(constant, MODULE_NAME)

// Device Data Sources

export const SET_DEVICE_DATA_SOURCES = getActionNameFactory('SET_DEVICE_DATA_SOURCES')
export const setDeviceDataSources = dataSources => createAction(SET_DEVICE_DATA_SOURCES, { dataSources })

export const LOAD_DEVICE_DATA_SOURCE_TO_UPDATE = getActionNameFactory('LOAD_DEVICE_DATA_SOURCE_TO_UPDATE')
export const loadDeviceDataSourceToUpdate = dataSource =>
  createAction(LOAD_DEVICE_DATA_SOURCE_TO_UPDATE, { dataSource })

export const LOAD_DEVICE_DATA_SOURCE_CONFIGURATION_TO_UPDATE = getActionNameFactory(
  'LOAD_DEVICE_DATA_SOURCE_CONFIGURATION_TO_UPDATE',
)
export const loadDeviceDataSourceConfigurationToUpdate = dataSourceConfiguration =>
  createAction(LOAD_DEVICE_DATA_SOURCE_CONFIGURATION_TO_UPDATE, { dataSourceConfiguration })

export const CHANGE_DEVICE_DATA_SOURCE_STATE = getActionNameFactory('CHANGE_DEVICE_DATA_SOURCE_STATE')
export const changeDataSourceState = (key, state) => createAction(CHANGE_DEVICE_DATA_SOURCE_STATE, { key, state })

export const CLEAR_DEVICE_DATA_SOURCE_PARAMS = getActionNameFactory('CLEAR_DEVICE_DATA_SOURCE_PARAMS')
export const clearDeviceDataSourceParams = key => createAction(CLEAR_DEVICE_DATA_SOURCE_PARAMS, { key })

export const CHANGE_UPDATE_DEVICE_DATA_SOURCE_STATE = getActionNameFactory('CHANGE_UPDATE_DEVICE_DATA_SOURCE_STATE')
export const changeUpdateDeviceDataSourceState = state =>
  createAction(CHANGE_UPDATE_DEVICE_DATA_SOURCE_STATE, { state })

export const CHANGE_RETRIEVE_DEVICE_DATA_SOURCE_CONFIGURATION_STATE = getActionNameFactory(
  'CHANGE_RETRIEVE_DEVICE_DATA_SOURCE_CONFIGURATION_STATE',
)
export const changeRetrieveDeviceDataSourceConfigurationState = state =>
  createAction(CHANGE_RETRIEVE_DEVICE_DATA_SOURCE_CONFIGURATION_STATE, { state })

export const CHANGE_UPDATE_DEVICE_DATA_SOURCE_CONFIGURATION_STATE = getActionNameFactory(
  'CHANGE_UPDATE_DEVICE_DATA_SOURCE_CONFIGURATION_STATE',
)
export const changeUpdateDeviceDataSourceConfigurationState = state =>
  createAction(CHANGE_UPDATE_DEVICE_DATA_SOURCE_CONFIGURATION_STATE, { state })

export const SET_DEVICE_DATA_TRANSFER_CONFIGURATION = getActionNameFactory('SET_DEVICE_DATA_TRANSFER_CONFIGURATION')
export const setDeviceDataTransferConfiguration = dataTransfer =>
  createAction(SET_DEVICE_DATA_TRANSFER_CONFIGURATION, { dataTransfer })

export const SET_DEVICE_DATA_TRANSFER_ACTIVE_TRANSFER_METHOD = getActionNameFactory(
  'SET_DEVICE_DATA_TRANSFER_ACTIVE_TRANSFER_METHOD',
)
export const setDeviceDataTransferActiveTransferMethod = (method, state) =>
  createAction(SET_DEVICE_DATA_TRANSFER_ACTIVE_TRANSFER_METHOD, { method, state })

export const CHANGE_DEVICE_DATA_SOURCE_TRANSFER_STATE = getActionNameFactory('CHANGE_DEVICE_DATA_SOURCE_TRANSFER_STATE')
export const changeDataDataSourceTransferState = state =>
  createAction(CHANGE_DEVICE_DATA_SOURCE_TRANSFER_STATE, { state })

export const SET_DEVICE_DATA_SOURCE_CONFIGURATION_ERRORS = getActionNameFactory(
  'SET_DEVICE_DATA_SOURCE_CONFIGURATION_ERRORS',
)
export const setDeviceDataSourceConfigurationErrors = errors =>
  createAction(SET_DEVICE_DATA_SOURCE_CONFIGURATION_ERRORS, { errors })

export const ADD_RUNNING_MEASUREMENT = getActionNameFactory('ADD_RUNNING_MEASUREMENT')
export const addRunningMeasurement = data => createAction(ADD_RUNNING_MEASUREMENT, { data })

export const REMOVE_RUNNING_MEASUREMENT = getActionNameFactory('REMOVE_RUNNING_MEASUREMENT')
export const removeRunningMeasurement = dataSourceId => createAction(REMOVE_RUNNING_MEASUREMENT, { dataSourceId })

// Factory actions

export const changeListDeviceDataSourcesState = state => changeDataSourceState(DATA_SOURCES_LIST, state)

export const changeDeviceDataSourcesState = state => changeDataSourceState(DATA_SOURCE_DETAILS, state)

export const changeDeviceDataSourceConfigurationState = state => changeDataSourceState(DATA_SOURCE_CONFIGURATION, state)

export const changeDeviceDataSourceTransferConfigurationState = state => changeDataSourceState(DATA_TRANSFER, state)

export const clearDeviceDataSourceDetailsParams = () => clearDeviceDataSourceParams(DATA_SOURCE_DETAILS)

export const clearDeviceDataSourceConfigurationParams = () => clearDeviceDataSourceParams(DATA_SOURCE_CONFIGURATION)

export const clearDeviceDataSourceTransferConfigurationParams = () => clearDeviceDataSourceParams(DATA_TRANSFER)

export const clearAllDeviceDataSourceForms = () => dispatch => {
  dispatch(
    batchActions([
      clearDeviceDataSourceDetailsParams(),
      clearDeviceDataSourceConfigurationParams(),
      clearDeviceDataSourceTransferConfigurationParams(),
    ]),
  )
}

export const openDataSourceZMQGraph = data => openModal(DATASOURCE_GRAPH_MODAL_KEY, data)
