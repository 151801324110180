// prevent false dirty and cache misses.
// We need stable order of types (but don't care about what their order is)
export const fixateAllTypesInDevices = devices => {
  if (!devices) {
    return devices
  }
  devices.forEach(device => {
    if (!device) {
      return
    }
    if (device.types) {
      // eslint-disable-next-line no-param-reassign
      device.types = device.types.sort()
    }
    if (!device.modules) {
      return
    }
    device.modules.forEach(module => {
      if (!module.types) {
        return
      }
      // eslint-disable-next-line no-param-reassign
      module.types = module.types.sort()
    })
  })
  return devices
}
