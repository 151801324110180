/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  save: {
    id: 'addComponentModalMissingData.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'addComponentModalMissingData.cancel',
    defaultMessage: 'Cancel',
  },
  pleaseConfirmTheTypeNumberOfYourSensor: {
    id: 'addComponentModalMissingData.pleaseConfirmTheTypeNumberOfYourSensor',
    defaultMessage: 'Please confirm the type number of your sensor',
  },
  pleaseConfirmTheSerialNumberOfYourSensor: {
    id: 'addComponentModalMissingData.pleaseConfirmTheSerialNumberOfYourSensor',
    defaultMessage: 'Please confirm the serial number of your sensor',
  },
  pleaseConfirmTheTypeNumberOfYourCable: {
    id: 'addComponentModalMissingData.pleaseConfirmTheTypeNumberOfYourCable',
    defaultMessage: 'Please confirm the type number of your cable',
  },
  addMissingData: {
    id: 'addComponentModalMissingData.addMissingData',
    defaultMessage: 'Add missing data',
  },
  typeNumber: {
    id: 'addComponentModalMissingData.typeNumber',
    defaultMessage: 'Type number',
  },
  serialNumber: {
    id: 'addComponentModalMissingData.serialNumber',
    defaultMessage: 'Serial number',
  },
})
