/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  channelNumber: {
    id: 'measurementUnitDetailChannelSmartValues.channelNumber',
    defaultMessage: 'Channel {number}',
  },
  on: {
    id: 'On',
    defaultMessage: 'On',
  },
  off: {
    id: 'Off',
    defaultMessage: 'Off',
  },
  active: {
    id: 'measurementUnitDetailChannelSmartValues.active',
    defaultMessage: 'Active',
  },
  toEnableThisFeaturePleaseAddASensorToTheChannel: {
    id: 'measurementUnitDetailChannelSmartValues.toEnableThisFeaturePleaseAddASensorToTheChannel',
    defaultMessage: 'To enable this feature, {br}please add a sensor to the channel.',
  },
  highestFrequencyOfInterest: {
    id: 'measurementUnitDetailChannelSmartValues.highestFrequencyOfInterest',
    defaultMessage: 'Highest frequency of interest',
  },
  theSystemWasNotAbleToGetAllDataForTheSpecifiedSensor: {
    id: 'measurementUnitDetailChannelSmartValues.theSystemWasNotAbleToGetAllDataForTheSpecifiedSensor',
    defaultMessage: 'The system was not able to get all data for the specified sensor',
  },
  maximalUnit: {
    id: 'measurementUnitDetailChannelSmartValues.maximalUnit',
    defaultMessage: 'Maximal {unit}',
  },
  hz: {
    id: 'measurementUnitDetailChannelSmartValues.hz',
    defaultMessage: 'Hz',
  },
  inactive: {
    id: 'measurementUnitDetailChannelSmartValues.inactive',
    defaultMessage: 'Inactive',
  },
  moduleModule: {
    id: 'measurementUnitDetailChannelSmartValues.moduleModule',
    defaultMessage: 'Module {module}',
  },
})
