import React from 'react'
import { useSelector } from 'react-redux'
import { OAuth } from 'skybase-oauth/oauth'
import { getOAuthState } from 'skybase-oauth/utils'
import { AUTH_STATE_KEY_NAME } from 'skybase-oauth/oauth/constants'

import { DEVELOPER_TOOLS_LS_KEY } from '@/containers/home-page/components/constants'
import { useLocalStorageObserve } from '@/hooks/use-local-storage-observe'
import { MAX_BADGE } from './menu-constants'

// If null, undefined or 0, return null since we dont want to show 0 as a badge
export const getBadgeNumber = (badgeKey, notificationCounts) => {
  if (!badgeKey) {
    return null
  }

  const badgeNumber = notificationCounts[badgeKey]

  if (badgeNumber > 0) {
    if (badgeNumber > MAX_BADGE) {
      return `${MAX_BADGE}+`
    }
    return badgeNumber
  }

  return null
}

// Remove all menu items except for the iot-hub section
export const filterByAppMode = item => OAuth.isInNormalMode || item.url === 'iot-hub'

export const withDevToolsEnabled = Component => {
  function ComponentWithLocalStorageKeyObservervation(props) {
    const { id: userId } = useSelector(state => getOAuthState(state)[AUTH_STATE_KEY_NAME])
    const devToolsList = useLocalStorageObserve(DEVELOPER_TOOLS_LS_KEY)
    const devToolsEnabled = devToolsList?.[userId] || false
    return <Component {...props} devToolsEnabled={OAuth.isInNormalMode ? devToolsEnabled : true} />
  }

  return ComponentWithLocalStorageKeyObservervation
}
