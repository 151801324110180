/* eslint-disable no-unused-expressions */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import JEdit from 'jsoneditor'
import classNames from 'classnames'
import 'jsoneditor/dist/jsoneditor.css'

import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'
import { editorModes } from './constants'

const { CODE, VIEW } = editorModes

export class JSONEditor extends PureComponent {
  static propTypes = {
    json: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]).isRequired,
    schema: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    onChange: PropTypes.func,
    onError: PropTypes.func,
    editorRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
    className: PropTypes.string,
    autofocus: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    style: PropTypes.object, // eslint-disable-line
    onResize: PropTypes.func,
    mode: PropTypes.oneOf([CODE, VIEW]),
  }

  static defaultProps = {
    onChange: null,
    onError: null,
    editorRef: null,
    className: null,
    autofocus: false,
    schema: {},
    width: '100%',
    height: '300px',
    style: {},
    onResize: () => {},
    mode: CODE,
  }

  componentDidMount() {
    // Delay the editor initialization for better reaction time from the application
    setTimeout(() => {
      if (this.container) {
        const { json, schema, autofocus, mode } = this.props
        const options = {
          mode,
          mainMenuBar: false,
          onChangeText: this.onChangeText,
          onValidationError: this.onValidationError,
          schema,
        }

        this.jsoneditor = new JEdit(this.container, options)
        if (typeof json === 'object') {
          this.jsoneditor.set(json)
        } else if (typeof json === 'string') {
          this.jsoneditor.setText(json)
        }

        if (autofocus) {
          this.jsoneditor.focus()
        }

        if (typeof ResizeObserver === 'function' && this.container) {
          this.resizeObserver = new ResizeObserver(this.handleResize)
          this.resizeObserver.observe(this.container)
        }
      }
    }, 1)

    SbEmitter.on('updateJsonEditor', this.handleUpdateJsonEditor)
  }

  componentWillUnmount() {
    if (this.jsoneditor) {
      this.jsoneditor.destroy()
    }

    if (typeof ResizeObserver === 'function' && this.container) {
      this.resizeObserver?.unobserve?.(this.container)
    }

    SbEmitter.off('updateJsonEditor', this.handleUpdateJsonEditor)
  }

  // Method used only from the guided tour
  handleUpdateJsonEditor = paramName => {
    const { json } = this.props
    const paramValue = json?.[paramName]

    const newJson = { [paramName]: !paramValue }

    this.jsoneditor.set(newJson)
    this.onChangeText(JSON.stringify(newJson))
  }

  handleResize = entries => {
    const { onResize } = this.props
    const { width, height } = entries?.[0]?.contentRect || {}

    onResize(width, height, () => {
      this?.jsoneditor?.aceEditor?.resize?.()
    })
  }

  onValidationError = error => {
    const { onError } = this.props

    if (onError) {
      onError(error)
    }
  }

  onChangeText = json => {
    const { onChange } = this.props

    if (onChange) {
      onChange(json)
    }
  }

  handleRef = node => {
    const { editorRef } = this.props

    if (editorRef) {
      editorRef(node)
    }

    this.container = node
  }

  render() {
    const {
      autofocus,
      className,
      editorRef,
      onChange,
      onError,
      json,
      schema,
      height,
      width,
      style,
      onResize,
      ...rest
    } = this.props

    return (
      <div
        {...rest}
        className={classNames(className, 'jsoneditor-react-container notranslate', 'json-editor-default-height', {
          resize: typeof ResizeObserver === 'function',
        })}
        ref={this.handleRef}
        style={{ ...style, width, height }}
      />
    )
  }
}

export * from './constants'
