import { map, escapeRegExp } from 'lodash'
import { getNextCharFactory } from '@/utils/get-next-char'
import { getDeviceOnlineStatus } from '@/fleet-configuration/data-fleet/device-online-status/device-online-status-selector'
import {
  deviceEventStatusList,
  deviceEventStatusToOnlineMap,
} from '@/fleet-configuration/data-fleet/device-online-status/device-online-status-constants'
import { messages as t } from './project-devices-selectors-i18n'

export const isProjectDeviceLoaded = (state, deviceId) => !!state.entries.projectDevices.loadedDeviceIds[deviceId]

export const getProjectDevices = state => state.entries.projectDevices.devices

export const getProjectDeviceById = (state, deviceId) => getProjectDevices(state).find(device => device.id === deviceId)

export const getProjectDeviceSamplingRateOptions = device => {
  const nextCharInstance = getNextCharFactory('A')
  return map((device && device.samplingRates) || {}, ({ dataSourceId, sampleRate }) => ({
    value: dataSourceId,
    rawDescription: t.samplingRate,
    rawDescriptionValues: [nextCharInstance(), sampleRate],
  }))
}
// recursively search all children for one with matching resourcePath
export const getProjectDeviceChildsByResourcePath = (device, resourcePath) => {
  if (device.resourcePath === resourcePath) {
    return [device]
  }

  // look for resource path in these properties representing children
  const searchChildren = ['controller', 'modules', 'channels']
  // this accumulates found result -> Array.some returns
  // bool and not found item hence the need for this
  let foundChild
  searchChildren.some(childName => {
    if (device[childName]) {
      const arrChild = device[childName] instanceof Array ? device[childName] : [device[childName]]
      // first match stops all subsequent searching -> foundChild
      // gets first hit and is not overridden by no-match
      return arrChild.some(childDef => {
        foundChild = getProjectDeviceChildsByResourcePath(childDef, resourcePath)
        // add parent containing this child to create full chain of items
        if (foundChild) {
          foundChild.push(device)
        }
        return foundChild // if found, then stop looking. Otherwise foundChild is falsy
      })
    }
    return false
  })

  return foundChild
}

export const getProjectPartsByProcessingElement = (state, processingElement) => {
  if (
    !processingElement.parameters ||
    !processingElement.parameters.deviceId ||
    !processingElement.parameters.resourcePath
  ) {
    return null
  }
  const device = getProjectDeviceById(state, processingElement.parameters.deviceId)
  if (!device) {
    return null
  }
  return getProjectDeviceChildsByResourcePath(device, processingElement.parameters.resourcePath)
}

export const getProjectDeviceModuleByComponentId = (state, deviceId, componentId) => {
  const device = getProjectDeviceById(state, deviceId)
  if (!device) {
    return null
  }
  const { controller } = device.deviceSpecific || {}
  if (controller && controller.componentId === componentId) {
    return controller.controller
  }
  return device.modules.find(module => module.componentId === componentId)
}

export const isDeviceOnlineByOnlineStatuses = (deviceOnlineStatuses, deviceId) =>
  deviceEventStatusToOnlineMap[deviceOnlineStatuses[deviceId] || deviceEventStatusList.UNKNOWN]

export const areAllProjectDevicesOnline = state =>
  getProjectDevices(state).every(device => deviceEventStatusToOnlineMap[getDeviceOnlineStatus(state, device.id)])

export const isAnyProjectDeviceOffline = state =>
  getProjectDevices(state).some(device => getDeviceOnlineStatus(state, device.id) === deviceEventStatusList.OFFLINE)

export const isAnyProjectDeviceStatusUnknown = state =>
  getProjectDevices(state).some(device => getDeviceOnlineStatus(state, device.id) === deviceEventStatusList.UNKNOWN)

const isResourcePathModule = (resourcePath, module) => {
  const moduleResourcePath = module.resourcePath.replace(/\/$/, '')
  // this makes sure that resourcePath ends after moduleResourcePath, or it continues with "/"
  // this way we can be sure that module 1 will not match module 10...
  const endOfModuleResourcePathRegexp = '(?=$|/)'
  return new RegExp(`^${escapeRegExp(moduleResourcePath)}${endOfModuleResourcePathRegexp}`).test(resourcePath)
}

export const getProjectDeviceModuleByResourcePath = (state, deviceId, resourcePath) => {
  const device = getProjectDeviceById(state, deviceId)
  if (!device) {
    return null
  }

  const { controller } = device.deviceSpecific || {}
  if (controller && isResourcePathModule(resourcePath, controller)) {
    return controller
  }
  return device.modules.find(module => isResourcePathModule(resourcePath, module))
}

export const getProjectDeviceChannelByResourcePath = (state, deviceId, resourcePath) => {
  const device = getProjectDeviceById(state, deviceId)

  if (!device) {
    return null
  }

  const { controller } = device.deviceSpecific || {}
  const resourceModule =
    controller && isResourcePathModule(resourcePath, controller)
      ? controller
      : device.modules.find(module => isResourcePathModule(resourcePath, module))
  return resourceModule ? resourceModule.getChannelByResourcePath(resourcePath) : null
}

export const getProjectDeviceModuleTypes = (state, deviceId) => {
  const device = getProjectDeviceById(state, deviceId) || {}
  return (device.modules || []).map(module => module.getType())
}

export const getProjectDeviceModuleById = (state, deviceId, moduleId) => {
  const device = getProjectDeviceById(state, deviceId)
  return device && device.getModuleById(moduleId)
}

export const getDeviceChannelByIndex = (state, deviceId, moduleId, channelIndex) => {
  const module = getProjectDeviceModuleById(state, deviceId, moduleId)
  return module && module.channels[channelIndex]
}

const getPhysicalChannelSamplingRate = (state, channel, device = undefined) => {
  const deviceId = channel.getDeviceId()
  const resolvedDevice = device || getProjectDeviceById(state, deviceId)
  const module = resolvedDevice && resolvedDevice.getModuleByResourcePath(channel.resourcePath)
  if (module) {
    return (resolvedDevice.samplingRates || []).find(({ dataSourceId }) => dataSourceId === module.samplingRate)
  }
  return null
}

const getVirtualChannelSamplingRate = (state, virtualChannel) => {
  const samplingRates = virtualChannel.inputSignals.map(({ deviceId, resourcePath }) => {
    const inputChannel = getProjectDeviceChannelByResourcePath(state, deviceId, resourcePath)
    // presuming there's no recursion since the virtual channels cannot be nested
    return inputChannel ? getPhysicalChannelSamplingRate(state, inputChannel) : {}
  })

  return samplingRates.length && samplingRates.every(({ sampleRate }) => sampleRate === samplingRates[0].sampleRate)
    ? samplingRates[0]
    : undefined
}

export const getChannelSamplingRate = (state, channel, device = undefined) =>
  channel.isVirtual === true
    ? getVirtualChannelSamplingRate(state, channel)
    : getPhysicalChannelSamplingRate(state, channel, device)

export const getIsDeviceReloading = state => state.entries.projectDevices.reloadingProjectDevice
