import React from 'react'
import PropTypes from 'prop-types'
import { WizardMenu } from '@/fleet-configuration/page-components/wizard/wizard-menu/wizard-menu'
import './measurement-screen-container.scss'

// TODO: clean up this component, remove measurement-screen-container-header
export const MeasurementScreenContainer = ({ children = null }) => (
  <div className="fl-container-row fl-grow-1 menu-content-wrapper">
    <WizardMenu baseUrl="/configuration/settings/" showChainComponents={false} />
    <div className="configuration-detail-content fl-grow-1">{children}</div>
  </div>
)

MeasurementScreenContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}
