export const entriesLoadedFactory =
  (areEntriesLoadedAction, unloadEntriesAction) =>
  (state = null, action = {}) => {
    switch (action.type) {
      case areEntriesLoadedAction:
        return true
      case unloadEntriesAction:
        return false
      default:
        return state
    }
  }
