export const NOTIFICATIONS_STATE_KEY_NAME = 'notifications'

export const notificationTypes = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  INFO: 'info',
}

export const NOTIFICATIONS_PAGE_URL = '/iot-hub/notifications'
