import {
  saveProjectDevice,
  setChannelAlerts,
  setProjectDeviceChannel,
  updateProjectDeviceById,
} from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { getProjectDeviceById } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import { getSensorSensitivityByPhysicalRangeTo } from '@/fleet-configuration/data-fleet/chain-certificate/chain-certificate-selector'

export const updateCutoffFrequencyDependencyRecalculations =
  (existingChain, cutOffFrequency, deviceId, enrichedDeviceModulesChain) => (dispatch, getState) => {
    const device = getProjectDeviceById(getState(), deviceId)
    const chainsDeviceModule = device.modules.find(
      module =>
        module.getType() === existingChain.channel.typeNumber &&
        (module.parametersReadable?.snr || device.serialNumber) === existingChain.channel.serialNumber,
    )
    const moduleDataSourceId = chainsDeviceModule.channels?.[0]?.parameters.dataSourceId
    const uartModules = moduleDataSourceId
      ? device.modules.filter(module => module.channels?.[0]?.parameters.dataSourceId === moduleDataSourceId)
      : [chainsDeviceModule]
    const uartModulesTypeNumber = uartModules.map(module => module.getType())
    const uartModulesSerialsNumber = uartModules.map(module => module.parametersReadable?.snr || device.serialNumber)
    const currentModules = enrichedDeviceModulesChain.filter(
      moduleCandidate =>
        moduleCandidate[0]?.channel &&
        uartModulesTypeNumber.includes(moduleCandidate[0].channel.typeNumber) &&
        uartModulesSerialsNumber.includes(moduleCandidate[0].channel.serialNumber),
    )
    const maxUsedCutoffFrequency = currentModules.reduce(
      // for each module
      (maxUsedFrequencySoFar, module) =>
        module.reduce((acc, item) => {
          // and each chain within that module
          if (
            item.sensor?.typeNumber &&
            item.sensor.catalog &&
            item.sensor.sensitivityAtMaxRange && // valid item
            (item.channel.slot !== existingChain.channel.slot ||
              item.channel.typeNumber !== existingChain.channel.typeNumber ||
              item.channel.serialNumber !== existingChain.channel.serialNumber) && // not using old value
            item.userPreferences?.cutOffFrequency > acc // and this channel's value should take precedence
          ) {
            return item.userPreferences.cutOffFrequency
          }
          return acc
        }, maxUsedFrequencySoFar),
      cutOffFrequency,
    )
    const samplingRateIndex = device.samplingRates.findIndex(
      sampleRate => sampleRate.dataSourceId === moduleDataSourceId,
    )
    const updateSpec = {
      samplingRates: {},
    }
    const originalSamplingRate = device.samplingRates[samplingRateIndex].sampleRate
    // case for updating main sampling rate
    if (samplingRateIndex === 0 || device.samplingRates[0].sampleRate < maxUsedCutoffFrequency) {
      const mainSamplingRateOptions = device.getMainSamplingRateOptions()
      const newSamplingRateOption =
        mainSamplingRateOptions.find(({ value }) => value >= maxUsedCutoffFrequency * 10) ||
        mainSamplingRateOptions.slice(-1)[0]
      updateSpec.samplingRates['0'] = { sampleRate: { $set: newSamplingRateOption.value } }
      // this temporary mutation is needed to get correct compatible sampling rates
      device.samplingRates[0].sampleRate = newSamplingRateOption.value
    }
    // now think about compatible sampling rate option cases
    const compatibleSamplingRates = device.getCompatibleSamplingRateOptions().map(({ value }) => value)
    if (samplingRateIndex === 0) {
      // revert temporary mutation as we already have compatibleSamplingRates (and we need original value to correctly mark changes on device)
      device.samplingRates[0].sampleRate = originalSamplingRate
      device.samplingRates.slice(1).forEach((samplingRate, index) => {
        // if sampling rate is no longer supported
        if (!compatibleSamplingRates.includes(samplingRate.sampleRate)) {
          // select compatible sampling rate value that is closest to currently selected one
          //  NOTE: This algorithm is inefficient, but simple/easy to understand.
          //  As we are working on short list of items, it's better to do it this way compared to binary searching
          //  best value (more complex algorithm)
          const newSamplingRateValue = compatibleSamplingRates.reduce((acc, item) => {
            if (Math.abs(samplingRate.sampleRate - item) < Math.abs(acc - samplingRate.sampleRate)) {
              return item
            }
            return acc
          }, compatibleSamplingRates[0])
          updateSpec.samplingRates[index + 1] = { sampleRate: { $set: newSamplingRateValue } }
        }
      })
    } else {
      const newSampleRate =
        compatibleSamplingRates.find(value => value >= maxUsedCutoffFrequency * 10) ||
        compatibleSamplingRates.slice(-1)[0]
      updateSpec.samplingRates[samplingRateIndex] = { sampleRate: { $set: newSampleRate } }
    }
    const updatedDevice = dispatch(updateProjectDeviceById(device.id, updateSpec))
    updatedDevice?.refreshSamplingRateOptionLabels()
  }

let lastUpdatedChannelChain
let lastUpdateTimeoutId
let lastUpdatedDevice
export const updateMaxPhysicalRangeDependencyRecalculations =
  (existingChain, maxPhysicalRange, deviceId) => (dispatch, getState) => {
    const device = getProjectDeviceById(getState(), deviceId)
    const minimum = Math.trunc(existingChain.sensor?.sensitivityAtMaxRange?.[0]?.maxRange)
    const rawMaximum = existingChain.sensor?.sensitivityAtMaxRange?.slice(-1)[0]?.maxRange
    const maximum = rawMaximum < 0 ? Math.floor(rawMaximum) : Math.ceil(rawMaximum)
    if (!isNaN(minimum) && !isNaN(maximum) && existingChain.sensor.catalog) {
      const moduleToUpdate = device.modules.find(
        module =>
          module.getType() === existingChain.channel.typeNumber &&
          (module.parametersReadable?.snr || device.serialNumber) === existingChain.channel.serialNumber,
      )
      const channelToUpdate = moduleToUpdate?.channels.find(
        channel => `channels/${channel.getChannelIndex() + 1}` === existingChain.channel.slot,
      )
      const pickedSensitivity = getSensorSensitivityByPhysicalRangeTo(
        existingChain.sensor.sensitivityAtMaxRange,
        maxPhysicalRange,
      )
      const updatedChannel = channelToUpdate
        .setParameter('stage', existingChain.sensor.catalog.sensorClass.toLowerCase())
        .setParameter('physicalQuantity', existingChain.sensor.catalog.measurand)
        .setParameter(
          'sensitivity',
          device.isLabAmp() ? Math.abs(pickedSensitivity.sensitivityValue) : pickedSensitivity.sensitivityValue,
        )
        .setParameter('sensitivityUnit', pickedSensitivity.sensitivityUnit.replace(/\s/g, ''))
        .setParameter('physicalRange[1]', Math.abs(maxPhysicalRange))
        .setParameter('physicalUnit', pickedSensitivity.maxRangeUnit)
      dispatch(setChannelAlerts(updatedChannel.validate()))

      if (
        lastUpdatedDevice &&
        (lastUpdatedChannelChain.typeNumber !== existingChain.channel?.typeNumber ||
          lastUpdatedChannelChain.serialNumber !== existingChain.channel?.serialNumber ||
          lastUpdatedChannelChain.slot !== existingChain.channel?.slot)
      ) {
        // lastUpdatedDevice could have race-condition (is changed before last saveProjectDevice is actually called) with sampling-rate changes.
        // Resolve this issue manually by creating new device as manual merge of these 2 things
        // NOTE: Fully correct/bulletproof solution would be to run saveProjectDevice immediately, but that would make too big performance hit,
        //  so make a compromise of unsystematic fix for this problem (as there's very limited number of changes that could clash with this)
        const lastUpdatedDeviceInState = getProjectDeviceById(getState(), lastUpdatedDevice.id)
        lastUpdatedDevice.samplingRates = lastUpdatedDeviceInState.samplingRates

        dispatch(saveProjectDevice(lastUpdatedDevice, false))
      }

      // saveProjectDevice must be called before setProjectDeviceChannel otherwise it overwrites it.
      const updatedDevice = dispatch(setProjectDeviceChannel(updatedChannel))

      lastUpdatedChannelChain = existingChain.channel || {}
      lastUpdatedDevice = updatedDevice
      clearTimeout(lastUpdateTimeoutId)
      lastUpdateTimeoutId = setTimeout(() => dispatch(saveProjectDevice(updatedDevice)), 300)
    }
  }
