import { removeOldProjectDevices, setProjectDeviceChannels } from './project-devices-actions'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter'
import { DEVICES_LOADED_EVENT } from '@/fleet-configuration/data-fleet/devices/devices-constants'
import { areProjectComponentsLoaded } from '@/fleet-configuration/data-fleet/components/components-selectors'
import { throttle } from 'lodash'

export const initProjectDevicesEventListener = (dispatch, getState) => {
  SbEmitter.on('ChannelSpanningUpdated', ({ updateChannels }) => {
    dispatch(setProjectDeviceChannels(updateChannels))
  })

  // in case of many devices in project - this can be costly
  // this code is just cleanup so running it at most once per 10s is OK
  SbEmitter.on(
    DEVICES_LOADED_EVENT,
    throttle(
      () => {
        if (areProjectComponentsLoaded(getState())) {
          removeOldProjectDevices(getState, dispatch)
        }
      },
      10000,
      { leading: true, trailing: true },
    ),
  )
}
