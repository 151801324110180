import { EventListener } from '@/fleet-configuration/events/event-listener'
import {
  initDeviceOnlineStatusListener,
  storeDeviceOnlineStatusEventsUntilInit,
} from '@/fleet-configuration/data-fleet/device-online-status/device-online-status-listener'
import { initDeviceRegisterStatusListener } from '@/fleet-configuration/data-fleet/device-register-status/device-register-status-listener'
import { initDeviceSyncListener } from '@/fleet-configuration/data-fleet/device-sync/device-sync-listener'
import { registerDefaultHttpErrorsHandler } from '@/fleet-configuration/utils/handleHttpError'
import { loadProjectsIfEmpty } from '@/fleet-configuration/data-fleet/projects/projects-actions'
import { initProjectDevicesEventListener } from '@/fleet-configuration/data-fleet/project-devices/project-devices-event-listener'
import { initFirmwareEventListener } from '@/fleet-configuration/data-fleet/firmware/firmware-listener'
import { initEquipmentListener } from '@/fleet-configuration/equipment/equipment-listener'

let initDone = false
storeDeviceOnlineStatusEventsUntilInit()
export const fleetInit = _ => async (dispatch, getState) => {
  // run init at most once
  if (initDone) {
    return
  }
  initDone = true

  await dispatch(loadProjectsIfEmpty())
  registerDefaultHttpErrorsHandler(dispatch)
  const eventListener = new EventListener(dispatch, getState)
  initDeviceOnlineStatusListener(eventListener, dispatch, getState)
  initDeviceRegisterStatusListener(eventListener, dispatch, getState)
  initDeviceSyncListener(eventListener, dispatch, getState)
  initFirmwareEventListener(eventListener, dispatch, getState, _)
  initEquipmentListener(eventListener)
  initProjectDevicesEventListener(dispatch, getState)
}
