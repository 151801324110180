import { isArray, castArray } from 'lodash'
import { getStudioAPIHost } from '@/utils/url'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter'
import { getActiveProjectId } from '@/fleet-configuration/data-fleet/projects/projects-selectors'
import { OAuth } from 'skybase-oauth/oauth'
import { PROJECTS_LOADED_EVENT } from '@/fleet-configuration/data-fleet/projects/projects-constants'
import { liveWebSocketFactory, sendInitialSocketRequest } from './web-socket-factory'

export class EventListener {
  eventsSocket = null

  _registeredEvents = []

  constructor(dispatch, getState) {
    this.dispatch = dispatch
    this.getState = getState
    const token = this.getAccessTokenForSockets()
    if (token) {
      this.initEventListenerSockets()
    }
  }

  authenticateSocket(socket) {
    // eslint-disable-next-line no-param-reassign
    socket.authenticateHandler = () => {
      socket.send(JSON.stringify({ Token: this.getAccessTokenForSockets() }))
    }
    socket.addEventListener('open', socket.authenticateHandler)
  }

  getAccessTokenForSockets() {
    const { accessToken } = OAuth.oAuthHandler.getAccessToken()
    return accessToken
  }

  initEventListenerSockets() {
    this.eventsSocket = liveWebSocketFactory(`${getStudioAPIHost(true)}/events`)

    let initialProjectId = getActiveProjectId(this.getState())
    const initEventsSocket = () => {
      // add on open every time (because reconnect)
      this.eventsSocket.addEventListener('open', () =>
        sendInitialSocketRequest(this.eventsSocket, { projectId: initialProjectId }),
      )
      if (this.eventsSocket.readyState !== this.eventsSocket.CONNECTING) {
        sendInitialSocketRequest(this.eventsSocket, { projectId: initialProjectId })
      }
    }
    const sendAccessTokenMsg = socket => {
      const doSend = () => {
        const { accessToken } = OAuth.oAuthHandler.getAccessToken()
        socket.send(JSON.stringify({ accessToken }))
      }

      if (this.eventsSocket.readyState === this.eventsSocket.CONNECTING) {
        this.eventsSocket.addEventListener('open', doSend)
      } else {
        doSend()
      }
    }
    if (initialProjectId) {
      initEventsSocket()
    } else {
      sendAccessTokenMsg(this.eventsSocket)
      SbEmitter.once(PROJECTS_LOADED_EVENT, () => {
        initialProjectId = getActiveProjectId(this.getState())
        initEventsSocket()
      })
    }
    this.eventsSocket.addEventListener('message', this.handleEventsMessage)
  }

  handleEventsMessage = event => {
    const message = JSON.parse(event.data)
    if (message.kind === 'pong') {
      return
    }

    castArray(message).forEach(singleMessage => this.doHandleMessage(singleMessage, singleMessage.eventName))
  }

  doHandleMessage = (message, type) => {
    this._registeredEvents.forEach(({ eventName, callback }) => {
      const eventNames = isArray(eventName) ? eventName : [eventName]
      if (eventNames.includes(type)) {
        callback(message)
      }
    })
  }

  on(eventName, callback) {
    this._registeredEvents.push({ eventName, callback })
  }
}
