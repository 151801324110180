import axios from 'axios'
import { uniq, intersection, range } from 'lodash'
import { getActionName } from '@/common/actions'
import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { getStudioAPIHost } from '@/utils/url'
import { getDeviceCalibrationsByDeviceId } from '@/fleet-configuration/data-fleet/devices-calibrations/devices-calibrations-selector'
import {
  KGATE_DEVICE_TYPE,
  NOT_CALIBRABLE_DEVICE_TYPES,
} from '@/fleet-configuration/pages/fleet-overview/fleet-overview-constants'
import { getDeviceById } from '@/fleet-configuration/data-fleet/devices/devices-selectors'
import { batchActions } from 'redux-batched-actions'
import { MAX_CALIBRATIONS_TO_LOAD_PER_REQUEST } from '@/fleet-configuration/data-fleet/calibration/calibration-constants'

export const SET_DEVICES_CALIBRATION = getActionName('SET_DEVICES_CALIBRATION')
export const setDevicesCalibration = (deviceId, calibrationData) =>
  createAction(SET_DEVICES_CALIBRATION, { ...calibrationData, id: deviceId })

export const loadDeviceCalibrations = deviceId => async (dispatch, getState) => {
  const device = getDeviceById(getState(), deviceId)
  if (!device?.types || device.types.includes(KGATE_DEVICE_TYPE)) {
    return
  }

  const { data } = await axios
    .post(
      `${getStudioAPIHost()}/api/calibrations`,
      [{ typeNumber: device.modelNumber, serialNumber: device.serialNumber }],
      {
        customErrorHandler: () => {}, // suppress errors - bad request is part of "normal" workflow and is handled explicitly
      },
    )
    .catch(() => ({
      data: [{ hasRequestError: true }],
    }))
  dispatch(setDevicesCalibration(deviceId, data?.[0] || {}))
}

export const loadDeviceCalibrationsIfEmpty =
  deviceId =>
  (dispatch, getState, ...rest) =>
    getDeviceCalibrationsByDeviceId(getState(), deviceId) ||
    loadDeviceCalibrations(deviceId)(dispatch, getState, ...rest)

const devicesAlreadyBeingLoaded = []
let lastCalibrationRequestPromise = null
export const batchLoadDevicesCalibrations = listOfDeviceIds => async (dispatch, getState) => {
  const devices = listOfDeviceIds.map(id => getDeviceById(getState(), id))
  const calibrableDevices = devices.filter(
    device => device && !intersection(NOT_CALIBRABLE_DEVICE_TYPES, device.types).length,
  )
  if (!calibrableDevices.length) {
    return null
  }

  // Check which devices are not already being loaded
  const newFetches = calibrableDevices.filter(device => !devicesAlreadyBeingLoaded.includes(device.id))
  if (!newFetches.length) {
    return lastCalibrationRequestPromise
  }
  newFetches.forEach(device => {
    devicesAlreadyBeingLoaded.push(device.id)
  })

  // Create a new promise for this batch of requests
  let resolver
  const newPromise = new Promise(resolve => {
    resolver = resolve
  })

  // Chain the new promise with the existing one
  if (lastCalibrationRequestPromise) {
    lastCalibrationRequestPromise = lastCalibrationRequestPromise.then(() => newPromise)
  } else {
    lastCalibrationRequestPromise = newPromise
  }

  // first mark all requests as being loaded
  dispatch(
    batchActions(
      newFetches.map(device =>
        setDevicesCalibration(device.id, {
          typeNumber: device.modelNumber,
          serialNumber: device.serialNumber,
          isLoading: true,
        }),
      ),
    ),
  )

  const numberOfCalibrationRequests = newFetches.length / MAX_CALIBRATIONS_TO_LOAD_PER_REQUEST
  // load calibrations per batch size - each batch sequentially (wait for previous item to finish)
  //  The reason for this is, that /api/calibrations can't handle too much stress, so this code gives a bit of breathing room to it - see DHUB-5211
  await range(0, numberOfCalibrationRequests).reduce(async (previousPromise, currentDataIndex) => {
    // NOTE: first await is "previousPromise" for next iteration of requests

    const deviceBatch = newFetches.slice(
      currentDataIndex * MAX_CALIBRATIONS_TO_LOAD_PER_REQUEST,
      (currentDataIndex + 1) * MAX_CALIBRATIONS_TO_LOAD_PER_REQUEST,
    )
    const thisIterationRequest = previousPromise.then(() =>
      axios
        .post(
          `${getStudioAPIHost()}/api/calibrations`,
          deviceBatch.map(device => ({ typeNumber: device.modelNumber, serialNumber: device.serialNumber })),
          {
            customErrorHandler: () => {}, // suppress errors - bad request is part of "normal" workflow and is handled explicitly
          },
        )
        .then(response => {
          if (response.data.length === 0) {
            return {
              data: deviceBatch.map(device => ({ device, hasRequestError: true })),
            }
          }
          return response
        })
        .catch(() => ({
          data: deviceBatch.map(device => ({ device, hasRequestError: true })),
        })),
    )
    // this await represents return value for the reduce = "previousPromise"
    const { data } = await thisIterationRequest
    // Remove new devices from the map of devices being loaded
    deviceBatch.forEach(device => {
      const index = devicesAlreadyBeingLoaded.findIndex(id => id === device.id)
      if (index !== -1) {
        devicesAlreadyBeingLoaded.splice(index, 1)
      }
    })

    if (data?.map) {
      // each device request will get some response. BUT order of request-to-response is not kept, so we need to match them manually
      const idData = data
        .map(entry => ({
          id:
            deviceBatch.find(
              device =>
                device.modelNumber.startsWith(entry.baseTypeNumber) && device.serialNumber === entry.serialNumber,
            )?.id || entry.device?.id,
          entryData: entry,
        }))
        .filter(entry => entry.id)

      if (idData && idData.length > 0) {
        dispatch(batchActions(idData.map(({ entryData, id }) => setDevicesCalibration(id, entryData))))
      }
    }
  }, Promise.resolve())

  // after everything is done, check if some calibration is stuck in "loading" state
  const state = getState()
  const loadingCalibrationsNeedingFix = newFetches.reduce((acc, device) => {
    const calibration = getDeviceCalibrationsByDeviceId(state, device.id)
    if (calibration.isLoading) {
      acc.push(
        setDevicesCalibration(device.id, {
          typeNumber: device.modelNumber,
          serialNumber: device.serialNumber,
          hasRequestError: true,
        }),
      )
      console.error('Calibration did not finish loading', calibration)
    }
    return acc
  }, [])
  if (loadingCalibrationsNeedingFix.length) {
    dispatch(batchActions(loadingCalibrationsNeedingFix))
  }

  resolver(newFetches)
  return lastCalibrationRequestPromise
}

export const batchLoadDevicesCalibrationsIfEmpty =
  listOfDeviceIds =>
  async (dispatch, getState, ...rest) => {
    const state = getState()
    const devicesWithoutCalibrations = []
    uniq(listOfDeviceIds).forEach(deviceId => {
      const calibration = getDeviceCalibrationsByDeviceId(state, deviceId)
      if (!calibration || calibration.hasRequestError) {
        devicesWithoutCalibrations.push(deviceId)
      }
    })

    return batchLoadDevicesCalibrations(devicesWithoutCalibrations)(dispatch, getState, ...rest)
  }
