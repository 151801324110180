import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { Sb21Layout } from 'skybase-ui/skybase-components/layouts'
import { ScopesList, CreateScopeButton, ScopeTab } from 'skybase-oauth/scopes/components'
import { getOAuthState } from 'skybase-oauth/utils'
import { fetchScopes, fetchScope } from 'skybase-oauth/scopes/utils'
import { fetchApi, apiShape, APIS_STATE_KEY_NAME, APIS_STATE_KEYS } from 'skybase-oauth/apis'
import { withAcl, aclShape } from 'skybase-oauth/auth'
import { oAuthPermissions } from 'skybase-oauth/oauth-permissions'

import { withRouter } from '@/common/router'
import { messages as menuT } from '@/common/menu/menu-i18n'

import { messages as t } from './scopes-page-i18n'

export class _ScopesPage extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    handleFetchApi: PropTypes.func.isRequired,
    handleFetchScopes: PropTypes.func.isRequired,
    handleFetchScope: PropTypes.func.isRequired,
    acl: aclShape.isRequired,
    apiData: apiShape.isRequired,
    router: PropTypes.shape({
      params: PropTypes.shape({
        apiId: PropTypes.string,
        scopeId: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }

  componentDidMount() {
    const {
      handleFetchApi,
      handleFetchScopes,
      handleFetchScope,
      acl: { read },
      router: {
        params: { apiId, scopeId },
      },
    } = this.props

    if (read && apiId) {
      handleFetchApi(apiId)
      handleFetchScopes(apiId)

      if (scopeId) {
        handleFetchScope(apiId, scopeId)
      }
    }
  }

  getBreadcrumbs = () => {
    const {
      apiData,
      intl: { formatMessage: _ },
    } = this.props

    if (apiData.tempId) {
      const { tempId, displayName } = apiData
      return [
        {
          path: '/',
          title: _(menuT.home),
        },
        {
          path: '/identity-management',
          title: _(menuT.identityManagement),
        },
        {
          path: '/identity-management/oauth-management',
          title: _(menuT.oAuthManagementPage),
        },
        {
          path: '/identity-management/oauth-management/apis',
          title: _(menuT.apisPage),
        },
        {
          path: `/identity-management/oauth-management/apis/${tempId}`,
          title: displayName,
        },
        _(t.pageName),
      ]
    }

    return null
  }

  render() {
    const {
      intl: { formatMessage: _ },
    } = this.props

    return (
      <Sb21Layout title={_(t.pageName)} breadcrumbs={this.getBreadcrumbs()}>
        <div>
          <div className="sb-heading fl-row fl-align-items-center">
            <h1>{_(t.pageName)}</h1>
            <CreateScopeButton />
          </div>
          <ScopesList />
        </div>
        <ScopeTab />
      </Sb21Layout>
    )
  }
}

const mapStateToProps = state => {
  const {
    [APIS_STATE_KEYS.UPDATE_API]: { data: apiData },
  } = getOAuthState(state)[APIS_STATE_KEY_NAME]

  return {
    apiData,
  }
}

const mapDispatchToProps = (dispatch, { intl: { formatMessage } }) => {
  return {
    handleFetchApi: apiId => dispatch(fetchApi(apiId, formatMessage)),
    handleFetchScopes: apiId => dispatch(fetchScopes(apiId, formatMessage)()),
    handleFetchScope: (apiId, scopeId) => dispatch(fetchScope(apiId, scopeId, formatMessage)),
  }
}

const withAclComponent = withAcl(_ScopesPage, {
  readPermissions: [oAuthPermissions.kiconnectApisReadWrite],
})

export const ScopesPage = withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(withAclComponent)))
