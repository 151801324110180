import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  pageName: {
    id: 'iot-hub-data-sources-page.pageName',
    defaultMessage: 'Data Sources',
  },
  details: {
    id: 'iot-hub-data-sources-page.details',
    defaultMessage: 'Details',
  },
  deviceNotFound: {
    id: 'iot-hub-data-sources-page.deviceNotFound',
    defaultMessage: 'Device with ID "{deviceId}" not found.',
  },
  deviceDataSourceNotFound: {
    id: 'iot-hub-data-sources-page.deviceDataSourceNotFound',
    defaultMessage: 'Data Source with ID "{dataSourceId}" not found.',
  },
  loadingDataSources: {
    id: 'iot-hub-data-sources-page.loadingDataSources',
    defaultMessage: 'Loading data sources',
  },
})
