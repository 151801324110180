/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  youDontHavePermissionsToPerformRemoteFirmwareUpdateOnADevice: {
    id: 'firmwareActions.youDontHavePermissionsToPerformRemoteFirmwareUpdateOnADevice',
    defaultMessage:
      "You don't have permissions to perform remote firmware update on a device. Please contact your KiCloud accounts administrator for further steps.",
  },
})
