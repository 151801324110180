import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { intersection } from 'lodash'
import { SbDataTable } from 'skybase-ui/skybase-components/sb-data-table'
import { expandedRowsArrowPositions, expandedRowsTypes } from 'skybase-ui/skybase-components/sb-data-table/constants'
import { SbFullLayout } from 'skybase-ui/skybase-components/layouts/sb-full-layout'
import { SbLink } from 'skybase-ui/skybase-components/sb-link/sb-link'
import { SbHint } from 'skybase-ui/skybase-components/sb-hint/sb-hint'
import { hintPositions } from 'skybase-ui/skybase-components/sb-hint/constants'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { loaderSize } from 'skybase-ui/skybase-components/sb-loader/constants'
import { showErrorToast } from '@/common/services/show-toast'
import { devicesTableRenderer } from '@/fleet-configuration/page-components/devices-table-renderer/devices-table-renderer'
import {
  factoryResetDevice,
  loadDevices,
  loadDevicesIfEmpty,
  rebootDevice,
  setDeviceName,
  storeDeviceName,
} from '@/fleet-configuration/data-fleet/devices/devices-actions'
import {
  loadCatalog,
  loadProjectDeviceIfEmpty,
} from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbTextbox } from 'skybase-ui/skybase-components/sb-textbox/sb-textbox'
import { dataTableFocusComponent } from '@/fleet-configuration/utils/data-table-utils'
import { checkboxLabelPosition } from 'skybase-ui/skybase-components/sb-checkbox/constants'
import { SbCheckbox } from 'skybase-ui/skybase-components/sb-checkbox/sb-checkbox'
import { fleetOverviewSelector } from '@/fleet-configuration/pages/fleet-overview/fleet-overview-selector'
import { fleetInit } from '@/fleet-configuration/fleet-init'
import { FleetOverviewExpandedRow } from '@/fleet-configuration/pages/fleet-overview/fleet-overview-expanded-row/fleet-overview-expanded-row'
import { BackupConfigurationModal } from '@/fleet-configuration/components/backup-configuration/backup-configuration-modal'
import { loadProjectsIfEmpty } from '@/fleet-configuration/data-fleet/projects/projects-actions'
import { batchLoadDevicesCalibrationsIfEmpty } from '@/fleet-configuration/data-fleet/devices-calibrations/devices-calibrations-actions'
import { showConfirmModal } from '@/fleet-configuration/components/confirm-modal/confirm-modal-actions'
import { loadFirmwareIfEmpty } from '@/fleet-configuration/data-fleet/firmware/firmware-actions'
import { IdentifyButton } from '@/fleet-configuration/components/identify-button/identify-button'
import { loadActiveProjectComponentsIfEmpty } from '@/fleet-configuration/data-fleet/components/components-actions'
import { DeviceDeleteButton } from '@/iot-hub/components/devices/device-delete-button'
import { deleteTrialSimulatorApi } from '@/containers/home-page/components'
import { withRouter } from '@/common/router'
import { calibrationShape } from '@/fleet-configuration/equipment/shapes'
import { CertificateIcon } from '@/fleet-configuration/components/certificate-icon/certificate-icon'
import { CONFIGURABLE_DEVICE_TYPES } from '@/fleet-configuration/pages/fleet-overview/fleet-overview-constants'
import { messages as t } from './fleet-overview-i18n'
import './fleet-overview.scss'
import '../pages.scss'

let lastKnownOfflineDeviceState = false
class _FleetOverview extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    dispatch: PropTypes.func.isRequired,
    devices: PropTypes.array.isRequired,
    deviceIdsInProject: PropTypes.object.isRequired,
    calibrations: PropTypes.arrayOf(calibrationShape),
    areOfflineDevicesShown: PropTypes.bool,
    router: PropTypes.shape({ navigate: PropTypes.func.isRequired }).isRequired,
    trialSimulator: PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string,
    }),
  }

  static defaultProps = {
    areOfflineDevicesShown: null,
    calibrations: [],
    trialSimulator: null,
  }

  constructor(props) {
    super(props)
    const {
      intl: { formatMessage: _ },
    } = props

    devicesTableRenderer.initialize(_)
    this.tableColumns = [...devicesTableRenderer.commonTableColumns, { name: '_actions', label: ' ', sortable: false }]
    this.state = {
      openRowDeviceId: null,
      backupModalIds: null,
      pagination: {
        pageSize: 10,
        pageNumber: 1,
      },
      isTableLoading: true,
    }
  }

  async componentDidMount() {
    const {
      dispatch,
      areOfflineDevicesShown,
      intl: { formatMessage },
    } = this.props
    if (areOfflineDevicesShown === null) {
      // Moving this call to the end of the stack supresses the console warning
      // "You should call navigate() in a React.useEffect(), not when your component is first rendered."
      setTimeout(() => {
        this.setOfflineDeviceUrl(lastKnownOfflineDeviceState)
      }, 0)
    }
    const fleetInitPromise = dispatch(fleetInit(formatMessage))
    const devicesPromise = dispatch(loadDevicesIfEmpty())
    await dispatch(loadProjectsIfEmpty())

    // make sure system correctly knows which (offline) devices are in project.
    //  NOTE: this function can only be called after loadProjectsIfEmpty finishes
    const activeProjectComponentsPromise = dispatch(loadActiveProjectComponentsIfEmpty())

    await Promise.all([activeProjectComponentsPromise, devicesPromise, fleetInitPromise])
    this.setState({ isTableLoading: false })
  }

  componentDidUpdate() {
    const { areOfflineDevicesShown } = this.props
    if (areOfflineDevicesShown === null) {
      this.setOfflineDeviceUrl(lastKnownOfflineDeviceState)
    }
  }

  calibrationsLoaderId = null

  calibrationsToLoad = []

  rowFormatter = row => {
    const { dispatch } = this.props
    if (!intersection(row.types, CONFIGURABLE_DEVICE_TYPES).length && !row.isDeviceLoaded) {
      // not configurable devices but needed in fleetOverview
      dispatch(loadCatalog(row.modelNumber))
    }
    if (!row.isProjectDeviceLoaded) {
      dispatch(loadProjectDeviceIfEmpty(row.id))
    }
    dispatch(loadFirmwareIfEmpty(row.id))
    this.calibrationsToLoad.push(row.id)
    clearTimeout(this.calibrationsLoaderId)
    this.calibrationsLoaderId = setTimeout(() => {
      dispatch(batchLoadDevicesCalibrationsIfEmpty(this.calibrationsToLoad))
      this.calibrationsToLoad = []
    })
    const { openRowDeviceId } = this.state
    return { className: row.id === openRowDeviceId ? 'opened-expanded-row' : '' }
  }

  handleOnPaginationChange = pagination => this.setState({ pagination })

  handleOnExpandableRowToggle = id => {
    this.setState(({ openRowDeviceId }) => {
      return { openRowDeviceId: openRowDeviceId === id ? null : id }
    })
  }

  handleOnFactoryResetIconClick = (evt, id, row) => {
    const { trialSimulator } = this.props

    if (!row.online) {
      return
    }

    const {
      dispatch,
      intl: { formatMessage: _ },
    } = this.props
    dispatch(
      showConfirmModal(_, _(t.factoryReset), factoryResetDevice(id), {
        modalMessage: (
          <>
            {_(t.areYouSureYouWantToResetSettingsOnDeviceDevice, { device: <i>{row.name}</i> })}
            <br />
            {_(t.theDeviceWillDisappearAndHasToBeOnboardedAgain)}
          </>
        ),
        confirmText: _(t.reset),
        className: 'destructive-confirm-modal',
        afterConfirmAction: () => {
          // Delete the trial simulator if it's this device
          if (trialSimulator?.id === id) {
            // This API might fail, but it should not block the user, the primary action was done
            deleteTrialSimulatorApi().catch(() => {})
          }
        },
      }),
    )
  }

  handleOnRebootIconClick = (evt, id, row) => {
    if (!row.online) {
      return
    }

    const {
      dispatch,
      intl: { formatMessage: _ },
    } = this.props
    dispatch(
      showConfirmModal(_, _(t.rebootDevice), rebootDevice(id), {
        modalMessage: (
          <>
            {_(t.areYouSureYouWantToRebootDeviceDevice, { device: <i>{row.name}</i> })}
            <br />
            {_(t.theDeviceWillNotBeVisibleDuringBootingUp)}
          </>
        ),
        confirmText: _(t.reboot),
      }),
    )
  }

  handleOnBackupIconClick = (evt, deviceId, piid) => {
    this.setState({ backupModalIds: [deviceId, piid] })
  }

  dismissBackupModal = () => {
    this.setState({ backupModalIds: null })
  }

  cellFormatter = (value, key, row) => {
    if (key === 'name') {
      return this.renderName(value, row)
    }
    if (devicesTableRenderer.commonCellFormatterFields.includes(key)) {
      return devicesTableRenderer.cellFormatter(value, key, row)
    }
    if (key === '_actions') {
      return this.renderActions(row)
    }
    return value
  }

  renderName(value, row) {
    const editComponent = (
      <SbTextbox
        className={`editable-component ${row.isDirty ? 'status-dirty' : ''}`}
        value={value}
        disabled={!row.online}
        onFocus={evt => this.handleOnNameFocus(evt, row)}
        onChange={evt => this.handleOnNameChange(evt.target.value, row)}
        onBlur={() => this.handleOnNameBlur(row)}
        onClick={evt => evt.stopPropagation()}
        size={Math.max(1, (value || '').length - 1)}
      />
    )
    if (row.isDirty) {
      const {
        intl: { formatMessage: _ },
      } = this.props
      return (
        <SbHint position={hintPositions.topRight} hintData={_(t.configurationIsNotWrittenToDevice)}>
          {editComponent}
        </SbHint>
      )
    }
    return editComponent
  }

  handleOnNameFocus = (evt, row) => {
    this.focusName = row.name
    dataTableFocusComponent(evt)
  }

  handleOnNameChange = (newName, row) => {
    const { dispatch } = this.props
    dispatch(setDeviceName(row.id, newName))
  }

  handleOnNameBlur = async row => {
    const {
      dispatch,
      intl: { formatMessage: _ },
    } = this.props
    if (this.focusName !== row.name) {
      try {
        await dispatch(storeDeviceName(row.id, row.name))
      } catch (e) {
        showErrorToast(_(t.deviceNameUpdateFailed))
        dispatch(loadDevices())
      }
    }
  }

  renderActions(row) {
    const {
      intl: { formatMessage: _ },
      deviceIdsInProject,
      calibrations,
      trialSimulator,
    } = this.props
    const { id, canBackup, documentationUrl, isConfigurable, online, hasLED } = row

    const identifyIcon = hasLED ? <IdentifyButton deviceId={id} online={online} /> : null
    const factoryResetIcon = (
      <SbHint
        hintData={online ? _(t.factoryReset) : _(t.factoryResetCantBeCalledOnOfflineDevice)}
        position={hintPositions.topCenter}
      >
        <span
          className={classNames('sbi-reset-device-kids icon-link', { 'icon-disabled': !online })}
          onClick={evt => this.handleOnFactoryResetIconClick(evt, id, row)}
        />
      </SbHint>
    )
    const rebootIcon = (
      <SbHint
        hintData={online ? _(t.rebootDevice) : _(t.rebootCantBeCalledOnOfflineDevice)}
        position={hintPositions.topCenter}
      >
        <span
          className={classNames('sbi-power-button-kids icon-link', { 'icon-disabled': !online })}
          onClick={evt => this.handleOnRebootIconClick(evt, id, row)}
        />
      </SbHint>
    )
    const backupIcon = (
      <SbHint
        hintData={canBackup ? _(t.backupDeviceConfiguration) : _(t.configurationBackupRestoreNotSupported)}
        position={canBackup ? hintPositions.topCenter : hintPositions.middleLeft}
      >
        <span
          className={classNames('sbi-backup-manager-kids icon-link', { 'icon-disabled': !canBackup })}
          onClick={canBackup ? evt => this.handleOnBackupIconClick(evt, id, row.protocolIndependentId) : null}
        />
      </SbHint>
    )
    const deleteButton = <DeviceDeleteButton data={row} isInTable trialSimulator={trialSimulator} />
    const documentationLink = documentationUrl ? (
      <SbHint hintData={_(t.deviceDocumentation)} position={hintPositions.topCenter}>
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label */}
        <a
          href={documentationUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="device-config-link sbi-link-kids icon-link"
        />
      </SbHint>
    ) : null
    let configureLink = null
    if (isConfigurable) {
      if (!row.isProjectDeviceLoaded) {
        configureLink = (
          <SbHint hintData={_(t.configureDevice)} position={hintPositions.topCenter}>
            <span className="config-loading">
              <SbLoader show size={loaderSize.XXS} />
            </span>
          </SbHint>
        )
      } else {
        const inProject = !!deviceIdsInProject[id]
        configureLink = inProject ? (
          <SbLink to={`/configuration/hardware-setup/${id}`}>
            <SbHint hintData={_(t.configureDevice)} position={hintPositions.topCenter}>
              <span className="device-config-link sbi-setting-kids icon-link" />
            </SbHint>
          </SbLink>
        ) : (
          <SbHint hintData={_(t.deviceConfigurationIsDisabled)} position={hintPositions.middleLeft}>
            <span className="device-config-link sbi-setting-kids icon-link icon-disabled" />
          </SbHint>
        )
      }
    }
    const notificationsNode =
      row.notifications > 0 ? (
        <SbHint
          className="num-of-notification"
          hintData={_(t.notificationsIssueSToPayAttention, { notifications: row.notifications })}
          position={hintPositions.topCenter}
        >
          <span>{row.notifications}</span>
        </SbHint>
      ) : null

    // items are shown from right to left
    const content = (
      <>
        {deleteButton}
        {configureLink}
        {documentationLink}
        {backupIcon}
        {factoryResetIcon}
        {rebootIcon}
        {identifyIcon}
        {row.isDeviceCalibrable && <CertificateIcon calibrations={calibrations} row={row} />}
      </>
    )

    return content || notificationsNode ? (
      <div className="actions-wrapper">
        {notificationsNode}
        <div onClick={evt => evt.stopPropagation()} className="show-on-hover">
          {content}
        </div>
      </div>
    ) : null
  }

  renderExpandedRow = row => {
    const { openRowDeviceId } = this.state
    // NOTE: Do not render FleetOverviewExpandedRow when not opened. It loads data and is harder for computations
    return openRowDeviceId === row.id ? (
      <FleetOverviewExpandedRow
        deviceId={row.id}
        piid={row.protocolIndependentId}
        onNoBackupClick={this.handleOnBackupIconClick}
        typeNumber={row.modelNumber || row.typeNumber}
        serialNumber={row.serialNumber}
      />
    ) : (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <></>
    )
  }

  handleOnShowOfflineDevicesToggle = () => {
    const { areOfflineDevicesShown } = this.props
    lastKnownOfflineDeviceState = !areOfflineDevicesShown
    this.setOfflineDeviceUrl(lastKnownOfflineDeviceState)
  }

  setOfflineDeviceUrl(showOfflineDevice) {
    const { router } = this.props
    router.navigate({ search: `offlineDevice=${showOfflineDevice ? 'yes' : 'no'}` })
  }

  render() {
    const { backupModalIds, pagination, isTableLoading } = this.state
    const {
      devices,
      calibrations,
      areOfflineDevicesShown,
      intl: { formatMessage: _ },
    } = this.props

    // [DHUB-5423] fix:
    // SbDataTable does not rerender if the source data does not really change. It does not matter, that it's formatter uses additional data. The formatter itself will not be called.
    // to circumvent it, we can directly add additional field to source data that represents the dependency data
    const dirtiedDevices = devices.map(device => ({
      calibration: calibrations.find(
        calibration =>
          device.modelNumber.startsWith(calibration.baseTypeNumber) && device.serialNumber === calibration.serialNumber,
      ),
      ...device,
    }))

    return (
      <SbFullLayout
        title={_(t.fleetDevices)}
        className="license-page"
        breadcrumbs={[
          {
            path: '/',
            title: _(t.home),
          },
          {
            path: '/fleet',
            title: _(t.fleetOverview),
          },
          _(t.fleetDevices),
        ]}
      >
        <div>
          <div className="sb-heading fl-row fl-justify-sb fl-align-items-center">
            <h1 className="sb-margin-right-5">{_(t.fleetDevices)}</h1>
            <SbCheckbox
              id="showOfflineDevices"
              type="switch-control"
              className="m-r-20 test-show-offline-devices"
              onClick={this.handleOnShowOfflineDevicesToggle}
              checked={!!areOfflineDevicesShown}
              labelPosition={checkboxLabelPosition.LEFT}
              value="1"
            >
              {_(t.showOfflineDevices)}
            </SbCheckbox>
          </div>
          <SbDataTable
            id="fleet-overview-table"
            className="list-table"
            columns={this.tableColumns}
            loading={isTableLoading}
            data={dirtiedDevices}
            rowFormatter={this.rowFormatter}
            rowsUniqueKeyName="id"
            expandableRowFormatter={this.renderExpandedRow}
            expandableRowsType={expandedRowsTypes.SINGLE}
            expandableRowsArrowPosition={expandedRowsArrowPositions.LEFT}
            onExpandableRowToggle={this.handleOnExpandableRowToggle}
            emptyMessage={_(t.noDevicesFound)}
            cellFormatter={this.cellFormatter}
            enablePagination
            asyncData={false}
            paginationProps={{
              pageSizeDropdownProps: {
                className: 'min-width-100px',
              },
              ...pagination,
              onChange: this.handleOnPaginationChange,
            }}
          />
          {backupModalIds && (
            <BackupConfigurationModal
              deviceId={backupModalIds[0]}
              piid={backupModalIds[1]}
              onDismiss={this.dismissBackupModal}
            />
          )}
        </div>
      </SbFullLayout>
    )
  }
}

export const FleetOverview = withRouter(injectIntl(connect(fleetOverviewSelector)(_FleetOverview)))
