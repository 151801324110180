import { some } from 'lodash'

const entryExists = (state, id) => some(state, otherId => otherId === id)

const setEntryId = (state, action) => {
  const {
    payload: { id },
  } = action
  if (entryExists(state, id)) {
    return state
  }
  return state.concat(id)
}

const removeEntry = (state, action) => {
  const {
    payload: { id },
  } = action
  return state.filter(entryId => entryId !== id)
}

export const allEntriesReducerFactory =
  (setEntryAction, removeAllEntriesAction, removeEntryAction) =>
  (state = [], action = {}) => {
    switch (action.type) {
      case setEntryAction:
        return setEntryId(state, action)
      case removeEntryAction:
        return removeEntry(state, action)
      case removeAllEntriesAction:
        return []
      default:
        return state
    }
  }
