import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import { Route, Routes, Navigate, BrowserRouter, useNavigate } from 'react-router-dom'

import { OAuth } from 'skybase-oauth/oauth'
import { oAuthPermissions } from 'skybase-oauth/oauth-permissions'
import { AclControl } from 'skybase-oauth/auth/acl-control'
import { SbNotFoundPage, SbAccessDeniedPage } from 'skybase-pages'
import { NavigateService } from './common/services/navigate-service'

import { App } from '@/containers/app'
import {
  UsersPage,
  RolesPage,
  ClientsPage,
  ApisPage,
  ScopesPage,
  TenantsPage,
  AssignedTenantsPage,
  ApplicationsPage,
} from '@/identity-management'
import { HomePage } from '@/containers/home-page'
import { history } from '@/stores/history'
import {
  DevicesPage,
  ResourcesPage,
  DataSourcesPage,
  NotificationsPage as IoTNotificationsPage,
  AboutPage as AboutIoTPage,
  InfoPage as InfoIoTPage,
} from '@/iot-hub'
// import { AboutPage as AboutJBeamPage, ApplyAlgorithmsPage } from '@/jbeam'
import { AboutPage as LicensingAboutPage, ManagementPage } from '@/licensing'
import { FleetOverview } from '@/fleet-configuration/pages/fleet-overview/fleet-overview'
import { ConfigurationSnippets } from '@/fleet-configuration/pages/configuration-snippets/configuration-snippets'
import { EquipmentInventory } from '@/fleet-configuration/pages/equipment-inventory/equipment-inventory'
import { iotHubPermissions, licensingPermissions } from '@/common/permissions'
import { ConfigurationSnippetDetail } from '@/fleet-configuration/pages/configuration-snippet-detail/configuration-snippet-detail'
import { Configuration } from '@/fleet-configuration/pages/configuration/configuration'
import { ConfigurationHardwareSetup } from '@/fleet-configuration/pages/configuration/configuration-hardware-setup/configuration-hardware-setup'
import { ConfigurationSettings } from '@/fleet-configuration/pages/configuration/configuration-settings/configuration-settings'
import { ConfigurationSummary } from '@/fleet-configuration/pages/configuration/configuration-summary/configuration-summary'

const AppRoutes = () => {
  const { pathname = '' } = history?.location || {}

  return (
    <BrowserRouter>
      <NavigateService />
      <App>
        <Routes>
          <Route path="/:url*(/+)" element={<Navigate to={pathname.slice(0, -1)} />} />
          {OAuth.isInNormalMode && [
            <Route path="/jbeam" element={<Navigate exact to="/jbeam/home" />} key="Navigate-jbeam" />,
            <Route
              exact
              path="/identity-management"
              element={<Navigate to="/identity-management/user-management/users" />}
              key="Navigate-users"
            />,
            <Route
              exact
              path="/identity-management/user-management"
              element={<Navigate to="/identity-management/user-management/users" />}
              key="Navigate-user-management"
            />,
            <Route
              exact
              path="/identity-management/oauth-management"
              element={<Navigate to="/identity-management/oauth-management/clients" />}
              key="Navigate-clients"
            />,
            <Route exact path="/licensing" element={<Navigate to="/licensing/about" />} key="Navigate-licensing" />,
          ]}
          {!OAuth.isInNormalMode && [
            <Route exact path="/" element={<Navigate to="/iot-hub/devices" />} key="Navigate-base-url-to-devices" />,
          ]}
          <Route exact path="/iot-hub" element={<Navigate to="/iot-hub/devices" />} />
          <Route exact path="/iot-hub/about" element={<Navigate to="/iot-hub/about/how-to-use" />} />
          <Route exact path="/fleet" element={<Navigate to="/fleet/configuration" />} />

          <Route path="/" exact element={<HomePage />} key="route-homepage" />

          {[
            '/fleet/configuration/devices/:unitId',
            '/fleet/configuration/devices/:unitId/module/:moduleId',
            '/fleet/configuration/devices/:unitId/module/:moduleId/channel/:channelId/:tab?/:activeTab?',
          ].map(path => {
            const NavComponent = () => {
              const navigate = useNavigate()
              const location = useLocation()
              useEffect(() => {
                navigate(location.pathname.replace('/fleet/configuration/devices/', '/configuration/settings/'))
              })
              return null
            }
            return <Route exact path={path} element={<NavComponent />} key={path} />
          })}

          <Route path="/fleet/configuration/" element={<FleetOverview />} />
          <Route exact path="/configuration/preset" element={<ConfigurationSnippets />} />
          <Route
            exact
            path="/configuration/preset/detail/:snippetId/module/:moduleId/channel/:channelId"
            element={<ConfigurationSnippetDetail />}
          />
          <Route
            path="/configuration/preset/detail/:snippetId/module/:moduleId/"
            element={<ConfigurationSnippetDetail />}
          />
          <Route path="/configuration/preset/detail/:snippetId/:isNew?" element={<ConfigurationSnippetDetail />} />

          <Route exact path="/configuration/summary/:unitId" element={<ConfigurationSummary />} />
          {[
            '/configuration/settings/:unitId',
            '/configuration/settings/:unitId/module/:moduleId',
            '/configuration/settings/:unitId/module/:moduleId/channel/:channelId/:tab?/:activeTab?',
          ].map(path => {
            return <Route exact path={path} element={<ConfigurationSettings />} key={path} />
          })}
          <Route
            exact
            path="/configuration/hardware-setup/:unitId/module/:moduleId/channel/:channelId"
            element={<ConfigurationHardwareSetup />}
          />
          <Route
            exact
            path="/configuration/hardware-setup/:unitId/module/:moduleId/"
            element={<ConfigurationHardwareSetup />}
          />
          <Route exact path="/configuration/hardware-setup/:unitId" element={<ConfigurationHardwareSetup />} />
          <Route exact path="/configuration" element={<Navigate to="/configuration/devices" />} />
          <Route exact path="/configuration/devices" element={<Configuration />} />
          <Route
            path="/fleet/snippet/detail/:snippetId/module/:moduleId/channel/:channelId"
            element={<ConfigurationSnippetDetail />}
          />
          <Route path="/fleet/snippet/detail/:snippetId/module/:moduleId/" element={<ConfigurationSnippetDetail />} />
          <Route path="/fleet/snippet/detail/:snippetId/:isNew?" element={<ConfigurationSnippetDetail />} />
          <Route path="/fleet/my-equipment" element={<EquipmentInventory />} />
          {OAuth.isInNormalMode && [
            <Route
              path="/identity-management/user-management/users"
              exact
              element={
                <AclControl
                  readPermissions={[oAuthPermissions.kiconnectUsersRead]}
                  overrideComponent={<SbAccessDeniedPage />}
                >
                  <UsersPage />
                </AclControl>
              }
              key="route-user-management-users"
            />,
            <Route
              path="/identity-management/user-management/roles"
              exact
              element={
                <AclControl
                  readPermissions={[oAuthPermissions.kiconnectRolesRead]}
                  overrideComponent={<SbAccessDeniedPage />}
                >
                  <RolesPage />
                </AclControl>
              }
              key="route-user-management-roles"
            />,
            ...[
              '/identity-management/user-management/tenants',
              '/identity-management/user-management/tenants/:tenantId',
            ].map(path => {
              return (
                <Route
                  path={path}
                  exact
                  element={
                    <AclControl
                      readPermissions={[oAuthPermissions.kiconnectTenantsRead]}
                      overrideComponent={<SbAccessDeniedPage />}
                    >
                      <TenantsPage />
                    </AclControl>
                  }
                  key={path}
                />
              )
            }),
            <Route
              path="/identity-management/user-management/tenants/:tenantId/assigned"
              exact
              element={
                <AclControl
                  readPermissions={[oAuthPermissions.kiconnectTenantsRead]}
                  overrideComponent={<SbAccessDeniedPage />}
                >
                  <AssignedTenantsPage />
                </AclControl>
              }
              key="route-user-management-assigned-tenants"
            />,
            <Route
              path="/identity-management/oauth-management/clients"
              exact
              element={
                <AclControl
                  readPermissions={[oAuthPermissions.kiconnectClientsReadWrite]}
                  overrideComponent={<SbAccessDeniedPage />}
                >
                  <ClientsPage />
                </AclControl>
              }
              key="route-oauth-management-clients"
            />,
            ...['/identity-management/oauth-management/apis', '/identity-management/oauth-management/apis/:apiId'].map(
              path => {
                return (
                  <Route
                    path={path}
                    exact
                    element={
                      <AclControl
                        readPermissions={[oAuthPermissions.kiconnectApisReadWrite]}
                        overrideComponent={<SbAccessDeniedPage />}
                      >
                        <ApisPage />
                      </AclControl>
                    }
                    key={path}
                  />
                )
              },
            ),
            ...[
              '/identity-management/oauth-management/apis/:apiId/scopes',
              '/identity-management/oauth-management/apis/:apiId/scopes/:scopeId',
            ].map(path => {
              return (
                <Route
                  path={path}
                  exact
                  element={
                    <AclControl
                      readPermissions={[oAuthPermissions.kiconnectApisReadWrite]}
                      overrideComponent={<SbAccessDeniedPage />}
                    >
                      <ScopesPage />
                    </AclControl>
                  }
                  key={path}
                />
              )
            }),
            <Route
              path="/identity-management/applications"
              exact
              element={
                <AclControl
                  readPermissions={[oAuthPermissions.kiconnectApplicationsRead]}
                  overrideComponent={<SbAccessDeniedPage />}
                >
                  <ApplicationsPage />
                </AclControl>
              }
              key="route-oauth-management-applications"
            />,
            <Route
              path="/licensing/about"
              exact
              element={
                <AclControl
                  readPermissions={[licensingPermissions.licensesRead]}
                  overrideComponent={<SbAccessDeniedPage />}
                >
                  <LicensingAboutPage />
                </AclControl>
              }
              key="route-licensing-about"
            />,
            <Route
              path="/licensing/management"
              exact
              element={
                <AclControl
                  readPermissions={[licensingPermissions.licensesRead]}
                  overrideComponent={<SbAccessDeniedPage />}
                >
                  <ManagementPage />
                </AclControl>
              }
              key="route-licensing-management"
            />,
          ]}
          {['/iot-hub/devices', '/iot-hub/devices/:deviceId'].map(path => {
            return (
              <Route
                path={path}
                exact
                element={
                  <AclControl
                    readPermissions={[iotHubPermissions.kiconnectDevicesRead]}
                    overrideComponent={<SbAccessDeniedPage />}
                  >
                    <DevicesPage />
                  </AclControl>
                }
                key={path}
              />
            )
          })}
          {['/iot-hub/devices/:deviceId/resources', '/iot-hub/devices/:deviceId/resources/:resourcePath/*'].map(
            path => {
              return (
                <Route
                  path={path}
                  exact
                  element={
                    <AclControl
                      readPermissions={[iotHubPermissions.kiconnectDevicesRead]}
                      overrideComponent={<SbAccessDeniedPage />}
                    >
                      <ResourcesPage />
                    </AclControl>
                  }
                  key={path}
                />
              )
            },
          )}
          {['/iot-hub/devices/:deviceId/datasources', '/iot-hub/devices/:deviceId/datasources/:dataSourceId'].map(
            path => {
              return (
                <Route
                  path={path}
                  exact
                  element={
                    <AclControl
                      readPermissions={[iotHubPermissions.kiconnectDevicesRead]}
                      overrideComponent={<SbAccessDeniedPage />}
                    >
                      <DataSourcesPage />
                    </AclControl>
                  }
                  key={path}
                />
              )
            },
          )}

          <Route path="/iot-hub/about/how-to-use" exact element={<AboutIoTPage />} />
          <Route path="/iot-hub/about/info" exact element={<InfoIoTPage />} />
          <Route path="/iot-hub/notifications" exact element={<IoTNotificationsPage />} />
          <Route path="*" element={<SbNotFoundPage />} />
        </Routes>
      </App>
    </BrowserRouter>
  )
}

export default AppRoutes
