import React, { useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'
import { SbCheckbox } from 'skybase-ui/skybase-components/sb-checkbox'
import { getOAuthState } from 'skybase-oauth/utils'
import { AUTH_STATE_KEY_NAME } from 'skybase-oauth/oauth/constants'
import { messages as t } from '../home-page-i18n'
import { DEVELOPER_TOOLS_LS_KEY } from './constants'
import { useLocalStorage } from '@/hooks'

export const DeveloperToolsToggle = props => {
  const { formatMessage: _ } = useIntl()
  const { id: userId } = useSelector(state => getOAuthState(state)[AUTH_STATE_KEY_NAME])
  const [developerTools, setDeveloperTools] = useLocalStorage(DEVELOPER_TOOLS_LS_KEY, {})
  const devToolsEnabled = developerTools?.[userId] || false
  const devToolsVisible = typeof developerTools?.[userId] === 'boolean'

  const handleToggleDevTools = useCallback(() => {
    setDeveloperTools({ ...developerTools, [userId]: !devToolsEnabled })
  }, [developerTools, setDeveloperTools, devToolsEnabled, userId])

  useEffect(() => {
    const showDevTools = () => {
      if (!devToolsVisible) {
        handleToggleDevTools()
      }
    }

    SbEmitter.on('enable-dev-tools', showDevTools)

    return () => {
      SbEmitter.off('enable-dev-tools', showDevTools)
    }
  }, [handleToggleDevTools, devToolsVisible])

  if (!devToolsVisible) {
    return null
  }

  return (
    <SbCheckbox
      {...props}
      type="switch-control"
      id="developer-tools"
      onClick={handleToggleDevTools}
      checked={devToolsEnabled}
    >
      {_(t.developerTools)}
    </SbCheckbox>
  )
}
