/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  home: {
    id: 'configurationHardwareSetup.home',
    defaultMessage: 'Home',
  },
  configuration: {
    id: 'configurationHardwareSetup.configuration',
    defaultMessage: 'Configuration',
  },
  configurationDetail: {
    id: 'configurationHardwareSetup.configurationDetail',
    defaultMessage: 'Configuration detail',
  },
  deviceConfiguration: {
    id: 'configurationHardwareSetup.deviceConfiguration',
    defaultMessage: 'Device Configuration',
  },
})
