import {
  getIotHubDeviceDetailsState,
  getIotHubDeviceResourceDetailsState,
  getIotHubDeviceDataSourcesListState,
  getIotHubDeviceDataSourceDetailsState,
  getIotHubDevicesState,
} from '@/iot-hub/selectors'
import { ownershipStatuses } from '@/iot-hub/components/devices/constants'
import { ACQUISITION_CHANNEL, ACQUISITION_DATASOURCE } from './constants'

export const getSelectedDeviceDetailsStateForTour = state => {
  const { isSecured, ownershipStatus, id, resources } = getIotHubDeviceDetailsState(state)?.data || {}
  const acquisitionChannelResourceHref = resources?.find?.(resource =>
    resource.types.includes(ACQUISITION_CHANNEL),
  )?.href

  return {
    isSecured,
    ownershipStatus,
    selectedDeviceId: id,
    acquisitionChannelResourceHref,
  }
}

export const selectIsIotHubDeviceResourceDetailsState = state =>
  !!getIotHubDeviceResourceDetailsState(state)?.data?.resourceData?.rt?.includes(ACQUISITION_CHANNEL)

export const getAcquisitionDatasource = state => {
  const { id } =
    getIotHubDeviceDataSourcesListState(state)?.data?.find(datasource =>
      datasource.types.includes(ACQUISITION_DATASOURCE),
    ) || {}

  return {
    acquisitionDatasourceId: id,
  }
}

export const selectIsOwnableDeviceInTable = state => {
  const data = getIotHubDevicesState(state)?.list?.data

  if (data && data.length > 0) {
    return !!data.find(device => device.isSecured && device.ownershipStatus === ownershipStatuses.READY_TO_BE_OWNED)
  }

  return false
}

export const getSelectedDatasource = appState => {
  const selectedDatasource = getIotHubDeviceDataSourceDetailsState(appState)?.data || {}
  const isSelectedAcquisitionDatasource = !!selectedDatasource?.types?.includes(ACQUISITION_DATASOURCE)
  const { state, transfer } = selectedDatasource

  return {
    isSelectedAcquisitionDatasource,
    state,
    transfer,
  }
}
