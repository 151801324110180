/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  channelIndex: {
    id: 'addComponentModal.channelIndex',
    defaultMessage: 'Channel {index}',
  },
  cancel: {
    id: 'addComponentModal.cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'addComponentModal.save',
    defaultMessage: 'Save',
  },
  validationError: {
    id: 'addComponentModal.validationError',
    defaultMessage: 'Validation error',
  },
  addSensor: {
    id: 'addComponentModal.addSensor',
    defaultMessage: 'Add sensor',
  },
  addCable: {
    id: 'addComponentModal.addCable',
    defaultMessage: 'Add cable',
  },
  channelChannelIndex: {
    id: 'addComponentModal.channelChannelIndex',
    defaultMessage: 'Channel {channelIndex}',
  },
  myEquipment: {
    id: 'addComponentModal.myEquipment',
    defaultMessage: 'My equipment',
  },
  addFromCatalog: {
    id: 'addComponentModal.addFromCatalog',
    defaultMessage: 'Add from catalog',
  },
  componentsOfChannelChannelIndexChanged: {
    id: 'addComponentModal.componentsOfChannelChannelIndexChanged',
    defaultMessage: 'Components of channel {channelIndex} changed',
  },
  systemWasUnableToUpdateComponents: {
    id: 'addComponentModal.systemWasUnableToUpdateComponents',
    defaultMessage: 'System was unable to update components',
  },
})
