export const tourGuides = {
  DEVICES: 'devices',
  RESOURCES: 'resources',
  DATA_SOURCES: 'data-sources',
}

export const ACQUISITION_CHANNEL = 'x.com.kistler.acquisition.channel'

export const ACQUISITION_DATASOURCE = 'x.com.kistler.acquisition.datasource'

export const stepIds = {
  UPDATE_RESOURCE: 'update-resource',
  OWN_DEVICE: 'own-device',
  RESOURCES_OVERVIEW: 'resources-overview',
  DATASOURCE_TABLE: 'datasource-table',
}
