import {
  showErrorToast as showErrorToastAction,
  showWarningToast as showWarningToastAction,
  showSuccessToast as showSuccessToastAction,
  showInfoToast as showInfoToastAction,
} from 'skybase-ui/skybase-components/sb-toastr'
import { store } from '@/stores'

/**
 * Display an error toast.
 * @param {string} message
 * @param {string} [title]
 * @param {Object} [options] Optional configuration for the toast. @see {@link http://skybase.kistler.com/ui/app/notifications/toasts}
 */
export const showErrorToast = (message, title, options) =>
  store.dispatch(showErrorToastAction({ message, title }, options))

/**
 * Display a warning toast.
 * @param {string} message
 * @param {string} [title]
 * @param {Object} [options] Optional configuration for the toast. @see {@link http://skybase.kistler.com/ui/app/notifications/toasts}
 */
export const showWarningToast = (message, title, options) =>
  store.dispatch(showWarningToastAction({ message, title }, options))

/**
 * Display a success toast.
 * @param {string} message
 * @param {string} [title]
 * @param {Object} [options] Optional configuration for the toast. @see {@link http://skybase.kistler.com/ui/app/notifications/toasts}
 */
export const showSuccessToast = (message, title, options) =>
  store.dispatch(showSuccessToastAction({ message, title }, options))

/**
 * Display an info toast.
 * @param {string} message
 * @param {string} [title]
 * @param {Object} [options] Optional configuration for the toast. @see {@link http://skybase.kistler.com/ui/app/notifications/toasts}
 */
export const showInfoToast = (message, title, options) =>
  store.dispatch(showInfoToastAction({ message, title }, options))
