import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  cannotWriteSettingsToDeviceWhileRunningMeasurement: {
    id: 'Cannot write settings to device while running measurement',
    defaultMessage: 'Cannot write settings to device while running measurement',
  },
  cannotWriteSettingsToDeviceWhileFactoryResetIsInProgress: {
    id: 'Cannot write settings to device while factory reset is in progress',
    defaultMessage: 'Cannot write settings to device while factory reset is in progress',
  },
  projectDeviceMustBeOnlineBeforeSync: {
    id: 'Project device must be online before sync',
    defaultMessage: 'Project device must be online before sync',
  },
  writeSettingsToDevice: {
    id: 'Write Settings to Device',
    defaultMessage: 'Write Settings to Device',
  },
  writeToDevice: {
    id: 'Write to Device',
    defaultMessage: 'Write to Device',
  },
  cantWriteSettingsToDeviceWhileRunningMeasurement: {
    id: "Can't write settings to device while running measurement",
    defaultMessage: "Can't write settings to device while running measurement",
  },
  syncAlreadyInProgress: {
    id: 'Sync already in progress',
    defaultMessage: 'Sync already in progress',
  },
  thereIsNothingToSync: {
    id: 'There is nothing to sync',
    defaultMessage: 'There is nothing to sync',
  },
  completed: {
    id: 'Completed',
    defaultMessage: 'Completed',
  },
  writing: {
    id: 'Writing',
    defaultMessage: 'Writing',
  },
  failed: {
    id: 'Failed',
    defaultMessage: 'Failed',
  },
  dirtyDeviceNeedsToBeValidBeforeSynchronisation: {
    id: 'Please correct all the errors before synchronisation',
    defaultMessage: 'Please correct all the errors before synchronisation',
  },
  deviceOffline: {
    id: 'Device offline',
    defaultMessage: 'Device offline',
  },
  writeSettingsFailed: {
    id: 'Write settings failed',
    defaultMessage: 'Write settings failed',
  },
  systemIsNotResponsiveRightNowTryAgainLaterPlease: {
    id: 'System is not responsive right now, try again later please',
    defaultMessage: 'System is not responsive right now, try again later please',
  },
  youNeedToEnsureDeviceIsUpdatedToLatestVersionToProceed: {
    id: 'You need to ensure device is updated to latest version to proceed',
    defaultMessage: 'You need to ensure device is updated to latest version to proceed',
  },
  deviceStudioIncompatibleUnknownVersion: {
    id: "Device and KiStudioLab are incompatible. System wasn't able to detect versions",
    defaultMessage: "Device and KiStudioLab are incompatible. System wasn't able to detect versions",
  },
  allowStopRunningMeasurementLongDescription: {
    id: 'writeSettingsButton.anyRunningMeasurementOnTheDeviceCanBeStoppedTemporarilyForWritingTheConfigurationToTheDevice',
    defaultMessage:
      'Any running measurement on the device can be stopped temporarily for writing the configuration to the device',
  },
  stopMeasurementBefore: {
    id: 'writeSettingsButton.stopMeasurementBefore',
    defaultMessage: 'Stop measurement before',
  },
  runningMeasurementWillBeStopped: {
    id: 'writeSettingsButton.currentlyRunningMeasurementWillBeStoppedWhileNewConfigurationIsBeingWrittenToDevice',
    defaultMessage: 'Currently running measurement will be stopped while new configuration is being written to device.',
  },
})
