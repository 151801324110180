import { capitalizeFirstLetter } from '@/utils'

// https://auth.devel.hub.kistler.com will return Devel Hub
// https://auth-test.devel.hub.kistler.com will return Test Devel Hub
// https://auth.hub.kistler.com will return Hub
// https://auth-test.hub.kistler.com will return Test Hub
export const getEnvironmentNameFromUrl = url => {
  const address = new URL(url)
  return address.host
    .replace('.kistler.com', '')
    .replace('auth.', '')
    .replace('auth-', '')
    .replace('-', '.')
    .split('.')
    .map(s => capitalizeFirstLetter(s))
    .join(' ')
}
