import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'

import { hasAccess } from 'skybase-oauth/auth'
import { getOAuthState } from 'skybase-oauth/utils'
import { AUTH_STATE_KEY_NAME } from 'skybase-oauth/oauth/constants'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbMenuItem, SbMenu } from 'skybase-ui/skybase-components/sb-menu'
import { SB_LEFT_COLUMN_STATE_NAME } from 'skybase-ui/skybase-components/sb-left-column'

import { filterNotVisitedNotifications } from '@/iot-hub/components/notifications/utils'
import { getIotHubNotifications } from '@/iot-hub/selectors'
import { replaceDuplicateSlashes } from '@/utils'
import { MENU_AUTO_COLLAPSING_WIDTH, getMenuStructure, badgeKeys } from './menu-constants'
import { getBadgeNumber, filterByAppMode, withDevToolsEnabled } from './utils'

const { IOT_HUB } = badgeKeys

export class Menu extends Component {
  static propTypes = {
    currentUrl: PropTypes.string.isRequired,
    onMenuItemClick: PropTypes.func.isRequired,
    leftMenuIsCollapsed: PropTypes.bool,
    intl: intlShape.isRequired,
    isSideMenu: PropTypes.bool,
    notificationCounts: PropTypes.shape({
      [IOT_HUB]: PropTypes.number,
    }),
    permissions: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
    devToolsEnabled: PropTypes.bool,
  }

  static defaultProps = {
    leftMenuIsCollapsed: false,
    isSideMenu: false,
    notificationCounts: {
      [IOT_HUB]: 0,
    },
    permissions: [],
    className: null,
    devToolsEnabled: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      isCollapsed: false,
    }

    this.handleResize = this.handleResize.bind(this)
    this.filteredMenu = this.getOnlyAccessibleItems()
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)

    this.handleResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  getMenuItemProps = title => {
    const { isCollapsed } = this.state
    const { currentUrl, onMenuItemClick, leftMenuIsCollapsed } = this.props

    return {
      title,
      currentUrl,
      onClick: onMenuItemClick,
      isCollapsed: leftMenuIsCollapsed || isCollapsed,
    }
  }

  // Remove all not accessible menu items and sub items from the menuStructure array
  getOnlyAccessibleItems = () => {
    return getMenuStructure()
      .filter(filterByAppMode)
      .filter(this.filterNoAccessibleItems)
      .filter(this.filterHiddenItems)
      .reduce((acc, baseMenu) => {
        if (!baseMenu.menu) {
          return acc.concat(baseMenu)
        }

        const menu = baseMenu.menu.filter(this.filterNoAccessibleItems).filter(this.filterHiddenItems)

        if (menu.length > 0) {
          const menuSubMenu = menu.reduce((acc2, firstLevelMenu) => {
            if (!firstLevelMenu.subMenu) {
              return acc2.concat(firstLevelMenu)
            }

            const subMenu = firstLevelMenu.subMenu.filter(this.filterNoAccessibleItems).filter(this.filterHiddenItems)

            if (subMenu.length > 0) {
              return acc2.concat({
                ...firstLevelMenu,
                subMenu,
              })
            }

            // If there is no menu inside the filtered subMenu, remove the subMenu from the menuStructure
            return acc2
          }, [])

          if (menuSubMenu.length > 0) {
            return acc.concat({
              ...baseMenu,
              menu: menuSubMenu,
            })
          }

          // If there is no menu inside the filtered menu, remove the menu from the menuStructure
          return acc
        }

        // If there is no menu inside the filtered baseMenu, remove the baseMenu from the menuStructure
        return acc
      }, [])
  }

  isValidBaseUrl = () => {
    const { currentUrl } = this.props

    return !!this.filteredMenu
      .filter(this.filterDevTools)
      .find(mainMenu => mainMenu.url !== '' && currentUrl.indexOf(mainMenu.url) !== -1)
  }

  handleResize() {
    const { isCollapsed } = this.state
    const width = window.innerWidth

    // the constant MENU_AUTO_COLLAPSING_WIDTH is the breakpoint for the collapse/expand by CSS media query.
    if (width <= MENU_AUTO_COLLAPSING_WIDTH && !isCollapsed) {
      this.setState({ isCollapsed: true })
    } else if (width > MENU_AUTO_COLLAPSING_WIDTH && isCollapsed) {
      this.setState({ isCollapsed: false })
    }
  }

  // Filter which returns only items which the user has permissions to
  filterNoAccessibleItems = item => {
    if (!item.permission) return true

    const { permissions } = this.props
    return hasAccess(permissions, item.permission)
  }

  filterHiddenItems = item => !item.hidden

  filterDevTools = item => {
    const { devToolsEnabled } = this.props
    return !item.devTool || devToolsEnabled
  }

  renderSubMenu = (subMenu, baseUrl, autoSelectFirstMenu) => {
    const {
      intl: { formatMessage: _ },
    } = this.props

    return subMenu.map((menu, index) => {
      const menuItemUrl = baseUrl.indexOf(menu.url) !== -1 ? `${baseUrl}` : `${baseUrl}/${menu.url}`
      const autoSelectFirstSubMenu = index === 0 && autoSelectFirstMenu
      return (
        <SbMenuItem
          id={menu.id}
          key={`submenu-item-${menuItemUrl}`}
          url={menuItemUrl}
          {...this.getMenuItemProps(_(menu.title))}
          handleActive={(url, currentUrl) => currentUrl.indexOf(url) !== -1 || autoSelectFirstSubMenu}
        />
      )
    })
  }

  renderMenu = () => {
    const {
      intl: { formatMessage: _ },
      notificationCounts,
      currentUrl,
    } = this.props
    const findMenu = this.filteredMenu
      .filter(this.filterDevTools)
      .find(mainMenu => mainMenu.url !== '' && currentUrl.indexOf(mainMenu.url) !== -1)

    let renderedStructure = ''
    if (findMenu && findMenu.menu) {
      const { menu: currentMenu, url: baseUrl } = findMenu

      renderedStructure = currentMenu.map((menu, index) => {
        const firstSubMenuUrl = menu?.subMenu?.[0]?.url
        const autoSelectFirstMenu =
          index === 0 && menu.subMenu && replaceDuplicateSlashes(currentUrl, '').indexOf(firstSubMenuUrl) !== -1
        const menuUrl =
          firstSubMenuUrl && menu.url.indexOf(firstSubMenuUrl) === -1 ? `${menu.url}/${menu.subMenu[0].url}` : menu.url
        const menuItemUrl = replaceDuplicateSlashes(`/${baseUrl}/${menuUrl}`)
        const subMenuBaseUrl = replaceDuplicateSlashes(`/${baseUrl}/${menu.url}`)

        const badge = getBadgeNumber(menu.badgeKey, notificationCounts)
        return (
          <SbMenuItem
            id={menu.id}
            key={`menu-item-${menuItemUrl}`}
            url={menuItemUrl}
            icon={<i className={menu.icon} />}
            {...this.getMenuItemProps(_(menu.title))}
            handleActive={(url, curUrl) => curUrl.indexOf(menu.url) !== -1 || autoSelectFirstMenu}
            badge={badge}
          >
            {menu.subMenu && this.renderSubMenu(menu.subMenu, subMenuBaseUrl, autoSelectFirstMenu)}
          </SbMenuItem>
        )
      })

      return <SbMenu>{renderedStructure}</SbMenu>
    }

    return null
  }

  // Base Menu is the primary menu for the whole application. It can be used as a side menu when the application is in "nested" mode.
  renderBaseMenu = () => {
    const {
      intl: { formatMessage: _ },
      currentUrl: activeUrl,
      notificationCounts,
      isSideMenu,
      className,
    } = this.props

    const renderedStructure = this.filteredMenu.filter(this.filterDevTools).map((menu, index) => {
      // If there is a menu element with url param, use that URL with combination of the parent URL. Otherwise, use the parent URL.
      const firstMenu = menu?.menu?.[0]?.url
      const firstSubMenu = menu?.menu?.[0]?.subMenu?.[0]?.url

      const menuUrl =
        firstSubMenu && firstMenu && firstMenu.indexOf(firstSubMenu) === -1 ? `${firstMenu}/${firstSubMenu}` : firstMenu
      const menuItemUrl = replaceDuplicateSlashes(menuUrl ? `/${menu.url}/${menuUrl}` : `/${menu.url}`)

      const menuClassName = classNames({ dark: isSideMenu, 'first-dark': isSideMenu && index === 0 }, className)
      const badge = activeUrl.indexOf(menu.url) === -1 ? getBadgeNumber(menu.badgeKey, notificationCounts) : null
      return (
        <SbMenuItem
          id={menu.id}
          key={`base-menu-${menuItemUrl}`}
          url={menuItemUrl}
          icon={<i className={menu.icon} />}
          {...this.getMenuItemProps(_(menu.title))}
          {...(isSideMenu ? { isCollapsed: isSideMenu } : {})}
          className={menuClassName}
          hoverItemsClassName={menuClassName}
          handleActive={(url, currentUrl) => currentUrl.split('/')?.[1] === menuItemUrl.split('/')?.[1]}
          badge={badge}
        />
      )
    })

    return <SbMenu className="dark">{renderedStructure}</SbMenu>
  }

  render() {
    const { currentUrl, isSideMenu } = this.props
    const trimCurrentUrl = replaceDuplicateSlashes(currentUrl, '')

    // @todo implement the hiddenSideMenu parameter from menu-constants.js - menuStructure
    if (trimCurrentUrl === '' || trimCurrentUrl === 'about' || isSideMenu || !this.isValidBaseUrl()) {
      return this.renderBaseMenu()
    }

    return this.renderMenu()
  }
}

const mapStateToProps = state => {
  const { isCollapsed } = state[SB_LEFT_COLUMN_STATE_NAME]
  const iotHubNotificationsCount = getIotHubNotifications(state)?.filter?.(filterNotVisitedNotifications)?.length
  const { permissions } = getOAuthState(state)[AUTH_STATE_KEY_NAME] || {}

  return {
    leftMenuIsCollapsed: isCollapsed,
    notificationCounts: {
      [IOT_HUB]: iotHubNotificationsCount,
    },
    permissions,
  }
}

export default injectIntl(withDevToolsEnabled(connect(mapStateToProps)(Menu)))
