import React, { memo, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { SbButton } from 'skybase-ui/skybase-components/sb-button'

import { tourGuides } from './constants'

const { DEVICES, RESOURCES, DATA_SOURCES } = tourGuides

export const CustomHelper = memo(
  ({
    current,
    content,
    totalSteps,
    gotoStep,
    close,
    nextButtonEnabled,
    currentTourGuide,
    onSkip,
    onContinueToNextTour,
    title = null,
  }) => {
    const isLastStep = current >= totalSteps - 1
    const isFirstStep = current === 0
    const isEndOfTour = isLastStep && currentTourGuide === DATA_SOURCES

    const nextButtonText = useMemo(() => {
      if (isFirstStep) return 'Start Tour'
      if (isEndOfTour) return 'Finish Tour'
      if (isLastStep) return 'Go to next Tour'
      return 'Next'
    }, [isFirstStep, isLastStep, isEndOfTour])

    const handleOnNext = () => {
      if (isLastStep) {
        onContinueToNextTour()
      } else {
        gotoStep(current + 1)
      }
    }

    const computedTitle = useMemo(() => {
      if (title) {
        return title
      }

      // Default fallback titles if not set
      switch (currentTourGuide) {
        case RESOURCES:
          return 'Resources'
        case DATA_SOURCES:
          return 'Data Sources'
        default:
          return 'Welcome to KiConnect Client!'
      }
    }, [title, currentTourGuide])

    const renderSkipButton = useCallback(() => {
      return !isLastStep ? (
        <span id="tour-skip-button" onClick={onSkip}>
          Skip Tour
        </span>
      ) : (
        <span id="tour-finish-button" onClick={close}>
          Finish Tour
        </span>
      )
    }, [isLastStep, onSkip, close])

    return (
      <main className="tour-container">
        <span id="tour-close" className="sbi-close" onClick={close} />
        <header id="tour-title">{computedTitle}</header>
        <div className="tour-content">{content}</div>
        <footer className="fl-row fl-justify-sb fl-align-items-center sb-width-100pct">
          {!isLastStep ? (
            <span id="tour-steps" className="steps">
              Step {current + 1} of {totalSteps}
            </span>
          ) : (
            <div />
          )}

          <div className="fl-row fl-align-items-center">
            {!isEndOfTour && renderSkipButton()}
            <SbButton
              id="tour-next-button"
              className={classNames('transparent', { finish: isLastStep })}
              onClick={handleOnNext}
              disabled={!nextButtonEnabled}
            >
              {nextButtonText}
            </SbButton>
          </div>
        </footer>
      </main>
    )
  },
)

CustomHelper.propTypes = {
  current: PropTypes.number.isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.func]).isRequired,
  totalSteps: PropTypes.number.isRequired,
  gotoStep: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  nextButtonEnabled: PropTypes.bool.isRequired,
  currentTourGuide: PropTypes.oneOf([DEVICES, RESOURCES, DATA_SOURCES]).isRequired,
  onSkip: PropTypes.func.isRequired,
  onContinueToNextTour: PropTypes.func.isRequired,
  title: PropTypes.string,
}
