import axios from 'axios'
import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { getChainCatalogEntry } from '@/fleet-configuration/data-fleet/chain-catalog/chain-catalog-selectors'
import { getStudioAPIHost } from '@/utils/url'
import { equipmentApi } from '@/fleet-configuration/equipment/constants'
import { uniq } from 'lodash'

export const SET_CHAIN_CATALOG_COMPONENT = getActionName('SET_CHAIN_CATALOG_COMPONENT')
export const setChainCatalogComponent = element => {
  return createAction(SET_CHAIN_CATALOG_COMPONENT, element)
}

const chainCatalogRequestCache = {}
export const tryLoadChainCatalog = typeNumber => (dispatch, getState) => {
  const state = getState()
  const trimmedTypeNumber = typeNumber.trim().toUpperCase()
  if (trimmedTypeNumber.length === 0) {
    return null
  }
  const catalogEntry = getChainCatalogEntry(state, trimmedTypeNumber)
  if (catalogEntry) {
    // for some reason if I wrote it as async function, I would get promise when returning this plain object too. So I am writing old non-async code
    return catalogEntry
  }

  let response
  if (chainCatalogRequestCache[trimmedTypeNumber]) {
    response = chainCatalogRequestCache[trimmedTypeNumber]
  } else {
    response = axios.get(
      `${getStudioAPIHost()}${equipmentApi.COMPONENTS_TYPE}?moduleType=${encodeURIComponent(trimmedTypeNumber)}`,
      {
        customErrorHandler: () => {}, // suppress error handling - bad request is part of "normal" workflow and is handled explicitly
      },
    )
    chainCatalogRequestCache[trimmedTypeNumber] = response
  }
  // this is the only async part that should return promise, so mark it as async explicitly by directly returning promise here
  return new Promise((resolve, reject) => {
    response.then(
      responseValue => {
        delete chainCatalogRequestCache[trimmedTypeNumber]
        const chainCatalogComponent = { id: typeNumber, type: typeNumber, ...responseValue.data }
        dispatch(setChainCatalogComponent(chainCatalogComponent))
        resolve(responseValue)
      },
      rejectReason => {
        delete chainCatalogRequestCache[trimmedTypeNumber]
        dispatch(setChainCatalogComponent({ id: typeNumber, type: typeNumber }))
        reject(rejectReason)
      },
    )
  })
}

export const tryLoadChainCatalogForAllChainItems =
  chain =>
  (dispatch, ...rest) =>
    uniq(
      chain.reduce((acc, chainEntry) => {
        if (chainEntry.sensor?.typeNumber) {
          acc.push(chainEntry.sensor.typeNumber)
        }
        if (chainEntry.cable?.typeNumber) {
          acc.push(chainEntry.cable.typeNumber)
        }
        return acc
      }, []),
    ).forEach(typeNumberToFetch => {
      tryLoadChainCatalog(typeNumberToFetch)(dispatch, ...rest)
    })
