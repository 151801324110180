// TODO: get rid of this code - use websockets that are already defined in websocket-client.js (it does the same thing)
import { v4 } from 'uuid'
import ReconnectingWebSocket from 'reconnecting-websocket'
import { OAuth } from 'skybase-oauth/oauth'

export const reconnectingWebSocketFactory = url => {
  const socketOptions = {
    maxReconnectionDelay: 10000,
    minReconnectionDelay: 500,
    reconnectionDelayGrowFactor: 3,
    connectionTimeout: 10000,
  }
  const { accessToken } = OAuth.oAuthHandler.getAccessToken()
  if (accessToken) {
    const httpsFlagCookie = `SameSite=Strict;${location.protocol.includes('s') ? ' Secure; ' : ''}`
    const maxAge = socketOptions.maxReconnectionDelay / 1000 + 1
    document.cookie = `auth_token=${accessToken}; max-age=${maxAge}; path=/; ${httpsFlagCookie}`
  }
  const socket = new ReconnectingWebSocket(url, [])
  // default error handler (otherwise app crashes when ANY socket error occurs)
  socket.addEventListener('error', err => {
    console.error('Socket error', err)
    const { accessToken: newToken } = OAuth.oAuthHandler.getAccessToken()
    if (newToken) {
      const httpsFlagCookie = location.protocol.includes('s') ? 'secure; ' : ''
      const maxAge = socketOptions.maxReconnectionDelay / 1000 + 1 // 1 more second than max reconnection time
      document.cookie = `auth_token=${newToken}; max-age=${maxAge}; path=/; ${httpsFlagCookie}`
    }
  })
  return socket
}

export const sendInitialSocketRequest = (socket, additionalData = {}) => {
  if (sessionStorage) {
    let clientId = sessionStorage.getItem('wsEventClientId')
    if (!clientId) {
      clientId = v4()
      sessionStorage.setItem('wsEventClientId', clientId)
    }

    const { accessToken } = OAuth.oAuthHandler.getAccessToken()
    socket.send(JSON.stringify({ accessToken }))
    socket.send(JSON.stringify({ ...additionalData, clientId }))
  }
}

export const liveWebSocketFactory = url => {
  const socket = reconnectingWebSocketFactory(url)
  let pingTimer = null

  socket.addEventListener('open', () => {
    pingTimer = setInterval(() => {
      socket.send('{"kind":"ping"}')
    }, 10000)
  })

  socket.addEventListener('close', () => {
    if (pingTimer) {
      clearInterval(pingTimer)
    }
  })

  return socket
}
