/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  sensor: {
    id: 'chainPanel.sensor',
    defaultMessage: 'Sensor',
  },
  sensorName: {
    id: 'chainPanel.sensorName',
    defaultMessage: 'Sensor name',
  },
  serialNumber: {
    id: 'chainPanel.serialNumber',
    defaultMessage: 'Serial number',
  },
  typeNumber: {
    id: 'chainPanel.typeNumber',
    defaultMessage: 'Type number',
  },
  measurand: {
    id: 'chainPanel.measurand',
    defaultMessage: 'Measurand',
  },
  class: {
    id: 'chainPanel.class',
    defaultMessage: 'Class',
  },
  naturalFrequency: {
    id: 'chainPanel.naturalFrequency',
    defaultMessage: 'Natural frequency',
  },
  range: {
    id: 'chainPanel.range',
    defaultMessage: 'Range',
  },
  cable: {
    id: 'chainPanel.cable',
    defaultMessage: 'Cable',
  },
  hz: {
    id: 'chainPanel.hz',
    defaultMessage: 'Hz',
  },
  cableName: {
    id: 'chainPanel.cableName',
    defaultMessage: 'Cable name',
  },
  toEnableThisFeaturePleaseAddASensorToTheChannel: {
    id: 'chainPanel.toEnableThisFeaturePleaseAddASensorToTheChannel',
    defaultMessage: 'To enable this feature, please add a sensor to the channel.',
  },
  toEnableThisFeaturePleaseAddACableToTheChannel: {
    id: 'chainPanel.toEnableThisFeaturePleaseAddACableToTheChannel',
    defaultMessage: 'To enable this feature, please add a cable to the channel',
  },
})
