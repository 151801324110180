import { curry } from 'lodash'
import { createError } from './create-alert'

export const required =
  (message, parameters = {}) =>
  value => {
    if (!value && value !== 0) {
      return createError(message, {}, parameters)
    }
    return undefined
  }

const interval = (value, min, max, message, isOpenInterval) => {
  if (!isNaN(value)) {
    const fvalue = parseFloat(value)
    const fmin = parseFloat(min)
    const fmax = parseFloat(max)
    if (isOpenInterval ? fvalue <= fmin || fvalue >= fmax : fvalue < fmin || fvalue > fmax) {
      return createError(
        message,
        {},
        {
          max: fmax.toString(),
          min: fmin.toString(),
        },
      )
    }
  }
  return undefined
}

export const closedInterval = curry((message, min, max, value) => interval(value, min, max, message))

export const openInterval = curry((message, min, max, value) => interval(value, min, max, message, true))

const greater = (value, otherValue, message, includeOther, messageArgs = {}) => {
  if (!isNaN(value)) {
    const fvalue = parseFloat(value)
    const fotherValue = parseFloat(otherValue)
    if (includeOther ? fvalue <= fotherValue : fvalue < fotherValue) {
      return createError(
        message,
        {},
        {
          otherValue,
          ...messageArgs,
        },
      )
    }
  }
  return undefined
}

export const greaterEqualThan =
  (message, otherValue, messageArgs = {}) =>
  value =>
    greater(value, otherValue, message, false, messageArgs)

export const greaterThan =
  (message, otherValue, messageArgs = {}) =>
  value =>
    greater(value, otherValue, message, true, messageArgs)

const less = (value, otherValue, message, includeOther, messageArgs = {}) => {
  if (!isNaN(value)) {
    const fvalue = parseFloat(value)
    const fotherValue = parseFloat(otherValue)
    if (includeOther ? fvalue >= fotherValue : fvalue > fotherValue) {
      return createError(
        message,
        {},
        {
          otherValue,
          ...messageArgs,
        },
      )
    }
  }
  return undefined
}

export const lessEqualThan =
  (message, otherValue, messageArgs = {}) =>
  value =>
    less(value, otherValue, message, false, messageArgs)

export const lessThan =
  (message, otherValue, messageArgs = {}) =>
  value =>
    less(value, otherValue, message, true, messageArgs)

export const isNumber = curry((message, value) => {
  if (isNaN(value)) {
    return createError(message)
  }
  return undefined
})

export const notEqual = curry((message, otherValue, value) => {
  if (String(value) === String(otherValue)) {
    return createError(message)
  }
  return undefined
})

export const maxLength = curry((message, length, value) => {
  if (value && value.length > length) {
    return createError(
      message,
      {},
      {
        otherValue: length,
      },
    )
  }
  return undefined
})

export const email = curry((message, value) => {
  if (!value || !/.+@.+\..+/.test(value)) {
    return createError(message)
  }
  return null
})
