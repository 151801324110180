/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  failedToLoadDeviceBackups: {
    id: 'backupConfigurationModal.failedToLoadDeviceBackups',
    defaultMessage: 'Failed to load device backups',
  },
  backupConfigurationOverview: {
    id: 'backupConfigurationModal.backupConfigurationOverview',
    defaultMessage: 'Backup Configuration Overview',
  },
  createBackup: {
    id: 'backupConfigurationModal.createBackup',
    defaultMessage: 'Create Backup',
  },
  thereAreNoBackupsInSystemYet: {
    id: 'backupConfigurationModal.thereAreNoBackupsInSystemYet',
    defaultMessage: 'There are no backups in system yet.',
  },
  clickCreateBackupButtonAtTheTopToCreateYourFirst: {
    id: 'backupConfigurationModal.clickCreateBackupButtonAtTheTopToCreateYourFirst',
    defaultMessage: 'Click {CREATE_BACKUP} button at the top to create your first',
  },
  newBackupCreated: {
    id: 'backupConfigurationModal.newBackupCreated',
    defaultMessage: 'New Backup Created',
  },
  name: {
    id: 'backupConfigurationModal.name',
    defaultMessage: 'Name',
  },
  lastUsed: {
    id: 'backupConfigurationModal.lastUsed',
    defaultMessage: 'Last used',
  },
  yourLicenseDoesntSupportMoreThanTwoBackups: {
    id: 'backupConfigurationModal.yourLicenseDoesntSupportMoreThanTwoBackups',
    defaultMessage: "Your license doesn't support more than two backups.",
  },
  upgradeYourLicenseToGetMore: {
    id: 'backupConfigurationModal.upgradeYourLicenseToGetMore',
    defaultMessage: 'Upgrade your license to get more',
  },
  thisActionCannotBeUndone: {
    id: 'backupConfigurationModal.thisActionCannotBeUndone',
    defaultMessage: 'This action cannot be undone.',
  },
  restoreBackedUpConfiguration: {
    id: 'backupConfigurationConfirm.restoreBackedUpConfiguration',
    defaultMessage: 'Restore backed up configuration',
  },
  deleteBackup: {
    id: 'backupConfigurationModal.deleteBackup',
    defaultMessage: 'Delete backup',
  },
  deleteDeviceConfiguration: {
    id: 'backupConfigurationModal.deleteDeviceConfiguration',
    defaultMessage: 'Delete device configuration?',
  },
  areYouSureYouWantToDeleteConfiguration: {
    id: 'backupConfigurationModal.areYouSureYouWantToDeleteConfiguration',
    defaultMessage: 'Are you sure you want to delete configuration?',
  },
  delete: {
    id: 'backupConfigurationModal.delete',
    defaultMessage: 'Delete',
  },
  deviceBackupDeleted: {
    id: 'backupConfigurationModal.deviceBackupDeleted',
    defaultMessage: 'Device backup deleted',
  },
})
