import PropTypes from 'prop-types'

import { deviceStatuses, cloudProvisioningStatuses, ownershipStatuses } from './constants'
import {
  dataSourceStates,
  supportedTransferMethods,
  transferFormObject,
  kafkaTransferFormObject,
  zeroMQTransferFormObject,
} from './datasources/constants'

const { ENCODING, ENDPOINTS, TOPIC, TIME_TRESHOLD, MESSAGE_TRESHOLD } = transferFormObject
const { BROKER_LIST, TOPIC_NAME, TRESHOLD_MESSAGE_COUNT, TRESHOLD_LINGER_MS } = kafkaTransferFormObject
const { ENDPOINT, TRESHOLD_FRAME_COUNT, TRESHOLD_TIME_MS } = zeroMQTransferFormObject

export const deviceResourceShape = PropTypes.shape({
  deviceId: PropTypes.string,
  href: PropTypes.string,
  interfaces: PropTypes.arrayOf(PropTypes.string),
  types: PropTypes.arrayOf(PropTypes.string),
  if: PropTypes.arrayOf(PropTypes.string),
  rt: PropTypes.arrayOf(PropTypes.string),
})

export const deviceShape = PropTypes.shape({
  cloudProvisioningStatus: PropTypes.oneOf(Object.values(cloudProvisioningStatuses)),
  endpoints: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  isSecured: PropTypes.bool,
  manufacturerName: PropTypes.string,
  modelNumber: PropTypes.string,
  name: PropTypes.string,
  ownerId: PropTypes.string,
  serialNumber: PropTypes.string,
  status: PropTypes.oneOf(Object.values(deviceStatuses)),
  types: PropTypes.arrayOf(PropTypes.string),
  resources: PropTypes.arrayOf(deviceResourceShape),
  ownershipStatus: PropTypes.oneOf(Object.values(ownershipStatuses)),
  protocolIndependentId: PropTypes.string,
})

export const deviceDataSourceShape = PropTypes.shape({
  types: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  deviceId: PropTypes.string,
  supportedMethods: PropTypes.arrayOf(PropTypes.oneOf(Object.values(supportedTransferMethods))),
  supportedEncodings: PropTypes.arrayOf(PropTypes.string),
  state: PropTypes.oneOf(Object.values(dataSourceStates)),
  name: PropTypes.string,
  encoding: PropTypes.string,
  description: PropTypes.string,
  transfer: PropTypes.oneOf([...Object.values(supportedTransferMethods), '']),
})

export const dataTrasnferConfigurationShape = PropTypes.shape({
  [ENDPOINTS]: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  [TOPIC]: PropTypes.string,
  [TIME_TRESHOLD]: PropTypes.number,
  [MESSAGE_TRESHOLD]: PropTypes.number,
  [ENCODING]: PropTypes.string,
})

export const dataTransferKafkaConfigurationShape = PropTypes.shape({
  [BROKER_LIST]: PropTypes.arrayOf(PropTypes.string),
  [TOPIC_NAME]: PropTypes.string,
  [TRESHOLD_MESSAGE_COUNT]: PropTypes.number,
  [TRESHOLD_LINGER_MS]: PropTypes.number,
  [ENCODING]: PropTypes.string,
})

export const dataTransferZeroMQConfigurationShape = PropTypes.shape({
  [ENDPOINT]: PropTypes.string,
  [TOPIC_NAME]: PropTypes.string,
  [TRESHOLD_FRAME_COUNT]: PropTypes.number,
  [TRESHOLD_TIME_MS]: PropTypes.number,
  [ENCODING]: PropTypes.string,
})
