import React, { useState } from 'react'
import { SbHint } from 'skybase-ui/skybase-components/sb-hint'
import { hintPositions } from 'skybase-ui/skybase-components/sb-hint/constants'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { OAuth } from 'skybase-oauth'
import { getStudioAPIHost } from '@/utils/url'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { equipmentApi } from '@/fleet-configuration/equipment'
import { calibrationShape } from '@/fleet-configuration/equipment/shapes'
import { findComponentByTypeAndSerial } from '@/fleet-configuration/utils/device-utils'
import { showErrorToast } from '@/common/services/show-toast'
import { messages as t } from './certificate-icon-i18n'

export const CertificateIcon = ({ row, calibrations = [] }) => {
  const [calibrationCertificatesLoading, setCalibrationCertificatesLoading] = useState({})
  const { typeNumber, id, serialNumber, modelNumber } = row
  const kistlerTypeNumber = row.getKistlerModelNumber?.() || typeNumber || modelNumber || ''
  const { isLoading, calibrationDate = 0 } = findComponentByTypeAndSerial(calibrations, kistlerTypeNumber, serialNumber)
  const { formatMessage: _ } = useIntl()

  const navigateToCalibrationCertificate = async () => {
    setCalibrationCertificatesLoading({ ...calibrationCertificatesLoading, [id]: true })
    try {
      const { downloadUrl } = await OAuth.fetchOAuth(
        `${getStudioAPIHost()}${equipmentApi.CALIBRATIONS}/certificate/${encodeURIComponent(
          kistlerTypeNumber,
        )}/${encodeURIComponent(serialNumber)}`,
      )
      const certificateBlob = await OAuth.fetchOAuth(downloadUrl, { headers: { 'Content-Type': 'application/pdf' } })
      if (certificateBlob instanceof Blob) {
        const pdfUrl = window.URL.createObjectURL(certificateBlob)
        window.open(pdfUrl, '_blank')
        window.URL.revokeObjectURL(pdfUrl)
      }
    } catch (e) {
      showErrorToast(
        _(t.pleaseTryAgainLater),
        _(t.calibrationCertificateNoAccess, { equipment: serialNumber ?? 'unknown.' }),
      )
    } finally {
      setCalibrationCertificatesLoading({ ...calibrationCertificatesLoading, [id]: false })
    }
  }
  if (isLoading || calibrationCertificatesLoading?.[id]) {
    return (
      <div>
        <SbLoader show />
      </div>
    )
  }

  return (
    <div>
      {calibrationDate ? (
        <SbHint position={hintPositions.topCenter} hintData={_(t.calibrationCertificate)}>
          <span className="sbi-file-pdf icon-link show-certificate" onClick={navigateToCalibrationCertificate} />
        </SbHint>
      ) : (
        <SbHint position={hintPositions.topCenter} hintData={_(t.calibrationCertificateIsNotAvailable)}>
          <span className="sbi-file-pdf icon-link icon-disabled show-certificate" />
        </SbHint>
      )}
    </div>
  )
}

CertificateIcon.propTypes = {
  calibrations: PropTypes.arrayOf(calibrationShape),
  row: PropTypes.any.isRequired,
}
