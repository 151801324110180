import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { SbButton } from 'skybase-ui/skybase-components/sb-button'
import { SbHint } from 'skybase-ui/skybase-components/sb-hint'

import { getObservationTimeoutSeconds } from '@/common/init-services/client-app-ws-manager'
import { subscribeToDevicesStatusWS } from './utils'
import { getDiscoveringDevicesState } from '../../selectors'
import { messages as t } from './devices-i18n'

export const StartDeviceObservationButton = () => {
  const { formatMessage: _ } = useIntl()
  const disabled = useSelector(getDiscoveringDevicesState)

  const handleOnClick = () => {
    subscribeToDevicesStatusWS()
  }

  return (
    <SbHint
      position={{ vertical: 'top', horizontal: 'center' }}
      hintData={_(t.startDeviceDiscoveryHint, { seconds: getObservationTimeoutSeconds() })}
      hintClassName="sb-width-m"
    >
      <SbButton
        id="start-device-observation-button"
        className={classNames('primary', 'm-l-15')}
        loading={disabled}
        disabled={disabled}
        onClick={handleOnClick}
      >
        {disabled ? _(t.discoveringDevices) : _(t.startDeviceDiscovery)}
      </SbButton>
    </SbHint>
  )
}
