import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { withRouter } from '@/common/router'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { loaderSize } from 'skybase-ui/skybase-components/sb-loader/constants'
import { SbFullLayout } from 'skybase-ui/skybase-components/layouts/sb-full-layout'
import {
  loadProjectDeviceIfEmpty,
  setChannelAlerts,
} from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { WizardButtons } from '@/fleet-configuration/page-components/wizard/wizard-buttons/wizard-buttons'
import { WizardNavigation } from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation'
import {
  configurationDetailBreadcrumbs,
  configurationDetailHeading,
} from '@/fleet-configuration/pages/configuration/configuration-detail-components'
import { configurationSummarySelector } from '@/fleet-configuration/pages/configuration/configuration-summary/configuration-summary-selector'
import { WizardMenu } from '@/fleet-configuration/page-components/wizard/wizard-menu/wizard-menu'
import { WizardSummary } from '@/fleet-configuration/page-components/wizard/wizard-summary/wizard-summary'
import { UnitIllustration } from '@/fleet-configuration/page-components/unit-illustration/unit-illustration'
import { GREEN, RED, StatusBullet } from '@/common/status-bullet'
import { messages as t } from '@/fleet-configuration/page-components/measurement-unit/measurement-unit-detail-i18n'
import { loadChainIfEmpty } from '@/fleet-configuration/data-fleet/chain/chain-actions'
import { tryLoadChainCatalogForAllChainItems } from '@/fleet-configuration/data-fleet/chain-catalog/chain-catalog-actions'
import { fleetInit } from '@/fleet-configuration/fleet-init'

export const ConfigurationSummary = withRouter(props => {
  const { device, deviceId, deviceChain } = useSelector(state => configurationSummarySelector(state, props))
  const dispatch = useDispatch()
  const { formatMessage: _ } = useIntl()

  useEffect(() => {
    dispatch(fleetInit(_))
  }, [_, dispatch])

  useEffect(() => {
    dispatch(loadProjectDeviceIfEmpty(deviceId))
  }, [deviceId, dispatch])

  useEffect(() => {
    if (device) {
      dispatch(loadChainIfEmpty())
      device.modules.forEach(module => {
        module.getActiveChannels().forEach(channel => {
          channel.initValidationRules()
          const validationResults = channel.validate()
          dispatch(setChannelAlerts(validationResults))
        })
      })
    }
  }, [device, dispatch])

  useEffect(() => {
    if (!deviceChain?.length) {
      return
    }
    dispatch(tryLoadChainCatalogForAllChainItems(deviceChain))
  }, [deviceChain, dispatch]) // can't use deviceChainWithNamesAndCalibrationStatus as that one is being recreated all the time which would run this effect all the time

  return (
    <SbFullLayout
      title={device ? device.name : undefined}
      className="configuration-detail-page skybase-fixes"
      breadcrumbs={configurationDetailBreadcrumbs(_, device)}
    >
      {device ? (
        <div className="p-r-20 fl-container-column">
          {configurationDetailHeading(device)}
          <WizardNavigation deviceId={deviceId} step={2} />
          <div className="fl-container-row fl-grow-1 menu-content-wrapper">
            <div className="fl-container-row">
              <WizardMenu showChainComponents={false} />
            </div>
            <div className="configuration-hardware-setup-content configuration-detail-content fl-grow-1">
              <div className="fl-grow-1">
                <h3 className="config-subtitle">
                  <StatusBullet
                    title={device.online ? _(t.online) : _(t.offline)}
                    status={device.online ? GREEN : RED}
                  />
                  {device.name ? <p className="component-name">{device.name}</p> : _(t.measurementDevice)}
                </h3>
                <div>
                  <UnitIllustration />
                </div>
              </div>
            </div>
            <WizardSummary baseUrl="/configuration/" />
          </div>
          <WizardButtons deviceId={deviceId} step={2} />
        </div>
      ) : (
        <SbLoader show size={loaderSize.L} />
      )}
    </SbFullLayout>
  )
})
