import { selectMeasurementUnitParams } from '@/fleet-configuration/select-measurement-unit-params'
import { getProjectDeviceById } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import { getChainsByModuleDetails } from '@/fleet-configuration/data-fleet/chain/chain-selectors'
import { getChainCatalogEntry } from '@/fleet-configuration/data-fleet/chain-catalog/chain-catalog-selectors'
import { getSensorCalibrationStatus } from '@/fleet-configuration/data-fleet/chain-certificate/chain-certificate-selector'
import { getExtendedDeviceCalibrationByDeviceId } from '@/fleet-configuration/data-fleet/devices-calibrations/devices-calibrations-selector'
import { intersection } from 'lodash'
import { NOT_CALIBRABLE_DEVICE_TYPES } from '@/fleet-configuration/pages/fleet-overview/fleet-overview-constants'
import { getCableCompatibilityStatus } from '@/fleet-configuration/utils/cable-utils'
import { getChannelAlerts } from '@/fleet-configuration/validation/get-alerts'

export const wizardSummarySelector = (state, props) => {
  const { unitId } = selectMeasurementUnitParams(state, props)
  const device = props.explicitDeviceRef || getProjectDeviceById(state, unitId)
  const channelInfo = device?.modules.flatMap(module =>
    module.getChannels().map(channel => {
      const alert = getChannelAlerts(state, channel)
      const moduleType = module.getType()
      const sampleRate = module.getSamplingRateValue()
      const rowChannelIndex = channel.getChannelIndex()
      const rowModuleIndex = channel.getModuleIndex()
      const { resourcePath } = channel
      const {
        channel: { deviceSpecific, parameters },
      } = channel.getChannelOptions()
      const isChargeType = channel.isChargeType()

      return {
        isChargeType,
        rowChannelIndex,
        rowModuleIndex,
        resourcePath,
        moduleType,
        parameters,
        deviceSpecific,
        sampleRate,
        alert,
      }
    }),
  )
  const isDeviceCalibrable = device && !intersection(NOT_CALIBRABLE_DEVICE_TYPES, device.types).length
  const isDeviceValid = device?.isValid()
  const hasVirtualModule = device?.modules?.some(module => module.isVirtual())

  let deviceChainWithNamesAndCalibrationStatus = []
  let deviceChain = []
  const deviceCalibration = getExtendedDeviceCalibrationByDeviceId(state, unitId)
  if (hasVirtualModule) {
    const module = device.modules[0]
    const moduleTypeNumber = module.getType()
    const moduleSerialNumber = module.parametersReadable?.snr || device.serialNumber
    deviceChain = getChainsByModuleDetails(state, moduleTypeNumber, moduleSerialNumber)
    deviceChainWithNamesAndCalibrationStatus = deviceChain.map(chainEntry => {
      const cableCatalog = chainEntry.cable?.typeNumber
        ? getChainCatalogEntry(state, chainEntry.cable.typeNumber)
        : undefined
      const sensorCatalog = chainEntry.sensor?.typeNumber
        ? getChainCatalogEntry(state, chainEntry.sensor.typeNumber)
        : undefined

      const compatibilityStatus = getCableCompatibilityStatus(cableCatalog, sensorCatalog, module)

      return {
        ...chainEntry,
        sensor: {
          ...chainEntry.sensor,
          calibrationStatus:
            chainEntry.sensor?.typeNumber &&
            chainEntry.sensor.serialNumber &&
            getSensorCalibrationStatus(state, chainEntry.sensor?.typeNumber, chainEntry.sensor?.serialNumber),
          name: sensorCatalog?.name,
        },
        cable: {
          ...chainEntry.cable,
          compatibilityStatus,
          name: cableCatalog?.name,
        },
      }
    })
  }

  return {
    device,
    isDeviceCalibrable,
    isDeviceValid,
    deviceCalibration,
    deviceChainWithNamesAndCalibrationStatus,
    channelInfo,
  }
}
