/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { batchActions } from 'redux-batched-actions'

import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbDataTable } from 'skybase-ui/skybase-components/sb-data-table'
import { SbBadge } from 'skybase-ui/skybase-components/sb-badge'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'

import { getSelectedItemPageNumber } from '@/utils'
import { StatusBullet, RED, GREEN } from '@/common/status-bullet'
import { clearDeviceDataSourceConfigurationParams, clearDeviceDataSourceTransferConfigurationParams } from './actions'
import { deviceDataSourceShape } from '../shapes'
import { fetchDeviceDataSource } from '../utils'
import { dataSourceStates, DATA_SOURCES_DETAILS_TAB_KEY, DS_DEFAULT_PAGE_SIZE, DS_DEFAULT_SORT_BY } from './constants'
import { messages as t } from './datasources-i18n'

const { STARTED } = dataSourceStates

class _DataSourcesList extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    dataSources: PropTypes.arrayOf(deviceDataSourceShape),
    loading: PropTypes.bool,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
    typesColors: PropTypes.object, // eslint-disable-line
    handleFetchDeviceDataSource: PropTypes.func.isRequired,
    selectedDataSourceId: PropTypes.string,
    changeTab: PropTypes.func.isRequired,
    clearParams: PropTypes.func.isRequired,
    matchDataSourceId: PropTypes.string,
  }

  static defaultProps = {
    columns: null,
    selectedDataSourceId: null,
    dataSources: [],
    loading: false,
    matchDataSourceId: null,
  }

  constructor(props) {
    super(props)

    const {
      intl: { formatMessage: _ },
    } = props

    this.tableColumns = props.columns || [
      {
        name: 'name',
        label: _(t.name),
        cellsClassName: 'text-align-left',
        headerClassName: 'text-align-left',
      },
      {
        name: 'id',
        label: 'ID',
        cellsClassName: 'text-align-left',
        headerClassName: 'text-align-left',
      },
      { name: 'types', label: _(t.types), sortable: false },
    ]
    this.state = {
      pagination: {
        pageSize: DS_DEFAULT_PAGE_SIZE,
        pageNumber: 1,
      },
      sortBy: DS_DEFAULT_SORT_BY,
    }
  }

  componentDidMount() {
    this.handleJumpToPage()

    SbEmitter.on('datasourceJumpToPage', this.handleJumpToPage)
  }

  componentWillUnmount() {
    SbEmitter.off('datasourceJumpToPage', this.handleJumpToPage)
  }

  handleJumpToPage = () => {
    const {
      pagination: { pageSize },
      sortBy,
    } = this.state
    const { dataSources, matchDataSourceId } = this.props

    // Set the pageNumber pagination based on the selected value in the table
    if (dataSources && matchDataSourceId) {
      const selectedItemPageNumber = getSelectedItemPageNumber({
        columns: this.tableColumns,
        data: dataSources,
        sortShape: sortBy,
        pageSize,
        indexSelector: ({ id }) => id === matchDataSourceId,
      })

      this.setState(prevState => {
        return { pagination: { ...prevState.pagination, pageNumber: selectedItemPageNumber } }
      })
    }
  }

  handleOnPaginationChange = pagination => this.setState({ pagination })

  handleOnSortChange = sortBy => this.setState({ sortBy })

  onRowSelect = dataSourceId => {
    const { dataSources, handleFetchDeviceDataSource, changeTab, clearParams } = this.props

    const thisDataSource = dataSources.find(dataSource => dataSource.id === dataSourceId)

    if (thisDataSource) {
      const { id, deviceId } = thisDataSource
      handleFetchDeviceDataSource(deviceId, id)
      clearParams()
      changeTab(DATA_SOURCES_DETAILS_TAB_KEY) // Change the dynamic tabs panel to the details view
    }
  }

  cellFormatter = (value, key) => {
    const {
      intl: { formatMessage: _ },
    } = this.props

    if (key === 'types') {
      const { typesColors } = this.props

      return (
        <div className="fl-row">
          {value.map((item, i) => {
            return (
              <SbBadge
                key={`${key}-item-${i}`}
                className={`${key}-${item.replace(/\./g, '-')}`}
                style={{ backgroundColor: typesColors[item] }}
              >
                {item}
              </SbBadge>
            )
          })}
        </div>
      )
    }

    if (key === 'name' || key === 'id') {
      return <span className="no-word-break">{value}</span>
    }

    if (key === 'state') {
      return <StatusBullet status={value === STARTED ? GREEN : RED}>{t[value] ? _(t[value]) : ''}</StatusBullet>
    }

    return value
  }

  rowFormatter = row => {
    const { selectedDataSourceId } = this.props
    const selected = row.id === selectedDataSourceId ? 'selected' : ''

    return {
      onClick: () => this.onRowSelect(row.id),
      className: classNames({ selected }),
    }
  }

  render() {
    const { pagination } = this.state
    const {
      dataSources,
      intl: { formatMessage: _ },
      loading,
    } = this.props

    return (
      <SbDataTable
        id="device-data-source-table"
        className="list-table"
        columns={this.tableColumns}
        loading={loading}
        data={dataSources}
        rowFormatter={row => this.rowFormatter(row)}
        cellFormatter={this.cellFormatter}
        emptyMessage={_(t.noDataSourcesFoundForThisDevice)}
        enablePagination
        asyncData={false}
        paginationProps={{
          pageSizeDropdownProps: {
            className: 'min-width-100px',
          },
          ...pagination,
          onChange: this.handleOnPaginationChange,
        }}
        defaultSortBy={DS_DEFAULT_SORT_BY}
        onSortChange={this.handleOnSortChange}
      />
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleFetchDeviceDataSource: (deviceId, dataSourceId) => dispatch(fetchDeviceDataSource(deviceId, dataSourceId)),
    clearParams: () =>
      dispatch(
        batchActions([clearDeviceDataSourceConfigurationParams(), clearDeviceDataSourceTransferConfigurationParams()]),
      ),
  }
}

export const DataSourcesList = injectIntl(connect(null, mapDispatchToProps)(_DataSourcesList))
