import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { getPendingLastUpdate } from './pending-selector'

export const PENDING_ADD = getActionName('PENDING_ADD')
export const pendingAdd = url => createAction(PENDING_ADD, url)

const MIN_ANIMATION_TIME = 1000
export const PENDING_REMOVE = getActionName('PENDING_REMOVE')
export const pendingRemove = url => (dispatch, getState) => {
  const lastUpdate = getPendingLastUpdate(getState())
  const timeDiff = Date.now() - lastUpdate
  const resultingAction = createAction(PENDING_REMOVE, url)
  if (timeDiff < MIN_ANIMATION_TIME) {
    setTimeout(() => {
      dispatch(resultingAction)
    }, MIN_ANIMATION_TIME - timeDiff)
    return
  }
  dispatch(resultingAction)
}
