import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  samplingRateCategoryANeedsToBeUsed: {
    id: 'Sampling rate category A needs to be used',
    defaultMessage: 'Sampling rate category A needs to be used',
  },
  domainIsRequired: {
    id: 'Domain is required',
    defaultMessage: 'Domain is required',
  },
  ntpServerIsRequired: {
    id: 'NTP server is required',
    defaultMessage: 'NTP Server URL is required (previously set URL was {serverUrl}).',
  },
})
