// noinspection LoopStatementThatDoesntLoopJS

import { STATES } from 'skybase-oauth/constants'

import {
  SET_DEVICES,
  CHANGE_DEVICE_PARAMS,
  CLEAR_DEVICE_PARAMS,
  CHANGE_DEVICE_STATE,
  LOAD_DEVICE_TO_UPDATE,
  ENABLE_DEVICE_STATUS_NOTIFICATIONS,
  UPDATE_DEVICE_PARAM,
  CHANGE_SEARCH_DEVICE_VALUE,
  LOAD_DEVICE_RESOURCE_TO_UPDATE,
  LOAD_DEVICE_RESOURCE_INTERFACE_TO_UPDATE,
  CHANGE_DEVICE_RESOURCE_INTERFACE_RETRIEVE_STATE,
  CHANGE_DEVICE_RESOURCE_INTERFACE_UPDATE_STATE,
  SET_DEVICE_RESOURCE_INTERFACE_ERRORS,
  REMOVE_DEVICE,
  ADD_DEVICE,
  UPDATE_DEVICE_DATA,
  UPDATE_DEVICE_ONLINE_STATUS,
  UPDATE_DEVICE_REGISTER_STATUS,
  SAVE_DEVICE_BY_IP_VALUE,
  SET_INITIAL_DEVICES_ONLINE_STATUS,
  SET_OWNING_DEVICE_ID,
  UNSET_OWNING_DEVICE_ID,
  SET_DISCOVERING_DEVICES,
} from './actions'
import { DEVICES_STATE_KEYS } from './constants'

const { UPDATE_DEVICE, LIST, RESOURCE_DETAILS, DEVICE_ONLINE_STATUS, DEVICE_REGISTER_STATUS } = DEVICES_STATE_KEYS

export const initialDevicesState = {
  id: null,
}

export const initialDeviceResourceState = {
  resourcePath: null,
  resourceData: {}, // full data from a resource
  resourceInterface: {}, // data from a specific interface of a resource
}

const getDefaultState = () => {
  return {
    [LIST]: {
      data: [],
      state: STATES.LOADING,
    },
    [UPDATE_DEVICE]: {
      data: initialDevicesState,
      state: STATES.NORMAL,
    },
    [DEVICE_ONLINE_STATUS]: {},
    [DEVICE_REGISTER_STATUS]: {},
    [RESOURCE_DETAILS]: {
      data: initialDeviceResourceState,
      state: STATES.NORMAL,
      interfacesRetrieveState: STATES.NORMAL,
      interfaceUpdateState: STATES.NORMAL,
      errors: [],
    },
    enableNotifications: false,
    searchDeviceValue: '',
    addDeviceByIpValue: '',
    owningDeviceIds: [],
    discoveringDevices: false,
  }
}

const getInitialParams = state => {
  switch (state) {
    case UPDATE_DEVICE:
      return initialDevicesState
    case RESOURCE_DETAILS:
      return initialDeviceResourceState
    default:
      return initialDevicesState
  }
}

export const devicesReducer = (state = getDefaultState(), action = {}) => {
  const { payload, type } = action

  switch (type) {
    case SET_DEVICES: {
      return {
        ...state,
        [LIST]: {
          ...state[LIST],
          // remove resources, which are only used in fetch filter
          data: payload.devices.map(({ resources, ...device }) => {
            // set device online state by data from websocket (race condition problem fix)
            return { ...device, status: state[DEVICE_ONLINE_STATUS][device.id]?.status || device.status }
          }),
        },
      }
    }
    case CHANGE_DEVICE_PARAMS: {
      const { key, attribute, value } = payload

      return {
        ...state,
        [key]: {
          ...state[key],
          data: {
            ...state[key].data,
            [attribute]: value,
          },
        },
      }
    }
    case SET_INITIAL_DEVICES_ONLINE_STATUS: {
      // for-in is most performant test for testing for empty object (especially large objects).
      // eslint-disable-next-line guard-for-in,no-restricted-syntax,no-unreachable-loop
      for (const hasObject in state[DEVICE_ONLINE_STATUS]) {
        return state
      }
      const initialOnlineStatuses = payload.devices.reduce((acc, { id, status }) => {
        if (id && status) {
          acc[id] = status
        }
        return acc
      }, {})

      return {
        ...state,
        [DEVICE_ONLINE_STATUS]: initialOnlineStatuses,
      }
    }
    case UPDATE_DEVICE_ONLINE_STATUS: {
      const { deviceId, status } = payload
      return {
        ...state,
        [DEVICE_ONLINE_STATUS]: {
          ...state[DEVICE_ONLINE_STATUS],
          [deviceId]: status,
        },
      }
    }
    case UPDATE_DEVICE_REGISTER_STATUS: {
      const { deviceId, status } = payload
      return {
        ...state,
        [DEVICE_REGISTER_STATUS]: {
          ...state[DEVICE_REGISTER_STATUS],
          [deviceId]: status,
        },
      }
    }
    case CLEAR_DEVICE_PARAMS: {
      const { key } = payload

      return {
        ...state,
        [key]: {
          ...state[key],
          data: getInitialParams(key),
        },
      }
    }
    case CHANGE_DEVICE_STATE: {
      const { key, state: newState } = payload

      return {
        ...state,
        [key]: {
          ...state[key],
          state: newState,
        },
      }
    }
    case LOAD_DEVICE_TO_UPDATE:
      return {
        ...state,
        [UPDATE_DEVICE]: {
          ...state[UPDATE_DEVICE],
          data: payload.device,
        },
      }
    case ENABLE_DEVICE_STATUS_NOTIFICATIONS:
      return {
        ...state,
        enableNotifications: payload.enable,
      }
    case UPDATE_DEVICE_PARAM: {
      const { deviceId, param } = payload
      const updatedDeviceData = state?.[UPDATE_DEVICE]?.data?.id === deviceId ? param : {}

      return {
        ...state,
        [LIST]: {
          ...state[LIST],
          data: state[LIST].data.reduce((newData, currentItem) => {
            if (currentItem.id === deviceId) {
              return newData.concat({
                ...currentItem,
                ...param,
              })
            }

            return newData.concat(currentItem)
          }, []),
        },
        [UPDATE_DEVICE]: {
          ...state[UPDATE_DEVICE],
          data: {
            ...state[UPDATE_DEVICE].data,
            ...updatedDeviceData,
          },
        },
      }
    }
    case CHANGE_SEARCH_DEVICE_VALUE:
      return {
        ...state,
        searchDeviceValue: payload.value,
      }
    case LOAD_DEVICE_RESOURCE_TO_UPDATE:
      return {
        ...state,
        [RESOURCE_DETAILS]: {
          ...state[RESOURCE_DETAILS],
          data: payload.resource,
          errors: [],
        },
      }
    case LOAD_DEVICE_RESOURCE_INTERFACE_TO_UPDATE:
      return {
        ...state,
        [RESOURCE_DETAILS]: {
          ...state[RESOURCE_DETAILS],
          data: {
            ...state[RESOURCE_DETAILS].data,
            resourceInterface: payload.resourceInterface,
          },
          errors: [],
        },
      }
    case CHANGE_DEVICE_RESOURCE_INTERFACE_RETRIEVE_STATE:
      return {
        ...state,
        [RESOURCE_DETAILS]: {
          ...state[RESOURCE_DETAILS],
          interfacesRetrieveState: payload.state,
          errors: [],
        },
      }
    case CHANGE_DEVICE_RESOURCE_INTERFACE_UPDATE_STATE:
      return {
        ...state,
        [RESOURCE_DETAILS]: {
          ...state[RESOURCE_DETAILS],
          interfaceUpdateState: payload.state,
          errors: [],
        },
      }
    case SET_DEVICE_RESOURCE_INTERFACE_ERRORS:
      return {
        ...state,
        [RESOURCE_DETAILS]: {
          ...state[RESOURCE_DETAILS],
          errors: payload.errors,
        },
      }
    case REMOVE_DEVICE: {
      return {
        ...state,
        [LIST]: {
          ...state[LIST],
          data: state[LIST].data.filter(device => device.id !== payload.deviceId),
        },
        [UPDATE_DEVICE]: {
          ...state[UPDATE_DEVICE],
          data: state[UPDATE_DEVICE].data?.id === payload.deviceId ? initialDevicesState : state[UPDATE_DEVICE].data,
        },
      }
    }
    case ADD_DEVICE: {
      const {
        device: { id: deviceId, ipAddress = '' },
      } = payload
      // Remove the device we are about to add in if it is already in the list
      const filteredList = state[LIST].data.filter(device => device.id !== deviceId && device.ipAddress !== ipAddress)
      return {
        ...state,
        [LIST]: {
          ...state[LIST],
          data: [...filteredList, payload.device],
        },
        [UPDATE_DEVICE]: {
          ...state[UPDATE_DEVICE],
          data:
            state[UPDATE_DEVICE].data?.id === deviceId || state[UPDATE_DEVICE].data?.ipAddress === ipAddress
              ? { ...state[UPDATE_DEVICE].data, ...payload.device }
              : state[UPDATE_DEVICE].data,
        },
      }
    }
    case UPDATE_DEVICE_DATA: {
      const {
        device: { id: deviceId },
      } = payload
      return {
        ...state,
        [LIST]: {
          ...state[LIST],
          data: state[LIST].data.map(device => (device.id === deviceId ? payload.device : device)),
        },
        [UPDATE_DEVICE]: {
          ...state[UPDATE_DEVICE],
          data: state[UPDATE_DEVICE].data?.id === deviceId ? payload.device : state[UPDATE_DEVICE].data,
        },
      }
    }
    case SAVE_DEVICE_BY_IP_VALUE:
      return {
        ...state,
        addDeviceByIpValue: payload.ipAddress,
      }
    case SET_OWNING_DEVICE_ID:
      return {
        ...state,
        owningDeviceIds: state.owningDeviceIds.filter(id => id !== payload.deviceId).concat(payload.deviceId),
      }
    case UNSET_OWNING_DEVICE_ID:
      return {
        ...state,
        owningDeviceIds: state.owningDeviceIds.filter(id => id !== payload.deviceId),
      }
    case SET_DISCOVERING_DEVICES:
      return {
        ...state,
        discoveringDevices: payload.discovering,
      }
    default:
      return state
  }
}
