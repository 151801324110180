export const IOT_HUB_STATE_KEY_NAME = 'iot-hub'

export const iotHubApi = {
  DEVICES: '/api/devices',
  OCF_CLOUD_CONFIGURATION: '/.well-known/ocfcloud-configuration',
}

export const iotHubWSApi = {
  DEVICES: '/ws/devices',
}

export const ACQUISITION_DATASOURCE = 'x.com.kistler.acquisition.datasource'

export const unauthorizedDeviceErrorNamespaces = ['sdk', 'sdk.grpc', 'sdk.plgd']
