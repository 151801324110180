export const firmwareStates = {
  UP_TO_DATE: 'UpToDate',
  UPDATE_AVAILABLE: 'UpdateAvailable',
  UNKNOWN: 'Unknown',
  /* UI-only states (that come from websocket information) */
  UPDATE_IN_PROGRESS: 'UpdateInProgress',
  UPDATE_FINISHED_UP_TO_DATE: 'UpdateFinishedUpToDate',
  UPDATE_FINISHED_UPDATE_AVAILABLE: 'UpdateFinishedUpdateAvailable',
}

export const firmwareEventOperationNames = {
  STARTED: 'Started',
  ENDED: 'Ended',
}
