import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { aclShape } from 'skybase-oauth/auth'
import { STATES } from 'skybase-oauth/constants'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbLabel } from 'skybase-ui/skybase-components/sb-label'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { SbBadge } from 'skybase-ui/skybase-components/sb-badge'
import { SbCheckbox } from 'skybase-ui/skybase-components/sb-checkbox'
import { InfoMessage, CopyBox } from 'skybase-oauth/common/components'

import { StatusBullet, RED, GREEN } from '@/common/status-bullet'
import { WSClients } from '@/common/websocket'
import { getIotHubDeviceDataSourceDetailsState } from '@/iot-hub/selectors'
import { deviceDataSourceShape, deviceShape } from '../shapes'
import { deviceStatuses } from '../constants'
import { DataSourceUpdate } from './datasource-update'
import { DataSourceConfigurationUpdate } from './datasource-configuration-update'
import { dataSourceStates, supportedTransferMethodNames } from './constants'
import { getDataSourceWSID, getIntlDataSourceState } from './utils'
import { dataSourceStatusListener } from './datasource-status-listener'
import { messages as t } from './datasources-i18n'

const { STARTED, STOPPED } = dataSourceStates
const { OFFLINE } = deviceStatuses

class _DataSourcesDetails extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    data: deviceDataSourceShape,
    deviceData: deviceShape,
    loading: PropTypes.bool.isRequired,
    typesColors: PropTypes.object, // eslint-disable-line
    acl: aclShape.isRequired,
  }

  static defaultProps = {
    data: {},
    deviceData: {},
  }

  constructor(props) {
    super(props)

    this.state = {
      dataSourceId: props?.data?.id,
      subscribeAndNotify: !!WSClients.ws[getDataSourceWSID(props?.deviceData?.id, props?.data?.id)],
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props?.data?.id && props.data.id !== state.dataSourceId) {
      return {
        dataSourceId: props.data.id,
        hasError: false,
        subscribeAndNotify: !!WSClients.ws[getDataSourceWSID(props?.deviceData?.id, props.data.id)],
      }
    }

    return null
  }

  handleOnToggleSubscribeAndNotify = () => {
    const {
      data: { id, name },
      deviceData: { id: deviceId, name: deviceName },
    } = this.props
    const dataSourceWSKey = getDataSourceWSID(deviceId, id)

    if (!WSClients.ws[dataSourceWSKey]) {
      WSClients.addToWsClients({
        name: dataSourceWSKey,
        endpoint: `/ws/devices/${deviceId}/datasources/${id}`,
        listener: message => dataSourceStatusListener(message, { deviceId, deviceName, dataSourceName: name }),
        // permission,
      })
      this.setState({ subscribeAndNotify: true })
    } else {
      WSClients.removeFromWsClients(dataSourceWSKey)
      this.setState({ subscribeAndNotify: false })
    }
  }

  renderLoader = () => {
    return (
      <div className="fl-row fl-justify-center">
        <SbLoader show />
      </div>
    )
  }

  render() {
    const {
      intl: { formatMessage: _ },
      loading,
      data,
      deviceData,
      typesColors,
      acl,
    } = this.props
    const { subscribeAndNotify } = this.state

    if (loading) {
      return this.renderLoader()
    }

    if (deviceData?.id && data?.id) {
      const { id, name, types, state: dataSourceState, transfer: activeDataTransfer } = data
      const { id: deviceId, status: deviceStatus } = deviceData
      const deviceOffline = deviceStatus === OFFLINE
      const dataTransferStarted = dataSourceState !== STOPPED

      return (
        <>
          <SbLabel title={_(t.name)} inline>
            {name}
          </SbLabel>

          <SbLabel title="ID" inline className="resource-path-label">
            <CopyBox text={id} />
          </SbLabel>

          <SbLabel title={_(t.deviceId)} inline>
            {deviceId}
          </SbLabel>

          <SbLabel title={_(t.state)} inline>
            <StatusBullet status={dataSourceState === STARTED ? GREEN : RED}>
              {_(getIntlDataSourceState(dataSourceState))}
            </StatusBullet>
          </SbLabel>

          <SbLabel title={_(t.activeTransfer)} inline>
            {(dataTransferStarted && supportedTransferMethodNames?.[activeDataTransfer]) || '-'}
          </SbLabel>

          <SbLabel title={_(t.types)} inline>
            <div className="fl-col fl-align-items-end">
              {types?.map?.(type => {
                return (
                  <SbBadge
                    key={`device-data-source-type-${type}`}
                    className="primary"
                    style={{ backgroundColor: typesColors[type] }}
                  >
                    {type}
                  </SbBadge>
                )
              }) || '-'}
            </div>
          </SbLabel>

          <div className="widget-underline-container m-b-10">
            <SbCheckbox
              type="switch-control"
              onClick={this.handleOnToggleSubscribeAndNotify}
              checked={subscribeAndNotify}
            >
              {_(t.subscribeAndNotify)}
            </SbCheckbox>
          </div>

          <DataSourceUpdate data={data} disabled={deviceOffline} acl={acl} />

          <hr />

          <DataSourceConfigurationUpdate dataSource={data} deviceOffline={deviceOffline} acl={acl} />
        </>
      )
    }

    return <InfoMessage>{_(t.selectADataSource)}</InfoMessage>
  }
}

const mapStateToProps = state => {
  const { data, state: dataSourceState } = getIotHubDeviceDataSourceDetailsState(state)

  return {
    data,
    loading: dataSourceState === STATES.LOADING,
  }
}

export const DataSourcesDetails = injectIntl(connect(mapStateToProps)(_DataSourcesDetails))
