import { v4 } from 'uuid'
import { ALERT_TYPE_ERROR, ALERT_TYPE_WARNING, ALERT_ORIGIN_FRONTEND, ALERT_TYPE_INFO } from './constants'
import { messages as t } from './create-alert-i18n'

const defaultConfig = {
  origin: ALERT_ORIGIN_FRONTEND,
}

const createAlert = (type, message, config, parameters = {}) => {
  const newConfig = { ...defaultConfig, ...config }

  return {
    type,
    // Skybase is using severity which is later mapped to type
    severity: type,
    message,
    id: v4(),
    date: Date.now(),
    parameters,
    ...newConfig,
  }
}

const defaultErrorConfig = {
  title: t.validationError,
}

export const createError = (message, config, parameters) =>
  createAlert(ALERT_TYPE_ERROR, message, { ...defaultErrorConfig, ...config }, parameters)

const defaultWarningConfig = {
  title: t.warning,
}

export const createWarning = (message, config, parameters) =>
  createAlert(ALERT_TYPE_WARNING, message, { ...defaultWarningConfig, ...config }, parameters)

export const createInfo = (message, config, parameters) => createAlert(ALERT_TYPE_INFO, message, config, parameters)
