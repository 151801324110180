/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  id: {
    id: 'ID',
    defaultMessage: 'ID',
  },
  noDevicesFound: {
    id: 'No devices found',
    defaultMessage: 'No devices found',
  },
  fleetDevices: {
    id: 'Fleet Devices',
    defaultMessage: 'Devices',
  },
  status: {
    id: 'Status',
    defaultMessage: 'Status',
  },
  name: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  serialNumber: {
    id: 'Serial Number',
    defaultMessage: 'Serial Number',
  },
  home: {
    id: 'Home',
    defaultMessage: 'Home',
  },
  fleetOverview: {
    id: 'Fleet Overview',
    defaultMessage: 'Hardware',
  },
  online: {
    id: 'fleetOverview.online',
    defaultMessage: 'Online',
  },
  offline: {
    id: 'fleetOverview.offline',
    defaultMessage: 'Offline',
  },
  deviceType: {
    id: 'fleetOverview.deviceType',
    defaultMessage: 'Device Type',
  },
  daq: {
    id: 'fleetOverview.daq',
    defaultMessage: 'DAQ',
  },
  configurationBackupRestoreNotSupported: {
    id: 'fleetOverview.theConfigurationBackupRestoreIsNotSupportedAtThisTypeOfDevice',
    defaultMessage: 'The Configuration Backup/Restore is not supported at this type of device',
  },
  deviceNameUpdateFailed: {
    id: 'fleetOverview.deviceNameUpdateFailed',
    defaultMessage: 'Device name update failed',
  },
  configurationIsNotWrittenToDevice: {
    id: 'fleetOverview.configurationIsNotWrittenToDevice',
    defaultMessage: 'Configuration is not written to Device',
  },
  rebootDevice: {
    id: 'fleetOverview.rebootDevice',
    defaultMessage: 'Reboot device',
  },
  identifyDevice: {
    id: 'fleetOverview.identifyDevice',
    defaultMessage: 'Identify device',
  },
  factoryReset: {
    id: 'fleetOverview.factoryReset',
    defaultMessage: 'Factory reset',
  },
  identifyCalled: {
    id: 'fleetOverview.identifyCalled',
    defaultMessage: 'Identify called',
  },
  identifyCantBeCalledOnOfflineDevice: {
    id: 'fleetOverview.identifyCantBeCalledOnOfflineDevice',
    defaultMessage: "Identify can't be called on offline device",
  },
  factoryResetCantBeCalledOnOfflineDevice: {
    id: 'fleetOverview.factoryResetCantBeCalledOnOfflineDevice',
    defaultMessage: "Factory reset can't be called on offline device",
  },
  rebootCantBeCalledOnOfflineDevice: {
    id: 'fleetOverview.rebootCantBeCalledOnOfflineDevice',
    defaultMessage: "Reboot can't be called on offline device",
  },
  areYouSureYouWantToRebootDeviceDevice: {
    id: 'fleetOverview.areYouSureYouWantToRebootDeviceDevice',
    defaultMessage: 'Are you sure you want to reboot device {device}',
  },
  areYouSureYouWantToResetSettingsOnDeviceDevice: {
    id: 'fleetOverview.areYouSureYouWantToResetSettingsOnDeviceDevice',
    defaultMessage: 'Are you sure you want to reset settings on device "{device}"?',
  },
  theDeviceWillDisappearAndHasToBeOnboardedAgain: {
    id: 'fleetOverview.theDeviceWillDisappearAndHasToBeOnboardedAgain',
    defaultMessage: 'The device will disappear and has to be onboarded again.',
  },
  theDeviceWillNotBeVisibleDuringBootingUp: {
    id: 'fleetOverview.theDeviceWillNotBeVisibleDuringBootingUp',
    defaultMessage: 'The device will not be visible during booting up.',
  },
  reset: {
    id: 'fleetOverview.reset',
    defaultMessage: 'Reset',
  },
  reboot: {
    id: 'fleetOverview.reboot',
    defaultMessage: 'Reboot',
  },
  backupDeviceConfiguration: {
    id: 'fleetOverview.backupDeviceConfiguration',
    defaultMessage: 'Backup device configuration',
  },
  deviceDocumentation: {
    id: 'fleetOverview.deviceDocumentation',
    defaultMessage: 'Device documentation',
  },
  configureDevice: {
    id: 'fleetOverview.configureDevice',
    defaultMessage: 'Configure device',
  },
  showOfflineDevices: {
    id: 'fleetOverview.showOfflineDevices',
    defaultMessage: 'Show Offline Devices',
  },
  deviceConfigurationIsDisabled: {
    id: 'fleetOverview.deviceConfigurationIsDisabledBecauseSystemCanNotReadThisConfiguration',
    defaultMessage: "Device configuration is disabled, because system can't read this configuration",
  },
  notificationsIssueSToPayAttention: {
    id: 'fleetOverview.notificationsIssueSToPayAttention',
    defaultMessage: '{notifications} issue(s) to pay attention',
  },
  calibrationCertificateIsNotAvailable: {
    id: 'fleetOverview.calibrationCertificateIsNotAvailable',
    defaultMessage: 'The calibration certificate is not available',
  },
  calibrationCertificate: {
    id: 'fleetOverview.calibrationCertificate',
    defaultMessage: 'Calibration certificate',
  },
  lastModified: {
    id: 'fleetOverview.lastModified',
    defaultMessage: 'Last Modified',
  },
})
