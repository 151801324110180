import { v4 } from 'uuid'

import { showInfoToast } from 'skybase-ui/skybase-components/sb-toastr/actions'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'

import { store } from '@/stores'
import { getIotHubDeviceDataSourceDetailsState } from '@/iot-hub/selectors'
import { logNotification, notificationTypes, NOTIFICATIONS_PAGE_URL } from '@/iot-hub/components/notifications'
import { isBrowserTabActive } from '@/utils/is-browser-tab-active'

import { supportedTransferMethodNames } from './constants'
import { setDeviceDataTransferActiveTransferMethod } from './actions'
import { messages as t } from './datasources-i18n'

const handleOnToastClick = (onRemove, deviceId, notificationId) => {
  // Remove/Hide the sw licensing toast
  onRemove()

  // Redirect to the notifications page
  SbEmitter.emit('navigate', NOTIFICATIONS_PAGE_URL, {
    state: { selectedId: notificationId },
  })
}

export const dataSourceStatusListener = (message, info) => {
  const parsedData = JSON.parse(message.data)
  const { id, transferMethod, state } = parsedData
  const { deviceId, deviceName, dataSourceName } = info
  const {
    data: { id: visibleDataSourceId },
  } = getIotHubDeviceDataSourceDetailsState(store.getState())

  // If the the data source opened in details view is the current one, update its info based on the WS message.
  if (id === visibleDataSourceId) {
    store.dispatch(setDeviceDataTransferActiveTransferMethod(transferMethod, state))
  }

  const title = t.dataSourceTransferTitle
  const text = {
    message: transferMethod ? t.changeOnDataSourceOccuredWithMethod : t.changeOnDataSourceOccuredWithoutMethod,
    params: {
      deviceName,
      dataSourceName,
      transferMethod: supportedTransferMethodNames[transferMethod] || transferMethod,
      state,
    },
  }
  const notificationId = v4()

  // Show toast only when browser tab is active
  if (isBrowserTabActive()) {
    // Show toast
    store.dispatch(
      showInfoToast(
        {
          title,
          message: text,
        },
        {
          className: 'sb-cursor-pointer',
          hideTime: 5000,
          closeButton: true,
          onClick: (event, onRemove) => handleOnToastClick(onRemove, deviceId, notificationId),
        },
      ),
    )
  }

  // Log notification
  store.dispatch(
    logNotification({
      id: notificationId,
      type: notificationTypes.INFO,
      title,
      message: text,
      payload: parsedData,
      redirectLocation: `/iot-hub/devices/${deviceId}/datasources/${id}`,
    }),
  )
}
