/* eslint-disable react/no-unescaped-entities */

import React from 'react'

import { stepIds } from './constants'
import tourAsset1 from './assets/tour_asset_1.png'
import tourAsset2 from './assets/tour_asset_2.png'

export const stepsDevice = ({ isOwnableDeviceInTable, isOwnableDeviceSelected, isOwnedDeviceSelected }) => [
  {
    title: '1. Welcome to KiConnect Client!',
    content: 'Would you like to take a guided tour or do you want to start using KiConnect Client?',
  },
  {
    title: 'What is KiConnect Client?',
    selector: '#app-title',
    content:
      'KiConnect Client is built on top of an SDK, which is available as REST API (webAPI), .NET or Golang libraries.',
  },
  {
    title: 'What is its purpose?',
    selector: '#nav-iot-hub-devices',
    content: () => (
      <div>
        The purpose is to communicate with Kistler devices in a secure and standardized way as defined in the{' '}
        <a href="https://openconnectivity.org/" target="_blank" rel="noopener noreferrer">
          Open Connectivity Foundation
        </a>{' '}
        specification.
      </div>
    ),
  },
  {
    title: 'Supporting',
    selector: '.devices-list-container',
    content: () => (
      <div>
        KiConnect Supports device-to-device as well as device-to-cloud communication paradigms. KiConnect Client
        utilizes D2D, which allows you to discover your devices on your local network, via{' '}
        <a href="https://openconnectivity.org/" target="_blank" rel="noopener noreferrer">
          CoAP multicast
        </a>{' '}
        internally.
      </div>
    ),
    stepInteraction: false,
  },
  {
    title: 'Device discovery',
    selector: '.devices-list-container',
    content:
      'Discovered devices are displayed in this list after few seconds. If a new device was turned on, it will appear automatically.',
    stepInteraction: false,
  },
  {
    title: 'Connect your device',
    selector: '.devices-list-container',
    content:
      "So let's KiConnect your device to your local network and discover it! Make sure to have your device ready before continuing.",
    nextButtonEnabled: isOwnableDeviceInTable,
    stepInteraction: false,
  },
  {
    title: 'Hurray!',
    selector: '.devices-list-container',
    content: 'Your device(s) were successfully discovered.',
    nextButtonEnabled: isOwnableDeviceInTable,
    stepInteraction: false,
  },
  {
    title: 'Your device',
    selector: '.devices-list-container',
    content:
      "Now, select your device and verify that it's yours in the device details. You can use the search field to find your device in the list.",
    nextButtonEnabled: isOwnableDeviceSelected,
    highlightedSelectors: ['.search-devices'],
  },
  {
    title: 'Name',
    selector: '#device-name-label',
    content:
      'The details section contains basic information about each KiConnect device. The device name is configurable.',
    nextButtonEnabled: isOwnableDeviceSelected,
  },
  {
    title: 'Serial number',
    selector: '#device-serial-number-label',
    content:
      'The serial number is unique for each device. If you are using a development device like EvalBoard, the value may not be unique.',
    nextButtonEnabled: isOwnableDeviceSelected,
  },
  {
    title: 'ID',
    selector: '#device-id-label',
    content: 'The ID of the device is unique. It is changed after each factory reset.',
    nextButtonEnabled: isOwnableDeviceSelected,
  },
  {
    title: 'Status',
    selector: '#device-status-label',
    content:
      "Status represents if the device is still reachable on the local network. KiConnect Client continually pings the device, when the device doesn't respond, the status will be changed to offline.",
    nextButtonEnabled: isOwnableDeviceSelected,
  },
  {
    title: 'Types',
    selector: '#device-types-label',
    content:
      'Each device has a type. The type is used to identify core capabilities. A Kistler application can us the type to decide if it supports the device or not.',
    nextButtonEnabled: isOwnableDeviceSelected,
  },
  {
    title: 'Security',
    selector: '#device-secured-label',
    content:
      "Older KiConnect versions supported unsecured devices. There are still a few of them out there. Unsecured devices don't require registration before usage, and communication with them is insecure.",
    nextButtonEnabled: isOwnableDeviceSelected,
  },
  {
    title: 'Ownership transfer',
    selector: '#device-secured-label',
    content: 'Secured devices require registration before usage. This process is called "ownership transfer".',
    nextButtonEnabled: isOwnableDeviceSelected,
  },
  {
    title: 'Application mode',
    selector: '#app-mode',
    content:
      'The process of taking ownership issues a certificate for the device, using the selected method specified in initial screen when the application was started.',
    nextButtonEnabled: isOwnableDeviceSelected,
  },
  {
    title: 'Local Ownership',
    selector: '#app-mode',
    content:
      'In the Local Ownership mode, KiConnect Client issues a certificate for the device on its own. CA is contained in the advanced configuration. If the CA doesn\'t match the one used during the previous ownership transfer, the device will be considered as "Owned by other". That means you are not able to use it.',
    nextButtonEnabled: isOwnableDeviceSelected,
  },
  {
    title: 'Backend Ownership',
    selector: '#app-mode',
    content:
      'In the Backend Ownership mode, before using KiConnect Client you have to first authenticate. Your identity is stored in the KiCloud where the certificate for the device is issued. This allows working with your devices without taking care of a CA certificate. All you need is a KiCloud account.',
    nextButtonEnabled: isOwnableDeviceSelected,
  },
  {
    title: 'Ownership status',
    selector: '#device-ownership-status-label',
    content:
      'Owned devices can be onboarded to the KiCloud and configured remotely. This step is optional, but require the usage of the Backend Ownership mode.',
    nextButtonEnabled: isOwnableDeviceSelected,
  },
  {
    title: 'Take ownership',
    selector: '#device-details-footer',
    content:
      "Let's take ownership of my device. If it's \"Owned by other\", you'll need to do a factory reset using the hardware button or Device UI.",
    nextButtonEnabled: isOwnedDeviceSelected,
    stepId: stepIds.OWN_DEVICE,
  },
  {
    title: 'Owned device',
    selector: '#device-ownership-status-label',
    content: 'Successfully owned devices, are represented with by a green color and "Owned" status.',
    nextButtonEnabled: isOwnedDeviceSelected,
  },
  {
    title: 'Reset ownership',
    selector: '#device-details-footer',
    content:
      'Reset ownership causes a factory reset. This sets the device values to default and removes all the data associated with you. After this step, the device will be discovered as new, ready to be owned.',
    stepInteraction: false,
    nextButtonEnabled: isOwnedDeviceSelected,
  },
  {
    title: 'Congratulations!',
    selector: '',
    content: 'You have finished the Device tour!',
  },
]

export const stepsResources = ({
  isAcquisitionChannelResourceInTable,
  isSelectedResourceAcquisitionChannel,
  isResourceJSONUpdated,
  isResourceUpdated,
}) => [
  {
    title: '2. Resources',
    selector: '',
    content: () => (
      <div>
        <div>
          Each KiConnect device has its own Resources. What do they represent?
          <div className="img-container">
            <img src={tourAsset1} alt="Resources explanation block" />
          </div>
          Here is a hint. To know more, start this tour.
        </div>
      </div>
    ),
  },
  {
    title: 'What are resources?',
    selector: '#device-resources-table',
    content: () => (
      <div>
        Each device can be perceived as a constrained web server hosting its resources.
        <div className="img-container">
          <img src={tourAsset2} alt="Resources explanation block 2" />
        </div>
        It's a way how you can configure your device, command it or receive notifications about changes.
        {!isAcquisitionChannelResourceInTable && (
          <>
            <br />
            <br />
            <strong>You must use a device which has at least one channel (x.com.kistler.acquisition.channel).</strong>
          </>
        )}
      </div>
    ),
    mutationObservables: ['#device-resources-table'],
    stepInteraction: false,
    nextButtonEnabled: isAcquisitionChannelResourceInTable,
    stepId: stepIds.RESOURCES_OVERVIEW,
  },
  {
    title: 'Resource types',
    selector: '.types-x-com-kistler-acquisition-channel',
    content: () => (
      <div>
        Each resource has a type. This is a way how we standardize models on various Kistler devices. This allows
        applications to be more generic. If a new device is developed and follows well-defined models, the application
        can communicate with this device right away.
        <br />
        <br />
        Let's look at how a generic acquisition channel resource looks.
        <br />
        <br />
        <strong>Click on the highlighted area to select this resource.</strong>
      </div>
    ),
    nextButtonEnabled: isSelectedResourceAcquisitionChannel,
  },
  {
    title: 'Resource content',
    selector: '.jsoneditor-react-container',
    content: () => (
      <div>
        Content of the resource is encoded in{' '}
        <a href="https://cbor.io/" target="_blank" rel="noopener noreferrer">
          CBOR
        </a>
        . To be user friendly, we decode it for you to JSON. This content represents what is currently on the device.
      </div>
    ),
    stepInteraction: false,
    nextButtonEnabled: isSelectedResourceAcquisitionChannel,
  },
  {
    title: 'Resource update',
    selector: '#update-resource-button',
    content: () => (
      <div>
        To update values on the device, change values in the editor and remove those you don't want to update.
        <br />
        <br />
        <strong>We have edited this JSON for you.</strong>
      </div>
    ),
    stepInteraction: false,
    nextButtonEnabled: isResourceJSONUpdated,
  },
  {
    title: 'Resource update',
    selector: '#update-resource-button',
    content: 'Click update to update the acquisition channel resource on the device.',
    nextButtonEnabled: isResourceUpdated,
    stepId: stepIds.UPDATE_RESOURCE,
  },
  {
    title: 'Perfect!',
    selector: '.jsoneditor-react-container',
    content:
      'As you can see, the values were updated and based on the device logic, other values like dataOffset were modified as well.',
    stepInteraction: false,
  },
  {
    title: 'Subscription and Notifications',
    selector: '#resource-change-notification-switch',
    content:
      'As already mentioned, you can subscribe to changes on this resource. Each change using this client, or a different application, or even by using the device itself will trigger a notification for this client.',
    stepInteraction: false,
  },
  {
    title: 'Notification center',
    selector: '#nav-iot-hub-notifications',
    content: 'All notifications which occurred (your browser needs to be open) can be found here.',
    stepInteraction: false,
  },
  {
    title: 'Congratulations!',
    selector: '',
    content: 'You have finished the Resources tour!',
  },
]

export const stepsDatasources = ({
  isAcquisitionDatasourceInTable,
  isSelectedAcquisitionDatasource,
  zmqTabSelected,
  zmtStreamStarted,
}) => [
  {
    title: '3. Data Sources',
    selector: '.datasource-list-container',
    content: () => (
      <div>
        Data Sources represent the delivery mechanism for the measurement data. To start and stop the measurement, a
        data source has to be used.
        <br />
        <br />
        To know more, start this tour.
      </div>
    ),
    nextButtonEnabled: isAcquisitionDatasourceInTable,
    stepId: stepIds.DATASOURCE_TABLE,
  },
  {
    title: 'Select data source',
    selector: '.types-x-com-kistler-acquisition-datasource',
    content: () => (
      <div>
        We will work with the <strong>x.com.kistler.acquisition.datasource</strong>, so let's select it to continue the
        tour.
      </div>
    ),
    nextButtonEnabled: isSelectedAcquisitionDatasource,
  },
  {
    title: 'Supported encodings',
    selector: '.datasource-update-encoding',
    content: () => (
      <div>
        Each data source offers supported encodings. This may vary, but each SkyBase device running KiConnect supports
        kistler.stream.v2 which is a preferred encoding documented{' '}
        <a
          href="https://git.kistler.com/skybase/embedded/skybase-build/-/blob/master/doc/Manuals_and_Tutorials/DAQ_and_Measurements/Kistler_Streaming_Protocol_v2.md"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </div>
    ),
    stepInteraction: false,
  },
  {
    title: 'Configuration',
    selector: '.jsoneditor-react-container',
    content: () => (
      <div>
        Each data source has its own configuration. Some configuration is device-specific, some is generically defined
        by the x.com.kistler.acquisition.datasource type as documented{' '}
        <a
          href="https://wiki.kistler.com/display/KiConnect/Generic+Acquisition+Device"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </div>
    ),
    stepInteraction: false,
  },
  {
    title: 'Transfer methods',
    selector: '#datasource-tab-stream',
    content:
      'Data sources might support various transfer methods. Measurement data can be transferred using Kafka to the Kafka Server.',
    stepInteraction: false,
  },
  {
    title: 'ZMQ transfer method',
    selector: '#datasource-tab-zmqpubclient',
    content: "Or by ZMQ to your or built-in ZMQ server. Let's explore ZMQ.",
    nextButtonEnabled: zmqTabSelected,
  },
  {
    title: 'Endpoint',
    selector: '.datastream-zmq-endpoint',
    content: () => (
      <div>
        To start a ZMQ transfer, the ZMQ Server needs to be configured. Be aware that this IP needs to be accessible by
        the device!
        <br />
        Localhost probably won't work :)
      </div>
    ),
  },
  {
    title: 'IP and Port',
    selector: '.datastream-zmq-endpoint',
    content:
      'KiConnect Client hosts its own ZMQ server if configured in config.yaml. Add an IP and port of a PC running this KiConnect Client as set in the config.yaml.',
  },
  {
    title: 'Topic',
    selector: '.datastream-topic',
    content: 'The topic where your measurement data will be streamed to.',
  },
  {
    title: 'Time threshold',
    selector: '.datastream-time-treshold',
    content:
      'The data source publisher has its own cache. This threshold defines how often this cache is emptied – and pushed to your ZMQ server. Value "0" means immediately as the data become available.',
  },
  {
    title: 'Message threshold',
    selector: '.datastream-message-treshold',
    content:
      'Measurement data are from ZMQ perspective messages. The buffer might not be time-based but size-based. If the buffer contains 1000 messages, it will be emptied/pushed to your ZMQ server.',
  },
  {
    title: 'Encoding',
    selector: '.datastream-encoding',
    content: () => (
      <div>
        Encoding must be set to <strong>kistler.stream.v2</strong> in order to be able to see the visualized data on a
        graph.
      </div>
    ),
    highlightedSelectors: ['.datastream-encoding', '.menu.opened'],
    mutationObservables: ['.menu.opened'],
    resizeObservables: ['.menu.opened'],
  },
  {
    title: 'Start streaming',
    selector: '#datastream-button',
    content: 'If everything is configured as it should be, we can get the data!',
    nextButtonEnabled: zmtStreamStarted,
  },
  {
    title: 'Data visualization',
    selector: '#datastream-show-graph-button',
    content:
      'The KiConnect Client gives you the possibility to see what data are being sent from the device. This is a simple visualization valuable for debugging purposes.',
    stepInteraction: false,
  },
  {
    title: 'Congratulations!',
    selector: '',
    content: 'You have finished the Data Sources tour!',
  },
]
