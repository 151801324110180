import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'

import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { Sb21Layout } from 'skybase-ui/skybase-components/layouts'
import { ClientsList, CreateClientButton, ClientTab } from 'skybase-oauth/clients/components'

import { messages as menuT } from '@/common/menu/menu-i18n'
import { messages as t } from './clients-page-i18n'

export class _ClientsPage extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
  }

  render() {
    const {
      intl: { formatMessage: _ },
    } = this.props

    return (
      <Sb21Layout
        title={_(t.pageName)}
        breadcrumbs={[
          {
            path: '/',
            title: _(menuT.home),
          },
          {
            path: '/identity-management',
            title: _(menuT.identityManagement),
          },
          {
            path: '/identity-management/oauth-management',
            title: _(menuT.oAuthManagementPage),
          },
          _(t.pageName),
        ]}
      >
        <div>
          <div className="sb-heading fl-row fl-align-items-center">
            <h1>{_(t.pageName)}</h1>
            <CreateClientButton />
          </div>
          <ClientsList />
        </div>
        <ClientTab />
      </Sb21Layout>
    )
  }
}

export const ClientsPage = injectIntl(_ClientsPage)
