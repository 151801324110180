import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbFileInput } from 'skybase-ui/skybase-components/sb-file-input/sb-file-input'
import { showErrorToast, showSuccessToast } from '@/common/services/show-toast'
import { SNIPPET_SALT } from '@/fleet-configuration/data-fleet/snippets/snippets-constants'
import {
  createSnippetWithData,
  loadSnippetDetailById,
  loadSnippets,
  updateSnippet,
} from '@/fleet-configuration/data-fleet/snippets/snippets-actions'
import { loadDevicesCatalog } from '@/fleet-configuration/data-fleet/catalog/catalog-actions'
import { areSnippetsLoaded, getFullSnippets } from '@/fleet-configuration/data-fleet/snippets/snippets-selectors'
import { showConfirmModal } from '@/fleet-configuration/components/confirm-modal/confirm-modal-actions'
import { sha256 } from '@/utils/sha256'
import { messages as t } from './import-snippet-i18n'
import './import-snippet.scss'

class _ImportSnippet extends React.Component {
  static propTypes = {
    ViewBox: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
    intl: intlShape.isRequired,
    dispatch: PropTypes.func.isRequired,
    snippetsLoaded: PropTypes.bool.isRequired,
    snippets: PropTypes.array.isRequired,
    snippetId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  static defaultProps = {
    snippetId: null,
  }

  handleOnClick = () => {
    this.fileInput.click()
  }

  handleOnReceiveFiles = async files => {
    const {
      intl: { formatMessage: _ },
      dispatch,
      snippetsLoaded,
      snippetId,
    } = this.props

    if (!snippetsLoaded) {
      await dispatch(loadSnippets())
    }

    const { snippets } = this.props
    Array.from(files).forEach(file => {
      if (!file.type.match('application/json')) {
        showErrorToast(_(t.theImportedFileIsNotSupported, { file: file.name }))
        return
      }

      const reader = new FileReader()
      reader.onload = async evt => {
        this.fileInput.value = null

        let fileContent
        try {
          fileContent = JSON.parse(evt.target.result)
        } catch (e) {
          showErrorToast(_(t.theImportedFileIsMalformed, { file: file.name }))
          return
        }
        // check structure and file integrity
        if (
          !fileContent.snippet ||
          !fileContent.fingerprint ||
          sha256(SNIPPET_SALT + JSON.stringify(fileContent.snippet) + SNIPPET_SALT) !== fileContent.fingerprint
        ) {
          showErrorToast(_(t.theImportedFileIsMalformed, { file: file.name }))
          return
        }

        await dispatch(loadDevicesCatalog([fileContent.snippet.originalDeviceConfiguration]))
        // if it's edit snippet
        if (snippetId) {
          // check if the snippet is already loaded in the system
          const existingSnippet = snippets.find(snippet => snippet.id === snippetId)
          if (existingSnippet) {
            dispatch(
              showConfirmModal(
                _,
                _(t.updateExistingSnippet),
                updateSnippet({ ...fileContent.snippet, id: snippetId }),
                {
                  modalMessage: _(t.theContentOfTheExistingSnippetSnippetWillBeOverridden, {
                    snippet: existingSnippet.name,
                  }),
                  confirmText: _(t.import),
                  afterConfirmAction: () => {
                    showSuccessToast(_(t.snippetWasSuccessfullyImported))
                  },
                },
              ),
            )
          } else {
            showErrorToast(_(t.theSystemWasUnableToFindSelectedSnippet))
          }
          return
        }

        // handle case where creating brand new snippet
        const { id, created, lastChanged, ...newSnippet } = fileContent.snippet
        const { id: newId } = await dispatch(createSnippetWithData(newSnippet))
        showSuccessToast(_(t.snippetWasSuccessfullyImported))
        // reload snippet to get "created time" (which is server time, not browser time)
        dispatch(loadSnippetDetailById(newId))
      }

      reader.readAsText(file, 'utf-8')
    })
  }

  render() {
    const { ViewBox } = this.props
    return (
      <>
        <ViewBox onClick={this.handleOnClick} />
        <SbFileInput
          id="import-snippet-file-input"
          containerClassName="import-snippet-file-input"
          onReceiveFiles={this.handleOnReceiveFiles}
          inputRef={fileInput => {
            this.fileInput = fileInput
          }}
        />
      </>
    )
  }
}

const importPresetSelector = state => ({
  snippetsLoaded: !!areSnippetsLoaded(state),
  snippets: getFullSnippets(state),
})

export const ImportSnippet = connect(importPresetSelector)(injectIntl(_ImportSnippet))
