/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  identifyCalled: {
    id: 'wizardMenu.identifyCalled',
    defaultMessage: 'Identify called',
  },
  identifyDevice: {
    id: 'wizardMenu.identifyDevice',
    defaultMessage: 'Identify device',
  },
  identifyCantBeCalledOnOfflineDevice: {
    id: 'wizardMenu.identifyCantBeCalledOnOfflineDevice',
    defaultMessage: "Identify can't be called on offline device",
  },
  online: {
    id: 'wizardMenu.online',
    defaultMessage: 'Online',
  },
  offline: {
    id: 'wizardMenu.offline',
    defaultMessage: 'Offline',
  },
  calibrationExpired: {
    id: 'configurationHardwareSetup.calibrationExpired',
    defaultMessage: 'Calibration expired',
  },
  calibrationStatusUnknown: {
    id: 'configurationHardwareSetup.calibrationStatusUnknown',
    defaultMessage: 'Calibration status unknown',
  },
  unknownCableComponent: {
    id: 'configurationHardwareSetup.unknownCableComponent',
    defaultMessage: 'Unknown cable component',
  },
  incompatibleWithDeviceDueToMismatchingConnectors: {
    id: 'configurationHardwareSetup.incompatibleWithDeviceDueToMismatchingConnectors',
    defaultMessage: 'Incompatible with device due to mismatching connectors',
  },
  compatibilityWithSensorGuaranteed: {
    id: 'configurationHardwareSetup.compatibilityWithSensorGuaranteed',
    defaultMessage: 'Compatibility with sensor guaranteed',
  },
})
