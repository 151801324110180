import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { showWarningToast, showSuccessToast } from 'skybase-ui/skybase-components/sb-toastr'
import { getDeviceById } from '@/fleet-configuration/data-fleet/devices/devices-selectors'
import { deviceEventStatusList, deviceEventStatusToOnlineMap } from './device-online-status-constants'
import { messages as t } from './device-online-status-listener-i18n'
import { hasDeviceGoneFromOnlineToOffline, hasDeviceGoneFromOfflineToOnline } from './device-online-status-utils'

export const SET_DEVICE_ONLINE_STATUS = getActionName('SET_DEVICE_ONLINE_STATUS')
export const setDeviceOnlineStatus = (deviceId, status) =>
  createAction(SET_DEVICE_ONLINE_STATUS, {
    deviceId,
    status: status ? status.toLowerCase() : deviceEventStatusList.UNKNOWN,
  })

const unresolvedDeviceOnlineStatusMessages = {}

export const getUnresolvedDeviceOnlineStatusMessages = () => unresolvedDeviceOnlineStatusMessages

export const resolveDeviceOnlineStatusMessage = deviceId => delete unresolvedDeviceOnlineStatusMessages[deviceId]

export const notifyProjectDeviceOnlineStatusChangeShowMessage = (device, status, dispatch) => {
  const toastParams = [{ closeButton: true, hideTime: 4000 }]

  if (deviceEventStatusToOnlineMap[status] === true) {
    dispatch(
      showSuccessToast(
        { message: { message: t.deviceDeviceWentOnline, params: { device: device.name || device.id } } },
        ...toastParams,
      ),
    )
  } else {
    dispatch(
      showWarningToast(
        { message: { message: t.deviceDeviceWentOffline, params: { device: device.name || device.id } } },
        ...toastParams,
      ),
    )
  }
}

export const notifyProjectDeviceOnlineStatusChange = message => async (dispatch, getState) => {
  const { deviceId, status, isReplayed } = message
  const state = getState()
  const device = getDeviceById(state, deviceId)

  const goneOnline = hasDeviceGoneFromOfflineToOnline(state, deviceId, status)
  if (isReplayed || (!goneOnline && !hasDeviceGoneFromOnlineToOffline(state, deviceId, status))) {
    return
  }
  if (!device) {
    if (goneOnline) {
      unresolvedDeviceOnlineStatusMessages[deviceId] = message
    }
    return
  }

  resolveDeviceOnlineStatusMessage(deviceId)
  notifyProjectDeviceOnlineStatusChangeShowMessage(device, status, dispatch)
}
