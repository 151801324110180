import { getStudioAPIHost } from '@/utils/url'
import { equipmentApi } from '@/fleet-configuration/equipment/constants'
import { OAuth } from 'skybase-oauth'

export const storeEquipmentItem = (data, isUpdate) => {
  // remove starting/trailing spaces
  const storeAdjustedData = Object.entries(data).reduce((acc, [key, value]) => {
    acc[key] = value?.trim?.()
    return acc
  }, {})
  // type number is always upper-cased
  storeAdjustedData.typeNumber = storeAdjustedData.typeNumber.toUpperCase()

  const url = `${getStudioAPIHost()}${equipmentApi.EQUIPMENT}${isUpdate ? `/${data.id}` : ''}`
  return OAuth.fetchOAuth(url, {
    method: isUpdate ? 'PUT' : 'POST',
    body: JSON.stringify(storeAdjustedData),
  })
}
