import { openModal } from 'skybase-ui/skybase-core/base/actions'

import { SIMPLE_MODAL_ID } from './constants'

/**
 *
 * @param className String - Class name of the modal.
 * @param buttons Array - List of objects containing buttons. Example: {
          position: 'left', // Position of the button in modal.
          title: 'Left button', // Title of the button.
          action: myCustomAction, // Redux action to be dispatched.
          className: 'my-custom-class', // Custom className for a button in modal.
        },
 * @param message Node - String, component, any node to appear in as a body in the modal.
 * @param title String - Title of the modal
 * @param onClickOverlay Function - Function to be called on overlay and close button click.
 * @param closeButton Boolean - If true, renders a close button.
 */
export const showSimpleModal = ({ className, buttons, message, title, ...rest } = {}) =>
  openModal(SIMPLE_MODAL_ID, {
    className,
    buttons,
    message,
    title,
    ...rest,
  })
