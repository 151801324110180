import { intersection } from 'lodash'
import { areOnlyActiveChannelsShown } from '@/fleet-configuration/data-fleet/devices-show-only-active/devices-show-only-active-selector'
import { selectMeasurementUnitParams } from '@/fleet-configuration/select-measurement-unit-params'
import { getProjectDeviceById } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import { NOT_CALIBRABLE_DEVICE_TYPES } from '@/fleet-configuration/pages/fleet-overview/fleet-overview-constants'
import { getDeviceType, hasLED } from '@/fleet-configuration/utils/device-utils'
import { getChainsByModuleDetails } from '@/fleet-configuration/data-fleet/chain/chain-selectors'
import { getChainCatalogEntry } from '@/fleet-configuration/data-fleet/chain-catalog/chain-catalog-selectors'
import { getExtendedDeviceCalibrationByDeviceId } from '@/fleet-configuration/data-fleet/devices-calibrations/devices-calibrations-selector'
import { getSensorCalibrationStatus } from '@/fleet-configuration/data-fleet/chain-certificate/chain-certificate-selector'
import { getCableCompatibilityStatus } from '@/fleet-configuration/utils/cable-utils'

export const wizardMenuSelector = (state, props) => {
  const { unitId, moduleIndex, channelIndex } = selectMeasurementUnitParams(state, props)
  const onlyActive = areOnlyActiveChannelsShown(state)
  const device = props.explicitDeviceRef || getProjectDeviceById(state, unitId)
  const deviceType = getDeviceType(device?.types || [])
  const isDeviceCalibrable = device && !intersection(NOT_CALIBRABLE_DEVICE_TYPES, device.types).length
  const deviceCalibration = (isDeviceCalibrable && getExtendedDeviceCalibrationByDeviceId(state, unitId)) || {}

  let deviceChain
  let deviceChainWithNamesAndCalibrationStatus
  if (device) {
    // moduleIndexToChainIndexMap speeds up lookup for module
    //  technically it's not really needed, as we could always search all modules by type number and serial number,
    //  but as the chain is sorted by the way we are creating "deviceChain", we don't need to do it that way.
    //  Instead we can mark index of chain items of when are we using next module in list
    const moduleIndexToChainIndexMap = [0]
    deviceChain = device.modules.reduce((acc, module) => {
      const moduleTypeNumber = module.getType()
      const moduleSerialNumber = module.parametersReadable?.snr || device.serialNumber
      acc.push(...getChainsByModuleDetails(state, moduleTypeNumber, moduleSerialNumber))
      moduleIndexToChainIndexMap.push(acc.length)
      return acc
    }, [])

    // Idea here is - deviceChainWithNamesAndCalibrationStatus is deviceChain + additional information (basically .map to extend the item)
    //  the reason why I don't directly use `deviceChain.map` is, that we need correct module for every chain item.
    //  to get that, we can directly assign it via usage of moduleIndexToChainIndexMap - as it directly maps chain index to module
    //  So we do have direct information of which device chain index corresponds to which module.
    //  So using this idea we go about it like this:
    //  For each moduleIndexToChainIndexMap interval fetch immediately correct module and then go for all indexes in that interval and get those data.
    deviceChainWithNamesAndCalibrationStatus = new Array(deviceChain.length)
    for (let i = 0; i < moduleIndexToChainIndexMap.length - 1; i += 1) {
      const module = device.modules[i]
      for (let j = moduleIndexToChainIndexMap[i]; j < moduleIndexToChainIndexMap[i + 1]; j += 1) {
        const chainEntry = deviceChain[j]
        const cableCatalog = chainEntry.cable?.typeNumber
          ? getChainCatalogEntry(state, chainEntry.cable.typeNumber)
          : undefined
        const sensorCatalog = chainEntry.sensor?.typeNumber
          ? getChainCatalogEntry(state, chainEntry.sensor.typeNumber)
          : undefined

        const compatibilityStatus = getCableCompatibilityStatus(cableCatalog, sensorCatalog, module)

        deviceChainWithNamesAndCalibrationStatus[j] = {
          ...chainEntry,
          sensor: {
            ...chainEntry.sensor,
            calibrationStatus:
              chainEntry.sensor?.typeNumber &&
              chainEntry.sensor.serialNumber &&
              getSensorCalibrationStatus(state, chainEntry.sensor?.typeNumber, chainEntry.sensor?.serialNumber),
            name: sensorCatalog?.name,
          },
          cable: {
            ...chainEntry.cable,
            compatibilityStatus,
            name: cableCatalog?.name,
          },
        }
      }
    }
  } else {
    deviceChain = []
    deviceChainWithNamesAndCalibrationStatus = []
  }

  return {
    onlyActive,
    deviceId: unitId,
    moduleIndex,
    channelIndex,
    device,
    isDeviceCalibrable,
    hasLED: hasLED(deviceType),
    deviceChain,
    deviceChainWithNamesAndCalibrationStatus,
    deviceCalibration,
  }
}
