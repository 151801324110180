/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  home: {
    id: 'configurationSnippets.home',
    defaultMessage: 'Home',
  },
  presets: {
    id: 'configurationSnippets.presets',
    defaultMessage: 'Presets',
  },
  name: {
    id: 'configurationSnippets.name',
    defaultMessage: 'Name',
  },
  created: {
    id: 'configurationSnippets.created',
    defaultMessage: 'Created',
  },
  lastChange: {
    id: 'configurationSnippets.lastChange',
    defaultMessage: 'Last Change',
  },
  noPresetsFound: {
    id: 'configurationSnippets.noPresetsFound',
    defaultMessage: 'No Presets Found',
  },
  fleetOverview: {
    id: 'configurationSnippets.fleetOverview',
    defaultMessage: 'Hardware',
  },
  deletePreset: {
    id: 'configurationSnippets.deletePreset',
    defaultMessage: 'Delete preset',
  },
  areYouSureYouWantToDeletePresetPreset: {
    id: 'configurationSnippets.areYouSureYouWantToDeletePresetPreset',
    defaultMessage: 'Are you sure you want to delete "{preset}" preset?',
  },
  thisActionCannotBeUndone: {
    id: 'configurationSnippets.thisActionCannotBeUndone',
    defaultMessage: 'This action cannot be undone',
  },
  delete: {
    id: 'configurationSnippets.delete',
    defaultMessage: 'Delete',
  },
  presetDeleted: {
    id: 'configurationSnippets.presetDeleted',
    defaultMessage: 'Preset deleted',
  },
  applyToDevices: {
    id: 'configurationSnippets.applyToDevices',
    defaultMessage: 'Apply to devices',
  },
  exportPreset: {
    id: 'configurationSnippets.exportPreset',
    defaultMessage: 'Export Preset',
  },
  importPreset: {
    id: 'configurationSnippets.importPreset',
    defaultMessage: 'Import Preset',
  },
  import: {
    id: 'configurationSnippets.import',
    defaultMessage: 'Import',
  },
  configurePreset: {
    id: 'configurationSnippets.configurePreset',
    defaultMessage: 'Configure preset',
  },
  devices: {
    id: 'configuration.devices',
    defaultMessage: 'Devices',
  },
  configuration: {
    id: 'configurationSnippets.configuration',
    defaultMessage: 'Configuration',
  },
})
