import { v4 } from 'uuid'
import { uniqWith, isEqual, get } from 'lodash'

const getOptionData = (item, valueKey, descriptionKey, keyProp) => {
  if (valueKey) {
    const result = {
      value: get(item, valueKey),
      key: item[keyProp] || get(item, valueKey),
    }
    const usedDescriptionKey =
      descriptionKey instanceof Array ? descriptionKey.find(key => key in item) : descriptionKey
    result.description = get(item, usedDescriptionKey || valueKey)
    return result
  }
  return {
    value: item,
    description: item,
    key: item,
  }
}

export const mapItemsToOptions = (items, valueKey, descriptionKey, keyProp) => {
  const filteredItems = valueKey
    ? items.filter(item => {
        if ((item.label && item.options) || (typeof item === 'object' && get(item, valueKey) !== undefined)) {
          return true
        }
        return false
      })
    : items
  return filteredItems.map(item => {
    if (item.label && item.options) {
      return {
        label: item.label,
        name: item.label,
        key: item[keyProp] || v4(),
        options: item.options.map(subItem => getOptionData(subItem, valueKey, descriptionKey)),
      }
    }
    return getOptionData(item, valueKey, descriptionKey, keyProp)
  })
}

export const mapOptions = (items, valueKey, descriptionKey, keyProp) =>
  uniqWith(mapItemsToOptions(items, valueKey, descriptionKey, keyProp || 'key'), isEqual)
