import {
  DATA_SOURCE_WS_KEY,
  supportedTransferMethods,
  transferFormObject,
  kafkaTransferFormObject,
  zeroMQTransferFormObject,
  DEFAULT_BROKER_VERIFY_TIMEOUT_MS,
  dataSourceStates,
} from './constants'
import { messages as t } from './datasources-i18n'

const { STREAM, ZMQPUBCLIENT } = supportedTransferMethods
const { ENDPOINTS, TOPIC, TIME_TRESHOLD, MESSAGE_TRESHOLD, ENCODING } = transferFormObject
const { BROKER_LIST, TOPIC_NAME, TRESHOLD_MESSAGE_COUNT, TRESHOLD_LINGER_MS, BROKER_VERIFY_TIMEOUT_MS } =
  kafkaTransferFormObject
const { ENDPOINT, TRESHOLD_FRAME_COUNT, TRESHOLD_TIME_MS } = zeroMQTransferFormObject
const { STARTED, STOPPED, DEVICE_ERROR, INTERNAL_ERROR } = dataSourceStates

export const getDataSourceWSID = (deviceId, dataSourceId) => `${DATA_SOURCE_WS_KEY}-${deviceId}-${dataSourceId}`

// Takes a data object and converts it to a transfer form data based on the transfer method
export const mapDataToTransferForm = (data, method) => {
  if (method === STREAM) {
    return {
      [ENDPOINTS]: data[BROKER_LIST] ?? [],
      [TOPIC]: data[TOPIC_NAME] ?? '',
      [TIME_TRESHOLD]: data[TRESHOLD_LINGER_MS] ?? 0,
      [MESSAGE_TRESHOLD]: data[TRESHOLD_MESSAGE_COUNT] ?? 0,
      [ENCODING]: data[ENCODING] ?? '',
      [BROKER_VERIFY_TIMEOUT_MS]: data[BROKER_VERIFY_TIMEOUT_MS] ?? DEFAULT_BROKER_VERIFY_TIMEOUT_MS,
    }
  }

  if (method === ZMQPUBCLIENT) {
    return {
      [ENDPOINTS]: data[ENDPOINT] ?? '',
      [TOPIC]: data[TOPIC_NAME] ?? '',
      [TIME_TRESHOLD]: data[TRESHOLD_TIME_MS] ?? 0,
      [MESSAGE_TRESHOLD]: data[TRESHOLD_FRAME_COUNT] ?? 0,
      [ENCODING]: data[ENCODING] ?? '',
    }
  }

  return {}
}

// Takes a transfer form data and converts it back to a data object based on the transfer method
export const mapDataFromTransferForm = (data, method) => {
  if (method === STREAM) {
    return {
      [BROKER_LIST]: data[ENDPOINTS],
      [TOPIC_NAME]: data[TOPIC],
      [TRESHOLD_MESSAGE_COUNT]: data[MESSAGE_TRESHOLD],
      [TRESHOLD_LINGER_MS]: data[TIME_TRESHOLD],
      [ENCODING]: data[ENCODING],
      [BROKER_VERIFY_TIMEOUT_MS]: data[BROKER_VERIFY_TIMEOUT_MS],
    }
  }

  if (method === ZMQPUBCLIENT) {
    return {
      [ENDPOINT]: data[ENDPOINTS],
      [TOPIC_NAME]: data[TOPIC],
      [TRESHOLD_FRAME_COUNT]: data[MESSAGE_TRESHOLD],
      [TRESHOLD_TIME_MS]: data[TIME_TRESHOLD],
      [ENCODING]: data[ENCODING],
    }
  }

  return {}
}

// Normalize the width of the graf which is sent to the WS (half of the real width)
export const normalizeWidth = width => parseInt(width / 2, 10)

// Add a datapoint to the chart
export const addData = (chart, data) => {
  // data -> [ [{}], [{}] ]
  for (let i = 0; i < data.length; i += 1) {
    for (let a = 0; a < data[i].length; a += 1) {
      chart.data.datasets[a].data.splice(0, 1)
      chart.data.datasets[a].data = chart.data.datasets[a].data.concat(data[i][a])
    }
  }
}

// Returns the translatable intl object for the datasource state
export const getIntlDataSourceState = state => {
  switch (state) {
    case STARTED:
      return t.started
    case STOPPED:
      return t.stopped
    case DEVICE_ERROR:
      return t.deviceError
    case INTERNAL_ERROR:
      return t.internalError
    default:
      return t.unknown
  }
}
