import React, { useEffect } from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { loaderSize } from 'skybase-ui/skybase-components/sb-loader/constants'
import { SbFullLayout } from 'skybase-ui/skybase-components/layouts/sb-full-layout'
import { SbHint } from 'skybase-ui/skybase-components/sb-hint'
import { openModal } from 'skybase-ui/skybase-core/base/actions'
import { withRouter } from '@/common/router'
import { GREEN, RED, StatusBullet } from '@/common/status-bullet'
import { ReactComponent as ChannelIcon } from '@/fleet-configuration/svg-components/channel.svg'
import { configurationHardwareSetupSelector } from '@/fleet-configuration/pages/configuration/configuration-hardware-setup/configuration-hardware-setup-selector'
import { loadProjectDeviceIfEmpty } from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { WizardButtons } from '@/fleet-configuration/page-components/wizard/wizard-buttons/wizard-buttons'
import { WizardNavigation } from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation'
import {
  configurationDetailBreadcrumbs,
  configurationDetailHeading,
} from '@/fleet-configuration/pages/configuration/configuration-detail-components'
import { CALIBRATION_STATUSES } from '@/fleet-configuration/data-fleet/chain-certificate/chain-certificate-constants'
import { loadMissingCalibrations } from '@/fleet-configuration/data-fleet/calibration/calibration-actions'
import { WizardMenu } from '@/fleet-configuration/page-components/wizard/wizard-menu/wizard-menu'
import {
  ADD_COMPONENT_MODAL_ID,
  addComponentModalPossibleComponents,
} from '@/fleet-configuration/page-components/wizard/add-component-modal/add-component-modal-constants'
import { messages as t } from './configuration-hardware-setup-i18n'
import './configuration-hardware-setup.scss'
import { CABLE_COMPATIBILITY } from '@/fleet-configuration/utils/cable-utils-constants'
import { fleetInit } from '@/fleet-configuration/fleet-init'
import { intersection } from 'lodash'
import { NOT_CALIBRABLE_DEVICE_TYPES } from '@/fleet-configuration/pages/fleet-overview/fleet-overview-constants'

export const ConfigurationHardwareSetup = withRouter(props => {
  const {
    device,
    deviceId,
    moduleIndex,
    channelIndex,
    onlyActive,
    deviceChain,
    deviceChainWithNamesAndCalibrationStatus,
    deviceCalibration,
  } = useSelector(state => configurationHardwareSetupSelector(state, props))
  const dispatch = useDispatch()
  const { formatMessage: _ } = useIntl()
  const navigate = useNavigate()

  const isCalibrable = device && !intersection(NOT_CALIBRABLE_DEVICE_TYPES, device.types).length

  useEffect(() => {
    dispatch(fleetInit(_))
  }, [_, dispatch])

  useEffect(() => {
    dispatch(loadProjectDeviceIfEmpty(deviceId))
  }, [deviceId, dispatch])

  useEffect(() => {
    if (!deviceChain?.length || !isCalibrable) {
      return
    }
    dispatch(
      loadMissingCalibrations(
        deviceChain
          .filter(chainItem => chainItem.sensor?.typeNumber && chainItem.sensor.serialNumber)
          .map(chainItem => chainItem.sensor),
      ),
    )
  }, [deviceChain, isCalibrable, dispatch]) // can't use deviceChainWithNamesAndCalibrationStatus as that one is being recreated all the time which would run this effect all the time

  return (
    <SbFullLayout
      title={device ? device.name : undefined}
      className="configuration-detail-page skybase-fixes"
      breadcrumbs={configurationDetailBreadcrumbs(_, device)}
    >
      {device ? (
        <div className="p-r-20 fl-container-column">
          {configurationDetailHeading(device)}
          <WizardNavigation deviceId={deviceId} step={0} />
          <div className="fl-container-row fl-grow-1 fl-wrap-wrap menu-content-wrapper">
            <WizardMenu baseUrl="/configuration/hardware-setup/" explicitDeviceRef={device} showContextMenu />
            <div className="configuration-hardware-setup-content configuration-detail-content fl-grow-1">
              <div className="components-section">
                <div className="component-description-wrapper">
                  <div className="component-description m-b-20">
                    <StatusBullet
                      title={device.online ? _(t.online) : _(t.offline)}
                      status={device.online ? GREEN : RED}
                    />
                    {device.name}
                  </div>
                </div>
                {device.modules[0] &&
                  (() => {
                    return (
                      <>
                        <div className="device-part-contour">
                          {device.modules.map(module => {
                            const numChannels = module[onlyActive ? 'getActiveChannels' : 'getChannels']().length
                            if (numChannels === 0) {
                              return null
                            }
                            return (
                              <div
                                className={classNames('module-part-contour', {
                                  'selected-module': moduleIndex === module.getModuleId(),
                                })}
                                style={{ height: numChannels * 80 }}
                                onClick={() => {
                                  navigate(`/configuration/hardware-setup/${deviceId}/module/${module.getModuleId()}`)
                                }}
                              >
                                <span>{module.getType()}</span>
                              </div>
                            )
                          })}
                        </div>
                        {isCalibrable && (
                          <div className="device-calibration-status">
                            {(() => {
                              if (!deviceCalibration.isLoaded) {
                                return <SbLoader size={loaderSize.XXS} show />
                              }
                              if (deviceCalibration.isExpiring) {
                                return (
                                  <SbHint
                                    hintData={_(t.calibrationExpired)}
                                    position={{ vertical: 'top', horizontal: 'center' }}
                                  >
                                    <span className="sbi-help-info calibration-information" />
                                  </SbHint>
                                )
                              }
                              if (deviceCalibration.calibrationDate) {
                                return <span className="sbi-big-success calibration-valid" />
                              }
                              return (
                                <SbHint
                                  hintData={_(t.calibrationStatusUnknown)}
                                  position={{ vertical: 'top', horizontal: 'center' }}
                                >
                                  <span className="sbi-big-hint calibration-unknown" />
                                </SbHint>
                              )
                            })()}
                          </div>
                        )}
                      </>
                    )
                  })()}
                {device.modules.map(module => {
                  const rowModuleIndex = module.getModuleId()
                  const moduleTypeNumber = module.getType()
                  const moduleSerialNumber = module.parametersReadable?.snr || device.serialNumber
                  const moduleChannels = module[onlyActive ? 'getActiveChannels' : 'getChannels']()
                  return moduleChannels.map((channel, iterationChannelIndex) => {
                    const rowChannelIndex = channel.getChannelIndex()
                    const rowChannelSlot = `channels/${rowChannelIndex + 1}`
                    const currentChainItem = deviceChainWithNamesAndCalibrationStatus.find(
                      chain =>
                        chain.channel.slot === rowChannelSlot &&
                        chain.channel.typeNumber === moduleTypeNumber &&
                        chain.channel.serialNumber === moduleSerialNumber,
                    )
                    return (
                      <div
                        key={`${deviceId}/${channel.resourcePath}`}
                        className={classNames('components-row fl-container-row', {
                          'components-row-selected': rowChannelIndex === channelIndex && moduleIndex === rowModuleIndex,
                          'last-channel-in-module': iterationChannelIndex === moduleChannels.length - 1,
                        })}
                        onClick={() => {
                          navigate(
                            `/configuration/hardware-setup/${deviceId}/module/${rowModuleIndex}/channel/${rowChannelIndex}`,
                          )
                        }}
                      >
                        <div
                          className={`sensor-component configuration-component ${
                            currentChainItem?.sensor?.typeNumber ? 'component-filled' : 'sbi-plus-alt'
                          }`}
                          onClick={() => {
                            dispatch(
                              openModal(ADD_COMPONENT_MODAL_ID, {
                                deviceId: device.id,
                                moduleIndex: rowModuleIndex,
                                channelIndex: rowChannelIndex,
                                componentToAdd: addComponentModalPossibleComponents.SENSOR,
                              }),
                            )
                          }}
                        >
                          {(() => {
                            if (!currentChainItem?.sensor?.calibrationStatus) {
                              return null
                            }
                            if (currentChainItem.sensor.calibrationStatus === CALIBRATION_STATUSES.VALID) {
                              return <span className="sensor-certificate-status sbi-big-success calibration-valid" />
                            }
                            if (currentChainItem.sensor.calibrationStatus === CALIBRATION_STATUSES.EXPIRED) {
                              return (
                                <SbHint hintData={_(t.calibrationExpired)}>
                                  <span className="sensor-certificate-status sbi-help-info calibration-information" />
                                </SbHint>
                              )
                            }
                            return (
                              <SbHint hintData={_(t.calibrationStatusUnknown)}>
                                <span className="sensor-certificate-status sbi-big-hint calibration-unknown" />
                              </SbHint>
                            )
                          })()}
                          <span className="component-name">{currentChainItem?.sensor?.name}</span>
                          {currentChainItem?.sensor?.typeNumber && <span className="component-icon" />}
                        </div>
                        <div
                          className={classNames('component-line', {
                            'filled-line': currentChainItem?.sensor?.typeNumber,
                          })}
                        />
                        {(() => {
                          const cableComponent = (
                            <div
                              className={(() => {
                                let resultingClasses = 'cable-component configuration-component'
                                if (currentChainItem?.cable?.typeNumber) {
                                  resultingClasses += ' component-filled'
                                  if (currentChainItem.cable.compatibilityStatus === CABLE_COMPATIBILITY.INVALID) {
                                    resultingClasses += ' sbi-big-error error-status'
                                  } else if (currentChainItem.cable.compatibilityStatus === CABLE_COMPATIBILITY.VALID) {
                                    resultingClasses += ' sbi-big-success success-status'
                                  } else {
                                    resultingClasses += ' sbi-minus-circle unknown-status'
                                  }
                                } else {
                                  resultingClasses += ' sbi-plus-alt'
                                }
                                return resultingClasses
                              })()}
                              onClick={() => {
                                dispatch(
                                  openModal(ADD_COMPONENT_MODAL_ID, {
                                    deviceId: device.id,
                                    moduleIndex: rowModuleIndex,
                                    channelIndex: rowChannelIndex,
                                    componentToAdd: addComponentModalPossibleComponents.CABLE,
                                  }),
                                )
                              }}
                            >
                              <span
                                className="component-name"
                                title={
                                  currentChainItem?.cable?.name?.length > 45 ? currentChainItem.cable.name : undefined
                                }
                              >
                                {currentChainItem?.cable?.name || currentChainItem?.cable?.typeNumber}
                              </span>
                            </div>
                          )
                          if (currentChainItem?.cable?.compatibilityStatus === CABLE_COMPATIBILITY.INVALID) {
                            return (
                              <SbHint hintData={_(t.incompatibleWithDeviceDueToMismatchingConnectors)}>
                                {cableComponent}
                              </SbHint>
                            )
                          }
                          if (currentChainItem?.cable?.compatibilityStatus === CABLE_COMPATIBILITY.VALID) {
                            return <SbHint hintData={_(t.compatibilityWithSensorGuaranteed)}>{cableComponent}</SbHint>
                          }
                          if (
                            currentChainItem?.cable?.typeNumber &&
                            currentChainItem.cable.compatibilityStatus === CABLE_COMPATIBILITY.NONE
                          ) {
                            return <SbHint hintData={_(t.unknownCableComponent)}>{cableComponent}</SbHint>
                          }
                          return cableComponent
                        })()}

                        <div
                          className={classNames('component-line', {
                            'filled-line': currentChainItem?.cable?.typeNumber,
                          })}
                        />
                        <div
                          className={classNames('channel-component configuration-component', {
                            'channel-connected': currentChainItem?.cable?.typeNumber,
                          })}
                        >
                          {device.isLabAmp() || (device.isKGate() && module.getType() === '5509A') ? (
                            <ChannelIcon />
                          ) : (
                            <div className="generic-channel" />
                          )}
                        </div>
                      </div>
                    )
                  })
                })}
              </div>
            </div>
          </div>
          <WizardButtons deviceId={deviceId} step={0} />
        </div>
      ) : (
        <SbLoader show size={loaderSize.L} />
      )}
    </SbFullLayout>
  )
})
