/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  hardwareSetup: {
    id: 'wizardConstants.hardwareSetup',
    defaultMessage: 'Hardware setup',
  },
  settings: {
    id: 'wizardConstants.settings',
    defaultMessage: 'Settings',
  },
  summary: {
    id: 'wizardConstants.summary',
    defaultMessage: 'Summary',
  },
})
