import { intersection } from 'lodash'
import {
  KGATE_DEVICE_TYPE,
  LABAMP_DEVICE_TYPE,
  FORCE_PLATE_DEVICE_TYPE,
  ACQUISITION_DEVICE_TYPE,
  CONFIGURABLE_DEVICE_TYPES,
  BIO_SYNC_BOX_DEVICE_TYPE,
  BIO_START_BLOCK_DEVICE_TYPE,
  BIO_DAQ_BOX_DEVICE_TYPE,
} from '@/fleet-configuration/pages/fleet-overview/fleet-overview-constants'
import { DEVICE_TYPE } from '@/fleet-configuration/page-components/devices-table-renderer/devices-table-renderer-constants'

export const getDeviceType = types => {
  let deviceType = null
  const { KIDAQ, LABAMP, FORCE_PLATE, BIO_SYNC_BOX, BIO_DAQ_BOX, BIO_START_BLOCK } = DEVICE_TYPE

  if (types.includes(KGATE_DEVICE_TYPE)) {
    deviceType = KIDAQ
  } else if (types.includes(LABAMP_DEVICE_TYPE)) {
    deviceType = LABAMP
  } else if (types.includes(FORCE_PLATE_DEVICE_TYPE)) {
    deviceType = FORCE_PLATE
  } else if (types.includes(BIO_SYNC_BOX_DEVICE_TYPE)) {
    deviceType = BIO_SYNC_BOX
  } else if (types.includes(BIO_START_BLOCK_DEVICE_TYPE)) {
    deviceType = BIO_START_BLOCK
  } else if (types.includes(BIO_DAQ_BOX_DEVICE_TYPE)) {
    deviceType = BIO_DAQ_BOX
  }
  return deviceType
}
export const getFormattedModelNumber = (deviceType, modelNumber) =>
  deviceType === DEVICE_TYPE.KIDAQ ? '5500A' : modelNumber

export const isConfigurableDevice = types => !!intersection(types, CONFIGURABLE_DEVICE_TYPES).length

export const hasLED = deviceType => !(deviceType === DEVICE_TYPE.FORCE_PLATE)

export const hasAcquisitionDeviceType = types => {
  return types?.includes(ACQUISITION_DEVICE_TYPE) ?? false
}

export const findComponentByTypeAndSerial = (components, typeNumber, serialNumber = null) => {
  const trimmedTypeNumber = typeNumber.trim()
  const component = components.find(c => {
    const typeMatch = c.kistlerType && c.kistlerType.toLowerCase() === trimmedTypeNumber.toLowerCase()
    const baseTypeMatch = c.baseTypeNumber && trimmedTypeNumber.startsWith(c.baseTypeNumber) // 1.fallback to baseTypeNumber
    const typeNumberMatch = c.typeNumber && trimmedTypeNumber.startsWith(c.typeNumber) // 2.fallback to TypeNumber
    const serialMatch = serialNumber === null || c.serialNumber === serialNumber // optional: check serialNumber
    return (typeMatch || baseTypeMatch || typeNumberMatch) && serialMatch
  })
  return component || {}
}
