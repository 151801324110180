/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbDataTable } from 'skybase-ui/skybase-components/sb-data-table'
import { SbBadge } from 'skybase-ui/skybase-components/sb-badge'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'

import { removeFirstCharIfExists, getSelectedItemPageNumber } from '@/utils'
import { getIotHubDeviceResourceDetailsState } from '@/iot-hub/selectors'
import { deviceShape } from '../shapes'
import { fetchResource } from '../utils'
import { removeDeviceIdFromResourcePath } from './utils'
import { DEFAULT_PAGE_SIZE, DEFAULT_SORT_BY } from './constants'
import { messages as t } from './resources-i18n'

class _ResourcesList extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    device: deviceShape.isRequired,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
    interfacesColors: PropTypes.object, // eslint-disable-line
    typesColors: PropTypes.object, // eslint-disable-line
    handleFetchResource: PropTypes.func.isRequired,
    selectedResourcePath: PropTypes.string,
    matchResourcePath: PropTypes.string,
  }

  static defaultProps = {
    columns: null,
    selectedResourcePath: null,
    matchResourcePath: null,
  }

  constructor(props) {
    super(props)

    const {
      intl: { formatMessage: _ },
    } = props

    this.tableColumns = props.columns || [
      {
        name: 'href',
        label: _(t.resourceLocation),
        cellsClassName: 'text-align-left',
        headerClassName: 'text-align-left',
      },
      // { name: 'interfaces', label: _(t.interfaces), sortable: false },
      { name: 'types', label: _(t.types), sortable: false },
    ]
    this.state = {
      pagination: {
        pageSize: DEFAULT_PAGE_SIZE,
        pageNumber: 1,
      },
      sortBy: DEFAULT_SORT_BY,
    }
  }

  componentDidMount() {
    this.handleJumpToPage()

    SbEmitter.on('resourceJumpToPage', this.handleJumpToPage)
  }

  componentWillUnmount() {
    SbEmitter.off('resourceJumpToPage', this.handleJumpToPage)
  }

  handleJumpToPage = () => {
    const {
      pagination: { pageSize },
      sortBy,
    } = this.state
    const {
      device: { resources },
      matchResourcePath,
    } = this.props

    // Set the pageNumber pagination based on the selected value in the table
    if (resources && matchResourcePath) {
      const selectedItemPageNumber = getSelectedItemPageNumber({
        columns: this.tableColumns,
        data: resources,
        sortShape: sortBy,
        pageSize,
        indexSelector: ({ href }) =>
          removeFirstCharIfExists(href, '/') === removeFirstCharIfExists(matchResourcePath, '/'),
      })

      this.setState(prevState => {
        return { pagination: { ...prevState.pagination, pageNumber: selectedItemPageNumber } }
      })
    }
  }

  handleOnPaginationChange = pagination => this.setState({ pagination })

  handleOnSortChange = sortBy => this.setState({ sortBy })

  onRowSelect = href => {
    const {
      device: { resources = [] },
      handleFetchResource,
    } = this.props

    const thisResource = resources.find(resource => resource.href === href)

    if (thisResource) {
      handleFetchResource(href)
    }
  }

  cellFormatter = (value, key) => {
    if (key === 'interfaces' || key === 'types') {
      const { interfacesColors, typesColors } = this.props
      const colors = key === 'interfaces' ? interfacesColors : typesColors

      return (
        <div className="fl-row">
          {value.map((item, i) => {
            return (
              <SbBadge
                className={`${key}-${item.replace(/\./g, '-')}`}
                key={`${key}-item-${i}`}
                style={{ backgroundColor: colors[item] }}
              >
                {item}
              </SbBadge>
            )
          })}
        </div>
      )
    }

    if (key === 'href') {
      const {
        device: { id },
      } = this.props
      return <span className="no-word-break">{removeDeviceIdFromResourcePath(value, id)}</span>
    }

    return value
  }

  rowFormatter = row => {
    const { selectedResourcePath } = this.props
    const selected =
      removeFirstCharIfExists(row.href, '/') === removeFirstCharIfExists(selectedResourcePath, '/') ? 'selected' : ''

    return {
      onClick: () => this.onRowSelect(row.href),
      className: classNames({ selected }),
    }
  }

  render() {
    const { pagination } = this.state
    const {
      device: { resources = [] },
      intl: { formatMessage: _ },
    } = this.props

    return (
      <SbDataTable
        id="device-resources-table"
        className="list-table"
        columns={this.tableColumns}
        data={resources}
        rowFormatter={row => this.rowFormatter(row)}
        cellFormatter={this.cellFormatter}
        emptyMessage={_(t.noResourcesFoundForThisDevice)}
        enablePagination
        asyncData={false}
        paginationProps={{
          pageSizeDropdownProps: {
            className: 'min-width-100px',
          },
          ...pagination,
          onChange: this.handleOnPaginationChange,
        }}
        defaultSortBy={DEFAULT_SORT_BY}
        onSortChange={this.handleOnSortChange}
      />
    )
  }
}

const mapStateToProps = state => {
  const {
    data: { resourcePath },
  } = getIotHubDeviceResourceDetailsState(state)

  return {
    selectedResourcePath: resourcePath,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleFetchResource: resourcePath => dispatch(fetchResource(resourcePath)),
  }
}

export const ResourcesList = injectIntl(connect(mapStateToProps, mapDispatchToProps)(_ResourcesList))
