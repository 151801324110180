import PropTypes from 'prop-types'

export const licenseShape = PropTypes.shape({
  ticketLicenseId: PropTypes.number,
  itemName: PropTypes.string,
  productCode: PropTypes.number,
  assignmentTime: PropTypes.string,
  activated: PropTypes.bool,
  quantity: PropTypes.number,
  orderIdentification: PropTypes.string,
  ticketIdentification: PropTypes.string,
})
