import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  module: {
    id: 'Module',
    defaultMessage: 'Module',
  },
  unsupportedModule: {
    id: 'Unsupported Module',
    defaultMessage: 'Unsupported Module',
  },
  name: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  moduleType: {
    id: 'Module type',
    defaultMessage: 'Module type',
  },
  samplingRate: {
    id: 'Sampling Rate',
    defaultMessage: 'Sampling Rate',
  },
  thisModuleIsNotSupportedInCurrentSoftwareVersion: {
    id: 'This module is not supported in current software version.',
    defaultMessage: 'This module is not supported in current software version.',
  },
  nameIsRequired: {
    id: 'Name is required',
    defaultMessage: 'Name is required',
  },
  maxSamplingRateOnThisModuleIs: {
    id: 'Max. sampling rate on this module is {maxSamplingRate} Sps',
    defaultMessage: 'Max. sampling rate on this module is {maxSamplingRate} Sps',
  },
  tareFunctionality: {
    id: 'Tare functionality',
    defaultMessage: 'Tare functionality',
  },
  on: {
    id: 'On',
    defaultMessage: 'On',
  },
  off: {
    id: 'Off',
    defaultMessage: 'Off',
  },
  inputLevelSingle: {
    id: 'Digital Input Level',
    defaultMessage: 'Digital Input Level',
  },
  inputLevelFirst: {
    id: 'Digital Input Level 1',
    defaultMessage: 'Digital Input Level 1',
  },
  inputLevelSecond: {
    id: 'Digital Input Level 2',
    defaultMessage: 'Digital Input Level 2',
  },
  controller: {
    id: 'Controller',
    defaultMessage: 'Controller',
  },
  mainsRejection: {
    id: 'Mains rejection',
    defaultMessage: 'Mains rejection',
  },
})
