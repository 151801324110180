import React from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import isEmpty from 'lodash/isEmpty'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'

import { FormComponent } from 'skybase-oauth/common/components'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbLoaderButton, SbButton } from 'skybase-ui/skybase-components/sb-button'
import { SbHint } from 'skybase-ui/skybase-components/sb-hint'
import { hintPositions } from 'skybase-ui/skybase-components/sb-hint/constants'
import { SbModal, SbModalHeader } from 'skybase-ui/skybase-components/sb-modal'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'
import { showErrorToast, showSuccessToast } from '@/common/services/show-toast'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'
import { messages as a } from 'skybase-oauth/messages-i18n'

import { equipmentShape } from '../shapes'
import { fetchComponentByType } from '../hooks'
import { formScenarios, emptyEquipmentForm, families, equipmentEmitterEvents } from '../constants'
import { canSubmitEquipmentForm } from '../utils'
import { messages as t } from '../equipment-i18n'
import { storeEquipmentItem } from '@/fleet-configuration/equipment/actions'

const { CREATE, UPDATE } = formScenarios

class _EquipmentModal extends FormComponent {
  static propTypes = {
    ...this.propTypes,
    intl: intlShape.isRequired,
    handleOnCloseModal: PropTypes.func.isRequired,
    data: equipmentShape,
    equipmentId: PropTypes.string,
    scenario: PropTypes.oneOf([CREATE, UPDATE]),
  }

  static defaultProps = {
    ...this.defaultProps,
    data: emptyEquipmentForm,
    scenario: CREATE,
  }

  constructor(props) {
    super(props)

    this.dataInState = true
    this.isModal = true
    this.state = {
      ...this.state,
      data: props.data,
      loading: false,
    }
  }

  handleOnClose = () => {
    const { handleOnCloseModal } = this.props

    handleOnCloseModal()
  }

  handleOnConfirm = data => {
    const {
      intl: { formatMessage: _ },
      scenario,
    } = this.props
    this.setState({ loading: true })

    storeEquipmentItem(data, scenario === UPDATE)
      .then(() => {
        showSuccessToast(
          _(scenario === CREATE ? t.equipmentWasSuccessfullyAdded : t.equipmentWasSuccessfullyUpdated),
          _(a.success),
        )
        SbEmitter.emit(equipmentEmitterEvents.REFETCH_EQUIPMENT_LIST)
        this.handleOnClose()
      })
      .catch(error => {
        showErrorToast(
          _(scenario === CREATE ? t.failedToAddAnEquipment : t.failedToUpdateAnEquipment, {
            errorMessage: error?.errors?.message ?? 'unknown.',
          }),
          _(a.error),
        )
        this.setState({ loading: false })
      })
  }

  /**
   * This function is actually being used by skybase-oauth and it's form-component.
   * Should we ever delete it, then debounced onChange handlers would get original value instead of actual value changed by user.
   */
  handleOnChangeParams = (attribute, value) => {
    this.setState(prevState => {
      return {
        data: {
          ...prevState.data,
          [attribute]: value,
        },
      }
    })
  }

  renderHeader = () => {
    const {
      intl: { formatMessage: _ },
      scenario,
    } = this.props

    return (
      <SbModalHeader
        title={scenario === CREATE ? _(t.addNewEquipment) : _(t.editEquipment)}
        onCloseBtnClick={this.handleOnClose}
      />
    )
  }

  renderSubmitButton = canSubmit => {
    const { loading } = this.state
    const {
      intl: { formatMessage: _ },
    } = this.props

    return (
      <SbLoaderButton
        loading={loading}
        disabled={loading || !canSubmit}
        className="primary"
        onClick={this.handleOnConfirmClick}
        id="equipment-form-confirm-button"
      >
        {_(t.save)}
      </SbLoaderButton>
    )
  }

  renderFooter = () => {
    const { data } = this.state
    const {
      intl: { formatMessage: _ },
    } = this.props
    const canSubmit = canSubmitEquipmentForm(data)

    return (
      <div className="sb-modal-footer fl-container fl-justify-end">
        <div className="sb-modal-left">
          {canSubmit ? (
            this.renderSubmitButton(canSubmit)
          ) : (
            <SbHint
              id="equipment-form-confirm-button-hint"
              position={hintPositions.topCenter}
              hintData={_(t.atLeastOneFieldHasToBeFilled)}
            >
              <div>{this.renderSubmitButton(canSubmit)}</div>
            </SbHint>
          )}
        </div>
        <div className="sb-modal-center" />
        <div className="sb-modal-right">
          <SbButton onClick={this.handleOnClose} id="equipment-form-close-button">
            {_(t.cancel)}
          </SbButton>
        </div>
      </div>
    )
  }

  loadCatalogErrorHandler = throttle((e, _) => {
    showErrorToast(
      _(t.failedToFetchCatalog, {
        errorMessage: e?.errors?.message ?? 'unknown.',
      }),
      _(a.error),
    )
  }, 10000)

  getValidFamily = family => {
    return Object.values(families).includes(family) ? family : null
  }

  onTypeNumberChange = debounce(async event => {
    const {
      intl: { formatMessage: _ },
    } = this.props
    const type = event?.target?.value ?? ''
    if (type.length < 5) {
      return
    }

    try {
      const component = await fetchComponentByType(type)

      if (!isEmpty(component)) {
        this.setState(prevState => ({
          data: {
            ...prevState.data,
            family: this.getValidFamily(component?.family) ?? prevState.data.family,
            typeName: component?.name ?? prevState.data.typeName,
            icon: component?.icon ?? prevState.data.icon,
          },
        }))
      }
    } catch (e) {
      if (e?.status && e.status === 404) {
        this.loadCatalogErrorHandler(e, _)
      } else {
        throw e
      }
    }
  }, 500)

  renderInputs = () => {
    const {
      intl: { formatMessage: _ },
    } = this.props

    return (
      <>
        {this.renderInputWithLabel('typeNumber', _(t.typeNumber), {
          placeholder: _(t.typeNumberPlaceholder),
          id: 'equipment-type-number-input',
          autoFocus: true,
          onChange: this.onTypeNumberChange,
          maxLength: 32,
        })}
        {this.renderInputWithLabel('serialNumber', _(t.serialNumber), {
          placeholder: _(t.serialNumberPlaceholder),
          id: 'equipment-serial-number-input',
          maxLength: 32,
        })}
        {this.renderInputWithLabel('family', _(t.family), {
          componentType: 'select',
          items: Object.values(families).map(family => {
            return { value: family, label: _(t[family]) }
          }),
          title: _(t.selectFamily),
          id: 'equipment-select-family',
        })}
        {this.renderInputWithLabel('typeName', _(t.name), {
          placeholder: _(t.namePlaceholder),
          id: 'equipment-name-input',
          maxLength: 64,
        })}
        {this.renderInputWithLabel('userNotes', _(t.notes), {
          componentType: 'textarea',
          placeholder: _(t.notePlaceholder),
          id: 'equipment-note-input',
          className: 'no-reisze',
          style: { height: '100px' },
          maxLength: 256,
        })}
      </>
    )
  }

  render() {
    return (
      <SbModal
        Header={this.renderHeader()}
        Footer={this.renderFooter()}
        width={310}
        height="auto"
        className="equipment-form-modal"
      >
        <div className="sb-message-modal-content">{this.renderInputs()}</div>
      </SbModal>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleOnCloseModal: () => dispatch(closeModal()),
  }
}

export const EquipmentModal = injectIntl(connect(null, mapDispatchToProps)(_EquipmentModal))
