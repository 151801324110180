import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { v4 } from 'uuid'
import { Chart, LineController, LineElement, PointElement, LinearScale, Title, Legend, CategoryScale } from 'chart.js'

import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { OAuth } from 'skybase-oauth/oauth'
import { SbModal, SbModalHeader } from 'skybase-ui/skybase-components/sb-modal'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'

import { getVariousColors } from '@/utils/get-various-colors'
import { WSClients } from '@/common/websocket'
import { DATASOURCE_GRAPH_WS_KEY } from './constants'
import { normalizeWidth, addData } from './utils'
import { messages as t } from './datasources-i18n'

import './graph.scss'

class _GraphModal extends PureComponent {
  static propTypes = {
    topic: PropTypes.string.isRequired,
    channels: PropTypes.arrayOf(
      PropTypes.shape({
        offset: PropTypes.number,
        type: PropTypes.string,
      }),
    ).isRequired,
    samplingRate: PropTypes.number.isRequired,
    handleClose: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  }

  constructor(props) {
    super(props)

    this.widthInMs = 10000
    this.graphWrapper = null
    this.graphCanvas = null
    this.resizeObserver = null
    this.chart = null
    this.x = 1
    this.correlationID = v4()
  }

  componentDidMount() {
    if (this.graphWrapper?.clientWidth) {
      const { topic, channels, samplingRate } = this.props

      if (typeof ResizeObserver === 'function') {
        this.resizeObserver = new ResizeObserver(debounce(this.handleResize, 500))
        this.resizeObserver.observe(this.graphWrapper)
      }

      WSClients.addToWsClients({
        name: DATASOURCE_GRAPH_WS_KEY,
        baseUrl: OAuth.config.ZMQ_PROXY_BASE_URL,
        endpoint: '/ws/zmqproxy',
        listener: this.handleData,
        onOpen: (event, ws) => {
          ws.send(
            JSON.stringify({
              correlationID: this.correlationID,
              widthInPixels: normalizeWidth(this.graphWrapper.clientWidth),
              widthInMilliseconds: this.widthInMs,
              topic,
              channels,
              samplingRate,
              filterChannels: channels.map((c, i) => i),
            }),
          )
        },
      })

      Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Legend, Title)

      const ctx = this.graphCanvas.getContext('2d')
      this.chart = new Chart(ctx, {
        type: 'line',
        data: this.getInitialData(),
        options: {
          normalized: true,
          spanGaps: true,
          scales: {
            x: {
              display: false,
              ticks: {
                display: false,
              },
            },
            y: {
              title: {
                display: true,
                text: 'value',
              },
            },
          },
          events: ['click'],
          plugins: {
            legend: {
              position: 'bottom',
            },
            tooltip: {
              enabled: false,
            },
          },
          elements: {
            point: {
              borderWidth: 0,
              radius: 0,
            },
            line: {
              borderWidth: 2,
            },
          },
          animation: false,
        },
      })

      window.requestAnimationFrame(this.handleRefreshGraph)
    }
  }

  componentWillUnmount() {
    if (typeof ResizeObserver === 'function') {
      this.resizeObserver?.unobserve?.(this.graphWrapper)
    }

    WSClients.removeFromWsClients(DATASOURCE_GRAPH_WS_KEY)
  }

  handleRefreshGraph = () => {
    this.chart.update()

    window.requestAnimationFrame(this.handleRefreshGraph)
  }

  handleData = message => {
    const measurement = JSON.parse(message.data)

    if (measurement?.correlationID === this.correlationID) {
      const scans = measurement?.scans

      if (scans) {
        for (let i = 0; i < scans.length; i += 1) {
          const data = []
          for (let a = 0; a < scans[i].length; a += 1) {
            data.push({
              x: this.x,
              y: scans[i][a].signal[0],
            })
          }

          addData(this.chart, [data])

          this.x += 1
        }
      }
    }
  }

  getInitialData = () => {
    const {
      channels,
      intl: { formatMessage: _ },
    } = this.props
    const width = normalizeWidth(this.graphWrapper.clientWidth)
    this.x = width + 1

    const initialData = Array(width)
      .fill(null)
      .map((a, i) => ({
        x: i + 1,
        y: 0,
      }))
    const initialLabels = Array(width)
      .fill(null)
      .map((a, i) => String(i + 1))

    let colorIndex = -1
    const colors = getVariousColors()
    const initialDatasets = channels.map((channel, channelIndex) => {
      colorIndex += 1
      if (!colors[colorIndex]) {
        colorIndex = 0
      }
      const color = colors[colorIndex]
      return {
        label: `${_(t.channel)} ${channelIndex + 1}`,
        data: initialData,
        borderColor: color,
        backgroundColor: color,
      }
    })

    return {
      labels: initialLabels,
      datasets: initialDatasets,
    }
  }

  resetData = () => {
    this.chart.data = this.getInitialData()
    this.chart.update()
  }

  handleResize = entries => {
    const { topic, channels, samplingRate } = this.props
    const { width } = entries?.[0]?.contentRect || {}
    const ws = WSClients.getWs(DATASOURCE_GRAPH_WS_KEY)

    if (ws && ws.isConnected()) {
      this.resetData()
      this.correlationID = v4()

      ws.send(
        JSON.stringify({
          correlationID: this.correlationID,
          widthInPixels: normalizeWidth(width),
          widthInMilliseconds: this.widthInMs,
          topic,
          channels,
          samplingRate,
          filterChannels: channels.map((c, i) => i),
        }),
      )
    }
  }

  handleOnClose = () => {
    const { handleClose } = this.props

    handleClose()
  }

  renderHeader = () => {
    const {
      intl: { formatMessage: _ },
    } = this.props

    return <SbModalHeader title={_(t.graph)} onCloseBtnClick={this.handleOnClose} />
  }

  render() {
    return (
      <SbModal
        Header={this.renderHeader()}
        Footer={<div className="m-t-20" />}
        width="750px"
        height="auto"
        className="graph-modal"
        onClickOverlay={this.handleOnClose}
      >
        <div
          className="sb-message-modal-content"
          ref={node => {
            this.graphWrapper = node
          }}
        >
          <canvas
            ref={node => {
              this.graphCanvas = node
            }}
            className="chart"
          />
        </div>
      </SbModal>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClose: () => dispatch(closeModal()),
  }
}

export const GraphModal = injectIntl(connect(null, mapDispatchToProps)(_GraphModal))
