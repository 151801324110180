import isEmpty from 'lodash/isEmpty'
import { families } from './constants'

const { DEVICE, MODULE, CONTROLLER, CABLE, SENSOR } = families
export const getEquipmentIconClassNameByFamily = family => {
  switch (family) {
    case CABLE:
      return 'sbi-device-thermic' // @todo change to original icon
    case SENSOR:
      return 'sbi-sensor'
    case DEVICE:
      return 'sbi-nav-devices' // @todo make sure to not use nav icons
    case CONTROLLER:
      return 'sbi-nav-devices'
    case MODULE:
      return 'sbi-nav-devices'
    default:
      return null
  }
}

export const canSubmitEquipmentForm = data => Object.values(data).some(value => !isEmpty(value))
