import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { startCase, intersection } from 'lodash'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbCheckbox } from 'skybase-ui/skybase-components/sb-checkbox/sb-checkbox'
import { SbInlineMessage } from 'skybase-ui/skybase-components/sb-inline-message'
import {
  INLINE_MESSAGE_TYPE_INFO,
  INLINE_MESSAGE_TYPE_WARNING,
} from 'skybase-ui/skybase-components/sb-inline-message/constants'
import { camelCaseToDashSeparator } from '@/utils/sanitizer'
import { byteLengthOfStringEncodedInUtf8 } from '@/utils/various-utils'
import { Dropdown } from '@/fleet-configuration/components/dropdown/dropdown'
import { TextBox } from '@/fleet-configuration/components/text-box/text-box'
import { ButtonBar } from '@/fleet-configuration/components/button-bar/button-bar'
import { LabeledField } from '@/fleet-configuration/components/form/labeled-field'
import { TitleWithTooltip } from '@/fleet-configuration/components/title-with-tooltip/title-with-tooltip'
import { SwitchingControl } from '@/fleet-configuration/components/switching-control/switching-control'
import { Label } from '@/fleet-configuration/components/form/label'
import { ValidationGroup } from '@/fleet-configuration/components/form/validation-group'
import { SensitivityTextbox } from '@/fleet-configuration/components/sensitivity-textbox/sensitivity-textbox'
import { ACQUISITION_CHANNEL } from '@/fleet-configuration/data-fleet/catalog/catalog-constants'
import { convertUnit } from '@/fleet-configuration/data-fleet/project-devices/channel-utils'
import { wizardNavigationSelection } from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation-constants'
import { messages as t } from './channel-parameters-form-i18n'
import { ChannelConnectionScheme } from './channel-connection-scheme'
import './channel-parameters-form.scss'

const { SELECTED } = wizardNavigationSelection
const MAX_CHANNEL_NAME_LENGTH_KIDAQ = 100 // KiDAQ in data sources is not limited to 100
const MAX_CHANNEL_NAME_BYTES = 32 // labAmp only allows 32 byte names

// prefer numeric equivalent so that we do not get temporary dirty field where it is not necessary
const getEvtVal = ({ target: { value }, values: { floatValue } = {} }) =>
  String(floatValue) === value ? floatValue : value

class _ChannelParametersForm extends React.PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    snippetPathPrefix: PropTypes.string.isRequired,
    onNumericParameterBlur: PropTypes.func,
    onParameterCommit: PropTypes.func,
    onParameterChange: PropTypes.func,
    onActivateChannelBlur: PropTypes.func,
    onActivateChannelChange: PropTypes.func,
    onSubResourceChange: PropTypes.func,
    onSubResourceBlur: PropTypes.func,
    onSubResourceNumericBlur: PropTypes.func,
    channel: PropTypes.object,
    computeDirtyState: PropTypes.bool,
    paramsSelected: PropTypes.object,
    paramSelectionChange: PropTypes.func,
    handleParamsSelectionChange: PropTypes.func,
    chainCatalog: PropTypes.object,
    chainSensitivity: PropTypes.object,
    chainMaxPhysicalRangeWithUnit: PropTypes.object,
  }

  static defaultProps = {
    onNumericParameterBlur: () => {},
    onParameterCommit: () => {},
    onParameterChange: () => {},
    onActivateChannelBlur: () => {},
    onActivateChannelChange: () => {},
    onSubResourceChange: () => {},
    onSubResourceBlur: () => {},
    onSubResourceNumericBlur: () => {},
    handleParamsSelectionChange: () => {},
    channel: null,
    computeDirtyState: true,
    paramsSelected: {},
    paramSelectionChange: null,
    chainCatalog: {},
    chainSensitivity: null,
    chainMaxPhysicalRangeWithUnit: null,
  }

  getChannelType = types => {
    const {
      intl: { formatMessage: _ },
    } = this.props

    if (types && types.length) {
      const includesAnalog = intersection(types, [
        'x.com.kistler.kgate.analogInput',
        'x.com.kistler.labamp.acquisition.channel',
      ]).length
      const includesDigital = types.includes('x.com.kistler.kgate.digitalInput')

      if (includesAnalog && includesDigital) {
        return `${_(t.analogInput)}, ${_(t.digitalInput)}`
      }
      if (includesAnalog) {
        return _(t.analogInput)
      }
      if (includesDigital) {
        return _(t.digitalInput)
      }
      if (types.includes(ACQUISITION_CHANNEL)) {
        return _(t.genericChannel)
      }
      return `${_(t.unknownInput)}: ${types && types.join(',')}`
    }
    return _(t.unknownInput)
  }

  renderDefaultSelectField(
    fieldName,
    testClassName = undefined,
    title = undefined,
    additionalSelectionName = undefined,
    showEmptyValue = false,
    hasWarningCondition = false,
    tWarningText = undefined,
  ) {
    const {
      channel,
      intl: { formatMessage: _ },
      onParameterChange,
      onParameterCommit,
      paramsSelected,
      paramSelectionChange,
      computeDirtyState,
      snippetPathPrefix,
      handleParamsSelectionChange,
    } = this.props
    const readOnly = !channel.isActive()
    const hasField = channel.hasField(fieldName)
    const channelOptions = hasField && channel.getOptions(fieldName, _)
    return (
      hasField && (
        <div className={`select-field-group ${hasWarningCondition ? 'underlined' : ''}`}>
          <Label
            className={testClassName || `test-${camelCaseToDashSeparator(fieldName)}`}
            source={fieldName}
            title={
              <>
                {paramSelectionChange && (
                  <SbCheckbox
                    onClick={evt => {
                      const selectionParams = [`${snippetPathPrefix}parameters.${fieldName}`]
                      if (additionalSelectionName) {
                        selectionParams.push(`${snippetPathPrefix}${additionalSelectionName}`)
                      }
                      handleParamsSelectionChange(evt.target.checked, selectionParams)
                    }}
                    checked={paramsSelected[`${snippetPathPrefix}parameters.${fieldName}`] === SELECTED}
                  />
                )}
                {_(title || t[fieldName])}
              </>
            }
            isDirty={computeDirtyState && channel.isDirty('parameters', fieldName)}
            underline={!hasWarningCondition}
          >
            <Dropdown
              className="sb-width-m"
              data-testid={fieldName}
              disabled={!!paramSelectionChange || readOnly || !channelOptions || !channelOptions.length}
              options={channelOptions}
              value={channel.parameters[fieldName]}
              onChange={value => onParameterChange(fieldName, value)}
              onCommit={onParameterCommit}
              canShowEmptyOption={showEmptyValue}
            />
          </Label>
          {hasWarningCondition && (
            <SbInlineMessage
              className={`input-difference-inline-warning sbi-toast-warning test-${fieldName}-inline-warning`}
              type={INLINE_MESSAGE_TYPE_WARNING}
              message={tWarningText}
              title={_(t.warning)}
            />
          )}
        </div>
      )
    )
  }

  renderDefaultSubResourceSelectField(subResource, fieldName, testClassName, title, options = {}) {
    const {
      channel,
      intl: { formatMessage: _ },
      onSubResourceChange,
      onSubResourceBlur,
      onSubResourceNumericBlur,
      paramsSelected,
      paramSelectionChange,
      computeDirtyState,
      snippetPathPrefix,
      handleParamsSelectionChange,
    } = this.props
    const readOnly = !channel.isActive() || options.readOnly
    const hasField = channel.subResourceHasField(subResource, fieldName)
    const channelOptions = hasField && channel.getSubResourceOptions(subResource, fieldName)
    return (
      hasField && (
        <Label
          className={
            testClassName || `test-${camelCaseToDashSeparator(subResource)}-${camelCaseToDashSeparator(fieldName)}`
          }
          title={
            <>
              {paramSelectionChange && (
                <SbCheckbox
                  onClick={evt =>
                    handleParamsSelectionChange(
                      evt.target.checked,
                      `${snippetPathPrefix}deviceSpecific.${subResource}.${fieldName}`,
                    )
                  }
                  checked={
                    paramsSelected[`${snippetPathPrefix}deviceSpecific.${subResource}.${fieldName}`] === SELECTED
                  }
                />
              )}
              {_(title || t[fieldName])}
            </>
          }
          isDirty={computeDirtyState && channel.isDirty('deviceSpecific', subResource, fieldName)}
        >
          <Dropdown
            className="sb-width-m"
            disabled={!!handleParamsSelectionChange || readOnly || !channelOptions || !channelOptions.length}
            options={channelOptions}
            value={channel.deviceSpecific[subResource][fieldName]}
            onChange={value => onSubResourceChange(subResource, fieldName, options.isNumeric ? +value : value)}
            onCommit={
              options.isNumeric ? value => onSubResourceNumericBlur(subResource, fieldName, value) : onSubResourceBlur
            }
          />
        </Label>
      )
    )
  }

  renderPhysicalRange() {
    const {
      channel,
      intl: { formatMessage: _ },
      onParameterChange,
      onNumericParameterBlur,
      onParameterCommit,
      paramsSelected,
      paramSelectionChange,
      computeDirtyState,
      snippetPathPrefix,
      handleParamsSelectionChange,
      chainMaxPhysicalRangeWithUnit,
    } = this.props
    const readOnly = !channel.isActive()

    return (
      channel.hasField('physicalRange', 1) && (
        <div data-testid="physicalRangeWrapper">
          <Label
            className="test-nominal-range"
            title={
              <>
                {paramSelectionChange && (
                  <SbCheckbox
                    onClick={evt => {
                      const selectionParams = [`${snippetPathPrefix}parameters.physicalRange`]
                      if (channel.hasField('physicalUnit')) {
                        selectionParams.push(`${snippetPathPrefix}parameters.physicalUnit`)
                      }
                      handleParamsSelectionChange(evt.target.checked, selectionParams)
                    }}
                    checked={paramsSelected[`${snippetPathPrefix}parameters.physicalRange`] === SELECTED}
                  />
                )}
                {_(t.physicalRange)}
              </>
            }
            source={['physicalRange[0]', 'physicalRange[1]', 'physicalUnit']}
            isDirty={
              computeDirtyState &&
              (channel.isDirty('parameters', 'physicalRange') ||
                channel.isDirty('parameters', 'hwRange') ||
                channel.isDirty('parameters', 'physicalUnit'))
            }
          >
            {(channel.hasField('physicalRange', 0) || (channel.hasCustomUnit() && !channel.isLabAmp())) && (
              <TextBox
                data-testid="physicalRangeFrom"
                id="physicalRange[0]"
                className="sb-width-sm test-physical-range-value-from"
                type="number"
                fraction={5}
                value={(channel.parameters.physicalRange || [])[0]}
                disabled={!!paramSelectionChange || readOnly || !channel.isEditablePhysicalRangeFrom()}
                onValueChange={e => onParameterChange('physicalRange[0]', getEvtVal(e))}
                onCommit={value => onNumericParameterBlur('physicalRange[0]', value)}
              />
            )}
            <TextBox
              data-testid="physicalRangeTo"
              id="physicalRange[1]"
              className="sb-width-sm test-physical-range-value"
              type="number"
              fraction={5}
              value={(channel.parameters.physicalRange || [])[1]}
              disabled={!!paramSelectionChange || readOnly || !channel.isEditablePhysicalRangeTo()}
              onValueChange={e => onParameterChange('physicalRange[1]', getEvtVal(e))}
              onCommit={value => onNumericParameterBlur('physicalRange[1]', value)}
            />
            {channel.hasField('physicalUnit') && (
              <Dropdown
                data-testid="physicalUnitDropdown"
                className="sb-width-sm physicalUnit test-physical-range-unit"
                options={channel.getOptions('physicalUnit', _)}
                value={channel.parameters.physicalUnit}
                disabled={!!paramSelectionChange || readOnly}
                canShowEmptyOption
                onChange={value => onParameterChange('physicalUnit', value)}
                onCommit={onParameterCommit}
              />
            )}
          </Label>
          {(() => {
            let normalizedChainMaxRange
            if (chainMaxPhysicalRangeWithUnit) {
              try {
                normalizedChainMaxRange = convertUnit(
                  chainMaxPhysicalRangeWithUnit.unit,
                  channel.parameters.physicalUnit,
                  chainMaxPhysicalRangeWithUnit.range,
                )
                return (
                  Math.abs(normalizedChainMaxRange) !== channel.parameters.physicalRange[1] && (
                    <SbInlineMessage type={INLINE_MESSAGE_TYPE_INFO} title={_(t.physicalRangeDoesNotMatch)} />
                  )
                )
              } catch (e) {
                // conversion failed, do not display warning
              }
            }
            return null
          })()}
        </div>
      )
    )
  }

  render() {
    const {
      channel,
      intl: { formatMessage: _ },
      onNumericParameterBlur,
      onParameterCommit,
      onParameterChange,
      onActivateChannelBlur,
      onActivateChannelChange,
      onSubResourceChange,
      onSubResourceBlur,
      onSubResourceNumericBlur,
      paramsSelected,
      paramSelectionChange,
      computeDirtyState,
      snippetPathPrefix,
      handleParamsSelectionChange,
      chainCatalog,
      chainSensitivity,
    } = this.props
    const { physicalUnit, sensitivityUnit, channelSpanState: rawChannelSpanState, timeConstant } = channel.parameters
    // prevent problems where rawChannelSpanState === '' by explicit assign for falsy values
    const channelSpanState = rawChannelSpanState || {}
    const isValidFilterType = channel.hasOption('filterType', channel.parameters.filterType)

    const hasSignalType = channel.hasField('signalType')
    const hasSensorType = channel.hasField('sensorType')
    const hasStage = channel.hasField('stage')
    const hasPulseUnit = channel.hasField('pulseUnit')
    const hasFilterSettings = channel.hasField('filterType') || channel.hasField('timeConstant')

    const offsetUnit = hasPulseUnit ? 'Hz' : (sensitivityUnit || '').split('/', 2)[0] || null
    const readOnly = !channel.isActive()
    const { src: connectionImageSrc, mimeType: connectionImageMime } = channel.getConnectionImage()

    const { measurand, sensorClass } = chainCatalog
    const selectedPhysicalQuantity = channel.getSelectedPhysicalQunatity()
    const channelNameLimit = channel.isKiDAQ() ? MAX_CHANNEL_NAME_LENGTH_KIDAQ : undefined // allow for KiDAQ longer channel names

    return (
      <ValidationGroup name={channel.path.toString()}>
        <div data-testid="channel-parameters" className="fl-grow-1 channel-parameters">
          <Label
            className="activate-channel test-channel-active"
            title={
              <>
                {paramSelectionChange && (
                  <SbCheckbox
                    onClick={evt =>
                      handleParamsSelectionChange(evt.target.checked, `${snippetPathPrefix}parameters.enabled`)
                    }
                    checked={paramsSelected[`${snippetPathPrefix}parameters.enabled`] === SELECTED}
                  />
                )}
                {_(t.active)}
              </>
            }
            isDirty={computeDirtyState && channel.isDirty('parameters', 'enabled')}
          >
            <SwitchingControl
              checked={channel.isActive()}
              disabled={!!paramSelectionChange || ('lockedBy' in channelSpanState && !channel.isActive())}
              indeterminate={channel.isPartiallyActive()}
              onChange={onActivateChannelChange}
              onBlur={onActivateChannelBlur}
              textTrue={_(t.on)}
              textFalse={_(t.off)}
            />
          </Label>
          <LabeledField
            id="name"
            source="name"
            label={
              <>
                {paramSelectionChange && (
                  <SbCheckbox
                    onClick={evt =>
                      handleParamsSelectionChange(evt.target.checked, `${snippetPathPrefix}parameters.name`)
                    }
                    checked={paramsSelected[`${snippetPathPrefix}parameters.name`] === SELECTED}
                  />
                )}
                {_(t.name)}
              </>
            }
            labelClassName="test-channel-name"
            className="sb-width-ml"
            disabled={!!paramSelectionChange || readOnly}
            Component={TextBox}
            value={channel.parameters.name}
            isDirty={computeDirtyState && channel.isDirty('parameters', 'name')}
            maxLength={channelNameLimit}
            onChange={e => {
              // LabAmp device supports only 32 bytes for name. Some chars consist of more then 1 byte so we can't just use maxLength
              // this condition makes sure that any change that would produce longer input then what is supported will be ignored
              if (
                (channel.isLabAmp() && byteLengthOfStringEncodedInUtf8(e.target.value) <= MAX_CHANNEL_NAME_BYTES) ||
                !channel.isLabAmp()
              ) {
                onParameterChange('name', e.target.value)
              }
            }}
            onCommit={onParameterCommit}
          />
          <Label className="test-channel-type" title={_(t.channelType)}>
            <span>{this.getChannelType(channel.types)}</span>
          </Label>
          {(hasSignalType || hasSensorType || hasStage) && (
            <div className="sb-group">
              {hasSensorType && (
                <>
                  <h3>{_(t.inputConfiguration)}</h3>
                  {this.renderDefaultSelectField('sensorType', 'test-sensor-type', t.type, undefined, true)}
                  {channel.hasField('sensorConnectorType') && (
                    <Label
                      className="test-sensor-detail-overview"
                      source={['sensorConnectorType', 'sensorTypeDetail']}
                      title={
                        <>
                          {paramSelectionChange && (
                            <SbCheckbox
                              onClick={evt => {
                                const selectionParams = [`${snippetPathPrefix}parameters.sensorConnectorType`]
                                if (channel.hasField('sensorTypeDetail')) {
                                  selectionParams.push(`${snippetPathPrefix}parameters.sensorTypeDetail`)
                                }
                                handleParamsSelectionChange(evt.target.checked, selectionParams)
                              }}
                              checked={
                                paramsSelected[`${snippetPathPrefix}parameters.sensorConnectorType`] === SELECTED
                              }
                            />
                          )}
                          {_(t.sensorDetailType)}
                        </>
                      }
                      isDirty={
                        computeDirtyState &&
                        (channel.isDirty('parameters', 'sensorTypeDetail') ||
                          channel.isDirty('parameters', 'sensorConnectorType'))
                      }
                    >
                      {channel.hasField('sensorTypeDetail') && (
                        <Dropdown
                          className="sb-width-m test-sensor-type-detail"
                          disabled={!!paramSelectionChange || readOnly}
                          options={channel.getOptions('sensorTypeDetail', _)}
                          value={channel.parameters.sensorTypeDetail}
                          onChange={value => onParameterChange('sensorTypeDetail', value)}
                          onCommit={onParameterCommit}
                          canShowEmptyOption
                        />
                      )}
                      <Dropdown
                        className="sb-width-m test-connector-type"
                        disabled={!!paramSelectionChange || readOnly}
                        options={channel.getOptions('sensorConnectorType', _)}
                        value={channel.parameters.sensorConnectorType}
                        onChange={value => onParameterChange('sensorConnectorType', value)}
                        onCommit={onParameterCommit}
                        canShowEmptyOption
                      />
                    </Label>
                  )}
                </>
              )}
              {hasSignalType && (
                <>
                  <h3>{_(t.signal)}</h3>
                  {this.renderDefaultSelectField('signalType', 'test-signal-type', t.type, undefined, true)}
                  {(channel.hasField('signalTypeDetail') || channel.hasField('signalConnectorType')) &&
                    (() => {
                      const existingFields = []
                      if (channel.hasField('signalTypeDetail')) {
                        existingFields.push('signalTypeDetail')
                      }
                      if (channel.hasField('signalConnectorType')) {
                        existingFields.push('signalConnectorType')
                      }

                      return (
                        <Label
                          source={existingFields}
                          className="test-signal-detail-overview"
                          title={
                            <>
                              {paramSelectionChange && (
                                <SbCheckbox
                                  onClick={evt => {
                                    const selectionParams = existingFields.map(
                                      field => `${snippetPathPrefix}parameters.${field}`,
                                    )
                                    handleParamsSelectionChange(evt.target.checked, selectionParams)
                                  }}
                                  checked={
                                    paramsSelected[`${snippetPathPrefix}parameters.signalTypeDetail`] === SELECTED ||
                                    paramsSelected[`${snippetPathPrefix}parameters.signalConnectorType`] === SELECTED
                                  }
                                />
                              )}
                              {_(t.signalDetailType)}
                            </>
                          }
                          isDirty={
                            computeDirtyState &&
                            (channel.isDirty('parameters', 'signalTypeDetail') ||
                              channel.isDirty('parameters', 'signalConnectorType'))
                          }
                        >
                          {channel.hasField('signalTypeDetail') && (
                            <Dropdown
                              className="sb-width-m test-signal-type-detail"
                              disabled={!!paramSelectionChange || readOnly}
                              options={channel.getOptions('signalTypeDetail', _)}
                              value={channel.parameters.signalTypeDetail}
                              onChange={value => onParameterChange('signalTypeDetail', value)}
                              onCommit={onParameterCommit}
                              canShowEmptyOption
                            />
                          )}
                          {channel.hasField('signalConnectorType') && (
                            <Dropdown
                              className="sb-width-m test-connector-type"
                              disabled={!!paramSelectionChange || readOnly}
                              options={channel.getOptions('signalConnectorType', _)}
                              value={channel.parameters.signalConnectorType}
                              onChange={value => onParameterChange('signalConnectorType', value)}
                              onCommit={onParameterCommit}
                            />
                          )}
                        </Label>
                      )
                    })()}
                </>
              )}
              {hasStage && (
                <>
                  <h3>{_(t.inputConfiguration)}</h3>
                  {(() => {
                    const stageOption = channel.getChannelOptions().getOption('stage', channel.parameters.stage)
                    const providedType = stageOption?.description || stageOption?.rawDescription || ''
                    return this.renderDefaultSelectField(
                      'stage',
                      'test-stage',
                      t.type,
                      undefined,
                      true,
                      sensorClass && sensorClass.toLowerCase() !== channel.parameters.stage.toLowerCase(),
                      _(t.specifiedSensorMeasuresSensorsTypeNotProvidedType, {
                        sensorsType: sensorClass || '',
                        providedType,
                      }),
                    )
                  })()}
                  {this.renderDefaultSelectField('supplyCurrent')}
                </>
              )}
              <Label className="test-connection-scheme" title={_(t.connectionScheme)}>
                <ChannelConnectionScheme src={connectionImageSrc} mimeType={connectionImageMime} />
              </Label>
              {this.renderDefaultSelectField(
                'physicalQuantity',
                undefined,
                undefined,
                undefined,
                true,
                measurand && measurand.toLowerCase() !== selectedPhysicalQuantity.toLowerCase(),
                _(t.inputDifferenceWarning, { measurand, selectedPhysicalQuantity }),
              )}
              {channel.hasField('physicalQuantity') && channel.hasCustomUnit() && (
                <LabeledField
                  id="physicalUnit"
                  source="physicalUnit"
                  label={
                    <>
                      {paramSelectionChange && (
                        <SbCheckbox
                          onClick={evt =>
                            handleParamsSelectionChange(evt.target.checked, [
                              `${snippetPathPrefix}parameters.physicalUnit`,
                              `${snippetPathPrefix}parameters.customUnit`,
                            ])
                          }
                          checked={paramsSelected[`${snippetPathPrefix}parameters.physicalUnit`] === SELECTED}
                        />
                      )}
                      {_(t.customUnit)}
                    </>
                  }
                  labelClassName="test-custom-unit"
                  className="sb-width-m"
                  data-testid="physicalUnit"
                  Component={TextBox}
                  maxLength={6}
                  value={physicalUnit}
                  isDirty={computeDirtyState && channel.isDirty('parameters', 'physicalUnit')}
                  disabled={!!paramSelectionChange || readOnly}
                  onChange={e => onParameterChange('physicalUnit', e.target.value)}
                  onCommit={value => onParameterCommit('physicalUnit', value)}
                />
              )}
              {channel.hasField('unitFactor') && (
                <>
                  <Label
                    className="test-sensitivity channel-sensitivity"
                    source={['sensitivity', 'sensitivityUnit']}
                    title={
                      <>
                        {paramSelectionChange && (
                          <SbCheckbox
                            onClick={evt => {
                              const selectionParams = [`${snippetPathPrefix}parameters.sensitivity`]
                              if (channel.hasField('sensitivityUnit')) {
                                selectionParams.push(`${snippetPathPrefix}parameters.sensitivityUnit`)
                              }
                              handleParamsSelectionChange(evt.target.checked, selectionParams)
                            }}
                            checked={paramsSelected[`${snippetPathPrefix}parameters.sensitivity`] === SELECTED}
                          />
                        )}
                        {_(t.sensitivityScaling)}
                      </>
                    }
                    isDirty={
                      computeDirtyState &&
                      (channel.isDirty('parameters', 'sensitivity') || channel.isDirty('parameters', 'sensitivityUnit'))
                    }
                  >
                    <SensitivityTextbox
                      channel={channel}
                      disabled={!!paramSelectionChange || readOnly}
                      className="sb-width-sm test-sensitivity-value"
                      onChange={e => onParameterChange('sensitivity', e.target.value)}
                      onCommit={value => onNumericParameterBlur('sensitivity', value)}
                    />
                    {channel.hasField('sensitivityUnit') && (
                      <Dropdown
                        data-testid="sensitivityUnit"
                        className="sb-width-sm test-sensitivity-unit"
                        options={channel.getOptions('sensitivityUnit', _)}
                        value={sensitivityUnit}
                        canShowEmptyOption
                        disabled={!!paramSelectionChange || readOnly || channel.isMatchingMainTypeAndPhysicalQuantity()}
                        onChange={value => onParameterChange('sensitivityUnit', value)}
                        onCommit={onParameterCommit}
                      />
                    )}
                  </Label>
                  {(() => {
                    let chainSensitivityNormalizedValue
                    if (chainSensitivity) {
                      const baseUnit = channel.parameters.sensitivityUnit.split('/').slice(-1)[0].trim()
                      const targetUnit = chainSensitivity.sensitivityUnit.split('/').slice(-1)[0].trim()
                      try {
                        chainSensitivityNormalizedValue = convertUnit(
                          baseUnit,
                          targetUnit,
                          chainSensitivity?.sensitivityValue,
                        )
                      } catch (e) {
                        // if conversion fails, do not display anything as physical unit does not match sensor's unit
                        return null
                      }
                      return (
                        chainSensitivity &&
                        Math.abs(chainSensitivityNormalizedValue) !== Math.abs(channel.parameters.sensitivity) && (
                          <SbInlineMessage type={INLINE_MESSAGE_TYPE_INFO} title={_(t.thisSensitivityDoesNotMatch)} />
                        )
                      )
                    }
                    return null
                  })()}
                </>
              )}
              {channel.hasField('pulseFactor') && (
                <Label
                  className="test-pulseFactor"
                  source={['pulseFactor', 'pulseOffset']}
                  title={
                    <>
                      {paramSelectionChange && (
                        <SbCheckbox
                          onClick={evt => {
                            const selectionParams = [`${snippetPathPrefix}parameters.pulseFactor`]
                            if (hasPulseUnit) {
                              selectionParams.push(`${snippetPathPrefix}parameters.pulseOffset`)
                            }
                            handleParamsSelectionChange(evt.target.checked, selectionParams)
                          }}
                          checked={paramsSelected[`${snippetPathPrefix}parameters.pulseFactor`] === SELECTED}
                        />
                      )}
                      <TitleWithTooltip titleText={t.pulseFactor} tooltip={t.pulseFactorTooltip} />
                    </>
                  }
                  isDirty={computeDirtyState && channel.isDirty('parameters', 'pulseFactor')}
                >
                  <TextBox
                    type="number"
                    className={`sb-width-${hasPulseUnit ? 'sm' : 'm'} test-pulse-factor`}
                    value={channel.parameters.pulseFactor}
                    disabled={!!paramSelectionChange || readOnly}
                    onChange={e => onParameterChange('pulseFactor', e.target.value)}
                    onCommit={value => onNumericParameterBlur('pulseFactor', value)}
                  />
                  {hasPulseUnit && (
                    <Dropdown
                      className="pulse-unit-sizes test-pulse-unit"
                      options={channel.getOptions('pulseUnit', _)}
                      value={channel.parameters.pulseUnit}
                      canShowEmptyOption
                      disabled={!!paramSelectionChange || readOnly}
                      onChange={value => onParameterChange('pulseUnit', value)}
                      onCommit={onParameterCommit}
                    />
                  )}
                </Label>
              )}
              {channel.hasField('physicalRange', 1) ? null : this.renderDefaultSelectField('physicalUnit')}
              {channel.hasField('zeroOffset') && (
                <Label
                  className="test-zeroOffset"
                  source={['zeroOffset']}
                  title={
                    <>
                      {paramSelectionChange && (
                        <SbCheckbox
                          onClick={evt =>
                            handleParamsSelectionChange(evt.target.checked, `${snippetPathPrefix}parameters.zeroOffset`)
                          }
                          checked={paramsSelected[`${snippetPathPrefix}parameters.zeroOffset`] === SELECTED}
                        />
                      )}
                      {_(t.zeroMeasurandOffset)}
                    </>
                  }
                  isDirty={computeDirtyState && channel.isDirty('parameters', 'zeroOffset')}
                >
                  <div className="sb-width-m">
                    <TextBox
                      data-testid="zeroOffset"
                      type="number"
                      fraction={5}
                      className="test-zero-offset"
                      value={channel.parameters.zeroOffset}
                      disabled={!!paramSelectionChange || readOnly || channel.isMatchingMainTypeAndPhysicalQuantity()}
                      onChange={e => onParameterChange('zeroOffset', e.target.value)}
                      onCommit={value => onNumericParameterBlur('zeroOffset', value)}
                      suffix={offsetUnit || null}
                    />
                  </div>
                </Label>
              )}
              {this.renderDefaultSelectField('supply')}
              {this.renderDefaultSelectField('shuntResistance')}
              {this.renderDefaultSelectField('supplyPrinciple')}
              {this.renderDefaultSelectField('synchronisation')}
              {channel.hasField('timeBase') && (
                <Label
                  source={['timeBase']}
                  className="test-time-base"
                  title={
                    <>
                      {paramSelectionChange && (
                        <SbCheckbox
                          onClick={evt =>
                            handleParamsSelectionChange(evt.target.checked, `${snippetPathPrefix}parameters.timeBase`)
                          }
                          checked={paramsSelected[`${snippetPathPrefix}parameters.timeBase`] === SELECTED}
                        />
                      )}
                      <TitleWithTooltip titleText={t.timeBase} tooltip={t.timeBaseTooltip} />
                    </>
                  }
                  isDirty={computeDirtyState && channel.isDirty('parameters', 'timeBase')}
                >
                  <TextBox
                    className="sb-width-m"
                    type="number"
                    fraction={3}
                    value={channel.parameters.timeBase}
                    disabled={!!paramSelectionChange || readOnly}
                    onValueChange={e => onParameterChange('timeBase', getEvtVal(e))}
                    onCommit={value => onNumericParameterBlur('timeBase', value)}
                  />
                </Label>
              )}
              {this.renderPhysicalRange()}
              {channel.hasField('switchingLevelLower') && channel.hasField('switchingLevelUpper') && (
                <Label
                  title={
                    <>
                      {paramSelectionChange && (
                        <SbCheckbox
                          onClick={evt => {
                            const selectionParams = [
                              `${snippetPathPrefix}parameters.switchingLevelLower`,
                              `${snippetPathPrefix}parameters.switchingLevelUpper`,
                            ]
                            handleParamsSelectionChange(evt.target.checked, selectionParams)
                          }}
                          checked={paramsSelected[`${snippetPathPrefix}parameters.switchingLevelLower`] === SELECTED}
                        />
                      )}
                      <TitleWithTooltip titleText={t.switchingLevels} tooltip={t.switchingLevelsTooltip} />
                    </>
                  }
                  source={['switchingLevelLower', 'switchingLevelUpper']}
                  className="test-switching-level"
                  isDirty={
                    computeDirtyState &&
                    (channel.isDirty('parameters', 'switchingLevelLower') ||
                      channel.isDirty('parameters', 'switchingLevelUpper'))
                  }
                >
                  <div className="sb-width-m">
                    <TextBox
                      type="number"
                      fraction={3}
                      value={channel.parameters.switchingLevelLower}
                      disabled={!!paramSelectionChange || readOnly}
                      onValueChange={e => onParameterChange('switchingLevelLower', getEvtVal(e))}
                      onCommit={value => onNumericParameterBlur('switchingLevelLower', value)}
                      suffix={channel.unit('switchingLevelLower')}
                    />
                  </div>
                  <div className="sb-width-m">
                    <TextBox
                      type="number"
                      fraction={3}
                      value={channel.parameters.switchingLevelUpper}
                      disabled={!!paramSelectionChange || readOnly}
                      onValueChange={e => onParameterChange('switchingLevelUpper', getEvtVal(e))}
                      onCommit={value => onNumericParameterBlur('switchingLevelUpper', value)}
                      suffix={channel.unit('switchingLevelUpper')}
                    />
                  </div>
                </Label>
              )}
            </div>
          )}
          {hasFilterSettings && (
            <div className="sb-group">
              <h3>{_(t.filterSettings)}</h3>
              {channel.hasField('timeConstant') && (
                <>
                  <Label
                    className="test-time-constant"
                    title={
                      <>
                        {paramSelectionChange && (
                          <SbCheckbox
                            onClick={evt => {
                              handleParamsSelectionChange(
                                evt.target.checked,
                                `${snippetPathPrefix}parameters.timeConstant`,
                              )
                            }}
                            checked={paramsSelected[`${snippetPathPrefix}parameters.timeConstant`] === SELECTED}
                          />
                        )}
                        {_(t.timeConstant)}
                      </>
                    }
                    isDirty={computeDirtyState && channel.isDirty('parameters', 'timeConstant')}
                  >
                    <ButtonBar
                      className="sb-width-m"
                      value={timeConstant}
                      options={channel.getOptions('timeConstant', _)}
                      disabled={!!paramSelectionChange || readOnly}
                      onChange={value => onParameterChange('timeConstant', value)}
                      onBlur={onParameterCommit}
                    />
                  </Label>
                  {(() => {
                    const timeConstantDescription = channel.getTimeConstantDescription()
                    if (!timeConstantDescription) {
                      return null
                    }
                    return (
                      <Label
                        className="test-time-constant-description"
                        title={_(t.constantTimeConstant, { timeConstant: startCase(timeConstant) })}
                      >
                        {channel.getTimeConstantDescription()}
                      </Label>
                    )
                  })()}
                </>
              )}
              {this.renderDefaultSelectField('filterType', 'test-filter-type', undefined, 'deviceSpecific.filter.type')}
              {channel.hasField('filterFreq1') && isValidFilterType && (
                <>
                  <Label
                    className="test-filter-characteristics"
                    title={
                      <>
                        {paramSelectionChange && (
                          <SbCheckbox
                            onClick={evt => {
                              handleParamsSelectionChange(evt.target.checked, [
                                `${snippetPathPrefix}parameters.algorithm`,
                                `${snippetPathPrefix}deviceSpecific.filter.algorithm`,
                              ])
                            }}
                            checked={paramsSelected[`${snippetPathPrefix}parameters.algorithm`] === SELECTED}
                          />
                        )}
                        {_(t.filterCharacteristics)}
                      </>
                    }
                  >
                    <Dropdown
                      className="sb-width-m"
                      disabled
                      options={[{ value: 'Butterworth', description: _(t.butterworth) }]}
                      value={channel.parameters.algorithm}
                    />
                  </Label>
                  <Label
                    className="test-filter-order"
                    title={
                      <>
                        {paramSelectionChange && (
                          <SbCheckbox
                            onClick={evt => {
                              handleParamsSelectionChange(evt.target.checked, [
                                `${snippetPathPrefix}parameters.order`,
                                `${snippetPathPrefix}deviceSpecific.filter.order`,
                              ])
                            }}
                            checked={paramsSelected[`${snippetPathPrefix}parameters.order`] === SELECTED}
                          />
                        )}
                        {_(t.filterOrder)}
                      </>
                    }
                  >
                    <Dropdown
                      className="sb-width-m"
                      disabled
                      options={[
                        { value: 4, description: _(t.fourthOrder) },
                        { value: 1, description: _(t.firstOrder) },
                      ]}
                      value={channel.parameters.order}
                    />
                  </Label>
                  <Label
                    className="test-lower-cutoff-frequency"
                    title={
                      <>
                        {paramSelectionChange && (
                          <SbCheckbox
                            onClick={evt => {
                              handleParamsSelectionChange(evt.target.checked, [
                                `${snippetPathPrefix}parameters.filterFreq1`,
                                `${snippetPathPrefix}parameters.filterFreq2`,
                                `${snippetPathPrefix}deviceSpecific.filter.frequencies`,
                              ])
                            }}
                            checked={paramsSelected[`${snippetPathPrefix}parameters.filterFreq1`] === SELECTED}
                          />
                        )}
                        {channel.hasField('filterFreq2') ? _(t.lowerCutoffFrequency) : _(t.cutoffFrequency)}
                      </>
                    }
                    isDirty={computeDirtyState && channel.isDirty('parameters', 'filterFreq1')}
                  >
                    <Dropdown
                      className="sb-width-sm"
                      options={channel.getOptions('filterFreq1', _)}
                      value={channel.parameters.filterFreq1}
                      disabled={!!paramSelectionChange || readOnly}
                      onChange={value => onParameterChange('filterFreq1', parseFloat(value))}
                      onCommit={onParameterCommit}
                    />
                  </Label>
                </>
              )}
              {channel.hasField('filterFreq2') && isValidFilterType && (
                <Label
                  className="test-upper-cutoff-frequency"
                  title={
                    <>
                      {paramSelectionChange && (
                        <SbCheckbox
                          onClick={evt => {
                            handleParamsSelectionChange(evt.target.checked, [
                              `${snippetPathPrefix}parameters.filterFreq1`,
                              `${snippetPathPrefix}parameters.filterFreq2`,
                              `${snippetPathPrefix}deviceSpecific.filter.frequencies`,
                            ])
                          }}
                          checked={paramsSelected[`${snippetPathPrefix}parameters.filterFreq2`] === SELECTED}
                        />
                      )}
                      {_(t.upperCutoffFrequency)}
                    </>
                  }
                  isDirty={computeDirtyState && channel.isDirty('parameters', 'filterFreq2')}
                  source={['filterFreq2']}
                >
                  <Dropdown
                    className="sb-width-sm"
                    options={channel.getOptions('filterFreq2', _)}
                    value={channel.parameters.filterFreq2}
                    disabled={!!paramSelectionChange || readOnly}
                    onChange={value => onParameterChange('filterFreq2', parseFloat(value))}
                    onCommit={onParameterCommit}
                  />
                </Label>
              )}
            </div>
          )}
          {channel.subResourceHasField('highpass', 'enabled') && (
            <div className="sb-group">
              <h3>{_(t.highPass)}</h3>
              <Label
                className="test-highpass-active"
                title={
                  <>
                    {paramSelectionChange && (
                      <SbCheckbox
                        onClick={evt => {
                          handleParamsSelectionChange(evt.target.checked, [
                            `${snippetPathPrefix}deviceSpecific.highpass.enabled`,
                            `${snippetPathPrefix}deviceSpecific.highpass.order`,
                          ])
                        }}
                        checked={paramsSelected[`${snippetPathPrefix}deviceSpecific.highpass.enabled`] === SELECTED}
                      />
                    )}
                    {_(t.active)}
                  </>
                }
                isDirty={computeDirtyState && channel.isDirty('deviceSpecific', 'highpass', 'enabled')}
              >
                <SwitchingControl
                  checked={channel.deviceSpecific.highpass.enabled}
                  onChange={value => onSubResourceChange('highpass', 'enabled', value)}
                  onBlur={onSubResourceBlur}
                  disabled={!!paramSelectionChange}
                  textTrue={_(t.on)}
                  textFalse={_(t.off)}
                />
              </Label>
              <Label className="test-highpass-order" title={_(t.order)}>
                {channel.deviceSpecific.highpass.order || 1}
              </Label>
              <Label
                className="test-highpass-frequency"
                title={
                  <>
                    {paramSelectionChange && (
                      <SbCheckbox
                        onClick={evt => {
                          handleParamsSelectionChange(
                            evt.target.checked,
                            `${snippetPathPrefix}deviceSpecific.highpass.frequency`,
                          )
                        }}
                        checked={paramsSelected[`${snippetPathPrefix}deviceSpecific.highpass.frequency`] === SELECTED}
                      />
                    )}
                    {_(t.cutoffFrequency)}
                  </>
                }
                source={['deviceSpecific.highpass.frequency']}
                isDirty={computeDirtyState && channel.isDirty('deviceSpecific', 'highpass', 'frequency')}
              >
                <TextBox
                  id="deviceSpecific.highpass.frequency"
                  className="sb-width-sm test-highpass-frequency"
                  type="number"
                  fraction={1}
                  suffix=" Hz"
                  disabled={!!paramSelectionChange || readOnly}
                  value={channel.deviceSpecific.highpass.frequency}
                  onValueChange={e => onSubResourceChange('highpass', 'frequency', getEvtVal(e))}
                  onCommit={value => onSubResourceNumericBlur('highpass', 'frequency', value)}
                />
              </Label>
            </div>
          )}
          {channel.subResourceHasField('notch', 'enabled') && (
            <div className="sb-group">
              <h3>{_(t.notch)}</h3>
              <Label
                className="test-notch-active"
                title={
                  <>
                    {paramSelectionChange && (
                      <SbCheckbox
                        onClick={evt => {
                          handleParamsSelectionChange(
                            evt.target.checked,
                            `${snippetPathPrefix}deviceSpecific.notch.enabled`,
                          )
                        }}
                        checked={paramsSelected[`${snippetPathPrefix}deviceSpecific.notch.enabled`] === SELECTED}
                      />
                    )}
                    {_(t.active)}
                  </>
                }
                isDirty={computeDirtyState && channel.isDirty('deviceSpecific', 'notch', 'enabled')}
              >
                <SwitchingControl
                  checked={channel.deviceSpecific.notch.enabled}
                  onChange={value => onSubResourceChange('notch', 'enabled', value)}
                  onBlur={onSubResourceBlur}
                  disabled={!!paramSelectionChange || readOnly}
                  textTrue={_(t.on)}
                  textFalse={_(t.off)}
                />
              </Label>
              <Label
                className="test-notch-frequency"
                title={
                  <>
                    {paramSelectionChange && (
                      <SbCheckbox
                        onClick={evt => {
                          handleParamsSelectionChange(
                            evt.target.checked,
                            `${snippetPathPrefix}deviceSpecific.notch.frequency`,
                          )
                        }}
                        checked={paramsSelected[`${snippetPathPrefix}deviceSpecific.notch.frequency`] === SELECTED}
                      />
                    )}
                    {_(t.centerFrequency)}
                  </>
                }
                source={['deviceSpecific.notch.frequency']}
                isDirty={computeDirtyState && channel.isDirty('deviceSpecific', 'notch', 'frequency')}
              >
                <TextBox
                  id="notch.frequency"
                  className="sb-width-sm test-notch-frequency"
                  type="number"
                  fraction={3}
                  suffix=" Hz"
                  disabled={!!paramSelectionChange || readOnly}
                  value={channel.deviceSpecific.notch.frequency}
                  onValueChange={e => onSubResourceChange('notch', 'frequency', getEvtVal(e))}
                  onCommit={value => onSubResourceNumericBlur('notch', 'frequency', value)}
                />
              </Label>
              <Label
                className="test-notch-q-factor"
                title={
                  <>
                    {paramSelectionChange && (
                      <SbCheckbox
                        onClick={evt => {
                          handleParamsSelectionChange(
                            evt.target.checked,
                            `${snippetPathPrefix}deviceSpecific.notch.qFactor`,
                          )
                        }}
                        checked={paramsSelected[`${snippetPathPrefix}deviceSpecific.notch.qFactor`] === SELECTED}
                      />
                    )}
                    {_(t.qFactor)}
                  </>
                }
                source={['deviceSpecific.notch.qFactor']}
                isDirty={computeDirtyState && channel.isDirty('deviceSpecific', 'notch', 'qFactor')}
              >
                <TextBox
                  id="notch.qFactor"
                  className="sb-width-sm test-notch-q-factor"
                  type="number"
                  fraction={3}
                  disabled={!!paramSelectionChange || readOnly}
                  value={channel.deviceSpecific.notch.qFactor}
                  onValueChange={e => onSubResourceChange('notch', 'qFactor', getEvtVal(e))}
                  onCommit={value => onSubResourceNumericBlur('notch', 'qFactor', value)}
                />
              </Label>
            </div>
          )}
          {channel.subResourceHasField('lowpass', 'enabled') && (
            <div className="sb-group">
              <h3>{_(t.lowPass)}</h3>
              <Label
                className="test-lowpass-active"
                title={
                  <>
                    {paramSelectionChange && (
                      <SbCheckbox
                        onClick={evt => {
                          handleParamsSelectionChange(
                            evt.target.checked,
                            `${snippetPathPrefix}deviceSpecific.lowpass.enabled`,
                          )
                        }}
                        checked={paramsSelected[`${snippetPathPrefix}deviceSpecific.lowpass.enabled`] === SELECTED}
                      />
                    )}
                    {_(t.active)}
                  </>
                }
                isDirty={computeDirtyState && channel.isDirty('deviceSpecific', 'lowpass', 'enabled')}
              >
                <SwitchingControl
                  checked={channel.deviceSpecific.lowpass.enabled}
                  onChange={value => onSubResourceChange('lowpass', 'enabled', value)}
                  onBlur={onSubResourceBlur}
                  disabled={!!paramSelectionChange || readOnly}
                  textTrue={_(t.on)}
                  textFalse={_(t.off)}
                />
              </Label>
              {this.renderDefaultSubResourceSelectField('lowpass', 'algorithm')}
              {this.renderDefaultSubResourceSelectField('lowpass', 'order', undefined, undefined, {
                isNumeric: true,
              })}
              <Label
                className="test-lowpass-frequency"
                title={
                  <>
                    {paramSelectionChange && (
                      <SbCheckbox
                        onClick={evt => {
                          handleParamsSelectionChange(
                            evt.target.checked,
                            `${snippetPathPrefix}deviceSpecific.lowpass.frequency`,
                          )
                        }}
                        checked={paramsSelected[`${snippetPathPrefix}deviceSpecific.lowpass.frequency`] === SELECTED}
                      />
                    )}
                    {_(t.cutoffFrequency)}
                  </>
                }
                source={['deviceSpecific.lowpass.frequency']}
                isDirty={computeDirtyState && channel.isDirty('deviceSpecific', 'lowpass', 'frequency')}
              >
                <TextBox
                  id="deviceSpecific.lowpass.frequency"
                  className="sb-width-sm test-lowpass-frequency"
                  type="number"
                  fraction={3}
                  suffix=" Hz"
                  disabled={!!paramSelectionChange || readOnly}
                  value={channel.deviceSpecific.lowpass.frequency}
                  onValueChange={e => onSubResourceChange('lowpass', 'frequency', getEvtVal(e))}
                  onCommit={value => onSubResourceNumericBlur('lowpass', 'frequency', value)}
                />
              </Label>
            </div>
          )}
        </div>
      </ValidationGroup>
    )
  }
}

export const ChannelParametersForm = injectIntl(connect()(_ChannelParametersForm))
