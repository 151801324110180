import { get, set, find } from 'lodash'
import { objectDiffDeep } from '@/utils/diff'
import {
  getFieldName,
  parseField,
  hasCustomUnit,
  prepareMirroringDataFromParametersToSpecificSource,
  executePreparedMirroring,
} from './channel-utils'

export class ChannelUpdater {
  constructor(catalog, channelOptions, channel) {
    this.catalog = catalog
    this.options = channelOptions
    this.channel = channel
  }

  setParameter(field, value, originalChannel) {
    let updatedChannel = this.setChannelParameter(this.channel, field, value)
    updatedChannel = this.setFinalDependencies(updatedChannel, field)

    dispatchEvent(
      new CustomEvent('channel-parameters-updated', {
        detail: {
          channel: this.channel,
          changes: objectDiffDeep(originalChannel.parameters, updatedChannel.parameters),
        },
      }),
    )
    return updatedChannel
  }

  setFinalDependencies(channelToUpdate, field) {
    let updatedChannel = channelToUpdate

    // copy all the mirrored-subtree parameters - depending on situation it could be from
    // parameters to deviceSpecific subtree or the other way around
    updatedChannel = executePreparedMirroring(updatedChannel, this.catalog)

    // In case of potentiometer's custom value sensitivity should copy physicalRange[1]
    // We don't have such a rule in catalog (that would allow us to copy values like this)
    // and also having rule like that would be to hard. Hence we will have this small snippet
    // of hardcoded correction
    if (
      updatedChannel.parameters.sensorType === 'Potentiometer' &&
      updatedChannel.parameters.physicalQuantity === 'Custom value'
    ) {
      updatedChannel = this.setParameterValue(updatedChannel, 'sensitivity', updatedChannel.parameters.physicalRange[1])
    }

    // since setting zero offset triggers nominal range recalculation, we can only do it when
    // dependencies of all the other parameters are resolved so there's no mismatch (e.g. in units)
    if (
      updatedChannel.hasField('zeroOffset') &&
      updatedChannel.isMatchingMainTypeAndPhysicalQuantity() &&
      updatedChannel.parameters.zeroOffset !== 0
    ) {
      updatedChannel = this.setChannelParameter(updatedChannel, 'zeroOffset', 0)
    }

    // we can update physicalRange[1] only at the very end of the update process
    // otherwise it will be called multiple times and will produce console error
    // messages due to the channel is not fully initialized yet
    if (updatedChannel.hasField('physicalRange', 1)) {
      if (
        parseFloat(updatedChannel.parameters.sensitivity) &&
        updatedChannel.maxRangeTo() === updatedChannel.minRangeTo()
      ) {
        updatedChannel = this.updateParameterValue(updatedChannel, 'physicalRange[1]', updatedChannel.maxRangeTo())
      } else if (
        [
          'sensorType',
          'sensorConnectorType',
          'sensorTypeDetail',
          'sensitivity',
          'sensitivityUnit',
          'physicalUnit',
          'physicalQuantity',
        ].includes(field)
      ) {
        updatedChannel = this.setChannelParameter(
          updatedChannel,
          'physicalRange[1]',
          (channelToUpdate.parameters.physicalRange || [])[1],
        )
      }
      if (updatedChannel.hasField('physicalRange', '0') && !updatedChannel.isEditablePhysicalRangeFrom()) {
        const maxRangeFrom = updatedChannel.maxRangeFrom()
        const minRangeFrom = updatedChannel.minRangeFrom()
        if (minRangeFrom === maxRangeFrom) {
          updatedChannel = this.updateParameterValue(updatedChannel, 'physicalRange[0]', minRangeFrom)
        } else if (updatedChannel.parameters.physicalRange) {
          if (minRangeFrom > updatedChannel.parameters.physicalRange[0]) {
            updatedChannel = this.setChannelParameter(updatedChannel, 'physicalRange[0]', minRangeFrom)
          }
          if (maxRangeFrom < updatedChannel.parameters.physicalRange[0]) {
            updatedChannel = this.setChannelParameter(updatedChannel, 'physicalRange[0]', maxRangeFrom)
          }
        }
      }
    }

    if (field === 'filterType') {
      switch (updatedChannel.parameters.filterType) {
        case 'NoFilter':
          delete updatedChannel.parameters.filterFreq1
          delete updatedChannel.parameters.filterFreq2
          break
        case 'Lowpass':
        case 'Highpass':
          delete updatedChannel.parameters.filterFreq2
          break
        case 'Bandpass':
          // let both through
          break
        default:
          console.warn('Unknown filter type', updatedChannel.parameters.filterType)
      }
    }

    return updatedChannel
  }

  setChannelParameter(channelToUpdate, field, value) {
    let updatedChannel = channelToUpdate
    updatedChannel = this.updateParameterValue(updatedChannel, field, value)
    updatedChannel = this.setDependencies(updatedChannel, field, value)
    return updatedChannel
  }

  setDependencies(channelToUpdate, field, value) {
    let updatedChannel = channelToUpdate
    if (field === 'physicalUnit') {
      updatedChannel = this.setPhysicalUnitDependencies(updatedChannel)
    }

    if (field === 'physicalQuantity') {
      updatedChannel = this.setPhysicalQuantityDependencies(updatedChannel)
    }

    if (this.catalog.isEnum(field) && (value || value === '')) {
      const option = this.options.getOption(field, value)
      updatedChannel = this.applyFilters(updatedChannel, option.filters)
      updatedChannel = this.applyChanges(updatedChannel, option.changes)
      updatedChannel = this.ensureEnabledFieldValues(updatedChannel, option.enables)

      // update spanning after all dependencies are resolved (avoid inconsistent channel config)
      this.applySpanningChannel(updatedChannel, option)
    }

    if (
      ['physicalRange[1]', 'zeroOffset'].includes(field) &&
      !updatedChannel.hasField('physicalRange', 0) &&
      (!updatedChannel.hasCustomUnit() || updatedChannel.isLabAmp())
    ) {
      let rangeFrom = updatedChannel.minRangeFrom()
      const { physicalRange, sensitivity } = updatedChannel.parameters
      // take minimal calculated value (negative sensitivity swaps values, so test "max" range from and negative from min range from too)
      if (sensitivity < 0) {
        rangeFrom = Math.min(rangeFrom, updatedChannel.maxRangeFrom(), -rangeFrom)
      }
      updatedChannel = this.setChannelParameter(
        updatedChannel,
        'physicalRange[0]',
        physicalRange ? Math.min(Math.max(rangeFrom, -physicalRange[1]), physicalRange[1]) : rangeFrom,
      )
    }

    return updatedChannel
  }

  applySpanningChannel(updatedChannel, optionBeingSet) {
    if (optionBeingSet.channelsUsed) {
      const oldChannelsUsed = (updatedChannel.parameters.channelSpanState || {}).span || 1
      const newChannelsUsed = optionBeingSet.channelsUsed
      if (oldChannelsUsed === newChannelsUsed) {
        return
      }
      this.channel.updateSpanningChannels(oldChannelsUsed, newChannelsUsed)
      // mutating here is not a problem - we have already deepClone-d this whole channel
      // eslint-disable-next-line no-param-reassign
      updatedChannel.parameters.channelSpanState = { span: newChannelsUsed }
    }
  }

  ensureEnabledFieldValues(channelToUpdate, enables = []) {
    return enables.reduce((acc, enable) => {
      const fieldName = getFieldName(enable)
      if (this.catalog.isEnum(fieldName) && !this.options.hasOption(fieldName, acc.parameters[fieldName])) {
        const firstOption = this.options.getOptions(fieldName)[0]
        const { catalog } = this
        const fieldType = catalog.hasField(fieldName) ? catalog.getType(fieldName) : ''
        const value = parseField(fieldType, firstOption.value)
        return this.setChannelParameter(acc, fieldName, value)
      }
      return acc
    }, channelToUpdate)
  }

  applyFilters(channelToUpdate, filters = []) {
    let updatedChannel = channelToUpdate
    // here we have to perform breadth first traversal
    // apply all values before we continue recursively with dependencies otherwise it fails
    const updatedFields = []
    updatedChannel = filters.reduce(
      (acc, filter) =>
        this.applyFilter(acc, filter, (options, option, fieldName) => {
          if (option || !options.length) {
            return acc
          }
          const currentValue = acc.parameters[fieldName]
          if (!options.some(o => o.value === currentValue) && fieldName !== 'unit') {
            const firstOption = options[0]
            updatedFields.push(fieldName)
            const { catalog } = this
            const fieldType = catalog.hasField(fieldName) ? catalog.getType(fieldName) : ''
            const value = parseField(fieldType, firstOption.value)
            return this.updateParameterValue(acc, fieldName, value)
          }
          return acc
        }),
      updatedChannel,
    )
    updatedChannel = filters.reduce(
      (acc, filter) =>
        this.applyFilter(acc, filter, (options, option, fieldName) => {
          if (!option) {
            return acc
          }
          if (updatedFields.includes(fieldName)) {
            return this.setDependencies(acc, fieldName, option.value)
          }
          return acc
        }),
      updatedChannel,
    )
    return updatedChannel
  }

  updateParameterValue(channelToUpdate, field, value) {
    let updatedChannel = channelToUpdate
    updatedChannel = this.setParameterValue(updatedChannel, field, value)
    updatedChannel = prepareMirroringDataFromParametersToSpecificSource(updatedChannel, this.catalog, field)
    return updatedChannel
  }

  setParameterValue(channelToUpdate, field, value) {
    const channelPath = `parameters.${field}`
    if (get(channelToUpdate, channelPath) !== value) {
      this.options.optionsCache = {}
      set(channelToUpdate, channelPath, value)
    }
    return channelToUpdate
  }

  applyFilter(updatedChannel, filter, f) {
    const filteredFieldName = getFieldName(filter.field)
    const filteredFieldValue = this.getOptionsValue(updatedChannel, filteredFieldName)
    const options = this.options.getEnumOptions(filteredFieldName)
    const option = find(options, { value: filteredFieldValue })
    return f(options, option, filteredFieldName)
  }

  getOptionsValue(channel, field) {
    return channel.parameters[field]
  }

  applyChanges(updatedChannel, changes = []) {
    return changes.reduce((accChannel, change) => {
      const fieldName = getFieldName(change.field)
      const { values } = change
      if (values && values.value !== undefined) {
        const fieldType = this.catalog.hasField(fieldName) ? this.catalog.getType(fieldName) : ''
        const value = parseField(fieldType, values.value)
        return this.setChannelParameter(accChannel, fieldName, value)
      }
      return accChannel
    }, updatedChannel)
  }

  deleteParameter(field) {
    delete this.channel.parameters[field]
    return this.channel
  }

  setPhysicalUnitDependencies(channelToUpdate) {
    // we use updateParameterValue instead of setParameter because
    // unit dependencies are handled differently then it is specified in catalog
    let updatedChannel = channelToUpdate
    if (hasCustomUnit(updatedChannel)) {
      if (this.channel.hasField('sensitivityUnit')) {
        const allSensitivityUnitOptions = this.options.getOptions('sensitivityUnit')
        // this strips old physical unit from sensitivity unit pair and injects new physical unit into it
        const adjustedSensitivityUnit = updatedChannel.parameters.sensitivityUnit.replace(
          /\/[^/]+$/,
          `/${updatedChannel.parameters.physicalUnit}`,
        )
        // if adjusted sensitivity unit can be used, then use that value
        //  so it basically keeps non-base values like mV/CUSTOM_VAL instead od forcing base value on each change like V/CUSTOM_VAL
        const sensitivityUnitOption =
          allSensitivityUnitOptions.find(
            sensitivityUnitCandidate => sensitivityUnitCandidate.value === adjustedSensitivityUnit,
          ) || allSensitivityUnitOptions[0]
        updatedChannel = this.updateParameterValue(updatedChannel, 'sensitivityUnit', sensitivityUnitOption.value)
      } else if (updatedChannel.parameters.sensitivityUnit) {
        updatedChannel = this.updateParameterValue(updatedChannel, 'sensitivityUnit', '')
      }
      if (this.channel.hasField('pulseUnit')) {
        const pulseUnitOption = this.options.getOptions('pulseUnit')[0]
        updatedChannel = this.updateParameterValue(updatedChannel, 'pulseUnit', pulseUnitOption.value)
      }
    } else {
      const { sensorType, physicalQuantity, physicalUnit } = updatedChannel.parameters
      if (sensorType === 'Potentiometer') {
        if (physicalQuantity === 'Resistance') {
          updatedChannel = this.updateParameterValue(
            updatedChannel,
            'sensitivityUnit',
            `${physicalUnit}/${physicalUnit}`,
          )
        } else if (physicalQuantity === '') {
          updatedChannel = this.updateParameterValue(updatedChannel, 'sensitivityUnit', '')
        }
      }
    }
    return updatedChannel
  }

  setPhysicalQuantityDependencies(channelToUpdate) {
    let updatedChannel = channelToUpdate
    if (hasCustomUnit(channelToUpdate)) {
      updatedChannel = this.updateParameterValue(updatedChannel, 'physicalUnit', 'm.u.')
    }

    if (this.channel.hasField('customUnit')) {
      updatedChannel = this.updateParameterValue(updatedChannel, 'customUnit', hasCustomUnit(updatedChannel))
    }

    if (
      this.channel.hasField('sensitivityUnit') &&
      this.channel.hasField('unit') &&
      !this.channel.hasField('pulseFactor')
    ) {
      // TODO: this assumes that the first option is always the correct one
      // this it not guarantied for all cases but is good enough now
      // find better way how to identify the correct value
      const sensitivityUnitOption = this.options.getSensitivityUnitOptions()[0]
      if (sensitivityUnitOption) {
        if (updatedChannel.isMatchingMainTypeAndPhysicalQuantity()) {
          updatedChannel = this.updateParameterValue(updatedChannel, 'sensitivityUnit', sensitivityUnitOption.value)
          const sensitivityValue = updatedChannel.isChargeType() && !updatedChannel.isLabAmp() ? -1 : 1
          updatedChannel = this.setChannelParameter(updatedChannel, 'sensitivity', sensitivityValue)
        } else {
          updatedChannel = this.updateParameterValue(updatedChannel, 'sensitivityUnit', sensitivityUnitOption.value)
        }
      }
    }

    return updatedChannel
  }
}
