import { getEntries } from '@/fleet-configuration/data-fleet/entries/entries-selectors'

export const getChainCatalogEntries = state => getEntries(state, 'entries.chainCatalog')

export const getSensorFromChainCatalog = (state, sensorTypeNumber) => {
  const catalogEntries = getChainCatalogEntries(state)
  const catalogEntry = catalogEntries?.find(catalog => catalog.type === sensorTypeNumber)
  return {
    measurand: catalogEntry?.parameters?.physicalQuantity,
    sensorClass: catalogEntry?.parameters?.sensor,
  }
}

export const getChainCatalogEntry = (state, chainTypeNumber) => {
  const catalogEntries = getChainCatalogEntries(state)
  return catalogEntries.find(catalog => catalog.type === chainTypeNumber)
}
