import React from 'react'
import { CreateSnippetButton } from '@/fleet-configuration/components/create-snippet-button/create-snippet-button'
import { BackupConfigurationButton } from '@/fleet-configuration/components/backup-configuration/backup-configuration-button'
import { messages as t } from './configuration-detail-components-i18n'
import './configuration-detail-components.scss'

export const configurationDetailBreadcrumbs = (_, device) => [
  {
    path: '/',
    title: _(t.home),
  },
  {
    path: '/configuration',
    title: _(t.configuration),
  },
  {
    path: '/configuration/devices',
    title: _(t.deviceConfiguration),
  },
  device ? device.name : _(t.configurationDetail),
]

export const configurationDetailHeading = device => (
  <h1>
    {device.name}
    <div className="measurement-buttons-on-right">
      <CreateSnippetButton deviceId={device.id} />
      <BackupConfigurationButton deviceId={device.id} />
    </div>
  </h1>
)
