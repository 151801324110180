import { debounce } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './switching-control.scss'

class _SwitchingControl extends React.Component {
  static propTypes = {
    checked: PropTypes.bool,
    indeterminate: PropTypes.bool,
    textTrue: PropTypes.string,
    textFalse: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }

  static defaultProps = {
    checked: false,
    indeterminate: false,
    disabled: false,
    textTrue: '',
    textFalse: '',
    className: '',
    onChange: () => {},
    onBlur: () => {},
  }

  constructor(props) {
    super(props)
    /*
     * debounce deduplicates handleOnChange via ->
     *  1, onClick for div
     *  2, label's "for" highlighting (clicking) inner input
     * both actions are desired because switching-control
     *  1, can but needn't to be inside label AND
     *  2, we want to toggle checking this component when clicking on label
     */
    this.handleOnChange = debounce(this.handleOnChange.bind(this))
  }

  async handleOnChange() {
    const { checked, disabled, onChange, onBlur } = this.props
    if (!disabled) {
      await onChange(!checked)
      setTimeout(onBlur)
    }
  }

  render() {
    const {
      className,
      checked,
      textTrue,
      textFalse,
      disabled,
      indeterminate,
      /* These 2 event handlers ARE required here even if they are not used */
      onChange,
      onBlur,
      ...rest
    } = this.props

    /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
    return (
      <div
        {...rest}
        tabIndex="0"
        className={classNames(className, 'switching-control', {
          disabled,
          checked,
          indeterminate,
          unchecked: !checked,
        })}
        onClick={this.handleOnChange}
      >
        {!indeterminate && (
          <>
            <span className={`option option-on ${checked && 'active'}`}>{checked ? textTrue : ''}</span>
            <span className={`option option-off ${!checked && 'active'}`}>{!checked ? textFalse : ''}</span>
          </>
        )}
        <input tabIndex={-1} type="checkbox" defaultChecked={checked} className="hidden" />
      </div>
    )
    /* eslint-enable jsx-a11y/no-noninteractive-tabindex */
  }
}

export const SwitchingControl = _SwitchingControl
