import { intersection } from 'lodash'
import { CABLE_COMPATIBILITY } from '@/fleet-configuration/utils/cable-utils-constants'

const hasFisherConnector = connectorsToTest =>
  connectorsToTest.some(
    connector =>
      /\bFischer\b/i.test(connector) && /\b9[- ]pole\b/i.test(connector) && /\bpos\b|\bpositive\b/i.test(connector),
  )

const hasBNCConnector = connectorsToTest =>
  connectorsToTest.some(connector => /\bBNC\b/i.test(connector) && /\bpos\b|\bpositive\b/i.test(connector))

const isConnectorLabAmp5165Compatible = (deviceModule, connectorsToTest) => {
  if (
    intersection(deviceModule.types, ['x.com.kistler.kistudio.labamp.5165a1', 'x.com.kistler.kistudio.labamp.5165a4'])
      .length
  ) {
    return hasBNCConnector(connectorsToTest)
  }
  return false
}

const isConnectorLabAmp5167Ax0Compatible = (deviceModule, connectorsToTest) => {
  if (
    intersection(deviceModule.types, ['x.com.kistler.kistudio.labamp.5167a40', 'x.com.kistler.kistudio.labamp.5167a80'])
      .length
  ) {
    return hasBNCConnector(connectorsToTest)
  }
  return false
}

const isConnectorLabAmp5167Ax1Compatible = (deviceModule, connectorsToTest) => {
  if (
    intersection(deviceModule.types, ['x.com.kistler.kistudio.labamp.5167a41', 'x.com.kistler.kistudio.labamp.5167a81'])
      .length
  ) {
    return hasFisherConnector(connectorsToTest)
  }
  return false
}

export const getCableCompatibilityStatus = (cableCatalog, sensorCatalog, deviceModule) => {
  const connectorsToTest = []
  if (cableCatalog?.parameters) {
    if (cableCatalog.parameters.connectorA) {
      connectorsToTest.push(cableCatalog.parameters.connectorA)
    }
    if (cableCatalog.parameters.connectorB) {
      connectorsToTest.push(cableCatalog.parameters.connectorB)
    }
  }
  if (
    connectorsToTest.length &&
    !isConnectorLabAmp5165Compatible(deviceModule, connectorsToTest) &&
    !isConnectorLabAmp5167Ax0Compatible(deviceModule, connectorsToTest) &&
    !isConnectorLabAmp5167Ax1Compatible(deviceModule, connectorsToTest)
  ) {
    return CABLE_COMPATIBILITY.INVALID
  }
  if (sensorCatalog && cableCatalog) {
    return sensorCatalog.compatibility?.includes(cableCatalog.id) ||
      cableCatalog.compatibility?.includes(sensorCatalog.id)
      ? CABLE_COMPATIBILITY.VALID
      : CABLE_COMPATIBILITY.UNKNOWN
  }
  return CABLE_COMPATIBILITY.NONE
}
