/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  theFirmwareUpdateOfTheDeviceDeviceFinishedSuccessfully: {
    id: 'firmwareListener.theFirmwareUpdateOfTheDeviceDeviceFinishedSuccessfully',
    defaultMessage: 'The firmware update of the device "{device}" finished successfully',
  },
  theFirmwareUpdateOfTheDeviceDeviceFailedReasonReason: {
    id: 'firmwareListener.theFirmwareUpdateOfTheDeviceDeviceFailedTheReasonReason',
    defaultMessage: 'The firmware update of the device "{device}" failed. Reason: {reason}',
  },
  idle: {
    id: 'firmwareListener.idle',
    defaultMessage: 'Idle',
  },
  notEnoughRam: {
    id: 'firmwareListener.notEnoughRam',
    defaultMessage: 'Not enough RAM',
  },
  notEnoughFlash: {
    id: 'firmwareListener.notEnoughFlash',
    defaultMessage: 'Not enough Flash',
  },
  connectionLost: {
    id: 'firmwareListener.connectionLost',
    defaultMessage: 'Connection lost',
  },
  softwareValidationFailure: {
    id: 'firmwareListener.softwareValidationFailure',
    defaultMessage: 'Software validation failure.',
  },
  invalidUrlToReceiveTheSoftwarePackage: {
    id: 'firmwareListener.invalidUrlToReceiveTheSoftwarePackage',
    defaultMessage: 'Invalid URL to receive the software package',
  },
  unsupportedProtocolForDownloadUrl: {
    id: 'firmwareListener.unsupportedProtocolForDownloadUrl',
    defaultMessage: 'Unsupported protocol for download URL',
  },
  firmwareUpdateFailed: {
    id: 'firmwareListener.firmwareUpdateFailed',
    defaultMessage: 'Firmware update failed',
  },
  unknown: {
    id: 'firmwareListener.unknown',
    defaultMessage: 'Unknown',
  },
  pending: {
    id: 'firmwareListener.pending',
    defaultMessage: 'Pending',
  },
})
