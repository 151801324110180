import { PROCESS_STARTED, PROCESS_FINISHED } from './process-indicator-actions'

export const processIndicatorReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case PROCESS_STARTED:
      return { ...state, [action.payload.name]: { isRunning: true, updateTime: Date.now() } }
    case PROCESS_FINISHED:
      return { ...state, [action.payload.name]: { isRunning: false, updateTime: Date.now() } }
    default:
      return state
  }
}
