import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { SbModal } from 'skybase-ui/skybase-components/sb-modal'
import { SbButton } from 'skybase-ui/skybase-components/sb-button'
import { SbListView } from 'skybase-ui/skybase-components/sb-listview'
import { SbTextbox } from 'skybase-ui/skybase-components/sb-textbox'
import { addComponentModalPossibleComponents } from '@/fleet-configuration/page-components/wizard/add-component-modal/add-component-modal-constants'
import { useIntl } from 'react-intl'
import { messages as t } from './add-component-modal-missing-data-i18n'
import './add-component-modal-missing-data.scss'

export const AddComponentModalMissingData = ({ existingData, componentToAdd, onSubmit, onCancel }) => {
  const [currentData, setCurrentData] = useState(existingData)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { formatMessage: _ } = useIntl()

  const areAllRequiredDataFilled = useMemo(() => {
    if (componentToAdd === addComponentModalPossibleComponents.SENSOR) {
      return currentData.typeNumber && currentData.serialNumber
    }
    return currentData.typeNumber
  }, [currentData, componentToAdd])

  const getControlsToDisplay = () => {
    if (componentToAdd === addComponentModalPossibleComponents.SENSOR) {
      const displayControls = []
      if (!existingData.typeNumber) {
        displayControls.push({
          title: _(t.pleaseConfirmTheTypeNumberOfYourSensor),
          item: (
            <SbTextbox
              value={currentData.typeNumber || ''}
              placeholder={_(t.typeNumber)}
              onChange={evt => {
                setCurrentData({ ...currentData, typeNumber: evt.target.value })
              }}
            />
          ),
        })
      }
      if (!existingData.serialNumber) {
        displayControls.push({
          title: _(t.pleaseConfirmTheSerialNumberOfYourSensor),
          item: (
            <SbTextbox
              value={currentData.serialNumber || ''}
              placeholder={_(t.serialNumber)}
              onChange={evt => {
                setCurrentData({ ...currentData, serialNumber: evt.target.value })
              }}
            />
          ),
        })
      }
      return displayControls
    }
    return [
      {
        title: _(t.pleaseConfirmTheTypeNumberOfYourCable),
        item: (
          <SbTextbox
            value={currentData.typeNumber || ''}
            placeholder={_(t.typeNumber)}
            onChange={evt => {
              setCurrentData({ ...currentData, typeNumber: evt.target.value })
            }}
          />
        ),
      },
    ]
  }

  const handleOnSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true)
      await onSubmit(currentData)
    } finally {
      setIsSubmitting(false)
    }
  }, [currentData, onSubmit])

  return (
    <SbModal
      width="280px"
      title={_(t.addMissingData)}
      onClickOverlay={onCancel}
      Footer={
        <div className="sb-modal-footer fl-container add-missing-data-footer">
          <SbButton onClick={onCancel}>{_(t.cancel)}</SbButton>
          <SbButton
            loading={isSubmitting}
            disabled={!areAllRequiredDataFilled}
            onClick={handleOnSubmit}
            className="primary"
          >
            {_(t.save)}
          </SbButton>
        </div>
      }
    >
      <SbListView items={getControlsToDisplay()} />
    </SbModal>
  )
}

AddComponentModalMissingData.propTypes = {
  existingData: PropTypes.object.isRequired,
  componentToAdd: PropTypes.oneOf(Object.values(addComponentModalPossibleComponents)).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}
