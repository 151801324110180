import { castArray, reduce } from 'lodash'
import { filterAlerts } from './filter-alerts'
import { sortAlerts } from './sort-alerts'
import { enrichWithReturnActions } from './enrich-with-return-actions'

export const getAlerts = (validation, ids) => {
  const allAlerts = []
  castArray(ids).forEach(id => {
    const alerts = filterAlerts(validation, id)
    const alertsWithReturnActions = enrichWithReturnActions(validation, id, alerts)
    allAlerts.push(...alertsWithReturnActions)
  })
  return sortAlerts(allAlerts)
}

export const getChannelAlerts = (state, channel) => {
  const channelPathLength = channel.path.length
  const { validation } = state
  return reduce(
    validation.controls,
    (acc, [alertId], key) => {
      if (key.startsWith(channel.path)) {
        acc[key.slice(channelPathLength)] = validation.alerts.find(alert => alert.id === alertId)
      }
      return acc
    },
    {},
  )
}
