import React from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import { castArray } from 'lodash'
import { connect } from 'react-redux'
import {
  setProjectDeviceChannel,
  saveProjectDevice,
  trySaveValidProjectDevice,
  setChannelAlerts,
  setProjectDeviceModuleDataSourceForChannel,
  updateDeviceChannelParameter,
} from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import {
  wizardNavigationSelection,
  urlPathController,
} from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation-constants'
import { getFieldName } from '@/fleet-configuration/data-fleet/project-devices/channel-utils'
import { withRouter } from '@/common/router'
import { selectChannelDetail } from './select-channel-detail'
import { ChannelParametersForm } from './channel-parameters-form'

const { SELECTED, UNSELECTED } = wizardNavigationSelection

export class _ChannelParameters extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    snippetPathPrefix: PropTypes.string.isRequired,
    isMeasurementReliabilityEnabled: PropTypes.bool,
    channel: PropTypes.object,
    device: PropTypes.object,
    envVars: PropTypes.object,
    shouldInitConnections: PropTypes.bool,
    computeDirtyState: PropTypes.bool,
    paramsSelected: PropTypes.object,
    paramSelectionChange: PropTypes.func,
    chainCatalog: PropTypes.object,
    chainSensitivity: PropTypes.object,
    chainMaxPhysicalRangeWithUnit: PropTypes.object,
  }

  static defaultProps = {
    channel: null,
    device: null,
    envVars: {},
    isMeasurementReliabilityEnabled: false,
    shouldInitConnections: true,
    computeDirtyState: true,
    paramsSelected: {},
    paramSelectionChange: null,
    chainCatalog: {},
    chainSensitivity: null,
    chainMaxPhysicalRangeWithUnit: null,
  }

  constructor(props) {
    super(props)
    this.handleOnActivateChannelBlur = this.handleOnActivateChannelBlur.bind(this)
  }

  componentDidMount() {
    const { channel, dispatch } = this.props
    if (channel) {
      channel.initValidationRules()
      const validationResults = channel.validate()
      dispatch(setChannelAlerts(validationResults))
    }
  }

  componentDidUpdate(prevProps) {
    const { channel, dispatch } = this.props
    if (channel && prevProps.channel?.resourcePath !== channel?.resourcePath) {
      channel.initValidationRules()
      const validationResults = channel.validate()
      dispatch(setChannelAlerts(validationResults))
    }
  }

  /* This function mutates "accProcessedParams" for performance reasons
   *  reduce GC = memory trashing by recreating same object with extra param each time
   */
  processParameterDependencyTree(accProcessedParams, actualParam, catalog) {
    // infinite loop break - do not process same parameter multiple times
    if (accProcessedParams.includes(actualParam)) {
      return
    }
    // mark that we processed this one
    accProcessedParams.push(actualParam)
    // non-enum parameters do not have dependencies that need to be stored
    if (!catalog.isEnum(actualParam)) {
      return
    }

    const { channel } = this.props

    // add all parent parameters that sets this one
    catalog.getDependencies(actualParam).forEach(prefixedDependencyName => {
      const dependency = getFieldName(prefixedDependencyName)
      const dependencyOption =
        catalog.isEnum(dependency) && catalog.getEnumOption(dependency, channel.parameters[dependency])
      if (
        dependencyOption?.changes?.find(({ field }) => getFieldName(field) === actualParam) ||
        dependencyOption?.filters?.find(({ field }) => getFieldName(field) === actualParam)
      ) {
        this.processParameterDependencyTree(accProcessedParams, dependency, catalog)
      }
    })

    const selectedOption = catalog.getEnumOption(actualParam, channel.parameters[actualParam])
    selectedOption?.changes?.forEach(changedPropDefinition => {
      const changedField = getFieldName(changedPropDefinition.field)
      this.processParameterDependencyTree(accProcessedParams, changedField, catalog)
    })
    selectedOption?.filters?.forEach(filteredPropDefinition => {
      const changedField = getFieldName(filteredPropDefinition.field)
      this.processParameterDependencyTree(accProcessedParams, changedField, catalog)
    })
  }

  /* This function mutates "accProcessedParams" for performance reasons
   *  reduce GC = memory trashing by recreating same object with extra param each time
   */
  processParameterDependencyRemoval(accProcessedParams, candidates, catalog) {
    let candidatesAdded
    do {
      candidatesAdded = 0
      // we are actually counting how many times we added candidate into function - not creating separate variable for
      //  each function call. For this reason I explicitly want to use "loop func variable" (forbidden by linter)
      // eslint-disable-next-line no-loop-func
      candidates.forEach((candidate, index) =>
        catalog.getDependencies(candidate).forEach(prefixedDependencyName => {
          const dependency = getFieldName(prefixedDependencyName)
          if (accProcessedParams.includes(dependency)) {
            accProcessedParams.push(dependency)
            candidates.splice(index - candidatesAdded, 1)
            candidatesAdded += 1
          }
        }),
      )
    } while (candidatesAdded)
  }

  getParameterDependencyGroups(processedParams, catalog) {
    // resolve grouping of selections (which is specific in UI and there is no catalog rule for this one)
    const groups = [
      ['physicalUnit', 'physicalRange'],
      ['switchingLevelLower', 'switchingLevelUpper'],
      ['filterFreq1', 'filterFreq2'],
    ]
    if (catalog.hasField('sensorTypeDetail')) {
      groups.push(['sensorConnectorType', 'sensorTypeDetail'])
    }
    if (catalog.hasField('signalTypeDetail') && catalog.hasField('signalConnectorType')) {
      groups.push(['signalTypeDetail', 'signalConnectorType'])
    }
    if (catalog.hasField('sensitivityUnit')) {
      groups.push(['sensitivity', 'sensitivityUnit', 'physicalUnit', 'unit'])
    }
    if (catalog.hasField('pulseUnit')) {
      groups.push(['pulseFactor', 'pulseOffset'])
    }
    const { channel } = this.props
    if (channel.hasCustomUnit()) {
      groups.push(['physicalUnit', 'customUnit'])
    }

    // if one item from group is selected, then all items from group are selected
    const addedItems = []
    groups.forEach(group => {
      const missing = []
      group.forEach(item => {
        if (!processedParams.includes(item)) {
          missing.push(item)
        }
      })
      if (missing.length !== group.length) {
        addedItems.push(...missing)
      }
    })
    return addedItems
  }

  getParamsDependencyTree(sourceParams, isAddition) {
    const { channel } = this.props
    const targetParams = [...sourceParams]
    const { catalog } = channel

    const channelPath = `$.modules[${channel.getModuleIndex()}].channels[${channel.getChannelIndex()}].`
    const paramsPath = 'parameters.'
    const processedParams = []
    targetParams.forEach(fullParamPath => {
      if (!fullParamPath.startsWith(channelPath)) {
        return
      }
      const inChannelPath = fullParamPath.slice(channelPath.length)
      // process direct parameters
      if (inChannelPath.startsWith(paramsPath)) {
        const paramName = inChannelPath.slice(paramsPath.length)
        this.processParameterDependencyTree(processedParams, paramName, catalog)
      }
    })
    // check other parameters if they require one of removed values
    let otherSelectedParams
    if (!isAddition) {
      const { paramsSelected } = this.props
      const paramsPrefix = `${channelPath}${paramsPath}`
      otherSelectedParams = Object.keys(paramsSelected)
        .filter(param => param.startsWith(paramsPrefix))
        .map(fullPath => fullPath.slice(paramsPrefix.length))
      this.processParameterDependencyRemoval(processedParams, otherSelectedParams, catalog)
    }
    let addedItems
    do {
      addedItems = this.getParameterDependencyGroups(processedParams, catalog)
      addedItems.forEach(addedGroupItem => {
        this.processParameterDependencyTree(processedParams, addedGroupItem, catalog)
      })
      if (!isAddition && addedItems.length) {
        this.processParameterDependencyRemoval(processedParams, otherSelectedParams, catalog)
      }
    } while (addedItems.length)

    // handle selecting filter parameters that are for BE purpose not stored in "parameters"
    const filterParameters = {
      filterType: 'type',
      filterFreq1: 'frequencies',
      algorithm: 'algorithm',
      order: 'order',
      qualityFactor: 'qualityFactor',
      sampleCount: 'sampleCount',
    }

    const mirrorResources = []
    catalog.getMirrorResourcesFields().forEach(mirrorField => {
      const option = catalog.getEnumOption(mirrorField, channel.parameters[mirrorField])
      if (option?.mirrorResources) {
        mirrorResources.push(option?.mirrorResources)
      }
    })

    // convert just parameter names into snippet's digestible format
    processedParams.forEach(paramToAdd => {
      const fullPath = `${channelPath}${paramsPath}${paramToAdd}`
      if (!targetParams.includes(fullPath)) {
        targetParams.push(fullPath)
      }

      if (filterParameters[paramToAdd]) {
        const filterFullPath = `${channelPath}deviceSpecific.filter.${filterParameters[paramToAdd]}`
        if (!targetParams.includes(filterFullPath)) {
          targetParams.push(filterFullPath)
        }
      }

      mirrorResources.forEach(mirrorResource => {
        const mirrorMap = mirrorResource.map.find(({ fromField }) => fromField === paramToAdd)
        if (mirrorMap) {
          const mirrorFullPath = `${channelPath}${mirrorResource.toPath}.${mirrorMap.toField}`
          if (!targetParams.includes(mirrorFullPath)) {
            targetParams.push(mirrorFullPath)
          }
        }
      })
    })

    return targetParams
  }

  handleParamsSelectionChange = (checked, name) => {
    const { paramSelectionChange } = this.props

    const writeValue = checked ? SELECTED : UNSELECTED
    paramSelectionChange(writeValue, this.getParamsDependencyTree(castArray(name), checked))
  }

  handleOnActivateChannelChange = enabled => {
    const { dispatch, channel } = this.props
    const updatedChannel = channel.setParameter('enabled', enabled)
    dispatch(setProjectDeviceChannel(updatedChannel))
    const updatedDevice = dispatch(setProjectDeviceModuleDataSourceForChannel(updatedChannel))
    this.validate(updatedDevice)
  }

  async handleOnActivateChannelBlur() {
    const { dispatch, device } = this.props
    await dispatch(saveProjectDevice(device))
  }

  handleOnParameterChange = (field, value) => {
    const { channel, dispatch, paramSelectionChange } = this.props
    if (paramSelectionChange || channel.parameters[field] === value) {
      return
    }
    // for unknown reason in specific case "channel" variable can be old reference of object that should no longer exist
    // instead of using it directly, go to redux and get up-to-date reference and update the parameter from there
    const deviceId = channel.getDeviceId()
    const moduleIndex = channel.getModuleIndex()
    const channelIndex = channel.getChannelIndex()
    const updatedDevice = dispatch(updateDeviceChannelParameter(deviceId, moduleIndex, channelIndex, field, value))
    const updatedModule =
      moduleIndex === urlPathController ? updatedDevice.deviceSpecific.controller : updatedDevice.modules[moduleIndex]
    const updatedChannel = updatedModule.channels[channelIndex]
    this.validate(updatedChannel)
  }

  handleOnParameterCommit = () => {
    const { dispatch, device, paramSelectionChange } = this.props
    if (paramSelectionChange) {
      return
    }
    dispatch(trySaveValidProjectDevice(device))
  }

  handleOnSubResourceChange = (subResource, field, value) => {
    const { channel, dispatch, paramSelectionChange } = this.props
    if (paramSelectionChange) {
      return null
    }
    const updatedChannel = channel.setSubResourceParameter(subResource, field, value)
    const updatedDevice = dispatch(setProjectDeviceChannel(updatedChannel))
    this.validate(updatedChannel)
    return updatedDevice
  }

  handleOnSubResourceBlur = () => {
    const { dispatch, device, paramSelectionChange } = this.props
    if (paramSelectionChange) {
      return
    }
    dispatch(trySaveValidProjectDevice(device))
  }

  handleOnSubResourceNumericBlur = (subResource, field, value) => {
    const { dispatch, paramSelectionChange } = this.props
    if (paramSelectionChange) {
      return
    }
    const updatedDevice = this.handleOnSubResourceChange(subResource, field, parseFloat(value))
    dispatch(trySaveValidProjectDevice(updatedDevice))
  }

  handleOnNumericParameterBlur = (field, value) => {
    const { channel, dispatch, paramSelectionChange } = this.props
    const floatValue = parseFloat(value)
    if (paramSelectionChange) {
      return
    }
    if (channel.parameters[field] === floatValue) {
      dispatch(trySaveValidProjectDevice(channel.getDeviceId()))
      return
    }
    const updatedChannel = channel.setParameter(field, floatValue)
    const updatedDevice = dispatch(setProjectDeviceChannel(updatedChannel))
    dispatch(trySaveValidProjectDevice(updatedDevice))
  }

  handleOnSignalNameChange = spec => {
    const { channel, dispatch, paramSelectionChange } = this.props
    if (paramSelectionChange) {
      return
    }
    const updatedChannel = update(channel, spec)
    dispatch(setProjectDeviceChannel(updatedChannel))
    this.validate(updatedChannel)
  }

  handleOnSignalNameBlur = () => {
    const { dispatch, device, paramSelectionChange } = this.props
    if (paramSelectionChange) {
      return
    }
    dispatch(trySaveValidProjectDevice(device))
  }

  validate = target => {
    const { dispatch } = this.props
    const validationResults = target.validate()
    dispatch(setChannelAlerts(validationResults))
  }

  render() {
    const {
      channel,
      isMeasurementReliabilityEnabled,
      envVars,
      shouldInitConnections,
      computeDirtyState,
      paramsSelected,
      paramSelectionChange,
      snippetPathPrefix,
      chainCatalog,
      chainSensitivity,
      chainMaxPhysicalRangeWithUnit,
    } = this.props
    return (
      <ChannelParametersForm
        channel={channel}
        isMeasurementReliabilityEnabled={isMeasurementReliabilityEnabled}
        onSignalNameChange={this.handleOnSignalNameChange}
        onSignalNameBlur={this.handleOnSignalNameBlur}
        onParameterChange={this.handleOnParameterChange}
        onParameterCommit={this.handleOnParameterCommit}
        onNumericParameterBlur={this.handleOnNumericParameterBlur}
        onActivateChannelChange={this.handleOnActivateChannelChange}
        onActivateChannelBlur={this.handleOnActivateChannelBlur}
        onEnvValueChange={this.handleOnEnvValueChange}
        onEnvValueBlur={this.handleOnEnvValueBlur}
        onSubResourceChange={this.handleOnSubResourceChange}
        onSubResourceNumericBlur={this.handleOnSubResourceNumericBlur}
        onSubResourceBlur={this.handleOnSubResourceBlur}
        envVars={envVars}
        shouldInitConnections={shouldInitConnections}
        computeDirtyState={computeDirtyState}
        paramsSelected={paramsSelected}
        paramSelectionChange={paramSelectionChange}
        snippetPathPrefix={snippetPathPrefix}
        handleParamsSelectionChange={this.handleParamsSelectionChange}
        chainCatalog={chainCatalog}
        chainSensitivity={chainSensitivity}
        chainMaxPhysicalRangeWithUnit={chainMaxPhysicalRangeWithUnit}
      />
    )
  }
}

export const ChannelParameters = withRouter(connect(selectChannelDetail)(_ChannelParameters))
