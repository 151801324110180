/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  confirm: {
    id: 'confirmModalActions.confirm',
    defaultMessage: 'Confirm',
  },
  cancel: {
    id: 'confirmModalActions.cancel',
    defaultMessage: 'Cancel',
  },
})
