import React from 'react'
import { useIntl } from 'react-intl'

import { SbLabel } from 'skybase-ui/skybase-components/sb-label'
import { InfoMessage, CopyBox } from 'skybase-oauth/common/components'

import { StatusBullet, RED, GREEN } from '@/common/status-bullet'
import { getValue } from '@/utils'
import { licenseShape } from './shapes'
import { messages as t } from './licenses-i18n'

export const LicenseDetails = ({ data = null }) => {
  const { formatMessage: _, formatDate } = useIntl()

  if (data) {
    const { assignmentTime, itemName, productCode, quantity, orderIdentification, ticketIdentification, activated } =
      data

    return (
      <>
        <SbLabel title={_(t.licenseName)} inline id="license-details-name">
          {getValue(itemName)}
        </SbLabel>
        <SbLabel title={_(t.productCode)} inline id="license-details-product-code">
          {getValue(productCode)}
        </SbLabel>
        <SbLabel title={_(t.assignmentTime)} inline id="license-details-assignment-time">
          {formatDate(assignmentTime, {
            format: 'date-minutes-12hour-numeric',
          })}
        </SbLabel>
        <SbLabel title={_(t.quantity)} inline id="license-details-quantity">
          {getValue(quantity)}
        </SbLabel>
        <SbLabel title={_(t.orderIdentification)} inline id="license-details-orderid">
          {getValue(orderIdentification)}
        </SbLabel>
        <SbLabel title={_(t.ticketId)} inline id="license-details-ticketid">
          <CopyBox text={ticketIdentification} />
        </SbLabel>
        <SbLabel title={_(t.activation)} inline id="license-details-activation">
          <StatusBullet className="no-word-break" status={activated ? GREEN : RED}>
            {activated ? _(t.activated) : _(t.notActivated)}
          </StatusBullet>
        </SbLabel>
      </>
    )
  }

  return <InfoMessage>{_(t.selectALicense)}</InfoMessage>
}

LicenseDetails.propTypes = {
  data: licenseShape,
}
