import { OAuth } from 'skybase-oauth'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter'
import { hasAccess } from 'skybase-oauth/auth'
import { AUTH_STATE_KEY_NAME } from 'skybase-oauth/oauth/constants'
import { getOAuthState } from 'skybase-oauth/utils'

import { getMode, getTime } from '@/utils'
import { store } from '@/stores'
import { WebSocketClient } from './websocket-client'
import { wsClientList as staticWsClientList } from './constants'
import { resetDelayListeners } from './utils'

class _WSClients {
  constructor() {
    this.wsClientList = staticWsClientList
    this.isInitialized = false
    this.ws = {}
  }

  _addToWsClientList = (name, endpoint) => {
    this.wsClientList = {
      ...this.wsClientList,
      [name]: endpoint,
    }
  }

  _registerWS = (name, endpoint, baseUrl) => {
    if (this.ws[name]) {
      throw new Error(`WS [${name}] is already registered.`)
    }

    this.ws[name] = new WebSocketClient(endpoint, baseUrl)
  }

  _unregisterWS = name => {
    if (this.ws[name]) {
      this.ws[name].onMessage = () => {}
      this.ws[name].onClose = () => {}
      this.ws[name].onOpen = () => {}
      this.ws[name].onError = () => {}
      this.ws[name].disconnect()
      delete this.ws[name]
    }
  }

  // Disconnect the active WebSockets when a silent token renewal went through.
  // The disconnect will trigger the onClose event in the active socket,
  // which triggers the connect() after 2 seconds with new token.
  _disconnectAllWSClients = () => {
    const { wsClientList } = this

    Object.keys(wsClientList).forEach(id => {
      if (this.ws[id]) {
        this.ws[id].disconnect()
      }
    })
  }

  // name, endpoint -> required fields
  addToWsClients = ({ name, endpoint, listener = null, delayListener = 4000, permission = null, ...rest }) => {
    if (name && endpoint) {
      this.wsClientList = {
        ...this.wsClientList,
        [name]: {
          endpoint,
          listener,
          permission,
          delayListener,
          ...rest,
        },
      }

      this.registerWSClients()
    }
  }

  removeFromWsClients = name => {
    this._unregisterWS(name)

    if (this.wsClientList[name]) {
      delete this.wsClientList[name]
    }
  }

  removeAllByPartialNameFromWsClient = partialName => {
    Object.keys(this.ws)
      .filter(key => key.indexOf(partialName) !== -1)
      .forEach(key => this.removeFromWsClients(key))
  }

  registerWSClients = (isInit = false) => {
    if (OAuth.oAuthService.hasValidToken() || OAuth.isInLocalSDKMode) {
      const { permissions } = getOAuthState(store.getState())[AUTH_STATE_KEY_NAME] || {}
      const { wsClientList } = this

      const normalizeListeners = OAuth.isInNormalMode ? wsClientList : resetDelayListeners(wsClientList)
      // Register clients
      Object.keys(normalizeListeners).forEach(id => {
        // If registered, dont try to register again
        if (!this.ws[id]) {
          const {
            endpoint,
            listener,
            permission,
            delayListener = 4000,
            baseUrl = null,
            onOpen = null,
            onError = null,
          } = normalizeListeners[id]
          if (!permission || (permission && hasAccess(permissions, permission))) {
            this._registerWS(id, endpoint, baseUrl)

            // Connect to the WS and Register listeners
            if (this.ws[id] && listener) {
              this.ws[id].connect(delayListener)
              this.ws[id].onMessage = listener

              // Reconnect on close after 2 seconds
              this.ws[id].onClose = () => {
                if (getMode() !== 'production') {
                  console.info(`ws [${id}] was closed, reconnecting in 2 seconds %c@ ${getTime()}`, 'color: #888887;')
                }
                // After a close event, reconnect
                setTimeout(() => {
                  if (this.ws[id]) {
                    const reconnectDelayListenerTime = 1500
                    this.ws[id].connect(reconnectDelayListenerTime)
                  }
                }, 2000)
              }

              this.ws[id].onOpen = (...args) => {
                if (onOpen) {
                  onOpen(...args, this.ws[id])
                }

                if (getMode() !== 'production') {
                  console.info(`ws [${id}] was opened %c@ ${getTime()}`, 'color: #888887;')
                }
              }

              this.ws[id].onError = (...args) => {
                if (onError) {
                  onError(...args, this.ws[id])
                }
              }
            }
          }
        }
      })

      // Register the silent token renew listener only when we call this method with the isInit true, meaning it is called for the first time
      if (isInit) {
        SbEmitter.on('oauth.success.silent_renew', this._disconnectAllWSClients)
        this.isInitialized = true
      }
    }
  }

  unregisterWSClients = () => {
    const { wsClientList } = this

    // Reset listeners clients and unregister clients
    Object.keys(wsClientList).forEach(id => {
      this._unregisterWS(id)
    })

    SbEmitter.off('oauth.success.silent_renew', this._disconnectAllWSClients)
  }

  getWs = id => {
    return this.ws[id]
  }

  isConnected = id => {
    return this.ws[id].isConnected()
  }
}

export const WSClients = new _WSClients()
