import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './list-view.scss'

export const ListView = ({ children = null, className = null, stretch = true }) => {
  return (
    <ul className={classNames('fl-container-column fl-grow-1 list-view', className)}>
      {children}
      {stretch && <li className="fl-grow-1 list-view-item" />}
    </ul>
  )
}

ListView.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  stretch: PropTypes.bool,
}
