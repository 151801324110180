import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { withRouter } from '@/common/router'
import { SbSeekbar } from 'skybase-ui/skybase-components/sb-seekbar'
import { SbInlineMessage } from 'skybase-ui/skybase-components/sb-inline-message'
import { INLINE_MESSAGE_TYPE_INFO } from 'skybase-ui/skybase-components/sb-inline-message/constants'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { loaderSize } from 'skybase-ui/skybase-components/sb-loader/constants'
import { SbLabel } from 'skybase-ui/skybase-components/sb-label'
import { updateChain } from '@/fleet-configuration/data-fleet/chain/chain-actions'
import { tryLoadChainCatalog } from '@/fleet-configuration/data-fleet/chain-catalog/chain-catalog-actions'
import { SwitchingControl } from '@/fleet-configuration/components/switching-control/switching-control'
import { TextBox } from '@/fleet-configuration/components/text-box/text-box'
import {
  saveProjectDevice,
  setProjectDeviceChannel,
  setProjectDeviceModuleDataSourceForChannel,
} from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { tryLoadChainCertificate } from '@/fleet-configuration/data-fleet/chain-certificate/chain-certificate-actions'
import { DeviceComponentIcon } from '@/fleet-configuration/device-component-icon'
import {
  updateCutoffFrequencyDependencyRecalculations,
  updateMaxPhysicalRangeDependencyRecalculations,
} from '@/fleet-configuration/page-components/measurement-unit/measurement-unit-detail-channel-smart-values-actions'
import { MeasurementUnitDetailChannelSmartValuesSelector } from './measurement-unit-detail-channel-smart-values-selector'
import { messages as t } from './measurement-unit-detail-channel-smart-values-i18n'
import './measurement-unit-detail-channel-smart-values.scss'

let lastRenderedDevice
export const MeasurementUnitDetailChannelSmartValues = withRouter(props => {
  const [shouldInitialize, setShouldInitialize] = useState(false)
  const [liveEditedComponent, setLiveEditedComponent] = useState({ resourcePath: null, property: null, value: '' })
  const [collapsedModules, setCollapsedModules] = useState({})
  const { formatMessage: _ } = useIntl()
  const dispatch = useDispatch()
  const { enrichedDeviceModulesChain, chainComponentTypes, sensorTypeAndSerialNumbers, chainCatalog, chainLoaded } =
    useSelector(state => MeasurementUnitDetailChannelSmartValuesSelector(state, props))

  const { device } = props
  lastRenderedDevice = device

  const getInitializedStateOfModuleChannels = () => {
    // if chain is not loaded - return empty array (which means everything is loading)
    if (!chainComponentTypes.length) {
      return []
    }
    const componentsThatNeedLoading = chainComponentTypes.filter(
      componentTypeId => !chainCatalog.find(catalogEntry => catalogEntry.type === componentTypeId),
    )
    return device.modules.map((module, moduleIndex) =>
      module.channels.map((channel, channelIndex) => {
        const slot = `channels/${channelIndex + 1}`
        const channelChain = enrichedDeviceModulesChain[moduleIndex].find(chainItem => chainItem.channel?.slot === slot)

        // no channel chain means there's nothing to load = it's initialized
        if (!channelChain) {
          return true
        }

        // if sensor catalog-loading request is not finished yet
        if (channelChain.sensor?.typeNumber in componentsThatNeedLoading) {
          return false
        }

        // if cable catalog-loading request is not finished yet
        if (channelChain.cable?.typeNumber in componentsThatNeedLoading) {
          return false
        }

        // if sensor has catalog (full non-empty response), has certificate and calculated cutOffFrequency and maxPhysicalRange
        return (
          !channelChain.sensor?.catalog ||
          (channelChain.sensor.sensitivityAtMaxRange &&
            channelChain.userPreferences &&
            'cutOffFrequency' in channelChain.userPreferences &&
            'maxPhysicalRange' in channelChain.userPreferences)
        )
      }),
    )
  }

  const [areModuleChannelsInitialized, setAreModuleChannelsInitialized] = useState(
    getInitializedStateOfModuleChannels(),
  )

  // every chain-catalog dispatch will basically ask to replay this use-effect. Debounce reduces these "empty" re-calculations
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const chainCatalogLoaderEffectDebounced = useCallback(
    debounce(
      (innerChainComponentTypes, innerSensorTypeAndSerialNumbers, innerChainCatalog) => {
        // preemptively remove catalog entries that are already loaded so that less (expensive) dispatches happen
        const componentTypes = innerChainComponentTypes.filter(
          componentTypeId => !innerChainCatalog.find(catalogEntry => catalogEntry.type === componentTypeId),
        )
        const componentPromises = componentTypes.map(componentType => dispatch(tryLoadChainCatalog(componentType)))
        const promises = [
          ...componentPromises,
          ...innerSensorTypeAndSerialNumbers.map(({ typeNumber, serialNumber }) =>
            dispatch(tryLoadChainCertificate(typeNumber, serialNumber)),
          ),
        ].filter(isPromiseItem => isPromiseItem.then)
        if (promises.length) {
          Promise.allSettled(promises).then(() => {
            setShouldInitialize(true)
          })
        } else {
          // a rare case when someone adds chain that is already loaded
          // basically happens only if user deletes existing chain and recreates it to the exact same value
          setShouldInitialize(true)
        }
      },
      1000,
      { leading: false, trailing: true },
    ),
    [],
  )

  const getInitialSliderValues = useCallback((sensorCatalog, sensitivityAtMaxRange) => {
    // if we don't have correct catalog, then we should not fill in default values (they should remain null)
    if (!sensorCatalog) {
      throw new Error("Can't get initial slider data without source catalog")
    }
    if (!sensitivityAtMaxRange) {
      throw new Error("Can't get initial slider data without source certificate")
    }
    return {
      cutOffFrequency: sensorCatalog.naturalFrequency / 2,
      maxPhysicalRange: sensitivityAtMaxRange.slice(-1)[0].maxRange,
    }
  }, [])

  useEffect(() => {
    if (chainComponentTypes.length) {
      chainCatalogLoaderEffectDebounced(chainComponentTypes, sensorTypeAndSerialNumbers, chainCatalog)
    } else if (chainLoaded && device) {
      // now mark every module and every channel as initialized
      setAreModuleChannelsInitialized(device.modules.map(module => module.channels.map(() => true)))
    }
  }, [
    chainComponentTypes,
    sensorTypeAndSerialNumbers,
    chainCatalog,
    chainLoaded,
    device,
    dispatch,
    chainCatalogLoaderEffectDebounced,
  ])

  useEffect(() => {
    if (shouldInitialize) {
      const promisesWithInitializationChain = []
      const scheduledUpdates = []
      enrichedDeviceModulesChain.forEach(moduleChain =>
        moduleChain.forEach(channelChain => {
          if (!(channelChain.userPreferences?.cutOffFrequency && channelChain.userPreferences?.maxPhysicalRange)) {
            if (channelChain.sensor?.catalog && channelChain.sensor?.sensitivityAtMaxRange) {
              const chainToUpdateTo = {
                ...channelChain,
                userPreferences: Object.entries(
                  getInitialSliderValues(channelChain.sensor.catalog, channelChain.sensor.sensitivityAtMaxRange),
                ).reduce((acc, [key, value]) => {
                  acc[key] = channelChain.userPreferences?.[key] ?? value // if existing value is null or undefined, make it default value instead
                  return acc
                }, {}),
              }
              promisesWithInitializationChain.push(dispatch(updateChain(chainToUpdateTo)))
              scheduledUpdates.push(chainToUpdateTo)
            }
          }
        }),
      )
      Promise.allSettled(promisesWithInitializationChain).then(() => {
        scheduledUpdates.forEach(chainToUpdate => {
          dispatch(
            updateCutoffFrequencyDependencyRecalculations(
              chainToUpdate,
              chainToUpdate.userPreferences.cutOffFrequency,
              device.id,
              enrichedDeviceModulesChain,
            ),
          )
          dispatch(
            updateMaxPhysicalRangeDependencyRecalculations(
              chainToUpdate,
              chainToUpdate.userPreferences.maxPhysicalRange,
              device.id,
            ),
          )
        })
        setShouldInitialize(false)
        // now mark every module and every channel as initialized
        setAreModuleChannelsInitialized(device.modules.map(module => module.channels.map(() => true)))
      })
    }
  }, [shouldInitialize, device, enrichedDeviceModulesChain, getInitialSliderValues, dispatch])

  const handleOnActivateChannelChangeFactory = channel => enabled => {
    const updatedChannel = channel.setParameter('enabled', enabled)
    dispatch(setProjectDeviceChannel(updatedChannel))
    dispatch(setProjectDeviceModuleDataSourceForChannel(updatedChannel))
  }

  const handleOnActivateChannelBlur = () => {
    // can't use "device", because that would reference object that was created when this function was created
    // we instead want to reference/store object, that underwent all the updates (in this case changes to channel enabled state)
    dispatch(saveProjectDevice(lastRenderedDevice))
  }

  /* This version needs to be here until max value bug in skybase is fixed properly (possible only in allowArbitraryValue case) */
  const fixSeekbarNumericValueChange = (rangeMin, rangeMax, originalOnChangeCallback) => value => {
    const length = Math.round(rangeMax - rangeMin)

    // if step adjustment happened, check if we actually tried to set max value (but it got changed to maxValue divisible by step)
    if (length > 10000) {
      const newStep = Math.round((rangeMax - rangeMin) / 10000)
      if (value + newStep > rangeMax) {
        return originalOnChangeCallback(rangeMax)
      }
    }
    return originalOnChangeCallback(value)
  }

  const updateCutoffFrequency = (existingChain, newCutoffFrequency) => {
    let cutOffFrequency = newCutoffFrequency < 1 ? 1 : newCutoffFrequency
    if (
      existingChain.sensor?.catalog?.naturalFrequency &&
      newCutoffFrequency > existingChain.sensor.catalog.naturalFrequency / 2
    ) {
      cutOffFrequency = existingChain.sensor.catalog.naturalFrequency / 2
    }
    if (cutOffFrequency === existingChain.userPreferences.cutOffFrequency) {
      return
    }
    dispatch(
      updateChain({
        ...existingChain,
        userPreferences: { ...existingChain.userPreferences, cutOffFrequency },
      }),
    )
    dispatch(
      updateCutoffFrequencyDependencyRecalculations(
        existingChain,
        cutOffFrequency,
        device.id,
        enrichedDeviceModulesChain,
      ),
    )
  }

  const updateMaxPhysicalRange = (existingChain, newMaxPhysicalRange) => {
    // deduplicate calls
    if (existingChain?.userPreferences?.maxPhysicalRange === newMaxPhysicalRange) {
      return
    }
    const minimum = Math.trunc(existingChain.sensor?.sensitivityAtMaxRange?.[0]?.maxRange)
    const rawMaximum = existingChain.sensor?.sensitivityAtMaxRange?.slice(-1)[0]?.maxRange
    const maximum = rawMaximum < 0 ? Math.floor(rawMaximum) : Math.ceil(rawMaximum)
    let maxPhysicalRange = newMaxPhysicalRange === 0 ? 1 : newMaxPhysicalRange
    if (!isNaN(minimum) && !isNaN(maximum)) {
      maxPhysicalRange = Math.min(maximum, Math.max(minimum, maxPhysicalRange))
    }
    if (isNaN(maxPhysicalRange)) {
      maxPhysicalRange = minimum
    }
    dispatch(
      updateChain({
        ...existingChain,
        userPreferences: { ...existingChain.userPreferences, maxPhysicalRange },
      }),
    )
    dispatch(updateMaxPhysicalRangeDependencyRecalculations(existingChain, maxPhysicalRange, device.id))
  }

  return (
    <div className="smart-settings-panel">
      {device.modules.map((module, moduleIndex) => (
        <div className="module-wrapper">
          <p className="module-title">
            <button
              type="button"
              className={classnames(
                'p-l-10',
                collapsedModules[module.resourcePath] ? 'sbi-chevron-right' : 'sbi-chevron-down',
              )}
              onClick={() =>
                setCollapsedModules({
                  ...collapsedModules,
                  [module.resourcePath]: !collapsedModules[module.resourcePath],
                })
              }
            >
              <DeviceComponentIcon className="m-l-15 m-r-10" types={module.types} width={20} height={20} />
              {_(t.moduleModule, { module: module.getType() })}
            </button>
          </p>
          {!collapsedModules[module.resourcePath] &&
            module.channels.map((channel, channelIndex) => {
              const slot = `channels/${channelIndex + 1}`
              const channelChain = enrichedDeviceModulesChain[moduleIndex].find(
                chainItem => chainItem.channel?.slot === slot,
              )
              return (
                <div className="smart-settings-item" key={channel.resourcePath}>
                  <div className="smart-settings-description-side">
                    <p className="channel-name">
                      <span className="channel-icon m-r-10" />
                      {_(t.channelNumber, { number: channel.getChannelIndex() + 1 })}
                    </p>
                    <div className="channel-active">
                      <SwitchingControl
                        checked={channel.isActive()}
                        onChange={handleOnActivateChannelChangeFactory(channel)}
                        onBlur={handleOnActivateChannelBlur}
                        textTrue={_(t.on)}
                        textFalse={_(t.off)}
                      />
                      <span className="p-l-10">{channel.isActive() ? _(t.active) : _(t.inactive)}</span>
                    </div>
                  </div>
                  <div className="smart-settings-values-side">
                    {(() => {
                      if (!areModuleChannelsInitialized[moduleIndex]?.[channelIndex]) {
                        return (
                          <div className="fl-container fl-justify-center">
                            <SbLoader show size={loaderSize.XS} />
                          </div>
                        )
                      }
                      if (!channelChain?.sensor?.typeNumber) {
                        return (
                          <SbInlineMessage
                            type={INLINE_MESSAGE_TYPE_INFO}
                            title={_(t.toEnableThisFeaturePleaseAddASensorToTheChannel, { br: <br /> })}
                          />
                        )
                      }
                      if (!channelChain.sensor.catalog || !channelChain.sensor.sensitivityAtMaxRange) {
                        return (
                          <SbInlineMessage
                            type={INLINE_MESSAGE_TYPE_INFO}
                            title={_(t.theSystemWasNotAbleToGetAllDataForTheSpecifiedSensor, { br: <br /> })}
                          />
                        )
                      }
                      return (
                        <>
                          <SbLabel title={_(t.highestFrequencyOfInterest)} inline>
                            <TextBox
                              id={`cut-off-frequency-${moduleIndex}-${channelIndex}`}
                              type="number"
                              disabled={!channel.isActive()}
                              outerClassName="sb-width-sm"
                              value={
                                liveEditedComponent.resourcePath === channel.resourcePath &&
                                liveEditedComponent.property === 'cutOffFrequency'
                                  ? liveEditedComponent.value
                                  : channelChain.userPreferences?.cutOffFrequency
                              }
                              onFocus={() => {
                                setLiveEditedComponent({
                                  resourcePath: channel.resourcePath,
                                  property: 'cutOffFrequency',
                                  value: channelChain.userPreferences?.cutOffFrequency,
                                })
                              }}
                              onChange={evt => {
                                setLiveEditedComponent({
                                  resourcePath: channel.resourcePath,
                                  property: 'cutOffFrequency',
                                  value: evt.target.value,
                                })
                              }}
                              onCommit={value => {
                                setLiveEditedComponent({
                                  resourcePath: '',
                                  property: '',
                                  value: '',
                                })
                                updateCutoffFrequency(channelChain, parseFloat(value))
                              }}
                              suffix={_(t.hz)}
                            />
                          </SbLabel>
                          <SbSeekbar
                            className="m-b-30"
                            disabled={!channel.isActive()}
                            min={1}
                            max={channelChain.sensor.catalog.naturalFrequency / 2}
                            value={channelChain.userPreferences?.cutOffFrequency || 0}
                            onChange={fixSeekbarNumericValueChange(
                              1,
                              channelChain.sensor.catalog.naturalFrequency / 2,
                              newValue => updateCutoffFrequency(channelChain, newValue),
                            )}
                            allowArbitraryValue
                          />
                          {(() => {
                            const physicalRangeMinimum = Math.trunc(
                              channelChain.sensor.sensitivityAtMaxRange[0].maxRange,
                            )
                            const physicalRangeRawMaximum =
                              channelChain.sensor.sensitivityAtMaxRange.slice(-1)[0].maxRange
                            const physicalRangeMaximum =
                              physicalRangeRawMaximum < 0
                                ? Math.floor(physicalRangeRawMaximum)
                                : Math.ceil(physicalRangeRawMaximum)
                            if (physicalRangeMinimum >= physicalRangeMaximum) {
                              return null
                            }
                            return (
                              <>
                                <SbLabel
                                  title={_(t.maximalUnit, { unit: channelChain.sensor.catalog.measurand })}
                                  inline
                                >
                                  <TextBox
                                    id={`max-physical-range-${moduleIndex}-${channelIndex}`}
                                    type="number"
                                    disabled={!channel.isActive()}
                                    outerClassName="sb-width-sm"
                                    value={
                                      liveEditedComponent.resourcePath === channel.resourcePath &&
                                      liveEditedComponent.property === 'maxPhysicalRange'
                                        ? liveEditedComponent.value
                                        : channelChain.userPreferences?.maxPhysicalRange
                                    }
                                    onFocus={() => {
                                      setLiveEditedComponent({
                                        resourcePath: channel.resourcePath,
                                        property: 'maxPhysicalRange',
                                        value: channelChain.userPreferences?.maxPhysicalRange,
                                      })
                                    }}
                                    onChange={evt => {
                                      setLiveEditedComponent({
                                        resourcePath: channel.resourcePath,
                                        property: 'maxPhysicalRange',
                                        value: evt.target.value,
                                      })
                                    }}
                                    onCommit={value => {
                                      setLiveEditedComponent({
                                        resourcePath: '',
                                        property: '',
                                        value: '',
                                      })
                                      updateMaxPhysicalRange(channelChain, parseFloat(value))
                                    }}
                                    suffix={channelChain.sensor.maxPhysicalRangeUnit || ''}
                                  />
                                </SbLabel>
                                <SbSeekbar
                                  disabled={!channel.isActive()}
                                  min={physicalRangeMinimum}
                                  max={physicalRangeMaximum}
                                  value={channelChain.userPreferences?.maxPhysicalRange}
                                  onChange={fixSeekbarNumericValueChange(
                                    physicalRangeMinimum,
                                    physicalRangeMaximum,
                                    newValue => updateMaxPhysicalRange(channelChain, newValue),
                                  )}
                                  allowArbitraryValue
                                />
                              </>
                            )
                          })()}
                        </>
                      )
                    })()}
                  </div>
                </div>
              )
            })}
        </div>
      ))}
    </div>
  )
})

MeasurementUnitDetailChannelSmartValues.propTypes = {
  device: PropTypes.object.isRequired,
}
