import {
  getDeviceSyncStartedProcessName,
  getDeviceSyncStatuses,
  hasDeviceSyncFailedBySyncStatuses,
  hasSyncSucceededBySyncStatuses,
  isDeviceBeingSyncedBySyncStatuses,
} from '@/fleet-configuration/data-fleet/device-sync/device-sync-selectors'
import { getProcessIndicators } from '@/fleet-configuration/data-fleet/process-indicator/process-indicator-selectors'
import { isDeviceDirtyByProjectDevices } from '@/fleet-configuration/data-fleet/project-devices-dirty/project-devices-dirty-selector'
import { deviceSyncOperationNames } from '@/fleet-configuration/data-fleet/device-sync/device-sync-constants'
import {
  getProjectDevices,
  isDeviceOnlineByOnlineStatuses,
} from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import { createSelector } from 'reselect'
import { getDevicesOnlineStatuses } from '@/fleet-configuration/data-fleet/device-online-status/device-online-status-selector'

const getSyncStartTime = (deviceSyncStatuses, deviceId) => {
  const deviceSyncStatus = deviceSyncStatuses[deviceId]
  // if not operation in progress
  if (deviceSyncStatus?.operationName !== deviceSyncOperationNames.STARTED) {
    return ''
  }
  return deviceSyncStatus.timestamp
}

const isDeviceSyncRequestRunning = (processIndicators, deviceId) =>
  !!processIndicators[getDeviceSyncStartedProcessName(deviceId)]?.isRunning

// even if this uses simple createSelector + property, this is fine. Most of the times we see button with one deviceId
// meaning cache miss will occur only when we change viewed device = that is actually desired behavior
export const writeSettingsButtonSelector = createSelector(
  [
    getProjectDevices,
    getDeviceSyncStatuses,
    getProcessIndicators,
    getDevicesOnlineStatuses,
    (state, props) => props.deviceId,
  ],
  (projectDevices, deviceSyncStatuses, processIndicators, devicesOnlineStatuses, deviceId) => {
    const device = projectDevices.find(deviceCandidate => deviceCandidate.id === deviceId)
    return {
      hasDeviceSyncFailed: hasDeviceSyncFailedBySyncStatuses(deviceSyncStatuses, deviceId),
      hasSyncSucceeded: hasSyncSucceededBySyncStatuses(deviceSyncStatuses, deviceId),
      isProcessingRunning: false,
      isDeviceBeingSynced: isDeviceBeingSyncedBySyncStatuses(deviceSyncStatuses, deviceId),
      isDeviceDirty: isDeviceDirtyByProjectDevices(projectDevices, deviceId),
      isDeviceSyncRequestRunning: isDeviceSyncRequestRunning(processIndicators, deviceId),
      deviceLastSyncStartTime: getSyncStartTime(deviceSyncStatuses, deviceId),
      isDeviceOnline: isDeviceOnlineByOnlineStatuses(devicesOnlineStatuses, deviceId),
      isDeviceSupported: true,
      isDeviceValid: device?.isValid(),
      isDeviceKGate: device?.isKGate?.(),
      projectDevice: device,
    }
  },
)
