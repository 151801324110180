import {
  SET_FIRMWARE_DATA,
  SET_FIRMWARE_UPDATE_STATUS,
} from '@/fleet-configuration/data-fleet/firmware/firmware-actions'

export const firmwareReducer = (state = { data: {}, updateStatus: {} }, action = {}) => {
  switch (action.type) {
    case SET_FIRMWARE_DATA: {
      const { deviceId, firmwareData } = action.payload
      return { ...state, data: { ...state.data, [deviceId]: firmwareData } }
    }
    case SET_FIRMWARE_UPDATE_STATUS: {
      const { deviceId, status } = action.payload
      return { ...state, updateStatus: { ...state.updateStatus, [deviceId]: status } }
    }
    default:
      return state
  }
}
