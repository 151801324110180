/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  recentConfigurations: {
    id: 'fleetOverviewExpandedRow.recentConfigurations',
    defaultMessage: 'Recent Configurations',
  },
  upgradeYourLicenseToGetMore: {
    id: 'fleetOverviewExpandedRow.upgradeYourLicenseToGetMore',
    defaultMessage: 'Upgrade your license to get more',
  },
  thereAreNoBackupsInSystemYet: {
    id: 'fleetOverviewExpandedRow.thereAreNoBackupsInSystemYet',
    defaultMessage: 'There are no backups in system yet.',
  },
  clickBackupConfigurationQuickActionAtTheTopToCreateYourFirst: {
    id: 'fleetOverviewExpandedRow.clickBackupConfigurationQuickActionAtTheTopToCreateYourFirst',
    defaultMessage: 'Click {BACKUP_CONFIGURATION} quick action at the top to create your first',
  },
  backupConfiguration: {
    id: 'fleetOverviewExpandedRow.backupConfiguration',
    defaultMessage: 'Backup Configuration',
  },
  theConfigurationNotSupported: {
    id: 'fleetOverviewExpandedRow.theConfigurationBackupRestoreIsNotSupportedAtThisTypeOfDevice',
    defaultMessage: 'The Configuration Backup/Restore is not supported at this type of device',
  },
  module: {
    id: 'fleetOverviewExpandedRow.module',
    defaultMessage: 'Module',
  },
  documentation: {
    id: 'fleetOverviewExpandedRow.documentation',
    defaultMessage: 'Documentation',
  },
  link: {
    id: 'fleetOverviewExpandedRow.link',
    defaultMessage: 'Link',
  },
  controller: {
    id: 'fleetOverviewExpandedRow.controller',
    defaultMessage: 'Controller',
  },
  firmware: {
    id: 'fleetOverviewExpandedRow.firmware',
    defaultMessage: 'Firmware',
  },
  upToDate: {
    id: 'fleetOverviewExpandedRow.upToDate',
    defaultMessage: 'Up to date',
  },
  updateAvailable: {
    id: 'fleetOverviewExpandedRow.updateAvailable',
    defaultMessage: 'Update available',
  },
  noInformationForUpdate: {
    id: 'fleetOverviewExpandedRow.noInformationForUpdate',
    defaultMessage: 'No information for update',
  },
  newVersionFwVersion: {
    id: 'fleetOverviewExpandedRow.newVersionFwVersion',
    defaultMessage: 'New version: {fwVersion}',
  },
  firmwareUpdate: {
    id: 'fleetOverviewExpandedRow.firmwareUpdate',
    defaultMessage: 'Firmware update',
  },
  close: {
    id: 'fleetOverviewExpandedRow.close',
    defaultMessage: 'Close',
  },
  updating: {
    id: 'fleetOverviewExpandedRow.updating',
    defaultMessage: 'Updating...',
  },
  lastUpdateSucceed: {
    id: 'fleetOverviewExpandedRow.lastUpdateSucceed',
    defaultMessage: 'Last update succeed',
  },
  lastUpdateFailed: {
    id: 'fleetOverviewExpandedRow.lastUpdateFailed',
    defaultMessage: 'Last update failed',
  },
  reasonReason: {
    id: 'fleetOverviewExpandedRow.reasonReason',
    defaultMessage: 'Reason: {reason}',
  },
  lastFirmwareUpdateOnDateTimeFinishedSuccessfully: {
    id: 'firmwareUtils.lastFirmwareUpdateOnDateTimeFinishedSuccessfully',
    defaultMessage: 'Last firmware update on {dateTime} finished successfully',
  },
  lastFirmwareUpdateOnDateTimeFailedTheReasonReason: {
    id: 'fleetOverviewExpandedRow.lastFirmwareUpdateOnDateTimeFailedTheReasonReason',
    defaultMessage: 'Last firmware update on "{dateTime}" failed.{newLine}The reason: {reason}',
  },
  calibration: {
    id: 'fleetOverviewExpandedRow.calibration',
    defaultMessage: 'Calibration',
  },
  calibrationExplanationHint: {
    id: 'fleetOverviewExpandedRow.calibrationExplanationHint',
    defaultMessage:
      'Why is calibration so important?{newLine}{newLine}Calibration verifies the precision and reproducibility of measurement instruments, such as sensors and measuring systems. {newLine}The Instruments that are calibrated are the prerequisite for precise, reliable and reproducible measurement results.{newLine}{newLine}For details, click on the icon and read more.',
  },
  missingPermissionsForSWUpdate: {
    id: 'fleetOverviewExpandedRow.missingPermissionsForSWUpdate',
    defaultMessage:
      'You have no permission to perform update of the device.{newLine}Please contact your administrator.',
  },
  runningMeasurementWillBeStoppedDuringFirmwareUpdate: {
    id: 'fleetOverviewExpandedRow.currentlyRunningMeasurementWillBeStoppedDuringFirmwareUpdate',
    defaultMessage: 'Currently running measurement will be stopped during firmware update.',
  },
})
