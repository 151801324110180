/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  deviceDeviceWentOffline: {
    id: 'Device "{device}" went offline',
    defaultMessage: 'Device "{device}" went offline',
  },
  deviceDeviceWentOnline: {
    id: 'Device "{device}" is online',
    defaultMessage: 'Device "{device}" is online',
  },
})
