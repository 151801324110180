import { deviceEventStatusList, deviceEventStatusToOnlineMap } from './device-online-status-constants'
import { getDeviceOnlineStatus } from './device-online-status-selector'

const { UNKNOWN, OFFLINE, ONLINE } = deviceEventStatusList

export const addOnlineStatusToDeviceModel = (eventOnlineStatus, device) => {
  const formattedStatus = (eventOnlineStatus || '').toLowerCase()
  const isOnline = deviceEventStatusToOnlineMap[formattedStatus] || false
  return { ...device, online: isOnline, status: formattedStatus }
}

export const hasDeviceGoneFromOfflineToOnline = (state, deviceId, nextStatus) => {
  const prevStatus = getDeviceOnlineStatus(state, deviceId)
  return [OFFLINE, UNKNOWN].includes(prevStatus) && nextStatus === ONLINE
}

export const hasDeviceGoneFromOnlineToOffline = (state, deviceId, nextStatus) => {
  const prevStatus = getDeviceOnlineStatus(state, deviceId)
  return prevStatus === ONLINE && nextStatus === OFFLINE
}
