import { useState, useEffect } from 'react'

export const useLocalStorageObserve = key => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return null
      return item ? JSON.parse(item) : null
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      return null
    }
  })

  useEffect(() => {
    const checkData = () => {
      try {
        // Get from local storage by key
        const item = window.localStorage.getItem(key)

        if (item) {
          setStoredValue(JSON.parse(item))
        }
      } catch (error) {
        console.log(error)
      }
    }

    window.addEventListener('storage', checkData)

    return () => {
      window.removeEventListener('storage', checkData)
    }
  }, []) // eslint-disable-line

  return storedValue
}
