import { formatData } from 'skybase-ui/skybase-components/sb-data-table/utils'

export const removeFirstCharIfExists = (text, char) => (text?.[0] === char ? text.slice(1) : text)

export const isJsonString = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const isValidGuid = guid =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(guid)

/**
 * Calls the same formatData method as the SbDataTable
 * in order to get the sorted data, in which we can filter
 * by the provided indexSelector function.
 * @param {array} data - Array of data.
 * @param {object} sortShape - Shape of the sorting {sortCol: 'id', sortOrder: 'asc'}.
 * @param {function} indexSelector - Function for finding the item we want to know the selected page number for.
 * @param {integer} pageSize - Current page size.
 * @param {function} groupByIteratee
 * @param {array} requiredGroups
 */
export const getSelectedItemPageNumber = ({
  columns,
  data,
  sortShape,
  indexSelector,
  pageSize,
  groupByIteratee = null,
  requiredGroups = [],
}) => {
  const formattedData = formatData({
    columns,
    data,
    groupByIteratee,
    sortByShape: sortShape,
    requiredGroups,
  })

  const selectedIndex = formattedData.findIndex(indexSelector)

  if (selectedIndex !== -1) {
    return Math.ceil((selectedIndex + 1) / pageSize)
  }

  return 1
}

// Convert an object to query used in fetch URL
export const objectToQuery = obj =>
  Object.keys(obj)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&')

// Can be used in combination with await: await sleep(1000)
export const sleep = ms => {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

// Makes the first letter of a given string uppercase
export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

let encoder
export const byteLengthOfStringEncodedInUtf8 = string => {
  // Support for IE. This is not perfect, but it covers at least something.
  if (!TextEncoder) {
    const m = encodeURIComponent(string).match(/%[89ABab]/g)
    return string.length + (m ? m.length : 0)
  }
  if (!encoder) {
    encoder = new TextEncoder()
  }
  return encoder.encode(string).length
}

export const areSkydaqAPISDisabled = () =>
  ['true', '1'].includes(process.env.REACT_APP_DISABLE_SKYDAQ_APIS?.toLowerCase())
