import { REQUIRED, MATCH_PATTERN } from 'skybase-oauth/validator'

import { messages as t } from './licenses-i18n'

const assignTicketValidationRules = _ => [
  {
    attributes: ['ticketId'],
    condition: REQUIRED,
    message: () => _(t.ticketidCannotBeEmpty),
  },
  {
    attributes: ['ticketId'],
    condition: MATCH_PATTERN,
    pattern: /^([A-Za-z0-9]{5}-){4}[A-Za-z0-9]{5}$/,
    message: () => _(t.ticketIdIncorrectPattern),
  },
]

export const getAssignTicketValidationRules = _ => assignTicketValidationRules(_)
