import {
  getCalibrationFormData,
  getCalibrationFormDataErrors,
  getCalibrationUserData,
} from '@/fleet-configuration/data-fleet/calibration/calibration-selectors'
import { getProjectDeviceById } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'

export const calibrationBoxSelector = (state, props) => ({
  userData: getCalibrationUserData(state),
  formData: getCalibrationFormData(state),
  formDataErrors: getCalibrationFormDataErrors(state),
  device: props.deviceId ? getProjectDeviceById(state, props.deviceId) : null,
})
