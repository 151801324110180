import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  onboardingFailedScanningForAvailableDevices: {
    id: 'Onboarding failed - scanning for available devices. Please try to onboard again later.',
    defaultMessage: 'Onboarding failed - scanning for available devices. Please try to onboard again later.',
  },
  offboardingDeviceFailed: {
    id: 'Offboarding device failed',
    defaultMessage: 'Offboarding device failed',
  },
  factoryResetOfDeviceFailed: {
    id: 'Factory reset of device failed',
    defaultMessage: 'Factory reset of device failed',
  },
  unableToTriggerFactoryResetOnTheDevice: {
    id: 'KiStudioLab was unable to trigger factory reset on the device. Please try to factory reset device by some other means (e.g. hardware button)!',
    defaultMessage:
      'KiStudioLab was unable to trigger factory reset on the device. Please try to factory reset device by some other means (e.g. hardware button)!',
  },
  onboardingFailed: {
    id: 'Onboarding failed',
    defaultMessage: 'Onboarding failed',
  },
  deviceRebootFailed: {
    id: 'Device reboot failed',
    defaultMessage: 'Device reboot failed',
  },
  unableToTriggerRebootOnTheDevice: {
    id: 'Unable to trigger reboot on the device',
    defaultMessage: 'Unable to trigger reboot on the device',
  },
  identificationOfDeviceFailed: {
    id: 'devicesActions.identificationOfDeviceFailed',
    defaultMessage: 'Identification of device failed',
  },
})
