import { replaceDuplicateSlashes } from '@/utils'

import { RESOURCE_WS_KEY } from './constants'

export const removeDeviceIdFromResourcePath = (resourcePath, deviceId) =>
  replaceDuplicateSlashes(resourcePath.replace(deviceId, ''))

// Get the pure JSON data from resource data, removing the unnecessary attributes.
export const getResourceJSON = resourceData => {
  const { if: interfaces, rt, ...json } = resourceData

  return json
}

export const getResourceWSID = (deviceId, resourcePath) => `${RESOURCE_WS_KEY}-${deviceId}-${resourcePath}`
