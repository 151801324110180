export const trialServiceApiEndpoints = {
  CREATE: '/api/v1/trial/simulator/create',
  ONBOARD: '/api/v1/trial/simulator/onboard',
  GET: '/api/v1/trial/simulator',
}

export const trialServiceStates = {
  NONE: 'none',
  CREATING: 'creating',
  CREATED: 'created',
  ONBOARDING: 'onboarding',
  ONBOARDED: 'onboarded',
  DELETING: 'deleting',
  ERROR: 'error',
}

export const DEVELOPER_TOOLS_LS_KEY = 'DHUBDeveloperToolsEnabledList'
