import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { SbTextbox } from 'skybase-ui/skybase-components/sb-textbox'
import { addComponentModalPossibleComponents } from '@/fleet-configuration/page-components/wizard/add-component-modal/add-component-modal-constants'
import { tryLoadCatalogItemsByFamily } from '@/fleet-configuration/data-fleet/catalog/catalog-actions'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { SbDataTable } from 'skybase-ui/skybase-components/sb-data-table'
import { menuPositions } from 'skybase-ui/skybase-components/sb-dropdown/constants'
import { toCssString } from '@/utils/sanitizer'
import { messages as t } from './add-component-modal-catalog-table-i18n'

export const AddComponentModalCatalogTable = ({ componentToAdd, onSelectionChange = null }) => {
  const [selectedRow, setSelectedRow] = useState(null)
  const [isLoadingDependencies, setIsLoadingDependencies] = useState(true)
  const [catalogItems, setCatalogItems] = useState([])
  const [filter, setFilter] = useState({})
  const [pagination, setPagination] = useState({})

  const { formatMessage: _ } = useIntl()
  const dispatch = useDispatch()

  useEffect(() => {
    setIsLoadingDependencies(true)
    Promise.resolve(dispatch(tryLoadCatalogItemsByFamily(componentToAdd))).then(items => {
      setCatalogItems(items)
      setIsLoadingDependencies(false)
    })
  }, [componentToAdd, dispatch])

  const cellFormatter = useCallback(
    (value, key, row) => {
      if (key === 'name') {
        return (
          <>
            <span
              className={classnames(
                'm-r-10',
                componentToAdd === addComponentModalPossibleComponents.SENSOR ? 'sbi-sensor' : 'sbi-cable-kids',
              )}
            />
            {value}
          </>
        )
      }
      if (key === 'kistlerType') {
        return row.parameters?.deviceDocUrl ? (
          <a href={row.parameters.deviceDocUrl} target="_blank" rel="noreferrer">
            {value}
          </a>
        ) : (
          value
        )
      }
      if (key === '_actions') {
        return (
          <div className="actions-wrapper">
            <span
              className={classnames(
                'row-selection',
                selectedRow?.id === row.id ? 'sbi-minus-circle' : 'sbi-plus-circle',
              )}
              onClick={() => {
                if (onSelectionChange) {
                  if (selectedRow?.id === row.id) {
                    onSelectionChange(null)
                    setSelectedRow(null)
                  } else {
                    onSelectionChange(row, setSelectedRow)
                  }
                }
              }}
            />
          </div>
        )
      }
      return value
    },
    [selectedRow, componentToAdd, onSelectionChange],
  )

  const filterCellFormatter = useCallback(
    columnName => {
      if (columnName === '_actions') {
        return null
      }
      return (
        <SbTextbox
          value={filter[columnName] || ''}
          onChange={evt => setFilter({ ...filter, [columnName]: evt.target.value })}
        />
      )
    },
    [filter],
  )

  const rowFormatter = useCallback(
    row => {
      return {
        className: classnames(row.id === selectedRow?.id ? 'selected' : '', toCssString(`row-${row.id}`)),
      }
    },
    [selectedRow],
  )

  const handleOnPaginationChange = useCallback(newPagination => setPagination(newPagination), [])

  const columns = useMemo(
    () => [
      { name: 'name', label: _(t.typeName) },
      { name: 'kistlerType', label: _(t.typeNumber) },
      { name: '_actions', label: ' ', sortable: false },
    ],
    [_],
  )

  const itemsToDisplay = useMemo(() => {
    let resultItems = []
    if (!isLoadingDependencies) {
      // now apply the actual filter
      const filterEntries = Object.entries(filter).map(([key, value]) => [key, value.toLowerCase()])
      resultItems = catalogItems.filter(item =>
        filterEntries.every(([filterKey, filterValue]) => item[filterKey].toLowerCase().includes(filterValue)),
      )
    }
    return resultItems
  }, [isLoadingDependencies, catalogItems, filter])

  return (
    <div className="add-component-content-wrapper" data-testid="catalog-table-wrapper">
      {isLoadingDependencies ? (
        <SbLoader show />
      ) : (
        <div data-testid="displays-catalog-table">
          <SbDataTable
            className="catalog-table"
            columns={columns}
            data={itemsToDisplay}
            cellFormatter={cellFormatter}
            rowFormatter={rowFormatter}
            enableFilterRow
            filterCellFormatter={filterCellFormatter}
            enablePagination
            paginationProps={{
              pageSizeDropdownProps: {
                className: 'min-width-100px',
                menuPosition: menuPositions.AUTO_TOP,
              },
              ...pagination,
              onChange: handleOnPaginationChange,
            }}
            asyncData={false}
            defaultSortBy={{
              sortCol: 'name',
              sortOrder: 'asc',
            }}
          />
        </div>
      )}
    </div>
  )
}

AddComponentModalCatalogTable.propTypes = {
  componentToAdd: PropTypes.oneOf(Object.values(addComponentModalPossibleComponents)).isRequired,
  onSelectionChange: PropTypes.func,
}
