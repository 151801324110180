/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  close: {
    id: 'wizardButtons.close',
    defaultMessage: 'Close',
  },
  back: {
    id: 'wizardButtons.back',
    defaultMessage: 'Back',
  },
  continue: {
    id: 'wizardButtons.continue',
    defaultMessage: 'Continue',
  },
})
