/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  typeName: {
    id: 'addComponentModalCatalogTable.typeName',
    defaultMessage: 'Type name',
  },
  typeNumber: {
    id: 'addComponentModalCatalogTable.typeNumber',
    defaultMessage: 'Type number',
  },
})
