import { throttle } from 'lodash'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter'
import { loadDevices } from '@/fleet-configuration/data-fleet/devices/devices-actions'
import { areDevicesLoaded, getDeviceById } from '@/fleet-configuration/data-fleet/devices/devices-selectors'
import { DEVICE_REGISTER_LISTENER_NAME, deviceStatuses } from '@/iot-hub/components'

const { REGISTERED, UNREGISTERED } = deviceStatuses
export const initDeviceRegisterStatusListener = (eventListener, dispatch, getState) => {
  const throttledUpdateStatus = throttle(
    () => {
      const state = getState()
      const devicesLoaded = areDevicesLoaded(state)
      // refresh devices list - this event means there is one more or one less item in list
      if (devicesLoaded) {
        dispatch(loadDevices())
      }
    },
    300,
    { leading: false, trailing: true },
  )

  SbEmitter.on(DEVICE_REGISTER_LISTENER_NAME, ({ status, deviceId }) => {
    // ignore reloading if it does not change anything
    let doReload = false
    const state = getState()
    const devicesLoaded = areDevicesLoaded(state)
    if (devicesLoaded) {
      const device = getDeviceById(state, deviceId)
      if ((status === REGISTERED && !device) || (status === UNREGISTERED && device)) {
        doReload = true
      }
    }
    if (doReload) {
      throttledUpdateStatus()
    }
  })
}
