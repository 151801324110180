import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { createSelector } from 'reselect'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorToast } from '@/common/services/show-toast'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'
import { SbFullLayout } from 'skybase-ui/skybase-components/layouts/sb-full-layout'

import { fleetInit } from '@/fleet-configuration/fleet-init'
import { useEquipment, useComponents, Equipment, CreateEquipmentButton, equipmentEmitterEvents } from '../../equipment'
import { loadMissingCalibrations } from '@/fleet-configuration/data-fleet/calibration/calibration-actions'
import { getCalibrationItems } from '@/fleet-configuration/data-fleet/calibration/calibration-selectors'
import { messages as t } from './equipment-inventory-i18n'
import './equipment-inventory.scss'

const { REFETCH_EQUIPMENT_LIST, ADD_EQUIPMENT_BY_ID } = equipmentEmitterEvents

export const EquipmentInventory = () => {
  const { formatMessage: _ } = useIntl()
  const dispatch = useDispatch()
  const { data, error, loading, refetch, addEquipmentById } = useEquipment()
  const { data: components, fetchComponents } = useComponents()

  const { calibrations } = useSelector(
    createSelector([state => getCalibrationItems(state)], rawCalibrationItems => ({
      calibrations: Object.values(rawCalibrationItems).map(item => item.calibrationItem),
    })),
  )

  const [expandedRow, setExpandedRow] = useState(null)

  useEffect(() => {
    dispatch(fleetInit(_))
  }, [_, dispatch])

  useEffect(() => {
    const cleanupRefetchEmitter = SbEmitter.on(REFETCH_EQUIPMENT_LIST, () => {
      refetch()
    })

    const cleanupAddEquipmentEmitter = SbEmitter.on(ADD_EQUIPMENT_BY_ID, id => {
      addEquipmentById(id)
    })

    return () => {
      cleanupRefetchEmitter()
      cleanupAddEquipmentEmitter()
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (error) {
      showErrorToast(
        _(t.failedToRetrieveYourEquipmentInventory, { errorMessage: error?.errors?.message ?? 'unknown.' }),
        _(t.error),
      )
    }
  }, [error, _])

  useEffect(() => {
    fetchComponents(data)
    // sort the data according to the equipment component's data table.
    //  This way we are going to first fetch those items, that are visible/are on current page/are on top of the table
    dispatch(loadMissingCalibrations(data.sort((a, b) => a.family?.localeCompare?.(b.family))))
  }, [data]) // eslint-disable-line

  return (
    <SbFullLayout
      title={_(t.myEquipment)}
      breadcrumbs={[
        {
          path: '/',
          title: _(t.home),
        },
        {
          path: '/fleet',
          title: _(t.fleetOverview),
        },
        _(t.myEquipment),
      ]}
    >
      <div className="m-r-40">
        <div className="sb-heading fl-row fl-align-items-center">
          <h1>{_(t.myEquipment)}</h1>
          <CreateEquipmentButton />
        </div>

        <Equipment
          data={data}
          loading={loading}
          refetch={refetch}
          components={components}
          calibrations={calibrations}
          expandedRow={expandedRow}
          onExpand={setExpandedRow}
        />
      </div>
    </SbFullLayout>
  )
}
