import { areEntriesLoaded, getEntries, getEntryById } from '@/fleet-configuration/data-fleet/entries/entries-selectors'
import { createSelector } from 'reselect'

export const areSnippetsLoaded = state => areEntriesLoaded(state, 'entries.snippets')

export const getSnippets = state => getEntries(state, 'entries.snippets')

export const getSnippetDetails = state => getEntries(state, 'entries.snippetDetail')

const snippetDetailById = state => state.entries.snippetDetail.byId

export const getFullSnippets = createSelector(getSnippets, snippetDetailById, (snippets, snippetDetailsById) => {
  return snippets.map(snippet => snippetDetailsById[snippet.id] || snippet)
})

export const getSnippetById = (state, snippetId) => getEntryById(state, 'entries.snippetDetail', snippetId)

export const isSnippetDetailLoadedById = (state, snippetId) => !!state.entries.snippetDetail.loadedDetail[snippetId]

export const getSnippetFilter = state => state.entries.snippetDetail.filter
