/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  calibrationCertificateIsNotAvailable: {
    id: 'certificateIcon.calibrationCertificateIsNotAvailable',
    defaultMessage: 'Calibration certificate is not available',
  },
  calibrationCertificate: {
    id: 'certificateIcon.calibrationCertificate',
    defaultMessage: 'Calibration certificate',
  },
  pleaseTryAgainLater: {
    id: 'certificateIcon.pleaseTryAgainLater',
    defaultMessage: 'Please try again later',
  },
  calibrationCertificateNoAccess: {
    id: 'certificateIcon.calibrationCertificateNoAccess',
    defaultMessage: 'No access to calibration certificate',
  },
})
