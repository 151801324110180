import { STATE_KEY_NAME } from './constants'

/**
 * @description
 * Returns the appropriate part of the state.
 *
 * @param state            - State where to select the local part.
 * @param calledFromReducer {boolean}       - Reducer provides already selected local state.
 *
 * @returns {Object}
 */
// @todo Make this method common and it's statements a bit shorter.
const getState = (state, calledFromReducer) => {
  return calledFromReducer ? state : state[STATE_KEY_NAME]
}

/**
 * @description
 * Returns ID of currently opened modal.
 *
 * @param state
 * @param calledFromReducer
 *
 * @returns {String|null}
 */
export const getCurrentModalId = (state, calledFromReducer = false) => {
  const { currentModalId } = getState(state, calledFromReducer)
  return currentModalId
}

/**
 * @description
 * Returns data that was dispatched to this dialog using `@@skybase/MODAL_OPEN` action.
 *
 * @param modalId
 * @param state
 * @param calledFromReducer
 *
 * @returns {Object}
 */
export const getModalData = (modalId, state, calledFromReducer = false) => {
  const { modals } = getState(state, calledFromReducer)
  const data = modals && modals[modalId]

  // eslint-disable-next-line no-nested-ternary
  return data ? (data.toObject ? data.toObject() : data) : {}
}
