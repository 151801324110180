import axios from 'axios'
import { v4 } from 'uuid'
import { batchActions } from 'redux-batched-actions'
import { getStudioAPIHost } from '@/utils/url'
import { sha256 } from '@/utils/sha256'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'
import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { showErrorToast, showSuccessToast } from '@/common/services/show-toast'
import { getProjectDeviceById } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import { getSnippetById, isSnippetDetailLoadedById } from '@/fleet-configuration/data-fleet/snippets/snippets-selectors'
import {
  loadProjectDeviceIfEmpty,
  saveProjectDevice,
} from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { loadProjectsIfEmpty } from '@/fleet-configuration/data-fleet/projects/projects-actions'
import { guardedDeviceFactory } from '@/fleet-configuration/data-fleet/project-devices/device-factory'
import { loadDevicesCatalog } from '@/fleet-configuration/data-fleet/catalog/catalog-actions'
import { SNIPPET_SALT } from './snippets-constants'
import { messages as t } from './snippets-actions-i18n'

export const REMOVE_GENERIC_SNIPPETS = getActionName('REMOVE_GENERIC_SNIPPETS')
export const removeGenericSnippets = () => createAction(REMOVE_GENERIC_SNIPPETS)

export const REMOVE_SNIPPET_ENTRY = getActionName('REMOVE_SNIPPET_ENTRY')
export const removeSnippetEntry = snippetId => createAction(REMOVE_SNIPPET_ENTRY, { id: snippetId })

export const SET_SNIPPET = getActionName('SET_SNIPPET')
export const setSnippet = snippet => createAction(SET_SNIPPET, snippet)

export const SET_SNIPPET_DETAIL = getActionName('SET_SNIPPET_DETAIL')
export const setSnippetDetail = snippet => createAction(SET_SNIPPET_DETAIL, snippet)

export const SNIPPETS_LOADED = getActionName('SNIPPETS_LOADED')
export const snippetsLoaded = () => createAction(SNIPPETS_LOADED)

export const SNIPPET_DETAIL_LOADED = getActionName('SNIPPET_DETAIL_LOADED')
export const setSnippetDetailLoaded = snippetId => createAction(SNIPPET_DETAIL_LOADED, { id: snippetId })

export const SET_SNIPPET_FILTER = getActionName('SET_SNIPPET_FILTER')
export const setSnippetFilter = snippetFilter => createAction(SET_SNIPPET_FILTER, { snippetFilter })

export const createSnippetWithData = snippetData => async (dispatch, getState) => {
  if (!snippetData.id) {
    snippetData.id = v4()
  }
  await axios.post(getStudioAPIHost() + '/api/devices/configSnippets', snippetData)

  // recreate "originalDeviceConfiguration" so as to not share same reference as "device" even if it's originally "same" object
  const originalDeviceConfiguration = guardedDeviceFactory(dispatch, getState, snippetData.originalDeviceConfiguration)
  const data = { ...snippetData, originalDeviceConfiguration }
  dispatch(batchActions([setSnippet(data), setSnippetDetail(data), setSnippetDetailLoaded(data.id)]))
  return data
}

export const createSnippet = (deviceId, _) => async (dispatch, getState) => {
  const state = getState()
  const device = getProjectDeviceById(state, deviceId)
  if (!device) {
    throw new Error('Device for requested snippet could not be found')
  }

  const defaultSnippetSet = [...device.getWritableProperties()]
  if (device.deviceSpecific?.controller) {
    defaultSnippetSet.push(...device.deviceSpecific.controller.getWritableProperties())
  }
  device.modules.forEach(module => {
    defaultSnippetSet.push(...module.getWritableProperties())
    module.channels.forEach(channel => {
      defaultSnippetSet.push(...channel.getWritableProperties())
    })
  })

  const snippetId = v4()
  const snippetData = {
    id: snippetId,
    name: _(t.presetDatetime, { DATETIME: new Date().toLocaleString() }),
    snippetContent: {
      set: defaultSnippetSet,
      delete: [],
    },
    originalDeviceConfiguration: device.toDto(),
  }

  await createSnippetWithData(snippetData)(dispatch, getState)
  showSuccessToast(_(t.newPresetCreated))
  SbEmitter.emit('navigate', `/configuration/preset/detail/${snippetId}/new`)
}

export const loadSnippets = () => async dispatch => {
  const { data } = await axios.get(getStudioAPIHost() + '/api/devices/configSnippets')
  dispatch(batchActions([removeGenericSnippets(), ...data.map(snippet => setSnippet(snippet)), snippetsLoaded()]))
}

export const loadSnippetDetailById = snippetId => async (dispatch, getState) => {
  const { data } = await axios.get(`${getStudioAPIHost()}/api/devices/configSnippets/${snippetId}`)
  if (data.originalDeviceConfiguration) {
    await dispatch(loadDevicesCatalog([data.originalDeviceConfiguration]))
    data.originalDeviceConfiguration = guardedDeviceFactory(dispatch, getState, data.originalDeviceConfiguration)
  }
  dispatch(batchActions([setSnippetDetail(data), setSnippetDetailLoaded(snippetId)]))
  return data
}

export const loadSnippetDetailByIdIfEmpty = snippetId => async (dispatch, getState) => {
  const state = getState()
  return isSnippetDetailLoadedById(state, snippetId)
    ? getSnippetById(state, snippetId)
    : dispatch(loadSnippetDetailById(snippetId))
}

export const applySnippet = (snippetId, deviceId) => async (dispatch, getState) => {
  try {
    await dispatch(loadProjectsIfEmpty())
    await Promise.all([dispatch(loadSnippetDetailByIdIfEmpty(snippetId)), dispatch(loadProjectDeviceIfEmpty(deviceId))])
    const snippet = getSnippetById(getState(), snippetId)
    const device = getProjectDeviceById(getState(), deviceId)
    if (!snippet) {
      throw new Error("Can't apply snippet that does not exist")
    }
    if (!device) {
      throw new Error("Can't apply snippet on device that does not exist")
    }
    const { data } = await axios.post(getStudioAPIHost() + '/api/devices/configSnippets/apply', {
      configSnippet: {
        ...snippet,
        originalDeviceConfiguration:
          snippet.originalDeviceConfiguration.toDto?.() || snippet.originalDeviceConfiguration,
      },
      targetDeviceConfiguration: device.toDto(),
    })
    return dispatch(saveProjectDevice(data))
  } catch (e) {
    showErrorToast(t.couldNotApplyPresetOnTheDevice)
    throw e
  }
}

let updateSnippetTicket = 0
let abortController
// mark when update snippet finishes so that we can postpone some actions only after write is done
//  e.g. export snippet benefits from this (when clicking export immediately after editing snippet)
let updateFinishedPromise
export const updateSnippet = snippet => async (dispatch, getState) => {
  const oldState = getState()
  // now do optimistic update
  if (!snippet.originalDeviceConfiguration.toDto) {
    snippet.originalDeviceConfiguration = guardedDeviceFactory(dispatch, getState, snippet.originalDeviceConfiguration)
  }
  dispatch(setSnippetDetail(snippet))

  // prepare to cancel request if next one comes before last (= this) one finishes
  abortController?.abort()
  abortController = new AbortController()

  let canceled = false
  let updatePromiseResolve
  updateFinishedPromise = new Promise(resolve => {
    updatePromiseResolve = resolve
  })
  updateSnippetTicket += 1
  const ticket = updateSnippetTicket
  const { data } = await axios
    .put(
      `${getStudioAPIHost()}/api/devices/configSnippets/${snippet.id}`,
      {
        ...snippet,
        originalDeviceConfiguration:
          snippet.originalDeviceConfiguration.toDto?.() || snippet.originalDeviceConfiguration,
      },
      {
        signal: abortController.signal,
      },
    )
    .catch(err => {
      if (axios.isCancel(err)) {
        // there will be new content, no need to handle anything
        canceled = true
        return {}
      }

      // reverse optimistic update
      dispatch(setSnippetDetail(getSnippetById(oldState, snippet.id)))
      throw err
    })

  abortController = null
  if (canceled) {
    updatePromiseResolve(true)
    return false
  }
  // PUT and it's result don't need to be exactly the same - reason being lastChanged field.
  //  To resolve this issue - write back what you got from server (but only from last request)
  if (data && updateSnippetTicket === ticket) {
    if (data.originalDeviceConfiguration) {
      data.originalDeviceConfiguration = guardedDeviceFactory(dispatch, getState, data.originalDeviceConfiguration)
    }
    dispatch(setSnippetDetail(data))
  }
  updatePromiseResolve(true)
  return true
}

export const deletePreset = snippetId => async dispatch => {
  await axios.delete(`${getStudioAPIHost()}/api/devices/configSnippets/${snippetId}`)
  dispatch(removeSnippetEntry(snippetId))
  return true
}

const downloadData = (stringToDownload, fileName) => {
  const blobContent = new Blob([stringToDownload], { type: 'application/json' })
  const urlObject = URL.createObjectURL(blobContent)
  const link = document.createElement('a')
  link.download = fileName
  link.href = urlObject
  link.setAttribute('style', 'visibility:hidden; position:absolute')
  document.body.appendChild(link)
  link.click()
  setTimeout(() => {
    document.body.removeChild(link)
    URL.revokeObjectURL(urlObject)
  }, 100)
}

export const exportPreset = snippetId => async (dispatch, getState) => {
  // wait for snippet write if user was editing something at this exact time
  await updateFinishedPromise
  // await after await is OK - because they can't be both promises at the same time
  await dispatch(loadSnippetDetailByIdIfEmpty(snippetId))

  const rawSnippet = getSnippetById(getState(), snippetId)
  const snippet = { ...rawSnippet, originalDeviceConfiguration: rawSnippet.originalDeviceConfiguration.toDto() }
  const name = `${snippet.name.replace(/[<>:"/\\|?*]/g, '_')}.json`
  const fingerprint = sha256(SNIPPET_SALT + JSON.stringify(snippet) + SNIPPET_SALT)
  const hashedContent = JSON.stringify({
    snippet,
    fingerprint,
  })
  downloadData(hashedContent, name)
}
