/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  typeName: {
    id: 'addComponentModalEquipmentTable.typeName',
    defaultMessage: 'Type name',
  },
  serialNumber: {
    id: 'addComponentModalEquipmentTable.serialNumber',
    defaultMessage: 'Serial number',
  },
  typeNumber: {
    id: 'addComponentModalEquipmentTable.typeNumber',
    defaultMessage: 'Type number',
  },
})
