import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  resourceLocation: {
    id: 'device-resources.resourceLocation',
    defaultMessage: 'Resource location',
  },
  interfaces: {
    id: 'device-resources.interfaces',
    defaultMessage: 'Interfaces',
  },
  types: {
    id: 'device-resources.types',
    defaultMessage: 'Types',
  },
  noResourcesFoundForThisDevice: {
    id: 'device-resources.noResourcesFoundForThisDevice',
    defaultMessage: 'No resources found for this device.',
  },
  deviceId: {
    id: 'device-resources.deviceId',
    defaultMessage: 'Device ID',
  },
  selectAResource: {
    id: 'device-resources.selectAResource',
    defaultMessage: 'First select a resource to see or edit its details.',
  },
  settings: {
    id: 'device-resources.settings',
    defaultMessage: 'Settings',
  },
  retrieve: {
    id: 'device-resources.retrieve',
    defaultMessage: 'Retrieve',
  },
  update: {
    id: 'device-resources.update',
    defaultMessage: 'Update',
  },
  retrieveOrUpdate: {
    id: 'device-resources.retrieveOrUpdate',
    defaultMessage: 'Retrieve or Update',
  },
  allInterfaces: {
    id: 'device-resources.allInterfaces',
    defaultMessage: 'Device interfaces',
  },
  cannotUpdateResourceWhileDeviceOffline: {
    id: 'device-resources.cannotUpdateResourceWhileDeviceOffline',
    defaultMessage: 'Cannot update resource while device is offline',
  },
  subscribeAndNotify: {
    id: 'device-resources.subscribeAndNotify',
    defaultMessage: 'Subscribe & Notify',
  },
  changeOnResourceOccured: {
    id: 'device-resources.changeOnResourceOccured',
    defaultMessage: 'Change on the device "{deviceName}" resource "{href}" occured.',
  },
  changeOnResourceTitle: {
    id: 'device-resources.changeOnResourceTitle',
    defaultMessage: 'Device Resource Change',
  },
})
