import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'

import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbFullLayout } from 'skybase-ui/skybase-components/layouts/sb-full-layout'

import { Markdown } from '@/common/markdown'
import { messages as menuT } from '@/common/menu/menu-i18n'
import { messages as t } from './about-page-i18n'

class _AboutPage extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
  }

  static defaultProps = {}

  constructor(props) {
    super(props)

    this.state = {
      readme: '',
    }
  }

  componentDidMount() {
    fetch('/docs/licensing/about/README.md')
      .then(file => file.text())
      .then(readme => this.setState({ readme }))
  }

  urlTransform = uri => {
    return `/docs/licensing/about/${uri}`
  }

  render() {
    const { readme } = this.state
    const {
      intl: { formatMessage: _ },
    } = this.props

    return (
      <SbFullLayout
        title={_(t.pageName)}
        breadcrumbs={[
          {
            path: '/',
            title: _(menuT.home),
          },
          {
            path: '/licensing/management',
            title: _(menuT.licensing),
          },
          _(t.pageName),
        ]}
      >
        <Markdown value={readme} urlTransform={this.urlTransform} internalUriPath="/docs/licensing/about" />
      </SbFullLayout>
    )
  }
}

export const AboutPage = injectIntl(_AboutPage)
