import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isEmpty, uniqBy } from 'lodash'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { SbDataTable } from 'skybase-ui/skybase-components/sb-data-table'
import { SbTextbox } from 'skybase-ui/skybase-components/sb-textbox'
import { families, SHOW_EVERYTHING_FILTER_VALUE } from '@/fleet-configuration/equipment/constants'
import { SbDropdown } from 'skybase-ui/skybase-components/sb-dropdown'
import { batchLoadDevicesCalibrationsIfEmpty } from '@/fleet-configuration/data-fleet/devices-calibrations/devices-calibrations-actions'
import { loadMissingCalibrations } from '@/fleet-configuration/data-fleet/calibration/calibration-actions'
import { getDeviceCalibrations } from '@/fleet-configuration/data-fleet/devices-calibrations/devices-calibrations-selector'
import { getCalibrationItems } from '@/fleet-configuration/data-fleet/calibration/calibration-selectors'
import { loadFirmwareIfEmpty } from '@/fleet-configuration/data-fleet/firmware/firmware-actions'
import { DEVICE_CALIBRATION_PRE_EXPIRATION_WARNING_DAYS } from '@/fleet-configuration/data-fleet/devices-calibrations/devices-calibrations-constants'
import { getAllFirmwareData } from '@/fleet-configuration/data-fleet/firmware/firmware-selectors'
import { firmwareStates } from '@/fleet-configuration/data-fleet/firmware/firmware-constants'
import { getEquipmentIconClassNameByFamily } from '@/fleet-configuration/equipment/utils'
import { getDeviceType } from '@/fleet-configuration/utils/device-utils'
import { devicesTableRenderer } from '@/fleet-configuration/page-components/devices-table-renderer/devices-table-renderer'
import { getDropdownLabelByValue } from '@/utils'
import { messages as t } from './issues-to-pay-attention-table-i18n'
import './issues-to-pay-attention-table.scss'

const { UPDATE_AVAILABLE, UPDATE_FINISHED_UPDATE_AVAILABLE } = firmwareStates
const familyValues = Object.values(families)

const issuesToPayAttentionTableSelector = createSelector(
  [getDeviceCalibrations, getAllFirmwareData, getCalibrationItems],
  (deviceCalibrations, deviceFirmwares, equipmentCalibrations) => ({
    deviceCalibrations,
    deviceFirmwares,
    equipmentCalibrations,
  }),
)

const emptyArray = [] // use this instead of "[]" to do no change (by reference comparisons -> minor perf optimization)
export const IssuesToPayAttentionTable = ({ equipments, devices, testsRequestId = null }) => {
  const [issueItems, setIssueItems] = useState([])
  const [filter, setFilter] = useState([])
  const [isFetchingData, setIsFetchingData] = useState(true)
  const [isFullyInitialized, setIsFullyInitialized] = useState(false)

  let isFetchingDataLocalVar = isFetchingData
  const { formatMessage: _ } = useIntl()
  const dispatch = useDispatch()

  const { deviceCalibrations, deviceFirmwares, equipmentCalibrations } = useSelector(issuesToPayAttentionTableSelector)

  useEffect(() => {
    devicesTableRenderer.initialize(_, null)
  }, [_])
  useEffect(() => {
    dispatch(loadMissingCalibrations(equipments, testsRequestId))
  }, [equipments, dispatch, testsRequestId])
  useEffect(() => {
    dispatch(
      batchLoadDevicesCalibrationsIfEmpty(
        devices.map(device => device.id),
        testsRequestId,
      ),
    )
    devices.forEach(device => !device.isLabAmp() && dispatch(loadFirmwareIfEmpty(device.id, testsRequestId)))
  }, [devices, dispatch, testsRequestId])
  useEffect(() => {
    // eslint-disable-next-line no-restricted-syntax
    for (const calibrationId in equipmentCalibrations) {
      if (equipmentCalibrations[calibrationId].calibrationItem.isFetchingData) {
        setIsFetchingData(true)
        isFetchingDataLocalVar = true
        return
      }
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const deviceCalibration of deviceCalibrations) {
      if (deviceCalibration.isFetchingData) {
        setIsFetchingData(true)
        isFetchingDataLocalVar = true
        return
      }
    }
    setIsFetchingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isFetchingDataLocalVar = false
  }, [deviceCalibrations, equipmentCalibrations])

  const deviceIssues = useMemo(() => {
    if (!deviceCalibrations.length && isEmpty(deviceFirmwares)) {
      return emptyArray
    }
    return devices
      .filter(device => {
        if (
          !device.isLabAmp() &&
          [UPDATE_AVAILABLE, UPDATE_FINISHED_UPDATE_AVAILABLE].includes(deviceFirmwares[device.id]?.state)
        ) {
          return true
        }
        const calibration = deviceCalibrations.find(deviceCalibration => deviceCalibration.id === device.id)
        if (calibration?.calibrationDate) {
          const calibrationExpires = new Date(calibration.calibrationDate)
          calibrationExpires.setDate(calibrationExpires.getDate() + calibration.calibrationIntervalDays)
          const expirationWarningDate = new Date()
          expirationWarningDate.setDate(
            expirationWarningDate.getDate() + DEVICE_CALIBRATION_PRE_EXPIRATION_WARNING_DAYS,
          )
          if (expirationWarningDate.getTime() > calibrationExpires.getTime()) {
            return true
          }
        }
        return false
      })
      .map(device => ({
        typeNumber: device.getKistlerModelNumber(),
        familyDeviceType: getDeviceType(device.types),
        serialNumber: device.serialNumber,
      }))
  }, [devices, deviceCalibrations, deviceFirmwares])

  const equipmentIssues = useMemo(() => {
    if (isEmpty(equipmentCalibrations)) {
      return emptyArray
    }
    return equipments
      .filter(
        ({ typeNumber, serialNumber }) =>
          equipmentCalibrations[JSON.stringify({ typeNumber, serialNumber })]?.calibrationItem?.isExpiring,
      )
      .map(equipment => ({
        familyDeviceType: equipment.family,
        typeNumber: equipment.typeNumber,
        serialNumber: equipment.serialNumber,
      }))
  }, [equipments, equipmentCalibrations])

  useEffect(() => {
    // make sure this effect has been called after initializing to correctly mark when we have all data processed
    if (!isFetchingDataLocalVar) {
      setIsFullyInitialized(true)
    }
    if (!equipmentIssues.length && !deviceIssues.length) {
      setIssueItems(emptyArray)
      return
    }
    const newComponents = uniqBy([...deviceIssues, ...equipmentIssues], item =>
      JSON.stringify([item.typeNumber, item.serialNumber]),
    )
    setIssueItems(newComponents)
  }, [equipmentIssues, deviceIssues, isFetchingDataLocalVar])

  const columns = [
    {
      name: 'familyDeviceType',
      label: _(t.familyDeviceType),
    },
    {
      name: 'serialNumber',
      label: _(t.serialNumber),
    },
    {
      name: 'typeNumber',
      label: _(t.typeNumber),
    },
  ]

  const renderFamilyCell = useCallback(
    value => {
      const iconClassName = getEquipmentIconClassNameByFamily(value)
      if (!iconClassName) {
        return '-'
      }
      return (
        <div className="fl-row fl-align-items-center">
          <span className={classNames(iconClassName, 'family-icon')} />
          {_(t[value] || t.unknown)}
        </div>
      )
    },
    [_],
  )

  const cellFormatter = useCallback(
    (value, key) => {
      if (key === 'familyDeviceType') {
        if (familyValues.includes(value)) {
          return renderFamilyCell(value)
        }
        if (testsRequestId) {
          // do not display SVGs for unit tests - as unit tests can't do that
          return value
        }
        return devicesTableRenderer.renderDeviceType(value)
      }
      return value
    },
    [renderFamilyCell, testsRequestId],
  )

  const filterCellFormatter = useCallback(
    columnName => {
      if (columnName === 'familyDeviceType') {
        const items = [{ value: SHOW_EVERYTHING_FILTER_VALUE, label: _(t.all) }].concat(
          uniqBy(issueItems, columnName).map(item => ({
            value: item[columnName],
            label: _(t[item[columnName]] || t.unknown),
          })),
        )
        return (
          <SbDropdown
            items={items}
            value={filter[columnName] || ''}
            title={getDropdownLabelByValue(items, filter[columnName] || '')}
            onChange={value => setFilter({ ...filter, [columnName]: value })}
          />
        )
      }
      return (
        <SbTextbox
          value={filter[columnName] || ''}
          onChange={evt => setFilter({ ...filter, [columnName]: evt.target.value })}
        />
      )
    },
    [filter, issueItems, _],
  )

  const filteredComponents = useMemo(() => {
    return issueItems.filter(component => {
      return Object.entries(filter).every(
        ([filterColumn, filterValue]) =>
          filterValue === SHOW_EVERYTHING_FILTER_VALUE || component[filterColumn].includes(filterValue),
      )
    })
  }, [issueItems, filter])

  return (
    <div data-testid={isFullyInitialized ? 'issues-finished' : 'issues-loading'}>
      <SbDataTable
        id="issues-to-pay-attention-table"
        enableFilterRow
        loading={!isFullyInitialized}
        filterCellFormatter={filterCellFormatter}
        cellFormatter={cellFormatter}
        columns={columns}
        data={filteredComponents}
      />
    </div>
  )
}

IssuesToPayAttentionTable.propTypes = {
  equipments: PropTypes.array.isRequired,
  devices: PropTypes.array.isRequired,
  testsRequestId: PropTypes.string,
}
