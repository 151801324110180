import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { getOAuthState } from 'skybase-oauth/utils'
import { AUTH_STATE_KEY_NAME } from 'skybase-oauth/oauth/constants'
import { messages as oa } from 'skybase-oauth/messages-i18n'

import { showErrorToast } from '@/common/services/show-toast'
import { useIsMounted } from '@/hooks/use-is-mounted'
import { getLicensesOfUserApi } from '../../rest'
import { formatLicensingErrorMessage } from './utils'
import { messages as t } from './licenses-i18n'

export const useLicensesOfUser = () => {
  const isMounted = useIsMounted()
  const [shouldFetch, setShouldFetch] = useState(true)
  const [licenses, setLicenses] = useState([])
  const { tenant } = useSelector(state => getOAuthState(state)[AUTH_STATE_KEY_NAME])

  useEffect(
    () => {
      const getLicenses = async () => {
        try {
          const licensesOfUser = await getLicensesOfUserApi(tenant)

          if (isMounted.current) {
            setShouldFetch(false)
            setLicenses(licensesOfUser)
          }
        } catch (e) {
          if (isMounted.current) {
            setShouldFetch(false)
          }

          showErrorToast(formatLicensingErrorMessage(e.errorText) || t.licenseFetchError, oa.error)
        }
      }

      if (shouldFetch && isMounted.current) {
        getLicenses()
      }
    },
    [shouldFetch], // eslint-disable-line
  )

  return {
    licenses,
    getLicenses: () => (isMounted.current ? setShouldFetch(true) : null),
    fetching: shouldFetch,
  }
}
