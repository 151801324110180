import { getReadPermission, getWritePermission } from 'skybase-oauth/oauth-permissions'

export const resources = {
  kiconnectDevices: 'kiconnect.devices',
  licenses: 'licenses',
}

export const iotHubPermissions = {
  kiconnectDevicesWrite: getWritePermission(resources.kiconnectDevices),
  kiconnectDevicesRead: getReadPermission(resources.kiconnectDevices),
}

export const licensingPermissions = {
  licensesRead: getReadPermission(resources.licenses),
  licensesAssign: `${resources.licenses}.assign`,
}
