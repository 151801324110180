export const navigationSelectionItemType = {
  FULL_DEVICE: 'fullDevice',
  DEVICE_CONFIG_PROPS: 'deviceConfigurationProperties',
  FULL_MODULE: 'fullModule',
  MODULE_CONFIG_PROPS: 'moduleConfigurationProperties',
  CHANNEL: 'channel',
}

export const urlPathController = 'controller'

export const wizardNavigationSelection = {
  SELECTED: 'true',
  UNSELECTED: 'false',
  INDETERMINATE: 'indeterminate',
}
