import { get } from 'lodash'

export const getDirtyStates = state => state.entries.projectDevicesDirty

export const getDeviceDirtyState = (state, deviceId) => getDirtyStates(state)[deviceId]

export const isDevicePartDirty = (deviceState, pathSegments) => {
  if (pathSegments instanceof Array && !pathSegments.length) {
    return !!deviceState
  }
  return !!get(deviceState, pathSegments)
}

export const isDeviceInUnknownSyncState = (state, deviceId) => {
  const { unknownState, ...dirtyState } = state.entries.projectDevicesDirty[deviceId] || {}
  return !Object.keys(dirtyState).length && unknownState
}

export const isDeviceDirtyByProjectDevices = (projectDevices, deviceId) =>
  !!projectDevices.find(device => device.id === deviceId)?.isDirty()
