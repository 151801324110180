import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'
import { OAuth } from 'skybase-oauth/oauth'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbDynamicTabs, SbTab } from 'skybase-ui/skybase-components/sb-dynamic-tabs'
import { Sb21Layout } from 'skybase-ui/skybase-components/layouts'

import { withRouter } from '@/common/router'
import { getIotHubDevicesState, getIotHubDeviceDetailsState, getIotHubDevicesListState } from '@/iot-hub/selectors'
import { deviceShape } from '@/iot-hub/components/devices/shapes'
import { filterDevice } from '@/iot-hub/components/devices/utils'
import {
  DevicesList,
  DeviceDetails,
  SearchDevices,
  DevicesStatusNotificationToggle,
  changeSearchDeviceValue,
  fetchDevice,
  AddDeviceByIpButton,
  StartDeviceObservationButton,
} from '@/iot-hub/components'
import { DeviceDetailsFooter } from '@/iot-hub/components/devices/device-details-footer'
import { DeviceDeleteButton } from '@/iot-hub/components/devices/device-delete-button'
import { messages as menuT } from '@/common/menu/menu-i18n'
import { messages as t } from './devices-page-i18n'

export class _DevicesPage extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    searchDeviceValue: PropTypes.string.isRequired,
    handleOnChangeSearchDeviceValue: PropTypes.func.isRequired,
    handleFetchDevice: PropTypes.func.isRequired,
    deviceData: deviceShape,
    deviceList: PropTypes.arrayOf(deviceShape),
    router: PropTypes.shape({
      params: PropTypes.shape({
        deviceId: PropTypes.string,
      }).isRequired,
    }).isRequired,
    trialSimulator: PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string,
    }),
  }

  static defaultProps = {
    deviceData: null,
    deviceList: [],
    trialSimulator: null,
  }

  timeout = null

  componentDidMount() {
    const {
      handleFetchDevice,
      router: {
        params: { deviceId },
      },
    } = this.props

    if (deviceId) {
      handleFetchDevice(deviceId)
    }

    // Must delay cause of component mounting order, this page is registered sooner then the component where the listerner it
    this.timeout = setTimeout(() => {
      // Event used in the guided tour
      SbEmitter.emit('page-loaded', 'devices')
    }, 50)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  handleOnSearchChange = searchValue => {
    const { handleOnChangeSearchDeviceValue } = this.props

    handleOnChangeSearchDeviceValue(searchValue)
  }

  render() {
    const {
      intl: { formatMessage: _ },
      searchDeviceValue,
      router: {
        params: { deviceId },
      },
      deviceData,
      deviceList,
      trialSimulator,
    } = this.props

    return (
      <Sb21Layout
        title={_(t.pageName)}
        breadcrumbs={[
          {
            path: '/',
            title: _(menuT.home),
          },
          {
            path: '/iot-hub',
            title: _(menuT.iotHub),
          },
          _(t.pageName),
        ]}
      >
        <div>
          <div className="sb-heading fl-row fl-justify-sb fl-align-items-center devices-heading">
            <div className="fl-row fl-align-items-center">
              <h1>{_(t.pageName)}</h1>
              {!OAuth.isInNormalMode && (
                <>
                  <AddDeviceByIpButton />
                  <StartDeviceObservationButton />
                </>
              )}
            </div>
            <div className="fl-row fl-align-items-center m-l-0">
              <DevicesStatusNotificationToggle className="m-r-20" />
              <SearchDevices onSearchChange={this.handleOnSearchChange} value={searchDeviceValue} />
            </div>
          </div>
          <div className="devices-list-container">
            <DevicesList searchValue={searchDeviceValue} matchDeviceId={deviceId} data={deviceList} />
          </div>
        </div>
        <SbDynamicTabs>
          <SbTab tabId="device-details">
            <SbTab.Title>{_(t.details)}</SbTab.Title>
            <SbTab.Content>
              <DeviceDetails data={deviceData} />
            </SbTab.Content>
            {deviceData?.id &&
              (!OAuth.isInNormalMode ? (
                <DeviceDetailsFooter data={deviceData} list={deviceList} />
              ) : (
                <DeviceDeleteButton data={deviceData} trialSimulator={trialSimulator} />
              ))}
          </SbTab>
        </SbDynamicTabs>
      </Sb21Layout>
    )
  }
}

const mapStateToProps = state => {
  const { searchDeviceValue } = getIotHubDevicesState(state)
  const { data: deviceData } = getIotHubDeviceDetailsState(state)
  const { data: deviceList } = getIotHubDevicesListState(state)

  return {
    searchDeviceValue,
    deviceData,
    deviceList: filterDevice(deviceList, searchDeviceValue),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleOnChangeSearchDeviceValue: value => dispatch(changeSearchDeviceValue(value)),
    handleFetchDevice: deviceId => dispatch(fetchDevice(deviceId)),
  }
}

export const DevicesPage = withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(_DevicesPage)))
