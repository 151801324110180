import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  pageName: {
    id: 'notifications-page.pageName',
    defaultMessage: 'Notifications',
  },
  details: {
    id: 'notifications-page.details',
    defaultMessage: 'Details',
  },
  goToLocation: {
    id: 'notifications-page.jumpToLocation',
    defaultMessage: 'Jump to location',
  },
})
