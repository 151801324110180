import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { withRouter } from '@/common/router'
import { unitIllustrationSelector } from './unit-illustration-selector'
import { messages as t } from './unit-illustration-i18n'
import './unit-illustration.scss'

const getChannelIllustrationIndex = (module, selectedChannel) => {
  const lastChannelInFirstPlugMap = {
    '5167A81': 8, // map channels 0-7 to plug 0
    A104: 4,
    A104_K: 4,
    A109: 4,
    '5167A41': 4,
    D107: 3,
    A111: 2,
    A107: 2,
    A105: 2,
    '5514A': 2,
  }
  if (module && module.type in lastChannelInFirstPlugMap) {
    return Math.ceil((parseInt(selectedChannel, 10) + 1) / lastChannelInFirstPlugMap[module.type]) - 1
  }
  return selectedChannel
}

export const _UnitIllustration = ({
  intl: { formatMessage: _ },
  modules = [],
  selectedChannel = null,
  deviceSnr = '',
  deviceFirmware = '',
  deviceModelNumber = '',
  selectedModuleId = '',
}) => {
  const selectedModule = modules.find(m => m.id === selectedModuleId)

  // prepare how much space is used after each module (we only place one next to the other)
  let sum = 0
  const xStartByModuleIndex = modules.reduce(
    (acc, module) => {
      sum += module.imageWidth
      acc.push(sum)
      return acc
    },
    [0],
  )
  const illustrationWidth = xStartByModuleIndex[modules.length]
  const illustrationHeight = Math.max(0, ...modules.map(({ imageHeight }) => imageHeight))
  let serialNumber
  let fwVersion
  if (selectedModule) {
    serialNumber = selectedModule.snr
    fwVersion = selectedModule.firmware
  } else {
    serialNumber = deviceSnr
    fwVersion = deviceFirmware
  }

  return (
    <>
      <svg
        width={illustrationWidth}
        height={illustrationHeight}
        viewBox={`0 0 ${illustrationWidth} ${illustrationHeight}`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        {modules.map(({ id, imageWidth, imageHeight, Image }, index) => (
          <g key={id} id={id} transform={`translate(${xStartByModuleIndex[index]},0)`}>
            <Image width={imageWidth} height={imageHeight} />
          </g>
        ))}
      </svg>
      <style>
        {selectedModuleId &&
          !selectedChannel &&
          `#${selectedModuleId} .highlight-module { stroke: #4A90E2; stroke-width: 4; fill: rgba(74, 144, 226, 0.1); }`}
        {selectedModuleId &&
          selectedChannel &&
          `#${selectedModuleId} .highlight-channel.channel-${getChannelIllustrationIndex(
            selectedModule,
            selectedChannel,
          )} { stroke: #4A90E2; stroke-width: 1; fill: rgba(74, 144, 226, 0.1); }`}
        {`.config-screen-left-side { flex-basis: ${Math.max(
          600,
          modules.reduce((acc, module) => acc + module.imageWidth, 50),
        )}px }`}
      </style>
      {!selectedChannel && (
        <div className="unit-illustration-information">
          {serialNumber && (
            <span className="serial-number">
              {_(t.serialNumber)}:<span className="test-serial-number"> {serialNumber}</span>
            </span>
          )}
          {fwVersion && (
            <span className="firmware-version">
              {_(t.firmware)}:<span className="test-firmware-version"> {fwVersion}</span>
            </span>
          )}
          {deviceModelNumber && (
            <span className="model-type">
              {_(t.model)}:<span className="test-device-model"> {deviceModelNumber}</span>
            </span>
          )}
        </div>
      )}
    </>
  )
}

_UnitIllustration.propTypes = {
  modules: PropTypes.array,
  selectedChannel: PropTypes.string,
  selectedModuleId: PropTypes.string,
  deviceSnr: PropTypes.string,
  deviceFirmware: PropTypes.string,
  deviceModelNumber: PropTypes.string,
  intl: intlShape.isRequired,
}

export const UnitIllustration = withRouter(injectIntl(connect(unitIllustrationSelector)(_UnitIllustration)))
