import React from 'react'
import PropTypes from 'prop-types'
import { castArray } from 'lodash'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbFullLayout } from 'skybase-ui/skybase-components/layouts/sb-full-layout'
import { SbButton } from 'skybase-ui/skybase-components/sb-button/sb-button'
import { SbTextbox } from 'skybase-ui/skybase-components/sb-textbox/sb-textbox'
import { withRouter } from '@/common/router'
import { configurationSnippetDetailSelector } from '@/fleet-configuration/pages/configuration-snippet-detail/configuration-snippet-detail-selector'
import {
  exportPreset,
  loadSnippetDetailById,
  setSnippetDetail,
  updateSnippet,
} from '@/fleet-configuration/data-fleet/snippets/snippets-actions'
import { openModal } from 'skybase-ui/skybase-core/base/actions'
import { required } from 'skybase-ui/skybase-core/validation/validators'
import { SbInlineMessage } from 'skybase-ui/skybase-components/sb-inline-message/sb-inline-message'
import { SNIPPETS_TABLE_MODAL_ID } from '@/fleet-configuration/page-components/snippets-table-modal/snippets-table-modal-constants'
import {
  urlPathController,
  navigationSelectionItemType,
  wizardNavigationSelection,
} from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation-constants'
import {
  getDeviceTraversalFromNavigationId,
  getNavigationSelectionId,
} from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation-selector'
import { MeasurementUnitDetailContent } from '@/fleet-configuration/page-components/measurement-unit/measurement-unit-detail-content'
import { UnitIllustration } from '@/fleet-configuration/page-components/unit-illustration/unit-illustration'
import { toggleNavigationExpansion } from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation-actions'
import { resolveStateFromConfigSnippet } from '@/fleet-configuration/pages/configuration-snippet-detail/configuration-snippet-detail-utils'
import { ConfigScreenLayout } from '@/fleet-configuration/page-components/config-screen-layout/config-screen-layout'
import { ModuleDetailContent } from '@/fleet-configuration/page-components/module/module-detail-content'
import { ChannelParameters } from '@/fleet-configuration/page-components/channel/channel-parameters'
import { showErrorToast } from '@/common/services/show-toast'
import { ImportSnippet } from '@/fleet-configuration/components/import-snippet/import-snippet'
import { messages as t } from './configuration-snippet-detail-i18n'
import './configuration-snippet-detail.scss'
import { WizardMenu } from '@/fleet-configuration/page-components/wizard/wizard-menu/wizard-menu'

const { FULL_DEVICE, FULL_MODULE, DEVICE_CONFIG_PROPS, MODULE_CONFIG_PROPS, CHANNEL } = navigationSelectionItemType
const { SELECTED, INDETERMINATE, UNSELECTED } = wizardNavigationSelection

class _ConfigurationSnippetDetail extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    dispatch: PropTypes.func.isRequired,
    snippetId: PropTypes.string.isRequired,
    isNew: PropTypes.bool.isRequired,
    snippet: PropTypes.object,
    paramsSelected: PropTypes.object,
    moduleId: PropTypes.string,
    channelId: PropTypes.string,
  }

  static defaultProps = {
    snippet: null,
    paramsSelected: {},
    moduleId: null,
    channelId: null,
  }

  constructor(props) {
    super(props)
    this.state = { rowsSelected: {}, snippetNameAlerts: [], snippetNameHasError: false }
  }

  async componentDidMount() {
    const { dispatch, snippetId, isNew } = this.props
    await dispatch(loadSnippetDetailById(snippetId))
    const { snippet } = this.props
    if (snippet?.originalDeviceConfiguration) {
      dispatch(toggleNavigationExpansion(snippet.originalDeviceConfiguration.id, true))
    }
    this.initCheckboxSelection()
    if (isNew && this.snippetName) {
      this.snippetName.focus()
      this.snippetName.select()
    }
  }

  shouldComponentUpdate(nextProps) {
    const { snippet } = this.props
    if (nextProps.snippet !== snippet && snippet?.originalDeviceConfiguration) {
      this.initCheckboxSelection(nextProps.snippet)
    }
    return true
  }

  // this is not destructuring assignment, but setting default value
  // eslint-disable-next-line react/destructuring-assignment
  initCheckboxSelection(snippet = this.props.snippet) {
    const { originalDeviceConfiguration, snippetContent } = snippet
    const deviceId = originalDeviceConfiguration.id

    const devicePropsState = resolveStateFromConfigSnippet(
      originalDeviceConfiguration.getWritableProperties(),
      snippetContent.set,
    )
    const devicePropsId = getNavigationSelectionId(navigationSelectionItemType.DEVICE_CONFIG_PROPS, deviceId)
    let resultingRowSelection = {}
    resultingRowSelection = this.initialSelectionMarking(
      resultingRowSelection,
      DEVICE_CONFIG_PROPS,
      devicePropsId,
      devicePropsState,
    )

    const modulesSelectionData = []
    const channelsSelectionData = []

    if (originalDeviceConfiguration.deviceSpecific?.controller) {
      const controller = originalDeviceConfiguration.deviceSpecific.controller
      modulesSelectionData.push({
        id: getNavigationSelectionId(MODULE_CONFIG_PROPS, deviceId, urlPathController),
        props: resolveStateFromConfigSnippet(controller.getWritableProperties(), snippetContent.set),
      })
      // controller can't have channels (even if data model contains announces them) - do not try to resolve those
    }

    originalDeviceConfiguration.modules.forEach((module, moduleIndex) => {
      modulesSelectionData.push({
        id: getNavigationSelectionId(MODULE_CONFIG_PROPS, deviceId, moduleIndex),
        props: resolveStateFromConfigSnippet(module.getWritableProperties(), snippetContent.set),
      })
      module.channels.forEach((channel, channelIndex) => {
        channelsSelectionData.push({
          id: getNavigationSelectionId(CHANNEL, deviceId, moduleIndex, channelIndex),
          props: resolveStateFromConfigSnippet(channel.getWritableProperties(), snippetContent.set),
        })
      })
    })

    modulesSelectionData.forEach(({ id, props }) => {
      resultingRowSelection = this.initialSelectionMarking(resultingRowSelection, MODULE_CONFIG_PROPS, id, props)
    })

    channelsSelectionData.forEach(({ id, props }) => {
      resultingRowSelection = this.initialSelectionMarking(resultingRowSelection, CHANNEL, id, props, true)
    })
    this.setState({ rowsSelected: resultingRowSelection })
  }

  handleOnSnippetApply = () => {
    const { dispatch, snippetId } = this.props
    dispatch(openModal(SNIPPETS_TABLE_MODAL_ID, { snippetId }))
  }

  handleOnSnippetExport = () => {
    const { dispatch, snippetId } = this.props
    dispatch(exportPreset(snippetId))
  }

  needsPersistChange = false

  newSnippetContent = null

  initialSelectionMarking = (existingRowSelection, type, id, value) => {
    const downSelectionMarked = this.markRowSelectionDownDirection({ ...existingRowSelection }, type, id, value, true)
    const fullSelectionMarked = this.markRowSelectionUpDirection(downSelectionMarked, type, id)
    return fullSelectionMarked
  }

  /*
   * This function is complicated so READ THIS to understand it
   *   a, it marks all the selection into local state (this.state) for easy checkbox visualization
   *   b, but during checkbox state collection - it also marks redux state updates about underlying snippet into
   *     this.newSnippetContent + marks change into this.needsPersistChange
   * */
  handleRowSelectionChange = async (type, id, value) => {
    const { rowsSelected } = this.state
    const {
      snippet,
      dispatch,
      intl: { formatMessage: _ },
    } = this.props
    this.needsPersistChange = false
    this.newSnippetContent = { ...snippet.snippetContent }
    const downSelectionMarked = this.markRowSelectionDownDirection({ ...rowsSelected }, type, id, value)
    const fullSelectionMarked = this.markRowSelectionUpDirection(downSelectionMarked, type, id)
    this.setState({ rowsSelected: fullSelectionMarked })
    if (this.needsPersistChange) {
      this.newSnippetContent.set = this.newSnippetContent.set.sort()
      try {
        await dispatch(updateSnippet({ ...snippet, snippetContent: this.newSnippetContent }))
      } catch (e) {
        showErrorToast(_(t.snippetUpdateFailed))
        this.initCheckboxSelection()
        throw e
      }
    }
  }

  /* This method is mutating to save some performance */
  markRowSelectionDownDirection(existingSelection, type, id, value, isInitialization) {
    existingSelection[id] = value

    const {
      snippet: { originalDeviceConfiguration },
    } = this.props
    switch (type) {
      case FULL_DEVICE:
        this.markRowSelectionDownDirection(
          existingSelection,
          DEVICE_CONFIG_PROPS,
          getNavigationSelectionId(DEVICE_CONFIG_PROPS, originalDeviceConfiguration.id),
          value,
          isInitialization,
        )
        if (originalDeviceConfiguration.deviceSpecific?.controller) {
          this.markRowSelectionDownDirection(
            existingSelection,
            FULL_MODULE,
            getNavigationSelectionId(FULL_MODULE, originalDeviceConfiguration.id, urlPathController),
            value,
            isInitialization,
          )
        }
        originalDeviceConfiguration.modules.forEach((module, index) => {
          this.markRowSelectionDownDirection(
            existingSelection,
            FULL_MODULE,
            getNavigationSelectionId(FULL_MODULE, originalDeviceConfiguration.id, index),
            value,
            isInitialization,
          )
        })
        break
      case DEVICE_CONFIG_PROPS:
        if (isInitialization) {
          break
        }
        this.updateSnippetWriteProperties(originalDeviceConfiguration.getWritableProperties(), value)
        break
      case FULL_MODULE:
        {
          const traversal = getDeviceTraversalFromNavigationId(id)
          if (traversal[0] === urlPathController) {
            return existingSelection
          }
          const module = originalDeviceConfiguration.modules[traversal[0]]
          if (!module.isLabAmp()) {
            this.markRowSelectionDownDirection(
              existingSelection,
              MODULE_CONFIG_PROPS,
              getNavigationSelectionId(MODULE_CONFIG_PROPS, originalDeviceConfiguration.id, traversal[0]),
              value,
              isInitialization,
            )
          }
          module.channels.forEach((channel, channelIndex) => {
            this.markRowSelectionDownDirection(
              existingSelection,
              CHANNEL,
              getNavigationSelectionId(CHANNEL, originalDeviceConfiguration.id, traversal[0], channelIndex),
              value,
              isInitialization,
            )
          })
        }
        break
      case MODULE_CONFIG_PROPS:
        {
          if (isInitialization) {
            break
          }
          const traversal = getDeviceTraversalFromNavigationId(id)
          const module =
            traversal[0] === urlPathController
              ? originalDeviceConfiguration.deviceSpecific.controller
              : originalDeviceConfiguration.modules[traversal[0]]
          const propertiesToToggle = module.getWritableProperties()
          this.updateSnippetWriteProperties(propertiesToToggle, value)
        }
        break
      case CHANNEL:
        {
          if (isInitialization) {
            break
          }
          const traversal = getDeviceTraversalFromNavigationId(id)
          const module =
            traversal[0] === urlPathController
              ? originalDeviceConfiguration.deviceSpecific.controller
              : originalDeviceConfiguration.modules[traversal[0]]
          const channel = module.channels[traversal[1]]
          const propertiesToToggle = channel.getWritableProperties()
          this.updateSnippetWriteProperties(propertiesToToggle, value)
        }
        break
      default:
        break
    }
    return existingSelection
  }

  updateSnippetWriteProperties = (writableProperties, checkStatus) => {
    if (checkStatus === INDETERMINATE) {
      return
    }
    const { snippet } = this.props
    const { snippetContent } = snippet
    // first unselect all (this also removes possible duplicates for "selected" case)
    const updatedSetContent = this.newSnippetContent.set.filter(item => !writableProperties.includes(item))
    if (checkStatus === SELECTED) {
      updatedSetContent.push(...writableProperties)
    }
    // do not persist stuff if nothing changed (persist it when called by user action instead of initialization)
    if (snippetContent.set.length !== updatedSetContent.length) {
      this.newSnippetContent.set = updatedSetContent
      this.needsPersistChange = true
    }
  }

  /*
   * This function looks into specified selections in existing selections and determines if there are both
   *   a, true values in it
   *   b, false values in it.
   *
   *   Imagine it as a tree with one parent (the thing we want to determine = function result) and 2 children named A and B
   *     - Mark parent as "TRUE" when both A and B are true (all children are true)
   *     - Mark parent as "FALSE" when both A and B are false (all children are false)
   *     - Mark parent as "NULL" when at least one child is true and at the same time one child is false (so e.g. A is true, B is false).
   */
  resolveStateFromSelections(existingSelection, selectionsToCheck) {
    let hasTrue = false
    let hasFalse = false

    // note using "some" only to be able to early exit
    selectionsToCheck.some(selectionId => {
      // child indeterminate means parent indeterminate
      if (existingSelection[selectionId] === INDETERMINATE) {
        hasTrue = true
        hasFalse = true
        return true
      }
      if (existingSelection[selectionId] === SELECTED) {
        hasTrue = true
      } else {
        hasFalse = true
      }
      return hasTrue && hasFalse
    })

    if (hasTrue) {
      if (hasFalse) {
        return INDETERMINATE
      }
      return SELECTED
    }
    return UNSELECTED
  }

  /* This method is mutating to save some performance */
  markRowSelectionUpDirection(existingSelection, type, id) {
    const {
      snippet: { originalDeviceConfiguration },
    } = this.props

    switch (type) {
      case FULL_MODULE:
      case DEVICE_CONFIG_PROPS:
        {
          // all other direct children
          const selectionsToCheck = [
            getNavigationSelectionId(DEVICE_CONFIG_PROPS, originalDeviceConfiguration.id),
            ...Object.keys(originalDeviceConfiguration.modules).map(key =>
              getNavigationSelectionId(FULL_MODULE, originalDeviceConfiguration.id, key),
            ),
          ]
          const controller = originalDeviceConfiguration.deviceSpecific?.controller
          if (controller && controller.getWritableProperties().length) {
            selectionsToCheck.push(
              getNavigationSelectionId(FULL_MODULE, originalDeviceConfiguration.id, urlPathController),
            )
          }

          const valueToWrite = this.resolveStateFromSelections(existingSelection, selectionsToCheck)
          const parentId = getNavigationSelectionId(FULL_DEVICE, originalDeviceConfiguration.id)
          existingSelection[parentId] = valueToWrite
        }
        break
      case CHANNEL:
      case MODULE_CONFIG_PROPS:
        {
          const [moduleIndex] = getDeviceTraversalFromNavigationId(id)

          let deviceModule
          if (moduleIndex === urlPathController) {
            deviceModule = originalDeviceConfiguration.deviceSpecific.controller
          } else {
            deviceModule = originalDeviceConfiguration.modules[moduleIndex]
          }

          // all other direct children.
          const selectionsToCheck = [
            ...Object.keys(deviceModule.channels).map(key =>
              getNavigationSelectionId(CHANNEL, originalDeviceConfiguration.id, moduleIndex, key),
            ),
          ]

          if (!deviceModule.isLabAmp()) {
            selectionsToCheck.push(
              getNavigationSelectionId(MODULE_CONFIG_PROPS, originalDeviceConfiguration.id, moduleIndex),
            )
          }

          const valueToWrite = this.resolveStateFromSelections(existingSelection, selectionsToCheck)
          const parentId = getNavigationSelectionId(FULL_MODULE, originalDeviceConfiguration.id, moduleIndex)
          existingSelection[parentId] = valueToWrite
          this.markRowSelectionUpDirection(existingSelection, FULL_MODULE, parentId)
        }
        break
      default:
        break
    }
    return existingSelection
  }

  focusSnippetName = null

  handleOnSnippetNameFocus = () => {
    const { snippet } = this.props
    this.focusSnippetName = snippet.name
  }

  handleOnSnippetNameChange = evt => {
    const { dispatch, snippet } = this.props
    dispatch(setSnippetDetail({ ...snippet, name: evt.target.value }))
  }

  handleOnSnippetNameBlur = () => {
    const { dispatch, snippet } = this.props
    // do not update snippet if it did not change
    if (snippet.name === this.focusSnippetName) {
      return
    }
    if (snippet.name) {
      dispatch(updateSnippet(snippet))
    } else {
      dispatch(setSnippetDetail({ ...snippet, name: this.focusSnippetName }))
      this.handleOnSnippetNameValidated([])
    }
  }

  handleOnSnippetNameValidate = value => {
    const {
      intl: { formatMessage: _ },
    } = this.props
    return [required(value, _(t.valueCantBeEmpty))]
  }

  alertsUpdateId = null

  handleOnSnippetNameValidated = alerts => {
    // postpone setState so that we won't get caret movement problem (caret moving to the end of input)
    //  1, simply problem is that component will get old value even if "real" dom node already contains new/updated value.
    //    React updating this DOM disparity (so setting node to some/old value) resets caret position
    //  2, technically/internally problem was created by disparity from re-rendering snippet name twice ->
    //    a, by redux state update and b, by this setState call
    //    This setState call finishes update before redux state update is fully processed (even if dispatch is called first)
    //    so re-render after setState uses old value (already resets) and after that dispatch finish re-renders it again
    //    with new value (another reset with new/correct value, but input is already reset)
    //
    //    to circumvent this problem - just postpone setState long enough for redux to finish
    cancelAnimationFrame(this.alertsUpdateId)
    this.alertsUpdateId = requestAnimationFrame(() => {
      this.setState({ snippetNameAlerts: alerts, snippetNameHasError: alerts.length > 0 })
    })
  }

  handleParamsSelectionChange = async (value, paramNames) => {
    const {
      snippet,
      dispatch,
      intl: { formatMessage: _ },
    } = this.props
    const aParamNames = castArray(paramNames)
    let newSet = snippet.snippetContent.set
    if (value === SELECTED) {
      newSet = newSet.concat(...aParamNames)
    } else {
      newSet = newSet.filter(setParamName => !aParamNames.includes(setParamName))
    }
    try {
      await dispatch(updateSnippet({ ...snippet, snippetContent: { ...snippet.snippetContent, set: newSet } }))
    } catch (e) {
      showErrorToast(_(t.snippetUpdateFailed))
      throw e
    }
  }

  render() {
    const {
      intl: { formatMessage: _ },
      snippet,
      paramsSelected,
      moduleId,
      channelId,
    } = this.props
    const { name, originalDeviceConfiguration } = snippet || {}
    const { rowsSelected, snippetNameHasError, snippetNameAlerts } = this.state

    return (
      <SbFullLayout
        title={_(t.configurationSnippetDetail)}
        className="measurement-screen-page"
        breadcrumbs={[
          {
            path: '/',
            title: _(t.home),
          },
          {
            path: '/configuration/devices',
            title: _(t.configuration),
          },
          {
            path: '/configuration/preset',
            title: _(t.presets),
          },
          name || '',
        ]}
      >
        <div className="fl-container-row fl-grow-1 menu-content-wrapper full-size-wrapper">
          {snippet && (
            <>
              <WizardMenu
                rowSelectionChange={this.handleRowSelectionChange}
                rowsSelected={rowsSelected}
                baseUrl="/configuration/preset/detail/"
                rootId={snippet.id}
                explicitDeviceRef={originalDeviceConfiguration}
                computeDirtyState={false}
                showChainComponents={false}
              />
              <div className="configuration-detail-content fl-grow-1">
                <ConfigScreenLayout
                  title={
                    <>
                      <SbTextbox
                        inputRef={ref => {
                          this.snippetName = ref
                        }}
                        value={snippet.name}
                        onFocus={this.handleOnSnippetNameFocus}
                        onChange={this.handleOnSnippetNameChange}
                        onBlur={this.handleOnSnippetNameBlur}
                        onValidate={this.handleOnSnippetNameValidate}
                        onValidated={this.handleOnSnippetNameValidated}
                        hasError={snippetNameHasError}
                        className="auto-editable heading-control test-snippet-name"
                        size={Math.max(1, (snippet.name || '').length - 1)}
                      />
                      {snippetNameAlerts.length ? (
                        <SbInlineMessage
                          id={snippetNameAlerts[0].id}
                          title={snippetNameAlerts[0].title || ''}
                          type={snippetNameAlerts[0].severity}
                          message={snippetNameAlerts[0].message}
                        />
                      ) : null}
                      <SbButton className="test-apply-to-devices" onClick={this.handleOnSnippetApply}>
                        {_(t.applyToDevices)}
                      </SbButton>
                      <SbButton className="test-export-snippet" onClick={this.handleOnSnippetExport}>
                        {_(t.export)}
                      </SbButton>
                      <ImportSnippet
                        snippetId={snippet.id}
                        ViewBox={props => (
                          <SbButton {...props} className="test-import-snippet">
                            {_(t.import)}
                          </SbButton>
                        )}
                      />
                    </>
                  }
                  illustration={<UnitIllustration measurementDevice={originalDeviceConfiguration} />}
                  parameters={(() => {
                    if (channelId) {
                      return (
                        <ChannelParameters
                          paramSelectionChange={this.handleParamsSelectionChange}
                          paramsSelected={paramsSelected}
                          measurementDevice={originalDeviceConfiguration}
                          computeDirtyState={false}
                        />
                      )
                    }
                    if (moduleId) {
                      return (
                        <ModuleDetailContent
                          paramSelectionChange={this.handleParamsSelectionChange}
                          paramsSelected={paramsSelected}
                          measurementDevice={originalDeviceConfiguration}
                          computeDirtyState={false}
                        />
                      )
                    }
                    return (
                      <MeasurementUnitDetailContent
                        paramSelectionChange={this.handleParamsSelectionChange}
                        paramsSelected={paramsSelected}
                        measurementDevice={originalDeviceConfiguration}
                        computeDirtyState={false}
                      />
                    )
                  })()}
                />
              </div>
            </>
          )}
        </div>
      </SbFullLayout>
    )
  }
}

export const ConfigurationSnippetDetail = withRouter(
  connect(configurationSnippetDetailSelector)(injectIntl(_ConfigurationSnippetDetail)),
)
