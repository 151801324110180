import React from 'react'

import { OAuth } from 'skybase-oauth'

export const Docs = () => {
  const apiUrl = OAuth.config.API_ENDPOINT_URL
  const docsUrl = apiUrl?.replace('api.', 'docs.').replace('api-', 'docs-')

  return (
    <div id="documenation" className="device-onboarding">
      <div className="qr-container with-icon">
        <a href={docsUrl} target="_blank" rel="noreferrer" id="dhub-documentation">
          <span className="sbi-paper" />
          KiCloud
          <br />
          documenation
        </a>
      </div>

      {/* <div className="qr-container with-icon">
        <a href="https://hub.kistler.com" target="_blank" rel="noreferrer" id="latest-api-documentation">
          <span className="sbi-paper" />
          Latest API
          <br />
          documenation
        </a>
      </div> */}
    </div>
  )
}
