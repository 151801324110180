import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { OAuth } from 'skybase-oauth'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbLabel } from 'skybase-ui/skybase-components/sb-label'
import { SbButton } from 'skybase-ui/skybase-components/sb-button'
import { SbBadge } from 'skybase-ui/skybase-components/sb-badge'
import { InfoMessage } from 'skybase-oauth/common/components/info-message'

import { withRouter } from '@/common/router'
import { getVariousColors } from '@/utils/get-various-colors'
import { StatusBullet, RED, GREEN } from '@/common/status-bullet'
import { getColorsObjectFromArray, getValue } from '@/utils'
import { getIotHubDeviceOnlineState } from '@/iot-hub/selectors'
import { deviceStatuses, ownershipStatuses } from './constants'
import { deviceShape } from './shapes'
import { getOnboardingStatusBulletConfig, getSecurityBulletConfig } from './utils'
import { messages as t } from './devices-i18n'

const { ONLINE } = deviceStatuses
const { OWNED } = ownershipStatuses
const colors = getVariousColors()

class _DeviceDetails extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    data: deviceShape,
    liveDeviceStatuses: PropTypes.object, // eslint-disable-line
    router: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    data: null,
    liveDeviceStatuses: {},
  }

  handleOnResorucesClick = () => {
    const {
      router: { navigate },
      data,
    } = this.props

    navigate(`/iot-hub/devices/${data.id}/resources`)
  }

  handleOnDataSourcesClick = () => {
    const {
      router: { navigate },
      data,
    } = this.props

    navigate(`/iot-hub/devices/${data.id}/datasources`)
  }

  renderAdvancedDeviceParams = () => {
    const {
      data: { isSecured, ownershipStatus, cloudProvisioningStatus, ownerId },
      intl: { formatMessage: _ },
    } = this.props
    const { color: ownershipStatusColor, text: ownershipStatusText } = getSecurityBulletConfig(
      isSecured,
      ownershipStatus,
      _,
    )
    const { color: onboardingStatusColor, text: onboardingStatusText } = getOnboardingStatusBulletConfig(
      cloudProvisioningStatus,
      _,
    )

    return (
      <>
        <SbLabel title={_(t.securedDevice)} id="device-secured-label" inline>
          <StatusBullet status={isSecured ? GREEN : RED}>{isSecured ? _(t.enabled) : _(t.disabled)}</StatusBullet>
        </SbLabel>

        {isSecured && (
          <>
            <SbLabel title={_(t.ownershipStatus)} id="device-ownership-status-label" inline>
              <StatusBullet status={ownershipStatusColor}>{ownershipStatusText}</StatusBullet>
            </SbLabel>

            <SbLabel title={_(t.ownerId)} id="device-owner-id-label" inline>
              {ownerId ?? '-'}
            </SbLabel>

            {OAuth.isInLocalBackendMode && (
              <SbLabel title={_(t.onboardingStatus)} id="device-onboarding-status-label" inline>
                <StatusBullet status={onboardingStatusColor}>{onboardingStatusText}</StatusBullet>
              </SbLabel>
            )}
          </>
        )}
      </>
    )
  }

  render() {
    const {
      data,
      liveDeviceStatuses,
      intl: { formatMessage: _ },
    } = this.props

    if (data?.id) {
      const {
        id,
        name,
        manufacturerName,
        modelNumber,
        serialNumber,
        types,
        endpoints,
        status,
        isSecured,
        ownershipStatus,
        protocolIndependentId,
      } = data
      const liveStatus = liveDeviceStatuses?.[id] || status
      const isOnline = liveStatus === ONLINE
      const typesColors = getColorsObjectFromArray(data.types)
      let colorIndex = -1
      const renderButtons = OAuth.isInNormalMode || !isSecured || (isSecured && ownershipStatus === OWNED) // is secured and owned by me

      return (
        <>
          <SbLabel title={_(t.deviceName)} id="device-name-label" inline>
            {getValue(name)}
          </SbLabel>
          <SbLabel title={_(t.manufacturer)} id="device-manufacturer-label" inline>
            {getValue(manufacturerName)}
          </SbLabel>
          <SbLabel title={_(t.modelNumber)} id="device-model-name-label" inline>
            {getValue(modelNumber)}
          </SbLabel>
          <SbLabel title={_(t.serialNumber)} id="device-serial-number-label" inline>
            {getValue(serialNumber)}
          </SbLabel>
          <SbLabel title="ID" id="device-id-label" inline>
            {getValue(id)}
          </SbLabel>
          {protocolIndependentId && (
            <SbLabel title="PIID" id="device-piid-label" inline>
              {getValue(protocolIndependentId)}
            </SbLabel>
          )}
          <SbLabel title={_(t.deviceStatus)} id="device-status-label" inline>
            <StatusBullet status={isOnline ? GREEN : RED}>{isOnline ? _(t.online) : _(t.offline)}</StatusBullet>
          </SbLabel>
          <SbLabel title={_(t.endpoints)} id="device-endpoints-label" inline>
            <div className="text-align-right endpoints-list">
              {endpoints?.map?.(endpoint => {
                colorIndex += 1
                if (!colors[colorIndex]) {
                  colorIndex = 0
                }
                return (
                  <SbBadge
                    className="endpoint-badge"
                    key={`device-endpoint-${endpoint}`}
                    style={{ backgroundColor: colors[colorIndex] }}
                  >
                    {endpoint}
                  </SbBadge>
                )
              }) || '-'}
            </div>
          </SbLabel>
          <SbLabel title={_(t.types)} id="device-types-label" inline>
            <div className="fl-col fl-align-items-end">
              {types?.map?.(type => {
                return (
                  <SbBadge key={`device-type-${type}`} style={{ backgroundColor: typesColors[type] }}>
                    {type}
                  </SbBadge>
                )
              }) || '-'}
            </div>
          </SbLabel>

          {!OAuth.isInNormalMode && this.renderAdvancedDeviceParams()}

          {renderButtons && (
            <>
              <SbButton id="device-resources-button" className="m-t-20" onClick={this.handleOnResorucesClick}>
                {_(t.resources)}
              </SbButton>
              <SbButton id="device-data-sources-button" className="m-t-10" onClick={this.handleOnDataSourcesClick}>
                {_(t.dataSources)}
              </SbButton>
            </>
          )}
        </>
      )
    }

    return <InfoMessage>{_(t.selectADevice)}</InfoMessage>
  }
}

const mapStateToProps = state => {
  const liveDeviceStatuses = getIotHubDeviceOnlineState(state)

  return {
    liveDeviceStatuses,
  }
}

export const DeviceDetails = withRouter(injectIntl(connect(mapStateToProps)(_DeviceDetails)))
