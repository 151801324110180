import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { aclShape } from 'skybase-oauth/auth'
import { SbButton } from 'skybase-ui/skybase-components/sb-button'
import { STATES } from 'skybase-oauth/constants'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'

import { ErrorList } from '@/common/error-list'
import { apiErrorsShape } from '@/common/shapes'
import { isJsonString } from '@/utils'
import { HintRenderer } from '@/common/hint-renderer'
import { getIotHubDeviceDataSourceState, getIotHubDeviceDataSourceConfigurationState } from '@/iot-hub/selectors'
import { JSONEditor, editorModes } from '@/common/json-editor'
import { fetchDeviceDataSourceConfiguration, updateDeviceDataSourceConfiguration } from '../utils'
import { deviceDataSourceShape } from '../shapes'
import { messages as t } from './datasources-i18n'

const { CODE, VIEW } = editorModes

class _DataSourceConfigurationUpdate extends PureComponent {
  static propTypes = {
    configuration: PropTypes.object.isRequired, // eslint-disable-line
    dataSource: deviceDataSourceShape.isRequired,
    handleFetchConfiguration: PropTypes.func.isRequired,
    handleUpdateConfiguration: PropTypes.func.isRequired,
    retievingConfiguration: PropTypes.bool.isRequired,
    updatingConfiguration: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
    deviceOffline: PropTypes.bool.isRequired,
    errors: apiErrorsShape,
    acl: aclShape.isRequired,
  }

  static defaultProps = {
    errors: [],
  }

  constructor(props) {
    super(props)

    this.state = {
      configurationJSON: null,
      hasError: false,
      editorHeight: 300,
      id: props?.dataSource?.id,
    }
  }

  static getDerivedStateFromProps(props, state) {
    const {
      dataSource: { id: nextId },
    } = props
    const { id } = state

    if (id !== nextId) {
      return {
        id: nextId,
        configurationJSON: null,
        hasError: false,
      }
    }

    if (props.retievingConfiguration) {
      return {
        configurationJSON: null,
        hasError: false,
      }
    }

    return null
  }

  handleOnConfirmClick = () => {
    const { dataSource: { id, deviceId } = {}, handleUpdateConfiguration, configuration } = this.props
    const { configurationJSON } = this.state

    if (id && deviceId) {
      const jsonData = configurationJSON && isJsonString(configurationJSON) ? configurationJSON : configuration

      handleUpdateConfiguration(deviceId, id, typeof jsonData === 'object' ? jsonData : JSON.parse(jsonData))
    }
  }

  handleOnRetrieveClick = () => {
    const { dataSource: { id, deviceId } = {}, handleFetchConfiguration } = this.props

    if (id && deviceId) {
      handleFetchConfiguration(deviceId, id)
    }
  }

  handleOnInterfaceJSONChange = configurationJSON => this.setState({ configurationJSON })

  handleOnInterfaceJSONError = error => this.setState({ hasError: error.length > 0 })

  handleOnEditorResize = (width, height, callback = () => {}) => {
    this.setState({ editorHeight: height }, () => callback())
  }

  render() {
    const { configurationJSON, editorHeight, hasError } = this.state
    const {
      configuration,
      retievingConfiguration,
      updatingConfiguration,
      intl: { formatMessage: _ },
      deviceOffline,
      errors,
      acl: { write },
    } = this.props
    const disableControls = retievingConfiguration || updatingConfiguration
    const hasErrorServer = errors?.length > 0

    return (
      <>
        <div
          className={classNames('resource-editor-container', 'm-b-10', { error: hasErrorServer })}
          style={{ minHeight: `${editorHeight + 2}px` }}
        >
          {!disableControls && (
            <JSONEditor
              json={configurationJSON || configuration}
              onChange={this.handleOnInterfaceJSONChange}
              onError={this.handleOnInterfaceJSONError}
              onResize={this.handleOnEditorResize}
              height={`${editorHeight}px`}
              mode={write ? CODE : VIEW}
            />
          )}
        </div>

        <div className="fl-row fl-justify-end">
          <SbButton onClick={this.handleOnRetrieveClick} loading={retievingConfiguration} disabled={disableControls}>
            {_(t.retrieve)}
          </SbButton>

          {write && (
            <HintRenderer showHint={deviceOffline} hintData={_(t.cannotUpdateDataSourceWhileDeviceOffline)}>
              <SbButton
                className="primary m-l-10"
                disabled={hasError || disableControls || deviceOffline}
                onClick={this.handleOnConfirmClick}
                loading={updatingConfiguration}
              >
                {_(t.update)}
              </SbButton>
            </HintRenderer>
          )}
        </div>

        <div className="m-t-10">
          <ErrorList errors={errors} />
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  const { retrievingConfigurationState, updatingConfigurationState } = getIotHubDeviceDataSourceState(state)
  const { data: configuration, errors } = getIotHubDeviceDataSourceConfigurationState(state)

  return {
    errors,
    configuration,
    retievingConfiguration: retrievingConfigurationState === STATES.LOADING,
    updatingConfiguration: updatingConfigurationState === STATES.LOADING,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleUpdateConfiguration: (deviceId, dataSourceId, data) =>
      dispatch(updateDeviceDataSourceConfiguration(deviceId, dataSourceId, data)),
    handleFetchConfiguration: (deviceId, dataSourceId) =>
      dispatch(fetchDeviceDataSourceConfiguration(deviceId, dataSourceId)),
  }
}

export const DataSourceConfigurationUpdate = injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(_DataSourceConfigurationUpdate),
)
