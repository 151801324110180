import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { SbDataTable } from 'skybase-ui/skybase-components/sb-data-table'
import { addComponentModalPossibleComponents } from '@/fleet-configuration/page-components/wizard/add-component-modal/add-component-modal-constants'
import { useEquipment } from '@/fleet-configuration/equipment'
import { loadProjectDeviceIfEmpty } from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { loadChainIfEmpty } from '@/fleet-configuration/data-fleet/chain/chain-actions'
import { SbTextbox } from 'skybase-ui/skybase-components/sb-textbox'
import { getChannelsChainItemFromAllChainItems } from '@/fleet-configuration/data-fleet/chain/chain-selectors'
import { menuPositions } from 'skybase-ui/skybase-components/sb-dropdown/constants'
import { toCssString } from '@/utils/sanitizer'
import { messages as t } from './add-component-modal-equipment-table-i18n'
import './add-component-modal-equipment-table.scss'

export const AddComponentModalEquipmentTable = ({
  deviceId,
  moduleIndex,
  channelIndex,
  componentToAdd,
  onSelectionChange = null,
}) => {
  const [selectedRow, setSelectedRow] = useState(null)
  const [isLoadingDependencies, setIsLoadingDependencies] = useState(true)
  const [currentChain, setCurrentChain] = useState(null)
  const [filter, setFilter] = useState({})
  const [pagination, setPagination] = useState({})

  const { formatMessage: _ } = useIntl()
  const dispatch = useDispatch()

  const { data: equipmentData, loading: isEquipmentLoading } = useEquipment()
  useEffect(() => {
    setIsLoadingDependencies(true)
    Promise.all([dispatch(loadProjectDeviceIfEmpty(deviceId)), dispatch(loadChainIfEmpty())]).then(
      ([projectDevice, chainItems]) => {
        setCurrentChain(getChannelsChainItemFromAllChainItems(projectDevice, moduleIndex, channelIndex, chainItems))
        setIsLoadingDependencies(false)
      },
    )
  }, [deviceId, moduleIndex, channelIndex, dispatch])

  // try to select row once all dependency data are loaded
  useEffect(() => {
    const chainComponent = currentChain?.[componentToAdd]
    if (chainComponent && (chainComponent.typeNumber || chainComponent.serialNumber) && equipmentData.length) {
      setSelectedRow(
        equipmentData.find(
          equipmentItem =>
            equipmentItem.typeNumber === chainComponent.typeNumber &&
            (componentToAdd === addComponentModalPossibleComponents.CABLE ||
              equipmentItem.serialNumber === chainComponent.serialNumber),
        ),
      )
    }
  }, [currentChain, equipmentData, componentToAdd])

  const cellFormatter = useCallback(
    (value, key, row) => {
      if (key === 'typeName') {
        return (
          <>
            <span
              className={classnames(
                'm-r-10',
                componentToAdd === addComponentModalPossibleComponents.SENSOR ? 'sbi-sensor' : 'sbi-cable-kids',
              )}
            />
            {value}
          </>
        )
      }
      if (key === '_actions') {
        return (
          <div className="actions-wrapper">
            <span
              className={`${selectedRow?.id === row.id ? 'sbi-minus-circle' : 'sbi-plus-circle'} row-selection`}
              onClick={() => {
                if (selectedRow?.id === row.id) {
                  setSelectedRow(null)
                  if (onSelectionChange) {
                    onSelectionChange(null)
                  }
                } else if (onSelectionChange) {
                  onSelectionChange(row, setSelectedRow)
                } else {
                  setSelectedRow(row)
                }
              }}
            />
          </div>
        )
      }
      return value
    },
    [selectedRow, componentToAdd, onSelectionChange],
  )

  const rowFormatter = useCallback(
    row => {
      return {
        className: classnames(row.id === selectedRow?.id ? 'selected' : '', toCssString(`row-${row.id}`)),
      }
    },
    [selectedRow],
  )

  const handleOnPaginationChange = useCallback(newPagination => setPagination(newPagination), [])

  const filterCellFormatter = useCallback(
    columnName => {
      if (columnName === '_actions') {
        return null
      }
      return (
        <SbTextbox
          value={filter[columnName] || ''}
          onChange={evt => setFilter({ ...filter, [columnName]: evt.target.value })}
        />
      )
    },
    [filter],
  )

  const columns = useMemo(
    () => [
      { name: 'typeName', label: _(t.typeName) },
      componentToAdd === addComponentModalPossibleComponents.SENSOR
        ? { name: 'serialNumber', label: _(t.serialNumber) }
        : { name: 'typeNumber', label: _(t.typeNumber) },
      { name: '_actions', label: ' ', sortable: false },
    ],
    [componentToAdd, _],
  )

  const isLoading = isLoadingDependencies || isEquipmentLoading

  const componentsToDisplay = useMemo(() => {
    let resultItems = []
    if (!isLoading) {
      // first filter by equipment type
      if (componentToAdd === addComponentModalPossibleComponents.SENSOR) {
        resultItems = equipmentData.filter(e => e.family === 'sensor')
      } else {
        resultItems = equipmentData.filter(e => e.family === 'cable')
      }
      // now apply the actual filter
      const filterEntries = Object.entries(filter).map(([key, value]) => [key, value.toLowerCase()])
      resultItems = resultItems.filter(item =>
        filterEntries.every(([filterKey, filterValue]) => item[filterKey].toLowerCase().includes(filterValue)),
      )
    }
    return resultItems
  }, [componentToAdd, equipmentData, filter, isLoading])

  return (
    <div className="add-component-content-wrapper" data-testid="equipment-table-wrapper">
      {isLoading ? (
        <SbLoader show />
      ) : (
        <div data-testid="displays-equipment-table">
          <SbDataTable
            className="equipment-table"
            columns={columns}
            data={componentsToDisplay}
            cellFormatter={cellFormatter}
            rowFormatter={rowFormatter}
            enableFilterRow
            filterCellFormatter={filterCellFormatter}
            enablePagination
            paginationProps={{
              pageSizeDropdownProps: {
                className: 'min-width-100px',
                menuPosition: menuPositions.AUTO_TOP,
              },
              ...pagination,
              onChange: handleOnPaginationChange,
            }}
            asyncData={false}
            defaultSortBy={{
              sortCol: 'typeName',
              sortOrder: 'asc',
            }}
          />
        </div>
      )}
    </div>
  )
}

AddComponentModalEquipmentTable.propTypes = {
  deviceId: PropTypes.string.isRequired,
  moduleIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  channelIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  componentToAdd: PropTypes.oneOf(Object.values(addComponentModalPossibleComponents)).isRequired,
  onSelectionChange: PropTypes.func,
}
