import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { SbButton } from 'skybase-ui/skybase-components/sb-button'
import { steps } from '../wizard-constants'
import './wizard-navigation.scss'

export const WizardNavigation = ({ step, deviceId }) => {
  const { formatMessage: _ } = useIntl()
  const navigate = useNavigate()

  return (
    <div className="wizard-navigation">
      {steps.map(({ name, urlSegment }, index) => (
        <React.Fragment key={urlSegment}>
          <SbButton
            onClick={step === index ? () => {} : () => navigate(`/configuration/${urlSegment}/${deviceId}`)}
            className={classnames('wizard-item', { 'wizard-selected-item': step === index })}
          >
            <span className="wizard-dot">{index + 1}</span>
            {_(name)}
          </SbButton>
          {index === steps.length - 1 ? null : (
            <div className={classnames('wizard-line', { 'wizard-line-over': index < step })} />
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

WizardNavigation.propTypes = {
  step: PropTypes.number.isRequired,
  deviceId: PropTypes.string.isRequired,
}
