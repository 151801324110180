import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { SbHint } from 'skybase-ui/skybase-components/sb-hint'

export class HintRenderer extends PureComponent {
  static propTypes = {
    hintData: PropTypes.node.isRequired,
    showHint: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    hintClassName: PropTypes.string,
  }

  static defaultProps = {
    hintClassName: 'sb-width-m',
  }

  render() {
    const { hintData, showHint, children, hintClassName, ...rest } = this.props

    if (showHint) {
      return (
        <SbHint
          {...rest}
          position={{ vertical: 'middle', horizontal: 'left' }}
          hintData={hintData}
          hintClassName={hintClassName}
        >
          {children}
        </SbHint>
      )
    }

    return children
  }
}
