import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { OAuth } from 'skybase-oauth'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbDataTable } from 'skybase-ui/skybase-components/sb-data-table'
import { SbHint } from 'skybase-ui/skybase-components/sb-hint'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'
import { horizontalPosition, verticalPosition } from 'skybase-ui/skybase-components/sb-hint/constants'
import { messages as oa } from 'skybase-oauth/messages-i18n'

import { StatusBullet, RED, GREEN } from '@/common/status-bullet'
import { getSelectedItemPageNumber } from '@/utils'
import { getIotHubDeviceDetailsState, getIotHubDeviceOnlineState } from '@/iot-hub/selectors'
import { loadDeviceToUpdate } from './actions'
import {
  fetchDevices,
  getOnboardingStatusBulletConfig,
  getSecurityBulletConfig,
  subscribeToDevicesStatusWS,
  getDuplicatePIIDorSerialNumberRows,
  filterDevice,
} from './utils'
import { deviceStatuses, DEFAULT_PAGE_SIZE, DEFAULT_SORT_BY } from './constants'

import { messages as t } from './devices-i18n'

const { ONLINE } = deviceStatuses

class _DevicesList extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    // eslint-disable-next-line
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ).isRequired, // this prop is used in redux map
    deviceList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ).isRequired,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
    handleFetchDevices: PropTypes.func.isRequired,
    handleLoadDeviceToUpdate: PropTypes.func.isRequired,
    selectedDeviceId: PropTypes.string,
    searchValue: PropTypes.string,
    matchDeviceId: PropTypes.string,
    liveDeviceStatuses: PropTypes.object, // eslint-disable-line
  }

  static defaultProps = {
    columns: null,
    selectedDeviceId: null,
    searchValue: '',
    matchDeviceId: null,
    liveDeviceStatuses: {},
  }

  constructor(props) {
    super(props)

    const {
      intl: { formatMessage: _ },
    } = props

    this.tableColumns = props.columns || [
      {
        name: 'name',
        label: _(t.deviceName),
        cellsClassName: 'text-align-left device-name-cell',
        headerClassName: 'text-align-left',
      },
      { name: 'id', label: 'ID' },
    ]

    if (OAuth.isInNormalMode) {
      this.tableColumns.push({
        name: 'status',
        label: _(t.deviceStatus),
        cellsStyle: { width: '130px' },
      })
    } else {
      this.tableColumns.push({
        name: 'isSecured',
        label: _(t.deviceSecurity),
        cellsStyle: { width: '130px' },
        sortable: false,
      })

      if (OAuth.isInLocalBackendMode) {
        this.tableColumns.push({
          name: 'cloudProvisioningStatus',
          label: _(t.onboardingStatus),
          cellsStyle: { width: '140px' },
          headerClassName: 'no-word-break',
        })
      }
    }

    this.state = {
      pagination: {
        pageSize: DEFAULT_PAGE_SIZE,
        pageNumber: 1,
      },
      sortBy: DEFAULT_SORT_BY,
      searchValue: props.searchValue,
    }
    this.isComponentMounted = true
  }

  static getDerivedStateFromProps(props, state) {
    const { searchValue } = props
    const { searchValue: searchValueState } = state

    // When the search value changes, reset the pageNumber to the first page in the pagination
    if (searchValue !== searchValueState) {
      return {
        searchValue,
        pagination: {
          ...state.pagination,
          pageNumber: 1,
        },
      }
    }

    return null
  }

  async componentDidMount() {
    const { searchValue } = this.state
    const { handleFetchDevices } = this.props

    let devices = []

    if (OAuth.isInNormalMode) {
      devices = await handleFetchDevices()
    }

    this.fetchCallback(filterDevice(devices, searchValue))

    SbEmitter.on('deviceJumpToPage', this.handleJumpToPage)
  }

  componentWillUnmount() {
    this.isComponentMounted = false

    SbEmitter.off('deviceJumpToPage', this.handleJumpToPage)
  }

  fetchCallback = devices => {
    subscribeToDevicesStatusWS()
    this.handleJumpToPage(devices)
  }

  handleJumpToPage = (devices, deviceId = null) => {
    const {
      pagination: { pageSize },
      sortBy,
    } = this.state
    /* eslint-disable react/destructuring-assignment */
    const matchDeviceId = deviceId || this.props.matchDeviceId
    const deviceList = devices || this.props.deviceList
    /* eslint-enable react/destructuring-assignment */

    // Set the pageNumber pagination based on the selected value in the table
    if (deviceList && matchDeviceId && this.isComponentMounted) {
      const selectedItemPageNumber = getSelectedItemPageNumber({
        columns: this.tableColumns,
        data: deviceList,
        sortShape: sortBy,
        pageSize,
        indexSelector: ({ id }) => id === matchDeviceId,
      })

      this.setState(prevState => {
        return { pagination: { ...prevState.pagination, pageNumber: selectedItemPageNumber } }
      })
    }
  }

  handleOnPaginationChange = pagination => this.setState({ pagination })

  handleOnSortChange = sortBy => this.setState({ sortBy })

  onRowSelect = id => {
    const { deviceList, handleLoadDeviceToUpdate } = this.props

    const thisDevice = deviceList.find(device => device.id === id)

    if (thisDevice) {
      handleLoadDeviceToUpdate(thisDevice)
    }
  }

  cellFormatter = (value, key, row, data) => {
    const {
      intl: { formatMessage: _ },
      liveDeviceStatuses,
    } = this.props

    if (key === 'name' && !OAuth.isInNormalMode) {
      const duplicates = getDuplicatePIIDorSerialNumberRows(row, data)
      const itemCount = duplicates.length

      if (itemCount > 0) {
        const deviceNames = duplicates.map(device => device.name).join(', ')
        const message =
          itemCount < 4
            ? _(t.deviceHasTheSamePIIDorSerialNumber, { itemCount, deviceNames })
            : _(t.multipleDevicesHasTheSamePIIDorSerialNumber)
        return (
          <div className="duplicate-piid-cell fl-row fl-justify-sb fl-align-items-center">
            <span className="device-name-value" title={value}>
              {value}
            </span>
            <SbHint
              position={{ horizontal: horizontalPosition.CENTER, vertical: verticalPosition.TOP }}
              hintData={message}
            >
              <span className="duplicate-piid-bullet m-l-20 fl-row fl-justify-center fl-align-items-center">!</span>
            </SbHint>
          </div>
        )
      }

      return (
        <span className="device-name-value" title={value}>
          {value}
        </span>
      )
    }

    if (key === 'name' && OAuth.isInNormalMode) {
      return (
        <span className="device-name-value" title={value}>
          {value}
        </span>
      )
    }

    if (key === 'status') {
      const liveStatus = liveDeviceStatuses?.[row.id] || value
      const isOnline = liveStatus === ONLINE
      return <StatusBullet status={isOnline ? GREEN : RED}>{isOnline ? _(t.online) : _(t.offline)}</StatusBullet>
    }

    if (key === 'isSecured') {
      const { color, text } = getSecurityBulletConfig(value, row.ownershipStatus, _)
      return (
        <StatusBullet status={color}>
          <span className="no-word-break">{text}</span>
        </StatusBullet>
      )
    }

    if (key === 'cloudProvisioningStatus') {
      const { color, text } = getOnboardingStatusBulletConfig(value, _)
      return (
        <StatusBullet status={color}>
          <span className="no-word-break">{text}</span>
        </StatusBullet>
      )
    }

    return value
  }

  rowFormatter = row => {
    const { selectedDeviceId } = this.props
    const selected = row.id === selectedDeviceId ? 'selected' : ''

    return {
      onClick: () => this.onRowSelect(row.id),
      className: classNames({ selected }),
    }
  }

  render() {
    const { pagination } = this.state
    const {
      deviceList,
      intl: { formatMessage: _ },
    } = this.props

    return (
      <SbDataTable
        id="devices-table"
        className="list-table"
        columns={this.tableColumns}
        data={deviceList}
        rowFormatter={row => this.rowFormatter(row)}
        cellFormatter={this.cellFormatter}
        emptyMessage={_(oa.noResults)}
        enablePagination
        asyncData={false}
        paginationProps={{
          pageSizeDropdownProps: {
            className: 'min-width-100px',
          },
          ...pagination,
          onChange: this.handleOnPaginationChange,
        }}
        defaultSortBy={DEFAULT_SORT_BY}
        onSortChange={this.handleOnSortChange}
      />
    )
  }
}

const mapStateToProps = (state, { data }) => {
  const {
    data: { id },
  } = getIotHubDeviceDetailsState(state)
  const liveDeviceStatuses = getIotHubDeviceOnlineState(state)

  return {
    selectedDeviceId: id,
    liveDeviceStatuses,
    deviceList:
      data?.map(device => ({
        ...device,
        status: liveDeviceStatuses?.[device.id] || device.status,
      })) || [],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleFetchDevices: () => dispatch(fetchDevices()),
    handleLoadDeviceToUpdate: device => dispatch(loadDeviceToUpdate(device)),
  }
}

export const DevicesList = injectIntl(connect(mapStateToProps, mapDispatchToProps)(_DevicesList))
