import { reduce } from 'lodash'

// more specific/efficient implementation of !!lodash.intersect(arg1,arg2).length
export const arraysHaveSameItem = (arr1 = [], arr2 = []) => arr1.some(item1 => arr2.includes(item1))

export const objectDiffDeep = (sourceObj, targetObj, maxDepthRecursionStopper = 32) => {
  if (maxDepthRecursionStopper <= 0) {
    throw new Error('Recursion limit reached')
  }
  const newProps =
    sourceObj &&
    typeof sourceObj === 'object' &&
    reduce(
      targetObj,
      (prev, value, key) => {
        if (!(key in sourceObj) && value !== undefined) {
          prev[key] = value
        }
        return prev
      },
      {},
    )
  const changedProps = reduce(
    sourceObj,
    (prev, value, key) => {
      if (value && typeof value === 'object' && targetObj[key]) {
        const partialDiff = objectDiffDeep(value, targetObj[key], maxDepthRecursionStopper - 1)
        if (Object.keys(partialDiff).length) {
          prev[key] = partialDiff
        }
      } else if (value !== targetObj[key] && typeof value !== 'function') {
        prev[key] = targetObj[key]
      }
      return prev
    },
    {},
  )
  return { ...newProps, ...changedProps }
}
