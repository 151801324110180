import { STATES } from 'skybase-oauth/constants'

import {
  SET_DEVICE_DATA_SOURCES,
  LOAD_DEVICE_DATA_SOURCE_TO_UPDATE,
  LOAD_DEVICE_DATA_SOURCE_CONFIGURATION_TO_UPDATE,
  CLEAR_DEVICE_DATA_SOURCE_PARAMS,
  CHANGE_DEVICE_DATA_SOURCE_STATE,
  CHANGE_UPDATE_DEVICE_DATA_SOURCE_STATE,
  CHANGE_RETRIEVE_DEVICE_DATA_SOURCE_CONFIGURATION_STATE,
  CHANGE_UPDATE_DEVICE_DATA_SOURCE_CONFIGURATION_STATE,
  SET_DEVICE_DATA_TRANSFER_CONFIGURATION,
  SET_DEVICE_DATA_TRANSFER_ACTIVE_TRANSFER_METHOD,
  CHANGE_DEVICE_DATA_SOURCE_TRANSFER_STATE,
  SET_DEVICE_DATA_SOURCE_CONFIGURATION_ERRORS,
  ADD_RUNNING_MEASUREMENT,
  REMOVE_RUNNING_MEASUREMENT,
} from './actions'
import { DEVICES_DATA_SOURCE_STATE_KEYS } from './constants'

const { DATA_SOURCES_LIST, DATA_SOURCE_DETAILS, DATA_SOURCE_CONFIGURATION, DATA_TRANSFER, RUNNING_MEASUREMENTS } =
  DEVICES_DATA_SOURCE_STATE_KEYS

export const initialDeviceDataSourceState = {
  id: null,
}

export const initialDeviceDataSourceConfigurationState = {}

export const initialDeviceDataSourceTransferState = {}

const getDefaultState = () => {
  return {
    [DATA_SOURCES_LIST]: {
      data: [],
      state: STATES.LOADING,
    },
    [DATA_SOURCE_DETAILS]: {
      data: initialDeviceDataSourceState,
      state: STATES.NORMAL,
    },
    [DATA_SOURCE_CONFIGURATION]: {
      data: initialDeviceDataSourceConfigurationState,
      state: STATES.NORMAL,
      errors: [],
    },
    [DATA_TRANSFER]: {
      data: initialDeviceDataSourceTransferState,
      state: STATES.NORMAL,
      transferState: STATES.NORMAL,
      activeTransfer: null,
    },
    [RUNNING_MEASUREMENTS]: [],
    updateDataSourceState: STATES.NORMAL,
    retrievingConfigurationState: STATES.NORMAL,
    updatingConfigurationState: STATES.NORMAL,
  }
}

const getInitialParams = state => {
  switch (state) {
    case DATA_SOURCE_DETAILS:
      return initialDeviceDataSourceState
    case DATA_SOURCE_CONFIGURATION:
      return initialDeviceDataSourceConfigurationState
    case DATA_TRANSFER:
      return initialDeviceDataSourceTransferState
    default:
      return initialDeviceDataSourceState
  }
}

export const dataSourcesReducer = (state = getDefaultState(), action = {}) => {
  const { payload, type } = action

  switch (type) {
    case CHANGE_DEVICE_DATA_SOURCE_STATE: {
      const { key, state: newState } = payload

      return {
        ...state,
        [key]: {
          ...state[key],
          state: newState,
        },
      }
    }
    case CLEAR_DEVICE_DATA_SOURCE_PARAMS: {
      const { key } = payload

      return {
        ...state,
        [key]: {
          ...state[key],
          data: getInitialParams(key),
        },
      }
    }
    case SET_DEVICE_DATA_SOURCES:
      return {
        ...state,
        [DATA_SOURCES_LIST]: {
          ...state[DATA_SOURCES_LIST],
          data: payload.dataSources,
        },
      }
    case LOAD_DEVICE_DATA_SOURCE_TO_UPDATE:
      return {
        ...state,
        [DATA_SOURCE_DETAILS]: {
          ...state[DATA_SOURCE_DETAILS],
          data: payload.dataSource,
        },
      }
    case LOAD_DEVICE_DATA_SOURCE_CONFIGURATION_TO_UPDATE:
      return {
        ...state,
        [DATA_SOURCE_CONFIGURATION]: {
          ...state[DATA_SOURCE_CONFIGURATION],
          errors: [],
          data: payload.dataSourceConfiguration,
        },
      }
    case CHANGE_UPDATE_DEVICE_DATA_SOURCE_STATE:
      return {
        ...state,
        updateDataSourceState: payload.state,
      }
    case CHANGE_RETRIEVE_DEVICE_DATA_SOURCE_CONFIGURATION_STATE:
      return {
        ...state,
        retrievingConfigurationState: payload.state,
      }
    case CHANGE_UPDATE_DEVICE_DATA_SOURCE_CONFIGURATION_STATE:
      return {
        ...state,
        updatingConfigurationState: payload.state,
      }
    case SET_DEVICE_DATA_TRANSFER_CONFIGURATION:
      return {
        ...state,
        [DATA_TRANSFER]: {
          ...state[DATA_TRANSFER],
          data: payload.dataTransfer,
        },
      }
    case SET_DEVICE_DATA_TRANSFER_ACTIVE_TRANSFER_METHOD:
      return {
        ...state,
        [DATA_SOURCE_DETAILS]: {
          ...state[DATA_SOURCE_DETAILS],
          data: {
            ...state[DATA_SOURCE_DETAILS].data,
            state: payload.state,
            transfer: payload.method,
          },
        },
      }
    case CHANGE_DEVICE_DATA_SOURCE_TRANSFER_STATE:
      return {
        ...state,
        [DATA_TRANSFER]: {
          ...state[DATA_TRANSFER],
          transferState: payload.state,
        },
      }
    case SET_DEVICE_DATA_SOURCE_CONFIGURATION_ERRORS:
      return {
        ...state,
        [DATA_SOURCE_CONFIGURATION]: {
          ...state[DATA_SOURCE_CONFIGURATION],
          errors: payload.errors,
        },
      }
    case ADD_RUNNING_MEASUREMENT:
      return {
        ...state,
        [RUNNING_MEASUREMENTS]: state[RUNNING_MEASUREMENTS].filter(
          ({ dataSourceId }) => dataSourceId !== payload.data.dataSourceId,
        ).concat(payload.data),
      }
    case REMOVE_RUNNING_MEASUREMENT:
      return {
        ...state,
        [RUNNING_MEASUREMENTS]: state[RUNNING_MEASUREMENTS].filter(
          ({ dataSourceId }) => dataSourceId !== payload.dataSourceId,
        ),
      }
    default:
      return state
  }
}
