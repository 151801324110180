import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  myEquipment: {
    id: 'equipment-inventory.myEquipment',
    defaultMessage: 'My Equipment',
  },
  home: {
    id: 'equipment-inventory.home',
    defaultMessage: 'Home',
  },
  fleetOverview: {
    id: 'equipment-inventory.fleetOverview',
    defaultMessage: 'Hardware',
  },
  failedToRetrieveYourEquipmentInventory: {
    id: 'equipment-inventory.failedToRetrieveYourEquipmentInventory',
    defaultMessage: 'Failed to retrieve your equipment inventory: {errorMessage}',
  },
  error: {
    id: 'equipment-inventory.error',
    defaultMessage: 'Error',
  },
})
