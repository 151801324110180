import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  types: {
    id: 'device-data-sources.types',
    defaultMessage: 'Types',
  },
  noDataSourcesFoundForThisDevice: {
    id: 'device-data-sources.noDataSourcesFoundForThisDevice',
    defaultMessage: 'No data sources found for this device.',
  },
  deviceId: {
    id: 'device-data-sources.deviceId',
    defaultMessage: 'Device ID',
  },
  name: {
    id: 'device-data-sources.name',
    defaultMessage: 'Name',
  },
  state: {
    id: 'device-data-sources.state',
    defaultMessage: 'State',
  },
  stopped: {
    id: 'device-data-sources.stopped',
    defaultMessage: 'Stopped',
  },
  started: {
    id: 'device-data-sources.started',
    defaultMessage: 'Started',
  },
  internalError: {
    id: 'device-data-sources.internalError',
    defaultMessage: 'Internal Error',
  },
  deviceError: {
    id: 'device-data-sources.deviceError',
    defaultMessage: 'Device Error',
  },
  changeOnDataSourceOccuredWithMethod: {
    id: 'device-data-sources.changeOnDataSourceOccured',
    defaultMessage:
      '"{dataSourceName}" on device "{deviceName}" has {state} data transfer using method {transferMethod}.',
  },
  changeOnDataSourceOccuredWithoutMethod: {
    id: 'device-data-sources.changeOnDataSourceOccuredWithoutMethod',
    defaultMessage: '"{dataSourceName}" on device "{deviceName}" has {state}.',
  },
  dataSourceTransferTitle: {
    id: 'device-data-sources.dataSourceTransferTitle',
    defaultMessage: 'Data Source Transfer',
  },
  selectADataSource: {
    id: 'device-data-sources.selectADataSource',
    defaultMessage: 'First select a data source to see or edit its details.',
  },
  subscribeAndNotify: {
    id: 'device-data-sources.subscribeAndNotify',
    defaultMessage: 'Subscribe & Notify',
  },
  activeTransfer: {
    id: 'device-data-sources.activeTransfer',
    defaultMessage: 'Active Transfer',
  },
  epoch: {
    id: 'device-data-sources.epoch',
    defaultMessage: 'Epoch',
  },
  timeScale: {
    id: 'device-data-sources.timeScale',
    defaultMessage: 'Time Scale',
  },
  encoding: {
    id: 'device-data-sources.encoding',
    defaultMessage: 'Encoding',
  },
  encodingPlaceholder: {
    id: 'device-data-sources.encodingPlaceholder',
    defaultMessage: 'Enter an encoding',
  },
  description: {
    id: 'device-data-sources.description',
    defaultMessage: 'Description',
  },
  descriptionPlaceholder: {
    id: 'device-data-sources.descriptionPlaceholder',
    defaultMessage: 'Enter a description',
  },
  update: {
    id: 'device-data-sources.update',
    defaultMessage: 'Update',
  },
  retrieve: {
    id: 'device-data-sources.retrieve',
    defaultMessage: 'Retrieve',
  },
  cannotUpdateDataSourceWhileDeviceOffline: {
    id: 'device-data-sources.cannotUpdateDataSourceWhileDeviceOffline',
    defaultMessage: 'Cannot update data source while device is offline.',
  },
  cannotStartDataTransferWhileDeviceOffline: {
    id: 'device-data-sources.cannotStartDataTransferWhileDeviceOffline',
    defaultMessage: 'Cannot start data transfer while device is offline.',
  },
  cannotUpdateTranferConfigurationWhileActiveTranfer: {
    id: 'device-data-sources.cannotUpdateTranferConfigurationWhileActiveTranfer',
    defaultMessage: 'Cannot update data source transfer configuration while there is an active data transfer.',
  },
  endpoints: {
    id: 'device-data-sources.endpoints',
    defaultMessage: 'Endpoints',
  },
  endpointsPlaceholder: {
    id: 'device-data-sources.endpointsPlaceholder',
    defaultMessage: 'Enter Endpoints',
  },
  zmqEndpoint: {
    id: 'device-data-sources.zmqEndpoint',
    defaultMessage: 'ZMQ endpoint',
  },
  zmqEndpointPlaceholder: {
    id: 'device-data-sources.zmqEndpointPlaceholder',
    defaultMessage: 'Enter ZMQ endpoint',
  },
  topic: {
    id: 'device-data-sources.topic',
    defaultMessage: 'Topic',
  },
  topicPlaceholder: {
    id: 'device-data-sources.topicPlaceholder',
    defaultMessage: 'Enter Topic',
  },
  timeTreshold: {
    id: 'device-data-sources.timeTreshold',
    defaultMessage: 'Time Threshold',
  },
  timeTresholdPlaceholder: {
    id: 'device-data-sources.timeTresholdPlaceholder',
    defaultMessage: 'Enter Time Threshold',
  },
  messageTreshold: {
    id: 'device-data-sources.messageTreshold',
    defaultMessage: 'Message Threshold',
  },
  messageTresholdPlaceholder: {
    id: 'device-data-sources.messageTresholdPlaceholder',
    defaultMessage: 'Enter Message Threshold',
  },
  endpointCannotBeEmpty: {
    id: 'device-data-sources.endpointCannotBeEmpty',
    defaultMessage: 'Endpoint cannot be an empty string.',
  },
  start: {
    id: 'device-data-sources.start',
    defaultMessage: 'Start',
  },
  stop: {
    id: 'device-data-sources.stop',
    defaultMessage: 'Stop',
  },
  paramIsOutOfRange: {
    id: 'device-data-sources.paramIsOutOfRange',
    defaultMessage: 'Param "{param}" is out of range. It must be between {min} and {max}.',
  },
  automatic: {
    id: 'device-data-sources.automatic',
    defaultMessage: 'Automatic',
  },
  brokerVerifyTimeout: {
    id: 'device-data-sources.brokerVerifyTimeout',
    defaultMessage: 'Broker verify timeout',
  },
  brokerVerifyTimeoutPlaceholder: {
    id: 'device-data-sources.brokerVerifyTimeoutPlaceholder',
    defaultMessage: 'Enter Broker Verify Timeout',
  },
  shareWithTenant: {
    id: 'device-data-sources.shareWithTenant',
    defaultMessage: 'Share data with users under my tenant',
    description: 'Share streamed data with all users under my tenant',
  },
  graph: {
    id: 'device-data-sources.graph',
    defaultMessage: 'Graph',
  },
  showGraph: {
    id: 'device-data-sources.showGraph',
    defaultMessage: 'Show graph',
  },
  kislerStreamNotSelectedSelected: {
    id: 'device-data-sources.kislerStreamNotSelectedSelected',
    defaultMessage: 'Encoding kistler.stream.v2 must be used in order to show a graph.',
  },
  measurementRunning: {
    id: 'device-data-sources.measurementRunning',
    defaultMessage: 'Measurement Running',
  },
  graphError: {
    id: 'device-data-sources.graphError',
    defaultMessage: 'Graph Error',
  },
  couldNotOpenGraphErrorMessage: {
    id: 'device-data-sources.couldNotOpenGraphErrorMessage',
    defaultMessage: 'Could not open graph. Check if your device is still online and try again.',
  },
  channel: {
    id: 'device-data-sources.channel',
    defaultMessage: 'Channel',
  },
  unknown: {
    id: 'device-data-sources.unknown',
    defaultMessage: 'Unknown',
  },
  customKafkaCertificate: {
    id: 'device-data-sources.customKafkaCertificate',
    defaultMessage: 'Custom Kafka certificate',
  },
  customKafkaCertificatePlaceholder: {
    id: 'device-data-sources.customKafkaCertificatePlaceholder',
    defaultMessage: 'Enter certificate',
  },
})
