import { combineReducers } from 'redux'
import { allEntriesReducerFactory } from '@/fleet-configuration/data-fleet/entries/all-entries-reducer-factory'
import { byIdReducerFactory } from '@/fleet-configuration/data-fleet/entries/by-id-reducer-factory'
import { entriesLoadedFactory } from '@/fleet-configuration/data-fleet/entries/entries-loaded-factory'

const UNDEFINED_ACTION = 'not match anything'

export const entriesReducerFactory = (
  addEntryAction,
  removeAllEntriesAction = UNDEFINED_ACTION,
  removeEntryAction = UNDEFINED_ACTION,
  areEntriesLoadedAction = UNDEFINED_ACTION,
  unloadEntriesAction = UNDEFINED_ACTION,
) =>
  combineReducers({
    loaded: entriesLoadedFactory(areEntriesLoadedAction, unloadEntriesAction),
    byId: byIdReducerFactory(addEntryAction, removeAllEntriesAction, removeEntryAction),
    allIds: allEntriesReducerFactory(addEntryAction, removeAllEntriesAction, removeEntryAction),
  })
