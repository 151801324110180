import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  notifications: {
    id: 'menu.notifications',
    defaultMessage: 'Notifications',
  },
  about: {
    id: 'menu.about',
    defaultMessage: 'About',
  },
  home: {
    id: 'menu.home',
    defaultMessage: 'Home',
  },
  settings: {
    id: 'menu.settings',
    defaultMessage: 'Settings',
  },
  usersPage: {
    id: 'menu.usersPage',
    defaultMessage: 'Users',
  },
  rolesPage: {
    id: 'menu.rolesPage',
    defaultMessage: 'Roles',
  },
  clientsPage: {
    id: 'menu.clientsPage',
    defaultMessage: 'Clients',
  },
  apisPage: {
    id: 'menu.apisPage',
    defaultMessage: 'APIs',
  },
  tenantsPage: {
    id: 'menu.tenantsPage',
    defaultMessage: 'Tenants',
  },
  oAuthManagementPage: {
    id: 'menu.oAuthManagementPage',
    defaultMessage: 'OAuth Management',
  },
  userManagement: {
    id: 'menu.userManagement',
    defaultMessage: 'User Management',
  },
  deviceManagement: {
    id: 'menu.deviceManagement',
    defaultMessage: 'Device Management',
  },
  devices: {
    id: 'menu.devices',
    defaultMessage: 'Devices',
  },
  productionUnits: {
    id: 'menu.productionUnits',
    defaultMessage: 'Production Units',
  },
  visualization: {
    id: 'menu.visualization',
    defaultMessage: 'Visualization',
  },
  identityManagement: {
    id: 'menu.identityManagement',
    defaultMessage: 'Identity Management',
  },
  iotHub: {
    id: 'menu.iotHub',
    defaultMessage: 'IoT Hub',
  },
  export: {
    id: 'menu.export',
    defaultMessage: 'Export',
  },
  licensing: {
    id: 'menu.licensing',
    defaultMessage: 'Licensing',
  },
  jBeam: {
    id: 'menu.jBeam',
    defaultMessage: 'jBeam',
  },
  fleetSideBar: {
    id: 'menu.fleet',
    defaultMessage: 'Hardware',
  },
  configuration: {
    id: 'menuConstants.configuration',
    defaultMessage: 'Configuration',
  },
  fleetConfigDevices: {
    id: 'menuConstants.fleetConfigDevices',
    defaultMessage: 'Devices',
  },
  configDevices: {
    id: 'menuConstants.configDevices',
    defaultMessage: 'Device Configuration',
  },
  presets: {
    id: 'menuConstants.presets',
    defaultMessage: 'Presets',
  },
  myEquipment: {
    id: 'menuConstants.myEquipment',
    defaultMessage: 'My Equipment',
  },
  akvisIO: {
    id: 'menu.akvisIO',
    defaultMessage: 'akvisIO',
  },
  management: {
    id: 'menu.management',
    defaultMessage: 'Management',
  },
  infoPage: {
    id: 'menu.infoPage',
    defaultMessage: 'Info',
  },
  howToUse: {
    id: 'menu.howToUse',
    defaultMessage: 'How to use?',
  },
  applications: {
    id: 'menu.applications',
    defaultMessage: 'Applications',
  },
})
