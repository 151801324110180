/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  serialNumber: {
    id: 'Serial number',
    defaultMessage: 'Serial number',
  },
  firmware: {
    id: 'Firmware',
    defaultMessage: 'Firmware',
  },
  model: {
    id: 'Model',
    defaultMessage: 'Model',
  },
})
