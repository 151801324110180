import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { TextBox } from '@/fleet-configuration/components/text-box/text-box'
import './sensitivity-textbox.scss'

export class SensitivityTextbox extends React.Component {
  static defaultProps = {
    disabled: false,
    className: '',
  }

  static propTypes = {
    disabled: PropTypes.bool,
    channel: PropTypes.object.isRequired,
    className: PropTypes.string,
  }

  formatSensitivity = value => {
    const { channel } = this.props
    if (channel.isChargeType() && !channel.isLabAmp() && value > 0) {
      return (-value).toString()
    }
    return value.toString()
  }

  removeSensitivityFormatting = value => {
    const unFormattedValue = value.replace(/(.)-/, '$1').replace(/[^0-9.-]/g, '')
    const { channel } = this.props
    return channel.isNegativeSensitivityAllowed() ? unFormattedValue : unFormattedValue.replace(/-/g, '')
  }

  render() {
    const { channel, disabled, className, ...rest } = this.props
    const value = channel.parameters.sensitivity
    return (
      <TextBox
        id="sensitivity"
        data-testid="sensitivity"
        type="number"
        fraction={5}
        value={value}
        negative={channel.isNegativeSensitivityAllowed()}
        prefix={channel.isLabAmp() && channel.isChargeType() && value > 0 ? '-' : ''}
        format={this.formatSensitivity}
        removeFormatting={this.removeSensitivityFormatting}
        disabled={disabled || channel.isMatchingMainTypeAndPhysicalQuantity()}
        {...rest}
        className={classNames(className, channel.isLabAmp() && 'labamp-sensitivity')}
      />
    )
  }
}
