import React, { useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { openConfirmModal } from 'skybase-oauth/actions'
import { SbButton } from 'skybase-ui/skybase-components/sb-button'
import { SbTab } from 'skybase-ui/skybase-components/sb-dynamic-tabs'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'
import { messages as a } from 'skybase-oauth/messages-i18n'

import { getIotHubDeviceOnlineState } from '@/iot-hub/selectors'
import { HintRenderer } from '@/common/hint-renderer'
import { useIsMounted } from '@/hooks/use-is-mounted'
import { showErrorToast, showSuccessToast } from '@/common/services/show-toast'
import { deleteDeviceApi } from '@/iot-hub/rest'
import { deleteTrialSimulatorApi } from '@/containers/home-page/components'
import { removeDeviceEntry } from '@/fleet-configuration/data-fleet/devices/devices-actions'

import { removeDevice } from './actions'
import { deviceStatuses } from './constants'
import { deviceShape } from './shapes'
import { messages as t } from './devices-i18n'
import classNames from 'classnames'

const { OFFLINE } = deviceStatuses

export const DeviceDeleteButton = ({ data, isInTable = false, trialSimulator = null }) => {
  const [deleting, setDeleting] = useState(false)
  const { formatMessage: _ } = useIntl()
  const dispatch = useDispatch()
  const isMounted = useIsMounted()
  const liveDeviceStatuses = useSelector(getIotHubDeviceOnlineState)

  const { status: statusFromApi, id, name } = data
  const status = liveDeviceStatuses?.[id] || statusFromApi

  const handleOpenConfirmModal = config => dispatch(openConfirmModal(config))
  const handleOnCloseModal = () => dispatch(closeModal())

  const renderWithHint = component => {
    return (
      <HintRenderer showHint hintData={_(t.removedAnOfflineDeviceFromKiCloud)}>
        {component}
      </HintRenderer>
    )
  }

  const handleDeleteDevice = useCallback(() => {
    handleOpenConfirmModal({
      title: _(t.removeDevice),
      message: (
        <>
          <div>{_(t.deleteDeviceDesc, { deviceName: name })}</div>
          <div>{_(t.deleteDeviceDesc2)}</div>
        </>
      ),
      confirmButtonTitle: _(t.remove),
      confirmButtonClassName: 'destructive',
      handleOnConfirm: () => {
        setDeleting(true)
        deleteDeviceApi(id)
          .then(() => {
            if (isMounted.current) {
              // Delete trial simulator if the deleted device was the one onboarded as trial
              if (trialSimulator?.id === id) {
                deleteTrialSimulatorApi()
              }

              setDeleting(false)
              showSuccessToast(_(t.deviceWasSuccessfullyRemoved, { deviceName: name }), _(a.success))

              dispatch(removeDevice(id))
              dispatch(removeDeviceEntry({ id }))
            }
          })
          .catch(error => {
            if (isMounted.current) {
              setDeleting(false)
              showErrorToast(
                _(t.failedToRemoveThisDevice, { errorMessage: error?.errors?.message ?? 'unknown.' }),
                _(a.error),
              )
            }
          })
        handleOnCloseModal()
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleOnCloseModal, handleOpenConfirmModal, id, isMounted, trialSimulator])

  const disabled = status !== OFFLINE || deleting

  if (isInTable) {
    return (
      <div>
        {renderWithHint(
          <span
            className={classNames('sbi-delete icon-link', { 'icon-disabled': disabled })}
            onClick={disabled ? () => {} : handleDeleteDevice}
          />,
        )}
      </div>
    )
  }

  return (
    <SbTab.Footer>
      <div id="device-details-footer" className="fl-col">
        {renderWithHint(
          <SbButton
            id="delete-device"
            className="destructive sb-width-100pct"
            disabled={disabled}
            loading={deleting}
            onClick={handleDeleteDevice}
          >
            {_(t.removeDevice)}
          </SbButton>,
        )}
      </div>
    </SbTab.Footer>
  )
}

DeviceDeleteButton.propTypes = {
  data: deviceShape.isRequired,
  isInTable: PropTypes.bool,
  trialSimulator: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
  }),
}
