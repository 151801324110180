import { messages as t } from '@/fleet-configuration/data-fleet/firmware/firmware-i18n'

export const getFirmwareUpdateReasonByCode = (code, _) => {
  switch (code) {
    case 0:
      return _(t.idle)
    case 2:
      return _(t.notEnoughRam)
    case 3:
      return _(t.notEnoughFlash)
    case 4:
      return _(t.connectionLost)
    case 5:
      return _(t.softwareValidationFailure)
    case 6:
      return _(t.invalidUrlToReceiveTheSoftwarePackage)
    case 7:
      return _(t.unsupportedProtocolForDownloadUrl)
    case 8:
      return _(t.firmwareUpdateFailed)
    default:
      return _(t.unknown)
  }
}
