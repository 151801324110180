import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'

import { MODULE_NAME } from '@/constants'

const getActionNameFactory = constant => getActionName(constant, MODULE_NAME)

export const ADD_NOTIFICATION = getActionNameFactory('ADD_NOTIFICATION')
export const addNotification = notification => createAction(ADD_NOTIFICATION, { notification })

export const VISIT_NOTIFICATION = getActionNameFactory('VISIT_NOTIFICATION')
export const visitNotification = id => createAction(VISIT_NOTIFICATION, { id })

export const VISIT_ALL_NOTIFICATIONS = getActionNameFactory('VISIT_ALL_NOTIFICATIONS')
export const visitAllNotifications = () => createAction(VISIT_ALL_NOTIFICATIONS)
