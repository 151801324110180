import React, { PureComponent } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { batchActions } from 'redux-batched-actions'

import { OAuth } from 'skybase-oauth'
import { messages as oa } from 'skybase-oauth/messages-i18n'
import { showErrorToastFactory, showSuccessToastFactory } from 'skybase-oauth/actions'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbButton } from 'skybase-ui/skybase-components/sb-button'
import { SbTab } from 'skybase-ui/skybase-components/sb-dynamic-tabs'

import { showWarningToast } from '@/common/services/show-toast'
import { WSClients } from '@/common/websocket/ws-clients'
import {
  getOcfCloudConfiguration,
  ownDeviceApi,
  disownDeviceApi,
  onboardDeviceApi,
  getDeviceAuthCodeApi,
  getDeviceApi,
  setDeviceResourceApi,
  getDeviceResourceApi,
} from '@/iot-hub/rest'
import { sleep, replaceDuplicateSlashes } from '@/utils'

import { getResourceWSID } from './resources/utils'
import {
  loadDeviceToUpdate,
  removeDevice,
  addDevice,
  updateDeviceData,
  updateDeviceParam,
  setOwningDeviceId,
  unsetOwningDeviceId,
} from './actions'
import { ownershipStatuses, cloudProvisioningStatuses, deviceStatuses, resourceStructures } from './constants'
import { deviceShape } from './shapes'
import { getCloudProvisioningStatusFromStatus, getDuplicatePIIDorSerialNumberRows, handleErrors } from './utils'
import { messages as t } from './devices-i18n'

const { OWNED, READY_TO_BE_OWNED, OWNED_BY_OTHER } = ownershipStatuses
const { UNINITIALIZED, FAILED, REGISTERED, REGISTERING, READYTOREGISTER } = cloudProvisioningStatuses
const { OFFLINE } = deviceStatuses
const { ARRAY } = resourceStructures

class _DeviceDetailsFooter extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    data: deviceShape.isRequired,
    list: PropTypes.arrayOf(deviceShape).isRequired,
    showError: PropTypes.func.isRequired,
    showSuccess: PropTypes.func.isRequired,
    handleRemoveDevice: PropTypes.func.isRequired,
    handleReplaceDevice: PropTypes.func.isRequired,
    handleUpdateDeviceData: PropTypes.func.isRequired,
    handleUpdateDeviceParam: PropTypes.func.isRequired,
    handleSetOwningDeviceId: PropTypes.func.isRequired,
    handleUnsetOwningDeviceId: PropTypes.func.isRequired,
    handleLoadDeviceToUpdate: PropTypes.func.isRequired,
    handleAllErrors: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      owning: false,
      disowning: false,
      onboarding: false,
    }
  }

  resourceWSKey = null

  isUnmounted = false

  onboardApiCalled = false

  onboardTimer = null

  maxOnboardingTimeMs = 20000

  componentWillUnmount() {
    this.isUnmounted = true
    this._cleanupWs()
    clearTimeout(this.onboardTimer)
  }

  _setOwningState = owning => {
    if (!this.isUnmounted) {
      this.setState({ owning })
    }
  }

  _setDisowningState = disowning => {
    if (!this.isUnmounted) {
      this.setState({ disowning })
    }
  }

  _setOnboardingState = onboarding => {
    if (!this.isUnmounted) {
      this.setState({ onboarding })
    }
  }

  _cleanupWs = () => {
    if (WSClients.ws[this.resourceWSKey]) {
      WSClients.removeFromWsClients(this.resourceWSKey)
      this.resourceWSKey = null
    }
  }

  handleTakeOwnership = async () => {
    const {
      data: { id, protocolIndependentId },
      showError,
      showSuccess,
      handleReplaceDevice,
      handleUpdateDeviceData,
      handleSetOwningDeviceId,
      handleUnsetOwningDeviceId,
      handleAllErrors,
    } = this.props
    let successMessage = t.deviceWasOwned
    handleSetOwningDeviceId(protocolIndependentId)
    handleSetOwningDeviceId(id)
    this._setOwningState(true)
    try {
      const { deviceId: newDeviceId } = await ownDeviceApi(id)

      // Wait for 2 seconds to give time for the device to be synchronized
      await sleep(2000)

      // DeviceID was changed, which means we have to replace this device with the on in the list and details view. We also fetch a new device to have it replaced in the details view and set in the table.
      if (newDeviceId !== id) {
        const newDevice = await getDeviceApi(newDeviceId)

        handleReplaceDevice(newDevice, id)
      } else {
        const updatedDevice = await getDeviceApi(id)

        handleUpdateDeviceData(updatedDevice)
      }

      handleUnsetOwningDeviceId(protocolIndependentId)
      handleUnsetOwningDeviceId(id)
      this._setOwningState(false)

      // If the SW_UPDATE_URL is available in the oAuthConfig, set it to the /oc/swu resource
      const swUpdateResource = `${newDeviceId}/oc/swu`
      const swUpdateUrl = OAuth.config?.SW_UPDATE_URL
      if (OAuth.isInLocalBackendMode && swUpdateUrl) {
        await sleep(3000)
        const canSetSwUpdateUrl = await getDeviceResourceApi({ resourcePath: swUpdateResource }).catch(e => {
          // ignore errors if resource does not exist
          if (e?.errors?.[0]?.message?.includes('Unavailable') || e?.status === 503) {
            return
          }

          successMessage = t.deviceWasOwnedButFailedToGetSWUResource
        })

        if (canSetSwUpdateUrl) {
          const now = new Date()
          await setDeviceResourceApi({
            resourcePath: swUpdateResource,
            data: {
              purl: `${swUpdateUrl}/${newDeviceId}`,
              swupdateaction: 'isac', // Initiate software availability check
              updatetime: new Date(now.getTime() + 10000).toISOString().split('.')[0] + 'Z', // Now + 10s
            },
          }).catch(() => {
            successMessage = t.deviceWasOwnedButFailedToUpdateSWUResource
          })
        }
      }

      showSuccess({ title: oa.success, message: successMessage })
    } catch (e) {
      handleUnsetOwningDeviceId(protocolIndependentId)
      handleUnsetOwningDeviceId(id)
      this._setOwningState(false)

      const errors = e?.errors
      if (errors) {
        handleAllErrors(errors)
      } else {
        showError({ title: oa.error, message: t.errorWhileOwningADevice })
      }
    }
  }

  handleResetOwnership = async () => {
    const {
      data: { id },
      showError,
      showSuccess,
      handleRemoveDevice,
      handleAllErrors,
    } = this.props

    this._setDisowningState(true)
    try {
      await disownDeviceApi(id)
      handleRemoveDevice(id)
      this._setDisowningState(false)
      showSuccess({ title: oa.success, message: t.deviceWasDisowned })
    } catch (e) {
      this._setDisowningState(false)

      const errors = e?.errors
      if (errors) {
        handleAllErrors(errors)
      } else {
        showError({ title: oa.error, message: t.errorWhileDisowningADevice })
      }
    }
  }

  handleDeviceOnboardListener = async (message, deviceId) => {
    const { handleUpdateDeviceData, showSuccess, showError, handleUpdateDeviceParam } = this.props
    const { cps, cloudProvisioningStatus: cpsFromApi, status } = JSON.parse(message.data)

    // "cps" only exists on iotivity-lite device (>3.0.1), In device 2.6.0 there is only "status" with codes present. We have to map these codes against the cloudProvisioningStatuses
    const cloudProvisioningStatus = cps ?? cpsFromApi ?? getCloudProvisioningStatusFromStatus(status)

    if ([REGISTERED, FAILED].includes(cloudProvisioningStatus)) {
      this._cleanupWs()

      // Wait a second
      await sleep(1000)

      // Fetch the device again to get its new data
      const updatedDevice = await getDeviceApi(deviceId)

      // Update device in state
      if (handleUpdateDeviceData) {
        handleUpdateDeviceData(updatedDevice)
      }

      // Onboard successfull
      if (cloudProvisioningStatus === REGISTERED && showSuccess) {
        showSuccess({ title: oa.success, message: t.deviceOnboarded })
        this._setOnboardingState(false)
      }

      // Onboard failed
      if (cloudProvisioningStatus === FAILED && showError) {
        showError({ title: oa.error, message: t.onboardingFailed })
        this._setOnboardingState(false)
      }

      clearTimeout(this.onboardTimer)
    } else if (handleUpdateDeviceParam) {
      handleUpdateDeviceParam(deviceId, { cloudProvisioningStatus })
    }
  }

  handleOnboard = async () => {
    const {
      data: { id },
      showError,
      handleAllErrors,
    } = this.props

    this.onboardApiCalled = false
    this._setOnboardingState(true)

    try {
      // Gather the configuration for onboarding
      const authCodeConfig = await getDeviceAuthCodeApi(id)
      const { code: authCode } = JSON.parse(authCodeConfig)
      const { id: cloudId, coapGateway: cloudUrl } = await getOcfCloudConfiguration()

      // Fetch the device with resource structure ARRAY in order to get all the resources of this device
      const { resources } = await getDeviceApi(id, ARRAY)
      const coapCloudConfResURI = resources.find(resource => resource.types.includes('oic.r.coapcloudconf'))?.href

      // If CoapCloudConfResURI resource is not found, throw an error
      if (!coapCloudConfResURI) {
        throw new Error('CoapCloudConfResURI resource not found, cannot subscribe to observe the onboarding status')
      }

      const onWsOpen = async () => {
        if (!this.onboardApiCalled) {
          // Wait 1 sec for initial messages to pass
          await sleep(1000)

          const authProviderName = OAuth.config?.AUTHORIZATION_PROVIDER_NAME
          // Call the onboard API. The api is async, so it only returns 204 when the Onboard process on device has been started
          await onboardDeviceApi(id, {
            authCode,
            cloudUrl,
            cloudId,
            ...(authProviderName ? { authProviderName } : {}),
          })

          this.onboardApiCalled = true
        }
      }

      // Connect to the CoapCloudConfResURI resource via WebSocket
      this.resourceWSKey = `${getResourceWSID(id, coapCloudConfResURI)}-onboarding`
      if (!WSClients.ws[this.resourceWSKey]) {
        WSClients.addToWsClients({
          name: this.resourceWSKey,
          endpoint: replaceDuplicateSlashes(`/ws/devices/${coapCloudConfResURI}`),
          listener: message => this.handleDeviceOnboardListener(message, id),
          delayListener: 500,
          onOpen: onWsOpen,
        })
      }

      // Timer for limiting the observation and onboarding process for a certain amount of time
      this.onboardTimer = setTimeout(async () => {
        const device = await getDeviceApi(id)

        if ([REGISTERED, FAILED].includes(device.cloudProvisioningStatus)) {
          // Call this method with a same argument structure as it would be emitted from WS event
          this.handleDeviceOnboardListener({ data: JSON.stringify(device) }, id)
        } else {
          showWarningToast(t.onboardingIsStuck, t.onboardingIsStuckTitle)
          this._setOnboardingState(false)
          this._cleanupWs()
        }
      }, this.maxOnboardingTimeMs)
    } catch (e) {
      this._setOnboardingState(false)

      const errors = e?.errors
      if (errors) {
        handleAllErrors(errors)
      } else {
        showError({ title: oa.error, message: t.errorWhileOnboardingADevice })
      }

      this._cleanupWs()
      clearTimeout(this.onboardTimer)
    }
  }

  renderDuplicateDeviceWarning = (duplicateDevices, duplicateDevicesCount) => {
    const {
      intl: { formatMessage: _ },
      handleLoadDeviceToUpdate,
    } = this.props

    const deviceNames = duplicateDevices
      .map(device => (
        <span className="link" onClick={() => handleLoadDeviceToUpdate(device)} key={`device-name-link-${device.id}`}>
          {device.name}
        </span>
      ))
      .reduce((accu, elem) => {
        return accu === null ? [elem] : [...accu, ` ${_(t.or)} `, elem]
      }, null)

    return (
      duplicateDevicesCount > 0 && (
        <div className="duplicate-devices-warning-box fl-row fl-align-items-center">
          <span className="icon m-r-20 sbi-big-warning" />
          <div className="message">
            <strong>{_(t.multipleDevicesHasTheSamePIIDorSerialNumber)}</strong>
            <div>
              <FormattedMessage
                id="devices.pleaseChangePIIDorSerialNumberToAvoidUnexpected"
                defaultMessage="Please change the PIID or Serial Number on {itemCount, plural, one {device} other {devices}} {deviceNames} to avoid unexpected behavior."
                values={{ itemCount: duplicateDevicesCount, deviceNames }}
              />
            </div>
          </div>
        </div>
      )
    )
  }

  render() {
    const { owning, disowning, onboarding } = this.state
    const {
      data,
      data: { isSecured, ownershipStatus, cloudProvisioningStatus, status },
      list,
      intl: { formatMessage: _ },
    } = this.props

    const duplicateDevices = !OAuth.isInNormalMode ? getDuplicatePIIDorSerialNumberRows(data, list) : []
    const duplicateDevicesCount = duplicateDevices.length
    const canOperate = isSecured && ownershipStatus !== OWNED_BY_OTHER

    if (duplicateDevicesCount === 0 && !canOperate) {
      return null
    }

    const resetOwnershipDisabled = owning || disowning || onboarding || status === OFFLINE
    const disabled = resetOwnershipDisabled || [REGISTERING, READYTOREGISTER].includes(cloudProvisioningStatus)

    return (
      <SbTab.Footer>
        {canOperate && (
          <div id="device-details-footer" className="fl-col">
            {ownershipStatus === READY_TO_BE_OWNED && (
              <SbButton
                id="take-device-ownership"
                className="confirm"
                disabled={disabled}
                loading={owning}
                onClick={this.handleTakeOwnership}
              >
                {_(t.takeOwnership)}
              </SbButton>
            )}

            {ownershipStatus === OWNED && (
              <SbButton
                id="reset-device-ownership"
                className="destructive"
                disabled={resetOwnershipDisabled}
                loading={disowning}
                onClick={this.handleResetOwnership}
              >
                {_(t.resetOwnership)}
              </SbButton>
            )}

            {OAuth.isInLocalBackendMode &&
              ownershipStatus === OWNED &&
              [UNINITIALIZED, FAILED].includes(cloudProvisioningStatus) && (
                <SbButton
                  id="onboard-device"
                  className="confirm m-t-10"
                  disabled={disabled}
                  loading={onboarding}
                  onClick={this.handleOnboard}
                >
                  {_(t.onboard)}
                </SbButton>
              )}
          </div>
        )}
        {!OAuth.isInNormalMode && this.renderDuplicateDeviceWarning(duplicateDevices, duplicateDevicesCount)}
      </SbTab.Footer>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showError: params => dispatch(showErrorToastFactory(params)),
    showSuccess: params => dispatch(showSuccessToastFactory(params)),
    handleRemoveDevice: deviceId => dispatch(removeDevice(deviceId)),
    handleReplaceDevice: (device, oldDeviceId) =>
      dispatch(
        batchActions([
          removeDevice(oldDeviceId), // Remove the old device from the list and details view
          addDevice(device), // Add new device to the list
          loadDeviceToUpdate(device), // Set the new device to the details view
        ]),
      ),
    handleUpdateDeviceData: device => dispatch(updateDeviceData(device)),
    handleUpdateDeviceParam: (deviceId, param) => dispatch(updateDeviceParam(deviceId, param)),
    handleSetOwningDeviceId: deviceId => dispatch(setOwningDeviceId(deviceId)),
    handleUnsetOwningDeviceId: deviceId => dispatch(unsetOwningDeviceId(deviceId)),
    handleLoadDeviceToUpdate: device => dispatch(loadDeviceToUpdate(device)),
    handleAllErrors: errors => handleErrors({ errors }, dispatch),
  }
}

export const DeviceDetailsFooter = injectIntl(connect(null, mapDispatchToProps)(_DeviceDetailsFooter))
