import { some, omit } from 'lodash'
import { ALERTS_ADD, ALERTS_REMOVE, ALERTS_STORE_RETURN_ACTIONS } from './actions'

export const alertsReducer = (state = [], action = {}) => {
  switch (action.type) {
    case ALERTS_ADD:
      return [...state, ...action.payload.alerts]
    case ALERTS_REMOVE:
      return state.filter(alert => !some(action.payload.alerts, id => id === alert.id))
    default:
      return state
  }
}

export const returnActionsReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case ALERTS_STORE_RETURN_ACTIONS:
      if (action.payload.id) {
        return {
          ...state,
          [action.payload.id]: action.payload.returnActions,
        }
      }
      break
    default:
      break
  }
  return state
}

const controlsReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case ALERTS_ADD:
      if (action.payload.id) {
        return { ...state, [action.payload.id]: action.payload.alerts.map(a => a.id) }
      }
      break
    case ALERTS_REMOVE:
      if (action.payload.alerts && action.payload.alerts.length > 0) {
        const control = state[action.payload.id]

        if (control) {
          const alerts = control.filter(currentId => !some(action.payload.alerts, id => id === currentId))
          if (alerts.size > 0) {
            return { ...state, [action.payload.id]: alerts }
          }
          return omit(state, action.payload.id)
        }

        const tuple = Object.values(state).find(ids => ids.some(id => some(action.payload.alerts, a => a === id)))

        if (tuple) {
          const [key, values] = tuple
          const alerts = values.filter(currentId => !some(action.payload.alerts, id => id === currentId))
          if (alerts.size > 0) {
            return { ...state, [key]: alerts }
          }
          return omit(state, key)
        }
      }
      break
    default:
      break
  }
  return state
}

export const getDefaultState = () => ({
  controls: {},
  alerts: [],
  returnActions: {},
})

export const validationReducer = (state = getDefaultState(), action = {}) => {
  switch (action.type) {
    case ALERTS_ADD:
    case ALERTS_REMOVE:
      return {
        ...state,
        alerts: alertsReducer(state.alerts, action),
        controls: controlsReducer(state.controls, action),
      }
    case ALERTS_STORE_RETURN_ACTIONS:
      return {
        ...state,
        returnActions: returnActionsReducer(state.returnActions, action),
      }
    default:
      break
  }
  return state
}
