import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  pageName: {
    id: 'digital-hub-home-page.dashboard',
    defaultMessage: 'Dashboard',
  },
  devices: {
    id: 'digital-hub-home-page.devices',
    defaultMessage: 'Devices',
  },
  deviceModules: {
    id: 'equipment.deviceModules',
    defaultMessage: 'Devices, Modules',
  },
  sensors: {
    id: 'digital-hub-home-page.sensors',
    defaultMessage: 'Sensors',
  },
  cables: {
    id: 'digital-hub-home-page.cables',
    defaultMessage: 'Cables',
  },
  myEquipment: {
    id: 'digital-hub-home-page.myEquipment',
    defaultMessage: 'My equipment',
  },
  deviceOnboarding: {
    id: 'digital-hub-home-page.deviceOnboarding',
    defaultMessage: 'Device onboarding',
  },
  issuesToPayAttention: {
    id: 'digital-hub-home-page.issuesToPayAttention',
    defaultMessage: 'Issues to pay attention',
  },
  documentation: {
    id: 'digital-hub-home-page.documentation',
    defaultMessage: 'Documentation',
  },
  online: {
    id: 'digital-hub-home-page.online',
    defaultMessage: 'Online',
  },
  offline: {
    id: 'digital-hub-home-page.offline',
    defaultMessage: 'Offline',
  },
  device: {
    id: 'digital-hub-home-page.device',
    defaultMessage: '{count, plural, one {device} other {devices}}',
  },
  failedToRetrieveYourEquipmentInventory: {
    id: 'digital-hub-home-page.failedToRetrieveYourEquipmentInventory',
    defaultMessage: 'Failed to retrieve your equipment inventory',
  },

  pleaseTryRefreshingThePageOrTryAccessingTheServiceLater: {
    id: 'digital-hub-home-page.pleaseTryRefreshingThePageOrTryAccessingTheServiceLater',
    defaultMessage: 'Please try refreshing the page or try accessing the service later',
  },
  error: {
    id: 'digital-hub-home-page.error',
    defaultMessage: 'Error',
  },
  startDeviceSimulator: {
    id: 'digital-hub-home-page.startDeviceSimulator',
    defaultMessage: 'Start device simulator',
  },
  stopDeviceSimulator: {
    id: 'digital-hub-home-page.stopDeviceSimulator',
    defaultMessage: 'Stop device simulator',
  },
  startDeviceSimHint: {
    id: 'digital-hub-home-page.startDeviceSimHint',
    defaultMessage:
      'A new item - so called device simulator - will be added into the list of the devices. {newLine}{newLine}You can use this device simulator for your experiments with KiCloud functionality without a need of the physical HW to be connected to the KiCloud.',
  },
  stopDeviceSimHint: {
    id: 'digital-hub-home-page.stopDeviceSimHint',
    defaultMessage: 'Deletes the device simulator you have previously created',
  },
  failure: {
    id: 'digital-hub-home-page.failure',
    defaultMessage: 'Failure',
  },
  failedToDeleteSimulator: {
    id: 'digital-hub-home-page.failedToDeleteSimulator',
    defaultMessage:
      'Failed to do a cleanup before starting a new device simulator. {newLine}Please try it again later or contact your administrator.',
  },
  failedToCreateSimulator: {
    id: 'digital-hub-home-page.failedToCreateSimulator',
    defaultMessage:
      'The new device simulator with name "{deviceName}" failed to start. {newLine}Please try it again later or contact your administrator.',
  },
  success: {
    id: 'digital-hub-home-page.success',
    defaultMessage: 'Success',
  },
  successToDeleteSimulator: {
    id: 'digital-hub-home-page.successToDeleteSimulator',
    defaultMessage: 'Device simulator was successfully deleted.',
  },
  successToCreateSimulator: {
    id: 'digital-hub-home-page.successToCreateSimulator',
    defaultMessage:
      'The new device simulator with name {deviceName} has been started. {newLine}{newLine}Go to {goToPageName} to see its details.',
  },
  onboardedDevices: {
    id: 'homePage.onboardedDevices',
    defaultMessage: 'Onboarded devices',
  },
  developerTools: {
    id: 'homePage.developerTools',
    defaultMessage: 'Developer tools',
  },
})
