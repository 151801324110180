import {
  SET_CALIBRATION_USER_DATA,
  SET_CALIBRATION_FORM_DATA,
  SET_CALIBRATION_FORM_DATA_ERRORS,
  SET_CALIBRATION_ITEM,
} from '@/fleet-configuration/data-fleet/calibration/calibration-actions'

export const calibrationReducer = (
  state = { userData: {}, formData: {}, formDataErrors: {}, calibrationItems: {} },
  action = {},
) => {
  switch (action.type) {
    case SET_CALIBRATION_USER_DATA:
      return { ...state, userData: { ...action.payload } }
    case SET_CALIBRATION_FORM_DATA: {
      const formData = action.payload ? { ...action.payload } : action.payload // allow null instead of object
      return { ...state, formData }
    }
    case SET_CALIBRATION_FORM_DATA_ERRORS:
      return { ...state, formDataErrors: { ...state.formDataErrors, ...action.payload } }
    case SET_CALIBRATION_ITEM: {
      return { ...state, calibrationItems: { ...state.calibrationItems, [action.payload.id]: action.payload } }
    }
    default:
      return state
  }
}
