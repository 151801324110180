import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'
import { getIotHubDeviceDataSourceRunningMeasurementsState, getIotHubDevicesListState } from '@/iot-hub/selectors'
import { deviceStatuses } from '@/iot-hub/components/devices/constants'
import { messages as t } from './datasources-i18n'

export const StreamingStatusPanel = ({ className = null }) => {
  const { formatMessage: _ } = useIntl()
  const runningMeasurements = useSelector(getIotHubDeviceDataSourceRunningMeasurementsState)
  const devices = useSelector(getIotHubDevicesListState)

  // Filter out the offline devices
  const runningOnlineMeasurements = runningMeasurements?.filter(({ deviceId }) => {
    return devices?.data?.some?.(({ id, status }) => id === deviceId && status === deviceStatuses.ONLINE)
  })

  const measurement = runningOnlineMeasurements?.[0]

  const handleClick = () => {
    const { deviceId, dataSourceId, method } = measurement
    SbEmitter.emit('navigate', `/iot-hub/devices/${deviceId}/datasources/${dataSourceId}`, {
      state: { method },
    })
  }

  if (!measurement) {
    return null
  }

  return (
    <>
      <span className="separator" />
      <div id="measurement-running" className={classNames(className)} onClick={handleClick}>
        <span className="bullet" />
        {_(t.measurementRunning)}
      </div>
    </>
  )
}

StreamingStatusPanel.propTypes = {
  className: PropTypes.string,
}
