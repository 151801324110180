import { getSnippetById } from '@/fleet-configuration/data-fleet/snippets/snippets-selectors'
import {
  wizardNavigationSelection,
  urlPathController,
} from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation-constants'

const { SELECTED, UNSELECTED } = wizardNavigationSelection

const getOpenSubpageSelection = (pathParams, snippet) => {
  const {
    originalDeviceConfiguration,
    snippetContent: { set },
  } = snippet
  let writableProperties
  switch (pathParams.length) {
    case 0:
      writableProperties = originalDeviceConfiguration.getWritableProperties()
      break
    case 1:
      if (pathParams[0] === urlPathController) {
        writableProperties = originalDeviceConfiguration.deviceSpecific.controller.getWritableProperties()
      } else {
        writableProperties = originalDeviceConfiguration.modules[pathParams[0]].getWritableProperties()
      }
      break
    case 2:
      {
        const module =
          pathParams[0] === urlPathController
            ? originalDeviceConfiguration.deviceSpecific.controller
            : originalDeviceConfiguration.modules[pathParams[0]]
        writableProperties = module.channels[pathParams[1]].getWritableProperties()
      }
      break
    default:
      throw new Error('Unexpected number of parameters: ' + pathParams.length)
  }
  return writableProperties.reduce((acc, item) => {
    acc[item] = set.includes(item) ? SELECTED : UNSELECTED
    return acc
  }, {})
}

export const configurationSnippetDetailSelector = (state, props) => {
  const {
    router: {
      params: { snippetId, isNew, moduleId, channelId },
    },
  } = props

  const snippet = getSnippetById(state, snippetId)
  // set all params and filter out unassigned
  const pathParams = [moduleId, channelId].filter(el => el)

  return {
    snippetId,
    isNew: isNew === 'new',
    snippet,
    moduleId,
    channelId,
    paramsSelected: snippet?.originalDeviceConfiguration ? getOpenSubpageSelection(pathParams, snippet) : undefined,
  }
}
