import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  pageName: {
    id: 'iot-hub-devices-page.pageName',
    defaultMessage: 'Devices',
  },
  details: {
    id: 'iot-hub-devices-page.details',
    defaultMessage: 'Details',
  },
})
