import React from 'react'
import PropTypes from 'prop-types'

export class ValidationGroup extends React.Component {
  static defaultProps = {
    getValidationRef: () => {},
    children: null,
  }

  static propTypes = {
    children: PropTypes.node,
    name: PropTypes.string.isRequired,
    getValidationRef: PropTypes.func,
  }

  static contextTypes = {
    formNames: PropTypes.arrayOf(PropTypes.string),
    registerValidator: PropTypes.func,
    unRegisterValidator: PropTypes.func,
  }

  static childContextTypes = {
    formNames: PropTypes.arrayOf(PropTypes.string),
    registerValidator: PropTypes.func,
    unRegisterValidator: PropTypes.func,
  }

  constructor(props, context) {
    super(props, context)
    this.state = { formName: props.name || 'form' }
    this.childValidators = []
  }

  getChildContext() {
    const { state, context } = this

    const registerValidator = validate => {
      if (!this.childValidators.includes(validate)) {
        this.childValidators.push(validate)
      }
    }

    const unRegisterValidator = validate => {
      const index = this.childValidators.indexOf(validate)
      if (index !== -1) {
        this.childValidators.splice(index, 1)
      }
    }

    const parentFormNames = context.formNames || []

    return {
      registerValidator,
      unRegisterValidator,
      formNames: [...parentFormNames, state.formName],
    }
  }

  componentDidMount() {
    const validate = () => {
      // wrap in setTimeout in case we wan't to call this in componentDidMount after
      // we loaded some data from the server so we work with fresh data
      setTimeout(() => {
        this.childValidators.forEach(validateChild => {
          validateChild()
        })
      })
    }
    const { getValidationRef } = this.props
    getValidationRef({
      validate,
    })
  }

  static getDerivedStateFromProps(props, state) {
    const { name } = props

    if (name.includes('_')) {
      throw new Error('Underscore is a reserved form name separator character. Please choose a different character.')
    }

    if (name !== state.formName) {
      return { formName: name }
    }

    return null
  }

  render() {
    const { children } = this.props
    return children
  }
}
