/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  calibration: {
    id: 'calibrationBox.calibration',
    defaultMessage: 'Calibration',
  },
  calibrationExplanationHint: {
    id: 'calibrationBox.calibrationExplanationHint',
    defaultMessage:
      'Why is calibration so important?{newLine}{newLine}Calibration verifies the precision and reproducibility of measurement instruments, such as sensors and measuring systems. {newLine}The Instruments that are calibrated are the prerequisite for precise, reliable and reproducible measurement results.{newLine}{newLine}For details, click on the icon and read more.',
  },
  orderCalibration: {
    id: 'calibrationBox.orderCalibration',
    defaultMessage: 'Order calibration',
  },
  error: {
    id: 'calibrationBox.error',
    defaultMessage: 'Error',
  },
  emailAddressProvidedIsNotValid: {
    id: 'calibrationBox.emailAddressProvidedIsNotValid',
    defaultMessage: 'Email address provided is not valid',
  },
  validationError: {
    id: 'calibrationBox.validationError',
    defaultMessage: 'Validation error',
  },
  formSubject: {
    id: 'calibrationBox.form.subject',
    defaultMessage: 'Customer request for',
  },
  formSubjectAdd: {
    id: 'calibrationBox.form.subject.add',
    defaultMessage: 'from KiCloud',
  },
  formRequestTypeCalibration: {
    id: 'calibrationBox.form.body.requestType.calibration',
    defaultMessage: 'Calibration',
  },
  calibrationServiceOrder: {
    id: 'calibrationBox.calibrationServiceOrder',
    defaultMessage: 'Calibration service order',
  },
  send: {
    id: 'calibrationBox.send',
    defaultMessage: 'Send',
  },
  email: {
    id: 'calibrationBox.email',
    defaultMessage: 'Email',
  },
  fullName: {
    id: 'calibrationBox.fullName',
    defaultMessage: 'Full Name',
  },
  company: {
    id: 'calibrationBox.company',
    defaultMessage: 'Company',
  },
  note: {
    id: 'calibrationBox.note',
    defaultMessage: 'Note',
  },
  cancel: {
    id: 'calibrationBox.cancel',
    defaultMessage: 'Cancel',
  },
  serialNumber: {
    id: 'calibrationBox.serialNumber',
    defaultMessage: 'Serial Number',
  },
  typeNumber: {
    id: 'calibrationBox.typeNumber',
    defaultMessage: 'Type Number',
  },
  calibrateAlsoItsModules: {
    id: 'calibrationBox.calibrateAlsoItsModules',
    defaultMessage: 'List of KiDAQ modules (please do not remove this information):{modules}',
  },
  calibrationModuleLine: {
    id: 'calibrationBox.calibrationModuleLine',
    defaultMessage: '#{index} T/N: {typeNumber}, S/N: {serialNumber}',
  },
})
