// TODO: somehow axios does not register with eslint - triggering error (even it it is node_modules hard dependency). Find out why
// eslint-disable-next-line import/no-unresolved
import axios from 'axios'
import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { fixateAllTypesInDevices } from '@/fleet-configuration/utils/fixate-all-types-in-devices'
import { loadDevicesCatalog } from '@/fleet-configuration/data-fleet/catalog/catalog-actions'
import { getProjectDeviceById } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import { guardedDeviceFactory } from '@/fleet-configuration/data-fleet/project-devices/device-factory'
import { getStudioAPIHost } from '@/utils/url'

export const SET_DEVICE_LAST_STATE = getActionName('SET_DEVICE_LAST_STATE')
export const setDeviceLastState = device => createAction(SET_DEVICE_LAST_STATE, device)

export const REMOVE_DEVICE_LAST_STATE = getActionName('REMOVE_DEVICE_LAST_STATE')
export const removeDeviceLastState = deviceId => createAction(REMOVE_DEVICE_LAST_STATE, { id: deviceId })

export const loadDeviceLastState = deviceId => async (dispatch, getState) => {
  const { data, status } = await axios
    .get(`${getStudioAPIHost()}/api/devices/${deviceId}/lastKnownState`)
    .catch(err => {
      console.error(err)
      return new Promise(resolve => {
        resolve({ data: undefined })
      })
    })

  if (status < 400) {
    const currentDeviceModel = getProjectDeviceById(getState(), deviceId)
    const newDeviceModel = data && data.id ? data : { ...currentDeviceModel, unknownState: true }
    fixateAllTypesInDevices([newDeviceModel])
    await dispatch(loadDevicesCatalog([newDeviceModel]))
    const device = guardedDeviceFactory(dispatch, getState, newDeviceModel)

    dispatch(setDeviceLastState(device.setDefaultsIfNotSet()))
  }
}
