import PropTypes from 'prop-types'

export const apiErrorShape = PropTypes.shape({
  namespace: PropTypes.string,
  message: PropTypes.string,
  severity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  time: PropTypes.string,
  type: PropTypes.string,
  details: PropTypes.object, // eslint-disable-line
})

export const apiErrorsShape = PropTypes.arrayOf(apiErrorShape)

export const matchRouteShape = PropTypes.shape({
  params: PropTypes.object, // eslint-disable-line
})
