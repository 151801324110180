/* eslint-disable no-template-curly-in-string, formatjs/no-multiple-whitespaces */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  samplingRateTitleTemplate: {
    id: 'Sampling rate {0}',
    defaultMessage: 'Sampling rate {0}',
  },
  measurementDevice: {
    id: 'Measurement Device',
    defaultMessage: 'Measurement Device',
  },
  name: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  nameIsRequired: {
    id: 'Name is required',
    defaultMessage: 'Name is required',
  },
  masterSamplingRate: {
    id: 'Master sampling rate',
    defaultMessage: 'Master sampling rate',
  },
  masterSamplingRateDescription: {
    id: 'Master sampling rate is the highest sampling rate and influences other. After setting master sampling rate, other sampling rates must divide master sampling rates without remainder (because of hardware clock synchronisation)',
    defaultMessage:
      'Master sampling rate is the highest sampling rate and influences other. After setting master sampling rate, other sampling rates must divide master sampling rates without remainder (because of hardware clock synchronisation)',
  },
  timeSynchronization: {
    id: 'Time Synchronization',
    defaultMessage: 'Time Synchronization',
  },
  manual: {
    id: 'Manual',
    defaultMessage: 'Manual',
  },
  ptp: {
    id: 'PTP',
    defaultMessage: 'PTP',
  },
  ntp: {
    id: 'NTP',
    defaultMessage: 'NTP',
  },
  domain: {
    id: 'Domain',
    defaultMessage: 'Domain',
  },
  ntpServerUrl: {
    id: 'NTP Server URL',
    defaultMessage: 'NTP Server URL',
  },
  domainTooltipDescription: {
    id: 'PTP domain defines a group of devices that interact with each other - this way you can control which devices are being synchronised together. Highest domain number is 127.',
    defaultMessage:
      'PTP domain defines a group of devices that interact with each other - this way you can control which devices are being synchronised together. Highest domain number is 127.',
  },
  ntpServerUrlTooltipDescription: {
    id: 'URL of your NTP time server',
    defaultMessage: 'URL of your NTP time server',
  },
  timeSynchronizationTooltipDescription: {
    id: 'Time synchronisation:\n\nPTP (Precision Time Protocol) achieves sub-microsecond clock accuracy,\n\nNTP (Network Time Protocol) synchronises device’s clock with external time server,\n\nManual sets arbitrary time on device (suitable for isolated system without network connection).',
    defaultMessage:
      'Time synchronisation:\n\nPTP (Precision Time Protocol) achieves sub-microsecond clock accuracy,\n\nNTP (Network Time Protocol) synchronises device’s clock with external time server,\n\nManual sets arbitrary time on device (suitable for isolated system without network connection).',
  },
  deviceNameNotAvailable: {
    id: 'Device name not available',
    defaultMessage: 'Device name not available',
  },
  online: {
    id: 'measurementUnitDetail.online',
    defaultMessage: 'Online',
  },
  offline: {
    id: 'measurementUnitDetail.offline',
    defaultMessage: 'Offline',
  },
  smartDeviceSettings: {
    id: 'measurementUnitDetail.smartDeviceSettings',
    defaultMessage: 'Smart device settings',
  },
  samplingRateTooLow: {
    id: 'measurementUnitDetailContent.thisSamplingRateCouldBeTooLowForTheSpecifiedHighestFrequencyOfInterestSuchThatAnAliasFreeDataAcquisitionIsNotEnsuredAnymore',
    defaultMessage:
      'This sampling rate could be too low for the specified highest frequency of interest such that an alias-free data acquisition is not ensured anymore.',
  },
  samplingRateTooHigh: {
    id: 'measurementUnitDetailContent.thisSamplingRateCouldBeHigherThanNecessaryForTheSpecifiedHighestFrequencyOfInterestSuchThatTheDataVolumeCouldRedundantlyBeIncreased',
    defaultMessage:
      'This sampling rate could be higher than necessary for the specified highest frequency of interest such that the data volume could redundantly be increased.',
  },
})
