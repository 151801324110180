import { resetDelayListenersFor } from './constants'

// Set the delayListener to -1 when the app is in Local Mode, based on the provided list of delayed listeners.
export const resetDelayListeners = clientList => {
  return Object.keys(clientList).reduce((list, id) => {
    const ws = clientList[id]

    list[id] = {
      ...ws,
      delayListener: resetDelayListenersFor.includes(id) ? -1 : ws.delayListener,
    }
    return list
  }, {})
}
