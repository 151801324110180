export const ADD_COMPONENT_MODAL_ID = 'addComponentModalId'

export const addComponentModalPossibleComponents = {
  SENSOR: 'sensor',
  CABLE: 'cable',
}

export const addComponentModalTabs = {
  EQUIPMENT_TAB: 'equipment-tab',
  CATALOG_TAB: 'catalog-tab',
}
