import { setAlerts } from '@/fleet-configuration/validation/actions'
import { getControlId } from '@/fleet-configuration/components/get-control-id'
import { handleValidation } from 'skybase-ui/skybase-core/validation/handle-validation'

export class ComponentValidator {
  constructor(component) {
    this.component = component
    this.handleOnValidated(this.handleOnValidate(this.component.props.value).filter(alert => alert))
  }

  componentDidMount = () => {
    const { getValidationRef } = this.component.props
    this.revalidate = () => {
      const validationResult = handleValidation(this.component.props.value, this.validate)
      this.handleOnValidated(validationResult)
    }
    if (this.component.context && this.component.context.registerValidator) {
      this.component.context.registerValidator(this.revalidate)
    }
    getValidationRef({
      validate: this.revalidate,
    })
  }

  componentWillUnmount = () => {
    if (this.component.context && this.component.context.unRegisterValidator) {
      this.component.context.unRegisterValidator(this.revalidate)
    }
  }

  validate = value => {
    const { validators } = this.component.props
    return validators.map(validator => validator(value))
  }

  handleOnValidate = value => this.validate(value)

  handleOnValidated = alerts => {
    const { dispatch, id, validators } = this.component.props
    if (validators.length) {
      dispatch(setAlerts(alerts, getControlId(this.component.context, id)))
    }
  }

  handleOnChange = () => {
    this.executeDependentValidators()
  }

  executeDependentValidators = () => {
    const { dependentValidators } = this.component.props
    setTimeout(() => {
      dependentValidators.forEach(dependentValidator => {
        if (dependentValidator) {
          dependentValidator()
        }
      })
    })
  }
}
