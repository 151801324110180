/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  reloadDeviceConfiguration: {
    id: 'reloadSettingsButton.reloadDeviceConfiguration',
    defaultMessage: 'Reload device configuration',
  },
  areYouSureReplaceDeviceConfiguration: {
    id: 'reloadSettingsButton.areYouSureYouWantToRemoveAllConfigurationYouHaveDoneAndReplaceItWithDeviceConfiguration',
    defaultMessage:
      'Are you sure you want to remove all configuration you have done and replace it with device configuration?',
  },
  yourConfigurationWasReplacedWithDeviceConfiguration: {
    id: 'reloadSettingsButton.yourConfigurationWasReplacedWithDeviceConfiguration',
    defaultMessage: 'Your configuration was replaced with device configuration',
  },
  removeCustomConfigurationAndReplaceItWithConfigurationOfDevice: {
    id: 'reloadSettingsButton.removeCustomConfigurationAndReplaceItWithConfigurationOfDevice',
    defaultMessage: 'Remove custom configuration and replace it with configuration of device',
  },
  deviceIsOffline: {
    id: 'reloadSettingsButton.deviceIsOffline',
    defaultMessage: 'Device is offline',
  },
  readSettingsFromDevice: {
    id: 'reloadSettingsButton.readSettingsFromDevice',
    defaultMessage: 'Read Settings From Device',
  },
  thisActionCannotBeUndone: {
    id: 'reloadSettingsButton.thisActionCannotBeUndone',
    defaultMessage: 'This action cannot be undone.',
  },
  replaceConfiguration: {
    id: 'reloadSettingsButton.replaceConfiguration',
    defaultMessage: 'Replace configuration',
  },
  reloadingOfDeviceFailedTryToPutThisDeviceIntoFactoryReset: {
    id: 'reloadSettingsButton.reloadingOfDeviceFailedTryToPutThisDeviceIntoFactoryReset',
    defaultMessage: 'Reloading of device failed. Try to put this device into factory reset',
  },
  performingDeviceConfigurationBackupBeforeIsRecommended: {
    id: 'reloadSettingsButton.performingDeviceConfigurationBackupBeforeIsRecommended',
    defaultMessage: 'Performing device configuration backup before is recommended.',
  },
})
