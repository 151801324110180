import { SET_EULA_STATUS } from '@/common/eula/eula-actions'

export const eulaReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_EULA_STATUS:
      return action.payload
    default:
      return state
  }
}
