import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { MeasurementButtons } from '@/fleet-configuration/pages/measurement-screen/measurement-buttons/measurement-buttons'
import { UnitIllustration } from '@/fleet-configuration/page-components/unit-illustration/unit-illustration'
import { MeasurementUnitDetailContent } from '@/fleet-configuration/page-components/measurement-unit/measurement-unit-detail-content'
import { withRouter } from '@/common/router'
import { ConfigScreenLayout } from '../config-screen-layout/config-screen-layout'
import { measurementUnitDetailSelector } from './measurement-unit-detail-selector'
import { GREEN, RED, StatusBullet } from '@/common/status-bullet'
import { messages as t } from './measurement-unit-detail-i18n'
import './measurement-unit-detail.scss'
import { MeasurementUnitDetailChannelSmartValues } from '@/fleet-configuration/page-components/measurement-unit/measurement-unit-detail-channel-smart-values'

const _MeasurementUnitDetail = ({ intl: { formatMessage: _ }, device = null, deviceName = null }) => {
  if (!device) {
    return null
  }

  return (
    <ConfigScreenLayout
      title={
        <>
          {_(t.smartDeviceSettings)}
          <MeasurementButtons deviceId={device.id} />
        </>
      }
      illustration={
        <>
          <h3 className="config-subtitle">
            <StatusBullet title={device.online ? _(t.online) : _(t.offline)} status={device.online ? GREEN : RED} />
            {deviceName ? <p className="component-name">{deviceName}</p> : _(t.measurementDevice)}
          </h3>
          <UnitIllustration />
          <MeasurementUnitDetailContent measurementDevice={device} />
        </>
      }
      parameters={<MeasurementUnitDetailChannelSmartValues device={device} />}
    />
  )
}

_MeasurementUnitDetail.propTypes = {
  device: PropTypes.object,
  deviceName: PropTypes.string,
  intl: intlShape.isRequired,
}

export const MeasurementUnitDetail = withRouter(
  injectIntl(connect(measurementUnitDetailSelector)(_MeasurementUnitDetail)),
)
