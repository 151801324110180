import { getDeviceById } from '@/fleet-configuration/data-fleet/devices/devices-selectors'
import { isValid } from '@/fleet-configuration/validation/is-valid'
import { getProjectDeviceById } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import { getProjectDevicePreviousNTPServer } from '@/fleet-configuration/data-fleet/devices-last-state/devices-last-state-selectors'
import { selectMeasurementUnitParams } from '../../select-measurement-unit-params'
import { getChainsByModuleDetails } from '@/fleet-configuration/data-fleet/chain/chain-selectors'

export const measurementUnitDetailSelector = (state, props) => {
  let device
  let deviceName
  if (props.measurementDevice !== undefined) {
    device = props.measurementDevice
  } else {
    const { unitId } = selectMeasurementUnitParams(state, props)
    device = getProjectDeviceById(state, unitId)
  }

  let deviceLastStateNTPServerUrl
  let deviceSamplingRates
  let mainSamplingRateOptions = []
  let compatibleSamplingRateOptions = []
  let isValidDevice = true

  let chainSamplingRate = null
  if (device) {
    // get device name from project-agnostic device
    deviceName = getDeviceById(state, device?.id)?.name
    deviceLastStateNTPServerUrl = getProjectDevicePreviousNTPServer(state, device.id)
    isValidDevice = isValid(state, `Device-${device.id}`)

    if (device.modelNumber) {
      deviceSamplingRates = device.samplingRates
      mainSamplingRateOptions = device.getMainSamplingRateOptions().sort((a, b) => a.value - b.value)
      if (deviceSamplingRates.length > 1) {
        compatibleSamplingRateOptions = device.getCompatibleSamplingRateOptions()
      }
    }
    if (device.isLabAmp()) {
      const moduleTypeNumber = device.modules[0].getType()
      const moduleSerialNumber = device.modules[0].parametersReadable?.snr || device.serialNumber
      const chainMaxCutOffFrequency = getChainsByModuleDetails(state, moduleTypeNumber, moduleSerialNumber).reduce(
        (acc, chainItem) => Math.max(acc, parseFloat(chainItem?.userPreferences?.cutOffFrequency) || 0),
        0,
      )
      chainSamplingRate =
        chainMaxCutOffFrequency &&
        (
          mainSamplingRateOptions.find(samplingRate => samplingRate.value >= chainMaxCutOffFrequency * 10) ||
          mainSamplingRateOptions.slice(-1)[0]
        )?.value
    }
  }

  return {
    device,
    deviceName,
    deviceLastStateNTPServerUrl,
    deviceSamplingRates,
    isValidDevice,
    mainSamplingRateOptions,
    compatibleSamplingRateOptions,
    chainSamplingRate,
  }
}
