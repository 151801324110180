import axios from 'axios'
import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { getStudioAPIHost } from '@/utils/url'
import { getFirmwareData } from '@/fleet-configuration/data-fleet/firmware/firmware-selectors'
import { getActiveProjectId } from '@/fleet-configuration/data-fleet/projects/projects-selectors'
import { showErrorToast } from '@/common/services/show-toast'
import { messages as t } from './firmware-actions-i18n'

export const SET_FIRMWARE_DATA = getActionName('SET_FIRMWARE_DATA')
export const setFirmwareData = (deviceId, firmwareData) => createAction(SET_FIRMWARE_DATA, { deviceId, firmwareData })

export const SET_FIRMWARE_UPDATE_STATUS = getActionName('SET_FIRMWARE_UPDATE_STATUS')
export const setFirmwareUpdateStatus = (deviceId, status) =>
  createAction(SET_FIRMWARE_UPDATE_STATUS, { deviceId, status })

export const loadFirmware = deviceId => async dispatch => {
  try {
    const { data } = await axios.get(`${getStudioAPIHost()}/api/devices/${deviceId}/firmware`)
    dispatch(
      setFirmwareData(deviceId, {
        version: data.firmwareVersion,
        state: data.versionState,
        newVersion: data.newFirmwareVersion,
      }),
    )
  } catch (err) {
    dispatch(setFirmwareData(deviceId, null))
  }
}

const firmwaresBeingLoaded = {}
export const loadFirmwareIfEmpty = deviceId => (dispatch, getState) => {
  const data = getFirmwareData(getState(), deviceId) || firmwaresBeingLoaded[deviceId]
  if (data) {
    return data
  }
  const loadResult = dispatch(loadFirmware(deviceId))
  // remove duplicate load request when this method is called during ongoing request
  if (loadResult.then) {
    firmwaresBeingLoaded[deviceId] = loadResult
    const removeFirmwareLoadingCache = () => {
      delete firmwaresBeingLoaded[deviceId]
    }
    loadResult.then(removeFirmwareLoadingCache, removeFirmwareLoadingCache)
  }
  return loadResult
}

export const updateFirmware = deviceId => (dispatch, getState) => {
  const projectId = getActiveProjectId(getState())
  return axios.post(
    `${getStudioAPIHost()}/api/devices/${deviceId}/firmware`,
    {},
    {
      headers: {
        'initiator-project': projectId,
      },
      customErrorHandler: (error, localDispatch, handleDefaultHttpError) => {
        if (error.response?.status === 403) {
          showErrorToast(t.youDontHavePermissionsToPerformRemoteFirmwareUpdateOnADevice)
          return
        }
        handleDefaultHttpError(error)
      },
    },
  )
}
