import { configureStore } from '@reduxjs/toolkit'
import { thunk } from 'redux-thunk'
import { enableBatching } from 'redux-batched-actions'

// Middlewares required by SkyBase UI.
import { redirectMiddleware } from 'skybase-ui/skybase-core/middlewares/redirect-middleware'
import { localeMiddleware } from 'skybase-ui/skybase-core/locales/middlewares'
import { downloadFileMiddleware } from 'skybase-ui/skybase-core/middlewares/download-file-middleware'
import { oauthMiddleware } from 'skybase-oauth'

import { createAppReducer } from '../reducers'
import { history } from './history'

export const createAppStore = (initialState = {}) =>
  configureStore({
    preloadedState: initialState,
    reducer: enableBatching(createAppReducer(history)),
    middleware: getDefaultMiddleware => {
      const middlewares = [
        redirectMiddleware,

        ...getDefaultMiddleware({
          serializableCheck: false,
          immutableCheck: false,
        }),

        // Skybase-Middlewares
        localeMiddleware,
        downloadFileMiddleware,

        oauthMiddleware,

        // 3d party:
        thunk,
      ]
      if (process?.env?.NODE_ENV === 'development') {
        const { loggerMiddleware } = require('@/middlewares') // eslint-disable-line global-require

        middlewares.push(loggerMiddleware)
      }
      return middlewares
    },
  })

const store = createAppStore()

// Set the store to the window object so, that it is accessible from the browser console only when in development
if (process?.env?.NODE_ENV === 'development') {
  window.store = store
}

export default store
