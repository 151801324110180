import { OAuth } from 'skybase-oauth'

import { licensingApi, licensingErrorCodes } from './constants'

const { fetchOAuth } = OAuth
const {
  LICENSES_OF_USER,
  LICENSES_OF_TICKET,
  ASSIGN_LICENSES_TO_USER,
  REMOVE_LICENSES_FROM_USER,
  AUTO_UPDATE_USER_CONTAINER,
} = licensingApi
const { NO_ERROR, ERROR_NO_LICENSE } = licensingErrorCodes

const resolvePromise = response => Promise.resolve(response)

const rejectPromise = response => Promise.reject(response)

// userId represents tenantId in our application
export const getLicensesOfUserApi = userId =>
  fetchOAuth(`${OAuth.config.API_ENDPOINT_URL}${LICENSES_OF_USER}`, {
    method: 'POST',
    body: JSON.stringify({
      userIdentification: userId,
    }),
  }).then(response => {
    const { errorCode, licenseInformation } = response

    // Middleware for checking errors which can be ignored
    if (errorCode !== NO_ERROR) {
      switch (errorCode) {
        // No license was assigned to this user (tenant), return an empty list.
        case ERROR_NO_LICENSE:
          return resolvePromise(licenseInformation)
        default:
          return rejectPromise(response)
      }
    }

    return resolvePromise(licenseInformation)
  })

export const getLicensesOfTicketApi = ticketId =>
  fetchOAuth(`${OAuth.config.API_ENDPOINT_URL}${LICENSES_OF_TICKET}`, {
    method: 'POST',
    body: JSON.stringify({
      ticketIdentification: ticketId,
    }),
  }).then(response => {
    const { errorCode, licenseInformation } = response

    // Middleware for checking errors which can be ignored
    if (errorCode !== NO_ERROR) {
      switch (errorCode) {
        default:
          return rejectPromise(response)
      }
    }

    return resolvePromise(licenseInformation)
  })

export const assignLicensesToUserApi = ({ ticketId, userId, licenseIds }) =>
  fetchOAuth(`${OAuth.config.API_ENDPOINT_URL}${ASSIGN_LICENSES_TO_USER}`, {
    method: 'POST',
    body: JSON.stringify({
      ticketIdentification: ticketId,
      userIdentification: userId,
      ticketLicenseIds: licenseIds,
    }),
  }).then(response => {
    const { errorCode, licenseInformation } = response

    // Middleware for checking errors which can be ignored
    if (errorCode !== NO_ERROR) {
      switch (errorCode) {
        default:
          return rejectPromise(response)
      }
    }

    return resolvePromise(licenseInformation)
  })

export const removeLicensesFromUserApi = ({ ticketId, userId, licenseIds }) =>
  fetchOAuth(`${OAuth.config.API_ENDPOINT_URL}${REMOVE_LICENSES_FROM_USER}`, {
    method: 'POST',
    body: JSON.stringify({
      ticketIdentification: ticketId,
      userIdentification: userId,
      ticketLicenseIds: licenseIds,
    }),
  }).then(response => {
    const { errorCode } = response

    // Middleware for checking errors which can be ignored
    if (errorCode !== NO_ERROR) {
      switch (errorCode) {
        default:
          return rejectPromise(response)
      }
    }

    return resolvePromise()
  })

// userId represents tenantId in our application
export const autoUpdateUserContainerApi = userId =>
  fetchOAuth(`${OAuth.config.API_ENDPOINT_URL}${AUTO_UPDATE_USER_CONTAINER}`, {
    method: 'POST',
    body: JSON.stringify({
      userIdentification: userId,
    }),
  }).then(response => {
    const { errorCode, success } = response

    // Middleware for checking errors which can be ignored
    if (errorCode !== NO_ERROR) {
      switch (errorCode) {
        default:
          return rejectPromise(response)
      }
    }

    return resolvePromise(success)
  })
