import { sortBy } from 'lodash'
import { createSbError } from 'skybase-ui/skybase-core/errors/create-sb-error'
import { ALERT_TYPE_INFO, ALERT_TYPE_WARNING, ALERT_TYPE_ERROR } from './constants'

const getSortByValue = ({ type }) => {
  switch (type) {
    case ALERT_TYPE_INFO:
      return 1
    case ALERT_TYPE_WARNING:
      return 2
    case ALERT_TYPE_ERROR:
      return 3
    default:
      throw createSbError(`Unknown alert type: ${type}`)
  }
}

export const sortAlerts = alerts => sortBy(alerts, getSortByValue)
