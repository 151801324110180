import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbCheckbox } from 'skybase-ui/skybase-components/sb-checkbox'

import { getIotHubDevicesState } from '@/iot-hub/selectors'
import { enableDeviceStatusNotifications } from './actions'
import { messages as t } from './devices-i18n'

export class _DevicesStatusNotificationToggle extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    enableNotifications: PropTypes.bool.isRequired,
    handleOnEnableDeviceStatusNotifications: PropTypes.func.isRequired,
  }

  handleOnToggleNotifications = () => {
    const { enableNotifications, handleOnEnableDeviceStatusNotifications } = this.props

    handleOnEnableDeviceStatusNotifications(!enableNotifications)
  }

  render() {
    const {
      intl,
      intl: { formatMessage: _ },
      enableNotifications,
      handleOnEnableDeviceStatusNotifications,
      ...rest
    } = this.props

    return (
      <SbCheckbox
        {...rest}
        type="switch-control"
        onClick={this.handleOnToggleNotifications}
        checked={enableNotifications}
      >
        {_(t.notifications)}
      </SbCheckbox>
    )
  }
}

const mapStateToProps = state => {
  const { enableNotifications } = getIotHubDevicesState(state)

  return {
    enableNotifications,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleOnEnableDeviceStatusNotifications: enable => dispatch(enableDeviceStatusNotifications(enable)),
  }
}

export const DevicesStatusNotificationToggle = injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(_DevicesStatusNotificationToggle),
)
