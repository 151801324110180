import { castArray } from 'lodash'

export const getControlId = (context, id) => {
  if (context && context.formNames) {
    if (!context.formNames.length) {
      return [id]
    }
    const firstFormNames = castArray(context.formNames[0])
    const formNameVariants = firstFormNames.map(firstFormName => [firstFormName, ...context.formNames.slice(1)])
    return formNameVariants.map(formNames => formNames.join('_') + id)
  }

  return [id]
}
