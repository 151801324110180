import React from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import { map } from 'lodash'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbButtonBar } from 'skybase-ui/skybase-components/sb-button-bar/sb-button-bar'
import { SbDropdown } from 'skybase-ui/skybase-components/sb-dropdown/sb-dropdown'
import { SbRadioButton } from 'skybase-ui/skybase-components/sb-radio-button'
import { SbCheckbox } from 'skybase-ui/skybase-components/sb-checkbox/sb-checkbox'
import { SbInlineMessage } from 'skybase-ui/skybase-components/sb-inline-message'
import { INLINE_MESSAGE_TYPE_INFO } from 'skybase-ui/skybase-components/sb-inline-message/constants'
import { Label } from '@/fleet-configuration/components/form/label'
import { TitleWithTooltip } from '@/fleet-configuration/components/title-with-tooltip/title-with-tooltip'
import { deviceTimeSyncModes } from '@/fleet-configuration/data-fleet/device-online-status/device-online-status-constants'
import { TextBox } from '@/fleet-configuration/components/text-box/text-box'
import { MeasurementUnitNTPError } from '@/fleet-configuration/page-components/measurement-unit/measurement-unit-ntp-error'
import { ValidationGroup } from '@/fleet-configuration/components/form/validation-group'
import { wizardNavigationSelection } from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation-constants'
import { measurementUnitDetailSelector } from '@/fleet-configuration/page-components/measurement-unit/measurement-unit-detail-selector'
import {
  saveProjectDevice,
  setChannelAlerts,
  setProjectDevice,
} from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { loadDevicesIfEmpty } from '@/fleet-configuration/data-fleet/devices/devices-actions'
import { loadChainIfEmpty } from '@/fleet-configuration/data-fleet/chain/chain-actions'
import { getNextCharFactory } from '@/utils/get-next-char'
import { toCssString } from '@/utils/sanitizer'
import { withRouter } from '@/common/router'
import { messages as t } from '@/fleet-configuration/page-components/measurement-unit/measurement-unit-detail-i18n'

const { SELECTED, UNSELECTED } = wizardNavigationSelection

class _MeasurementUnitDetailContent extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    isValidDevice: PropTypes.bool.isRequired,
    device: PropTypes.object,
    deviceName: PropTypes.string,
    deviceLastStateNTPServerUrl: PropTypes.string,
    deviceSamplingRates: PropTypes.array,
    mainSamplingRateOptions: PropTypes.array,
    compatibleSamplingRateOptions: PropTypes.array,
    computeDirtyState: PropTypes.bool,
    paramsSelected: PropTypes.object,
    paramSelectionChange: PropTypes.func,
    chainSamplingRate: PropTypes.number,
  }

  static defaultProps = {
    device: null,
    deviceSamplingRates: null,
    mainSamplingRateOptions: null,
    compatibleSamplingRateOptions: null,
    deviceName: null,
    deviceLastStateNTPServerUrl: null,
    computeDirtyState: true,
    paramsSelected: {},
    paramSelectionChange: null,
    chainSamplingRate: null,
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(loadDevicesIfEmpty())
    dispatch(loadChainIfEmpty())
  }

  handleParamsSelectionChange = (checked, name) => {
    const { paramSelectionChange } = this.props
    const writeValue = checked ? SELECTED : UNSELECTED
    paramSelectionChange(writeValue, name)
  }

  handleOnChange = updateDefinition => {
    const updatedDevice = this.updateDevice(updateDefinition)
    this.validateDevice(updatedDevice)
    return updatedDevice
  }

  handleOnPersistedChange = updateDefinition => {
    const updatedDevice = this.updateDevice(updateDefinition)
    this.saveDevice(updatedDevice)
    this.validateDevice(updatedDevice)
  }

  handleOnBlur = () => {
    this.saveDevice()
  }

  updateDevice = updateDefinition => {
    const { dispatch, device } = this.props
    let updatedDevice = update(device, updateDefinition)
    updatedDevice = dispatch(setProjectDevice(updatedDevice))
    updatedDevice.modules.forEach(module => module.updateDeviceReference(updatedDevice))
    return updatedDevice
  }

  validateDevice = device => {
    const { dispatch } = this.props
    if (device) {
      const validationResults = device.validate()
      dispatch(setChannelAlerts(validationResults))
    }
  }

  // eslint-disable-next-line react/destructuring-assignment
  saveDevice = async (device = this.props.device) => {
    const { dispatch, isValidDevice } = this.props
    if (isValidDevice) {
      return dispatch(saveProjectDevice(device))
    }
    return false
  }

  handleOnSamplingRateChange = (value, index, samplingRateOption) => {
    const updateSpec = {
      [index]: {
        $set: { ...samplingRateOption, sampleRate: parseFloat(value) },
      },
    }
    if (index !== 0) {
      this.handleOnChange({
        samplingRates: updateSpec,
      })
      this.handleRefreshSamplingRateModuleLabels()
      return
    }

    const { device } = this.props
    const updatedDevice = update(device, { samplingRates: updateSpec })
    const compatibleOptions = updatedDevice.getCompatibleSamplingRateOptions()
    updatedDevice.samplingRates.slice(1).forEach((compSamplingRateOption, compIndex) => {
      if (!compatibleOptions.find(({ value: compValue }) => compValue === compSamplingRateOption.sampleRate)) {
        updateSpec[compIndex + 1] = { $set: { ...compSamplingRateOption, sampleRate: compatibleOptions[0].value } }
      }
    })
    this.handleOnChange({ samplingRates: updateSpec })
    this.handleRefreshSamplingRateModuleLabels()
  }

  handleRefreshSamplingRateModuleLabels() {
    // wait for device to update
    setTimeout(() => {
      const { device } = this.props
      device.refreshSamplingRateOptionLabels()
    })
  }

  renderDeviceSamplingRate = (samplingRateOption, index, samplingRateOptions, nextCharInstance, labelParams = {}) => {
    const {
      device,
      computeDirtyState,
      intl: { formatMessage: _ },
      paramSelectionChange,
      paramsSelected,
    } = this.props
    const { dataSourceId, sampleRate } = samplingRateOption

    let labelTitle = labelParams.title || _(t.samplingRateTitleTemplate, [nextCharInstance()])
    if (paramSelectionChange) {
      labelTitle = (
        <>
          <SbCheckbox
            onClick={evt => this.handleParamsSelectionChange(evt.target.checked, `$.samplingRates[${index}]`)}
            checked={paramsSelected[`$.samplingRates[${index}]`] === SELECTED}
          />
          {labelTitle}
        </>
      )
    }

    const selectionText = samplingRateOptions.find(option => option.value === sampleRate)?.description ?? sampleRate

    return (
      <Label
        key={dataSourceId}
        isDirty={computeDirtyState && device.isDirty('samplingRates', index, 'sampleRate')}
        {...labelParams}
        title={labelTitle}
      >
        <SbDropdown
          disabled={!!paramSelectionChange}
          selected={sampleRate}
          items={samplingRateOptions}
          maxHeight={195}
          title={selectionText}
          className="sb-width-ml"
          onChange={value => {
            this.handleOnSamplingRateChange(value, index, samplingRateOption)
            // temporary turn off on blur and instead store things like this on change - until sb-dropdown blur gets fixed
            setTimeout(this.handleOnBlur)
          }}
          menuProps={{ ellipsis: true }}
        />
      </Label>
    )
  }

  getSyncModeOption = (clockSettings, optionValue, optionText) => {
    const {
      intl: { formatMessage: _ },
    } = this.props
    return (
      <SbRadioButton
        className={`sb-width-s test-sync-mode-${toCssString(optionValue.toLowerCase().replace(/_/, '-'))}`}
        checked={clockSettings.syncMode === optionValue}
        value={optionValue}
        onClick={() => {
          this.handleOnPersistedChange({
            clockSettings: { syncMode: { $set: optionValue } },
          })
        }}
      >
        {_(optionText)}
      </SbRadioButton>
    )
  }

  render() {
    const {
      device,
      deviceLastStateNTPServerUrl,
      mainSamplingRateOptions,
      compatibleSamplingRateOptions,
      deviceSamplingRates,
      computeDirtyState,
      paramSelectionChange,
      paramsSelected,
      chainSamplingRate,
      intl: { formatMessage: _ },
    } = this.props

    if (!device) {
      return null
    }

    const nextCharInstance = getNextCharFactory('B')
    const { clockSettings } = device
    const validationGroupName = `Module-${device.id}`

    return (
      <ValidationGroup
        name={validationGroupName}
        getValidationRef={({ validate }) => {
          this.forceRevalidate = validate
        }}
      >
        {clockSettings && (
          <>
            <Label
              key="syncMode"
              isDirty={computeDirtyState && device.isDirty('clockSettings', 'syncMode')}
              title={
                <>
                  {paramSelectionChange && (
                    <SbCheckbox
                      onClick={evt => this.handleParamsSelectionChange(evt.target.checked, `$.clockSettings`)}
                      checked={paramsSelected[`$.clockSettings`] === SELECTED}
                    />
                  )}
                  <TitleWithTooltip
                    titleText={t.timeSynchronization}
                    tooltip={t.timeSynchronizationTooltipDescription}
                  />
                </>
              }
            >
              <SbButtonBar className="test-time-sync-mode" disabled={!!paramSelectionChange}>
                {this.getSyncModeOption(clockSettings, deviceTimeSyncModes.MANUAL, t.manual)}
                {this.getSyncModeOption(clockSettings, deviceTimeSyncModes.PTP, t.ptp)}
                {this.getSyncModeOption(clockSettings, deviceTimeSyncModes.NTP, t.ntp)}
              </SbButtonBar>
            </Label>
            {clockSettings.syncMode === deviceTimeSyncModes.PTP ? (
              <Label
                source={['ptp-domain']}
                isDirty={computeDirtyState && device.isDirty('clockSettings', 'ptp', 'domain')}
                title={<TitleWithTooltip titleText={t.domain} tooltip={t.domainTooltipDescription} />}
              >
                <TextBox
                  id="ptp-domain"
                  type="unsigned-number"
                  disabled={!!paramSelectionChange}
                  negative={false}
                  className="test-ptp-domain"
                  value={clockSettings.ptp && clockSettings.ptp.domain}
                  onChange={e =>
                    this.handleOnChange({
                      clockSettings: {
                        ptp: {
                          domain: { $set: Math.min(parseInt(e.target.value, 10), 127) },
                        },
                      },
                    })
                  }
                  onBlur={this.handleOnBlur}
                />
              </Label>
            ) : null}
            {clockSettings.syncMode === deviceTimeSyncModes.NTP ? (
              <Label
                source={['ntp-server']}
                isDirty={computeDirtyState && device.isDirty('clockSettings', 'ntp', 'servers', '0')}
                title={<TitleWithTooltip titleText={t.ntpServerUrl} tooltip={t.ntpServerUrlTooltipDescription} />}
                renderAlerts={{
                  [`${validationGroupName}ntp-server`]: () => (
                    <MeasurementUnitNTPError
                      previousNTPServer={deviceLastStateNTPServerUrl}
                      onRevert={() => {
                        this.handleOnChange({
                          clockSettings: {
                            ntp: { servers: { $set: [deviceLastStateNTPServerUrl] } },
                          },
                        })
                        this.forceRevalidate()
                        // focus element so that blur happens (save) and user can edit the text
                        const ntpServerInput = document.querySelector('.ntp-server-text-box')
                        if (ntpServerInput && ntpServerInput.focus) {
                          ntpServerInput.focus()
                        }
                      }}
                    />
                  ),
                }}
              >
                <TextBox
                  id="ntp-server"
                  className="ntp-server-text-box test-ntp-server"
                  value={clockSettings.ntp && clockSettings.ntp.servers && clockSettings.ntp.servers[0]}
                  disabled={!!paramSelectionChange}
                  onChange={e =>
                    this.handleOnChange({
                      clockSettings: {
                        ntp: { servers: { $set: [e.target.value] } },
                      },
                    })
                  }
                  onBlur={this.handleOnBlur}
                />
              </Label>
            ) : null}
          </>
        )}
        {mainSamplingRateOptions && (deviceSamplingRates || [])[0] && (
          <div className="sb-group device-sampling-rates">
            {this.renderDeviceSamplingRate(deviceSamplingRates[0], 0, mainSamplingRateOptions, nextCharInstance, {
              source: 'samplingRateCategory',
              title: <TitleWithTooltip titleText={t.masterSamplingRate} tooltip={t.masterSamplingRateDescription} />,
            })}
            {(chainSamplingRate && deviceSamplingRates[0].sampleRate !== chainSamplingRate && (
              <SbInlineMessage
                type={INLINE_MESSAGE_TYPE_INFO}
                title={
                  deviceSamplingRates[0].sampleRate < chainSamplingRate
                    ? _(t.samplingRateTooLow)
                    : _(t.samplingRateTooHigh)
                }
              />
            )) ||
              null}
            {map(deviceSamplingRates.slice(1), (deviceSamplingRate, secondaryIndex) =>
              this.renderDeviceSamplingRate(
                deviceSamplingRate,
                secondaryIndex + 1,
                compatibleSamplingRateOptions,
                nextCharInstance,
              ),
            )}
          </div>
        )}
      </ValidationGroup>
    )
  }
}

export const MeasurementUnitDetailContent = withRouter(
  injectIntl(connect(measurementUnitDetailSelector)(_MeasurementUnitDetailContent)),
)
