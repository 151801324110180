import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'

export const NavigateService = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const cleanupEvent = SbEmitter.on('navigate', (path, ...args) => navigate(path, ...args))

    return () => cleanupEvent()
  }, [navigate])

  return null
}
