import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbButton } from 'skybase-ui/skybase-components/sb-button/sb-button'
import { createSnippet } from '@/fleet-configuration/data-fleet/snippets/snippets-actions'
import { messages as t } from './create-snippet-button-i18n'

const _CreateSnippetButton = ({ dispatch, intl: { formatMessage: _ }, deviceId }) => (
  <SbButton className="test-create-config-snippet-btn" onClick={() => dispatch(createSnippet(deviceId, _))}>
    {_(t.createPreset)}
  </SbButton>
)
_CreateSnippetButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  deviceId: PropTypes.string.isRequired,
}

export const CreateSnippetButton = injectIntl(connect()(_CreateSnippetButton))
