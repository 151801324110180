import axios from 'axios'

export const loadHelpFilesMap = async () => {
  const { data } = await axios.get('/help/configMap.json')
  return data
}

export const getHelpFileContents = async helpFilePath => {
  const { data } = await axios.get(`/help/${helpFilePath}`, { responseType: 'text' })
  return data
}
