import React, { useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'

import { Sb21Layout } from 'skybase-ui/skybase-components/layouts'
import { SbButton } from 'skybase-ui/skybase-components/sb-button'
import { SbDynamicTabs, SbTab } from 'skybase-ui/skybase-components/sb-dynamic-tabs'
import { getOAuthState } from 'skybase-oauth/utils'
import { AUTH_STATE_KEY_NAME } from 'skybase-oauth/oauth/constants'
import { AclControl } from 'skybase-oauth/auth/acl-control'
import { oAuthPermissions } from 'skybase-oauth/oauth-permissions'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter'
import { openConfirmModal } from 'skybase-oauth/actions'
import { getTenantApi } from 'skybase-oauth/rest'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'

import { showSuccessToast, showWarningToast } from '@/common/services/show-toast'
import { useIsMounted } from '@/hooks/use-is-mounted'
import { licensingPermissions } from '@/common/permissions'
import { HintRenderer } from '@/common/hint-renderer'
import {
  LicensesList,
  LicenseDetails,
  useLicensesOfUser,
  showAssignLicenseModal,
  handleLicensesOfTicketApiErrors,
  messages as lt,
} from '@/licensing/components/management'
import { messages as menuT } from '@/common/menu/menu-i18n'
import { licenseErrorTypes } from '../../constants'
import { autoUpdateUserContainerApi, removeLicensesFromUserApi } from '../../rest'
import { messages as t } from './management-page-i18n'

import './licensing.scss'

export const ManagementPage = () => {
  const { formatMessage: _ } = useIntl()
  const { licenses, fetching, getLicenses } = useLicensesOfUser()
  const [selectedLicenseId, setSelectedLicenseId] = useState(null)
  const [removing, setRemoving] = useState(false)
  const [updating, setUpdating] = useState(false)
  const isMounted = useIsMounted()
  const selectedLicense = licenses?.find(({ ticketLicenseId }) => ticketLicenseId === selectedLicenseId)
  const { tenant: tenantId } = useSelector(state => getOAuthState(state)[AUTH_STATE_KEY_NAME])
  const dispatch = useDispatch()
  const hasLicenses = licenses?.length
  const containerSerial = licenses?.find?.(license => license.activated && license.containerSerial)?.containerSerial

  // Event emited from the Assign License modal after the successfull license assign action to re-fetch the current list of licenses
  useEffect(() => {
    SbEmitter.on('licensing.getLicenses', getLicenses)

    return () => {
      SbEmitter.off('licensing.getLicenses', getLicenses)
    }
  }, [getLicenses])

  const removeLicensesFromUser = async () => {
    const { ticketLicenseId, ticketIdentification, userIdentification, itemName } = selectedLicense

    try {
      setRemoving(true)
      await removeLicensesFromUserApi({
        ticketId: ticketIdentification,
        userId: userIdentification,
        licenseIds: [ticketLicenseId],
      })

      if (isMounted.current) {
        setRemoving(false)
        setSelectedLicenseId(null)

        // Show a success toast with the names of assigned licenses
        showSuccessToast(
          {
            message: t.licenseWasRevoked,
            params: { name: itemName },
          },
          t.success,
        )

        // Re-fetch the licenses list
        getLicenses()
      }
    } catch (error) {
      if (isMounted.current) {
        setRemoving(false)
      }
      handleLicensesOfTicketApiErrors(error, ticketIdentification, licenseErrorTypes.REMOVE)
    }
  }

  const openRemoveLicenseConfirmModal = async () => {
    setRemoving(true)

    const { ticketIdentification, itemName } = selectedLicense

    try {
      const { name } = await getTenantApi(tenantId)

      if (isMounted.current) {
        setRemoving(false)

        dispatch(
          openConfirmModal({
            title: _(t.revokeLicense),
            message: (
              <FormattedMessage
                id="licensing-management-page.revokeLicenseDesc"
                defaultMessage="Are you sure you want to revoke the license <strong>{licenseName}</strong> from tenant <strong>{tenantName}</strong>?"
                values={{
                  // eslint-disable-next-line react/no-unstable-nested-components
                  strong: text => <strong>{text}</strong>,
                  licenseName: itemName,
                  tenantName: name,
                }}
              />
            ),
            confirmButtonTitle: _(t.revoke),
            confirmButtonClassName: 'destructive',
            handleOnConfirm: () => {
              dispatch(closeModal())
              removeLicensesFromUser()
            },
          }),
        )
      }
    } catch (error) {
      if (isMounted.current) {
        setRemoving(false)
      }
      handleLicensesOfTicketApiErrors(error, ticketIdentification, licenseErrorTypes.REMOVE)
    }
  }

  const updateLicenses = async () => {
    setUpdating(true)

    try {
      const update = await autoUpdateUserContainerApi(tenantId)

      if (isMounted.current) {
        setUpdating(false)

        if (update) {
          showSuccessToast(lt.licenseUpdateSuccess, t.success)
        } else {
          showWarningToast(lt.licenseUpdateNotApplied, t.warning)
        }

        // Re-fetch the licenses list
        getLicenses()
      }
    } catch (error) {
      if (isMounted.current) {
        setUpdating(false)

        handleLicensesOfTicketApiErrors(error, null, licenseErrorTypes.UPDATE)
      }
    }
  }

  return (
    <Sb21Layout
      title={_(t.pageName)}
      id="license-management"
      breadcrumbs={[
        {
          path: '/',
          title: _(menuT.home),
        },
        {
          path: '/licensing/management',
          title: _(menuT.licensing),
        },
        _(menuT.management),
      ]}
    >
      <div>
        <div className="sb-heading fl-row fl-align-items-center fl-justify-sb">
          <div className="fl-row fl-align-items-center">
            <h1>{_(t.pageName)}</h1>
            <AclControl
              writePermissions={[licensingPermissions.licensesAssign, oAuthPermissions.kiconnectTenantsRead]}
              requireAll
            >
              <SbButton
                id="assign-license-button"
                className="primary"
                onClick={() => dispatch(showAssignLicenseModal({ tenantId, containerSerial }))}
                icon="sbi-plus"
              >
                {_(t.assignLicense)}
              </SbButton>
            </AclControl>
          </div>
          <AclControl
            writePermissions={[licensingPermissions.licensesAssign, oAuthPermissions.kiconnectTenantsRead]}
            requireAll
          >
            <HintRenderer showHint={!hasLicenses} hintData={_(t.cannotUpdateEmptyContainer)}>
              <SbButton
                id="update-licenses-button"
                className="refresh-button m-r-20"
                onClick={updateLicenses}
                icon="sbi-refresh"
                loading={updating}
                disabled={updating || !hasLicenses}
              >
                {_(t.updateLicenses)}
              </SbButton>
            </HintRenderer>
          </AclControl>
        </div>
        <LicensesList
          data={licenses}
          onLoadLicenseToUpdate={setSelectedLicenseId}
          selectedLicenseId={selectedLicenseId}
          loading={fetching}
        />
      </div>
      <SbDynamicTabs>
        <SbTab tabId="license-details">
          <SbTab.Title>{_(t.details)}</SbTab.Title>
          <SbTab.Content>
            <LicenseDetails data={selectedLicense} />
          </SbTab.Content>
          {selectedLicense ? (
            <AclControl
              writePermissions={[licensingPermissions.licensesAssign, oAuthPermissions.kiconnectTenantsRead]}
              requireAll
            >
              <SbTab.Footer>
                <SbButton
                  id="revoke-license-button"
                  className="sb-width-100pct danger"
                  onClick={openRemoveLicenseConfirmModal}
                  loading={removing}
                  disabled={removing}
                >
                  {_(t.revokeLicense)}
                </SbButton>
              </SbTab.Footer>
            </AclControl>
          ) : null}
        </SbTab>
      </SbDynamicTabs>
    </Sb21Layout>
  )
}
