import React from 'react'
import PropTypes from 'prop-types'
import { curry } from 'lodash'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'
import { selectedRowTypes } from 'skybase-ui/skybase-components/sb-data-table/constants'
import { SbDataTable } from 'skybase-ui/skybase-components/sb-data-table'
import { menuPositions } from 'skybase-ui/skybase-components/sb-dropdown/constants'
import { SbModal } from 'skybase-ui/skybase-components/sb-modal/sb-modal'
import { SbButton } from 'skybase-ui/skybase-components/sb-button'
import { devicesTableRenderer } from '@/fleet-configuration/page-components/devices-table-renderer/devices-table-renderer'
import { loadDevicesIfEmpty } from '@/fleet-configuration/data-fleet/devices/devices-actions'
import { snippetsTableModalSelector } from '@/fleet-configuration/page-components/snippets-table-modal/snippets-table-modal-selector'
import { applySnippet, setSnippetFilter } from '@/fleet-configuration/data-fleet/snippets/snippets-actions'
import { showInfoToast, showSuccessToast } from '@/common/services/show-toast'
import { messages as t } from './snippets-table-modal-i18n'
import './snippets-table-modal.scss'

class _SnippetsTableModal extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    dispatch: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    devices: PropTypes.array.isRequired,
    snippetId: PropTypes.string.isRequired,
    filterValues: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      applySnippetRunning: false,
      selectedRows: [],
      pagination: {
        pageSize: 10,
        pageNumber: 1,
      },
    }
    devicesTableRenderer.initialize(props.intl.formatMessage, this.handleOnFilterChange)
  }

  async componentDidMount() {
    const { dispatch } = this.props
    await dispatch(loadDevicesIfEmpty())
    this.setState({ isLoading: false })
  }

  handleOnFilterChange = (key, value) => {
    const { dispatch, filterValues } = this.props
    dispatch(setSnippetFilter({ ...filterValues, [key]: value }))
  }

  handleOnPaginationChange = pagination => this.setState({ pagination })

  onSelectableRowChanged = selectedRows => {
    this.setState({ selectedRows })
  }

  handleApplySnippet = async () => {
    const { dispatch, snippetId, handleClose } = this.props
    const { selectedRows } = this.state
    this.setState({ applySnippetRunning: true })
    let snippetsSuccess = true
    try {
      await Promise.all(
        selectedRows.map(deviceId =>
          dispatch(applySnippet(snippetId, deviceId)).catch(err => {
            console.error(err)
            snippetsSuccess = false
            return true
          }),
        ),
      )
    } catch (err) {
      console.error(err)
    }
    this.setState({ applySnippetRunning: false })
    if (snippetsSuccess) {
      showSuccessToast(t.presetsWereSuccessfullyApplied)
    } else {
      showInfoToast(t.somePresetsCouldNotHaveBeenAppliedCorrectly)
    }
    handleClose()
  }

  renderFooter() {
    const {
      intl: { formatMessage: _ },
      handleClose,
    } = this.props
    const { selectedRows, applySnippetRunning } = this.state
    return (
      <div className="sb-modal-footer fl-container fl-justify-end">
        <div className="sb-modal-left" />
        <div className="sb-modal-center" />
        <div className="sb-modal-right">
          <SbButton className="modal-cancel" onClick={handleClose}>
            {_(t.cancel)}
          </SbButton>
          <SbButton
            disabled={!selectedRows.length || applySnippetRunning}
            onClick={this.handleApplySnippet}
            className="primary modal-confirm footer-btn-left-margin"
          >
            {_(t.apply)}
          </SbButton>
        </div>
      </div>
    )
  }

  curriedFilterCellFormatter = curry(devicesTableRenderer.filterCellFormatter, 2)

  render() {
    const {
      devices,
      intl: { formatMessage: _ },
      handleClose,
      filterValues,
    } = this.props
    const { isLoading, pagination, selectedRows } = this.state
    const filteredDevices = devices.filter(devicesTableRenderer.applyFilterOnDevice.bind(null, filterValues))
    return (
      <SbModal
        id="snippets-table-modal"
        width="80%"
        height="auto"
        onClickOverlay={handleClose}
        Footer={this.renderFooter()}
      >
        <h1>{_(t.selectDevices)}</h1>
        <div className="num-selected-devices">
          {_(t.devicesSelectedNumSelectedOfNumDevices, {
            numSelected: selectedRows.length,
            numDevices: devices.length,
          })}
        </div>
        <SbDataTable
          id="snippets-table-modal"
          className="list-table has-filter-row"
          loading={isLoading}
          emptyMessage={_(t.noDevicesFound)}
          cellFormatter={devicesTableRenderer.cellFormatter}
          columns={devicesTableRenderer.commonTableColumns}
          enableFilterRow
          filterCellFormatter={this.curriedFilterCellFormatter(filterValues)}
          data={filteredDevices}
          enablePagination
          asyncData={false}
          paginationProps={{
            pageSizeDropdownProps: {
              className: 'min-width-100px',
              menuPosition: menuPositions.AUTO_TOP,
            },
            ...pagination,
            onChange: this.handleOnPaginationChange,
          }}
          enableSelectionColumn
          rowsUniqueKeyName="id"
          selectedRows={selectedRows}
          onSelectableRowChanged={this.onSelectableRowChanged}
          headerRowSelection={selectedRowTypes.ALL}
        />
      </SbModal>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return { dispatch, handleClose: () => dispatch(closeModal()) }
}

export const SnippetsTableModal = injectIntl(
  connect(snippetsTableModalSelector, mapDispatchToProps)(_SnippetsTableModal),
)
