/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  empty: {
    id: 'channelMenu.empty',
    defaultMessage: ' ',
  },
  menu: {
    id: 'channelMenu.menu',
    defaultMessage: 'menu',
  },
  rename: {
    id: 'channelMenu.rename',
    defaultMessage: 'Rename',
  },
  clear: {
    id: 'channelMenu.clear',
    defaultMessage: 'Clear',
  },
  componentsOfChannelCleared: {
    id: 'channelMenu.componentsOfChannelChannelCleared',
    defaultMessage: 'Components removed from Channel {channelIndex}',
  },
  systemWasUnableToUpdateComponents: {
    id: 'addComponentModal.systemWasUnableToUpdateComponents',
    defaultMessage: 'System was unable to update components',
  },
})
