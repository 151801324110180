import { get, flatMap, find } from 'lodash'
import {
  getComponentFromCatalog,
  getConfigurationOptions,
  getConfigurationParameters,
} from '@/fleet-configuration/data-fleet/catalog/catalog-selectors'
import { createError } from '@/fleet-configuration/validation/create-alert'
import { getProjectDeviceSamplingRateOptions } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import { moduleFactory } from './module-factory'
import {
  getDeviceDirtyState,
  isDeviceInUnknownSyncState,
  isDevicePartDirty,
} from '../project-devices-dirty/project-devices-dirty-selector'
import { setProjectDeviceDirtyProps } from '@/fleet-configuration/data-fleet/project-devices-dirty/project-devices-dirty-actions'
import { urlPathController } from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation-constants'
import { messages as t } from './device-factory-i18n'

export const deviceFactory = (dispatch, getState, deviceData) => {
  let devCatTypes
  let deviceCatalog
  let samplingRateOptions
  let validationResult
  let deviceCatalogOptions
  let deviceCatalogParameters
  class DeviceModel {
    constructor(deviceModelData) {
      Object.assign(this, deviceModelData)
      this.initialize()
    }

    initialize() {
      devCatTypes = this.modelNumber ? this.types.concat(this.modelNumber) : this.types
      deviceCatalog = getComponentFromCatalog(getState(), devCatTypes) || {}
      samplingRateOptions = getProjectDeviceSamplingRateOptions(this)

      this.samplingRates = this.samplingRates.sort(({ index: indexA }, { index: indexB }) => indexA - indexB)
      this.modules = this.modules.map((module, index) => moduleFactory(dispatch, getState, module, this, index))
      const controller = this.deviceSpecific?.controller
      if (controller) {
        this.deviceSpecific.controller = moduleFactory(dispatch, getState, controller, this, urlPathController)
      }
    }

    refreshSamplingRateOptionLabels() {
      samplingRateOptions = getProjectDeviceSamplingRateOptions(this)
    }

    clearValidationResult() {
      validationResult = undefined
      return this
    }

    setDefaultsIfNotSet() {
      this.modules.forEach(module => {
        module.channels.forEach(channel => channel.setDefaultsIfNotSet())
      })
      return this
    }

    isDirty(...properties) {
      const deviceState = getDeviceDirtyState(getState(), this.id)
      if (deviceState === undefined) {
        dispatch(setProjectDeviceDirtyProps(this.id))
        return false
      }
      return isDevicePartDirty(deviceState, properties)
    }

    isValid() {
      return !this.validate().some(({ alerts }) => alerts.length)
    }

    isLabAmp() {
      return this?.types?.includes('x.com.kistler.labamp')
    }

    isKGate() {
      return this?.types?.includes('x.com.kistler.kgate')
    }

    isDeviceInUnknownSyncState() {
      return isDeviceInUnknownSyncState(getState(), this.id)
    }

    toDto() {
      const resultingData = { ...this, modules: this.modules.map(module => module.toDto()) }
      if (resultingData?.deviceSpecific?.controller?.toDto) {
        // shallow copy whole "deviceSpecific" property - so that we don't mutate
        resultingData.deviceSpecific = {
          ...resultingData.deviceSpecific,
          controller: resultingData.deviceSpecific.controller.toDto(),
        }
      }
      return resultingData
    }

    validate() {
      // we can cache this call, as device is immutable
      if (validationResult === undefined) {
        const moduleValidationResults = this.modules.reduce((errors, module) => [...errors, ...module.validate()], [])
        const deviceValidationResults = this.validateDeviceSpecificRules()
        validationResult = moduleValidationResults.concat(deviceValidationResults)
      }
      return validationResult
    }

    validateDeviceSpecificRules() {
      return this.validateMainSamplingRateCategoryIsUsed()
    }

    validateMainSamplingRateCategoryIsUsed() {
      const mainSamplingRateDataSource = (this.samplingRates[0] || {}).dataSourceId
      const hasActiveChannels = this.modules.some(module => module.channels.some(channel => channel.isActive()))
      // do not make the validation if all channels are disabled
      if (hasActiveChannels) {
        const isMainSamplingRateUsed =
          mainSamplingRateDataSource &&
          this.modules.some(module => {
            const activeChannels = module.getActiveChannels()
            return (
              activeChannels &&
              activeChannels[0] &&
              activeChannels[0].parameters.dataSourceId === mainSamplingRateDataSource
            )
          })
        if (this.modules.length && !isMainSamplingRateUsed) {
          return [
            {
              path: `Module-${this.id}samplingRateCategory`,
              alerts: [createError(t.samplingRateCategoryANeedsToBeUsed)],
              deviceId: this.id,
            },
          ]
        }
      }
      return [
        {
          path: `Module-${this.id}samplingRateCategory`,
          alerts: [],
        },
      ]
    }

    getModuleTypes() {
      return this.modules.map(module => module.types)
    }

    getModuleById(moduleId) {
      let module
      if (moduleId === urlPathController) {
        module = (this.deviceSpecific || {}).controller
      } else {
        module = get(this, 'modules', [])[moduleId]
        if (!module) {
          module = find(get(this, 'modules', []), m => (m.parametersReadable || m.parameters).snr === moduleId)
        }
      }
      return module
    }

    getMaxRangeFor(resources) {
      const channels = flatMap(this.modules, module => module.channels).filter(c => resources.includes(c.resourcePath))
      return Math.max(...channels.map(c => (c.parameters.physicalRange || [])[1]).filter(range => !isNaN(range)))
    }

    getMinRangeFor(resources) {
      const channels = flatMap(this.modules, module => module.channels).filter(c => resources.includes(c.resourcePath))
      return Math.min(...channels.map(c => (c.parameters.physicalRange || [])[0]).filter(range => !isNaN(range)))
    }

    getSamplingRateConfig() {
      const configurationOption =
        deviceCatalog.data && deviceCatalog.data.find(({ options }) => options && options.samplingRates)
      return configurationOption ? (configurationOption.options || {}).samplingRates : null
    }

    getMainSamplingRateOptions() {
      const samplingRateConfiguration = this.getSamplingRateConfig()
      if (!samplingRateConfiguration) {
        return []
      }
      samplingRateConfiguration.enum.sort((a, b) => a.value - b.value)
      return samplingRateConfiguration.enum.map(({ value, description }) => ({
        value: parseFloat(value),
        description,
        title: description,
      }))
    }

    getCompatibleSamplingRateOptions() {
      const samplingRateConfiguration = this.getSamplingRateConfig()
      if (!samplingRateConfiguration?.dependencyMatrix) {
        return []
      }
      const compatibleSamplingRateOptions = samplingRateConfiguration.dependencyMatrix[
        this.samplingRates[0].sampleRate
      ].map(({ value, description }) => ({ value: parseFloat(value), description, title: description }))
      compatibleSamplingRateOptions.sort((a, b) => a.value - b.value)
      return compatibleSamplingRateOptions
    }

    getKistlerModelNumber() {
      if (!deviceCatalogParameters) {
        deviceCatalogParameters = getConfigurationParameters(
          deviceCatalog.data,
          deviceCatalog.data.map(({ type }) => type),
        )
      }
      return deviceCatalogParameters.kistlerType || this.modelNumber
    }

    hasSameModulesAs(otherDevice) {
      if (!otherDevice) {
        return false
      }

      const moduleTypes = this.getModuleTypes()
      const otherModuleTypes = otherDevice.getModuleTypes()
      return moduleTypes.toString() === otherModuleTypes.toString()
    }

    getSamplingRateOptions() {
      return samplingRateOptions
    }

    getModuleByResourcePath(resourcePath) {
      return this.modules.find(
        m =>
          resourcePath.startsWith(m.resourcePath) &&
          // this next part serves to not include module such as /uart/1/module/10 when resourcePath is /uart/1/module/1
          !/^[0-9]$/.test(resourcePath[m.resourcePath.length]),
      )
    }

    getAllModulesByResourcePath(resourcePath) {
      return this.modules.filter(m => m.resourcePath.startsWith(resourcePath))
    }

    getChannels() {
      return flatMap(this.modules, ({ channels }) => channels)
    }

    getActiveChannels = () => this.getChannels().filter(channel => channel.isActive())

    getWritableProperties = () => {
      if (!deviceCatalogOptions) {
        deviceCatalogOptions = getConfigurationOptions(
          deviceCatalog.data,
          deviceCatalog.data.map(({ type }) => type),
        )
      }
      const writableProperties = ['$.clockSettings']
      // sampling rates need to be handled specifically per item
      const additionalProperties = Object.keys(deviceCatalogOptions).filter(item => item !== 'samplingRates')
      if (this.samplingRates) {
        writableProperties.push(...Object.keys(this.samplingRates).map(index => `$.samplingRates[${index}]`))
      }

      writableProperties.push(...additionalProperties.map(item => `$.${item}`))
      return writableProperties
    }
  }

  return new DeviceModel(deviceData)
}

export const guardedDeviceFactory = (dispatch, getState, deviceData) => {
  try {
    return deviceFactory(dispatch, getState, deviceData)
  } catch (e) {
    if (e.notification) {
      console.error(e)
      dispatch(e.notification)
    }
    throw e
  }
}
