import { selectMeasurementUnitParams } from '@/fleet-configuration/select-measurement-unit-params'
import {
  getIsDeviceReloading,
  getProjectDeviceById,
} from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'

export const configurationSettingsSelector = (state, props) => {
  const { unitId, moduleIndex, channelIndex } = selectMeasurementUnitParams(state, props)

  return {
    deviceId: unitId,
    moduleIndex,
    channelIndex,
    device: getProjectDeviceById(state, unitId),
    isDeviceReloading: !!getIsDeviceReloading(state),
  }
}
