import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbButton } from 'skybase-ui/skybase-components/sb-button/sb-button'
import { SbHint } from 'skybase-ui/skybase-components/sb-hint'
import { verticalPosition } from 'skybase-ui/skybase-components/sb-hint/constants'
import { reloadSettingsButtonSelector } from '@/fleet-configuration/components/reload-settings-button/reload-settings-button-selector'
import { showConfirmModal } from '@/fleet-configuration/components/confirm-modal/confirm-modal-actions'
import { showSuccessToast } from '@/common/services/show-toast'
import { reloadDeviceConfiguration } from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { messages as t } from './reload-settings-button-i18n'
import './reload-settings-button.scss'

class _ReloadSettingsButton extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    dispatch: PropTypes.func.isRequired,
    deviceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isDeviceOnline: PropTypes.bool,
  }

  static defaultProps = {
    isDeviceOnline: false,
  }

  handleOnClick = () => {
    const {
      dispatch,
      deviceId,
      intl: { formatMessage: _ },
    } = this.props
    dispatch(
      showConfirmModal(
        _,
        _(t.reloadDeviceConfiguration),
        reloadDeviceConfiguration(deviceId, _(t.reloadingOfDeviceFailedTryToPutThisDeviceIntoFactoryReset)),
        {
          modalMessage: (
            <>
              {_(t.areYouSureReplaceDeviceConfiguration)}
              <br />
              {_(t.performingDeviceConfigurationBackupBeforeIsRecommended)}
              <br />
              <br />
              {_(t.thisActionCannotBeUndone)}
            </>
          ),
          confirmText: _(t.replaceConfiguration),
          afterConfirmAction: () => {
            showSuccessToast(_(t.yourConfigurationWasReplacedWithDeviceConfiguration))
          },
          className: 'reload-confirm-modal',
          isDestructive: true,
        },
      ),
    )
  }

  render() {
    const {
      isDeviceOnline,
      intl: { formatMessage: _ },
    } = this.props
    return (
      <SbHint
        position={{ vertical: verticalPosition.TOP }}
        hintData={
          isDeviceOnline ? _(t.removeCustomConfigurationAndReplaceItWithConfigurationOfDevice) : _(t.deviceIsOffline)
        }
      >
        <SbButton disabled={!isDeviceOnline} className="test-reload-settings" onClick={this.handleOnClick}>
          {_(t.readSettingsFromDevice)}
        </SbButton>
      </SbHint>
    )
  }
}

export const ReloadSettingsButton = injectIntl(connect(reloadSettingsButtonSelector)(_ReloadSettingsButton))
