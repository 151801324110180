import React, { PureComponent } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export const { RED, GREEN, ORANGE, DARK } = {
  RED: 'red',
  GREEN: 'green',
  ORANGE: 'orange',
  DARK: 'dark',
}

export class StatusBullet extends PureComponent {
  static propTypes = {
    status: PropTypes.oneOf([RED, GREEN, ORANGE, DARK]).isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
    alignCenter: PropTypes.bool,
  }

  static defaultProps = {
    children: null,
    className: null,
    alignCenter: true,
  }

  render() {
    const { className, status, children, alignCenter, ...rest } = this.props

    return (
      <div className={classNames('fl-row', { 'fl-align-items-center': alignCenter }, className)} {...rest}>
        <span className={classNames('status-bullet', { [status]: true })} />
        {children}
      </div>
    )
  }
}
