import axios from 'axios'
import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { getStudioAPIHost } from '@/utils/url'
import { eulaSelector } from '@/common/eula/eula-selector'

export const SET_EULA_STATUS = getActionName('SET_EULA_STATUS')
export const setEulaStatus = status => createAction(SET_EULA_STATUS, status)

export const loadEulaState = () => async dispatch => {
  const { data } = await axios.get(`${getStudioAPIHost()}/api/ksla/status`)
  dispatch(setEulaStatus(data))
}

export const confirmEula = () => async (dispatch, getState) => {
  const { data } = await axios.put(`${getStudioAPIHost()}/api/ksla/confirm`, {
    id: eulaSelector(getState()).id,
  })
  return data
}

export const setEulaReviewMode = isInReview => async (dispatch, getState) => {
  const currentEulaState = eulaSelector(getState())
  if (!currentEulaState?.content) {
    const { data } = await axios.get(`${getStudioAPIHost()}/api/ksla/confirmed`)
    dispatch(setEulaStatus({ ...currentEulaState, ...data, isInReview }))
  } else {
    dispatch(setEulaStatus({ ...currentEulaState, isInReview }))
  }
}
