import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  channel: {
    id: 'Channel',
    defaultMessage: 'Channel',
  },
  requestedChannelDoesNotExist: {
    id: 'Requested channel does not exist',
    defaultMessage: 'Requested channel does not exist',
  },
})
