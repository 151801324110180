import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { withAcl, aclShape } from 'skybase-oauth/auth'
import { STATES } from 'skybase-oauth/constants'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbLabel } from 'skybase-ui/skybase-components/sb-label'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { SbButton } from 'skybase-ui/skybase-components/sb-button'
import { SbDropdown } from 'skybase-ui/skybase-components/sb-dropdown'
import { SbBadge } from 'skybase-ui/skybase-components/sb-badge'
import { SbCheckbox } from 'skybase-ui/skybase-components/sb-checkbox'
import { InfoMessage, CopyBox } from 'skybase-oauth/common/components'

import { ErrorList } from '@/common/error-list'
import { HintRenderer } from '@/common/hint-renderer'
import { apiErrorsShape } from '@/common/shapes'
import { WSClients } from '@/common/websocket'
import { getIotHubDeviceDetailsState, getIotHubDeviceResourceDetailsState } from '@/iot-hub/selectors'
import { JSONEditor, editorModes } from '@/common/json-editor'
import { isJsonString, replaceDuplicateSlashes } from '@/utils'
import { iotHubPermissions } from '@/common/permissions'
import { deviceStatuses } from '../constants'
import { deviceResourceShape, deviceShape } from '../shapes'
import { fetchResourceInterface, updateResourceInterface } from '../utils'
import { clearDeviceResourceParams } from '../actions'
import { removeDeviceIdFromResourcePath, getResourceWSID } from './utils'
import { resourceStatusListener } from './resource-status-listener'
import { messages as t } from './resources-i18n'

const { OFFLINE } = deviceStatuses
const { CODE, VIEW } = editorModes

class _ResourceDetails extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    data: PropTypes.shape({
      resourceData: deviceResourceShape,
      resourceInterface: deviceResourceShape,
      resourcePath: PropTypes.string,
    }),
    deviceData: deviceShape,
    clearParams: PropTypes.func.isRequired,
    interfacesColors: PropTypes.object, // eslint-disable-line
    typesColors: PropTypes.object, // eslint-disable-line
    loading: PropTypes.bool.isRequired,
    handleFetchResourceInterface: PropTypes.func.isRequired,
    handleUpdateResourceInterface: PropTypes.func.isRequired,
    retievingInterfaces: PropTypes.bool.isRequired,
    updatingIntefaces: PropTypes.bool.isRequired,
    errors: apiErrorsShape,
    acl: aclShape.isRequired,
  }

  static defaultProps = {
    data: {},
    deviceData: {},
    errors: [],
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedInterface: '',
      resourcePath: props?.data?.resourcePath,
      isModalOpen: false,
      interfaceJSON: null,
      hasError: false,
      subscribeAndNotify: !!WSClients.ws[getResourceWSID(props?.deviceData?.id, props?.data?.resourcePath)],
      editorHeight: 300,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props?.data?.resourcePath && props.data.resourcePath !== state.resourcePath) {
      return {
        selectedInterface: '',
        resourcePath: props.data.resourcePath,
        interfaceJSON: null,
        hasError: false,
        subscribeAndNotify: !!WSClients.ws[getResourceWSID(props?.deviceData?.id, props.data.resourcePath)],
      }
    }

    if (props.retievingInterfaces) {
      return {
        interfaceJSON: null,
        hasError: false,
      }
    }

    return null
  }

  componentWillUnmount() {
    const { clearParams } = this.props

    clearParams()
  }

  handleOnInterfaceJSONChange = interfaceJSON => this.setState({ interfaceJSON })

  handleOnInterfaceJSONError = error => this.setState({ hasError: error.length > 0 })

  handleOnInterfaceChange = selectedInterface => this.setState({ selectedInterface })

  handleOnConfirmClick = () => {
    const { selectedInterface, interfaceJSON } = this.state
    const {
      data: {
        resourcePath,
        resourceInterface,
        resourceData: { rt: types },
      },
      handleUpdateResourceInterface,
    } = this.props
    const jsonData = interfaceJSON && isJsonString(interfaceJSON) ? interfaceJSON : resourceInterface

    handleUpdateResourceInterface({
      resourcePath,
      resourceInterface: selectedInterface,
      data: typeof jsonData === 'object' ? jsonData : JSON.parse(jsonData),
      types,
    })
  }

  handleOnRetrieveClick = () => {
    const { selectedInterface } = this.state
    const {
      data: { resourcePath },
      handleFetchResourceInterface,
    } = this.props

    handleFetchResourceInterface({ resourcePath, resourceInterface: selectedInterface })
  }

  handleOnToggleSubscribeAndNotify = () => {
    const {
      data: { resourcePath },
      deviceData: { id: deviceId, name: deviceName },
    } = this.props
    const resourceWSKey = getResourceWSID(deviceId, resourcePath)

    if (!WSClients.ws[resourceWSKey]) {
      WSClients.addToWsClients({
        name: resourceWSKey,
        endpoint: replaceDuplicateSlashes(`/ws/devices/${resourcePath}`),
        listener: message => resourceStatusListener(message, { deviceId, href: resourcePath, deviceName }),
        // permission,
      })
      this.setState({ subscribeAndNotify: true })
    } else {
      WSClients.removeFromWsClients(resourceWSKey)
      this.setState({ subscribeAndNotify: false })
    }
  }

  handleOnEditorResize = (width, height, callback = () => {}) => {
    this.setState({ editorHeight: height }, () => callback())
  }

  renderLoader = () => {
    return (
      <div className="fl-row fl-justify-center">
        <SbLoader show />
      </div>
    )
  }

  renderInterfaces = () => {
    const {
      retievingInterfaces,
      data,
      errors,
      deviceData,
      intl: { formatMessage: _ },
      updatingIntefaces,
      acl: { write },
    } = this.props
    const { interfaceJSON, selectedInterface, isModalOpen, hasError, editorHeight } = this.state
    const disabledUpdateButton = deviceData.status === OFFLINE
    const disableControls = retievingInterfaces || updatingIntefaces
    const hasErrorServer = errors?.length > 0

    if (deviceData?.id && data?.resourcePath && !isModalOpen) {
      const {
        resourceInterface,
        resourceData: { if: interfaces },
      } = data

      const interfacesOptions = interfaces
        ? [{ label: _(t.allInterfaces), value: '' }, ...interfaces.map(value => ({ label: value, value }))]
        : []

      return (
        <>
          <div
            className={classNames('resource-editor-container', 'm-t-5', 'm-b-10', { error: hasErrorServer })}
            style={{ minHeight: `${editorHeight + 2}px` }}
          >
            {!disableControls && (
              <JSONEditor
                json={interfaceJSON || resourceInterface}
                onChange={this.handleOnInterfaceJSONChange}
                onError={this.handleOnInterfaceJSONError}
                onResize={this.handleOnEditorResize}
                height={`${editorHeight}px`}
                mode={write ? CODE : VIEW}
              />
            )}
          </div>

          <div className="fl-row fl-justify-sb">
            {interfaces ? (
              <SbDropdown
                className="interfaces-dropdown"
                items={interfacesOptions}
                onChange={this.handleOnInterfaceChange}
                selected={selectedInterface}
                menuPosition="top-right"
                size="s"
                disabled={disableControls}
                menuProps={{ ellipsis: true }}
              />
            ) : (
              <div />
            )}
            <div className="fl-row">
              <SbButton onClick={this.handleOnRetrieveClick} loading={retievingInterfaces} disabled={disableControls}>
                {_(t.retrieve)}
              </SbButton>
              {write && (
                <HintRenderer showHint={disabledUpdateButton} hintData={_(t.cannotUpdateResourceWhileDeviceOffline)}>
                  <SbButton
                    id="update-resource-button"
                    className="primary m-l-10"
                    disabled={hasError || disableControls || disabledUpdateButton}
                    onClick={this.handleOnConfirmClick}
                    loading={updatingIntefaces}
                  >
                    {_(t.update)}
                  </SbButton>
                </HintRenderer>
              )}
            </div>
          </div>

          <div className="m-t-10">
            <ErrorList errors={errors} />
          </div>
        </>
      )
    }

    return null
  }

  render() {
    const { subscribeAndNotify } = this.state
    const {
      data,
      deviceData,
      intl: { formatMessage: _ },
      interfacesColors,
      typesColors,
      loading,
    } = this.props

    if (loading) {
      return this.renderLoader()
    }

    if (deviceData?.id && data?.resourcePath) {
      const {
        resourcePath,
        resourceData: { if: interfaces, rt: types },
      } = data
      const { id: deviceId } = deviceData

      return (
        <>
          <SbLabel title={_(t.resourceLocation)} inline className="resource-path-label">
            <CopyBox
              text={removeDeviceIdFromResourcePath(resourcePath, deviceId)}
              textToCopy={replaceDuplicateSlashes(`/${resourcePath}`)}
            />
          </SbLabel>

          <SbLabel title={_(t.deviceId)} inline>
            {deviceId}
          </SbLabel>

          <SbLabel title={_(t.types)} inline>
            <div className="fl-col fl-align-items-end">
              {types?.map?.(type => {
                return (
                  <SbBadge
                    key={`device-type-${type}`}
                    className="primary"
                    style={{ backgroundColor: typesColors[type] }}
                  >
                    {type}
                  </SbBadge>
                )
              }) || '-'}
            </div>
          </SbLabel>

          <SbLabel title={_(t.interfaces)} inline>
            <div className="fl-col fl-align-items-end">
              {interfaces?.map?.(ifs => {
                return (
                  <SbBadge
                    key={`device-interface-${ifs}`}
                    className="primary"
                    style={{ backgroundColor: interfacesColors[ifs] }}
                  >
                    {ifs}
                  </SbBadge>
                )
              }) || '-'}
            </div>
          </SbLabel>

          <div className="widget-underline-container m-b-10">
            <SbCheckbox
              id="resource-change-notification-switch"
              type="switch-control"
              onClick={this.handleOnToggleSubscribeAndNotify}
              checked={subscribeAndNotify}
            >
              {_(t.subscribeAndNotify)}
            </SbCheckbox>
          </div>

          {this.renderInterfaces()}
        </>
      )
    }

    return <InfoMessage>{_(t.selectAResource)}</InfoMessage>
  }
}

const mapStateToProps = state => {
  const {
    data: { id: deviceId, status: currentDeviceStatus, name: deviceName },
  } = getIotHubDeviceDetailsState(state)
  const {
    data,
    state: resourceState,
    interfacesRetrieveState,
    interfaceUpdateState,
    errors,
  } = getIotHubDeviceResourceDetailsState(state)

  return {
    data,
    deviceData: {
      id: deviceId,
      status: currentDeviceStatus,
      name: deviceName,
    },
    loading: resourceState === STATES.LOADING,
    retievingInterfaces: interfacesRetrieveState === STATES.LOADING,
    updatingIntefaces: interfaceUpdateState === STATES.LOADING,
    errors,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearParams: () => dispatch(clearDeviceResourceParams()),
    handleFetchResourceInterface: ({ resourcePath, resourceInterface }) =>
      dispatch(fetchResourceInterface({ resourcePath, resourceInterface })),
    handleUpdateResourceInterface: ({ resourcePath, resourceInterface, data, types }) =>
      dispatch(updateResourceInterface({ resourcePath, resourceInterface, data, types })),
  }
}

const withAclComponent = withAcl(_ResourceDetails, {
  writePermissions: [iotHubPermissions.kiconnectDevicesWrite],
})

export const ResourceDetails = injectIntl(connect(mapStateToProps, mapDispatchToProps)(withAclComponent))
