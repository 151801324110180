/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  cannotWithoutConsentToSLA: {
    id: 'eula.kiCloudCannotBeUsedWithoutGivingConsentToSoftwareLicenseAgreement',
    defaultMessage: 'KiCloud cannot be used without giving consent to Software License Agreement',
  },
  softwareLicenseAgreement: {
    id: 'eula.softwareLicenseAgreement',
    defaultMessage: 'Software License Agreement',
  },
  agree: {
    id: 'eula.agree',
    defaultMessage: 'Agree',
  },
  disagree: {
    id: 'eula.disagree',
    defaultMessage: 'Disagree',
  },
  loading: {
    id: 'eula.loading',
    defaultMessage: 'Loading...',
  },
  here: {
    id: 'eula.here',
    defaultMessage: 'here',
  },
  toGoBackToLogInScreenClickHere: {
    id: 'eula.toGoBackToLogInScreenClickHere',
    defaultMessage: 'To go back to log in screen, click {here}',
  },
  close: {
    id: 'eula.close',
    defaultMessage: 'Close',
  },
})
