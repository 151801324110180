import {
  REMOVE_BACKUP_ITEM,
  SET_BACKUP_ITEMS,
  UPDATE_BACKUP_ITEM,
} from '@/fleet-configuration/data-fleet/backup/backup-actions'

export const backupReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_BACKUP_ITEMS:
      return { ...state, [action.payload.deviceId]: action.payload.items || [] }
    case UPDATE_BACKUP_ITEM: {
      const { deviceId, backupId, updateParams } = action.payload
      return {
        ...state,
        [deviceId]: (state[deviceId] || []).map(backup => {
          if (backup.id === backupId) {
            return { ...backup, ...updateParams }
          }
          return backup
        }),
      }
    }
    case REMOVE_BACKUP_ITEM: {
      const { deviceId, itemId } = action.payload
      const deviceItems = (state[deviceId] || []).filter(({ id }) => itemId !== id)
      return { ...state, [deviceId]: deviceItems }
    }
    default:
      return state
  }
}
