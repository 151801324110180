/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  summary: {
    id: 'wizardSummary.summary',
    defaultMessage: 'Summary',
  },
  calibrationExpired: {
    id: 'wizardSummary.calibrationExpired',
    defaultMessage: 'Calibration expired',
  },
  calibrationStatusUnknown: {
    id: 'wizardSummary.calibrationStatusUnknown',
    defaultMessage: 'Calibration status unknown',
  },
  calibrationStatusDeviceUnknown: {
    id: 'wizardSummary.calibrationStatusDeviceUnknown',
    defaultMessage: 'Calibration of device {deviceName} is unknown',
  },
  calibrationStatusDeviceExpired: {
    id: 'wizardSummary.calibrationStatusDeviceExpired',
    defaultMessage: 'Calibration of device {deviceName} is expired',
  },
  errorCalibrationData: {
    id: 'wizardSummary.errorCalibrationData',
    defaultMessage: 'Calibration data are not reachable at this moment.{newLine}Please visit this page later.',
  },
  status: {
    id: 'wizardSummary.status',
    defaultMessage: 'Status',
  },
  settingsOverview: {
    id: 'wizardSummary.settingsOverview',
    defaultMessage: 'Settings Overview',
  },
  compatibilityWithSensorNotGuaranteed: {
    id: 'wizardSummary.compatibilityWithSensorNotGuaranteed',
    defaultMessage: 'Compatibility with sensor not guaranteed',
  },
  typeNumberCable: {
    id: 'wizardSummary.typeNumberCable',
    defaultMessage: '{typeNumber} cable',
  },
  statusWellSetUp: {
    id: 'wizardSummary.wellSetup',
    defaultMessage: 'Your configuration is well set up.',
  },
  channelIndex: {
    id: 'wizardMenu.channelIndex',
    defaultMessage: 'Channel {index}',
  },
  filterType: {
    id: 'wizardSummary.Filter Type',
    defaultMessage: 'Filter Type',
  },
  physicalRange: {
    id: 'wizardSummary.Physical Range',
    defaultMessage: 'Physical Range',
  },
  samplingRate: {
    id: 'wizardSummary.Sampling Rate',
    defaultMessage: 'Sampling Rate',
  },
  sensitivityScaling: {
    id: 'wizardSummary.Sensitivity',
    defaultMessage: 'Sensitivity',
  },
  filterCutoffFrequency: {
    id: 'wizardSummary.Filter Cutoff Frequency',
    defaultMessage: 'Cutoff Freq.',
  },
  filterCutoffFrequencyLow: {
    id: 'wizardSummary.Filter Cutoff Frequency low',
    defaultMessage: 'lower Cutoff Freq.',
  },
  filterCutoffFrequencyUp: {
    id: 'wizardSummary.Filter Cutoff Frequency up',
    defaultMessage: 'upper Cutoff Freq.',
  },
  bandpass: {
    id: 'wizardSummary.bandpass',
    defaultMessage: 'Band-pass',
  },
  highpass: {
    id: 'wizardSummary.highPass',
    defaultMessage: 'High-pass',
  },
  lowpass: {
    id: 'wizardSummary.lowPass',
    defaultMessage: 'Low-pass',
  },
  notch: {
    id: 'wizardSummary.Notch',
    defaultMessage: 'Notch',
  },
  qFactor: {
    id: 'wizardSummary.Q-Factor',
    defaultMessage: 'Q-Factor',
  },
  filterUnit: {
    id: 'wizardSummary.Filter Unit',
    defaultMessage: 'Hz',
  },
  sampleUnit: {
    id: 'wizardSummary.Sample Unit',
    defaultMessage: 'Sps',
  },
  incompatibleWithDeviceDueToMismatchingConnectors: {
    id: 'wizardSummary.incompatibleWithDeviceDueToMismatchingConnectors',
    defaultMessage: 'Incompatible with device due to mismatching connectors',
  },
  cableCableCantBeUsedWithThisDevice: {
    id: 'wizardSummary.cableCableCantBeUsedWithThisDevice',
    defaultMessage: 'Cable "{cable}" can\'t be used with this device',
  },
  invalidErrorMessage: {
    id: 'wizardSummary.invalidError',
    defaultMessage: 'invalid error message',
  },
  recommendedUsePtpNtpTimeSynchronization: {
    id: 'wizardSummary.itIsRecommendedToUsePtpNtpTimeSynchronizationForSystemsWithNetworkConnection',
    defaultMessage: 'It is recommended to use PTP/NTP Time Synchronization for systems with network connection.',
  },
  centerFrequency: {
    id: 'wizardSummary.centerFrequency',
    defaultMessage: 'Center Frequency',
  },
})
