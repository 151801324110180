import { createSelector } from 'reselect'
import { getDeviceBackups } from '@/fleet-configuration/data-fleet/backup/backup-selector'
import { canDeviceBackupByTypes } from '@/fleet-configuration/data-fleet/backup/backup-utils'
import { getProjectDeviceById } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import { getFirmwareData, getFirmwareUpdateStatus } from '@/fleet-configuration/data-fleet/firmware/firmware-selectors'
import { getDeviceById } from '@/fleet-configuration/data-fleet/devices/devices-selectors'
import {
  firmwareEventOperationNames,
  firmwareStates,
} from '@/fleet-configuration/data-fleet/firmware/firmware-constants'
import { getExtendedDeviceCalibrationByDeviceId } from '@/fleet-configuration/data-fleet/devices-calibrations/devices-calibrations-selector'

const {
  UPDATE_IN_PROGRESS,
  UPDATE_AVAILABLE,
  UP_TO_DATE,
  UPDATE_FINISHED_UPDATE_AVAILABLE,
  UPDATE_FINISHED_UP_TO_DATE,
} = firmwareStates

const getDeviceBackupsByParameters = (state, props) => getDeviceBackups(state, props.piid)
const getDeviceByParameters = (state, props) => getDeviceById(state, props.deviceId)
const getProjectDeviceByParameters = (state, props) => getProjectDeviceById(state, props.deviceId)
const getDeviceFirmwareData = (state, props) => getFirmwareData(state, props.deviceId)
const getFirmwareStatus = (state, props) => getFirmwareUpdateStatus(state, props.deviceId)
const getDeviceCalibrationByParameters = (state, props) => getExtendedDeviceCalibrationByDeviceId(state, props.deviceId)

const mapModuleToDeviceDataTableRow = module => {
  return {
    isController: module.isController(),
    type: module.getType(),
    documentation: module.getModuleConfigurationParameters('deviceDocUrl'),
  }
}

export const fleetOverviewExpandedRowSelector = createSelector(
  getDeviceBackupsByParameters,
  getDeviceByParameters,
  getProjectDeviceByParameters,
  getDeviceFirmwareData,
  getFirmwareStatus,
  getDeviceCalibrationByParameters,
  (backups, device, projectDevice, firmwareData, firmwareEventStatus, deviceCalibrations) => {
    const deviceDataTableRows = []
    if (projectDevice?.deviceSpecific?.controller?.isVirtual && !projectDevice.deviceSpecific.controller.isVirtual()) {
      deviceDataTableRows.push(mapModuleToDeviceDataTableRow(projectDevice.deviceSpecific.controller))
    }
    const physicalModules = projectDevice?.modules?.filter(module => !module.isVirtual())
    if (physicalModules?.length) {
      deviceDataTableRows.push(...(physicalModules.map(mapModuleToDeviceDataTableRow) || []))
    }

    let firmwareState = firmwareData?.state
    let firmwareUpdateResultCode = null
    let lastFirmwareUpdateTime = null
    if (
      firmwareState === UPDATE_AVAILABLE &&
      firmwareEventStatus?.operationName === firmwareEventOperationNames.STARTED
    ) {
      firmwareState = UPDATE_IN_PROGRESS
    } else if (
      [UPDATE_AVAILABLE, UP_TO_DATE].includes(firmwareState) &&
      firmwareEventStatus?.operationName === firmwareEventOperationNames.ENDED
    ) {
      firmwareState = firmwareState === UPDATE_AVAILABLE ? UPDATE_FINISHED_UPDATE_AVAILABLE : UPDATE_FINISHED_UP_TO_DATE
      firmwareUpdateResultCode = firmwareEventStatus.operationResultCode
      lastFirmwareUpdateTime = firmwareEventStatus.timestamp
    }

    return {
      backups,
      isLoadingBackups: !backups,
      deviceDataTableRows,
      firmwareVersion: firmwareData?.version,
      firmwareState,
      firmwareUpdateResultCode,
      lastFirmwareUpdateTime,
      newFirmwareVersion: firmwareData?.newVersion,
      projectDevice,
      deviceCalibrationData: deviceCalibrations,
      canBackup: canDeviceBackupByTypes((device || projectDevice)?.types || []),
    }
  },
)
