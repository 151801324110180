import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'

import { messages as oa } from 'skybase-oauth/messages-i18n'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbDataTable } from 'skybase-ui/skybase-components/sb-data-table'
import { getToastString } from 'skybase-ui/skybase-components/sb-toastr/utils'

import { NotificationBadge } from './notification-badge'
import { notificationShape } from './shapes'
import { messages as t } from './notifications-i18n'

class _NotificationsList extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    notifications: PropTypes.arrayOf(notificationShape).isRequired,
    onRowSelect: PropTypes.func.isRequired,
    selectedId: PropTypes.string,
  }

  static defaultProps = {
    selectedId: null,
  }

  constructor(props) {
    super(props)

    const {
      intl: { formatMessage: _ },
    } = props

    this.tableColumns = [
      { name: 'type', label: _(t.type), cellsClassName: 'text-align-left', headerClassName: 'text-align-left' },
      { name: 'time', label: _(t.time) },
      { name: 'title', label: _(t.title) },
      { name: 'message', label: _(t.message) },
    ]
  }

  cellFormatter = (value, key) => {
    const {
      intl: { formatMessage: _, formatDate },
    } = this.props

    if (key === 'title' || key === 'message') {
      return <span className="no-word-break">{getToastString(value, _)}</span>
    }
    if (key === 'type') {
      return <NotificationBadge type={value} />
    }

    if (key === 'time') {
      return <span className="no-word-break">{formatDate(value, { format: 'date-minutes-12hour' })}</span>
    }

    return value
  }

  rowFormatter = row => {
    const { selectedId, onRowSelect } = this.props
    const selected = row.id === selectedId ? 'selected' : ''

    return {
      onClick: () => onRowSelect(row.id, row.visited),
      className: classNames({ selected, 'not-visited': !row.visited }),
    }
  }

  render() {
    const {
      notifications,
      intl: { formatMessage: _ },
    } = this.props

    return (
      <SbDataTable
        id="notifications-table"
        className="list-table"
        columns={this.tableColumns}
        data={notifications}
        rowFormatter={row => this.rowFormatter(row)}
        cellFormatter={this.cellFormatter}
        emptyMessage={_(oa.noResults)}
        asyncData={false}
        defaultSortBy={{
          sortCol: 'time',
          sortOrder: 'desc',
        }}
        enablePagination
        paginationProps={{
          pageSizeDropdownProps: {
            className: 'min-width-100px',
          },
        }}
      />
    )
  }
}

export const NotificationsList = injectIntl(_NotificationsList)
