import { find } from 'lodash'
import { getEntries } from '@/fleet-configuration/data-fleet/entries/entries-selectors'
import { getActiveProjectId } from '@/fleet-configuration/data-fleet/projects/projects-selectors'

export const areProjectComponentsLoaded = state => state.entries.components.loaded

export const getProjectComponents = state => {
  const projectId = getActiveProjectId(state)
  return projectId ? getEntries(state, 'entries.components').filter(component => component.projectId === projectId) : []
}

export const getProjectComponentsByFamily = (state, ...family) =>
  getProjectComponents(state).filter(component => family.includes(component.family))

export const getProjectComponentById = (state, id) => find(getProjectComponents(state), { id })
