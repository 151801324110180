import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from '@/common/router'
import { loadChainIfEmpty } from '@/fleet-configuration/data-fleet/chain/chain-actions'
import { tryLoadChainCatalog } from '@/fleet-configuration/data-fleet/chain-catalog/chain-catalog-actions'
import { loadProjectDeviceIfEmpty } from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbLabel } from 'skybase-ui/skybase-components/sb-label/sb-label'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader/sb-loader'
import { chainPanelSelector } from '@/fleet-configuration/page-components/chain-panel/chain-panel-selector'
import { tryLoadChainCertificate } from '@/fleet-configuration/data-fleet/chain-certificate/chain-certificate-actions'
import { messages as t } from './chain-panel-i18n'
import './chain-panel.scss'

class _ChainPanel extends React.PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    dispatch: PropTypes.func.isRequired,
    deviceId: PropTypes.string.isRequired,
    isLoaded: PropTypes.bool,
    channelId: PropTypes.string.isRequired,
    chain: PropTypes.object,
    sensorCatalog: PropTypes.object,
    sensorDisplayData: PropTypes.object,
    cableCatalog: PropTypes.object,
  }

  static defaultProps = {
    chain: null,
    isLoaded: false,
    sensorCatalog: null,
    sensorDisplayData: {},
    cableCatalog: null,
  }

  async componentDidMount() {
    const { dispatch, deviceId, channelId } = this.props
    await Promise.all([dispatch(loadProjectDeviceIfEmpty(deviceId)), dispatch(loadChainIfEmpty())])
    // if we have quick navigation while request finishes, do not change local state for that of old state
    const { channelId: afterRequestChannelId } = this.props
    if (afterRequestChannelId !== channelId) {
      return
    }
    await this.loadCalibrationData()
  }

  async componentDidUpdate(prevProps) {
    const { dispatch, deviceId, channelId } = this.props
    if (prevProps.channelId !== channelId) {
      await Promise.all([dispatch(loadProjectDeviceIfEmpty(deviceId)), dispatch(loadChainIfEmpty())])
      // if we have quick navigation while request finishes, do not change local state for that of old state
      const { channelId: afterRequestChannelId } = this.props
      if (channelId !== afterRequestChannelId) {
        return
      }
      await this.loadCalibrationData()
    }
  }

  loadCalibrationData = async () => {
    const { dispatch, chain } = this.props
    const { sensor, cable } = chain || {}
    const { typeNumber: sensorTypeNum, serialNumber: sensorSerialNumber } = sensor || {}
    const { typeNumber: cableTypeNum } = cable || {}
    const sTypeNum = sensorTypeNum?.trim()
    const sSerialNum = sensorSerialNumber?.trim()
    const cTypeNum = cableTypeNum?.trim()
    if (sTypeNum) {
      dispatch(tryLoadChainCatalog(sTypeNum))
      if (sSerialNum) {
        dispatch(tryLoadChainCertificate(sTypeNum, sSerialNum))
      }
    }
    if (cTypeNum) {
      dispatch(tryLoadChainCatalog(cTypeNum))
    }
  }

  render() {
    const {
      intl: { formatMessage: _ },
      isLoaded,
      chain,
      sensorCatalog,
      sensorDisplayData,
      cableCatalog,
    } = this.props

    const { sensor, cable } = chain || {}
    const { typeNumber: sensorTypeNum, serialNumber: sensorSerialNum } = sensor || {}
    const { typeNumber: cableTypeNum } = cable || {}

    const { name: sensorName, measurand, sensorClass, naturalFrequency, range } = sensorDisplayData || {}

    if (!isLoaded) {
      return <SbLoader show />
    }

    return (
      <div className="fl-grow-1 chain-panel">
        <div>
          <h3>{_(t.sensor)}</h3>
          {sensorTypeNum ? (
            <>
              <SbLabel inline className="test-sensor-name underlined" title={_(t.sensorName)}>
                {sensorCatalog ? sensorName || '-' : <SbLoader show />}
              </SbLabel>
              <SbLabel inline className="test-sensor-serial-number underlined" title={_(t.serialNumber)}>
                {sensorSerialNum ?? '-'}
              </SbLabel>
              <SbLabel inline className="test-sensor-type-number underlined" title={_(t.typeNumber)}>
                {sensorTypeNum ?? '-'}
              </SbLabel>
              <SbLabel inline className="test-measurand underlined" title={_(t.measurand)}>
                {measurand ?? '-'}
              </SbLabel>
              <SbLabel inline className="test-class underlined" title={_(t.class)}>
                {sensorClass ?? '-'}
              </SbLabel>
              <SbLabel inline className="test-natural-frequency underlined" title={_(t.naturalFrequency)}>
                {naturalFrequency ? `${naturalFrequency} ${_(t.hz)}` : null}
              </SbLabel>
              <SbLabel inline className="test-smart-values-range underlined" title={_(t.range)}>
                {range}
              </SbLabel>
            </>
          ) : (
            <span>{_(t.toEnableThisFeaturePleaseAddASensorToTheChannel)}</span>
          )}
        </div>
        <div className="m-t-10">
          <h3>{_(t.cable)}</h3>
          {cableTypeNum ? (
            <>
              <SbLabel inline className="test-cable-name underlined" title={_(t.cableName)}>
                {cableCatalog ? cableCatalog.name || '-' : <SbLoader show />}
              </SbLabel>
              <SbLabel inline className="test-cable-type-number underlined" title={_(t.typeNumber)}>
                {cableTypeNum}
              </SbLabel>
            </>
          ) : (
            <span>{_(t.toEnableThisFeaturePleaseAddACableToTheChannel)}</span>
          )}
        </div>
      </div>
    )
  }
}

export const ChainPanel = withRouter(injectIntl(connect(chainPanelSelector)(_ChainPanel)))
