import { processFinished } from '@/fleet-configuration/data-fleet/process-indicator/process-indicator-actions'
import {
  deviceSyncEventFinishedName,
  deviceSyncEventName,
  deviceSyncOperationNames,
} from '@/fleet-configuration/data-fleet/device-sync/device-sync-constants'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter'
import { deviceSyncStatusUpdate } from './device-sync-actions'
import { getDeviceSyncStartedProcessName, isDeviceBeingSynced } from './device-sync-selectors'
import { showErrorToast } from '@/common/services/show-toast'
import { messages as t } from './device-sync-listener-i18n'
import { getDeviceById } from '@/fleet-configuration/data-fleet/devices/devices-selectors'

const getSyncErrorMessage = errorCode => {
  switch (String(errorCode)) {
    case '3':
      return t.writeFailDueToAnAlreadyRunningAcquisition
    case '5': // same as 7
    case '7':
      return t.writeFailSamplingRateSetOnSomeUartWasTooHigh
    case '6':
      return t.writeFailDeviceOrOneOfItsResourcesIsGone
    case '1': // same as default
    default:
      return t.writeToDeviceDeviceWasUnsuccessful
  }
}

export const initDeviceSyncListener = (eventListener, dispatch, getState) => {
  const timeoutIDs = {}
  const TIMEOUT_TIME = 1000 * 60 * 10 // 10 minutes
  eventListener.on('deviceSync', message => {
    clearTimeout(timeoutIDs[message.deviceId])
    const state = getState()
    if (
      message.operationName === deviceSyncOperationNames.ENDED &&
      message.isReplayed &&
      !isDeviceBeingSynced(state, message.deviceId)
    ) {
      return
    }
    // take into account possibility that start event might never get corresponding end event
    if (message.operationName === deviceSyncOperationNames.STARTED) {
      const eventStart = message.eventTime || Date.now()
      const now = message.serverTime || Date.now()
      const elapsedTime = now - eventStart
      if (elapsedTime > TIMEOUT_TIME) {
        return
      }
      timeoutIDs[message.deviceId] = setTimeout(() => {
        dispatch(
          deviceSyncStatusUpdate({
            ...message,
            operationName: deviceSyncOperationNames.ENDED,
            operationStatusCode: '500',
            operationDetailMessage: 'Sync timed out - source UI',
          }),
        )
      }, TIMEOUT_TIME - elapsedTime)
    }

    if (message.operationName === deviceSyncOperationNames.ENDED && message.operationStatusCode >= 400) {
      const device = getDeviceById(getState(), message.deviceId)?.name || message.deviceId
      showErrorToast(
        { message: getSyncErrorMessage(message.operationResultValue), params: { device } },
        t.writeToDeviceFailed,
      )
    }
    dispatch(processFinished(getDeviceSyncStartedProcessName(message.deviceId)))
    SbEmitter.emit(deviceSyncEventName, message, dispatch, getState)
  })

  SbEmitter.on(deviceSyncEventFinishedName, message => {
    dispatch(deviceSyncStatusUpdate(message))
  })
}
