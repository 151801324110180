import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { SbHint } from 'skybase-ui/skybase-components/sb-hint'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { horizontalPosition, verticalPosition } from 'skybase-ui/skybase-components/sb-hint/constants'
import { DARK, GREEN, RED, StatusBullet } from '@/common/status-bullet'
import { calibrationInformationSelector } from '@/fleet-configuration/components/calibration/calibration-information/calibration-information-selector'
import { messages as t } from './calibration-information-i18n'
import { loadProjectDeviceIfEmpty } from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { loadDevicesIfEmpty } from '@/fleet-configuration/data-fleet/devices/devices-actions'
import { loadDeviceCalibrationsIfEmpty } from '@/fleet-configuration/data-fleet/devices-calibrations/devices-calibrations-actions'

export const CalibrationInformation = ({ deviceId, showIcon = true }) => {
  const { formatDate, formatMessage: _ } = useIntl()
  const dispatch = useDispatch()
  const { deviceCalibrationData, hasOnlyVirtualModules } = useSelector(state =>
    calibrationInformationSelector(state, { deviceId }),
  )

  useEffect(() => {
    dispatch(loadProjectDeviceIfEmpty(deviceId))
    dispatch(loadDevicesIfEmpty())
    dispatch(loadDeviceCalibrationsIfEmpty(deviceId))
  }, [deviceId, dispatch])

  if (!hasOnlyVirtualModules || !deviceCalibrationData.isCalibrable) {
    return null
  }

  if (deviceCalibrationData.hasRequestError) {
    return (
      <div className="fl-row fl-align-items-center">
        <SbHint
          hintData={<>{_(t.errorCalibrationData, { newLine: <br /> })} </>}
          position={{ horizontal: horizontalPosition.CENTER, vertical: verticalPosition.TOP }}
          wrapperClassName="calibration-date"
        >
          <span className="sbi-toast-error color-error" />
        </SbHint>
      </div>
    )
  }
  if (deviceCalibrationData.isLoaded) {
    const calibrationDate = new Date(deviceCalibrationData.calibrationDate)
    const calibrationExpires = new Date(calibrationDate)
    calibrationExpires.setDate(calibrationDate.getDate() + deviceCalibrationData.calibrationIntervalDays)
    const expirationWarning = deviceCalibrationData.isExpiring ? (
      <SbHint hintData={_(t.theCalibrationOfYourDeviceExpiredOrIsAboutToExpire, { newLine: <br /> })}>
        <span className="sbi-toast-warning warning" />
      </SbHint>
    ) : null
    return isNaN(calibrationExpires) || !calibrationDate.getTime() ? (
      <StatusBullet status={DARK}>{_(t.noCalibrationData)}</StatusBullet>
    ) : (
      <StatusBullet alignCenter={!showIcon} status={deviceCalibrationData.isExpiring ? RED : GREEN}>
        {showIcon ? (
          <div>
            <div className="fl-container fl-justify-sb m-b-5">
              <span>
                {_(t.expiration)}
                <span className="m-l-5">{expirationWarning}</span>
              </span>
              <span>{formatDate(calibrationExpires, { format: 'date-table' })}</span>
            </div>
            <div className="fl-container fl-justify-sb m-b-5">
              <span>{_(t.lastCalibration)}</span>
              <span>{formatDate(calibrationDate, { format: 'date-table' })}</span>
            </div>
            <div className="fl-container fl-justify-sb">
              <span>{_(t.statusAsOf)}</span>
              <span>{formatDate(deviceCalibrationData.cacheEntryUpdatedTimestamp, { format: 'date-table' })}</span>
            </div>
          </div>
        ) : (
          <>
            {_(t.expiry, { date: formatDate(calibrationExpires, { format: 'date-table' }) })}
            <SbHint
              wrapperClassName="calibration-date"
              hintData={_(t.lastCalibrationDate, {
                date: formatDate(calibrationDate, { format: 'date-table' }),
              })}
            >
              <span className="sbi-help-info" />
            </SbHint>
            {expirationWarning}
          </>
        )}
      </StatusBullet>
    )
  }
  return (
    <StatusBullet status={DARK}>
      {_(t.expiry, {
        date: <SbLoader show />,
      })}
    </StatusBullet>
  )
}
CalibrationInformation.propTypes = {
  deviceId: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
}
