import { get, isPlainObject } from 'lodash'

const getStateEntries = (state, path) => {
  if (!state && !isPlainObject(state)) {
    throw new Error('Invalid state argument. State must be a valid object. State:', state)
  }
  if (!path) {
    throw new Error('Path argument cannot be null or empty')
  }

  const entries = get(state, path)

  if (!entries) {
    throw new Error(`Specified entries record (${path}) does not exits`)
  }
  return entries
}

export const areEntriesLoaded = (state, path) => getStateEntries(state, path).loaded

const getEntriesCache = {}
export const getEntries = (state, path) => {
  const entries = getStateEntries(state, path)
  // cache result from entries - to return same object on same input parameters. This will help-component shallow compares and cache hits/misses
  if (getEntriesCache[path]?.entriesKey === entries) {
    return getEntriesCache[path].result
  }
  const result = entries.allIds.map(id => entries.byId[id])
  getEntriesCache[path] = { entriesKey: entries, result }
  return result
}

export const getEntryById = (state, path, id) => getStateEntries(state, path).byId[id]
