import { castArray } from 'lodash'
import { getDeviceChannelByIndex } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'

export const isValidGroup = (state, formName) => {
  const formNames = castArray(formName)
  return !Object.entries(state.validation.controls).some(([key]) => formNames.some(name => key.startsWith(name)))
}

export const isValidInput = (state, inputName) =>
  !Object.entries(state.validation.controls).some(([key]) => key.endsWith(inputName))

export const isValid = (state, formName) =>
  !Object.entries(state.validation.controls).some(([key]) => {
    // TODO: temporary workaround
    // Reduce this to key.starsWith test this when
    // device returns usable config for disabled channels
    if (key.startsWith(formName)) {
      const indexes = key.match(/^([a-f0-9-]+)-modules-([0-9]+)-channels-([0-9]+)/)
      if (indexes) {
        const channel = getDeviceChannelByIndex(state, indexes[1], indexes[2], indexes[3])
        if (channel && channel.parameters) {
          return channel.isActive()
        }
      }
      return true
    }
    return false
  })
