import { omit } from 'lodash'

const setEntry = (state, action) => {
  const { payload } = action
  const { id } = payload
  return {
    ...state,
    [id]: payload,
  }
}

const removeEntry = (state, action) => {
  const { payload } = action
  const { id } = payload
  return omit(state, [id])
}

export const byIdReducerFactory =
  (setEntryAction, removeAllEntriesAction, removeEntryAction) =>
  (state = {}, action = {}) => {
    switch (action.type) {
      case setEntryAction:
        return setEntry(state, action)
      case removeEntryAction:
        return removeEntry(state, action)
      case removeAllEntriesAction:
        return {}
      default:
        return state
    }
  }
