import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { SbButton } from 'skybase-ui/skybase-components/sb-button'
import { SbTextbox } from 'skybase-ui/skybase-components/sb-textbox'
import { SbLabel } from 'skybase-ui/skybase-components/sb-label'
import { SbModal, SbModalHeader } from 'skybase-ui/skybase-components/sb-modal'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'

import { useIsMounted } from '@/hooks/use-is-mounted'
import { getIotHubDevicesDeviceIpByValue } from '../../selectors'
import { fetchDeviceByIp } from './utils'
import { saveDeviceByIPValue } from './actions'
import { messages as t } from './devices-i18n'

export const AddDeviceByIpModal = () => {
  const isMounted = useIsMounted()
  const dispatch = useDispatch()
  const { formatMessage: _ } = useIntl()
  const addDeviceByIpReduxValue = useSelector(getIotHubDevicesDeviceIpByValue)
  const [loading, setLoading] = useState(false)
  const [ipAddress, setIpAddress] = useState(addDeviceByIpReduxValue)
  const isInputEmpty = ipAddress.trim() === ''

  const handleOnClose = () => {
    dispatch(closeModal())
  }

  const handleOnChange = event => setIpAddress(event.target.value)

  const handleOnConfirmClick = () => {
    if (!isInputEmpty) {
      setLoading(true)
      dispatch(saveDeviceByIPValue(ipAddress))
      dispatch(fetchDeviceByIp(ipAddress))
        .then(() => {
          if (isMounted.current) {
            setLoading(false)
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setLoading(false)
          }
        })
    }
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleOnConfirmClick()
    }
  }

  const onCloseHandler = loading ? null : handleOnClose

  const renderHeader = () => {
    return <SbModalHeader title={_(t.addDeviceByIpAddress)} onCloseBtnClick={onCloseHandler} />
  }

  const renderFooter = () => {
    return (
      <div className="sb-modal-footer fl-container fl-justify-end">
        <div className="sb-modal-left" />
        <div className="sb-modal-center" />
        <div className="sb-modal-right">
          <SbButton
            loading={loading}
            disabled={loading || isInputEmpty}
            className="primary"
            onClick={handleOnConfirmClick}
          >
            {!loading ? _(t.addDevice) : _(t.finding)}
          </SbButton>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <SbLabel title={_(t.ipAddress)} required>
        <SbTextbox value={ipAddress} onChange={handleOnChange} disabled={loading} onKeyPress={handleKeyPress} />
      </SbLabel>
    )
  }

  return (
    <SbModal
      Header={renderHeader()}
      Footer={renderFooter()}
      width={310}
      height="auto"
      className="oauth-modal"
      onClickOverlay={onCloseHandler}
      placeholder={_(t.enterDeviceIpAddress)}
    >
      <div className="sb-message-modal-content">{renderContent()}</div>
    </SbModal>
  )
}
