// helper function to make sure results are same by reference (important for selectors with reselect)
export const memoSelector = functionToMemo => {
  let lastDependencyValues = []
  let lastResult
  const getAreDependencyArgsTheSame = (...args) => {
    const result =
      args.length === lastDependencyValues.length &&
      lastDependencyValues.every((lastValue, lastIndex) => lastValue === args[lastIndex])
    lastDependencyValues = args
    return result
  }
  const getCachedResult = () => lastResult
  const setCachedResult = objToCache => {
    lastResult = objToCache
    return lastResult
  }

  return (state, props) =>
    functionToMemo(state, props, { getAreDependencyArgsTheSame, getCachedResult, setCachedResult })
}
