import React from 'react'
import classNames from 'classnames'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'
import { showSimpleModal } from '@/common/modals'
import { messages as t } from './confirm-modal-actions-i18n'
import './confirm-modal-actions.scss'

export const showConfirmModal =
  (
    _,
    modalTitle,
    confirmAction,
    {
      modalMessage = '',
      confirmText = _(t.confirm),
      afterConfirmAction = null,
      className = '',
      isDestructive = false,
    } = {},
  ) =>
  dispatch => {
    const dispatchCloseModal = () => dispatch(closeModal())
    let isConfirmActionRunning = false
    dispatch(
      showSimpleModal({
        title: modalTitle,
        message: <div>{modalMessage}</div>,
        className: classNames('confirm-modal', className),
        onClickOverlay: dispatchCloseModal,
        closeButton: true,
        buttons: [
          {
            title: confirmText,
            className: classNames('primary m-r-10', { danger: isDestructive }),
            position: 'right',
            action: () => async () => {
              if (isConfirmActionRunning) {
                return
              }
              const hasConfirmedActionRunPromise = dispatch(confirmAction)
              isConfirmActionRunning = true

              hasConfirmedActionRunPromise.finally(() => {
                isConfirmActionRunning = false
                dispatchCloseModal()
              })
              if (afterConfirmAction) {
                if (await hasConfirmedActionRunPromise) {
                  afterConfirmAction()
                }
              }
            },
          },
          { title: _(t.cancel), className: 'secondary', position: 'right', action: closeModal },
        ],
      }),
    )
  }
