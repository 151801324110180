import { OAuth } from 'skybase-oauth'

export class WebSocketClient {
  constructor(apiUrl, baseUrl = null) {
    this.ws = null
    this.pingPong = null
    this.apiUrl = apiUrl
    this.baseUrl = baseUrl
  }

  connect = (delayListener = 4000) => {
    // API endpoint URL
    const { host, protocol } = new URL(this.baseUrl || OAuth.config.API_ENDPOINT_URL)
    const wsProtocol = protocol === 'https:' || protocol === 'wss:' ? 'wss:' : 'ws:'

    // Constructed WS URL with access token and apiUrl
    const wsUrl = `${wsProtocol}//${host}/api${this.apiUrl}`

    // The WS Instance
    this.ws = new WebSocket(wsUrl)

    this.ws.onopen = (...args) => {
      // this.startPingPong()
      this._sendToken()
      this._onOpen(...args)
    }

    this.ws.addEventListener('close', this._close)
    this.ws.addEventListener('error', this._error)

    // Delay the messages in order to filter out the initial messages from WS.
    setTimeout(() => {
      if (this.ws) {
        this.ws.addEventListener('message', this._onMessage)
      }
    }, delayListener)
  }

  disconnect = () => {
    if (this.ws) {
      this.ws.removeEventListener('message', this._onMessage)
      this.ws.close()
    }
  }

  _sendToken = () => {
    // Access Token
    const token = OAuth.oAuthHandler.getAccessToken().accessToken

    if (token && !OAuth.isInLocalSDKMode) {
      this.ws.send(JSON.stringify({ Token: token }))
    }
  }

  _onOpen = (...args) => {
    this.onOpen(...args)
  }

  onOpen = () => {}

  close = () => {
    if (this.pingPong) {
      clearInterval(this.pingPong)
    }

    this.ws = null
  }

  send = (...args) => {
    this.ws.send(...args)
  }

  onClose = () => {}

  _close = (...args) => {
    this.close()
    this.onClose(...args)
  }

  onError = () => {}

  _error = (...args) => {
    this.onError(...args)
  }

  onMessage = () => {}

  _onMessage = (...args) => {
    this.onMessage(...args)
  }

  isConnected = () => {
    return this?.ws?.readyState === 1
  }
}
