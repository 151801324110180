/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  writeToDeviceFailed: {
    id: 'deviceSyncListener.writeToDeviceFailed',
    defaultMessage: 'Write to device failed',
  },
  writeToDeviceDeviceWasUnsuccessful: {
    id: 'deviceSyncListener.writeToDeviceDeviceWasUnsuccessful',
    defaultMessage: 'Write to device "{device}" was unsuccessful',
  },
  writeSettingsCompleted: {
    id: 'deviceSyncListener.writeSettingsCompleted',
    defaultMessage: 'Write settings completed',
  },
  writeFailDueToAnAlreadyRunningAcquisition: {
    id: 'deviceSyncListener.writeFailDueToAnAlreadyRunningAcquisition',
    defaultMessage:
      'Write to device "{device}" failed because it was blocked by an active data acquisition / a running measurement',
  },
  writeFailSamplingRateSetOnSomeUartWasTooHigh: {
    id: 'deviceSyncListener.writeFailSamplingRateSetOnSomeUartWasTooHigh',
    defaultMessage:
      'Write to device "{device}" failed because at least one of the configured sampling rates is too high to be supported',
  },
  writeFailDeviceOrOneOfItsResourcesIsGone: {
    id: 'deviceSyncListener.writeFailDeviceOrOneOfItsResourcesIsGone',
    defaultMessage:
      'Write to device "{device}" failed because the device or one of its resources is not connected anymore',
  },
})
