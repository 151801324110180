import update from 'immutability-helper'
import { lt, gt, get, isFinite, cloneDeep, isNil } from 'lodash'
import { mapOptions } from '@/common/options/map-options'
import { ACQUISITION_CHANNEL } from '@/fleet-configuration/data-fleet/catalog/catalog-constants'
import {
  getDeviceDirtyState,
  isDevicePartDirty,
} from '@/fleet-configuration/data-fleet/project-devices-dirty/project-devices-dirty-selector'
import { setProjectDeviceDirtyProps } from '@/fleet-configuration/data-fleet/project-devices-dirty/project-devices-dirty-actions'
import { ChannelValidator } from '@/fleet-configuration/data-fleet/project-devices/channel-validator'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter'
import {
  getFieldName,
  getDependentOptions,
  hasCustomUnit,
  pathSegmentsFactory,
  rangeFromBaseToPhysicalUnit,
  rangeFromPhysicalToBaseUnit,
  convertUnit,
  getSensitivityUnitPair,
} from './channel-utils'
import { ChannelOptions } from './channel-options'
import { ChannelUpdater } from './channel-updater'
import { DefaultValueSetter } from './default-value-setter'
import { getProjectDeviceById } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'

export const channelModelFactory = (
  dispatch,
  getState,
  catalog,
  channelDto,
  deviceId,
  moduleIndex,
  channelIndex,
  module,
) => {
  const pathSegments = pathSegmentsFactory(moduleIndex, channelIndex)

  let channelOptions
  let validator
  class ChannelModel {
    constructor(catalogRef, channelDtoRef) {
      Object.assign(this, { deviceSpecific: {} }, channelDtoRef)
      this.catalog = catalogRef
      this.path = [deviceId, ...pathSegments].join('-')
      const { deviceSpecific, parameters } = this
      if (deviceSpecific.filter) {
        parameters.filterType = deviceSpecific.filter.type
        if (deviceSpecific.filter.frequencies) {
          /* eslint-disable prefer-destructuring */
          parameters.filterFreq1 = deviceSpecific.filter.frequencies[0]
          parameters.filterFreq2 = deviceSpecific.filter.frequencies[1]
          /* eslint-enable prefer-destructuring */
        }
        parameters.order = deviceSpecific.filter.order
        parameters.algorithm = deviceSpecific.filter.algorithm
        parameters.qualityFactor = deviceSpecific.filter.qualityFactor
        parameters.sampleCount = deviceSpecific.filter.sampleCount
        delete deviceSpecific.filter
      }
      validator = new ChannelValidator(catalogRef, deviceId, moduleIndex, channelIndex)
      this.initValidationRules(this)
    }

    initValidationRules() {
      validator.initValidationRules(this)
    }

    getDeviceId() {
      return deviceId
    }

    getModuleIndex() {
      return moduleIndex
    }

    getChannelIndex() {
      return channelIndex
    }

    getChannelType() {
      const typeToDisplayTypeMap = {
        'x.com.kistler.kgate.analogInput': 'AI',
        'x.com.kistler.labamp.acquisition.channel': 'AI',
        'x.com.kistler.kgate.digitalInput': 'DI',
      }
      const rawType = this.types.find(type => typeToDisplayTypeMap[type])
      return rawType ? typeToDisplayTypeMap[rawType] : null
    }

    getOptions(field, _) {
      return this.getChannelOptions()
        .getOptions(field)
        .map(option => ({
          description: option.rawDescription ? _(option.rawDescription) : option.description,
          ...option,
        }))
    }

    getOptionDescriptionOrEmpty(field, value) {
      if (!this.hasField(field)) {
        return ''
      }

      return this.getOptionDescription(field, value)
    }

    getOptionDescription(field, value) {
      return this.getChannelOptions().getOptionDescription(field, value)
    }

    hasOption(field, value) {
      return this.hasField(field) && this.getChannelOptions().hasOption(field, value)
    }

    isResetable() {
      return this.catalog.getConfigurationParameters().resetable
    }

    setParameter(field, value) {
      const updatedChannel = cloneDeep(this).getChannelUpdater().setParameter(field, value, this)
      updatedChannel.initValidationRules()
      return updatedChannel
    }

    setListParameter(field, value) {
      // lowercase by Labamp firmware. physicalQuantity table for Kidaq is required to be uppercase by Kidaq firmware.
      const fieldValues = this.getOptions(field)
      const matchingOption = fieldValues?.find(option => option?.value.toLowerCase() === value.toLowerCase())
      let updatedChannel = {}

      if (matchingOption) {
        updatedChannel = cloneDeep(this).getChannelUpdater().setParameter(field, matchingOption.value, this)
      } else {
        updatedChannel = cloneDeep(this).getChannelUpdater().setParameter(field, value, this)
      }
      updatedChannel.initValidationRules()
      return updatedChannel
    }

    setSubResourceParameter(subResourceName, field, value) {
      const updatedChannel = cloneDeep(this)
      updatedChannel.deviceSpecific[subResourceName][field] = value
      updatedChannel.initValidationRules()
      return updatedChannel
    }

    hasField(field, accessor = undefined) {
      const exists = this.catalog.hasField(field)
      if (exists) {
        const options = this.getChannelOptions()
        const dependentOptions = getDependentOptions(this.catalog, this, options, field)
        // TODO: move this login into the option field
        const catalogNames = accessor === undefined ? [field] : [field, `${field}[${accessor}]`]
        const isHiddenField =
          dependentOptions &&
          dependentOptions.some(
            option =>
              option.disables && option.disables.some(hiddenField => catalogNames.includes(getFieldName(hiddenField))),
          )
        return !isHiddenField
      }
      return exists
    }

    subResourceHasField(subResourceName, field) {
      return this.getSubResource(subResourceName)[field]
    }

    getSubResourceOptions(subResourceName, field) {
      const enumOptions = (this.getSubResource(subResourceName)[field] || {}).enum || []
      return mapOptions(enumOptions, 'value', ['description', 'rawDescription'])
    }

    getSubResource(subResourceName) {
      if (!((this.deviceSpecific || {})[subResourceName] || {}).types) {
        return {}
      }
      return this.catalog.getSubResource(this.deviceSpecific[subResourceName].types)
    }

    getSubResourceValue(subResourceName, field) {
      return ((this.deviceSpecific || {})[subResourceName] || {})[field]
    }

    getSubResourceMin(subResourceName, field) {
      return (this.getSubResource(subResourceName)[field] || {}).min
    }

    getSubResourceMax(subResourceName, field) {
      return (this.getSubResource(subResourceName)[field] || {}).max
    }

    getTimeConstantDescription() {
      const { timeConstant, physicalUnit, physicalRange } = this.parameters
      const range = (physicalRange || [])[1]
      if (!timeConstant || !physicalUnit || isNil(range)) {
        return null
      }
      const option = this.catalog.getEnumOption('timeConstant', timeConstant)
      const { sensitivitySensorUnit, sensitivityPhysicalUnit } = getSensitivityUnitPair(this.getChannelOptions(), this)
      if (!(option || {}).information || !sensitivitySensorUnit) {
        return null
      }
      const baseUnitRange = convertUnit(this.parameters.physicalUnit, sensitivityPhysicalUnit, range)

      const selectedInformation = option.information.find(information => {
        const normalizedRange = convertUnit(sensitivitySensorUnit, information.unit, baseUnitRange)
        switch (information.operator) {
          case 'lt':
            return normalizedRange < information.range
          case 'le':
            return normalizedRange <= information.range
          case 'gt':
            return normalizedRange > information.range
          case 'ge':
            return normalizedRange >= information.range
          default:
            console.error(`Unknown operator type: ${information.operator}`)
            return false
        }
      })
      return (selectedInformation || {}).value
    }

    getConnectionImageField() {
      const imageFields = [
        'stage',
        'shuntResistance',
        'sensorConnectorType',
        'sensorType',
        'signalConnectorType',
        'signalTypeDetail',
        'signalType',
      ]
      // Order matters here: find first connectionImages field and then early return
      let connectionImages = null

      imageFields.some(field => {
        if (!this.hasField(field)) {
          return false
        }

        connectionImages = this.getChannelOptions().getOption(field, this.parameters[field]).connectionImages
        return !!connectionImages
      })
      return connectionImages
    }

    findConnectionImageByRangeValue(availableImages) {
      return availableImages.find(({ range, operator }) => {
        if (!range) {
          return false
        }
        const [physicalRangeFrom, physicalRangeTo] = this.parameters.physicalRange || []
        const toRange = rangeFromPhysicalToBaseUnit(this, physicalRangeTo)
        const fromRange =
          physicalRangeFrom === undefined ? toRange : rangeFromPhysicalToBaseUnit(this, physicalRangeFrom)

        switch (operator) {
          case 'lt': {
            const checkRange = Math.min(Math.abs(fromRange), Math.abs(toRange))
            return lt(checkRange, range)
          }
          case 'gt': {
            const checkRange = Math.max(Math.abs(fromRange), Math.abs(toRange))
            return gt(checkRange, range)
          }
          default:
            console.error(`Unsupported operator ${operator}.`)
            return false
        }
      })
    }

    findConnectionImageByFieldValue(availableImages) {
      return availableImages.find(
        ({ field, value }) => field && value !== undefined && this.parameters[getFieldName(field)] === value,
      )
    }

    filterConnectionImagesByChannelIndex(connectionImages) {
      return connectionImages.filter(
        connectionImage => !connectionImage.channels || connectionImage.channels.includes(channelIndex),
      )
    }

    getConnectionImage() {
      let connectionImages = this.getConnectionImageField()
      if (connectionImages) {
        connectionImages = this.filterConnectionImagesByChannelIndex(connectionImages)

        if (connectionImages.length === 1) {
          return {
            src: connectionImages[0].connectionImageSvg || connectionImages[0].connectionImagePng,
            mimeType: this.getImageMime(connectionImages[0]),
          }
        }

        const selectedImage =
          this.findConnectionImageByRangeValue(connectionImages) ||
          this.findConnectionImageByFieldValue(connectionImages) ||
          connectionImages.find(image => image.default) ||
          connectionImages[0]

        return {
          src: selectedImage.connectionImageSvg || selectedImage.connectionImagePng,
          mimeType: this.getImageMime(selectedImage),
        }
      }
      return ''
    }

    getImageMime = ({ connectionImageSvg }) => (connectionImageSvg ? 'image/svg+xml' : 'image/png')

    getType(field) {
      return this.catalog.getType(field)
    }

    isUsingFilter() {
      return this.parameters.filterType && this.parameters.filterType !== 'NoFilter'
    }

    getChangesForField(field) {
      const options = this.getChannelOptions()
      return this.catalog.getDependencies(field).reduce((acc, dependency) => {
        const dependentField = getFieldName(dependency)
        const dependentOption =
          this.catalog.isEnum(dependentField) && options.getOption(dependentField, this.parameters[dependentField])
        if (dependentOption && dependentOption.changes) {
          const changeObject = dependentOption.changes.find(change => getFieldName(change.field) === field)
          if (changeObject && changeObject.values) {
            acc.push(changeObject.values)
          }
        }
        return acc
      }, [])
    }

    min(field) {
      // catalog's default value might be overridden by dependent field
      // if no override is present, use that default
      return this.getChangesForField(field).reduce(
        (result, changeObject) => ('min' in changeObject ? changeObject.min : result),
        this.catalog.min(field),
      )
    }

    max(field) {
      // catalog's default value might be overridden by dependent field
      // if no override is present, use that default
      return this.getChangesForField(field).reduce(
        (result, changeObject) => ('max' in changeObject ? changeObject.max : result),
        this.catalog.max(field),
      )
    }

    unit(field) {
      return (this.catalog.getField(field) || {}).unit
    }

    setDefaultsIfNotSet() {
      const valueSetter = new DefaultValueSetter(this, this.getChannelUpdater(), this.getChannelOptions(), this.catalog)
      valueSetter.setDefaultsIfNotSet()
    }

    isEditablePhysicalRangeFrom() {
      const minRangeFrom = this.minRangeFrom()
      const maxRangeFrom = this.maxRangeFrom()
      if (minRangeFrom === maxRangeFrom) {
        return false
      }
      if (this.isBridgeSensorType()) {
        return maxRangeFrom < 0
      }
      return true
    }

    isEditablePhysicalRangeTo() {
      const minRangeTo = this.minRangeTo()
      const maxRangeTo = this.maxRangeTo()
      if (minRangeTo === maxRangeTo) {
        return false
      }
      return true
    }

    minRangeFrom() {
      return this.getPhysicalRange('min.from', 0)
    }

    minRangeTo() {
      return this.getPhysicalRange('min.to', 0)
    }

    maxRangeFrom() {
      return this.getPhysicalRange('max.from', Number.MIN_SAFE_INTEGER)
    }

    maxRangeTo() {
      return this.getPhysicalRange('max.to', Number.MAX_SAFE_INTEGER)
    }

    getPhysicalRange(field, defaultValue) {
      if (this.isBridgeSensorType()) {
        return defaultValue
      }
      const range = this.getRange()
      const rangeValue = get(range, field)

      if (isFinite(parseFloat(rangeValue))) {
        return this.hasField('unit') ? rangeFromBaseToPhysicalUnit(this, rangeValue) : rangeValue
      }
      return defaultValue
    }

    getZeroOffset() {
      return parseFloat(this.parameters.zeroOffset) || 0
    }

    getRange() {
      const range =
        this.tryGetRange('sensorTypeDetail') ||
        this.tryGetRange('sensorConnectorType') ||
        this.tryGetRange('sensorType') ||
        this.tryGetRange('signalTypeDetail') ||
        this.tryGetRange('signalType') ||
        this.tryGetRange('stage') ||
        this.tryGetRange('physicalQuantity')

      if (!range) {
        console.error('Missing range field')
      }
      return range
    }

    tryGetRange(field) {
      const value = this.parameters[field]
      if (this.hasField(field) && (value || value === '')) {
        const option = this.getChannelOptions().getOption(field, value)
        if (option.range) {
          return option.range
        }
        if (option.ranges) {
          return option.ranges.find(range => range.channels.includes(channelIndex))
        }
      }
      return null
    }

    getChannelUpdater() {
      return new ChannelUpdater(this.catalog, this.getChannelOptions(), this)
    }

    getChannelOptions() {
      if (!channelOptions) {
        channelOptions = new ChannelOptions(this.catalog, this)
      } else {
        channelOptions.channel = this
      }
      return channelOptions
    }

    hasCustomUnit() {
      return hasCustomUnit(this)
    }

    isUndefined(field) {
      return this.catalog.hasField(field) && !this.parameters[field] && this.parameters[field] !== ''
    }

    isDirty(...properties) {
      const deviceState = getDeviceDirtyState(getState(), deviceId)
      if (deviceState === undefined) {
        dispatch(setProjectDeviceDirtyProps(deviceId))
        return false
      }
      return isDevicePartDirty(deviceState, [...pathSegments, ...properties])
    }

    updateSpanningChannels(oldSpanning = 1, newSpanning = undefined) {
      const actualChannel = this
      // you can't use module ref, because it's old reference and it's channels could contain old channel values
      const channels = getProjectDeviceById(getState(), actualChannel.getDeviceId())
        .getModuleById(actualChannel.getModuleIndex())
        .getChannels()
      if (actualChannel.parameters.channelSpanState) {
        actualChannel.parameters.channelSpanState.span = newSpanning
      }
      const updateChannels = [actualChannel]
      // first update newly-disabled-by-spanning channels (do not process already disabled channels)
      for (let i = channelIndex + oldSpanning; i < channelIndex + newSpanning; i += 1) {
        // remember value before auto-disable and then disable it + store for update
        const newChannel = cloneDeep(channels[i])
        newChannel.parameters = {
          ...channels[i].parameters,
          channelSpanState: {
            previouslyEnabled: channels[i].parameters.enabled,
            lockedBy: channelIndex,
          },
          enabled: false,
        }
        updateChannels.push(newChannel)
      }
      // then revert auto-disabled channels to state before auto-disabling
      for (let i = channelIndex + newSpanning; i < channelIndex + oldSpanning && channels[i]; i += 1) {
        const newChannel = cloneDeep(channels[i])
        newChannel.parameters.enabled = channels[i].parameters.channelSpanState
          ? channels[i].parameters.channelSpanState.previouslyEnabled
          : true
        delete newChannel.parameters.channelSpanState
        updateChannels.push(newChannel)
      }
      SbEmitter.emit('ChannelSpanningUpdated', { updateChannels })
    }

    isSupported() {
      return this.isAcquisitionChannel()
    }

    isAcquisitionChannel() {
      return this.types.includes(ACQUISITION_CHANNEL)
    }

    isAnalogInput() {
      return this.types.includes('x.com.kistler.kgate.analogInput')
    }

    isDigitalIO() {
      return this.isDigitalInput() || this.isDigitalOutput()
    }

    isDigitalInput() {
      return this.types.includes('x.com.kistler.kgate.digitalInput')
    }

    isDigitalOutput() {
      return this.types.includes('x.com.kistler.kgate.digitalOutput')
    }

    isActive() {
      return this.parameters.enabled
    }

    isPartiallyActive() {
      return false
    }

    isChargeType() {
      return this.parameters.sensorType === 'Charge' || this.parameters.stage === 'charge'
    }

    isNegativeSensitivityAllowed() {
      return !this.isLabAmp()
    }

    isLabAmp() {
      return this.types.includes('x.com.kistler.labamp.acquisition.channel')
    }

    isKiDAQ() {
      return this.types.some(type => type.includes('kgate'))
    }

    isTemperatureSensorType() {
      return (
        this.isMatchingMainTypeAndPhysicalQuantity('Temperature') ||
        module.getModuleConfigurationParameters('kistlerType') === '5514A'
      )
    }

    isBridgeSensorType() {
      return ['Bridge, full', 'Bridge, half', 'Bridge, quarter', 'LVDT'].includes(this.parameters.sensorType)
    }

    isMatchingMainTypeAndPhysicalQuantity(physicalQuantity = this.parameters.physicalQuantity) {
      return (
        (this.hasField('sensorType') &&
          this.getSensorTypesByPhysicalQuantity(physicalQuantity).includes(this.parameters.sensorType)) ||
        (this.hasField('stage') &&
          this.parameters.stage &&
          String(this.parameters.stage).toLowerCase() === String(physicalQuantity).toLowerCase())
      )
    }

    getSelectedPhysicalQunatity() {
      return String(this.parameters.physicalQuantity)
    }

    getSensorTypesByPhysicalQuantity(physicalQuantity) {
      if (physicalQuantity === 'Temperature') {
        return ['Resistance Thermometers', 'Thermocouple']
      }
      return [physicalQuantity]
    }

    toDto() {
      const setUndefined = { $set: undefined }
      const getFilter = () => {
        const getFrequencies = (filterFreq1, filterFreq2) => {
          if (filterFreq1 && filterFreq2) {
            return [filterFreq1, filterFreq2]
          }
          if (filterFreq1) {
            return [filterFreq1]
          }
          return []
        }

        return this.isUsingFilter()
          ? {
              $set: {
                type: this.parameters.filterType,
                frequencies: getFrequencies(this.parameters.filterFreq1, this.parameters.filterFreq2),
                algorithm: this.parameters.algorithm || 'Butterworth',
                order: this.parameters.order || 4,
                qualityFactor: this.parameters.qualityFactor || 0,
                sampleCount: this.parameters.sampleCount || 0,
              },
            }
          : {
              $set: {
                type: this.parameters.filterType,
                frequencies: null,
                algorithm: '',
                order: 0,
                qualityFactor: 0,
                sampleCount: 0,
              },
            }
      }

      return update(this, {
        catalog: setUndefined,
        path: setUndefined,
        parameters: {
          filterType: setUndefined,
          filterFreq1: setUndefined,
          filterFreq2: setUndefined,
          algorithm: setUndefined,
          order: setUndefined,
          qualityFactor: setUndefined,
          sampleCount: setUndefined,
        },
        deviceSpecific: {
          filter: this.parameters.filterType ? getFilter() : setUndefined,
        },
        validationRules: setUndefined,
      })
    }

    validate() {
      return validator.validate(this)
    }

    getExcludedFromDirtyFields() {
      return this.catalog.getDirtyExcludableFields().reduce((acc, fieldToCheck) => {
        acc.push(...this.catalog.getExcludeFromDirtyCheckFieldsOfOption(fieldToCheck, this.parameters[fieldToCheck]))
        return acc
      }, [])
    }

    getWritableProperties() {
      const result = []
      const channelPath = `$.${
        module.isController() ? 'deviceSpecific.controller' : `modules[${moduleIndex}]`
      }.channels[${channelIndex}].`
      if (this.hasField('filterType') || this.hasField('timeConstant')) {
        result.push(`${channelPath}deviceSpecific.filter.type`)
        if (this.hasField('filterFreq1')) {
          result.push(`${channelPath}deviceSpecific.filter.order`)
          result.push(`${channelPath}deviceSpecific.filter.algorithm`)
        }
      }
      if (this.hasField('filterFreq1')) {
        result.push(`${channelPath}deviceSpecific.filter.frequencies`)
      }

      const mirrorFields = this.catalog.getMirrorResourcesFields()
      Object.keys(this.parameters).forEach(field => {
        if (this.hasField(field)) {
          // special conditions
          if (['algorithm', 'order'].includes(field)) {
            if (this.hasField('filterFreq1')) {
              result.push(`${channelPath}parameters.${field}`)
            }
          } else if (field === 'customUnit') {
            if (this.hasField('physicalQuantity') && this.hasCustomUnit()) {
              result.push(`${channelPath}parameters.${field}`)
            }
          } else {
            // base stuff
            result.push(`${channelPath}parameters.${field}`)
          }
        }

        if (mirrorFields?.length && this.catalog.isEnum(field)) {
          const option = this.catalog.getEnumOption(field, this.parameters[field])
          if (option?.mirrorResources?.toPath) {
            option.mirrorResources.map.forEach(({ toField }) => {
              result.push(`${channelPath}${option.mirrorResources.toPath}.${toField}`)
            })
          }
        }
      })
      const subresourceFields = {
        highpass: ['enabled', 'order', 'frequency'],
        notch: ['enabled', 'frequency', 'qFactor'],
        lowpass: ['enabled', 'algorithm', 'order', 'frequency'],
      }
      Object.keys(subresourceFields).forEach(subResource => {
        subresourceFields[subResource].forEach(subField => {
          if (this.subResourceHasField(subResource, subField)) {
            result.push(`${channelPath}deviceSpecific.${subResource}.${subField}`)
          }
        })
      })
      return result
    }
  }

  return new ChannelModel(catalog, channelDto)
}
