import {
  registerLanguage,
  initCulture,
  setCultureOrder,
  setLanguage,
  setLanguageOrder,
} from 'skybase-ui/skybase-core/locales/actions'

import { getDetectedLanguage } from 'skybase-ui/skybase-core/locales/utils'
import { batchActions } from 'redux-batched-actions'

// Here is where you link all of the other translations that you will need
// For example: import skybaseAuthTranslations from 'skybase-auth/languages/languages.json'
// eslint-disable-next-line import/extensions
import skybaseUiTranslations from 'skybase-ui/languages/languages'

// eslint-disable-next-line import/extensions
import localTranslations from '../../languages/languages.json'
import config from '../../config'

if (!Intl.RelativeTimeFormat) {
  /* eslint-disable global-require */
  require('@formatjs/intl-relativetimeformat/polyfill')
  require('@formatjs/intl-relativetimeformat/locale-data/en')
  require('@formatjs/intl-relativetimeformat/locale-data/de')
  require('@formatjs/intl-relativetimeformat/locale-data/es')
  require('@formatjs/intl-relativetimeformat/locale-data/zh')
  require('@formatjs/intl-relativetimeformat/locale-data/vi')
  require('@formatjs/intl-relativetimeformat/locale-data/ko')
  require('@formatjs/intl-relativetimeformat/locale-data/ru')
  /* eslint-enable global-require */
}

// @todo Create task for SkyBase (respect `languageOrder` in `initLanguage`).
export const initializeLanguagesAndCulture = dispatch => {
  // Todo:  Fix locales - decouple the locale from the language, etc.
  // Add all locale metadata like number and currency formats, etc.

  const translations = []
  config.languages.forEach(key => {
    translations.push(
      registerLanguage(key, {
        ...skybaseUiTranslations[key],
        ...localTranslations[key],
      }),
    )
  })

  const detectedLanguage = getDetectedLanguage(config.languages)
  dispatch(
    batchActions([
      ...translations,
      setLanguageOrder(config.languages),
      setLanguage(detectedLanguage),
      setCultureOrder(config.cultures),
      initCulture(),
    ]),
  )
}
