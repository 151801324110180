import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'

import { getToastString } from 'skybase-ui/skybase-components/sb-toastr/utils'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbLabel } from 'skybase-ui/skybase-components/sb-label'
import { InfoMessage } from 'skybase-oauth/common/components/info-message'

import { SyntaxHighlighter } from '@/common/syntax-highlighter'
import { getValue } from '@/utils'
import { NotificationBadge } from './notification-badge'
import { notificationShape } from './shapes'
import { messages as t } from './notifications-i18n'

class _NotificationDetails extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    data: notificationShape,
  }

  static defaultProps = {
    data: null,
  }

  render() {
    const {
      data,
      intl: { formatMessage: _, formatDate },
    } = this.props

    if (data) {
      const { type, time, title, message, payload } = data
      const jsonPayload = typeof payload === 'string' ? JSON.parse(payload) : payload

      return (
        <>
          <SbLabel title={_(t.type)} inline>
            <NotificationBadge type={type} />
          </SbLabel>
          <SbLabel title={_(t.time)} inline>
            {formatDate(time, { format: 'date-minutes-12hour' })}
          </SbLabel>
          <SbLabel title={_(t.title)} inline>
            {getValue(getToastString(title, _))}
          </SbLabel>
          <SbLabel title={_(t.message)} inline>
            <div className="sb-width-l text-align-right">{getValue(getToastString(message, _))}</div>
          </SbLabel>
          {payload && (
            <SbLabel title={_(t.payload)} className="m-t-10">
              <SyntaxHighlighter allowUpdates className="no-margin" language="json">
                {JSON.stringify(jsonPayload, null, 2)}
              </SyntaxHighlighter>
            </SbLabel>
          )}
        </>
      )
    }

    return <InfoMessage>{_(t.selectANotification)}</InfoMessage>
  }
}

export const NotificationDetails = injectIntl(_NotificationDetails)
