import { isDeviceOnlineByOnlineStatuses } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import { createSelector } from 'reselect'
import { getDevicesOnlineStatuses } from '@/fleet-configuration/data-fleet/device-online-status/device-online-status-selector'

// even if this uses simple createSelector + property, this is fine. Most of the times we see button with one deviceId
// meaning cache miss will occur only when we change viewed device = that is actually desired behavior
export const reloadSettingsButtonSelector = createSelector(
  [getDevicesOnlineStatuses, (state, props) => props.deviceId],
  (devicesOnlineStatuses, deviceId) => {
    return {
      isDeviceOnline: isDeviceOnlineByOnlineStatuses(devicesOnlineStatuses, deviceId),
    }
  },
)
