import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { MESSAGE_MODAL_ID } from 'skybase-ui/skybase-components/sb-message-modal/constants'
import { SbMessageModal } from 'skybase-ui/skybase-components/sb-message-modal/sb-message-modal'
import { translateMessage } from 'skybase-ui/skybase-core/locales'

import { OAuthModals } from 'skybase-oauth/oauth-modals'
import { O_AUTH_MODALS_KEY } from 'skybase-oauth/constants'

import { AssignLicenseModal, ASSIGN_LICENSE_MODAL_ID } from '@/licensing/components/management'
import {
  RESOURCE_MODAL_ID,
  ResourceModal,
  DATASOURCE_GRAPH_MODAL_KEY,
  GraphModal,
  AddDeviceByIpModal,
  ADD_DEVICE_BY_IP_MODAL_ID,
} from '@/iot-hub/components/devices'
import { getCurrentModalId, getModalData, SimpleModal, SIMPLE_MODAL_ID } from '@/common/modals'
import { SNIPPETS_TABLE_MODAL_ID } from '@/fleet-configuration/page-components/snippets-table-modal/snippets-table-modal-constants'
import { SnippetsTableModal } from '@/fleet-configuration/page-components/snippets-table-modal/snippets-table-modal'
import { EQUIPMENT_MODAL_ID, EquipmentModal } from '@/fleet-configuration/equipment'
import { HELP_MODAL_ID } from '@/common/help-component/help-component-constants'
import { HelpComponentModal } from '@/common/help-component/help-component-modal'
import { ADD_COMPONENT_MODAL_ID } from '@/fleet-configuration/page-components/wizard/add-component-modal/add-component-modal-constants'
import { AddComponentModal } from '@/fleet-configuration/page-components/wizard/add-component-modal/add-component-modal'

/**
 * @description
 * Opens given modal and prepares its props.
 *
 * @param props
 */
const Modals = ({
  intl: { formatMessage: _ },
  currentModalId = null,
  modalState = {},
  currentModalPayload = {},
  ...rest
}) => {
  switch (currentModalId) {
    case MESSAGE_MODAL_ID: {
      const { message, title, translate: doTranslation } = modalState
      let messageModalState = modalState

      if (doTranslation) {
        messageModalState = {
          ...modalState,
          message: translateMessage(_, message),
          title: translateMessage(_, title),
        }
      }

      return <SbMessageModal {...{ ...rest, currentModalPayload, modalState, currentModalId }} {...messageModalState} />
    }
    case SIMPLE_MODAL_ID:
      return <SimpleModal {...modalState} />
    case O_AUTH_MODALS_KEY:
      return <OAuthModals {...modalState} />
    case RESOURCE_MODAL_ID:
      return <ResourceModal {...modalState} />
    case DATASOURCE_GRAPH_MODAL_KEY:
      return <GraphModal {...modalState} />
    case ASSIGN_LICENSE_MODAL_ID:
      return <AssignLicenseModal {...modalState} />
    case ADD_DEVICE_BY_IP_MODAL_ID:
      return <AddDeviceByIpModal {...modalState} />
    case SNIPPETS_TABLE_MODAL_ID:
      return <SnippetsTableModal {...modalState} />
    case EQUIPMENT_MODAL_ID:
      return <EquipmentModal {...modalState} />
    case HELP_MODAL_ID:
      return <HelpComponentModal {...modalState} />
    case ADD_COMPONENT_MODAL_ID:
      return <AddComponentModal {...modalState} />
    default: {
      break
    }
  }

  return <div />
}

Modals.propTypes = {
  intl: intlShape.isRequired,
  currentModalId: PropTypes.string,
  modalState: PropTypes.object, // eslint-disable-line
  currentModalPayload: PropTypes.object, // eslint-disable-line
}

const mapStateToProps = state => {
  const currentModalId = getCurrentModalId(state)

  return {
    currentModalId,
    modalState: getModalData(currentModalId, state),
  }
}

export default injectIntl(connect(mapStateToProps)(Modals))
