import { RELOADING_PROJECT_DEVICE, REMOVE_PROJECT_DEVICE, SET_PROJECT_DEVICE } from './project-devices-actions'

export const projectDevicesReducer = (
  state = { loadedDeviceIds: {}, devices: [], reloadingProjectDevice: null },
  action = {},
) => {
  switch (action.type) {
    case SET_PROJECT_DEVICE: {
      const { id: deviceId } = action.payload
      if (state.loadedDeviceIds[deviceId]) {
        return {
          ...state,
          devices: state.devices.map(device => (device.id === deviceId ? action.payload : device)),
        }
      }
      return {
        ...state,
        loadedDeviceIds: { ...state.loadedDeviceIds, [deviceId]: true },
        devices: state.devices.concat(action.payload),
      }
    }
    case REMOVE_PROJECT_DEVICE: {
      const { deviceId } = action.payload
      const { [deviceId]: _removedId, ...loadedDeviceIds } = state.loadedDeviceIds
      return { ...state, loadedDeviceIds, devices: state.devices.filter(({ id }) => id !== deviceId) }
    }
    case RELOADING_PROJECT_DEVICE: {
      const { deviceId } = action.payload
      return { ...state, reloadingProjectDevice: deviceId }
    }
    default:
      return state
  }
}
