// TODO: somehow axios does not register with eslint - triggering error (even it it is node_modules hard dependency). Find out why
// eslint-disable-next-line import/no-unresolved
import axios from 'axios'
import { castArray } from 'lodash'
import { batchActions } from 'redux-batched-actions'
import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { getProjectComponentsByFamily } from '@/fleet-configuration/data-fleet/components/components-selectors'
import { getStudioAPIHost } from '@/utils/url'
import { ACQUISITION_CHANNEL } from './catalog-constants'
import { getComponentsByFamilyName } from '@/fleet-configuration/data-fleet/catalog/catalog-selectors'

export const REMOVE_ALL_CATALOG_COMPONENTS = getActionName('REMOVE_ALL_CATALOG_COMPONENTS')

export const SET_CATALOG_COMPONENT = getActionName('SET_CATALOG_COMPONENT')

export const setCatalogComponent = element => createAction(SET_CATALOG_COMPONENT, element)

let loadedCatalogs = []

// utility function required for unit tests
export const clearCatalogCache = () => {
  loadedCatalogs = []
}

export const loadChainCatalog = () => async (dispatch, getState) => {
  const chainComponents = getProjectComponentsByFamily(getState(), 'cable', 'sensor') || []
  if (!chainComponents.length) {
    return
  }
  const responses = await Promise.all(
    chainComponents.map(({ catalogId }) =>
      axios.get(`${getStudioAPIHost()}/api/components/${catalogId}?version=4`, { cache: true }),
    ),
  )
  const catalogComponents = responses.map(({ data }) => data[0])
  const actions = catalogComponents.map(component => setCatalogComponent(component))
  if (actions.length) {
    dispatch(batchActions(actions))
  }
}

export const batchSetCatalogComponents = components => async dispatch => {
  const actions = castArray(components).map(component => setCatalogComponent(component))
  dispatch(batchActions(actions))
}

export const loadDevicesCatalog = devices => async dispatch => {
  let catalogRequestPayloads = new Set()
  devices.forEach(device => {
    // NOTE: device.types and channel.types are already sorted to ensure correct comparison
    if (device.types) {
      const types = JSON.stringify(device.modelNumber ? device.types.concat(device.modelNumber) : device.types)
      if (!loadedCatalogs.includes(types)) {
        catalogRequestPayloads.add(types)
      }
    }

    const { controller } = device.deviceSpecific || {}
    if (controller && controller.types) {
      const catalogRequestPayload = JSON.stringify(controller.types)
      if (!loadedCatalogs.includes(catalogRequestPayload)) {
        catalogRequestPayloads.add(catalogRequestPayload)
      }
    }

    if (device.modules) {
      device.modules.forEach(module => {
        const hasAcquisitionChannel =
          module.channels &&
          module.channels.some(channel => channel.types && channel.types.includes(ACQUISITION_CHANNEL))
        if (module.types) {
          const catalogRequestPayload = JSON.stringify(
            hasAcquisitionChannel ? module.types.concat(ACQUISITION_CHANNEL) : module.types,
          )
          if (!loadedCatalogs.includes(catalogRequestPayload)) {
            catalogRequestPayloads.add(catalogRequestPayload)
          }
        }
      })
    }
  })
  catalogRequestPayloads = Array.from(catalogRequestPayloads)

  const responses = await Promise.all(
    catalogRequestPayloads.map(payload =>
      axios.post(`${getStudioAPIHost()}/api/configuration-options?version=4`, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    ),
  )
  loadedCatalogs.push(...catalogRequestPayloads)
  const catalogComponents = responses.map(({ data }, index) => ({
    id: catalogRequestPayloads[index],
    data,
  }))
  const actions = catalogComponents.map(component => setCatalogComponent(component))
  dispatch(batchActions(actions))
}

export const loadCatalogItemsByFamily = family => async dispatch => {
  const { data } = await axios.get(`${getStudioAPIHost()}/api/components?family=${family}`, { cache: true })
  const actions = [
    ...data.map(component =>
      setCatalogComponent({
        ...component,
      }),
    ),
  ]
  dispatch(batchActions(actions))
  return data
}

export const tryLoadCatalogItemsByFamily = family => async (dispatch, getState) => {
  const catalogItems = getComponentsByFamilyName(getState(), family)
  if (catalogItems.length) {
    return catalogItems
  }
  return loadCatalogItemsByFamily(family)(dispatch, getState)
}
