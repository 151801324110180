import { SbEmitter } from 'skybase-ui/skybase-core/emitter'

import { equipmentEmitterEvents } from './constants'

export const initEquipmentListener = eventListener => {
  eventListener.on('equipmentAddedToInventory', message => {
    if (!message.isReplayed) {
      SbEmitter.emit(equipmentEmitterEvents.ADD_EQUIPMENT_BY_ID, message.equipmentId)
    }
  })
}
