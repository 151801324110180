import { getProjectDeviceById } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import { pathSegmentsFactory } from '@/fleet-configuration/data-fleet/project-devices/channel-utils'
import { getDeviceById } from '@/fleet-configuration/data-fleet/devices/devices-selectors'
import { areOnlyActiveChannelsShown } from '@/fleet-configuration/data-fleet/devices-show-only-active/devices-show-only-active-selector'

const getNavigationExpandedItems = state => state.entries.deviceNavigation

export const getNavigationSelectionId = (selectionItemType, deviceId, moduleIndex = '', channelIndex = '') => {
  return `${selectionItemType}|${deviceId}|${moduleIndex}|${channelIndex}`
}

export const getDeviceTraversalFromNavigationId = navigationSelectionIdentifier => {
  return navigationSelectionIdentifier
    .split('|')
    .slice(2)
    .filter(value => value)
}

const mapModuleToNavigationItem = (
  state,
  module,
  showOnlyActive,
  expandedItems,
  selectedDeviceId,
  selectedModuleId,
  selectedChannelId,
  baseUrl,
  rootId,
  computeDirtyState,
) => {
  const selectedModulePath = [selectedDeviceId, ...pathSegmentsFactory(selectedModuleId)].join('-')
  const selectedChannelPath = [selectedDeviceId, ...pathSegmentsFactory(selectedModuleId, selectedChannelId)].join('-')
  const deviceId = module.getDeviceId()
  const moduleIndex = module.getModuleId()
  const modulePath = module.getPath()
  let channels = []
  let hasSelectedChannel = false
  if (module.isSupported?.()) {
    if (expandedItems[modulePath] || (module.isVirtual() && expandedItems[deviceId])) {
      channels = module[showOnlyActive ? 'getActiveChannels' : 'getChannels']().map(channel => {
        const index = channel.getChannelIndex()
        const id = channel.path
        const isSelected = id === selectedChannelPath
        if (isSelected) {
          hasSelectedChannel = true
        }
        return {
          id,
          deviceId,
          moduleIndex,
          index,
          isSelected,
          isCurrentPage: isSelected,
          isDirty: computeDirtyState && channel.isDirty(),
          isActive: channel.isActive(),
          isExpanded: expandedItems[id],
          types: channel.types,
          url: `${baseUrl}${rootId || deviceId}/module/${moduleIndex}/channel/${index}`,
        }
      })
    } else {
      // channels are collapsed - we need to just know whether we have any...
      channels = showOnlyActive ? module.getActiveChannels() : module.getChannels()
    }
  }

  const nameBuilder = []
  if (module.name) {
    nameBuilder.push(module.name)
  }
  const moduleDisplayType = module.getType()
  if (moduleDisplayType) {
    nameBuilder.push(moduleDisplayType)
  }
  const name = nameBuilder.join(' | ')
  const { types = [] } = module

  return {
    id: modulePath,
    types,
    isSelected: !hasSelectedChannel && modulePath === selectedModulePath,
    isCurrentPage: modulePath === selectedModulePath && !selectedChannelId,
    hasSelectedChannel,
    index: types.some(type => /module/i.test(type)) ? moduleIndex : 'controller',
    isExpanded: expandedItems[modulePath],
    isDirty: computeDirtyState && module.isDirty(),
    isSupported: module.isSupported(),
    isController: module.isController(),
    isVirtual: module.isVirtual(),
    url: `${baseUrl}${rootId || deviceId}/module/${moduleIndex}`,
    deviceId,
    name,
    channels,
  }
}

export const wizardNavigationSelector = (state, props) => {
  const {
    router: {
      params: { unitId: urlParamsUnitId, moduleId, channelId },
    },
    baseUrl = '/configuration/settings/',
    rootId,
    navigationDevice,
    computeDirtyState = true,
  } = props
  const unitId = navigationDevice?.id || urlParamsUnitId

  const navigationExpandedItems = getNavigationExpandedItems(state)
  const onlyActive = areOnlyActiveChannelsShown(state)
  const device = navigationDevice || getProjectDeviceById(state, unitId)
  const navigation = []
  if (device) {
    const isSelected = device.id === unitId
    const deviceRow = {
      isSyncUnknown: device.isDeviceInUnknownSyncState?.(),
      isDirty: computeDirtyState && device.isDirty?.(),
      types: device.types,
      isSelected,
      isCurrentPage: isSelected && !moduleId,
      url: `${baseUrl}${rootId || device.id}`,
      name: (getDeviceById(state, device.id) || {}).name,
      id: device.id,
      isExpanded: navigationExpandedItems[device.id],
      modules: [],
    }
    navigation.push(deviceRow)
    if (navigationExpandedItems[device.id]) {
      const { controller } = device.deviceSpecific || {}
      if (controller) {
        const moduleRow = mapModuleToNavigationItem(
          state,
          controller,
          onlyActive,
          navigationExpandedItems,
          unitId,
          moduleId,
          channelId,
          baseUrl,
          rootId,
          computeDirtyState,
        )
        deviceRow.modules.push(moduleRow)
        if (moduleRow.isSelected) {
          deviceRow.isSelected = false
        }
      }
      const navigationModules = device.modules.map(module => {
        const moduleRow = mapModuleToNavigationItem(
          state,
          module,
          onlyActive,
          navigationExpandedItems,
          unitId,
          moduleId,
          channelId,
          baseUrl,
          rootId,
          computeDirtyState,
        )
        if (moduleRow.isSelected || moduleRow.hasSelectedChannel) {
          deviceRow.isSelected = false
        }
        return moduleRow
      })
      deviceRow.modules.push(...navigationModules)
    }
  }
  return {
    navigation,
    onlyActive,
  }
}
