/* eslint-disable react/no-array-index-key, no-nested-ternary */
import React, { PureComponent } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbModal, SbModalHeader } from 'skybase-ui/skybase-components/sb-modal'
import { SbLoaderButton, SbButton } from 'skybase-ui/skybase-components/sb-button'
import { SbLabel } from 'skybase-ui/skybase-components/sb-label'
import { SbCheckbox } from 'skybase-ui/skybase-components/sb-checkbox'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'

import { getValue } from '@/utils'
import { StatusBullet, RED, GREEN, ORANGE } from '@/common/status-bullet'

import { licenseShape } from './shapes'
import { messages as t } from './licenses-i18n'

class _AssignLicenseFromListModal extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    handleOnCloseModal: PropTypes.func.isRequired,
    onAssign: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(licenseShape).isRequired,
    licenseTicketId: PropTypes.string.isRequired,
    tenantName: PropTypes.string.isRequired,
    onBack: PropTypes.func.isRequired,
    ticketBelongsToCurrentTenant: PropTypes.bool.isRequired,
    ticketHasOwner: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      licenseIds: [],
    }
  }

  handleOnClose = () => {
    const { handleOnCloseModal } = this.props

    handleOnCloseModal()
  }

  handleOnConfirm = () => {
    const { licenseIds } = this.state
    const { onAssign } = this.props

    onAssign({ licenseIds })
  }

  handleOnLicenseClick = event => {
    const { value } = event.target
    const numericValue = Number(value)

    this.setState(prevState => {
      const { licenseIds: prevLicenseIds } = prevState
      return {
        licenseIds: prevLicenseIds.includes(numericValue)
          ? prevLicenseIds.filter(v => v !== numericValue)
          : prevLicenseIds.concat(numericValue),
      }
    })
  }

  renderHeader = () => {
    const {
      intl: { formatMessage: _ },
    } = this.props

    return <SbModalHeader title={_(t.assignNewLicense)} onCloseBtnClick={this.handleOnClose} />
  }

  renderFooter = () => {
    const { licenseIds } = this.state
    const { loading, onBack } = this.props
    const {
      intl: { formatMessage: _ },
    } = this.props

    return (
      <div className="sb-modal-footer fl-container fl-justify-end">
        <div className="sb-modal-left" />
        <div className="sb-modal-center" />
        <div className="sb-modal-right">
          <SbButton className="m-r-10" onClick={onBack} id="assign-license-modal-back-button">
            {_(t.back)}
          </SbButton>

          <SbLoaderButton
            loading={loading}
            disabled={loading || licenseIds.length === 0}
            className="primary"
            onClick={this.handleOnConfirm}
            id="assign-license-modal-assign-button"
          >
            {_(t.assign)}
          </SbLoaderButton>
        </div>
      </div>
    )
  }

  renderLicenses = () => {
    const { licenseIds } = this.state
    const {
      data,
      intl: { formatMessage: _ },
      loading,
      ticketBelongsToCurrentTenant,
      ticketHasOwner,
    } = this.props

    return data.map((license, i) => {
      const { itemName, productCode, quantity, orderIdentification, activated, ticketLicenseId } = license
      const activatedToCurrentTenant = activated && ticketBelongsToCurrentTenant
      const activatedLabel = activatedToCurrentTenant ? _(t.activated) : _(t.activatedByOther)
      const activatedBulletColor = activatedToCurrentTenant ? GREEN : ORANGE
      return (
        <div
          className="license-box sb-box m-t-10"
          key={`${license.ticketLicenseId}-${i}-licence`}
          id={`assign-license-item-${i}`}
        >
          <div className="left">
            <SbCheckbox
              checked={activatedToCurrentTenant || licenseIds.includes(ticketLicenseId)}
              disabled={activated || (ticketHasOwner && !ticketBelongsToCurrentTenant) || loading}
              onClick={this.handleOnLicenseClick}
              value={ticketLicenseId}
              className="license-details-assign-checkbox"
            />
          </div>
          <div className="right">
            <SbLabel title={_(t.licenseName)} inline className="license-details-name">
              {itemName}
            </SbLabel>
            <SbLabel title={_(t.productCode)} inline className="license-details-product-code">
              {getValue(productCode)}
            </SbLabel>
            <SbLabel title={_(t.quantity)} inline className="license-details-quantity">
              {getValue(quantity)}
            </SbLabel>
            <SbLabel title={_(t.orderIdentification)} inline className="license-details-orderid">
              {getValue(orderIdentification)}
            </SbLabel>
            <SbLabel title={_(t.activation)} inline className="license-details-activation">
              <StatusBullet className="no-word-break" status={activated ? activatedBulletColor : RED}>
                {activated ? activatedLabel : _(t.notActivated)}
              </StatusBullet>
            </SbLabel>
          </div>
        </div>
      )
    })
  }

  render() {
    const {
      licenseTicketId,
      tenantName,
      intl: { formatMessage: _ },
    } = this.props

    return (
      <SbModal
        Header={this.renderHeader()}
        Footer={this.renderFooter()}
        width={480}
        height="auto"
        className="assign-license-from-list-modal"
      >
        <div className="sb-message-modal-content">
          <FormattedMessage
            id="manage-licenses.selectLicenseFromList"
            defaultMessage="Below you can see a list of licenses belonging to your Ticket ID. Please select those <strong>you want to assign</strong>."
            values={{
              strong: text => <strong>{text}</strong>,
            }}
          />

          <div className="m-t-15">
            <SbLabel title={_(t.ticketId)} inline id="assign-license-modal-ticketid">
              {licenseTicketId.toUpperCase()}
            </SbLabel>

            <SbLabel title={_(t.tenantName)} inline id="assign-license-modal-tenant-name">
              {tenantName}
            </SbLabel>

            <div className="m-t-20">{this.renderLicenses()}</div>
          </div>
        </div>
      </SbModal>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleOnCloseModal: () => dispatch(closeModal()),
  }
}

export const AssignLicenseFromListModal = injectIntl(connect(null, mapDispatchToProps)(_AssignLicenseFromListModal))
