import { flatMap, uniqWith } from 'lodash'
import { getEntries } from '@/fleet-configuration/data-fleet/entries/entries-selectors'
import { getActiveProjectId } from '@/fleet-configuration/data-fleet/projects/projects-selectors'
// eslint-disable-next-line import/no-cycle
import { getProjectComponentById } from '../components/components-selectors'
import { getProjectDeviceModuleByComponentId } from '../project-devices/project-devices-selectors'

export const getProjectConnections = state => {
  const projectId = getActiveProjectId(state)
  return getEntries(state, 'entries.connections').filter(connection => connection.projectId === projectId)
}

export const isSourceConnectionDevice = (sourceConnection, deviceId, resourcePathOrPartOfIt) =>
  sourceConnection.deviceId === deviceId &&
  (sourceConnection.deviceResourcePath === resourcePathOrPartOfIt ||
    sourceConnection.deviceResourcePath.startsWith(`${resourcePathOrPartOfIt.replace(/\/$/, '')}/`))

/**
 * Extracts connections starting with sourceComponentId
 * @param state - redux store state
 * @param sourceComponentId
 * @param wholeChain - recursive search of all children
 * @returns {[]}
 */
export const getProjectConnectionsBySourceComponentId = (
  state,
  sourceComponentId,
  wholeChain = false,
  sourceSlot = undefined,
) => {
  const sourceComponent = getProjectComponentById(state, sourceComponentId)
  if (!sourceComponent) {
    console.error(`Component with id "${sourceComponentId}" not found`)
    return []
  }
  const { family, deviceId } = sourceComponent
  let connections = []
  if (family === 'module') {
    const module = deviceId && getProjectDeviceModuleByComponentId(state, deviceId, sourceComponentId)
    if (module) {
      if (sourceSlot || sourceSlot === 0) {
        const channel = module.getChannels()[sourceSlot]
        connections = getProjectConnections(state).filter(connection =>
          isSourceConnectionDevice(connection.source, deviceId, channel.resourcePath),
        )
      } else {
        connections = getProjectConnections(state).filter(connection =>
          isSourceConnectionDevice(connection.source, deviceId, module.resourcePath),
        )
      }
    }
  } else if (sourceSlot) {
    connections = getProjectConnections(state).filter(
      ({ source }) => source.componentId === sourceComponentId && source.slot === sourceSlot,
    )
  } else {
    connections = getProjectConnections(state).filter(({ source }) => source.componentId === sourceComponentId)
  }

  if (wholeChain) {
    const usedTargets = {}
    return [
      ...connections,
      ...flatMap(
        connections.filter(conn => conn.target),
        ({ target: { componentId, slot } }) => {
          // multi-connections (e.g. slot 1 & 2 of same module to same cable) create duplicates
          if (usedTargets[componentId]) {
            return []
          }
          usedTargets[componentId] = true

          return getProjectConnectionsBySourceComponentId(state, componentId, true, slot)
        },
      ),
    ]
  }
  return connections
}

export const getProjectConnectionsByTargetComponentId = (state, componentId) => {
  const connections = getProjectConnections(state).filter(({ target }) => target && target.componentId === componentId)
  return connections
}

export const hasMultipleChannelConnections = (state, componentId) => {
  const intoComponentConnections = getProjectConnectionsByTargetComponentId(state, componentId)
  // no data loaded yet
  if (!intoComponentConnections.length) {
    return false
  }

  const allSources = intoComponentConnections.filter(conn => conn.target).map(conn => conn.source)
  // unique by whether they are device-to-cable, or cable-to-sensor connections
  const sources = allSources[0].componentId
    ? uniqWith(allSources, (a, b) => a.componentId === b.componentId)
    : uniqWith(allSources, (a, b) => a.deviceId === b.deviceId && a.deviceResourcePath === b.deviceResourcePath)

  // if connected to at least 2 distinct items, then there must be at least 2 channels used
  if (sources.length > 1) {
    return true
  }

  // if this is direct device connection, then there is no possibility for multiple channels
  if (sources[0].deviceResourcePath) {
    return false
  }

  // otherwise count how many device connections does this middle-component (cable) have.
  //   meaning every other connection is another connected channel
  return (
    getProjectConnections(state).filter(({ target }) => target && target.componentId === sources[0].componentId)
      .length > 1
  )
}

export const getChannelConnections = (state, channel) => {
  const { resourcePath } = channel
  const projectConnections = getProjectConnections(state)
  const deviceId = channel.getDeviceId()
  return projectConnections.filter(conn => isSourceConnectionDevice(conn.source, deviceId, resourcePath))
}

// Note make this function return same object on last same input by object reference, not it's value
// this way less render calls for channel-parameters-form will be possible
const envVarsCache = new Map() // Using {} (or new Object() ) is incorrect
export const getEnvVarsAsObject = envVarsArray => {
  if (!envVarsCache.has(envVarsArray)) {
    envVarsCache.clear()
    const result = envVarsArray
      ? envVarsArray.reduce((acc, itm) => {
          acc[itm.type] = itm
          return acc
        }, {})
      : {}
    envVarsCache.set(envVarsArray, result)
  }
  return envVarsCache.get(envVarsArray)
}

export const getEnvVarsByChannel = (state, channel) => {
  const connection = getChannelConnections(state, channel)[0]
  if (connection) {
    return getEnvVarsAsObject(connection.source.mrsRanges)
  }
  return {}
}

// limited implementation of object intersection for this use-case
// I could not find library function for deep compare of objects
const objectIntersection = (source, target) => {
  // works also for arrays
  if (source && target && typeof source === 'object') {
    const sourceKeys = Object.keys(source)
    return sourceKeys.reduce((accumulator, key) => {
      const intersection = objectIntersection(source[key], target[key])
      if (intersection !== undefined) {
        accumulator[key] = intersection
      }
      return accumulator
    }, {})
  }
  // in this case we want to null & undefined values to be considered same
  const normalizedSource = !source && source !== 0 ? undefined : source
  const normalizedTarget = !target && target !== 0 ? undefined : target
  if (normalizedSource !== normalizedTarget) {
    return undefined
  }
  return source
}

export const getCommonEnvVarsByChannels = (state, channels) => {
  if (channels.length) {
    const [firstEnvVars, ...otherEnvVars] = channels.map(channel => getEnvVarsByChannel(state, channel))
    return otherEnvVars.reduce(
      (accumulator, channelEnvVars) => objectIntersection(accumulator, channelEnvVars),
      firstEnvVars,
    )
  }
  return {}
}
