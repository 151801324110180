import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { omit } from 'lodash'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { translateMessage } from 'skybase-ui/skybase-core/locales'
import { SbInlineMessage } from 'skybase-ui/skybase-components/sb-inline-message'
import { SbLabel } from 'skybase-ui/skybase-components/sb-label'
import { getFlattenedAlerts } from './get-flattened-alerts'
import './label.scss'

export const _Label = (
  {
    validation,
    children,
    intl: { formatMessage: _ },
    source = [],
    isDirty = false,
    className = '',
    inline = true,
    underline = true,
    renderAlerts = {},
    ...rest
  },
  context,
) => {
  const alerts = getFlattenedAlerts(context, validation, source)
  return (
    <>
      <SbLabel
        {...omit(rest, ['clearAlertsOnUnmount', 'onCommit', 'dispatch'])}
        inline={inline}
        className={classNames(className, {
          'control-dirty': isDirty,
          underlined: inline && underline,
        })}
      >
        <div className={`${inline ? 'fl-container-row' : 'fl-container'}`}>{children}</div>
      </SbLabel>
      {alerts.length ? (
        <div className="label-alerts">
          {alerts.map((alert, index) => (
            <SbInlineMessage
              {...omit(alert, ['backUrl', 'componentId'])}
              key={alert.id || `label-alert-${index}`}
              title={translateMessage(_, alert.title, alert.parameters)}
              message={
                // Use custom alert rendering function if provided (otherwise render text)
                alert.componentId in renderAlerts
                  ? renderAlerts[alert.componentId](alert)
                  : translateMessage(_, alert.message, alert.parameters)
              }
            />
          ))}
        </div>
      ) : null}
    </>
  )
}

_Label.propTypes = {
  validation: PropTypes.object.isRequired,
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  children: PropTypes.node.isRequired,
  isDirty: PropTypes.bool,
  inline: PropTypes.bool,
  intl: intlShape.isRequired,
  className: PropTypes.string,
  underline: PropTypes.bool,
  renderAlerts: PropTypes.objectOf(PropTypes.func),
}

_Label.contextTypes = {
  formNames: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const selector = state => ({ validation: state.validation })

export const Label = injectIntl(connect(selector)(_Label))
