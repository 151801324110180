/**
 * @description
 * Defines a property name that will be used in the Redux state.
 *
 * @type {string}
 */
export const STATE_KEY_NAME = 'modals'

export const SIMPLE_MODAL_ID = 'SIMPLE_MODAL_ID'

export const buttonPositions = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
}
