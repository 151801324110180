import {
  loadFirmware,
  setFirmwareData,
  setFirmwareUpdateStatus,
} from '@/fleet-configuration/data-fleet/firmware/firmware-actions'
import {
  firmwareEventOperationNames,
  firmwareStates,
} from '@/fleet-configuration/data-fleet/firmware/firmware-constants'
import { getFirmwareUpdateReasonByCode } from '@/fleet-configuration/data-fleet/firmware/firmware-utils'
import { showErrorToast, showSuccessToast } from '@/common/services/show-toast'
import { getDeviceById } from '@/fleet-configuration/data-fleet/devices/devices-selectors'
import { messages as t } from './firmware-i18n'
import { batchActions } from 'redux-batched-actions'

export const initFirmwareEventListener = (eventListener, dispatch, getState, _) => {
  eventListener.on('deviceSoftwareUpdate', eventData => {
    const firmwareUpdateActions = [setFirmwareUpdateStatus(eventData.deviceId, eventData)]
    // change firmware state immediately, it will be rewritten by consecutive load, but until that happens dipsplay something reasonable
    if (
      eventData.operationResultCode === 1 &&
      eventData.operationName === firmwareEventOperationNames.ENDED &&
      !eventData.isReplayed &&
      eventData.deviceId
    ) {
      firmwareUpdateActions.push(
        setFirmwareData(eventData.deviceId, {
          version: _(t.pending),
          state: firmwareStates.UP_TO_DATE,
          newVersion: null,
        }),
      )
    }
    dispatch(batchActions(firmwareUpdateActions))
    if (eventData.operationName === firmwareEventOperationNames.ENDED && !eventData.isReplayed && eventData.deviceId) {
      dispatch(loadFirmware(eventData.deviceId))
      const deviceName = getDeviceById(getState(), eventData.deviceId)?.name
      if (deviceName) {
        if (eventData.operationResultCode === 1) {
          showSuccessToast(_(t.theFirmwareUpdateOfTheDeviceDeviceFinishedSuccessfully, { device: deviceName }))
        } else {
          showErrorToast(
            _(t.theFirmwareUpdateOfTheDeviceDeviceFailedReasonReason, {
              reason: getFirmwareUpdateReasonByCode(eventData.operationResultCode, _),
              device: deviceName,
            }),
          )
        }
      }
    }
  })
}
