import { getEntries } from '@/fleet-configuration/data-fleet/entries/entries-selectors'
import { DEVICE_CALIBRATION_PRE_EXPIRATION_WARNING_DAYS } from '@/fleet-configuration/data-fleet/devices-calibrations/devices-calibrations-constants'
import { intersection } from 'lodash'
import { NOT_CALIBRABLE_DEVICE_TYPES } from '@/fleet-configuration/pages/fleet-overview/fleet-overview-constants'
import { getDeviceById } from '@/fleet-configuration/data-fleet/devices/devices-selectors'

export const getDeviceCalibrations = state => getEntries(state, 'entries.devicesCalibrations')

export const getDeviceCalibrationsByDeviceId = (state, deviceId) => state.entries.devicesCalibrations.byId[deviceId]

export const getExtendedDeviceCalibrationByDeviceId = (state, deviceId) => {
  const deviceCalibrations = getDeviceCalibrationsByDeviceId(state, deviceId)
  const device = getDeviceById(state, deviceId)
  let isCalibrationExpiring = false
  if (deviceCalibrations) {
    const calibrationExpires = new Date(deviceCalibrations.calibrationDate)
    calibrationExpires.setDate(calibrationExpires.getDate() + deviceCalibrations.calibrationIntervalDays)
    const expirationWarningDate = new Date()
    expirationWarningDate.setDate(expirationWarningDate.getDate() + DEVICE_CALIBRATION_PRE_EXPIRATION_WARNING_DAYS)
    isCalibrationExpiring =
      expirationWarningDate.getTime() > calibrationExpires.getTime() && deviceCalibrations.calibrationDate
  }
  const isDeviceCalibrationLoaded = !!deviceCalibrations && !deviceCalibrations.hasRequestError
  // Ignore KGATE_DEVICE_TYPE and FORCE_PLATE_SYNC_BOX types as they are not calibrable
  const isDeviceCalibrable = device && !intersection(NOT_CALIBRABLE_DEVICE_TYPES, device.types).length

  return {
    ...deviceCalibrations,
    isExpiring: isCalibrationExpiring,
    isLoaded: isDeviceCalibrationLoaded,
    isCalibrable: isDeviceCalibrable,
  }
}
