import { uniq } from 'lodash'
import { createSelector } from 'reselect'
import { memoSelector } from '@/utils/memo-selector'
import { getProjectDeviceById } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import {
  getChainEntries,
  getChainsByModuleDetails,
  isChainLoaded,
} from '@/fleet-configuration/data-fleet/chain/chain-selectors'
import {
  getChainCatalogEntries,
  getChainCatalogEntry,
} from '@/fleet-configuration/data-fleet/chain-catalog/chain-catalog-selectors'
import {
  getCertificateRecordByTypeNumberAndSerialNumber,
  getChainCertificateEntries,
  getMaxRangeUnitFromCertificate,
  getSensitivityPerMaxRangeFromSensorCertificate,
  getSensorDisplayDataFromCatalog,
} from '@/fleet-configuration/data-fleet/chain-certificate/chain-certificate-selector'

const getDeviceModulesChain = memoSelector(
  (state, props, { getAreDependencyArgsTheSame, getCachedResult, setCachedResult }) => {
    const {
      router: {
        params: { unitId },
      },
    } = props
    const device = getProjectDeviceById(state, unitId)
    if (!device) {
      return null
    }
    const chainEntries = getChainEntries(state)
    // we have dependency on actual chain from redux - we are fetching data from it so if it changes, invalidate cache too
    if (getAreDependencyArgsTheSame(chainEntries, device)) {
      return getCachedResult()
    }
    const deviceModulesTypeNumberAndSerialNumber = device.modules.reduce((acc, module) => {
      acc.push({ typeNumber: module.getType(), serialNumber: module.parametersReadable?.snr || device.serialNumber })
      return acc
    }, [])
    return setCachedResult(
      deviceModulesTypeNumberAndSerialNumber.map(({ typeNumber, serialNumber }) =>
        getChainsByModuleDetails(state, typeNumber, serialNumber),
      ),
    )
  },
)

const getEnrichedDeviceModulesChain = memoSelector(
  (state, props, { getAreDependencyArgsTheSame, getCachedResult, setCachedResult }) => {
    const deviceModuleChain = getDeviceModulesChain(state, props)
    // we have dependency on actual chain from redux - we are fetching data from it so if it changes, invalidate cache too
    if (
      getAreDependencyArgsTheSame(deviceModuleChain, getChainCatalogEntries(state), getChainCertificateEntries(state))
    ) {
      return getCachedResult()
    }
    return setCachedResult(
      deviceModuleChain.map(moduleChain =>
        moduleChain.map(channelChain => {
          if (!channelChain.sensor?.typeNumber) {
            return channelChain
          }
          const enrichedChannelChain = { ...channelChain }
          const sensorCatalog = getChainCatalogEntry(state, channelChain.sensor.typeNumber)
          const sensorCertificateRecord =
            channelChain.sensor.serialNumber &&
            getCertificateRecordByTypeNumberAndSerialNumber(
              state,
              channelChain.sensor.typeNumber,
              channelChain.sensor.serialNumber,
            )
          enrichedChannelChain.sensor = {
            ...channelChain.sensor,
            sensitivityAtMaxRange:
              sensorCertificateRecord?.certificate &&
              getSensitivityPerMaxRangeFromSensorCertificate(sensorCertificateRecord.certificate),
            maxPhysicalRangeUnit:
              sensorCertificateRecord?.certificate &&
              getMaxRangeUnitFromCertificate(sensorCertificateRecord.certificate),
            certificate: sensorCertificateRecord?.certificate,
            catalog: sensorCatalog ? getSensorDisplayDataFromCatalog(sensorCatalog) : null,
          }
          return enrichedChannelChain
        }),
      ),
    )
  },
)

const getChainComponentTypes = memoSelector(
  (state, props, { getAreDependencyArgsTheSame, getCachedResult, setCachedResult }) => {
    const deviceModulesChain = getDeviceModulesChain(state, props)
    if (getAreDependencyArgsTheSame(deviceModulesChain)) {
      return getCachedResult()
    }
    const componentTypes = uniq(
      deviceModulesChain.reduce((acc, moduleChains) => {
        moduleChains.forEach(channelChain => {
          if (channelChain.sensor?.typeNumber) {
            acc.push(channelChain.sensor.typeNumber)
          }
          if (channelChain.cable?.typeNumber) {
            acc.push(channelChain.cable.typeNumber)
          }
        })
        return acc
      }, []),
    )
    return setCachedResult(componentTypes)
  },
)

const getSensorTypeAndSerialNumbers = memoSelector(
  (state, props, { getAreDependencyArgsTheSame, getCachedResult, setCachedResult }) => {
    const deviceModulesChain = getDeviceModulesChain(state, props)
    if (getAreDependencyArgsTheSame(deviceModulesChain)) {
      return getCachedResult()
    }
    return setCachedResult(
      deviceModulesChain.reduce((acc, moduleChains) => {
        moduleChains.forEach(channelChain => {
          if (channelChain.sensor?.typeNumber && channelChain.sensor.serialNumber) {
            acc.push({ typeNumber: channelChain.sensor.typeNumber, serialNumber: channelChain.sensor.serialNumber })
          }
        })
        return acc
      }, []),
    )
  },
)

export const MeasurementUnitDetailChannelSmartValuesSelector = createSelector(
  [
    getEnrichedDeviceModulesChain,
    getChainComponentTypes,
    getSensorTypeAndSerialNumbers,
    getChainCatalogEntries,
    isChainLoaded,
  ],
  (enrichedDeviceModulesChain, chainComponentTypes, sensorTypeAndSerialNumbers, chainCatalog, chainLoaded) => ({
    enrichedDeviceModulesChain,
    chainComponentTypes,
    sensorTypeAndSerialNumbers,
    chainCatalog,
    chainLoaded,
  }),
)
