import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { openModal } from 'skybase-ui/skybase-core/base/actions'
import { SbButton } from 'skybase-ui/skybase-components/sb-button'
import { EQUIPMENT_MODAL_ID } from '../constants'
import { messages as t } from '../equipment-i18n'

export const CreateEquipmentButton = () => {
  const { formatMessage: _ } = useIntl()
  const dispatch = useDispatch()

  return (
    <SbButton
      id="create-equipment-button"
      className="sb-btn-with-icon-text primary"
      onClick={() => dispatch(openModal(EQUIPMENT_MODAL_ID))}
    >
      <i className="sbi-plus" />
      {_(t.addNew)}
    </SbButton>
  )
}
