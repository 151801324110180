import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useIntl } from 'react-intl'
import { purposes } from '@/common/announcement/constants'
import { getStudioAPIHost } from '@/utils/url'
import './announcement.scss'

let showUntilId
export const Announcement = () => {
  const [announcementsData, setAnnouncementsData] = useState('')
  const [isOpen, setIsOpen] = useState(true)
  const intl = useIntl()

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await axios.get(`${getStudioAPIHost()}/api/announcement`)
        setAnnouncementsData(data)
      } catch (e) {
        console.error(e)
      }
    })()
    return () => {
      clearTimeout(showUntilId)
    }
  }, [])

  const { message: intlMessages, options = {} } = announcementsData || {}
  if (!intlMessages) {
    return null
  }

  if (options.showUntil) {
    const now = new Date()
    const showUntil = new Date(options.showUntil)
    if (isNaN(showUntil)) {
      console.error('Wrong format for showUntil. Provided value is: ', options.showUntil)
      return null
    }
    // if already expired
    if (showUntil.getTime() < now.getTime()) {
      return null
    }
    // schedule hiding of announcement
    clearTimeout(showUntilId)
    showUntilId = setTimeout(setAnnouncementsData, showUntil.getTime() - now.getTime(), null)
  }

  const displayMessage =
    intlMessages[intl.locale] || intlMessages[intl.locale.replace(/[_-].*$/, '')] || intlMessages.fallback
  if (!displayMessage || !isOpen) {
    return null
  }

  const purpose = purposes[options.purpose?.toUpperCase?.()] || purposes.NOTICE

  return (
    <div className={`announcement ${purpose}`}>
      <span>{displayMessage}</span>
      <span onClick={() => setIsOpen(false)} className="sbi-close announcement-close" />
    </div>
  )
}
