import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { Sb21Layout } from 'skybase-ui/skybase-components/layouts'
import { SbDynamicTabs, SbTab } from 'skybase-ui/skybase-components/sb-dynamic-tabs'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { STATES } from 'skybase-oauth/constants'
import { SbNotFoundPage } from 'skybase-pages'

import { withRouter } from '@/common/router'
import { removeFirstCharIfExists } from '@/utils'
import { getIotHubDeviceDetailsState } from '@/iot-hub/selectors'
import {
  fetchDevice,
  fetchResource,
  deviceShape,
  resourceStructures,
  ResourcesList,
  ResourceDetails,
  getColorsForResource,
} from '@/iot-hub/components/devices'
import { subscribeToDevicesStatusWS } from '@/iot-hub/components/devices/utils'
import { messages as menuT } from '@/common/menu/menu-i18n'

import { getResourcePathFromParams } from './utils'
import { messages as t } from './resources-page-i18n'

const { ARRAY } = resourceStructures

class _ResourcesPage extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    deviceData: deviceShape,
    handleFetchDevice: PropTypes.func.isRequired,
    handleFetchResource: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    router: PropTypes.shape({
      params: PropTypes.shape({
        deviceId: PropTypes.string,
        resourcePath: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }

  static defaultProps = {
    deviceData: {},
  }

  componentDidMount() {
    const {
      handleFetchDevice,
      handleFetchResource,
      router: { params, params: { deviceId } = {} },
    } = this.props

    const resourcePath = getResourcePathFromParams(params)

    if (deviceId) {
      handleFetchDevice(deviceId, () => {
        // Event used in the guided tour
        SbEmitter.emit('page-loaded', 'resources')

        subscribeToDevicesStatusWS()

        if (resourcePath) {
          handleFetchResource(resourcePath)
        }
      })
    }
  }

  renderPage = () => {
    const {
      intl: { formatMessage: _ },
      deviceData,
      deviceData: { resources },
      router: { params },
    } = this.props
    const matchResourcePath = getResourcePathFromParams(params)
    const interfacesColors = getColorsForResource(resources, 'interfaces')
    const typesColors = getColorsForResource(resources, 'types')

    return [
      <div key="resources-page-header">
        <h1>{_(t.pageName)}</h1>
        <ResourcesList
          device={deviceData}
          interfacesColors={interfacesColors}
          typesColors={typesColors}
          matchResourcePath={matchResourcePath}
        />
      </div>,
      <SbDynamicTabs key="resources-page-tab">
        <SbTab tabId="resource-details">
          <SbTab.Title>{_(t.details)}</SbTab.Title>
          <SbTab.Content>
            <ResourceDetails interfacesColors={interfacesColors} typesColors={typesColors} />
          </SbTab.Content>
        </SbTab>
      </SbDynamicTabs>,
    ]
  }

  renderError = message => {
    return <SbNotFoundPage {...this.props} message={message} />
  }

  render() {
    const {
      intl: { formatMessage: _ },
      deviceData: { id, name, resources } = {},
      router: { params, params: { deviceId: matchDeviceId } = {} },
      loading,
    } = this.props
    const matchResourcePath = getResourcePathFromParams(params)
    const deviceExists = id && resources && !loading
    const breadcrumbs = deviceExists
      ? [
          {
            path: '/',
            title: _(menuT.home),
          },
          {
            path: '/iot-hub',
            title: _(menuT.iotHub),
          },
          {
            path: '/iot-hub/devices',
            title: _(menuT.devices),
          },
          {
            path: `/iot-hub/devices/${id}`,
            title: name,
          },
          _(t.pageName),
        ]
      : null

    // If the device is loaded, and the resourcePath is in the URL params, the param must be in the list of resources.
    if (deviceExists) {
      if (matchResourcePath) {
        const resourceNotFound =
          resources.findIndex(resource => removeFirstCharIfExists(resource.href, '/') === matchResourcePath) === -1

        if (resourceNotFound) {
          return this.renderError(_(t.deviceResourceNotFound, { resourcePath: matchResourcePath }))
        }
      }
    }

    // If id or resource is not set and the app is no loading anymore, it means the device was not found.
    if ((!id || !resources) && !loading) {
      return this.renderError(_(t.deviceNotFound, { deviceId: matchDeviceId }))
    }

    return (
      <Sb21Layout title={_(t.pageName)} breadcrumbs={breadcrumbs}>
        {!loading ? (
          this.renderPage()
        ) : (
          <div className="fl-row fl-justify-center fl-align-items-center">
            <SbLoader textWrapperClassName="sb-width-ml" show loadingText={_(t.loadingResources)} size={30} />
          </div>
        )}
      </Sb21Layout>
    )
  }
}

const mapStateToProps = state => {
  const { data: deviceData, state: fetchState } = getIotHubDeviceDetailsState(state)

  return {
    deviceData,
    loading: fetchState === STATES.LOADING,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleFetchDevice: (deviceId, callback) => dispatch(fetchDevice(deviceId, ARRAY, callback)),
    handleFetchResource: resourcePath => dispatch(fetchResource(resourcePath)),
  }
}

export const ResourcesPage = withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(_ResourcesPage)))
