import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { showErrorToast, showInfoToast } from '@/common/services/show-toast'
import { updateChain } from '@/fleet-configuration/data-fleet/chain/chain-actions'
import { messages as t } from './channel-menu-i18n'
import './channel-menu.scss'

export const ChannelMenu = ({ channelChain, channelIndex, className = '' }) => {
  const { formatMessage: _ } = useIntl()
  const [menuOpen, setMenuOpen] = useState(false)
  const [showClearButton, setShowClearButton] = useState(false)
  const menuRef = useRef()
  const dispatch = useDispatch()
  const cableObject = channelChain?.cable?.typeNumber || null
  const sensorObject = channelChain?.sensor?.typeNumber || null

  useEffect(() => {
    // Check if either cableObject or sensorObject is not null
    const shouldShowClearButton = !isEmpty(cableObject) || !isEmpty(sensorObject)
    setShowClearButton(shouldShowClearButton)
  }, [cableObject, sensorObject])

  const toggleMenu = () => {
    setMenuOpen(prevMenuOpen => !prevMenuOpen)
  }

  const handleClearClick = async () => {
    const updatedChannelChain = {
      ...channelChain,
      cable: {}, //clear cable
      sensor: {}, //clear sensor
      userPreferences: {}, //clear userPreferences
    }

    try {
      await dispatch(updateChain(updatedChannelChain))
    } catch (e) {
      showErrorToast(_(t.systemWasUnableToUpdateComponents))
    }

    showInfoToast(_(t.componentsOfChannelCleared, { channelIndex: channelIndex + 1 }))
    setMenuOpen(false)
  }

  const handleClickOutside = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <span className="channel-edit-kebab-menu" ref={menuRef}>
      <button
        title={_(t.menu)}
        type="button"
        className={classNames('sbi-dots-vertical channel-menu-button', { open: menuOpen }, className)}
        onClick={toggleMenu}
      >
        {_(t.empty)}
      </button>
      {menuOpen && (
        <ul className="menu-items test-channel-menu">
          <li>
            <button type="button" className="menu-item sbi-edit-kids test-rename-button icon-disabled">
              {_(t.rename)}
            </button>
          </li>
          {showClearButton && (
            <li>
              <button type="button" className="menu-item sbi-minus-circle test-clear-button" onClick={handleClearClick}>
                {_(t.clear)}
              </button>
            </li>
          )}
        </ul>
      )}
    </span>
  )
}

ChannelMenu.propTypes = {
  channelChain: PropTypes.object.isRequired,
  channelIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
}
