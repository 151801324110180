import { OAuth } from 'skybase-oauth'

export const getStudioAPIHost = (isWSEndpoint = false) => {
  if (isWSEndpoint) {
    if (OAuth.config.STUDIO_WS_API_ENDPOINT_URL) {
      return OAuth.config.STUDIO_WS_API_ENDPOINT_URL
    }
    return (OAuth.config.STUDIO_API_ENDPOINT_URL || `${location.protocol}//studio.${location.host}`).replace(
      /^http/i,
      'ws',
    )
  }
  return OAuth.config.STUDIO_API_ENDPOINT_URL || `${location.protocol}//studio.${location.host}`
}

export const getWSProtocol = () => (window.location.protocol === 'https:' ? 'wss' : 'ws')
