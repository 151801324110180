import { getProjectDevices } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'

export const selectMeasurementUnitParams = (state, props) => {
  const {
    router: {
      params: { unitId, moduleId, channelId, componentId },
    },
    measurementDevice,
  } = props
  let resolvedUnitId = measurementDevice?.id || unitId
  if (!resolvedUnitId) {
    const [firstProjectDevice] = getProjectDevices(state)
    if (firstProjectDevice) {
      resolvedUnitId = firstProjectDevice.id
    }
  }
  const isControllerModule = moduleId === 'controller'
  return {
    unitId: resolvedUnitId,
    isControllerModule,
    channelIndex: channelId && parseInt(channelId, 10),
    moduleIndex: moduleId && (isControllerModule ? moduleId : parseInt(moduleId, 10)),
    componentId,
  }
}
