import { useEffect, useState, useCallback } from 'react'
import { isEmpty } from 'lodash'
import uniq from 'lodash/uniq'
import { OAuth } from 'skybase-oauth'
import { getStudioAPIHost } from '@/utils/url'
import { areSkydaqAPISDisabled } from '@/utils'
import { equipmentApi } from './constants'

const { fetchOAuth } = OAuth

export const fetchComponentByType = type =>
  fetchOAuth(`${getStudioAPIHost()}${equipmentApi.COMPONENTS_TYPE}?moduleType=${encodeURIComponent(type)}`)

let equipmentPromise
export const useEquipment = () => {
  const [data, setData] = useState([])
  const [error, setError] = useState()
  const [loading, setLoading] = useState(!areSkydaqAPISDisabled())

  // Transform the null value of the family to empty string to make the sorting of this field work
  // @todo should be fixed in skybase-ui, then this line can be removed
  const transformNullFamily = d => ({ ...d, family: d.family ?? '' })

  const doFetch = useCallback(() => {
    setLoading(true)
    if (!equipmentPromise) {
      equipmentPromise = fetchOAuth(`${getStudioAPIHost()}${equipmentApi.EQUIPMENT}`)
    }
    equipmentPromise
      .then(response => setData(response.map(transformNullFamily) || []))
      .catch(setError)
      .finally(() => {
        equipmentPromise = null
        setLoading(false)
      })
  }, []) // eslint-disable-line

  const doFetchById = useCallback(id => {
    fetchOAuth(`${getStudioAPIHost()}${equipmentApi.EQUIPMENT}/${id}`)
      .then(response =>
        setData(equipmentList =>
          equipmentList
            .filter(e => e.id !== response.id) // Precaution for having duplicate equipment in the list
            .concat(transformNullFamily(response)),
        ),
      )
      .catch(setError)
  }, [])

  useEffect(() => {
    if (!areSkydaqAPISDisabled()) {
      doFetch()
    }
  }, [doFetch])

  return { data, error, loading, refetch: doFetch, addEquipmentById: doFetchById }
}

export const useComponents = () => {
  const [types, setFetchedTypes] = useState([])
  const [data, setData] = useState([])
  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)

  const doFetch = moduleTypes => {
    setLoading(true)

    const newTypes = uniq(
      moduleTypes
        .filter(({ typeNumber }) => typeNumber.length >= 5 && !types.includes(typeNumber))
        .map(({ typeNumber }) => typeNumber.toUpperCase()),
    )

    Promise.allSettled(newTypes.map(fetchComponentByType))
      .then(values => {
        const fullfilledValues = values
          .filter(({ status, value }) => status === 'fulfilled' && !isEmpty(value))
          .map(({ value }) => value)

        setData(prevData => [
          ...prevData,
          ...fullfilledValues
            .filter(v => !isEmpty(v))
            .map(({ icon, kistlerType, parameters: { deviceDocUrl } = {} }) => ({ icon, kistlerType, deviceDocUrl })),
        ])

        setFetchedTypes(prevTypes => uniq([...prevTypes, ...newTypes]))
      })
      .catch(setError)
      .finally(() => setLoading(false))
  }

  return { data, error, loading, fetchComponents: doFetch }
}
