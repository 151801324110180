import { some, uniq, flatten, isNil } from 'lodash'
import {
  getDeviceDirtyState,
  isDevicePartDirty,
} from '@/fleet-configuration/data-fleet/project-devices-dirty/project-devices-dirty-selector'
import {
  getComponentFromCatalog,
  getConfigurationOptions,
  getConfigurationParameters,
} from '@/fleet-configuration/data-fleet/catalog/catalog-selectors'
import {
  ACQUISITION_CHANNEL,
  SUPPORTED_CONTROLLER_TYPES,
  VIRTUAL_MODULE,
} from '@/fleet-configuration/data-fleet/catalog/catalog-constants'
import { setProjectDeviceDirtyProps } from '@/fleet-configuration/data-fleet/project-devices-dirty/project-devices-dirty-actions'
import { urlPathController } from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation-constants'
import { arraysHaveSameItem } from '@/utils/diff'
import { mapOptions } from '@/common/options/map-options'
import { channelFactory } from './channel-factory'
import { getChannelCatalogByTypes } from './channel-catalog-factory'
import { unsupportedChannelFactory } from './unsupported-channel-factory'
import { getFieldName, mirrorDataFromSpecificSourceToParameters, pathSegmentsFactory } from './channel-utils'

export const moduleFactory = (dispatch, getState, module, sourceDevice, moduleId) => {
  let device = sourceDevice
  const deviceId = device.id
  const pathSegments = pathSegmentsFactory(moduleId)
  const component = getComponentFromCatalog(getState(), module.types)
  const moduleConfigOptions = getConfigurationOptions(component.data, module.types)

  let cachedPath
  class ModuleModel {
    constructor(data) {
      Object.assign(this, data)

      this.initChannels()
      this.initSamplingRate()
    }

    updateDeviceReference(deviceRef) {
      device = deviceRef
    }

    initChannels() {
      if (this.isSupported()) {
        const mirrorResourceFields = uniq(
          component.data.reduce((acc, item) => {
            acc.push(...(item.mirrorResourcesFields || []))
            return acc
          }, []),
        )
        this.channels = (this.channels || []).map((channel, channelIndex, channels) => {
          const catalogTypes = channel.types.concat(
            Object.values(channel.extraTypes || {}),
            ...flatten(
              mirrorResourceFields.map(field => (channel.deviceSpecific[channel.parameters[field]] || {}).types || []),
            ),
          )
          const catalog = getChannelCatalogByTypes(getState, module.types, catalogTypes, component)
          this.applyInitialChannelSpanning(channel, channelIndex, channels, catalog)
          const updatedChannelData = mirrorDataFromSpecificSourceToParameters(channel, catalog)
          this.applyDataInitialization(updatedChannelData, catalog)
          const channelModel = channelFactory(
            dispatch,
            getState,
            updatedChannelData,
            deviceId,
            moduleId,
            channelIndex,
            this,
            catalog,
          )
          return channelModel
        })
      } else {
        this.channels = (this.channels || []).map((channel, channelIndex) =>
          unsupportedChannelFactory(channel, deviceId, moduleId, channelIndex),
        )
      }
    }

    applyInitialChannelSpanning(channelDto, channelIndex, allChannels, catalog) {
      // optimization step. If this function will not do anything, just skip it
      if (!channelDto.parameters.enabled && channelDto.parameters.channelSpanState) {
        return
      }
      const enumsConfigOptions = catalog.getAllEnums()
      let channelSpanningOption = null
      some(enumsConfigOptions, (configOption, field) => {
        const value = channelDto.parameters[field]
        const usedOption = catalog.getEnumOption(field, value)
        if (usedOption && usedOption.channelsUsed > 0) {
          channelSpanningOption = usedOption
          return true
        }
        return false
      })
      if (channelSpanningOption) {
        if (!channelDto.parameters.channelSpanState) {
          // this is initialization. We are mutating/preparing data for model which is OK
          // eslint-disable-next-line no-param-reassign
          channelDto.parameters.channelSpanState = { span: channelSpanningOption.channelsUsed }
        }
        // disabled channels cannot span over other channels
        if (!channelDto.parameters.enabled) {
          return
        }

        for (let i = channelIndex + 1; i < channelIndex + channelSpanningOption.channelsUsed; i += 1) {
          const updateChannel = allChannels[i]
          // do not overwrite existing spanning settings
          if (
            !updateChannel.parameters.channelSpanState ||
            updateChannel.parameters.channelSpanState.lockedBy !== channelIndex
          ) {
            updateChannel.parameters.channelSpanState = {
              previouslyEnabled: updateChannel.parameters.enabled,
              lockedBy: channelIndex,
            }
            updateChannel.parameters.enabled = false
          }
        }
      }
    }

    /*
     * we are looking for value that would be allowed by dependant field
     *
     * meaning if we e.g. take unit and physicalQuantity and we have unit N then physicalQuantity
     * needs to be force (as force units contain newtons, but e.g. charge units do not)
     * */
    applyDataInitialization(channel, catalog) {
      this.initCustomUnitPhysicalQuantity(channel)
      catalog.getInitializingFields().forEach(fieldToCheck => {
        const fieldToCheckOptions = catalog.getEnum(fieldToCheck)
        const fieldToCheckValue = channel.parameters[fieldToCheck]
        const fieldToCheckValueOptions = fieldToCheckOptions.filter(o => o.value === fieldToCheckValue)
        const checkOptionGroups = fieldToCheckValueOptions.map(option => option.group)

        if (checkOptionGroups.length) {
          catalog.getInitializationFieldsOfField(fieldToCheck).forEach(fieldToInit => {
            const field = getFieldName(fieldToInit)
            // already initialized (empty string CAN be valid value even if it's falsy)?
            if (
              channel.parameters[field] ||
              !catalog.hasField(field) ||
              catalog.getEnumOption(field, channel.parameters[field])
            ) {
              return
            }

            const options = catalog.getEnum(field)
            let foundOption
            // check if filtration by grouping is applied
            if (checkOptionGroups.some(optionGroup => !isNil(optionGroup))) {
              options.some(option =>
                (option.filters || []).some(filter => {
                  if (
                    getFieldName(filter.field) === fieldToCheck &&
                    arraysHaveSameItem((filter.values || {}).groups || [], checkOptionGroups)
                  ) {
                    foundOption = option
                    return true
                  }
                  return false
                }),
              )
            } else if (fieldToCheckOptions.some(option => option.initializes)) {
              // check if initialization by explicit initializes catalog property is used

              // fetch all options that correspond to current value - it should be 1 item only
              // but if there are some duplicates, then fetch all of them and combine them to one
              // in the end we hope that just one of them initializes this field = so they don't
              // overwrite. If that would be the case, we would need to fix it on catalog side
              const initializingProperties = fieldToCheckValueOptions.reduce((acc, item) => {
                acc.push(...(item?.initializes || []))
                return acc
              }, [])
              initializingProperties.forEach(({ value, field: initField }) => {
                if (initField === fieldToInit) {
                  foundOption = options.find(option => option.value === value)
                }
              })
            } else {
              // no specific rules found, we need to just initialize this array to "some" value - so
              // use first value available

              // eslint-disable-next-line prefer-destructuring
              foundOption = options[0]
            }

            if (foundOption) {
              // eslint-disable-next-line no-param-reassign
              channel.parameters[field] = foundOption.value
            }
          })
        }
      })
    }

    initCustomUnitPhysicalQuantity(channel) {
      if (!channel.parameters.physicalQuantity && channel.parameters.customUnit) {
        // eslint-disable-next-line no-param-reassign
        channel.parameters.physicalQuantity = 'Custom Unit'
      }
    }

    initSamplingRate() {
      const activeChannel = this.getActiveChannels()[0]
      if (activeChannel) {
        this.samplingRate = activeChannel.parameters.dataSourceId
      } else {
        // prevent marking of module as dirty when no active channel exists
        // NOTE: sync state does not preserve this parameter, but project service does
        delete this.samplingRate
      }
    }

    getModuleConfigurationOptions(field) {
      return field === undefined ? moduleConfigOptions : moduleConfigOptions[field]
    }

    getModuleConfigurationParameters(field) {
      const parameters = getConfigurationParameters(component.data, this.types)
      return field === undefined ? parameters : parameters[field]
    }

    getInputLevelNames() {
      const options = this.getModuleConfigurationOptions()
      return Object.keys(options).filter(key => key.startsWith('inputLevel'))
    }

    getOptions(field, shouldTranslateOptions = false) {
      const options = this.getModuleConfigurationOptions(field)

      if (!options || !options.enum) {
        console.error(`Configuration for enum field ${field} of module ${this.getType()} not found in the catalog`)
        return []
      }

      let visibleOptions = options.enum.filter(o => o.visible !== false)
      if (shouldTranslateOptions) {
        visibleOptions = visibleOptions.map(option => ({
          ...option,
          rawDescription: option.description,
        }))
      }
      return mapOptions(visibleOptions, 'value', ['description', 'rawDescription'])
    }

    getMaxSamplingRate() {
      const { maxSamplingRate } = getConfigurationParameters(component && component.data, this.types)
      return maxSamplingRate
    }

    getSamplingRateValue() {
      const samplingRate = device.samplingRates.find(({ dataSourceId }) => dataSourceId === this.samplingRate)
      return samplingRate ? samplingRate.sampleRate : undefined
    }

    getPath() {
      // path can't change without device change. Hence we can save some memory garbage/few cycles by keeping one copy of path
      if (!cachedPath) {
        cachedPath = [deviceId, ...pathSegments].join('-')
      }
      return cachedPath
    }

    getType() {
      return this.kistlerType || this.getModuleConfigurationParameters('kistlerType') || this.type
    }

    getImageType() {
      const catalogParameters = this.getModuleConfigurationParameters()
      return catalogParameters?.type || catalogParameters?.kistlerType || this.kistlerType || this.type
    }

    isController() {
      return moduleId === urlPathController
    }

    isDirty(...properties) {
      const deviceState = getDeviceDirtyState(getState(), device.id)
      if (deviceState === undefined) {
        dispatch(setProjectDeviceDirtyProps(deviceId))
        return false
      }
      return isDevicePartDirty(deviceState, [...pathSegments, ...properties])
    }

    isVirtual() {
      return (this.types || []).includes(VIRTUAL_MODULE)
    }

    getChannels() {
      return this.channels.filter(channel => channel.isSupported())
    }

    getActiveChannels() {
      return this.getChannels().filter(channel => channel.isActive())
    }

    getChannelByResourcePath(resourcePath) {
      return this.getChannels().find(
        channel => channel.resourcePath === resourcePath || resourcePath.startsWith(`${channel.resourcePath}/`),
      )
    }

    getDeviceId() {
      return deviceId
    }

    toDto() {
      return {
        ...this,
        channels: this.channels.map(channel => channel.toDto()),
      }
    }

    isSupported() {
      return (
        component &&
        component.data &&
        component.data.some(({ type }) => type === ACQUISITION_CHANNEL || SUPPORTED_CONTROLLER_TYPES.includes(type))
      )
    }

    validate() {
      return this.getChannels().reduce((errors, channel) => [...errors, ...channel.validate()], [])
    }

    getModuleId() {
      return moduleId
    }

    isLabAmp() {
      return this.types?.includes?.('x.com.kistler.kistudio.labamp.module')
    }

    isKiDAQ() {
      return this.types.some(type => type.includes('kgate'))
    }

    getWritableProperties() {
      if (this.isLabAmp()) {
        return []
      }
      const result = []
      const modulePath = `$.${this.isController() ? 'deviceSpecific.controller' : `modules[${moduleId}]`}.`
      if ('name' in this) {
        result.push(modulePath + `name`)
      }
      if (!this.isController() && device.getSamplingRateOptions().length > 1 && this.channels.length) {
        result.push(modulePath + 'samplingRate')
      }
      if (this.deviceSpecific?.tare) {
        result.push(modulePath + 'deviceSpecific.tare')
      }
      if (this.getModuleConfigurationOptions('mainsRejection')) {
        result.push(modulePath + 'parameters.mainsRejection')
      }
      this.getInputLevelNames().forEach(inputLevelName => {
        result.push(modulePath + `parameters.${inputLevelName}`)
      })
      return result
    }
  }

  return new ModuleModel({ ...module })
}
