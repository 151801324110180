import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { isProcessInProgress } from './process-indicator-selectors'

export const PROCESS_STARTED = getActionName('PROCESS_STARTED')
export const processStarted = name => createAction(PROCESS_STARTED, { name })

export const PROCESS_FINISHED = getActionName('PROCESS_FINISHED')
export const processFinished = name => createAction(PROCESS_FINISHED, { name })

export const processStartedIfFulfilled = (name, promise) => async dispatch => {
  dispatch(processStarted(name))
  try {
    await promise
  } catch (error) {
    dispatch(processFinished(name))
    throw error
  }
}

const POLLING_TIME = 25000 // 25 seconds
const pollTimerIds = {}
export const pollQueryActionsForRecovery = (getState, processName, recoveryActionCallback) => {
  clearTimeout(pollTimerIds[processName])
  pollTimerIds[processName] = setInterval(() => {
    if (isProcessInProgress(getState(), processName)) {
      recoveryActionCallback()
    } else {
      clearInterval(pollTimerIds[processName])
    }
  }, POLLING_TIME)
}
