import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import Reactour from 'reactour'
import { useLocation, useNavigate } from 'react-router-dom'

import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'

import { ownershipStatuses } from '@/iot-hub/components/devices/constants'
import { useLocalStorage } from '@/hooks'
import { supportedTransferMethods } from '@/iot-hub/components/devices/datasources'

import { CustomHelper } from './custom-helper'
import { tourGuides, stepIds } from './constants'
import {
  getSelectedDeviceDetailsStateForTour,
  selectIsIotHubDeviceResourceDetailsState,
  getAcquisitionDatasource,
  selectIsOwnableDeviceInTable,
  getSelectedDatasource,
} from './selectors'
import { stepsDevice, stepsResources, stepsDatasources } from './steps'
import './styles.scss'

const { OWNED, READY_TO_BE_OWNED } = ownershipStatuses
const { DEVICES, RESOURCES, DATA_SOURCES } = tourGuides

export const Tour = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const [isTourSkipped, setIsTourSkipped] = useLocalStorage('tour-isSkipped', false)
  const [tourFinished, setTourFinished] = useLocalStorage('tour-isFinished', {
    [DEVICES]: false,
    [RESOURCES]: false,
    [DATA_SOURCES]: false,
  })
  const [isTourOpen, setIsTourOpen] = useState(false)
  const isOwnableDeviceInTable = useSelector(selectIsOwnableDeviceInTable)
  const { isSecured, ownershipStatus, selectedDeviceId, acquisitionChannelResourceHref } = useSelector(
    getSelectedDeviceDetailsStateForTour,
  )
  const location = useLocation()
  const navigate = useNavigate()

  const isSelectedResourceAcquisitionChannel = useSelector(selectIsIotHubDeviceResourceDetailsState)
  const [resourceTypeVisible, setResourceTypeVisible] = useState(false)
  const [resourceJSONUpdated, setResourceJSONUpdated] = useState(false)
  const [resourceUpdated, setResourceUpdated] = useState(false)

  const { acquisitionDatasourceId } = useSelector(getAcquisitionDatasource)
  const {
    isSelectedAcquisitionDatasource,
    state: dataSourceState,
    transfer: dataSourceTransfer,
  } = useSelector(getSelectedDatasource)
  const [datasourceTypeVisible, setDatasourceTypeVisible] = useState(false)
  const [zmqTabSelected, setZmqTabSelected] = useState(false)

  const currentTourGuide = useMemo(() => {
    if (location.pathname.includes('resources')) {
      return RESOURCES
    }

    if (location.pathname.includes('datasources')) {
      return DATA_SOURCES
    }

    return DEVICES
  }, [location.pathname])

  const steps = useMemo(() => {
    if (currentTourGuide === RESOURCES) {
      const isAcquisitionChannelResourceInTable = !!acquisitionChannelResourceHref

      return stepsResources({
        isAcquisitionChannelResourceInTable,
        isSelectedResourceAcquisitionChannel,
        isResourceJSONUpdated: resourceJSONUpdated,
        isResourceUpdated: resourceUpdated,
      })
    }

    if (currentTourGuide === DATA_SOURCES) {
      const isAcquisitionDatasourceInTable = !!acquisitionDatasourceId
      const zmtStreamStarted =
        dataSourceState === 'started' && dataSourceTransfer === supportedTransferMethods.ZMQPUBCLIENT

      return stepsDatasources({
        isAcquisitionDatasourceInTable,
        isSelectedAcquisitionDatasource,
        zmqTabSelected,
        zmtStreamStarted,
      })
    }

    const isOwnableDeviceSelected = !!isSecured && ownershipStatus === READY_TO_BE_OWNED
    const isOwnedDeviceSelected = !!isSecured && ownershipStatus === OWNED

    return stepsDevice({ isOwnableDeviceInTable, isOwnableDeviceSelected, isOwnedDeviceSelected })
  }, [
    acquisitionChannelResourceHref,
    currentTourGuide,
    isSecured,
    isSelectedResourceAcquisitionChannel,
    ownershipStatus,
    resourceJSONUpdated,
    resourceUpdated,
    isSelectedAcquisitionDatasource,
    acquisitionDatasourceId,
    zmqTabSelected,
    dataSourceState,
    dataSourceTransfer,
    isOwnableDeviceInTable,
  ])

  const isLastStep = currentStep >= steps.length - 1
  const { stepId: currentStepId = null, title = null } = steps[currentStep]

  const nextButtonEnabled =
    typeof steps[currentStep]?.nextButtonEnabled === 'boolean' ? steps[currentStep].nextButtonEnabled : true

  // When a resource was updated after we clicked on the Update button, go to the next step
  const handleResourceUpdated = useCallback(() => {
    if (currentStepId === stepIds.UPDATE_RESOURCE && currentTourGuide === RESOURCES) {
      setResourceUpdated(true)
      setCurrentStep(currentStep + 1)
    }
  }, [currentStep, currentStepId, currentTourGuide])

  const handleDatasourceTabChanged = useCallback(method => {
    if (method === supportedTransferMethods.ZMQPUBCLIENT) {
      setZmqTabSelected(true)
    }
  }, [])

  useEffect(() => {
    if (isTourOpen) {
      // Go to next step after the device was owned
      if (currentStepId === stepIds.OWN_DEVICE && ownershipStatus === OWNED && currentTourGuide === DEVICES) {
        setCurrentStep(currentStep + 1)
      }

      // Move the pagination to the page where the acquisition channel type resource is present
      if (
        currentStepId === stepIds.RESOURCES_OVERVIEW &&
        currentTourGuide === RESOURCES &&
        !resourceTypeVisible &&
        acquisitionChannelResourceHref
      ) {
        // redirect to the acquisition channel resource
        navigate(`/iot-hub/devices/${selectedDeviceId}/resources${acquisitionChannelResourceHref}`)

        // emit event to handle resource table jump (must delay cause of redux being async)
        setTimeout(() => {
          SbEmitter.emit('resourceJumpToPage')
        }, 100)

        setResourceTypeVisible(true)
      }

      // Update the JSON editor with the resource to only contain { enabled: true/false } based on the previous value
      if (currentStep === 4 && !resourceJSONUpdated && currentTourGuide === RESOURCES) {
        // Emit event to update JSON in editor
        SbEmitter.emit('updateJsonEditor', 'enabled')
        setResourceJSONUpdated(true)
      }

      // Move the pagination to the page where the acquisition datasource is present
      if (
        currentStepId === stepIds.DATASOURCE_TABLE &&
        currentTourGuide === DATA_SOURCES &&
        !datasourceTypeVisible &&
        acquisitionDatasourceId
      ) {
        // redirect to the acquisition datasource
        navigate(`/iot-hub/devices/${selectedDeviceId}/datasources/${acquisitionDatasourceId}`)

        // emit event to handle datasource table jump (must delay cause of redux being async)
        setTimeout(() => {
          SbEmitter.emit('datasourceJumpToPage')
        }, 100)

        setDatasourceTypeVisible(true)
      }
    }
  }, [
    isTourOpen,
    ownershipStatus,
    currentStep,
    currentStepId,
    currentTourGuide,
    resourceJSONUpdated,
    acquisitionChannelResourceHref,
    acquisitionDatasourceId,
    selectedDeviceId,
    resourceTypeVisible,
    datasourceTypeVisible,
    navigate,
  ])

  const handlePageLoaded = useCallback(() => {
    if (!isTourSkipped && !isTourOpen && !tourFinished[currentTourGuide]) {
      setIsTourOpen(true)
    }
  }, [isTourSkipped, isTourOpen, tourFinished, currentTourGuide])

  useEffect(() => {
    if (isTourOpen) {
      SbEmitter.on('resourceUpdated', handleResourceUpdated)
      SbEmitter.on('datasourceTabChanged', handleDatasourceTabChanged)

      return () => {
        SbEmitter.off('resourceUpdated', handleResourceUpdated)
        SbEmitter.off('datasourceTabChanged', handleDatasourceTabChanged)
      }
    }

    SbEmitter.on('page-loaded', handlePageLoaded)

    return () => {
      SbEmitter.off('page-loaded', handlePageLoaded)
    }
  }, [handleResourceUpdated, handleDatasourceTabChanged, handlePageLoaded, isTourOpen])

  // Reset the tour when closed
  const handleCloseTour = useCallback(() => {
    setCurrentStep(0)
    setResourceJSONUpdated(false)
    setResourceUpdated(false)
    setResourceTypeVisible(false)
    setDatasourceTypeVisible(false)
    setZmqTabSelected(false)
    setIsTourOpen(false)

    if (isLastStep && !tourFinished[currentTourGuide]) {
      setTourFinished(prev => ({ ...prev, [currentTourGuide]: true }))
    }
  }, [currentTourGuide, isLastStep, setTourFinished, tourFinished])

  const handleSkipTour = () => {
    setIsTourSkipped(true)
    handleCloseTour()
  }

  const handleOnContinueToNextTour = useCallback(() => {
    if (currentTourGuide === DEVICES) {
      navigate(`/iot-hub/devices/${selectedDeviceId}/resources`)

      // Reset the finished tour state when starting the tour as continuation from previous tour
      setTourFinished(prev => ({ ...prev, [RESOURCES]: false }))
    } else if (currentTourGuide === RESOURCES) {
      navigate(`/iot-hub/devices/${selectedDeviceId}/datasources`)

      // Reset the finished tour state when starting the tour as continuation from previous tour
      setTourFinished(prev => ({ ...prev, [DATA_SOURCES]: false }))
    }
    handleCloseTour()
  }, [currentTourGuide, selectedDeviceId, setTourFinished, handleCloseTour, navigate])

  const handleStartTour = () => {
    setIsTourOpen(true)
    setIsTourSkipped(false)
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const TourComponent = props => (
    <CustomHelper
      {...props}
      nextButtonEnabled={nextButtonEnabled}
      currentTourGuide={currentTourGuide}
      onSkip={handleSkipTour}
      onContinueToNextTour={handleOnContinueToNextTour}
      title={title}
    />
  )

  return (
    <>
      <div id="start-tour" className="fl-row fl-align-items-center fl-justify-center" onClick={handleStartTour}>
        <span className="fl-row fl-align-items-center fl-justify-center">
          <i className="sbi-tour" />
        </span>
      </div>
      <Reactour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        getCurrentStep={setCurrentStep}
        startAt={currentStep}
        onBeforeClose={handleCloseTour}
        disableDotsNavigation
        closeWithMask={false}
        nextStep={nextButtonEnabled ? null : () => {}}
        prevStep={() => {}}
        CustomHelper={TourComponent}
        goToStep={currentStep}
        updateDelay={200}
        disableFocusLock
        highlightedMaskClassName="tour-overlay"
      />
    </>
  )
}
