import React from 'react'
import ReactDom from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbDataTable } from 'skybase-ui/skybase-components/sb-data-table'
import { SbModal } from 'skybase-ui/skybase-components/sb-modal'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { SbButton } from 'skybase-ui/skybase-components/sb-button'
import { SbBadge } from 'skybase-ui/skybase-components/sb-badge'
import { SbTextbox } from 'skybase-ui/skybase-components/sb-textbox/sb-textbox'
import { SbHint } from 'skybase-ui/skybase-components/sb-hint'
import { hintPositions } from 'skybase-ui/skybase-components/sb-hint/constants'
import {
  createDeviceBackup,
  updateBackupItem,
  renameBackup,
  deleteBackup,
  loadDeviceBackups,
} from '@/fleet-configuration/data-fleet/backup/backup-actions'
import { backupConfigurationModalSelector } from '@/fleet-configuration/components/backup-configuration/backup-configuration-modal-selector'
import { loadProjectDeviceIfEmpty } from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { loadProjectsIfEmpty } from '@/fleet-configuration/data-fleet/projects/projects-actions'
import { showSuccessToast } from '@/common/services/show-toast'
import { showConfirmModal } from '@/fleet-configuration/components/confirm-modal/confirm-modal-actions'
import { showBackupConfigurationConfirm } from '@/fleet-configuration/components/backup-configuration/backup-configuration-confirm'
import { MAX_DEVICE_BACKUPS } from './backup-configuration-constants'
import { messages as t } from './backup-configuration-modal-i18n'
import './backup-configuration-modal.scss'

const appRoot = document.getElementById('app')
class _BackupConfigurationModal extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    deviceId: PropTypes.string.isRequired,
    piid: PropTypes.string.isRequired,
    onDismiss: PropTypes.func.isRequired,
    deviceBackups: PropTypes.array,
  }

  static defaultProps = {
    deviceBackups: null,
  }

  constructor(props) {
    super(props)
    this.state = { loading: true }
  }

  async componentDidMount() {
    const { piid, deviceId, dispatch } = this.props
    // NOTE: project-devices can be loaded only after projects are loaded
    //  So load projects if they are not loaded yet and then load projectDevices if those are not loaded yet
    const possibleProjectDevicesPromise = dispatch(loadProjectsIfEmpty())
    const promises = [
      possibleProjectDevicesPromise.then
        ? possibleProjectDevicesPromise.then(() => dispatch(loadProjectDeviceIfEmpty(deviceId)))
        : dispatch(loadProjectDeviceIfEmpty(deviceId)),
      //  but device backups can be loaded in parallel as they don't depend on project
      dispatch(loadDeviceBackups(piid)),
    ]
    try {
      await Promise.all(promises)
    } catch (e) {
      console.error('Backup modal dependencies could not be loaded')
      throw e
    }
    this.setState({ loading: false })
  }

  handleCreateBackup = async () => {
    const {
      dispatch,
      deviceId,
      intl: { formatMessage: _ },
    } = this.props
    await dispatch(createDeviceBackup(deviceId, _))
    showSuccessToast(_(t.newBackupCreated))
  }

  getBackupTableColumns() {
    const {
      intl: { formatMessage: _ },
    } = this.props
    return [
      { name: 'name', label: _(t.name), headerClassName: 'backup-name' },
      { name: 'lastUsed', label: _(t.lastUsed), headerClassName: 'backup-lastUsed' },
      {
        name: '_actions',
        label: ' ',
        sortable: false,
        cellsClassName: 'actions-column',
        headerClassName: 'backup-actions',
      },
    ]
  }

  factoryHandleBackupUsage(deviceBackupRow) {
    const {
      intl: { formatMessage: _ },
      onDismiss,
      deviceId,
      dispatch,
    } = this.props

    return () => showBackupConfigurationConfirm(_, dispatch, deviceId, deviceBackupRow, onDismiss)
  }

  factoryHandleBackupDelete(deviceBackupRow) {
    const {
      intl: { formatMessage: _ },
      piid,
      dispatch,
    } = this.props

    return () => {
      dispatch(
        showConfirmModal(_, _(t.deleteDeviceConfiguration), deleteBackup(piid, deviceBackupRow.id), {
          modalMessage: (
            <>
              {_(t.areYouSureYouWantToDeleteConfiguration)}
              <br />
              {_(t.thisActionCannotBeUndone)}
            </>
          ),
          confirmText: _(t.delete),
          className: 'destructive-confirm-modal backup-confirm-modal',
          afterConfirmAction: () => {
            showSuccessToast(t.deviceBackupDeleted)
          },
        }),
      )
    }
  }

  handleNameChange = (newName, row) => {
    const { piid, dispatch } = this.props
    dispatch(updateBackupItem(piid, row.id, { name: newName }))
  }

  handleNameBlur = row => {
    const { piid, dispatch } = this.props
    dispatch(renameBackup(piid, row.id, row.name))
  }

  getBackupTableFormattersCallback = (value, key, row) => {
    if (key === 'name') {
      return (
        <SbTextbox
          className="editable-component "
          value={value}
          size={90}
          onChange={evt => this.handleNameChange(evt.target.value, row)}
          onBlur={() => this.handleNameBlur(row)}
        />
      )
    }
    if (key === 'lastUsed') {
      const {
        intl: { formatDate },
      } = this.props
      return value ? formatDate(value, { format: 'date-minutes-12hour-numeric' }) : '-'
    }
    if (key === '_actions') {
      const {
        intl: { formatMessage: _ },
      } = this.props
      return (
        <>
          <SbHint hintData={_(t.restoreBackedUpConfiguration)} position={hintPositions.topCenter}>
            <span className="sbi-restore action-icon test-apply-backup" onClick={this.factoryHandleBackupUsage(row)} />
          </SbHint>
          <SbHint hintData={_(t.deleteBackup)} position={hintPositions.topCenter}>
            <span
              className="sbi-delete-kids action-icon test-delete-backup"
              onClick={this.factoryHandleBackupDelete(row)}
            />
          </SbHint>
        </>
      )
    }
    return value
  }

  render() {
    const { loading } = this.state
    const {
      deviceBackups,
      intl: { formatMessage: _ },
      onDismiss,
    } = this.props
    let modalContent
    if (loading) {
      modalContent = (
        <div className="fl-container fl-justify-sa">
          <SbLoader show />
        </div>
      )
    } else if (!deviceBackups) {
      modalContent = <span className="error">{_(t.failedToLoadDeviceBackups)}</span>
    } else if (deviceBackups.length === 0) {
      modalContent = (
        <div className="no-backups">
          <p>{_(t.thereAreNoBackupsInSystemYet)}</p>
          <p>
            {_(t.clickCreateBackupButtonAtTheTopToCreateYourFirst, {
              CREATE_BACKUP: (
                <span className="create-backup-highlight" onClick={this.handleCreateBackup}>
                  {_(t.createBackup)}
                </span>
              ),
            })}
          </p>
        </div>
      )
    } else {
      modalContent = (
        <>
          <SbDataTable
            id="backup-selection-table"
            columns={this.getBackupTableColumns()}
            cellFormatter={this.getBackupTableFormattersCallback}
            asyncData={false}
            data={deviceBackups}
          />
          {deviceBackups.length === MAX_DEVICE_BACKUPS && (
            <>
              <p className="required-license-text">{_(t.yourLicenseDoesntSupportMoreThanTwoBackups)}</p>
              <SbBadge className="required-license-badge">{_(t.upgradeYourLicenseToGetMore)}</SbBadge>
            </>
          )}
        </>
      )
    }
    return ReactDom.createPortal(
      <SbModal
        className="backup-modal"
        Header={
          <div className="backup-modal-header sb-modal-header">
            <h1>{_(t.backupConfigurationOverview)}</h1>
            <SbButton
              className="sbi-plus primary test-new-backup"
              onClick={this.handleCreateBackup}
              disabled={deviceBackups?.length === MAX_DEVICE_BACKUPS}
            >
              {_(t.createBackup)}
            </SbButton>
            <div className="close-modal" onClick={onDismiss}>
              <i className="sbi-toast-close" />
            </div>
          </div>
        }
        onClickOverlay={onDismiss}
        Footer={
          /* we don't want footer - and providing nullish value would use default footer. */
          <></> /* eslint-disable-line react/jsx-no-useless-fragment */
        }
      >
        {modalContent}
      </SbModal>,
      appRoot,
    )
  }
}

export const BackupConfigurationModal = connect(backupConfigurationModalSelector)(injectIntl(_BackupConfigurationModal))
