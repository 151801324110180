import { PENDING_ADD, PENDING_REMOVE } from './pending-actions'

export const pendingReducer = (state = { numActions: 0, updateTime: Date.now(), urls: {} }, action = {}) => {
  switch (action.type) {
    case PENDING_ADD:
      return {
        numActions: state.numActions + 1,
        updateTime: Date.now(),
        urls: {
          ...state.urls,
          [action.payload]: state.urls[action.payload] === undefined ? 1 : state.urls[action.payload] + 1,
        },
      }
    case PENDING_REMOVE:
      return {
        ...state,
        numActions: state.numActions - 1,
        urls: {
          ...state.urls,
          [action.payload]: state.urls[action.payload] === undefined ? 0 : state.urls[action.payload] - 1,
        },
      }
    default:
      return state
  }
}
