export const licensingApi = {
  LICENSES_OF_USER: '/licensing/manager/licensesofuser',
  LICENSES_OF_TICKET: '/licensing/manager/licensesofticket2',
  ASSIGN_LICENSES_TO_USER: '/licensing/manager/assignlicensestouser2',
  REMOVE_LICENSES_FROM_USER: '/licensing/manager/removelicensesfromuser',
  AUTO_UPDATE_USER_CONTAINER: '/licensing/manager/autoupdateusercontainer',
}

export const licensingErrorCodes = {
  NO_ERROR: 0,
  ERROR_NO_LICENSE: 3690995720,
  TICKET_NOT_FOUND: 3690995715,
  TICKET_CONTAINS_NO_LICENSES: 3690995716,
}

// Various error scenarios for licensing response
export const licenseErrorTypes = {
  ASSIGN: 'assign',
  REMOVE: 'remove',
  UPDATE: 'update',
  FETCH: 'fetch',
}
