import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { useEquipment, families } from '@/fleet-configuration/equipment'
import { messages as t } from '../home-page-i18n'
import { SbInlineMessage } from 'skybase-ui/skybase-components/sb-inline-message'
import { INLINE_MESSAGE_TYPE_ERROR } from 'skybase-ui/skybase-components/sb-inline-message/constants'

const { DEVICE, SENSOR, CABLE, MODULE, CONTROLLER } = families

export const Equipment = () => {
  const { formatMessage: _ } = useIntl()
  const { data, error } = useEquipment()

  const { devices, sensors, cables } = useMemo(
    () =>
      (data || []).reduce(
        (acc, { family }) => {
          if ([DEVICE, CONTROLLER, MODULE].includes(family)) {
            acc.devices += 1
          } else if (family === CABLE) {
            acc.cables += 1
          } else if (family === SENSOR) {
            acc.sensors += 1
          }
          return acc
        },
        {
          devices: 0,
          sensors: 0,
          cables: 0,
        },
      ),
    [data],
  )

  if (error) {
    return (
      <SbInlineMessage
        className="error-container"
        type={INLINE_MESSAGE_TYPE_ERROR}
        title={_(t.failedToRetrieveYourEquipmentInventory)}
        message={_(t.pleaseTryRefreshingThePageOrTryAccessingTheServiceLater)}
      />
    )
  }

  return (
    <div id="dashboard-equipment" className="fl-container">
      <div className="item devices-equipment">
        <span className="number">{devices}</span>
        <div className="label">
          <span className="sbi-nav-devices" />
          {_(t.deviceModules)}
        </div>
      </div>

      <div className="item sensors-equipment">
        <span className="number">{sensors}</span>
        <div className="label">
          <span className="sbi-sensor" />
          {_(t.sensors)}
        </div>
      </div>

      <div className="item cables-equipment">
        <span className="number">{cables}</span>
        <div className="label">
          <span className="sbi-device-thermic" />
          {_(t.cables)}
        </div>
      </div>
    </div>
  )
}
