import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { messages as t } from './measurement-unit-ntp-error-i18n'

const _MeasurementUnitNTPError = ({ intl: { formatMessage: _ }, onRevert, previousNTPServer = '' }) => (
  <FormattedMessage
    {...t.ntpServerIsRequired}
    values={{
      serverUrl: previousNTPServer ? (
        <button type="button" className="sb-btn btn-link inline" onClick={onRevert}>
          {previousNTPServer}
        </button>
      ) : (
        _(t.notDefined)
      ),
    }}
  />
)

_MeasurementUnitNTPError.propTypes = {
  intl: intlShape.isRequired,
  previousNTPServer: PropTypes.string,
  onRevert: PropTypes.func.isRequired,
}

export const MeasurementUnitNTPError = injectIntl(_MeasurementUnitNTPError)
