import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { Sb21Layout } from 'skybase-ui/skybase-components/layouts'
import { ApisList, CreateApiButton, ApiTab } from 'skybase-oauth/apis/components'
import { fetchApi } from 'skybase-oauth/apis/utils'
import { withAcl, aclShape } from 'skybase-oauth/auth'
import { oAuthPermissions } from 'skybase-oauth/oauth-permissions'

import { withRouter } from '@/common/router'
import { messages as menuT } from '@/common/menu/menu-i18n'
import { messages as t } from './apis-page-i18n'

export class _ApisPage extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    handleFetchApi: PropTypes.func.isRequired,
    acl: aclShape.isRequired,
    router: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      params: PropTypes.shape({
        apiId: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }

  componentDidMount() {
    const {
      handleFetchApi,
      router: {
        params: { apiId },
      },
      acl: { read },
    } = this.props

    if (read && apiId) {
      handleFetchApi(apiId)
    }
  }

  handleOnScopesClick = apiId => {
    const {
      router: { navigate },
    } = this.props
    navigate(`/identity-management/oauth-management/apis/${apiId}/scopes`)
  }

  render() {
    const {
      intl: { formatMessage: _ },
    } = this.props

    return (
      <Sb21Layout
        title={_(t.pageName)}
        breadcrumbs={[
          {
            path: '/',
            title: _(menuT.home),
          },
          {
            path: '/identity-management',
            title: _(menuT.identityManagement),
          },
          {
            path: '/identity-management/oauth-management',
            title: _(menuT.oAuthManagementPage),
          },
          _(t.pageName),
        ]}
      >
        <div>
          <div className="sb-heading fl-row fl-align-items-center">
            <h1>{_(t.pageName)}</h1>
            <CreateApiButton />
          </div>
          <ApisList />
        </div>
        <ApiTab onScopesClick={this.handleOnScopesClick} />
      </Sb21Layout>
    )
  }
}

const mapDispatchToProps = (dispatch, { intl: { formatMessage } }) => {
  return {
    handleFetchApi: apiId => dispatch(fetchApi(apiId, formatMessage)),
  }
}

const withAclComponent = withAcl(_ApisPage, {
  readPermissions: [oAuthPermissions.kiconnectApisReadWrite],
})

export const ApisPage = withRouter(injectIntl(connect(null, mapDispatchToProps)(withAclComponent)))
