import { getProjectDeviceById } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import {
  getEnvVarsByChannel,
  getChannelConnections,
  getProjectConnections,
} from '@/fleet-configuration/data-fleet/connections/connections-selectors'
import { urlPathController } from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation-constants'
import { selectMeasurementUnitParams } from '../../select-measurement-unit-params'
import { getSensorFromChainCatalog } from '@/fleet-configuration/data-fleet/chain-catalog/chain-catalog-selectors'
import { getChainByChannelDetails } from '@/fleet-configuration/data-fleet/chain/chain-selectors'
import {
  getSensitivityPerMaxRangeFromSensorCertificate,
  getCertificateRecordByTypeNumberAndSerialNumber,
  getSensorSensitivityByPhysicalRangeTo,
  getMaxRangeUnitFromCertificate,
} from '@/fleet-configuration/data-fleet/chain-certificate/chain-certificate-selector'
import { convertUnit } from '@/fleet-configuration/data-fleet/project-devices/channel-utils'

export const selectChannelDetail = (state, props) => {
  const { unitId, moduleIndex, channelIndex } = selectMeasurementUnitParams(state, props)
  const device = props.measurementDevice || getProjectDeviceById(state, unitId)
  const module = moduleIndex === urlPathController ? device.deviceSpecific.controller : device.modules[moduleIndex]
  const channel = module.channels[channelIndex]
  const envVars = getEnvVarsByChannel(state, channel)
  const shouldInitConnections = !getProjectConnections(state).length || !getChannelConnections(state, channel).length
  const snippetPathPrefix = `$.${
    moduleIndex === urlPathController ? 'deviceSpecific.controller' : `modules[${moduleIndex}]`
  }.channels[${channelIndex}].`

  const serialNumber = module.parametersReadable?.snr || device.serialNumber
  const chain = getChainByChannelDetails(state, module.getType(), serialNumber, channelIndex)
  let chainSensitivity
  let sensitivityPerMaxRange
  let chainCatalog = {}
  let chainMaxPhysicalRangeWithUnit
  if (chain?.sensor?.typeNumber) {
    const { measurand, sensorClass } = getSensorFromChainCatalog(state, chain.sensor.typeNumber) || {}
    chainCatalog = { measurand, sensorClass }
    if (chain.sensor.serialNumber) {
      const certificateRecord = getCertificateRecordByTypeNumberAndSerialNumber(
        state,
        chain.sensor.typeNumber,
        chain.sensor.serialNumber,
      )
      if (certificateRecord?.certificate) {
        sensitivityPerMaxRange = getSensitivityPerMaxRangeFromSensorCertificate(certificateRecord.certificate)
        chainMaxPhysicalRangeWithUnit = {
          range: chain?.userPreferences?.maxPhysicalRange,
          unit: getMaxRangeUnitFromCertificate(certificateRecord.certificate),
        }
        if (
          sensitivityPerMaxRange.length &&
          !['Custom Unit', 'Custom value'].includes(channel.parameters.physicalQuantity)
        ) {
          try {
            const normalizedPhysicalRange = convertUnit(
              channel.parameters.physicalUnit,
              sensitivityPerMaxRange[0].sensitivityUnit.split('/').slice(-1)[0].trim(),
              channel.parameters.physicalRange[1] === chain?.userPreferences?.maxPhysicalRange
                ? chain.userPreferences.maxPhysicalRange
                : channel.parameters.physicalRange[1],
            )
            const sensitivityPerMaxRangeNormalized = sensitivityPerMaxRange.map(sensitivityItem => ({
              ...sensitivityItem,
              maxRange: convertUnit(
                sensitivityItem.maxRangeUnit,
                sensitivityItem.sensitivityUnit.split('/').slice(-1)[0].trim(),
                sensitivityItem.maxRange,
              ),
              maxRangeUnit: channel.parameters.physicalUnit,
            }))
            chainSensitivity = getSensorSensitivityByPhysicalRangeTo(
              sensitivityPerMaxRangeNormalized,
              normalizedPhysicalRange,
            )
          } catch (e) {
            // if unit conversion fails, do not assign any chain sensitivity - as those are incompatible
          }
        }
      }
    }
  }

  return {
    device,
    channel,
    channelIndex,
    shouldInitConnections,
    envVars,
    snippetPathPrefix,
    chainCatalog,
    chainSensitivity,
    sensitivityPerMaxRange,
    chainMaxPhysicalRangeWithUnit,
  }
}
