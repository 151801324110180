import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
// for some reason eslint thinks rehype-external-links does not have default export. So suppress it (as the actual import works)
// eslint-disable-next-line import/no-extraneous-dependencies
import rehypeExternalLinks from 'rehype-external-links'

import { replaceDuplicateSlashes } from '@/utils'

import { CodeBlock, Heading } from './renderers'

export class Markdown extends React.PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    internalUriPath: PropTypes.string,
  }

  static defaultProps = {
    internalUriPath: '',
  }

  transformLinkUri = uri => {
    const { internalUriPath } = this.props

    // mailto url
    if (uri.includes('mailto')) {
      return uri
    }

    // external uri
    if (uri.includes('http')) {
      return uri
    }

    // internal hash uri
    if (uri.includes('#')) {
      return `${window.location.href}${uri}`
    }

    // internal uri
    return replaceDuplicateSlashes(`${internalUriPath}/${uri}`)
  }

  render() {
    const { value, ...rest } = this.props

    return (
      <div className="markdown-container">
        <ReactMarkdown
          {...rest}
          rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }]]}
          urlTransform={this.transformLinkUri}
          components={{
            code: CodeBlock,
            h1: Heading,
            h2: Heading,
            h3: Heading,
            h4: Heading,
            h5: Heading,
            h6: Heading,
          }}
        >
          {value}
        </ReactMarkdown>
      </div>
    )
  }
}
