import {
  SET_SNIPPET,
  REMOVE_GENERIC_SNIPPETS,
  REMOVE_SNIPPET_ENTRY,
  SNIPPETS_LOADED,
  SNIPPET_DETAIL_LOADED,
  SET_SNIPPET_FILTER,
  SET_SNIPPET_DETAIL,
} from './snippets-actions'
import { combineReducers } from 'redux'
import { byIdReducerFactory } from '@/fleet-configuration/data-fleet/reducers/by-id-reducer-factory'
import { allEntriesReducerFactory } from '@/fleet-configuration/data-fleet/reducers/all-entries-reducer-factory'
import { entriesReducerFactory } from '@/fleet-configuration/data-fleet/entries/entries-reducer-factory'

const loadedSnippetDetailReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case SNIPPET_DETAIL_LOADED: {
      return {
        ...state,
        [action.payload.id]: true,
      }
    }
    case REMOVE_SNIPPET_ENTRY: {
      const { [action.payload.id]: removedId, ...newState } = state
      return newState
    }
    default:
      return state
  }
}

const snippetFilterReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_SNIPPET_FILTER:
      return action.payload.snippetFilter
    default:
      return state
  }
}

export const snippetsReducer = entriesReducerFactory(
  SET_SNIPPET,
  REMOVE_GENERIC_SNIPPETS,
  REMOVE_SNIPPET_ENTRY,
  SNIPPETS_LOADED,
)

export const snippetDetailReducer = combineReducers({
  loadedDetail: loadedSnippetDetailReducer,
  filter: snippetFilterReducer,
  byId: byIdReducerFactory(SET_SNIPPET_DETAIL, undefined, REMOVE_SNIPPET_ENTRY),
  allIds: allEntriesReducerFactory(SET_SNIPPET_DETAIL, undefined, REMOVE_SNIPPET_ENTRY),
})
