import { getEntryById } from '@/fleet-configuration/data-fleet/entries/entries-selectors'
import { arraysHaveSameItem } from '@/utils/diff'
import { ACQUISITION_CHANNEL, ACQUISITION_DEVICE, SUPPORTED_CONTROLLER_TYPES } from './catalog-constants'

export const getFullCatalogFromState = state => state.entries.catalog

export const getCatalogIdByCatalogTypes = catalogTypes => {
  let catalogId
  if (catalogTypes instanceof Array) {
    if (
      catalogTypes.includes(ACQUISITION_DEVICE) ||
      catalogTypes.includes(ACQUISITION_CHANNEL) ||
      arraysHaveSameItem(catalogTypes, SUPPORTED_CONTROLLER_TYPES)
    ) {
      catalogId = JSON.stringify(catalogTypes)
    } else {
      catalogId = JSON.stringify(catalogTypes.concat(ACQUISITION_CHANNEL))
    }
  } else if (typeof catalogTypes === 'string') {
    catalogId = catalogTypes
  }
  return catalogId
}

export const getComponentFromCatalog = (state, catalogTypes) => {
  const catalogId = getCatalogIdByCatalogTypes(catalogTypes)
  return catalogId ? getEntryById(state, 'entries.catalog', catalogId) : null
}

export const getComponentsByFamilyName = (state, familyNames) => {
  const allComponents = state.entries.catalog.byId
  const normFamilyNames = familyNames instanceof Array ? familyNames : [familyNames]
  return Object.keys(allComponents)
    .filter(key => normFamilyNames.includes(allComponents[key].family))
    .map(key => allComponents[key])
}

// NOTE: I tried to cache this function by Map, but it required key modification that was
// more expensive then actual recalculation
const filterCatalogItems = (catalogItems, usableTypes) =>
  (catalogItems && catalogItems.filter(({ type }) => usableTypes.includes(type))) || []

export const getMirrorResourcesFields = (catalogItems, types) =>
  filterCatalogItems(catalogItems, types).reduce((acc, item) => {
    acc.push(...(item.mirrorResourcesFields || []))
    return acc
  }, [])

export const getInitializingFields = (catalogItems, types) =>
  filterCatalogItems(catalogItems, types).reduce((acc, item) => {
    acc.push(...(item.initializationFields || []).map(field => field.split(':').slice(-1)[0]))
    return acc
  }, [])

export const getDirtyExcludableFields = (catalogItems, types) =>
  filterCatalogItems(catalogItems, types).reduce((acc, item) => {
    acc.push(...(item.dirtyExcludableFields || []))
    return acc
  }, [])

export const getConfigurationOptions = (catalogItems, types) =>
  Object.assign({}, ...filterCatalogItems(catalogItems, types).map(item => item.options))

export const getConfigurationParameters = (catalogItems, types) =>
  Object.assign({}, ...filterCatalogItems(catalogItems, types).map(item => item.parameters))

export const getComponentParametersCatalog = (stateCatalog, modelNumber) =>
  Object.values(stateCatalog?.byId || {}).find(obj => obj.type === modelNumber)?.parameters
