/* eslint-disable no-nested-ternary */
import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'
import { OAuth } from 'skybase-oauth'
import { withAcl, aclShape } from 'skybase-oauth/auth'
import { SbNotFoundPage } from 'skybase-pages'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { Sb21Layout } from 'skybase-ui/skybase-components/layouts'
import { SbDynamicTabs, SbTab } from 'skybase-ui/skybase-components/sb-dynamic-tabs'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { STATES } from 'skybase-oauth/constants'

import { withRouter } from '@/common/router'
import {
  getIotHubDeviceDetailsState,
  getIotHubDeviceDataSourcesListState,
  getIotHubDeviceDataSourceDetailsState,
  getIotHubDeviceDataSourceTransferState,
} from '@/iot-hub/selectors'
import {
  fetchDevice,
  fetchDeviceDataSources,
  fetchDeviceDataSource,
  getColorsForResource,
  fetchDeviceDataSourceTransferConfiguration,
  subscribeToDevicesStatusWS,
} from '@/iot-hub/components/devices/utils'
import { deviceShape, deviceDataSourceShape } from '@/iot-hub/components/devices/shapes'
import { resourceStructures } from '@/iot-hub/components/devices/constants'
import {
  DataSourcesList,
  DataSourcesDetails,
  DataSourceTransfer,
  supportedTransferMethods,
  supportedTransferMethodNames,
  clearAllDeviceDataSourceForms,
  DATA_SOURCES_DETAILS_TAB_KEY,
  dataSourceStates,
} from '@/iot-hub/components/devices/datasources'
import { messages as menuT } from '@/common/menu/menu-i18n'
import { iotHubPermissions } from '@/common/permissions'

import { messages as t } from './datasources-page-i18n'

const { ARRAY } = resourceStructures
const { STARTED } = dataSourceStates

class _DataSourcesPage extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    dataSource: deviceDataSourceShape.isRequired,
    dataSources: PropTypes.arrayOf(deviceDataSourceShape).isRequired,
    deviceData: deviceShape.isRequired,
    handleFetchDevice: PropTypes.func.isRequired,
    handleFetchDeviceDataSources: PropTypes.func.isRequired,
    handleFetchDeviceDataSource: PropTypes.func.isRequired,
    deviceLoading: PropTypes.bool.isRequired,
    dataSourcesLoading: PropTypes.bool.isRequired,
    clearParams: PropTypes.func.isRequired,
    handleFetchDeviceDataSourceTransferConfiguration: PropTypes.func.isRequired,
    dataTransfer: PropTypes.object.isRequired, // eslint-disable-line
    dataTransferLoading: PropTypes.bool.isRequired,
    dataTransferFetching: PropTypes.bool.isRequired,
    acl: aclShape.isRequired,
    router: PropTypes.shape({
      location: PropTypes.shape({
        state: PropTypes.shape({
          method: PropTypes.oneOf(Object.values(supportedTransferMethods)),
        }),
      }),
      params: PropTypes.shape({
        deviceId: PropTypes.string,
        dataSourceId: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props)

    this.allSupportedTransferMethods = [supportedTransferMethods.STREAM]
    this.state = {
      selectedTab: DATA_SOURCES_DETAILS_TAB_KEY,
      loading: false,
    }
    this.mounted = true

    if (!OAuth.isInNormalMode) {
      this.allSupportedTransferMethods.push(supportedTransferMethods.ZMQPUBCLIENT)
    }
  }

  componentDidMount() {
    const {
      handleFetchDevice,
      handleFetchDeviceDataSources,
      handleFetchDeviceDataSource,
      router: { params, location },
    } = this.props

    const matchDeviceId = params?.deviceId
    const matchDataSourceId = params?.dataSourceId

    if (matchDeviceId) {
      this.setState({ loading: true })

      handleFetchDevice(
        matchDeviceId,
        () => {
          subscribeToDevicesStatusWS()

          handleFetchDeviceDataSources(
            matchDeviceId,
            () => {
              // Event used in the guided tour
              SbEmitter.emit('page-loaded', 'datasources')

              if (matchDataSourceId) {
                handleFetchDeviceDataSource(
                  matchDeviceId,
                  matchDataSourceId,
                  () => {
                    if (location?.state?.method) {
                      this.handleOnTabChange(location?.state?.method, {
                        deviceId: matchDeviceId,
                        dataSourceId: matchDataSourceId,
                      })
                    } else {
                      this.stopLoading()
                    }
                  },
                  () => {
                    this.stopLoading()
                  },
                )
              } else {
                this.stopLoading()
              }
            },
            () => {
              this.stopLoading()
            },
          )
        },
        () => {
          this.stopLoading()
        },
      )
    }
  }

  componentWillUnmount() {
    const { clearParams } = this.props

    clearParams()
    this.mounted = false
  }

  stopLoading = () => {
    if (this.mounted) {
      this.setState({ loading: false })
    }
  }

  handleOnTabChange = (tabId, { deviceId: deviceIdArg, dataSourceId: dataSourceIdArg } = {}) => {
    // if tabId === kafka, zmq, fetch
    if (tabId !== DATA_SOURCES_DETAILS_TAB_KEY && this.allSupportedTransferMethods.indexOf(tabId) !== -1) {
      const {
        handleFetchDeviceDataSourceTransferConfiguration,
        dataSource: { id, deviceId },
      } = this.props

      handleFetchDeviceDataSourceTransferConfiguration(deviceIdArg || deviceId, dataSourceIdArg || id, tabId)
    }

    if (this.mounted) {
      this.setState({ selectedTab: tabId, loading: false })
    }
  }

  renderError = message => {
    return <SbNotFoundPage {...this.props} message={message} />
  }

  renderPage = () => {
    const { selectedTab } = this.state
    const {
      intl: { formatMessage: _ },
      dataSources,
      dataSource: { id: dataSourceId, supportedMethods, state: dataSourceState, transfer: activeDataTransfer },
      deviceData,
      deviceData: { id: deviceId, status: deviceStatus },
      dataSourcesLoading,
      dataTransfer,
      dataTransferLoading,
      dataTransferFetching,
      acl,
      router: { params },
    } = this.props

    const matchDataSourceId = params?.dataSourceId
    const typesColors = getColorsForResource(dataSources, 'types')
    const streaming = dataSourceState === STARTED

    const customCaResource = deviceData?.resources?.find(
      resource =>
        resource?.href?.includes(`/kic/data/src/${dataSourceId}/stream`) &&
        resource?.types?.includes('x.com.kistler.kiconnect.data.source.stream.custom-ca'),
    )

    return [
      <div key="datasources-page-header">
        <h1>{_(t.pageName)}</h1>
        <div className="datasource-list-container">
          <DataSourcesList
            selectedDataSourceId={dataSourceId}
            dataSources={dataSources}
            typesColors={typesColors}
            loading={dataSourcesLoading}
            changeTab={this.handleOnTabChange}
            matchDataSourceId={matchDataSourceId}
          />
        </div>
      </div>,
      <SbDynamicTabs
        namespace="datasources-page-tab"
        key="datasources-page-tab"
        selectedTabId={selectedTab}
        onChange={this.handleOnTabChange}
      >
        <SbTab tabId={DATA_SOURCES_DETAILS_TAB_KEY} id="datasource-tab-details">
          <SbTab.Title>{_(t.details)}</SbTab.Title>
          <SbTab.Content>
            <DataSourcesDetails typesColors={typesColors} deviceData={deviceData} acl={acl} />
          </SbTab.Content>
        </SbTab>
        {
          // @todo store the current tab in state, so that we can reset it one we change to a data source with has different methods
          supportedMethods &&
            this.allSupportedTransferMethods
              .filter(method => supportedMethods.indexOf(method) !== -1)
              .map(method => {
                return (
                  <SbTab tabId={method} key={`datasource-tab-${method}`} id={`datasource-tab-${method}`}>
                    <SbTab.Title>{supportedTransferMethodNames[method] || ''}</SbTab.Title>
                    <SbTab.Content>
                      <DataSourceTransfer
                        method={method}
                        data={dataTransfer}
                        fetching={dataTransferFetching}
                        loading={dataTransferLoading}
                        isActiveTranfer={activeDataTransfer === method && streaming}
                        deviceId={deviceId}
                        dataSourceId={dataSourceId}
                        deviceStatus={deviceStatus}
                        streaming={streaming}
                        acl={acl}
                        customCaResource={customCaResource}
                      />
                    </SbTab.Content>
                  </SbTab>
                )
              })
        }
      </SbDynamicTabs>,
    ]
  }

  render() {
    const { loading } = this.state
    const {
      intl: { formatMessage: _ },
      deviceData: { id: deviceId, name: deviceName },
      deviceLoading,
      dataSourcesLoading,
      router: { params },
    } = this.props
    const matchDeviceId = params?.deviceId
    const deviceExists = deviceId && !deviceLoading && !dataSourcesLoading
    const breadcrumbs = deviceExists
      ? [
          {
            path: '/',
            title: _(menuT.home),
          },
          {
            path: '/iot-hub',
            title: _(menuT.iotHub),
          },
          {
            path: '/iot-hub/devices',
            title: _(menuT.devices),
          },
          {
            path: `/iot-hub/devices/${deviceId}`,
            title: deviceName,
          },
          _(t.pageName),
        ]
      : null

    // If the device does not exist
    if (!deviceId && !deviceLoading && !loading) {
      return this.renderError(_(t.deviceNotFound, { deviceId: matchDeviceId }))
    }

    return (
      <Sb21Layout title={_(t.pageName)} breadcrumbs={breadcrumbs}>
        {deviceLoading || dataSourcesLoading ? (
          <div className="fl-row fl-justify-center fl-align-items-center">
            <SbLoader textWrapperClassName="sb-width-ml" show loadingText={_(t.loadingDataSources)} size={30} />
          </div>
        ) : (
          this.renderPage()
        )}
      </Sb21Layout>
    )
  }
}

const mapStateToProps = state => {
  const { data: deviceData, state: dataSourceFetchState } = getIotHubDeviceDetailsState(state)
  const { data: dataSources, state: dataSourcesFetchState } = getIotHubDeviceDataSourcesListState(state)
  const { data: dataSource } = getIotHubDeviceDataSourceDetailsState(state)
  const {
    data: dataTransfer,
    state: dataTransferFetchingState,
    transferState: dataTransferState,
  } = getIotHubDeviceDataSourceTransferState(state)

  return {
    deviceData,
    deviceLoading: dataSourceFetchState === STATES.LOADING,
    dataSources,
    dataSource,
    dataSourcesLoading: dataSourcesFetchState === STATES.LOADING,
    dataTransfer,
    dataTransferLoading: dataTransferState === STATES.LOADING,
    dataTransferFetching: dataTransferFetchingState === STATES.LOADING,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleFetchDevice: (deviceId, onSuccess, onError) => dispatch(fetchDevice(deviceId, ARRAY, onSuccess, onError)),
    handleFetchDeviceDataSources: (deviceId, onSuccess, onError) =>
      dispatch(fetchDeviceDataSources(deviceId, onSuccess, onError)),
    handleFetchDeviceDataSource: (deviceId, dataSourceId, onSuccess, onError) =>
      dispatch(fetchDeviceDataSource(deviceId, dataSourceId, onSuccess, onError)),
    handleFetchDeviceDataSourceTransferConfiguration: (deviceId, dataSourceId, transferMethod) =>
      dispatch(fetchDeviceDataSourceTransferConfiguration(deviceId, dataSourceId, transferMethod)),
    clearParams: () => dispatch(clearAllDeviceDataSourceForms()),
  }
}

const withAclComponent = withAcl(_DataSourcesPage, {
  writePermissions: [iotHubPermissions.kiconnectDevicesWrite],
})

export const DataSourcesPage = withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(withAclComponent)))
