export const DEFAULT_LICENSING_SORT_BY = {
  sortCol: 'assignmentTime',
  sortOrder: 'desc',
}

export const ASSIGN_LICENSE_MODAL_ID = 'assignLicenseModal'

export const assignLicenseSteps = {
  ENTER_TICKET_ID: 1,
  ASSIGN_LICENSE: 2,
}
