import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import axios from 'axios'
import { getStudioAPIHost } from '@/utils/url'
import { getCertificateRecordByTypeNumberAndSerialNumber } from '@/fleet-configuration/data-fleet/chain-certificate/chain-certificate-selector'

export const SET_CHAIN_CERTIFICATE = getActionName('SET_CHAIN_CERTIFICATE')
export const setChainCertificate = (typeNumber, serialNumber, certificate) =>
  createAction(SET_CHAIN_CERTIFICATE, {
    id: JSON.stringify({ typeNumber, serialNumber }),
    typeNumber,
    serialNumber,
    certificate,
  })

const certificatesBeingLoaded = {}
export const tryLoadChainCertificate = (typeNumber, serialNumber) => (dispatch, getState) => {
  if (!typeNumber || !serialNumber) {
    return null
  }
  const id = `${typeNumber},${serialNumber}`
  if (certificatesBeingLoaded[id]) {
    return certificatesBeingLoaded[id]
  }
  const storedCertificate = getCertificateRecordByTypeNumberAndSerialNumber(getState(), typeNumber, serialNumber)
  if (storedCertificate) {
    return storedCertificate
  }
  const safeArgs = [encodeURIComponent(typeNumber), encodeURIComponent(serialNumber)]
  certificatesBeingLoaded[id] = axios.get(
    `${getStudioAPIHost()}/api/calibrations/certificate/${safeArgs[0]}/${safeArgs[1]}/download`,
    {
      customErrorHandler: () => {
        delete certificatesBeingLoaded[id]
      }, // suppress errors - bad request is part of "normal" workflow and is handled explicitly
      responseType: 'document',
    },
  )
  return certificatesBeingLoaded[id].then(
    ({ data, status }) => {
      delete certificatesBeingLoaded[id]
      if (status === 200) {
        dispatch(setChainCertificate(typeNumber, serialNumber, data))
      } else {
        dispatch(setChainCertificate(typeNumber, serialNumber, null))
      }
      return data
    },
    () => {
      delete certificatesBeingLoaded[id]
      dispatch(setChainCertificate(typeNumber, serialNumber, null))
    },
  )
}
