import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import ReactApexChart from 'react-apexcharts'

import { getColors } from 'skybase-ui/skybase-core/colors/utils'
import { getIotHubDeviceOnlineOfflineDevicesCount } from '@/iot-hub/selectors'
import { GREEN, RED, StatusBullet } from '@/common/status-bullet'
import { messages as t } from '../home-page-i18n'

const { green_100: green100, red_100: red100, black_25: black25 } = getColors(true)

export const Devices = () => {
  const { formatMessage: _ } = useIntl()
  const { online, offline } = useSelector(getIotHubDeviceOnlineOfflineDevicesCount)
  const count = online + offline

  const options = useMemo(
    () => ({
      chart: {
        type: 'donut',
      },
      colors: count > 0 ? [green100, red100] : [black25], // when there is no device, make the color for the only circle gray
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          filter: {
            type: 'none',
          },
        },
      },
      grid: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        show: false,
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
              name: {
                show: false,
              },
            },
          },
        },
      },
    }),
    [count],
  )

  return (
    <div id="devices-donut-container">
      <div className="donut-wrapper">
        <ReactApexChart
          options={options}
          series={count > 0 ? [online, offline] : [1]} // when there is no device, render only one series with the value 1 - which represents a full circle
          type="donut"
          width="200px"
          height="200px"
        />
        <div className="summary">
          <span className="number">{count}</span>
          <span className="label">{_(t.device, { count })}</span>
        </div>
      </div>
      <div className="legend">
        <StatusBullet className="status online-status" status={GREEN}>
          <span className="label">{_(t.online)}</span>
          <strong>{online}</strong>
        </StatusBullet>
        <StatusBullet className="status offline-status" status={RED}>
          <span className="label">{_(t.offline)}</span>
          <strong>{offline}</strong>
        </StatusBullet>
      </div>
    </div>
  )
}
