import { createSelector } from 'reselect'
import { getDevices } from '@/fleet-configuration/data-fleet/devices/devices-selectors'
import {
  getDeviceType,
  hasAcquisitionDeviceType,
  isConfigurableDevice,
  getFormattedModelNumber,
} from '@/fleet-configuration/utils/device-utils'
import { getSnippetFilter } from '@/fleet-configuration/data-fleet/snippets/snippets-selectors'

export const snippetsTableModalSelector = createSelector(
  getDevices,
  getSnippetFilter,
  (stateDevices, filterValues) => ({
    // it makes sense to adjust devices for our purposes here instead of in component -> free sort implementation
    devices: stateDevices
      ? stateDevices
          .filter(device => hasAcquisitionDeviceType(device.types || []) && isConfigurableDevice(device.types || []))
          .map(device => {
            const { types = [] } = device
            const deviceType = getDeviceType(types)
            const daq = true

            const modelNumber = getFormattedModelNumber(deviceType, device.modelNumber)
            return { ...device, modelNumber, deviceType, daq }
          })
      : [],
    filterValues,
  }),
)
