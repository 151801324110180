import React, { PureComponent } from 'react'

import { INLINE_MESSAGE_TYPE_ERROR } from 'skybase-ui/skybase-components/sb-inline-message/constants'
import { SbInlineMessage } from 'skybase-ui/skybase-components/sb-inline-message'

import { apiErrorsShape } from '@/common/shapes'

export class ErrorList extends PureComponent {
  static propTypes = {
    errors: apiErrorsShape,
  }

  static defaultProps = {
    errors: [],
  }

  render() {
    const { errors } = this.props

    return (
      errors?.map?.(error => {
        const { message, time, type } = error
        return (
          <SbInlineMessage
            className="sb-width-100pct"
            key={`${message}-${time}`}
            message={message}
            title={type}
            type={INLINE_MESSAGE_TYPE_ERROR}
          />
        )
      }) || null
    )
  }
}
