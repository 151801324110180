import { combineReducers } from 'redux'
import { backupReducer } from '@/fleet-configuration/data-fleet/backup/backup-reducer'
import { componentsReducer } from '@/fleet-configuration/data-fleet/components/components-reducer'
import { calibrationReducer } from '@/fleet-configuration/data-fleet/calibration/calibration-reducer'
import { catalogReducer } from '@/fleet-configuration/data-fleet/catalog/catalog-reducer'
import { chainReducer } from '@/fleet-configuration/data-fleet/chain/chain-reducer'
import { chainCatalogReducer } from '@/fleet-configuration/data-fleet/chain-catalog/chain-catalog-reducer'
import { chainCertificateReducer } from '@/fleet-configuration/data-fleet/chain-certificate/chain-certificate-reducer'
import { connectionsReducer } from '@/fleet-configuration/data-fleet/connections/connections-reducer'
import { devicesReducer } from '@/fleet-configuration/data-fleet/devices/devices-reducer'
import { devicesCalibrationsReducer } from '@/fleet-configuration/data-fleet/devices-calibrations/devices-calibrations-reducer'
import { devicesLastStateReducer } from '@/fleet-configuration/data-fleet/devices-last-state/devices-last-state-reducer'
import { deviceSyncStatusReducer } from '@/fleet-configuration/data-fleet/device-sync/device-sync-reducer'
import { wizardNavigationReducer } from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation-reducer'
import { deviceOnlineStatusReducer } from '@/fleet-configuration/data-fleet/device-online-status/device-online-status-reducer'
import { firmwareReducer } from '@/fleet-configuration/data-fleet/firmware/firmware-reducer'
import { processIndicatorReducer } from '@/fleet-configuration/data-fleet/process-indicator/process-indicator-reducer'
import { projectsReducer } from '@/fleet-configuration/data-fleet/projects/projects-reducer'
import { projectDevicesReducer } from '@/fleet-configuration/data-fleet/project-devices/project-devices-reducer'
import { projectDevicesDirtyReducer } from '@/fleet-configuration/data-fleet/project-devices-dirty/project-devices-dirty-reducer'
import { snippetsReducer, snippetDetailReducer } from '@/fleet-configuration/data-fleet/snippets/snippets-reducer'
import { showOnlyActiveChannelsReducer } from '@/fleet-configuration/data-fleet/devices-show-only-active/devices-show-only-active-reducer'

export const entriesReducer = combineReducers({
  backup: backupReducer,
  calibration: calibrationReducer,
  catalog: catalogReducer,
  chain: chainReducer,
  chainCatalog: chainCatalogReducer,
  chainCertificate: chainCertificateReducer,
  components: componentsReducer,
  connections: connectionsReducer,
  devices: devicesReducer,
  devicesCalibrations: devicesCalibrationsReducer,
  devicesLastState: devicesLastStateReducer,
  deviceNavigation: wizardNavigationReducer,
  deviceOnlineStatus: deviceOnlineStatusReducer,
  deviceSyncStatus: deviceSyncStatusReducer,
  firmware: firmwareReducer,
  processIndicator: processIndicatorReducer,
  projects: projectsReducer,
  projectDevices: projectDevicesReducer,
  projectDevicesDirty: projectDevicesDirtyReducer,
  showOnlyActiveChannels: showOnlyActiveChannelsReducer,
  snippets: snippetsReducer,
  snippetDetail: snippetDetailReducer,
})
