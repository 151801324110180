import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { omit } from 'lodash'
import { SbDropdown } from 'skybase-ui/skybase-components/sb-dropdown'
import { ComponentCommitter } from '@/fleet-configuration/components/component-committer/component-committer'
import { getDropdownLabelByValue } from '@/utils'
import './dropdown.scss'

const isMissingFromOptions = (options, value = null, mustHaveValue = true) => {
  const sValue = String(value)
  return (
    (!mustHaveValue || value || value === 0) &&
    !options.some(
      optGroup =>
        ('value' in optGroup && String(optGroup.value) === sValue) ||
        (optGroup.options && optGroup.options.some(signalOption => String(signalOption.value) === sValue)),
    )
  )
}

export class Dropdown extends React.Component {
  static defaultProps = {
    value: '',
    description: '',
    className: '',
    onChange: () => {},
    onCommit: null,
    onBlur: null,
    canShowEmptyOption: false,
  }

  static propTypes = {
    value: PropTypes.any,
    description: PropTypes.string,
    className: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onCommit: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    canShowEmptyOption: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.committer = new ComponentCommitter(this)
  }

  handleOnChange = (event, ...args) => {
    const { onChange } = this.props
    onChange(event, ...args)
    this.committer.handleOnChange(event, ...args)
  }

  render() {
    const { options, value, description, className, canShowEmptyOption, ...rest } = this.props
    let extendedOptions
    let isMissing
    const textReadableOptions = options.map(option => ({ label: option.description, ...option }))
    if (canShowEmptyOption && !value) {
      isMissing = isMissingFromOptions(textReadableOptions, value, false)
      extendedOptions = isMissing ? [{ value: '', label: '' }, ...textReadableOptions] : textReadableOptions
    } else {
      isMissing = isMissingFromOptions(textReadableOptions, value)
      extendedOptions = isMissing
        ? [
            ...textReadableOptions,
            {
              value: String(value),
              label: description || value,
              className: 'dropdown-missing-option',
            },
          ]
        : textReadableOptions
    }
    return (
      <SbDropdown
        items={extendedOptions}
        title={getDropdownLabelByValue(extendedOptions, value)}
        value={String(value)}
        className={classNames(className, 'dropdown', {
          'dropdown-missing-option': isMissing,
        })}
        {...omit(rest, ['onCommit'])}
        onChange={this.handleOnChange}
        onBlur={this.committer.handleOnBlur}
      />
    )
  }
}
