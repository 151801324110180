import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import { useAclList } from 'skybase-oauth/auth/use-acl-list'
import { SbDocumentTitle } from 'skybase-ui/skybase-components/sb-document-title'
import { iotHubPermissions } from '@/common/permissions'
import { fetchDevices, subscribeToDevicesStatusWS } from '@/iot-hub/components/devices/utils'
import { Card, DeviceOnboarding, Docs, Devices, Equipment, DeveloperToolsToggle } from './components'
import { messages as t } from './home-page-i18n'
import './home-page.scss'
import { IssuesToPayAttention } from '@/containers/home-page/components/issues-to-pay-attention/issues-to-pay-attention'

export const HomePage = () => {
  const { formatMessage: _ } = useIntl()
  const dispatch = useDispatch()
  const [initialized, setInitialized] = useState(false)
  const aclList = useAclList([iotHubPermissions.kiconnectDevicesRead])

  useEffect(() => {
    if (!initialized && aclList[iotHubPermissions.kiconnectDevicesRead]) {
      dispatch(fetchDevices()).then(() => {
        subscribeToDevicesStatusWS()
      })
      setInitialized(true)
    }
  }, [dispatch, aclList, initialized])

  return (
    <div id="home-dashboard">
      <SbDocumentTitle title={`KiCloud | ${_(t.pageName)}`} />
      <h1 className="m-b-30">{_(t.pageName)}</h1>
      <div className="cards">
        <Card title={_(t.onboardedDevices)}>{aclList[iotHubPermissions.kiconnectDevicesRead] && <Devices />}</Card>
        <Card title={_(t.myEquipment)}>
          <Equipment />
        </Card>
        <Card title={_(t.deviceOnboarding)}>
          <DeviceOnboarding />
        </Card>
        <Card title={_(t.issuesToPayAttention)} className="two-col">
          <IssuesToPayAttention />
        </Card>
        <Card title={_(t.documentation)}>
          <Docs />
        </Card>
      </div>

      <div className="m-t-10 fl-row fl-justify-end">
        <DeveloperToolsToggle />
      </div>
    </div>
  )
}
