import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  couldNotAddDeviceIntoProject: {
    id: 'Could not add device into project',
    defaultMessage: 'Could not add device into project',
  },
  addingDeviceFailedScanningForAvailableDevicesPleaseTryAgainLater: {
    id: 'Adding device failed - scanning for available devices. Please try again later.',
    defaultMessage: 'Adding device failed - scanning for available devices. Please try again later.',
  },
})
