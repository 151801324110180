import { getProjectDeviceById } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import { getDeviceLastStateById } from '@/fleet-configuration/data-fleet/devices-last-state/devices-last-state-selectors'
import { ReactComponent as Controller } from '../../svg-components/Controller.svg'
import { ReactComponent as Hochvolt } from '../../svg-components/Hochvolt.svg'
import { ReactComponent as HochvoltBanana } from '../../svg-components/Hochvolt-Banana.svg'
import { ReactComponent as Harting } from '../../svg-components/Harting.svg'
import { ReactComponent as Thermoelement } from '../../svg-components/Thermoelement.svg'
import { ReactComponent as ThermoelementHochvolt } from '../../svg-components/Thermoelement-Hochvolt.svg'
import { Klemmleiste, KlemmleisteX6, KlemmleisteX8, KlemmleisteX10 } from '../../svg-components/Klemmleiste'
import { ReactComponent as BNCx8 } from '../../svg-components/BNCx8.svg'
import { ReactComponent as KBeam } from '../../svg-components/K-Beam.svg'
import { ReactComponent as KbeamDsub } from '../../svg-components/K-Beam-Dsub.svg'
import { ReactComponent as LabAmp5165A1 } from '../../svg-components/labamp/5165A1.svg'
import { ReactComponent as LabAmp5165A4 } from '../../svg-components/labamp/5165A4.svg'
import { ReactComponent as LabAmp5167A40 } from '../../svg-components/labamp/5167A40.svg'
import { ReactComponent as LabAmp5167A41 } from '../../svg-components/labamp/5167A41.svg'
import { ReactComponent as LabAmp5167A80 } from '../../svg-components/labamp/5167A80.svg'
import { ReactComponent as LabAmp5167A81 } from '../../svg-components/labamp/5167A81.svg'
import { ReactComponent as Unsupported } from '../../svg-components/Unsupported.svg'
import { urlPathController } from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation-constants'

const knownTypes = [
  { image: Hochvolt, types: ['A111_BNC', 'A123_BNC', 'A141'] },
  { image: HochvoltBanana, types: ['A123', '5526A', 'A127', 'A128', '5529A'] },
  { image: Harting, types: ['A116'] },
  { image: Thermoelement, types: ['A104_TCK', 'A104_KTC', 'A104_KTCK'] },
  { image: ThermoelementHochvolt, types: ['A124'] },
  {
    image: Klemmleiste,
    types: ['A101', 'A104', 'A104_K', 'A105', 'A107', 'A109', 'D107', 'A111'],
  },
  {
    image: KlemmleisteX6,
    types: ['A106'],
  },
  {
    image: KlemmleisteX8,
    types: ['D101', '5534A', '5507a'],
  },
  {
    image: KlemmleisteX10,
    types: ['A108', 'A108_100V'],
  },
  { image: BNCx8, types: ['A108_BNC'] },
  { image: KBeam, types: ['5514A'] },
  { image: KbeamDsub, types: ['5514A_2M3'] },
  { image: LabAmp5165A1, types: ['5165A1'], width: 350, height: 65 },
  { image: LabAmp5165A4, types: ['5165A4'], width: 350, height: 65 },
  { image: LabAmp5167A40, types: ['5167A40'], width: 350, height: 65 },
  { image: LabAmp5167A41, types: ['5167A41'], width: 350, height: 65 },
  { image: LabAmp5167A80, types: ['5167A80'], width: 350, height: 100 },
  { image: LabAmp5167A81, types: ['5167A81'], width: 350, height: 100 },
]

const getImageDataByType = type => knownTypes.find(({ types }) => types.includes(type))

const generateModuleId = (deviceId, resourcePath) => `id_${deviceId}${resourcePath.replace(/\//g, '-')}`

export const unitIllustrationSelector = (
  state,
  {
    router: {
      params: { unitId, moduleId, channelId },
    },
    measurementDevice,
  },
) => {
  const deviceId = measurementDevice?.id || unitId
  const device = measurementDevice || getProjectDeviceById(state, unitId)
  if (!device) {
    return { modules: [] }
  }
  const { controller } = device.deviceSpecific || {}
  const controllerId = controller && generateModuleId(deviceId, controller.resourcePath)
  const modules = []

  const deviceLastState = getDeviceLastStateById(state, deviceId)
  const deviceLastStateController = deviceLastState?.deviceSpecific?.controller
  const fwController = deviceLastStateController || controller || {}
  const controllerFwVersion = (fwController.parametersReadable || fwController.parameters)?.fwVersion

  if (controller) {
    modules.push({
      id: controllerId,
      Image: Controller,
      imageWidth: 46,
      imageHeight: 200,
      snr: (controller.parametersReadable || controller.parameters).snr,
      firmware: controllerFwVersion,
    })
  }
  modules.push(
    ...((device.modules &&
      device.modules.map(module => {
        const imageType = module.getImageType()
        const imageData = getImageDataByType(imageType) || {}
        const Image = imageData.image || Unsupported
        return {
          type: imageType,
          id: generateModuleId(unitId, module.resourcePath),
          Image,
          imageWidth: imageData.width || 46,
          imageHeight: imageData.height || 200,
          snr: (module.parametersReadable || module.parameters).snr,
          firmware: (module.parametersReadable || module.parameters).fwVersion,
        }
      })) ||
      []),
  )

  let selectedModuleId
  let deviceModelNumber

  if (moduleId && (!isNaN(+moduleId) || moduleId === urlPathController)) {
    const selectedModule = moduleId === urlPathController ? controller : device.modules[moduleId]
    selectedModuleId = selectedModule && generateModuleId(unitId, selectedModule.resourcePath)
    deviceModelNumber = selectedModule.getType() || (controller ? controller.getType() : device.modelNumber)
  } else {
    deviceModelNumber = device.getKistlerModelNumber()
  }

  return {
    modules,
    selectedModuleId,
    selectedChannel: channelId,
    deviceModelNumber,
    deviceSnr: device.serialNumber,
    deviceFirmware: deviceLastState?.firmwareVersion || device?.firmwareVersion,
  }
}
