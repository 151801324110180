import React, { createElement } from 'react'
import PropTypes from 'prop-types'

export class Heading extends React.PureComponent {
  static propTypes = {
    level: PropTypes.number.isRequired,
    children: PropTypes.node,
  }

  static defaultProps = {
    children: null,
  }

  convertTitleToId = title => title.replace(/\s/g, '-').toLowerCase()

  render() {
    const { level, children, ...rest } = this.props

    let id = null
    if (typeof children === 'string') {
      id = this.convertTitleToId(children)
    }
    if (typeof children?.[0]?.props?.value === 'string') {
      id = this.convertTitleToId(children[0].props.value)
    }

    return createElement(`h${level}`, { ...rest, id }, children)
  }
}
