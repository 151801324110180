import { combineReducers } from 'redux'

import { DEVICES_STATE_KEY_NAME, devicesReducer } from './components/devices'
import { DEVICE_DATA_SOURCES_KEY_NAME, dataSourcesReducer } from './components/devices/datasources'

import { NOTIFICATIONS_STATE_KEY_NAME, notificationsReducer } from './components/notifications'

export const iotHubReducers = combineReducers({
  [DEVICES_STATE_KEY_NAME]: devicesReducer,
  [DEVICE_DATA_SOURCES_KEY_NAME]: dataSourcesReducer,
  [NOTIFICATIONS_STATE_KEY_NAME]: notificationsReducer,
})
