import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SbButton } from 'skybase-ui/skybase-components/sb-button'
import { messages as t } from './wizard-buttons-i18n'
import { steps } from '@/fleet-configuration/page-components/wizard/wizard-constants'
import { wizardDeviceSelector } from '@/fleet-configuration/page-components/wizard/wizard-device-selector'
import './wizard-buttons.scss'
import { WriteSettingsButton } from '@/fleet-configuration/components/write-settings-button/write-settings-button'

export const WizardButtons = ({ step, deviceId }) => {
  const navigate = useNavigate()
  const { formatMessage: _ } = useIntl()
  const { device } = useSelector(state => wizardDeviceSelector(state, { deviceId }))

  return (
    <div className="wizard-bottom-buttons">
      <SbButton
        className="wizard-close-button"
        onClick={() => {
          navigate('/configuration/devices')
        }}
      >
        {_(t.close)}
      </SbButton>
      <span className="fl-container-row">
        <SbButton
          className="wizard-back-button m-r-20"
          onClick={
            step === 0
              ? () => {}
              : () => {
                  navigate(`/configuration/${steps[step - 1].urlSegment}/${deviceId}`)
                }
          }
          disabled={step === 0 || (step === 1 && device && !device.isLabAmp())}
        >
          {_(t.back)}
        </SbButton>
        {step === steps.length - 1 ? (
          <WriteSettingsButton deviceId={deviceId} deviceName={device.name} />
        ) : (
          <SbButton
            className="wizard-next-button primary"
            onClick={() => navigate(`/configuration/${steps[step + 1].urlSegment}/${deviceId}`)}
            disabled={step === steps.length - 1}
          >
            {_(t.continue)}
          </SbButton>
        )}
      </span>
    </div>
  )
}
WizardButtons.propTypes = {
  step: PropTypes.number.isRequired,
  deviceId: PropTypes.string.isRequired,
}
