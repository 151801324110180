import { ADD_NOTIFICATION, VISIT_NOTIFICATION, VISIT_ALL_NOTIFICATIONS } from './actions'

const getDefaultState = () => {
  return []
}

export const notificationsReducer = (state = getDefaultState(), action = {}) => {
  const { payload, type } = action

  switch (type) {
    case ADD_NOTIFICATION: {
      return state.concat(payload.notification)
    }
    case VISIT_NOTIFICATION: {
      return state.map(notification => {
        if (notification.id === payload.id) {
          return {
            ...notification,
            visited: true,
          }
        }
        return notification
      })
    }
    case VISIT_ALL_NOTIFICATIONS: {
      return state.map(notification => {
        return {
          ...notification,
          visited: true,
        }
      })
    }
    default:
      return state
  }
}
