import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'
import { OAuth } from 'skybase-oauth/oauth'
import { WSClients } from '@/common/websocket'
import { deviceStatusWebsocketKey } from '@/iot-hub/components/devices/constants'
import { setDiscoveringDevices } from '@/iot-hub/components/devices/actions'
import { getDiscoveringDevicesState } from '@/iot-hub/selectors'

export const DEFAULT_DEVICE_OBSERVATION_TIMEOUT_SECONDS = 30

export const getObservationTimeoutSeconds = () =>
  OAuth.config.DEVICE_OBSERVATION_TIMEOUT_SECONDS ?? DEFAULT_DEVICE_OBSERVATION_TIMEOUT_SECONDS

/*
This service automatically stops the websocket for observing
the device status - this websocket starts the device discoveryprocess
on the background and we want to limit it.

This is registered only in the Client Application (Local and Backend mode).
*/
export const clientAppWSManager = store => {
  const stopObservingDevicesTimer = () => {
    setTimeout(() => {
      WSClients.removeFromWsClients(deviceStatusWebsocketKey)
      store.dispatch(setDiscoveringDevices(false))
    }, getObservationTimeoutSeconds() * 1000)
  }

  SbEmitter.on('startObservingDevices', () => {
    if (!getDiscoveringDevicesState(store.getState())) {
      store.dispatch(setDiscoveringDevices(true))
      stopObservingDevicesTimer()
    }
  })
}
