import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { SbLink } from 'skybase-ui/skybase-components/sb-link/sb-link'
import { SbButton } from 'skybase-ui/skybase-components/sb-button/sb-button'
import { SbFullLayout } from 'skybase-ui/skybase-components/layouts/sb-full-layout'
import { openModal } from 'skybase-ui/skybase-core/base/actions'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbDataTable } from 'skybase-ui/skybase-components/sb-data-table'
import { SbHint } from 'skybase-ui/skybase-components/sb-hint'
import { hintPositions } from 'skybase-ui/skybase-components/sb-hint/constants'
import { showSuccessToast } from '@/common/services/show-toast'
import { withRouter } from '@/common/router'
import { ImportSnippet } from '@/fleet-configuration/components/import-snippet/import-snippet'
import { showConfirmModal } from '@/fleet-configuration/components/confirm-modal/confirm-modal-actions'
import { deletePreset, exportPreset, loadSnippets } from '@/fleet-configuration/data-fleet/snippets/snippets-actions'
import { configurationSnippetsSelector } from '@/fleet-configuration/pages/configuration-snippets/configuration-snippets-selector'
import { SNIPPETS_TABLE_MODAL_ID } from '@/fleet-configuration/page-components/snippets-table-modal/snippets-table-modal-constants'
import { messages as t } from './configuration-snippets-i18n'
import './configuration-snippets.scss'
import '../pages.scss'

class _ConfigurationSnippets extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    snippets: PropTypes.array.isRequired,
    intl: intlShape.isRequired,
    router: PropTypes.shape({ navigate: PropTypes.func.isRequired }).isRequired,
  }

  constructor(props) {
    super(props)
    const {
      intl: { formatMessage: _ },
    } = props

    this.tableColumns = [
      { name: 'name', label: _(t.name) },
      { name: 'created', label: _(t.created) },
      { name: 'lastChanged', label: _(t.lastChange) },
      { name: '_actions', label: ' ', sortable: false },
    ]

    this.state = {
      isLoading: true,
      pagination: {
        pageSize: 10,
        pageNumber: 1,
      },
    }
  }

  async componentDidMount() {
    const {
      dispatch,
      router: { navigate },
    } = this.props
    try {
      await dispatch(loadSnippets())
    } catch (err) {
      navigate('/fleet/404')
    }
    this.setState({ isLoading: false })
  }

  cellFormatter = (value, key, row) => {
    if (key === '_actions') {
      return this.renderActions(row)
    }
    if (['created', 'lastChanged'].includes(key)) {
      const {
        intl: { formatDate },
      } = this.props
      return value ? formatDate(value, { format: 'date-minutes-12hour-numeric' }) : ''
    }
    return value
  }

  renderActions(row) {
    const {
      intl: { formatMessage: _ },
    } = this.props
    const applyIcon = (
      <SbHint hintData={_(t.applyToDevices)} position={hintPositions.topCenter}>
        <span
          onClick={() => this.handleOnSnippetApply(row.id)}
          className="sbi-nav-devices icon-link test-apply-to-devices"
        />
      </SbHint>
    )
    const exportIcon = (
      <SbHint hintData={_(t.exportPreset)} position={hintPositions.topCenter}>
        <span
          onClick={() => this.handleOnSnippetExport(row.id)}
          className="sbi-export-kids icon-link test-export-snippet"
        />
      </SbHint>
    )
    const importIcon = (
      <ImportSnippet
        snippetId={row.id}
        ViewBox={props => (
          <SbHint hintData={_(t.importPreset)} position={hintPositions.topCenter}>
            <span {...props} className="sbi-import-kids icon-link test-import-snippet" />
          </SbHint>
        )}
      />
    )
    const deleteIcon = (
      <SbHint hintData={_(t.deletePreset)} position={hintPositions.topCenter}>
        <span
          className={classNames('sbi-delete-kids icon-link')}
          onClick={() => this.handleOnSnippetDeleteClick(row.id, row.name)}
        />
      </SbHint>
    )
    const configureLink = (
      <SbLink to={`/configuration/preset/detail/${row.id}`} className="snippet-config-link icon-link">
        <SbHint hintData={_(t.configurePreset)} position={hintPositions.topCenter}>
          <span className="sbi-setting-kids" />
        </SbHint>
      </SbLink>
    )
    const content = (
      <>
        {configureLink}
        {deleteIcon}
        {importIcon}
        {exportIcon}
        {applyIcon}
      </>
    )
    return (
      <div onClick={evt => evt.stopPropagation()} className="show-on-hover">
        {content}
      </div>
    )
  }

  handleOnPaginationChange = pagination => this.setState({ pagination })

  handleOnSnippetExport = id => {
    const { dispatch } = this.props
    dispatch(exportPreset(id))
  }

  handleOnSnippetDeleteClick = (snippetId, snippetName) => {
    const {
      dispatch,
      intl: { formatMessage: _ },
    } = this.props
    dispatch(
      showConfirmModal(_, _(t.deletePreset), deletePreset(snippetId), {
        modalMessage: (
          <>
            {_(t.areYouSureYouWantToDeletePresetPreset, { preset: <i>{snippetName}</i> })}
            <br />
            {_(t.thisActionCannotBeUndone)}
          </>
        ),
        confirmText: _(t.delete),
        className: 'destructive-confirm-modal snippet-confirm-modal',
        afterConfirmAction: () => {
          showSuccessToast(_(t.presetDeleted))
        },
      }),
    )
  }

  handleOnSnippetApply = snippetId => {
    const { dispatch } = this.props
    dispatch(openModal(SNIPPETS_TABLE_MODAL_ID, { snippetId }))
  }

  render() {
    const {
      intl: { formatMessage: _ },
      snippets,
    } = this.props

    const { isLoading, pagination } = this.state

    return (
      <SbFullLayout
        title={_(t.presets)}
        className="measurement-screen-page"
        breadcrumbs={[
          {
            path: '/',
            title: _(t.home),
          },
          {
            path: '/configuration/devices',
            title: _(t.configuration),
          },
          _(t.presets),
        ]}
      >
        <div>
          <div className="fl-container layout-title-with-buttons">
            <h1 className="title-name">{_(t.presets)}</h1>
            <ImportSnippet
              ViewBox={props => (
                <SbButton {...props} className="test-import-new-snippet">
                  {_(t.import)}
                </SbButton>
              )}
            />
          </div>
          <SbDataTable
            id="snippets-table"
            className="list-table"
            columns={this.tableColumns}
            loading={isLoading}
            data={snippets}
            emptyMessage={_(t.noPresetsFound)}
            cellFormatter={this.cellFormatter}
            enablePagination
            asyncData={false}
            paginationProps={{
              pageSizeDropdownProps: {
                className: 'min-width-100px',
              },
              ...pagination,
              onChange: this.handleOnPaginationChange,
            }}
          />
        </div>
      </SbFullLayout>
    )
  }
}

export const ConfigurationSnippets = withRouter(
  injectIntl(connect(configurationSnippetsSelector)(_ConfigurationSnippets)),
)
