// noinspection LoopStatementThatDoesntLoopJS

import { SET_INITIAL_DEVICES_ONLINE_STATUS } from '@/iot-hub/components'
import { SET_DEVICE_ONLINE_STATUS } from './device-online-status-actions'

export const deviceOnlineStatusReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_INITIAL_DEVICES_ONLINE_STATUS: {
      // for-in is most performant test for testing for empty object (especially large objects).
      // eslint-disable-next-line guard-for-in,no-restricted-syntax,no-unreachable-loop
      for (const hasObject in state) {
        return state
      }
      return action.payload.devices.reduce((acc, { id, status }) => {
        if (id && status) {
          acc[id] = status
        }
        return acc
      }, {})
    }
    case SET_DEVICE_ONLINE_STATUS: {
      const { deviceId, status } = action.payload
      return {
        ...state,
        [deviceId]: status,
      }
    }
    default:
      return state
  }
}
