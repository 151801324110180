import { getVariousColors } from '@/utils/get-various-colors'

const colors = getVariousColors()

// Returns an object where the key is an item from a provided array, and a value is a color.
export const getColorsObjectFromArray = (values = []) => {
  if (values) {
    let index = -1
    return values.reduce((coloredValues, value) => {
      index += 1
      if (!colors[index]) {
        index = 0
      }
      return Object.assign(coloredValues, { [value]: colors[index] })
    }, {})
  }

  return {}
}
