import { uniqBy } from 'lodash'
import { CALIBRATION_STATUSES } from '@/fleet-configuration/data-fleet/chain-certificate/chain-certificate-constants'
import { getCalibrationItems } from '@/fleet-configuration/data-fleet/calibration/calibration-selectors'
import { getEntries } from '@/fleet-configuration/data-fleet/entries/entries-selectors'

export const getChainCertificateEntries = state => getEntries(state, 'entries.chainCertificate')

export const getCertificateRecordByTypeNumberAndSerialNumber = (state, typeNumber, serialNumber) =>
  state.entries.chainCertificate.byId[JSON.stringify({ typeNumber, serialNumber })]

// certificate is object - so we need Map instead of POJO, because regular object's key is toString-ed,
//  which means it would be impossible to distinguish between objects. But Maps allow anything to be key
const certificatePerSensitivityCache = new Map()
export const getSensitivityPerMaxRangeFromSensorCertificate = certificate => {
  const cachedValue = certificatePerSensitivityCache.get(certificate)
  if (cachedValue) {
    return cachedValue
  }
  const certificateResultTags = certificate.querySelectorAll('UnitUnderTest > Result')
  const sensitivityPerMaxRangeArr = uniqBy(
    Array.from(certificateResultTags)
      .reduce((acc, item) => {
        const maxValidValue = parseFloat(item.querySelector('Input > Number > Value')?.innerHTML)
        const maxValidValueUnit = item.querySelector('Input > Number > Unit')?.innerHTML?.trim()
        if (isNaN(maxValidValue)) {
          return acc
        }
        const isPreload = Array.from(item.querySelectorAll('ValueType')).filter(
          node => node.innerHTML?.trim().toLowerCase() === 'preload',
        ).length
        const sensitivityNode = Array.from(item.querySelectorAll('Result')) // take all certificate results for this max range
          .filter(node => node.querySelector('ValueType')?.innerHTML.includes('sensitivity'))[0] // filter to those having sensitivity value
        const sensitivityValue = parseFloat(sensitivityNode?.querySelector('Value')?.innerHTML)
        const sensitivityUnit = sensitivityNode?.querySelector('Unit')?.innerHTML.trim()
        acc.push({
          maxRange: maxValidValue,
          maxRangeUnit: maxValidValueUnit,
          sensitivityValue,
          sensitivityUnit,
          isPreload,
        })
        return acc
      }, [])
      .sort((a, b) => a.maxRange - b.maxRange || b.isPreload - a.isPreload),
    'maxRange',
  )
  certificatePerSensitivityCache.set(certificate, sensitivityPerMaxRangeArr)
  return sensitivityPerMaxRangeArr
}

export const getMaxRangeUnitFromCertificate = certificate => {
  return certificate.querySelector('UnitUnderTest > Result > Input > Number > Unit')?.innerHTML.trim()
}

export const getSensorSensitivityByPhysicalRangeTo = (sensitivityPerMaxRange, physicalRangeTo) => {
  const absPhysicalRangeTo = Math.abs(physicalRangeTo)
  const sensitivityCandidates =
    physicalRangeTo < 0
      ? sensitivityPerMaxRange.filter(itm => itm.maxRange < 0).reverse()
      : sensitivityPerMaxRange.filter(itm => itm.maxRange > 0)
  return sensitivityCandidates.find(candidate => absPhysicalRangeTo <= Math.abs(candidate.maxRange))
}

export const getSensorCalibrationStatus = (state, typeNumber, serialNumber) => {
  const calibrationItems = getCalibrationItems(state)
  const calibration = calibrationItems[JSON.stringify({ typeNumber, serialNumber })]
  if (!calibration?.calibrationItem?.calibrationDate || !calibration.calibrationItem.calibrationIntervalDays) {
    return CALIBRATION_STATUSES.UNKNOWN
  }
  const calibrationDate = new Date(calibration.calibrationItem.calibrationDate)
  calibrationDate.setDate(calibrationDate.getDate() + calibration.calibrationItem.calibrationIntervalDays)
  if (calibrationDate.getTime() < Date.now()) {
    return CALIBRATION_STATUSES.EXPIRED
  }
  return CALIBRATION_STATUSES.VALID
}

export const getSensorDisplayDataFromCatalog = catalog => {
  if (catalog) {
    if (
      catalog.name &&
      catalog.parameters?.physicalQuantity &&
      catalog.parameters.sensor &&
      catalog.parameters.naturalFrequency
    ) {
      return {
        name: catalog.name,
        measurand: catalog.parameters.physicalQuantity,
        sensorClass: catalog.parameters.sensor,
        naturalFrequency: parseInt(catalog.parameters.naturalFrequency, 10),
        range:
          catalog.parameters && 'rangeLow' in catalog.parameters && 'rangeHigh' in catalog.parameters
            ? `<${catalog.parameters.rangeLow}, ${catalog.parameters.rangeHigh}>`
            : null,
      }
    }
    console.error('Incomplete catalog will not be used: ', catalog)
  }
  return null
}
