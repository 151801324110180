import { v4 } from 'uuid'

import { addNotification } from './actions'
import { notificationTypes } from './constants'
import { messages as t } from './notifications-i18n'

const { ERROR, WARNING, SUCCESS } = notificationTypes

// Returns a translated string depending on the type of the notification.
export const getBadgeTitle = (type, _) => {
  switch (type) {
    case ERROR:
      return _(t.error)
    case SUCCESS:
      return _(t.success)
    case WARNING:
      return _(t.warning)
    default:
      return _(t.info)
  }
}

// Returns a className for the icon depending on the type of the notification.
export const getBadgeIconClassName = type => {
  switch (type) {
    case ERROR:
      return 'sbi-toast-error'
    case SUCCESS:
      return 'sbi-toast-success'
    case WARNING:
      return 'sbi-toast-warning'
    default:
      return 'sbi-toast-info'
  }
}

// Return only the not visited notifications
export const filterNotVisitedNotifications = notification => !notification.visited

// Returns an action which created a notification
export const logNotification = ({ type, title, message, payload, redirectLocation, ...rest }) => {
  return addNotification({
    id: v4(),
    type,
    time: new Date(),
    title,
    message,
    payload,
    redirectLocation,
    visited: false,
    ...rest,
  })
}
