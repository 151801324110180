import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { SbEmitter } from 'skybase-ui/skybase-core/emitter/sb-emitter'
import { OAuth } from 'skybase-oauth/oauth'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { StatusBar } from 'skybase-ui/skybase-components/status-bar'
import { toggleLeftColumn as toggleLeftColumnAction } from 'skybase-ui/skybase-components/sb-left-column/actions'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { UserWidget, TenantWidget } from 'skybase-oauth/oauth/components'

import { withRouter } from '@/common/router'
import { getMenuStructure } from '@/common/menu'
import { setEulaReviewMode } from '@/common/eula/eula-actions'
import { StreamingStatusPanel } from '@/iot-hub/components/devices/datasources/streaming-status-panel'
import { getEnvironmentNameFromUrl } from './utils'
import { messages as t } from './header-i18n'

class Header extends Component {
  static propTypes = {
    handleOnToggleLeftColumn: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    router: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props)
    this.state = { isLoadingEula: false, appTitleClickCount: 0 }
  }

  componentDidMount() {
    window.addEventListener('click', this.handleAppTitleOutsideClick)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleAppTitleOutsideClick)
  }

  getAppEnvironment = () => {
    if (!OAuth.isInLocalBackendMode || !OAuth?.config?.IDENTITY_SERVER_URL) {
      return ''
    }

    const environmentName = getEnvironmentNameFromUrl(OAuth.config.IDENTITY_SERVER_URL)

    if (environmentName) {
      return ` - ${environmentName}`
    }

    return ''
  }

  // Return a string representing the name of the type of the application mode.
  getMode = () => {
    if (OAuth.isInLocalSDKMode) {
      return 'Local SDK'
    }

    if (OAuth.isInLocalBackendMode) {
      return `Local Backend${this.getAppEnvironment()}`
    }

    return null
  }

  handleAppTitleOutsideClick = event => {
    const { appTitleClickCount } = this.state
    if (event.target.id !== 'app-title' && appTitleClickCount !== 0) {
      this.setState({ appTitleClickCount: 0 })
    }
  }

  handleAppTitleClick = () => {
    const { appTitleClickCount } = this.state
    if (appTitleClickCount === 9) {
      SbEmitter.emit('enable-dev-tools')
      this.setState({ appTitleClickCount: 0 })
      return
    }
    this.setState(prevState => ({ appTitleClickCount: prevState.appTitleClickCount + 1 }))
  }

  renderIdentifierZone = () => {
    const {
      router: {
        location: { pathname: currentUrl = '' },
      },
      intl: { formatMessage: _ },
    } = this.props

    const pageName = getMenuStructure()
      .filter(item => !item.hiddenSideMenu)
      .find(item => currentUrl.indexOf(item.url) !== -1)

    // eslint-disable-next-line no-nested-ternary
    const pageTitle = OAuth.isInNormalMode ? (pageName?.title ? _(pageName.title) : 'KiCloud') : 'KiConnect Client'

    return (
      <span
        id="app-title"
        className="fl-container fl-align-items-center sb-white-space-nowrap"
        onClick={this.handleAppTitleClick}
      >
        {pageTitle}
      </span>
    )
  }

  renderInfoZone = () => {
    const {
      intl: { formatMessage: _ },
      dispatch,
    } = this.props
    const { isLoadingEula } = this.state
    return (
      <div className="fl-container fl-row fl-align-items-center fl-grow-1">
        {!OAuth.isInLocalSDKMode && (
          <>
            {isLoadingEula ? (
              <SbLoader show />
            ) : (
              <UserWidget
                additionalItems={[
                  {
                    title: _(t.viewSoftwareLicenseAgreement),
                    className: 'test-review-eula',
                    onClick: () => {
                      this.setState({ isLoadingEula: true })
                      dispatch(setEulaReviewMode(true))
                        .catch(() => ({}))
                        .then(() => {
                          this.setState({ isLoadingEula: false })
                        })
                    },
                  },
                ]}
              />
            )}
            <span className="separator" />
            <TenantWidget />
            <span className="separator" />
          </>
        )}
      </div>
    )
  }

  renderSpecificZone = () => {
    return (
      <div className="fl-container fl-row fl-justify-start fl-align-items-center fl-grow-1 p-l-10 sb-height-100pct">
        <span id="app-mode" className="m-r-10">
          {this.getMode()}
        </span>
        <StreamingStatusPanel />
        <div className="item fl-row fl-justify-sb fl-align-items-center" />
      </div>
    )
  }

  render() {
    const { handleOnToggleLeftColumn } = this.props
    const {
      intl: { formatMessage },
    } = this.props

    return (
      <StatusBar
        onMenuItemClick={() => handleOnToggleLeftColumn()}
        identifierZone={this.renderIdentifierZone(formatMessage)}
        specificZone={this.renderSpecificZone()}
        infoZone={this.renderInfoZone()}
        helpProps={{ href: '/doc', target: '_blank' }}
      />
    )
  }
}

const mapDispatchToProps = dispatch => ({
  handleOnToggleLeftColumn() {
    dispatch(toggleLeftColumnAction())
  },
  dispatch,
})

export default withRouter(injectIntl(connect(null, mapDispatchToProps)(Header)))
