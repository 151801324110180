import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { SbNotFoundPage } from 'skybase-pages'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { Sb21Layout } from 'skybase-ui/skybase-components/layouts'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { TenantsList, TenantTab, CreateTenantButton } from 'skybase-oauth/tenants/components'
import { withAcl, aclShape } from 'skybase-oauth/auth'
import { oAuthPermissions } from 'skybase-oauth/oauth-permissions'
import { getTenantApi } from 'skybase-oauth/rest'

import { withRouter } from '@/common/router'
import { isValidGuid } from '@/utils'
import { messages as menuT } from '@/common/menu/menu-i18n'

import { messages as t } from './tenants-page-i18n'

export class _AssignedTenantsPage extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    acl: aclShape.isRequired,
    router: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      params: PropTypes.shape({
        tenantId: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      hasError: false,
      tenant: {},
    }
  }

  async componentDidMount() {
    const {
      router: {
        params: { tenantId },
      },
      acl: { read },
    } = this.props

    if (read && tenantId && isValidGuid(tenantId)) {
      try {
        const tenant = await getTenantApi(tenantId)

        this.setState({
          loading: false,
          hasError: false,
          tenant,
        })
      } catch (e) {
        this.setError()
      }
    } else {
      this.setError()
    }
  }

  setError = () => {
    this.setState({
      loading: false,
      hasError: true,
    })
  }

  getBreadcrumbs = () => {
    const { tenant } = this.state
    const {
      intl: { formatMessage: _ },
    } = this.props

    if (tenant) {
      const { id, name } = tenant
      return [
        {
          path: '/',
          title: _(menuT.home),
        },
        {
          path: '/identity-management',
          title: _(menuT.identityManagement),
        },
        {
          path: '/identity-management/user-management',
          title: _(menuT.userManagement),
        },
        {
          path: '/identity-management/user-management/tenants',
          title: _(menuT.tenantsPage),
        },
        {
          path: `/identity-management/user-management/tenants/${id}`,
          title: name,
        },
        _(t.assignedPageName),
      ]
    }

    return null
  }

  renderError = message => {
    return <SbNotFoundPage {...this.props} message={message} />
  }

  render() {
    const {
      loading,
      hasError,
      tenant: { id: tenantId },
    } = this.state
    const {
      intl: { formatMessage: _ },
      router: {
        params: { tenantId: matchTenantId },
      },
    } = this.props

    if (loading) {
      return (
        <div className="fl-row fl-justify-center fl-align-items-center">
          <SbLoader textWrapperClassName="sb-width-ml" show loadingText={_(t.loading)} size={30} />
        </div>
      )
    }

    if (!loading && hasError) {
      return this.renderError(_(t.tenantNotFound, { tenantId: matchTenantId }))
    }

    return (
      <Sb21Layout title={_(t.assignedPageName)} breadcrumbs={this.getBreadcrumbs()}>
        <div>
          <div className="sb-heading fl-row fl-align-items-center">
            <h1>{_(t.assignedPageName)}</h1>
            <CreateTenantButton parentTenantId={tenantId} />
          </div>
          <TenantsList parentTenantId={tenantId} />
        </div>
        <TenantTab parentTenantId={tenantId} />
      </Sb21Layout>
    )
  }
}

const withAclComponent = withAcl(_AssignedTenantsPage, {
  readPermissions: [oAuthPermissions.kiconnectTenantsRead],
})

export const AssignedTenantsPage = withRouter(injectIntl(withAclComponent))
