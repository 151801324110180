/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  thereAreTooManyHardwareItemsForCheckingTheirStatus: {
    id: 'issuesToPayAttention.thereAreTooManyHardwareItemsForCheckingTheirStatus',
    defaultMessage: 'There are too many hardware items for checking their status',
  },
  failedToRetrieveAnyIssuesToPayAttentionTo: {
    id: 'issuesToPayAttention.failedToRetrieveAnyIssuesToPayAttentionTo',
    defaultMessage: 'Failed to retrieve any issues to pay attention to',
  },
  pleaseTryRefreshingThePageOrTryAccessingTheServiceLater: {
    id: 'issuesToPayAttention.pleaseTryRefreshingThePageOrTryAccessingTheServiceLater',
    defaultMessage: 'Please try refreshing the page or try accessing the service later',
  },
})
