import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'

import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbDataTable } from 'skybase-ui/skybase-components/sb-data-table'
import { messages as oa } from 'skybase-oauth/messages-i18n'

import { StatusBullet, RED, GREEN } from '@/common/status-bullet'
import { DEFAULT_LICENSING_SORT_BY } from './constants'
import { licenseShape } from './shapes'
import { messages as t } from './licenses-i18n'

class _LicensesList extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    data: PropTypes.arrayOf(licenseShape).isRequired,
    selectedLicenseId: PropTypes.number,
    onLoadLicenseToUpdate: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    selectedLicenseId: null,
  }

  constructor(props) {
    super(props)

    const {
      intl: { formatMessage: _ },
    } = props

    this.tableColumns = [
      {
        name: 'itemName',
        label: _(t.licenseName),
        cellsClassName: 'text-align-left',
        headerClassName: 'text-align-left',
      },
      { name: 'productCode', label: _(t.productCode) },
      { name: 'assignmentTime', label: _(t.assignmentTime) },
      { name: 'activated', label: _(t.activation) },
    ]
  }

  onRowSelect = ticketLicenseId => {
    const { data, onLoadLicenseToUpdate } = this.props

    const thisLicense = data.find(license => license.ticketLicenseId === ticketLicenseId)

    if (thisLicense) {
      onLoadLicenseToUpdate(ticketLicenseId)
    }
  }

  cellFormatter = (value, key) => {
    const {
      intl: { formatMessage: _, formatDate },
    } = this.props

    if (key === 'activated') {
      return (
        <StatusBullet className="no-word-break" status={value ? GREEN : RED}>
          {value ? _(t.activated) : _(t.notActivated)}
        </StatusBullet>
      )
    }

    if (key === 'assignmentTime') {
      return <span className="no-word-break">{formatDate(value, { format: 'date-minutes-12hour-numeric' })}</span>
    }

    return value
  }

  rowFormatter = row => {
    const { selectedLicenseId } = this.props
    const selected = row.ticketLicenseId === selectedLicenseId ? 'selected' : ''

    return {
      onClick: () => this.onRowSelect(row.ticketLicenseId),
      className: classNames({ selected }),
    }
  }

  render() {
    const {
      data,
      intl: { formatMessage: _ },
      loading,
    } = this.props

    return (
      <SbDataTable
        id="licenses-table"
        className="list-table"
        loading={loading}
        columns={this.tableColumns}
        data={data}
        rowFormatter={row => this.rowFormatter(row)}
        cellFormatter={this.cellFormatter}
        emptyMessage={_(oa.noResults)}
        enablePagination
        asyncData={false}
        defaultSortBy={DEFAULT_LICENSING_SORT_BY}
      />
    )
  }
}

export const LicensesList = injectIntl(_LicensesList)
