import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbTextbox } from 'skybase-ui/skybase-components/sb-textbox'

import { messages as t } from './devices-i18n'

class _SearchDevices extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    onSearchChange: PropTypes.func,
    value: PropTypes.string,
  }

  static defaultProps = {
    onSearchChange: () => {},
    value: '',
  }

  onChange = e => {
    const { onSearchChange } = this.props

    onSearchChange(e.target.value)
  }

  handleOnClear = () => {
    const { onSearchChange } = this.props

    onSearchChange('')
  }

  render() {
    const {
      intl: { formatMessage: _ },
      value,
    } = this.props

    return (
      <SbTextbox
        value={value}
        onChange={this.onChange}
        placeholder={_(t.search)}
        outerClassName="sb-width-m m-r-20 search-devices"
        addonLeft={<i className="sbi-search" />}
        addonRight={
          value ? (
            <div onClick={this.handleOnClear}>
              <i className="sbi-close" />
            </div>
          ) : null
        }
      />
    )
  }
}

export const SearchDevices = injectIntl(_SearchDevices)
