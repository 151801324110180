import React, { Component } from 'react'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import { IntlProvider } from 'react-intl'
import config from '@/config'

import { getLocaleState } from 'skybase-ui/skybase-core/locales'

const mapStateToProps = state => {
  const { language, messages } = getLocaleState(state)

  return {
    locale: config.languageCultureMap[language] || config.defaultCulture,
    key: config.languageCultureMap[language] || config.defaultCulture,
    messages: messages[language],
    formats: {
      date: {
        date: {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        },
        'date-table': {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        },
        'date-minutes': {
          year: 'numeric',
          month: 'long',
          day: '2-digit',
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
        },
        'date-seconds': {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        },
        'date-minutes-12hour': {
          year: 'numeric',
          month: 'long',
          day: '2-digit',
          hour12: true,
          hour: '2-digit',
          minute: '2-digit',
        },
        'date-minutes-12hour-numeric': {
          year: 'numeric',
          month: 'numeric',
          day: '2-digit',
          hour12: true,
          hour: '2-digit',
          minute: '2-digit',
        },
      },
      time: {
        time: {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        },
        'time-seconds': {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        },
      },
      number: {
        imperial: {
          maximumFractionDigits: 0,
          useGrouping: false,
        },
        'imperial-velocity': {
          maximumFractionDigits: 1,
          useGrouping: false,
        },
        'full-precision': {
          maximumFractionDigits: 20,
          useGrouping: false,
        },
        metric: {
          maximumFractionDigits: 2,
          useGrouping: false,
        },
        'metric-velocity': {
          maximumFractionDigits: 2,
          useGrouping: false,
        },
        'metric-sign': {
          minimumSignificantDigits: 2,
          maximumSignificantDigits: 2,
          useGrouping: false,
        },
        percentage: {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
          useGrouping: false,
        },
      },
    },
  }
}

class _CustomIntlProvider extends Component {
  shouldComponentUpdate(props) {
    const { dispatch: nextDispatch, ...nextRest } = props // eslint-disable-line
    const { dispatch, ...rest } = this.props // eslint-disable-line

    return !isEqual(rest, nextRest)
  }

  render() {
    const { locale, messages, formats, children } = this.props // eslint-disable-line

    return (
      <IntlProvider locale={locale} messages={messages} formats={formats} onError={() => {}}>
        {children}
      </IntlProvider>
    )
  }
}

const CustomIntlProvider = connect(mapStateToProps)(_CustomIntlProvider)

export default CustomIntlProvider
// export default connect(mapStateToProps)(IntlProvider)
