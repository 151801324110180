import { TOGGLE_NAVIGATION_EXPANSION } from './wizard-navigation-actions'

export const wizardNavigationReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case TOGGLE_NAVIGATION_EXPANSION: {
      const { expansionItemId, newState = !state[expansionItemId] } = action.payload
      return { ...state, [expansionItemId]: newState }
    }
    default:
      return state
  }
}
