import axios from 'axios'
import { OAuth } from 'skybase-oauth'

export const getDeviceMeasurementRunning = projectDevice => async () => {
  // labamp has exactly 1 sampling rate and kgate has 4, but they are already sorted so first one is always the main/interesting one
  const dataSourceId = projectDevice.samplingRates[0].dataSourceId
  const { data } = await axios.get(
    `${OAuth.config.API_ENDPOINT_URL}/api/devices/${projectDevice.id}/kic/data/src/${dataSourceId}?interface=oic.if.rw`,
  )
  return !!data?.transfer
}
