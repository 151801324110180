import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Prism } from 'react-syntax-highlighter'
import { okaidia as defaultStyle } from 'react-syntax-highlighter/dist/cjs/styles/prism'

export class SyntaxHighlighter extends Component {
  static propTypes = {
    allowUpdates: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string]).isRequired,
  }

  static defaultProps = {
    allowUpdates: false,
  }

  shouldComponentUpdate(nextProps) {
    const { allowUpdates } = nextProps

    return allowUpdates
  }

  render() {
    const { allowUpdates, children, ...rest } = this.props

    return (
      <Prism style={defaultStyle} wrapLines {...rest}>
        {children?.trim ? children.trim() : children}
      </Prism>
    )
  }
}
