export const equipmentApi = {
  EQUIPMENT: '/api/equipment',
  COMPONENTS_TYPE: '/api/components/type',
  CALIBRATIONS: '/api/calibrations',
}

export const families = {
  SENSOR: 'sensor',
  CABLE: 'cable',
  DEVICE: 'device',
  MODULE: 'module',
  CONTROLLER: 'controller',
}

export const formScenarios = {
  CREATE: 'create',
  UPDATE: 'update',
}

export const emptyEquipmentForm = {
  family: null,
  serialNumber: '',
  typeName: '',
  typeNumber: '',
  userNotes: '',
  icon: '',
}

export const EQUIPMENT_MODAL_ID = 'equipment-modal'

export const CDN_SERVICE_100 = 'https://kistler.cdn.celum.cloud/SAPCommerce_Product_100x100/'

export const SHOW_EVERYTHING_FILTER_VALUE = '__SHOW_EVERYTHING_VALUE__'

export const equipmentEmitterEvents = {
  REFETCH_EQUIPMENT_LIST: 'refetch-equipment-list',
  ADD_EQUIPMENT_BY_ID: 'add-equipment-by-id',
}
