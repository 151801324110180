import { SHOW_ONLY_ACTIVE_CHANNELS } from './devices-show-only-active-actions'

export const showOnlyActiveChannelsReducer = (state = false, action = {}) => {
  switch (action.type) {
    case SHOW_ONLY_ACTIVE_CHANNELS:
      return action.payload.isShowOnlyActiveChannels
    default:
      return state
  }
}
