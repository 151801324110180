import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbButton } from 'skybase-ui/skybase-components/sb-button'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { BackupConfigurationModal } from '@/fleet-configuration/components/backup-configuration/backup-configuration-modal'
import { messages as t } from './backup-configuration-button-i18n'
import { backupConfigurationButtonSelector } from '@/fleet-configuration/components/backup-configuration/backup-configuration-button-selector'

class _BackupConfigurationButton extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    deviceId: PropTypes.string.isRequired,
    device: PropTypes.object,
    piid: PropTypes.string,
    canBackup: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    piid: null,
    device: null,
  }

  constructor(props) {
    super(props)
    this.state = { isModalOpen: false }
  }

  handleClick = () => {
    this.setState(state => ({ isModalOpen: !state.isModalOpen }))
    const { device } = this.props
    if (!device) {
      console.error('Device was not found on time')
    }
  }

  render() {
    const {
      intl: { formatMessage: _ },
      deviceId,
      piid,
      canBackup,
    } = this.props
    const { isModalOpen } = this.state
    return (
      <>
        <SbButton onClick={this.handleClick} disabled={!canBackup} className="sb-width-ml secondary test-backup-button">
          {_(t.backupConfigurationOverview)}
          {isModalOpen && !piid && <SbLoader show />}
        </SbButton>
        {isModalOpen && piid && (
          <BackupConfigurationModal deviceId={deviceId} piid={piid} onDismiss={this.handleClick} />
        )}
      </>
    )
  }
}

export const BackupConfigurationButton = injectIntl(
  connect(backupConfigurationButtonSelector)(_BackupConfigurationButton),
)
