import { selectMeasurementUnitParams } from '@/fleet-configuration/select-measurement-unit-params'
import {
  getIsDeviceReloading,
  getProjectDeviceById,
} from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import { getChainsByModuleDetails } from '@/fleet-configuration/data-fleet/chain/chain-selectors'

export const configurationSummarySelector = (state, props) => {
  const { unitId } = selectMeasurementUnitParams(state, props)

  let deviceChain = null
  const device = getProjectDeviceById(state, unitId)
  // when we have all dependencies, then show the actual chain
  if (device?.isLabAmp()) {
    const module = device.modules?.[0]
    deviceChain =
      module && getChainsByModuleDetails(state, module.getType(), module.parametersReadable?.snr || device.serialNumber)
  }

  return {
    deviceId: unitId,
    deviceChain,
    device,
    isDeviceReloading: !!getIsDeviceReloading(state),
  }
}
